import {
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  Skeleton,
  Box,
  ButtonCopy,
} from '@gr4vy/poutine-react'
import { Input } from 'antd'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'shared/paths/users'
import NewUserPageLayout from 'users/components/NewUserPageLayout/NewUserPageLayout'
import { getRolesAndPermissionsNames } from 'users/helpers'
import { useResetPassword } from 'users/hooks/use-reset-password'
import { User } from 'users/services/users'

interface UserInvitePageProps {
  title: string
  user?: User
}

const HeaderLoader = (
  <>
    <Skeleton width={32}>
      <Skeleton.Box height={32} />
    </Skeleton>
    <Flex flexDirection="column" gap={4}>
      <Skeleton gap={4}>
        <Skeleton.Box style={{ maxWidth: 320 }} height={24} />
        <Skeleton.Box height={20} />
      </Skeleton>
    </Flex>
  </>
)

const FooterLoader = (
  <Skeleton>
    <Skeleton.Box height={20} />
  </Skeleton>
)

export default function UserInvitePage({ title, user }: UserInvitePageProps) {
  const navigate = useNavigate()
  const { isInvalidToken, isExpiredToken, expirationTime, resetLink } =
    useResetPassword(user)
  const userRoles = useMemo(() => user?.roles ?? [], [user?.roles])

  return (
    <NewUserPageLayout
      title={title}
      step={
        userRoles.find((userRole) => userRole.name === 'Administrator')
          ? 'ADMIN_INVITE'
          : 'INVITE'
      }
    >
      {!user ? (
        HeaderLoader
      ) : (
        <>
          <Icon name="circle-check" size="large" color="green60" />
          <Stack gap={4}>
            <Heading as="h4">
              You&apos;ve successfully added “{user?.name}”.
            </Heading>
            <Text variant="reg3" color="gray100">
              An invitation link has been generated for the user with these
              roles and details.
            </Text>
          </Stack>
        </>
      )}
      <Stack gap={16}>
        <Text variant="overline1" color="gray70">
          USER DETAILS
        </Text>
        <Box>
          <Text variant="reg3" color="black" fontWeight="bold">
            Name
          </Text>
          <Text variant="reg3" color="gray90">
            {user?.name ?? '-'}
          </Text>
        </Box>
        <Box>
          <Text variant="reg3" color="black" fontWeight="bold">
            Email
          </Text>
          <Text variant="reg3" color="gray90">
            {user?.emailAddress ?? '-'}
          </Text>
        </Box>
        <Box>
          <Text variant="reg3" color="black" fontWeight="bold">
            Roles
          </Text>
          <Text variant="reg3" color="gray90">
            {getRolesAndPermissionsNames(userRoles).rolesNames}
          </Text>
        </Box>
        <Box>
          <Text variant="reg3" color="black" fontWeight="bold">
            Additional permissions
          </Text>
          <Text variant="reg3" color="gray90">
            {getRolesAndPermissionsNames(userRoles).additionalPermissionsNames}
          </Text>
        </Box>
        <Box>
          <Text variant="reg3" color="black" fontWeight="bold">
            Merchant accounts
          </Text>
          <Text variant="reg3" color="gray90">
            {user?.merchantAccounts
              .map((merchantAccount) => merchantAccount.displayName)
              .join(', ')}
          </Text>
        </Box>
      </Stack>
      <Stack gap={8}>
        <Text variant="med4" color="gray100">
          Invitation link for user
        </Text>
        <Flex gap={16}>
          <Input
            value={resetLink}
            style={{ textOverflow: 'ellipsis' }}
            readOnly
          />
          <ButtonCopy
            copy={resetLink}
            disabled={isInvalidToken || isExpiredToken}
          >
            Copy link
          </ButtonCopy>
        </Flex>
      </Stack>
      {!user && FooterLoader}
      {(!isInvalidToken || !isExpiredToken) && (
        <Text color="gray100">
          <b>Note:&nbsp;</b>
          This invite will expire in {expirationTime}. It can be regenerated at
          any point.
        </Text>
      )}

      <Flex gap={16}>
        <Button variant="secondary" onClick={() => navigate(pathTo.users)}>
          See all users
        </Button>
        {user && (
          <Button
            variant="tertiary"
            onClick={() => navigate(pathTo.user(user.id))}
          >
            See user details
          </Button>
        )}
      </Flex>
    </NewUserPageLayout>
  )
}
