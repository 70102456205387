import { useInfiniteQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { listDefinitions, PaymentServiceDefinition } from 'connections/services'
import { PAYMENT_SERVICE_DEFINITIONS } from 'shared/constants'
import { CollectionResponse } from 'shared/services/client'

interface Context {
  isLoading: boolean
  isError: boolean
  items?: CollectionResponse<PaymentServiceDefinition>
  paymentServiceDefinitions: Record<string, PaymentServiceDefinition>
}

const usePaymentServiceDefinitions = (): Context => {
  const {
    isLoading,
    isError,
    isFetching,
    isSuccess,
    data,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [PAYMENT_SERVICE_DEFINITIONS],
    queryFn: ({ pageParam: cursor }: { pageParam: string | null }) =>
      listDefinitions({
        params: { limit: 100, ...(cursor ? { cursor } : {}) },
      }),
    initialPageParam: null,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  })

  const allPagesData = data?.pages.map((page) => [...page.items]).flat() || []

  const paymentServiceDefinitions = allPagesData.reduce(
    (result, service) => {
      result[service.id] = service
      return result
    },
    {} as Record<string, PaymentServiceDefinition>
  )

  const items = { items: allPagesData, limit: 100 }

  useEffect(() => {
    if (!isFetching && isSuccess && hasNextPage) {
      fetchNextPage({ cancelRefetch: false })
    }
  }, [isFetching, isSuccess, hasNextPage, fetchNextPage])

  return {
    isLoading,
    isError,
    items,
    paymentServiceDefinitions: paymentServiceDefinitions || {},
  }
}

export default usePaymentServiceDefinitions
