import { DraggableLocation } from '@hello-pangea/dnd'
import { FormInstance } from 'antd'
import { ActionOutcome } from 'flows/constants'

type DragLocation = DraggableLocation & {
  droppableId: 'visibleOutcomes' | 'hiddenOutcomes'
}

export type RuleOutcome = {
  visibleOutcomes: ActionOutcome[]
  hiddenOutcomes: ActionOutcome[]
}

export const moveAll =
  (categorisedOutcomes: RuleOutcome, form: FormInstance) =>
  async ({
    from,
    to,
  }: {
    from: 'visibleOutcomes' | 'hiddenOutcomes'
    to: 'visibleOutcomes' | 'hiddenOutcomes'
  }) => {
    categorisedOutcomes[to] = [
      ...categorisedOutcomes[to],
      ...categorisedOutcomes[from],
    ]
    categorisedOutcomes[from] = []

    form.setFieldsValue({
      outcome: {
        result: categorisedOutcomes.visibleOutcomes.map(({ id }) => id),
      },
    })
  }

export const moveRuleOutcome =
  (categorisedOutcomes: RuleOutcome, form: FormInstance) =>
  async ({
    destination,
    source,
  }: {
    source: DragLocation
    destination: DragLocation
  }) => {
    if (!destination) {
      return
    }
    const items = reorder({ categorisedOutcomes, source, destination })
    form.setFieldsValue({
      outcome: {
        result: items.visibleOutcomes.map(({ id }) => id),
      },
    })
  }

const moveItem = (list: ActionOutcome[] = [], from: number, to: number) => {
  list.splice(to, 0, list.splice(from, 1)[0])
  return list
}

const reorder = ({
  categorisedOutcomes,
  source,
  destination,
}: {
  categorisedOutcomes: RuleOutcome
  source: DragLocation
  destination: DragLocation
}): RuleOutcome => {
  // moving to same list
  if (source.droppableId == destination.droppableId) {
    categorisedOutcomes[source.droppableId] = moveItem(
      categorisedOutcomes[source.droppableId],
      source.index,
      destination.index
    )

    return categorisedOutcomes
  }

  const current = [...categorisedOutcomes[source.droppableId]]
  const next = [...categorisedOutcomes[destination.droppableId]]
  const target = current[source.index]

  // moving to different list
  // remove from original
  current.splice(source.index, 1)
  // insert into next
  next.splice(destination.index, 0, target)

  categorisedOutcomes[source.droppableId] = current
  categorisedOutcomes[destination.droppableId] = next

  return categorisedOutcomes
}
