import { useMutation } from '@tanstack/react-query'
import { FormInstance } from 'antd'
import { useMemo } from 'react'
import { ActionOutcome } from 'flows/constants'
import {
  moveAll,
  moveRuleOutcome,
  RuleOutcome,
} from 'flows/utils/moveRuleOutcome'

export const useRuleOutcomes = (
  form: FormInstance,
  outcomes: ActionOutcome[]
): { categorisedOutcomes: RuleOutcome; onMoveRule: any; onMoveAll: any } => {
  const categorisedOutcomes = useMemo(() => {
    const selectedOutcomeIds = (form.getFieldValue(['outcome', 'result']) ||
      []) as string[]

    return {
      visibleOutcomes: selectedOutcomeIds.flatMap((selectedOutcomeId) =>
        outcomes.filter(({ id }) => id === selectedOutcomeId)
      ),
      hiddenOutcomes: outcomes.filter(
        ({ id }) => !selectedOutcomeIds.includes(id)
      ),
    }
  }, [form, outcomes])

  const onMoveRule: any = useMutation({
    mutationFn: moveRuleOutcome(categorisedOutcomes, form),
  })

  const onMoveAll: any = useMutation({
    mutationFn: (key: {
      from: 'visibleOutcomes' | 'hiddenOutcomes'
      to: 'visibleOutcomes' | 'hiddenOutcomes'
    }): any => moveAll(categorisedOutcomes, form)(key),
  })

  return {
    categorisedOutcomes,
    onMoveRule,
    onMoveAll,
  }
}
