import { GridItem } from '@gr4vy/poutine-react'
import { Input } from 'antd'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { currencyFieldValidator } from 'shared/utils/currency'
import { CurrencyInput } from './CurrencyInput'

export const ERROR_VALUE_REQUIRED = 'Value is required'

export const SingleAmountCondition = () => (
  <>
    <GridItem gridColumn="span 2" data-testid="single-amount-condition">
      <FormItem
        label={<Label>Value</Label>}
        name={['amountCondition', 'value', 'value']}
        rules={[
          { required: true, message: ERROR_VALUE_REQUIRED },
          ({ getFieldValue }) => ({
            validator(_, value) {
              return currencyFieldValidator(
                value,
                getFieldValue(['amountCondition', 'value', 'currency'])
              )
            },
          }),
        ]}
      >
        <Input type="number" />
      </FormItem>
    </GridItem>
    <GridItem gridColumn="span 4">
      <CurrencyInput
        name={['amountCondition', 'value', 'currency']}
        label="Currency"
      />
    </GridItem>
  </>
)
