import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  GET_MERCHANT_ACCOUNT,
  LIST_VAULT_FORWARD_CONFIGS,
  LIST_VAULT_FORWARD_DEFINITIONS,
} from 'shared/constants/query-keys'
import {
  MerchantAccount,
  getMerchantAccount,
} from 'shared/services/merchant-accounts'
import {
  VaultForwardConfig,
  listVaultForwardConfigs,
  listVaultForwardDefinitions,
  addVaultForwardConfig,
  removeVaultForwardConfig,
} from 'shared/services/vault-forward'
import { handleSuccessResponse } from 'shared/utils/handleSuccessResponse'
import { showErrorMessage } from 'shared/utils/showMessage'
import View from './EditMerchantAccountVaultForwardingPage'

const EditMerchantAccountVaultForwardingPage = ({
  title,
}: {
  title: string
}) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const queryClient = useQueryClient()
  const [removingVaultAuth, setRemovingVaultAuth] = useState<
    VaultForwardConfig | undefined
  >(undefined)

  const { data: merchantAccount } = useQuery({
    queryKey: [GET_MERCHANT_ACCOUNT, merchantAccountId],
    queryFn: () => getMerchantAccount(merchantAccountId),
    enabled: !!merchantAccountId,
  })

  const { data: definitions } = useQuery({
    queryKey: [LIST_VAULT_FORWARD_DEFINITIONS, merchantAccountId],
    queryFn: () => listVaultForwardDefinitions(merchantAccountId),
    enabled: !!merchantAccountId,
  })

  const { data: configs } = useQuery({
    queryKey: [LIST_VAULT_FORWARD_CONFIGS, merchantAccountId],
    queryFn: () => listVaultForwardConfigs(merchantAccountId),
    enabled: !!merchantAccountId,
  })

  const invalidateConfigs = () =>
    queryClient.invalidateQueries({
      queryKey: [LIST_VAULT_FORWARD_CONFIGS, merchantAccountId],
    })

  const enable = useMutation({
    mutationFn: addVaultForwardConfig,
    onSuccess: () => {
      handleSuccessResponse('Endpoint enabled.', invalidateConfigs)()
    },
    onError: () =>
      showErrorMessage('Unable to enable endpoint. Please try again.'),
  })

  const remove = useMutation({
    mutationFn: removeVaultForwardConfig,
    onSuccess: handleSuccessResponse('Endpoint disabled.', () => {
      invalidateConfigs()
      setRemovingVaultAuth(undefined)
    }),
    onError: () =>
      showErrorMessage('Unable to disable endpoint. Please try again.'),
  })

  return definitions && configs ? (
    <View
      title={title}
      merchantAccount={merchantAccount as MerchantAccount}
      configs={configs.items as VaultForwardConfig[]}
      definitions={definitions}
      enable={enable}
      remove={remove}
      removingVaultAuth={removingVaultAuth}
      setRemovingVaultAuth={setRemovingVaultAuth}
    />
  ) : (
    <></>
  )
}

export default EditMerchantAccountVaultForwardingPage
