import { Grid, GridItem, Heading, Stack } from '@gr4vy/poutine-react'
import {
  MerchantNameInput,
  MerchantUrlInput,
  MerchantDisplayNameInput,
  MerchantCountryCodeInput,
} from 'connections/components/DigitalWalletForm'
import { HelpText } from 'shared/components/Form'
import { DigitalWalletActivatePage } from './DigitalWalletsActivatePage'

type FormValues = {
  merchantName: string
  merchantUrl: string
  acceptTermsAndConditions: boolean
  merchantCountryCode: string
  merchantDisplayName: string
}

export const DigitalWalletsActivateClickToPayPage = ({
  title,
  initialValues = {
    acceptTermsAndConditions: true, // @todo https://gr4vy.atlassian.net/browse/TA-5145?focusedCommentId=16107
  },
}: {
  title: string
  initialValues?: Partial<FormValues>
}) => {
  return (
    <DigitalWalletActivatePage
      title={title}
      heading={
        <Heading level={3} as="h1">
          Click To Pay
        </Heading>
      }
      provider="click-to-pay"
      initialValues={initialValues}
      render={() => (
        <>
          <Grid>
            <GridItem gridColumn="span 6">
              <Stack gap={24}>
                <Heading level={4}>Merchant details</Heading>
                <MerchantNameInput
                  help={<HelpText>The legal name of your company.</HelpText>}
                />
                <MerchantDisplayNameInput
                  help={
                    <HelpText>
                      The name of your company presented to the buyer.
                    </HelpText>
                  }
                />
                <MerchantUrlInput />
                <MerchantCountryCodeInput />
              </Stack>
            </GridItem>
          </Grid>
        </>
      )}
    />
  )
}
