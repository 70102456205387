import { Icon, Stack, Description, Tooltip } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { pathTo, usePaymentServiceDefinitions } from 'connections'
import { useGiftCardServiceDefinition } from 'connections/hooks/use-gift-card-service-definition'
import iconConnector from 'images/icons/connector.svg'
import { AccessLevel, Resource } from 'shared/permissions/constants'
import { useResourcePermission } from 'shared/permissions/hooks'
import { PayoutSummary, Transaction } from 'transactions/services'

interface TransactionOverviewConnectionProps {
  transaction: Transaction | PayoutSummary
}

const TransactionOverviewConnection = ({
  transaction,
}: TransactionOverviewConnectionProps) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const { paymentService, paymentMethod, giftCardService } = transaction
  const { paymentServiceDefinitions } = usePaymentServiceDefinitions()
  const { giftCardServiceDefinition } = useGiftCardServiceDefinition(
    merchantAccountId,
    giftCardService?.giftCardServiceDefinitionId,
    {
      enabled: !!giftCardService?.giftCardServiceDefinitionId && !paymentMethod,
    }
  )
  const hasPermission = useResourcePermission(
    Resource.connections,
    AccessLevel.read
  )

  const paymentServiceDefinition =
    paymentService?.paymentServiceDefinitionId &&
    paymentServiceDefinitions[paymentService.paymentServiceDefinitionId]

  if (!transaction.paymentMethod && !!giftCardServiceDefinition) {
    return (
      <Description>
        <Description.Icon src={giftCardServiceDefinition.iconUrl} />
        <Description.Text>
          {giftCardServiceDefinition.displayName}
        </Description.Text>
      </Description>
    )
  }

  return paymentServiceDefinition ? (
    <Description>
      <Description.Icon src={paymentServiceDefinition.iconUrl} />
      {transaction?.paymentService?.id && hasPermission ? (
        <Description.Link
          href={pathTo.editConnectionPaymentService(
            merchantAccountId,
            transaction?.paymentService?.id
          )}
        >
          {paymentService?.displayName as string}
        </Description.Link>
      ) : (
        <Description.Text>
          {paymentService?.displayName as string}
        </Description.Text>
      )}
      <Description.SubText>
        {paymentServiceDefinition.displayName}
      </Description.SubText>
    </Description>
  ) : (
    <Stack direction="row" gap={4}>
      <Description>
        <Description.Icon src={iconConnector} />
        <Description.Text>No connection</Description.Text>
      </Description>

      <Tooltip
        content="There is no connection for this transaction. You can check your rules or
      verify your credentials."
      >
        <Icon name="info" size="small" />
      </Tooltip>
    </Stack>
  )
}

export default TransactionOverviewConnection
