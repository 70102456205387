import { TransactionStatus } from 'shared/services/transactions'

export const getToggledTransactionStatus = (
  transactionStatus: TransactionStatus,
  currentTransactionStatus?: TransactionStatus | TransactionStatus[]
) => {
  if (!currentTransactionStatus) {
    return transactionStatus
  }

  const arrCurrentTransactionStatus = [currentTransactionStatus].flat()

  if (
    arrCurrentTransactionStatus.includes(transactionStatus) &&
    arrCurrentTransactionStatus.length === 1
  ) {
    return undefined
  }

  return arrCurrentTransactionStatus.includes(transactionStatus)
    ? arrCurrentTransactionStatus.filter(
        (status) => status !== transactionStatus
      )
    : [...arrCurrentTransactionStatus, transactionStatus]
}
