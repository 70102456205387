import { Box, Heading } from '@gr4vy/poutine-react'

interface TitleProps {
  children: string
}

export const Title = ({ children }: TitleProps) => (
  <Box height={32} alignItems="center" asChild>
    <Heading level={5}>{children}</Heading>
  </Box>
)
