import { useNavigate, useParams } from 'react-router-dom'
import { usePaymentServiceDefinition } from 'connections/hooks/use-payment-service-definition'
import { usePaymentServices } from 'connections/hooks/use-payment-services'
import { Loading } from 'shared/components/Loading'
import { pathTo } from 'shared/paths/connections'
import { PaymentServiceAddPage as Page } from './PaymentServiceAddPage'

const PaymentServiceAddPage = () => {
  const navigate = useNavigate()
  const { create } = usePaymentServices()
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }

  const { paymentServiceDefinition, isLoading } = usePaymentServiceDefinition(
    merchantAccountId,
    id
  )

  if (isLoading || !paymentServiceDefinition) {
    return <Loading />
  }

  const handleSubmit = async (values: any) => {
    create.mutate(values, {
      onSuccess: (data) => {
        navigate(
          pathTo.editConnectionPaymentService(merchantAccountId, data.id)
        )
      },
    })
  }
  const handleCancel = () => {
    navigate(pathTo.connectionsCatalog(merchantAccountId), { replace: true })
  }

  return (
    <Page
      definition={paymentServiceDefinition}
      saving={create.isPending}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    />
  )
}

export default PaymentServiceAddPage
