import { Box, Flex } from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { isSandbox } from 'shared/helpers/is-sandbox'
import styles from './ErrorPageLayout.module.scss'

interface ErrorPageLayoutProps {
  title: string
  children: ReactNode
}

const ErrorPageLayout = ({ title, children }: ErrorPageLayoutProps) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Flex
      alignItems="center"
      justifyContent="center"
      className={clsx(styles.container, isSandbox && styles.sandbox)}
    >
      <Flex
        background="gray10"
        alignItems="center"
        justifyContent="center"
        width="full"
        height="full"
      >
        <Box width="readable" textAlign="center">
          {children}
        </Box>
      </Flex>
    </Flex>
  </>
)

export default ErrorPageLayout
