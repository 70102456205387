import { FormInstance } from 'antd'
import { isEqualWith } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalCancel } from 'shared/components/Modal/ModalCancel'
import { Prompt } from 'shared/components/Prompt'
import { mapObjectStructure } from './map-object-structure'
import { objectComparer } from './object-comparer'
import type { Location } from 'history'

interface FormPromptUnsavedChangesProps {
  form: FormInstance
  initialValues: Record<string, any>
}

const FormPromptUnsavedChanges = ({
  form,
  initialValues,
}: FormPromptUnsavedChangesProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const navigate = useNavigate()
  const [navigateTo, setNavigateTo] = useState<Location>()
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const handleBlockedRoute = (nextLocation: Location) => {
    // initialValues might have more properties than the form data
    // so we cant compared the objects directly, we need to exrtract
    // the properties that are in the form data from the initialValues
    // into a new object.

    const fieldsValue = form.getFieldsValue()
    const filteredInitialValues = mapObjectStructure(initialValues, fieldsValue)

    if (
      !confirmedNavigation &&
      !isEqualWith(filteredInitialValues, fieldsValue, objectComparer)
    ) {
      setIsModalVisible(true)
      setNavigateTo(nextLocation)
      return false
    }

    return true
  }
  const handleOk = () => {
    setIsModalVisible(false)
    setConfirmedNavigation(true)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    if (confirmedNavigation && navigateTo) {
      navigate(navigateTo.pathname)
    }
  }, [confirmedNavigation, navigateTo, navigate])

  return (
    <>
      <Prompt message={handleBlockedRoute} />
      {isModalVisible ? (
        <ModalCancel
          title="Leave page"
          okText="Yes, leave"
          onOk={handleOk}
          onCancel={handleCancel}
        >
          There are unsaved changes. Are you sure want to leave this page?
        </ModalCancel>
      ) : null}
    </>
  )
}

export default FormPromptUnsavedChanges
