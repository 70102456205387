export const ArrowUpIcon = () => (
  <svg
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 0.5L12 8.5H0L6 0.5Z" fill="currentColor" />
  </svg>
)
