import { Box, Container, Skeleton } from '@gr4vy/poutine-react'

export const Loading = () => (
  <Box marginTop={32} data-testid="Loading">
    <Container>
      <Skeleton>
        <Skeleton.Header />
        <Skeleton.Paragraph />
      </Skeleton>
    </Container>
  </Box>
)
