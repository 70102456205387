import { atoms, ButtonCopy, Heading, Stack, Text } from '@gr4vy/poutine-react'
import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { paymentMethodTabName } from 'payment-methods/constants'
import { usePaymentMethod } from 'payment-methods/hooks/use-payment-method'
import { PageLayout } from 'shared/components/PageLayout'
import { PaymentMethodTabs } from 'shared/constants'
import { dateFormatHeader } from 'shared/helpers/date-format'
import { pathTo } from 'shared/paths/payment-methods'
import { MaskedCardNumber } from 'transactions/components/MaskedCardNumber'
import { PaymentMethod } from 'transactions/services'

interface PaymentMethodPageLayoutProps {
  children: ReactNode
}
export default function PaymentMethodPageLayout({
  children,
}: PaymentMethodPageLayoutProps) {
  const { paymentMethodId, merchantAccountId } = useParams() as {
    paymentMethodId: string
    merchantAccountId: string
  }
  const { paymentMethod, isCard, scheme, definition } =
    usePaymentMethod(paymentMethodId)

  const tabs = [
    {
      title: paymentMethodTabName[PaymentMethodTabs.overview],
      url: pathTo.paymentMethodId(
        merchantAccountId,
        paymentMethodId,
        PaymentMethodTabs.overview
      ),
    },
    {
      title: paymentMethodTabName[PaymentMethodTabs.transactions],
      url: pathTo.paymentMethodId(
        merchantAccountId,
        paymentMethodId,
        PaymentMethodTabs.transactions
      ),
    },
  ]

  const title = isCard
    ? scheme?.displayName
    : paymentMethod?.label || definition?.displayName

  const iconUrl = scheme?.iconUrl || definition?.iconUrl

  return (
    <PageLayout
      title={title ?? ''}
      titleChildren={
        <Stack direction="row" gap={16} alignItems="center">
          {iconUrl && (
            <img className={atoms({ height: 32, width: 32 })} src={iconUrl} />
          )}
          <Heading level={3} as="h1">
            {isCard ? (
              <MaskedCardNumber
                paymentMethod={paymentMethod as PaymentMethod}
              />
            ) : (
              paymentMethod?.label || definition?.displayName
            )}
          </Heading>
        </Stack>
      }
      titleSubChildren={
        <Text as="span" color="gray80" fontWeight="normal">
          {dateFormatHeader(paymentMethod?.createdAt)}
        </Text>
      }
      subNavigation={tabs}
      actions={
        <ButtonCopy variant="secondary" copy={document.location.href}>
          Copy link
        </ButtonCopy>
      }
      noContainer
      gap="none"
    >
      {children}
    </PageLayout>
  )
}
