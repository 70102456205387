import { CardSchemeDefinition } from 'shared/services'
import { useCardSchemeDefinitions } from './use-card-scheme-definitions'

export const useCardSchemeDefinition = (
  scheme?: string
): CardSchemeDefinition | null => {
  const definitions = useCardSchemeDefinitions(!!scheme)

  if (!scheme) {
    return null
  }

  return definitions[scheme] || null
}
