import { Flex, Text } from '@gr4vy/poutine-react'
import { ReactNode } from 'react'

interface SummaryHeadingProps {
  children?: ReactNode
}

export const SummaryHeading = ({ children }: SummaryHeadingProps) => (
  <Flex height={24} alignItems="center">
    <Text variant="overline1">{children}</Text>
  </Flex>
)
