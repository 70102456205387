import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useReducer } from 'react'
import { HAS_SSO, SSO_REDIRECT } from 'session/constants'
import {
  createSession,
  endSession,
  Credentials,
  TokenConfiguration,
} from 'session/services/sessions'
import { getCurrentUser, User } from 'session/services/users'
import { GET_CURRENT_USER } from 'shared/constants'

type State =
  | { value: 'valid'; context: { user: User } }
  | { value: 'invalid'; context: { errors?: Array<string> } }
  | { value: 'pending.idle'; context: Record<string, unknown> }
  | {
      value: 'pending.refresh'
      context: Partial<TokenConfiguration>
    }
  | {
      value: 'pending.signInWithToken'
      context: TokenConfiguration
    }
  | {
      value: 'pending.signInWithCredentials'
      context: { credentials: Credentials }
    }
  | { value: 'pending.signOut'; context: Record<string, unknown> }

type Action =
  | { type: 'SIGN_IN'; payload: Credentials }
  | { type: 'SIGN_IN.SUCCESS' }
  | { type: 'SIGN_IN.ERROR' }
  | { type: 'SIGN_IN_TOKEN.SUCCESS' }
  | { type: 'SIGN_IN_TOKEN.ERROR' }
  | { type: 'REFRESH.SUCCESS'; payload: User }
  | { type: 'REFRESH.ERROR' }
  | {
      type: 'START'
      payload: Partial<TokenConfiguration>
    }
  | { type: 'SIGN_OUT' }
  | { type: 'SIGN_OUT.SUCCESS' }
  | { type: 'SIGN_OUT.ERROR' }
  | { type: 'INVALIDATE' }

const isSSOEnabled = HAS_SSO
const isSSORedirect = SSO_REDIRECT

const action = (type: string, payload?: unknown) =>
  ({ type, payload }) as Action

const reducer = (state: State, action: Action): State => {
  switch (state.value) {
    case 'invalid':
      switch (action.type) {
        case 'SIGN_IN':
          return {
            value: 'pending.signInWithCredentials',
            context: { credentials: action.payload },
          }
      }
      break
    case 'pending.idle':
      switch (action.type) {
        case 'START':
          return {
            value: 'pending.refresh',
            context: { ...action.payload },
          }
      }
      break
    case 'valid':
      switch (action.type) {
        case 'REFRESH.SUCCESS':
          return {
            value: 'valid',
            context: { user: action.payload },
          }
        case 'SIGN_OUT':
          return {
            value: 'pending.signOut',
            context: {},
          }
        case 'INVALIDATE':
          return {
            value: 'invalid',
            context: {},
          }
      }
      break
    case 'pending.signInWithCredentials':
      switch (action.type) {
        case 'SIGN_IN.SUCCESS':
          return {
            value: 'pending.refresh',
            context: {},
          }
        case 'SIGN_IN.ERROR': {
          return {
            value: 'invalid',
            context: {
              errors: ['Something went wrong. Please try again.'],
            },
          }
        }
      }
      break
    case 'pending.refresh':
      switch (action.type) {
        case 'REFRESH.SUCCESS':
          return {
            value: 'valid',
            context: { user: action.payload },
          }
        case 'REFRESH.ERROR':
          return state.context.token && state.context.provider
            ? {
                value: 'pending.signInWithToken',
                context: {
                  token: state.context.token,
                  provider: state.context.provider,
                },
              }
            : {
                value: 'invalid',
                context: {},
              }
      }
      break
    case 'pending.signInWithToken':
      switch (action.type) {
        case 'SIGN_IN_TOKEN.SUCCESS':
          return {
            value: 'pending.refresh',
            context: {},
          }
        case 'SIGN_IN_TOKEN.ERROR':
          return {
            value: 'invalid',
            context: {
              errors: [
                `Unable to sign in with ${
                  state.context.provider === 'gr4vy'
                    ? 'Google Auth'
                    : 'SSO provider'
                }.`,
              ],
            },
          }
      }
      break
    case 'pending.signOut':
      switch (action.type) {
        case 'SIGN_OUT.SUCCESS':
          return {
            value: 'pending.refresh',
            context: {},
          }
        case 'SIGN_OUT.ERROR':
          return {
            value: 'pending.refresh',
            context: {},
          }
      }
      break
  }
  return { ...state }
}

export const useSessionManager = (
  defaultState: State = {
    value: 'pending.idle',
    context: {},
  }
) => {
  const [state, dispatch] = useReducer<typeof reducer>(reducer, defaultState)
  const { isAuthenticated, logout } = useAuth0()

  useQuery({
    queryKey: [GET_CURRENT_USER],
    queryFn: () =>
      getCurrentUser()
        .then((user) => {
          dispatch(action('REFRESH.SUCCESS', user))
          return user
        })
        .catch((error) => {
          dispatch(action('REFRESH.ERROR'))
          return Promise.reject(error)
        }),
    enabled: state.value === 'valid',
  })

  useEffect(() => {
    switch (state.value) {
      case 'pending.refresh':
        getCurrentUser()
          .then((user) => {
            dispatch(action('REFRESH.SUCCESS', user))
          })
          .catch(() => dispatch(action('REFRESH.ERROR')))
        break
      case 'pending.signInWithToken':
        createSession({
          token: state.context.token,
          provider: state.context.provider,
        })
          .then(() => dispatch(action('SIGN_IN_TOKEN.SUCCESS')))
          .catch(() => dispatch(action('SIGN_IN_TOKEN.ERROR')))
        break
      case 'pending.signOut':
        if (isSSOEnabled && isAuthenticated) {
          // clean SSO session (cookies)
          logout({ logoutParams: { returnTo: window.location.origin } })
        }
        endSession()
          .then(() => dispatch(action('SIGN_OUT.SUCCESS')))
          .catch(() => dispatch(action('SIGN_OUT.ERROR')))
        break
      case 'pending.signInWithCredentials':
        createSession(state.context.credentials)
          .then(() => dispatch(action('SIGN_IN.SUCCESS')))
          .catch(() => dispatch(action('SIGN_IN.ERROR')))
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value])

  return {
    isLoading: state.value.includes('pending.'),
    isSignedIn: state.value === 'valid',
    isSignedOut: state.value === 'invalid',
    user: state.value === 'valid' && state.context.user,
    isStaff: state.value === 'valid' && state.context.user?.isStaff,
    errors: state.value === 'invalid' ? state.context.errors : [],
    isSSOEnabled,
    signInPath: isSSORedirect ? '/sso' : '/sign-in',
    signIn: (credentials: Credentials) =>
      dispatch(action('SIGN_IN', credentials)),
    signOut: () => dispatch(action('SIGN_OUT')),
    invalidate: () => dispatch(action('INVALIDATE')),
    start: (payload?: Partial<TokenConfiguration>) =>
      dispatch(action('START', payload)),
  }
}
