import { User } from 'users/services/users'

export const getUserMerchantAccountIds = (user: User) =>
  user.merchantAccounts.map((merchantAccount) => merchantAccount.id)

export const getMerchantAccountOverCapture = (
  value: number,
  overCaptureAmount: number | null | undefined,
  overCapturePercentage: number | null | undefined
) => {
  const values: number[] = []
  if (overCaptureAmount === 0 || overCapturePercentage === 0) {
    return 0
  }
  if (overCaptureAmount) {
    values.push(overCaptureAmount + value)
  }
  if (overCapturePercentage) {
    values.push(value * (1 + overCapturePercentage / 100))
  }
  return Math.min(...values.filter((value) => !isNaN(value)))
}
