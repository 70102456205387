import { atoms, Flex, Heading, TextLine } from '@gr4vy/poutine-react'
import { dateFormat } from 'shared/helpers/date-format'
import { getCurrentLocale } from 'shared/helpers/locale'

interface HeaderProps {
  title: string
  createdAt?: string
}

export default function Header({ title, createdAt }: HeaderProps) {
  return (
    <Flex gap={16} alignItems="center">
      <Heading level={3} as="h1">
        {title}
      </Heading>
      {createdAt && (
        <Heading level={5} as="h2" className={atoms({ height: 16 })}>
          <TextLine as="span" color="gray80" fontWeight="normal">
            {dateFormat(createdAt ?? '', getCurrentLocale(), {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </TextLine>
        </Heading>
      )}
    </Flex>
  )
}
