import { Heading } from '@gr4vy/poutine-react'
import { Panel } from 'shared/components/Panel'
import { RefundsTable } from 'transactions/components/RefundsTable'
import { useTransactionRefundsData } from 'transactions/hooks/use-transaction-refunds-data'
import { PaymentMethod, Transaction } from 'transactions/services'

export interface RefundsPanelProps {
  transaction: Transaction
  paymentMethod?: PaymentMethod
}

const RefundsPanel = ({ transaction, paymentMethod }: RefundsPanelProps) => {
  const { tableData } = useTransactionRefundsData(transaction)

  if (!tableData?.length) {
    return null
  }

  return (
    <Panel>
      <Panel.Header>
        <Heading level={4}>Refunds</Heading>
      </Panel.Header>
      <Panel.Content>
        <RefundsTable refunds={tableData} paymentMethod={paymentMethod} />
      </Panel.Content>
    </Panel>
  )
}

export default RefundsPanel
