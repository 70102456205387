import { Box, Checkbox } from '@gr4vy/poutine-react'
import { Select, SelectProps } from 'antd'
import { uniqueId } from 'lodash'
import { useState } from 'react'

export const ConnectionSelect = ({
  options,
  value,
  onChange,
  ...rest
}: SelectProps) => {
  const id = uniqueId('all_')
  const [checked, setChecked] = useState(
    value && options && value.length === options.length
  )

  const handleToggleCheck = (isChecked: boolean) => {
    setChecked(isChecked)
    if (isChecked) {
      onChange?.(
        options?.map((option) => option.value),
        options || []
      )
    } else {
      onChange?.(undefined, [])
    }
  }

  return (
    <Select
      mode="multiple"
      onDeselect={() => setChecked(false)}
      onChange={onChange}
      value={value}
      dropdownRender={(menu) => (
        <>
          <Box
            onMouseDown={(e) => e.preventDefault()}
            htmlFor={id}
            style={{
              padding: '5px 12px',
            }}
          >
            <Checkbox
              id={id}
              onCheckedChange={handleToggleCheck}
              checked={checked}
            >
              Select all
            </Checkbox>
          </Box>
          {menu}
        </>
      )}
      options={options}
      {...rest}
    />
  )
}
