import { useState } from 'react'
import { ActionsDropdown } from 'connections/components/ActionsDropdown'
import { ModalRemove } from 'shared/components/Modal/ModalRemove'

type ConnectionActionsProps = {
  onDelete: () => void
  isReadOnlyPermission: boolean
}

export const ConnectionActions = ({
  onDelete,
  isReadOnlyPermission,
}: ConnectionActionsProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  return (
    <>
      <ActionsDropdown
        onDelete={() => setShowDeleteModal(true)}
        isReadOnlyPermission={isReadOnlyPermission}
      />
      {showDeleteModal && (
        <ModalRemove
          title="Delete connection"
          onCancel={() => setShowDeleteModal(false)}
          onOk={() => {
            onDelete()
            setShowDeleteModal(false)
          }}
        >
          You are about to delete this connection and this change is
          non-reversable. Are you sure you want to proceed?
        </ModalRemove>
      )}
    </>
  )
}
