import { ConfiguredConnection } from 'home/constants/connection'

const getMethodByDefinitionId = (definitionId: string) =>
  ({
    [definitionId]: { method: definitionId },
    apple: { method: 'applepay' },
    google: { method: ['googlepay', 'googlepay_pan_only'] },
    'click-to-pay': { method: 'click-to-pay' },
  })[definitionId]

export const getTransactionsFilterByConnection = (
  connection: ConfiguredConnection
): Record<string, unknown> => {
  if (connection.group === 'payment-service') {
    return {
      paymentServiceId: connection.id,
    }
  }

  if (connection.group === 'digital-wallet') {
    return getMethodByDefinitionId(connection.definitionId)
  }

  return {}
}
