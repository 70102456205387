import {
  Description,
  Flex,
  Text,
  TextLine,
  TextLink,
} from '@gr4vy/poutine-react'
import { capitalize } from 'lodash'
import { useParams } from 'react-router-dom'
import {
  ColumnDef,
  DataTable,
  DataTableProps,
  TimeDate,
} from 'shared/components/DataTable'
import { Status } from 'shared/components/Status'
import currencyFormat from 'shared/helpers/currency-format'
import { pathTo as pathToGiftCards } from 'shared/paths/gift-cards'
import { pathTo as pathToPaymentMethods } from 'shared/paths/payment-methods'
import { PaymentMethodDescription } from 'transactions/components/PaymentMethodDescription'
import { RefundData } from 'transactions/hooks/use-transaction-refunds-data'
import {
  GiftCardRedemption,
  PaymentMethod,
  Transaction,
} from 'transactions/services'

export interface RefundsTableProps extends DataTableProps<Transaction> {
  refunds: Array<RefundData>
  paymentMethod?: PaymentMethod
}

export type RefundItem = RefundData

const RefundsTable = ({ refunds, paymentMethod }: RefundsTableProps) => {
  const { merchantAccountId } = useParams()

  const columns: Array<ColumnDef<RefundItem>> = [
    {
      header: 'Method',
      size: 260,
      cell: function Number({ row }) {
        const transactionPaymentMethod = (
          row.original.targetType === 'payment-method'
            ? row.original.paymentMethod
            : null
        ) as PaymentMethod | null
        const giftCardRedemptions = (
          row.original.targetType === 'gift-card-redemption'
            ? [row.original.paymentMethod]
            : []
        ) as Array<GiftCardRedemption> | []

        const getPaymentUrl = () => {
          if (!!paymentMethod?.id && !!merchantAccountId) {
            return pathToPaymentMethods.paymentMethodId(
              merchantAccountId,
              paymentMethod.id
            )
          }

          if (!!giftCardRedemptions[0]?.giftCard.id && !!merchantAccountId) {
            return pathToGiftCards.giftCardId(
              merchantAccountId,
              giftCardRedemptions[0].giftCard.id
            )
          }

          return null
        }

        const paymentUrl = getPaymentUrl()

        return paymentUrl ? (
          <TextLink href={paymentUrl}>
            <PaymentMethodDescription
              paymentMethod={transactionPaymentMethod}
              giftCardRedemptions={giftCardRedemptions}
            />
          </TextLink>
        ) : (
          <PaymentMethodDescription
            paymentMethod={transactionPaymentMethod}
            giftCardRedemptions={giftCardRedemptions}
          />
        )
      },
    },
    {
      header: 'Reason',
      size: 369,
      cell: ({ row }) => <TextLine>{row.original.reason || '-'}</TextLine>,
    },
    {
      header: () => (
        <Text textAlign="right" marginRight={16}>
          Amount
        </Text>
      ),
      accessorKey: 'amount',
      size: 144,
      cell: ({ row }) => {
        const amount = currencyFormat(row.original.amount, {
          currency: row.original.currency,
          style: 'decimal',
        })

        return (
          <Flex marginRight={16} justifyContent="flex-end" gap={4}>
            <Description align="flex-end">
              <Description.Text
                style={{
                  fontVariantNumeric: 'tabular-nums',
                }}
              >
                {amount}
              </Description.Text>
              <Description.SubText>{row.original.currency}</Description.SubText>
            </Description>
          </Flex>
        )
      },
    },
    {
      header: 'Date/Time',
      size: 120,
      cell: ({ row }) => <TimeDate value={row.original.createdAt} />,
    },
    {
      header: 'Status',
      size: 240,
      cell: ({ row }) => (
        <Status value={row.original.status}>
          {capitalize(row.original.status)}
        </Status>
      ),
    },
  ]

  return <DataTable data={{ items: refunds }} columns={columns} />
}

export default RefundsTable
