import {
  Alert,
  Button,
  CurrencyInput,
  Flex,
  Icon,
  Modal,
} from '@gr4vy/poutine-react'
import { Form, FormRule } from 'antd'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePaymentServiceDefinition } from 'connections/hooks/use-payment-service-definition'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import currencyFormat from 'shared/helpers/currency-format'
import { getMerchantAccountOverCapture } from 'shared/helpers/merchant-account'
import { useMerchantAccounts } from 'shared/permissions/hooks/use-merchant-accounts'
import { showErrorMessage } from 'shared/utils/showMessage'
import { FormValues } from 'transactions/hooks/use-transaction-capture'
import { Transaction } from 'transactions/services'

export const ERROR_CAPTURE_REQUIRED = 'Please provide a capture amount'

interface FormProps {
  capturedAmount: number
}

interface ModalTransactionCaptureProps {
  loading: boolean
  transaction: Transaction
  onCapture: (values: FormValues) => void
  onClose: () => void
}

const ModalTransactionCapture = ({
  loading,
  transaction,
  onCapture,
  onClose,
}: ModalTransactionCaptureProps) => {
  const { merchantAccountId } = useParams() as {
    merchantAccountId: string
  }
  const { merchantAccounts } = useMerchantAccounts()
  const merchantAccount = merchantAccounts.find(
    (account) => account.id == merchantAccountId
  )
  const { amount, authorizedAmount, currency, paymentMethod, paymentService } =
    transaction
  const { paymentServiceDefinition } = usePaymentServiceDefinition(
    merchantAccountId,
    paymentService?.paymentServiceDefinitionId
  )

  const maxOverCapture = getMerchantAccountOverCapture(
    amount,
    merchantAccount?.overCaptureAmount,
    merchantAccount?.overCapturePercentage
  )

  const [form] = Form.useForm<FormProps>()
  const { submit } = form
  const initialValues: FormProps = {
    capturedAmount: paymentMethod ? authorizedAmount : amount,
  }
  const capturedAmountValue = Form.useWatch('capturedAmount', form)
  const [isFormValid, setIsFormValid] = useState(true)
  const formatAmount = useCallback(
    (value: number) => currencyFormat(value, { currency }),
    [currency]
  )

  const onFieldsChange = () => {
    setIsFormValid(!form.getFieldsError().some(({ errors }) => errors.length))
  }

  const onFinish = (values: FormProps) => {
    onCapture({ amount: values.capturedAmount })
  }

  const onFinishFailed = () => {
    showErrorMessage('Please check the form for any errors before continuing.')
  }

  const handleBlur = (value: any) => {
    form.setFieldsValue({ capturedAmount: value })
  }

  const conditionalDescriptionPart = paymentMethod
    ? 'Enter the amount you want to capture. '
    : ''

  const overCaptureSupported =
    paymentServiceDefinition?.supportedFeatures.overCapture

  const rules: FormRule[] = [
    { required: true, message: ERROR_CAPTURE_REQUIRED },
  ]

  if (!!paymentMethod) {
    rules.push({
      type: 'number',
      min: 1,
      message: 'Amount must be larger than 0.',
    })
  }

  if (!overCaptureSupported && !!paymentMethod) {
    rules.push({
      type: 'number',
      max: authorizedAmount,
      message: `Amount must be less than or equal to the authorized amount of ${currencyFormat(
        authorizedAmount,
        { currency }
      )}.`,
    })
  }

  if (overCaptureSupported && maxOverCapture === 0) {
    rules.push({
      type: 'number',
      max: authorizedAmount,
      message: `Amount must be less than or equal to ${currencyFormat(authorizedAmount, { currency })}.`,
    })
  } else if (overCaptureSupported && maxOverCapture) {
    rules.push({
      type: 'number',
      max: maxOverCapture,
      message: `Amount must be less than or equal to ${currencyFormat(maxOverCapture, { currency })}.`,
    })
  }

  return (
    <Modal
      id="capture"
      icon={<Icon name="circle-check" size="large" color="blue60" />}
      title="Capture transaction"
      description={
        conditionalDescriptionPart +
        `You can only capture a
            transaction once, even if the amount captured was not for the full
            authorized amount.`
      }
      open
      onOpenChange={(status) => {
        if (!status) {
          onClose()
          form.resetFields()
        }
      }}
      closeBtn={false}
    >
      <Form
        form={form}
        layout="vertical"
        onFieldsChange={onFieldsChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={initialValues}
        requiredMark={false}
        hidden={!paymentMethod}
      >
        <FormItem
          label={<Label>Capture amount</Label>}
          name="capturedAmount"
          rules={rules}
          shouldUpdate
        >
          <CurrencyInput currency={currency} onBlur={handleBlur} />
        </FormItem>
      </Form>
      {isFormValid && capturedAmountValue < authorizedAmount && (
        <Alert variant="information">
          <Alert.Content>
            <Alert.Title>
              You entered a smaller amount than authorized
            </Alert.Title>
            <Alert.Text>
              If you capture {formatAmount(capturedAmountValue)}, the rest of
              the authorized amount of{' '}
              {formatAmount(authorizedAmount - capturedAmountValue)} will be
              voided and can therefore not be captured later.
            </Alert.Text>
          </Alert.Content>
        </Alert>
      )}
      {isFormValid &&
        capturedAmountValue > authorizedAmount &&
        overCaptureSupported && (
          <Alert variant="notice">
            <Alert.Content>
              <Alert.Title>
                You entered a larger amount than authorized
              </Alert.Title>
              <Alert.Text>
                If you capture this amount, you will be charging more than the
                amount originally authorized. Support for over capturing will
                depend on the payment service used as well as your account
                configuration.
              </Alert.Text>
            </Alert.Content>
          </Alert>
        )}
      {!paymentMethod && (
        <Alert variant="notice">
          <Alert.Icon />
          <Alert.Content>
            <Alert.Text>
              This transaction was processed using gift cards only. Capturing
              this transaction will move the transaction into a captured state
              but will not perform any call to a payment service provider.
            </Alert.Text>
          </Alert.Content>
        </Alert>
      )}
      <Flex justifyContent="flex-end" gap={16}>
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={submit}
          loading={loading}
          loadingText="Capturing"
        >
          Capture
        </Button>
      </Flex>
    </Modal>
  )
}

export default ModalTransactionCapture
