import { Heading, Stack, Text } from '@gr4vy/poutine-react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import {
  PAYMENT_METHODS,
  PAYOUT,
} from 'shared/constants/query-keys/transactions'
import currencyFormat from 'shared/helpers/currency-format'
import { dateFormatHeader } from 'shared/helpers/date-format'
import { getPaymentMethod, getPayout } from 'transactions/services'
import View from './PayoutDetailsPage'

const PayoutDetailsPage = () => {
  const { payoutID, merchantAccountId } = useParams<{
    payoutID: string
    merchantAccountId: string
  }>()
  const payout = useQuery({
    queryKey: [PAYOUT, payoutID, merchantAccountId],
    queryFn: () => getPayout(payoutID as string),
    meta: {
      error: {
        redirect: true,
      },
    },
  })
  const paymentMethodId = payout.data?.paymentMethod?.id
  const paymentMethod = useQuery({
    queryKey: [PAYMENT_METHODS, paymentMethodId, merchantAccountId],
    queryFn: () => getPaymentMethod(paymentMethodId as string),
    enabled: !!paymentMethodId,
    meta: {
      error: {
        ignore: [404],
      },
    },
  })

  const amount =
    currencyFormat(
      payout.data?.amount || 0,
      { currency: payout.data?.currency || 'USD', style: 'decimal' },
      'en'
    ) || 'Unknown'

  return (
    <View
      payout={payout.data}
      paymentMethod={paymentMethod.data}
      title={`${amount} ${payout.data?.currency}`}
      titleChildren={
        <Heading level={3} as="h1">
          <Stack direction="row" gap={8}>
            <Text as="span">{amount}</Text>
            <Text as="span" color="gray80" fontWeight="semibold">
              {payout.data?.currency}
            </Text>
          </Stack>
        </Heading>
      }
      titleSubChildren={
        <Text as="span" color="gray80" fontWeight="semibold">
          {dateFormatHeader(payout.data?.createdAt)}
        </Text>
      }
    />
  )
}

export default PayoutDetailsPage
