import { is } from 'shared/helpers/is'
import { BuyerBillingDetails } from 'transactions/services'

export const hasBillingDetails = (billingDetails?: BuyerBillingDetails) => {
  if (billingDetails) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { taxId, type, address, ...rest } = billingDetails

    return !Boolean(
      Object.values(rest)
        .concat(Object.values(address ? address : {}))
        .flat(1)
        .every(is.null)
    )
  } else {
    return false
  }
}
