import {
  Stack,
  Text,
  TextLink,
  Grid,
  GridItem,
  Checkbox,
  atoms,
} from '@gr4vy/poutine-react'
import { Input } from 'antd'
import { snakeCase } from 'lodash'
import { useState } from 'react'
import { AddConnectionForm } from 'connections/components/AddConnectionForm/AddConnectionForm'
import { CredentialsChecker } from 'connections/components/CredentialsChecker'
import {
  AntiFraudService,
  AntiFraudServiceDefinition,
  validateAntiFraudService,
} from 'connections/services'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'

type FormValues = {
  displayName: string
  merchantId: string
  merchantKeyId: string
  merchantSecretKey: string
  active: boolean
}

interface Props {
  saving: boolean
  definition: AntiFraudServiceDefinition
  onSubmit: (
    body: Omit<AntiFraudService, 'id' | 'type' | 'createdAt' | 'updatedAt'> & {
      fields: { key: string; value: string }[]
    }
  ) => void
  onCancel: () => void
}

export const Cybersource = ({
  saving,
  definition,
  onSubmit,
  onCancel,
}: Props) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    displayName: definition.displayName,
    merchantId: '',
    merchantKeyId: '',
    merchantSecretKey: '',
    active: false,
  })
  const handleSubmit = ({ active, displayName, ...fields }: FormValues) => {
    setInitialValues({ active, displayName, ...fields })
    onSubmit({
      active,
      displayName,
      antiFraudServiceDefinitionId: 'cybersource-anti-fraud',
      fields: Object.keys(fields)
        .map((key) => ({
          key: snakeCase(key),
          value: fields[key as keyof typeof fields],
        }))
        .filter((entry) => !!entry.value),
    })
  }

  return (
    <AddConnectionForm
      saving={saving}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      title="Add new anti-fraud connection"
    >
      <Grid>
        <GridItem gridColumn="span 6">
          <Stack gap={24}>
            <FormItem
              name="merchantId"
              label={<Label>Merchant ID</Label>}
              required
              rules={[{ required: true, message: 'This field is required' }]}
              extra={
                <Text
                  as="span"
                  className={atoms({ fontSize: 'xs', paddingTop: 4 })}
                >
                  This is the{' '}
                  <TextLink
                    href="https://support.cybersource.com/knowledgebase/Knowledgearticle/?code=000002872"
                    opensInNewWindow
                  >
                    unique identifier
                  </TextLink>{' '}
                  of your Cybersource account.
                </Text>
              }
            >
              <Input />
            </FormItem>
            <FormItem
              name="transactionKey"
              label={<Label>SOAP Toolkit Key</Label>}
              required
              rules={[{ required: true, message: 'This field is required' }]}
              extra={
                <Text
                  as="span"
                  className={atoms({ fontSize: 'xs', paddingTop: 4 })}
                >
                  This is the{' '}
                  <TextLink
                    href="https://support.cybersource.com/knowledgebase/Knowledgearticle/?code=000002769"
                    opensInNewWindow
                  >
                    API key
                  </TextLink>{' '}
                  for accessing the SOAP API.
                </Text>
              }
            >
              <Input />
            </FormItem>
            <FormItem
              name="orgId"
              label={<Label>ThreatMetrix Org ID</Label>}
              rules={[{ required: true, message: 'This field is required' }]}
              required
              extra={
                <Text
                  as="span"
                  className={atoms({ fontSize: 'xs', paddingTop: 4 })}
                >
                  The organization ID for use with ThreatMetrix device
                  fingerprints. Please contact your Cybersource support
                  representative for this value.
                </Text>
              }
            >
              <Input />
            </FormItem>
            <CredentialsChecker
              fieldNames={['merchantId', 'transactionKey']}
              verifyCredentials={validateAntiFraudService}
              antiFraudServiceDefinitionId="cybersource-anti-fraud"
            />
          </Stack>
        </GridItem>
      </Grid>
      <FormItem hidden={true} name="active">
        <Checkbox>Active</Checkbox>
      </FormItem>
    </AddConnectionForm>
  )
}
