import { Badge, Flex, Heading, Stack } from '@gr4vy/poutine-react'
import { Summary } from 'shared/components/Summary'
import { getCurrentLocale } from 'shared/helpers/locale'
import EventsConnection from 'transactions/components/EventsPanel/EventsConnection'
import { ECIInfo } from 'transactions/components/ThreeDSecurePanel/ECIInfo'
import { TransactionEvent } from 'transactions/services'
import styles from './styles.module.scss'

export const DigitalWalletGooglePayTokenDecryptedEvent = ({
  event,
}: {
  event?: TransactionEvent
}) => {
  return event ? (
    <Stack gap={32}>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Digital Wallet
        </Heading>
        <EventsConnection event={event} />
      </Stack>
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Instrument
        </Heading>
        <Stack gap={8} alignItems="flex-start" direction="row">
          <Badge variant="subtle" color="neutral">
            {event.context['version']}
          </Badge>
          <Badge
            variant="subtle"
            color={
              event.context['type'] === 'dpan' ? 'positive' : 'information'
            }
          >
            {event.context['type']
              ? event.context['type'].toUpperCase()
              : 'Unknown'}
          </Badge>
          {event.context['type'] === 'dpan' && (
            <Badge
              variant="subtle"
              color={event.context['hasCryptogram'] ? 'positive' : 'negative'}
            >
              {event.context['hasCryptogram'] ? 'Cryptogram' : 'No cryptogram'}
            </Badge>
          )}
        </Stack>
      </Stack>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Details
        </Heading>
        <Summary className={styles.summary} inline>
          <Summary.Key>Instrument</Summary.Key>
          <Summary.Value fallback={!event.context['paymentMethod']}>
            {event.context['paymentMethod']}
          </Summary.Value>
          <Summary.Key>Expiration</Summary.Key>
          <Summary.Value fallback={!event.context['expirationDate']}>
            {event.context['expirationDate']}
          </Summary.Value>
          <Summary.Key>ECI</Summary.Key>
          <Summary.Value>
            <Flex gap={4}>
              {event.context['eci'] ?? '-'}
              <ECIInfo eci={event.context['eci']} />
            </Flex>
          </Summary.Value>
        </Summary>
      </Stack>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Message
        </Heading>
        <Summary className={styles.summary} inline>
          <Summary.Key>ID</Summary.Key>
          <Summary.Value fallback={!event.context['messageId']}>
            {event.context['messageId']}
          </Summary.Value>
          <Summary.Key>Expiration</Summary.Key>
          <Summary.Value fallback={!event.context['messageExpiration']}>
            {new Date(
              parseInt(event.context['messageExpiration'])
            ).toLocaleString(getCurrentLocale(), {
              weekday: 'short',
              day: '2-digit',
              month: 'short',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              timeZoneName: 'short',
            })}
          </Summary.Value>
        </Summary>
      </Stack>
    </Stack>
  ) : null
}
