import { Box, Button, Flex, Icon, RadioGroup } from '@gr4vy/poutine-react'
import { BorderWidthSm, ColorGray20 } from '@gr4vy/poutine-tokens/js/tokens'
import { DatePicker } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker/interface'
import dayjs, { Dayjs } from 'dayjs'
import { compact } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import styles from './DateTimeRangePicker.module.scss'
import { FilterRange, RADIO_FILTER_RANGES } from './dateTimeFilter'
import { getDateRangeByFilter } from './get-date-by-range'

const { RangePicker } = DatePicker

type DateTimeRangePickerProps = RangePickerProps<Dayjs> & {
  popupClassName?: string
  onChange?: (value: [Dayjs?, Dayjs?] | undefined) => void
}

const defaultShowTime = {
  defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')],
}

const inputValueToMoment = (value: string | undefined) => {
  if (dayjs(value).isValid()) {
    return dayjs(value)
  }
  return undefined
}

const getInputValues = (
  datePickerEl: HTMLElement | null
): [Dayjs?, Dayjs?] | undefined => {
  if (datePickerEl) {
    const [fromInputEl, toInputEl] = Array.from(
      datePickerEl.querySelectorAll('.ant-picker-input input')
    ) as [HTMLInputElement, HTMLInputElement]
    return [
      inputValueToMoment(fromInputEl?.value),
      inputValueToMoment(toInputEl?.value),
    ]
  }
  return undefined
}

export const DateTimeRangePicker = ({
  value,
  onChange,
  ...rangePickerProps
}: DateTimeRangePickerProps) => {
  const getSelectedFilter = (): FilterRange => {
    const [selected] = Object.values(FilterRange).filter((filterRange) => {
      const [rangeFrom, rangeTo] = getDateRangeByFilter(filterRange) || []
      const [valueFrom, valueTo] = getInputValues(rangePickerRef.current) || []
      return (
        rangeFrom?.valueOf() === valueFrom?.valueOf() &&
        rangeTo?.valueOf() === valueTo?.endOf('day').valueOf()
      )
    })
    return selected || FilterRange.CUSTOM
  }
  const rangePickerRef = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(rangePickerProps.open)
  const [selectedFilter, setSelectedFilter] =
    useState<FilterRange>(getSelectedFilter())
  const handleClearButton = () => {
    onChange?.(undefined)
    setSelectedFilter(FilterRange.CUSTOM)
  }
  const handleApplyButton = () => {
    const nextValue = compact([getInputValues(rangePickerRef.current)].flat())
    onChange?.(
      nextValue.length === 2
        ? getInputValues(rangePickerRef.current)
        : undefined
    )
    setOpen(false)
  }
  const handleChangeRangeFilter = (filter: FilterRange) => {
    setSelectedFilter(filter)
    if (filter !== FilterRange.CUSTOM) {
      onChange?.(getDateRangeByFilter(filter))
    }
  }
  useEffect(() => {
    if (open) {
      setSelectedFilter(getSelectedFilter())
    }
  }, [open])

  return (
    <div ref={rangePickerRef}>
      <RangePicker
        allowClear={false}
        open={open}
        value={value}
        showTime={defaultShowTime}
        popupClassName={styles.dropdown}
        panelRender={(panelEl) => {
          return (
            <Flex gap={48} padding={24}>
              <RadioGroup
                value={selectedFilter}
                onValueChange={(value) =>
                  handleChangeRangeFilter(value as FilterRange)
                }
                gap={8}
              >
                {RADIO_FILTER_RANGES.map((filter) => (
                  <RadioGroup.Item
                    key={filter.value}
                    id={filter.value}
                    value={filter.value}
                  >
                    {filter.label}
                  </RadioGroup.Item>
                ))}
              </RadioGroup>
              <Box style={{ border: `${BorderWidthSm} solid ${ColorGray20}` }}>
                {panelEl}
              </Box>
            </Flex>
          )
        }}
        suffixIcon={<Icon name="calendar" size="small" color="black" />}
        className={styles.datePicker}
        renderExtraFooter={() => (
          <Flex gap={8} justifyContent="flex-end" padding={8}>
            <Button
              variant="secondary"
              size="small"
              onClick={handleClearButton}
              disabled={!value}
            >
              Clear
            </Button>
            <Button size="small" onClick={handleApplyButton}>
              Apply
            </Button>
          </Flex>
        )}
        onOpenChange={setOpen}
        {...rangePickerProps}
      />
    </div>
  )
}
