import {
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  Box,
  Skeleton,
} from '@gr4vy/poutine-react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'shared/paths/users'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import NewUserPageLayout from 'users/components/NewUserPageLayout/NewUserPageLayout'
import { User } from 'users/services/users'
interface UpdatedUserNamePageProps {
  title: string
  user?: User
  loading: boolean
}

const HeaderLoader = (
  <>
    <Skeleton width={32}>
      <Skeleton.Box height={32} />
    </Skeleton>
    <Flex flexDirection="column" gap={4}>
      <Skeleton gap={4}>
        <Skeleton.Box style={{ maxWidth: 320 }} height={24} />
      </Skeleton>
    </Flex>
  </>
)

const Loader = (
  <Skeleton width={160} height={20}>
    <Skeleton.Box />
  </Skeleton>
)

export default function UpdatedUserNamePage({
  title,
  user,
  loading,
}: UpdatedUserNamePageProps) {
  const navigate = useNavigate()
  const hasUserReadPermission = useResourcePermission(
    Resource.users,
    AccessLevel.read
  )

  return (
    <NewUserPageLayout title={title} step="INVITE">
      {loading ? (
        HeaderLoader
      ) : (
        <>
          <Icon name="circle-check" size="large" color="green60" />
          <Heading as="h4">Profile successfully updated</Heading>
        </>
      )}
      <Stack gap={16}>
        <Box>
          <Text variant="reg3" color="black" fontWeight="bold">
            Name
          </Text>
          {loading && !user ? (
            Loader
          ) : (
            <Text variant="reg3" color="gray90">
              {user?.name}
            </Text>
          )}
        </Box>
        <Box>
          <Text variant="reg3" color="black" fontWeight="bold">
            Email
          </Text>
          {loading && !user ? (
            Loader
          ) : (
            <Text variant="reg3" color="gray90">
              {user?.emailAddress}
            </Text>
          )}
        </Box>
      </Stack>
      <Flex gap={16}>
        {hasUserReadPermission && (
          <Button onClick={() => navigate(pathTo.users)}>See all users</Button>
        )}
        {user && (
          <Button
            variant="secondary"
            onClick={() => navigate(pathTo.user(user.id))}
          >
            See user details
          </Button>
        )}
      </Flex>
    </NewUserPageLayout>
  )
}
