import { Flex, Box } from '@gr4vy/poutine-react'
import { useTransactionPaymentMethod } from 'transactions/hooks/use-transaction-payment-methods'
import {
  PaymentMethod,
  GiftCardRedemption,
} from 'transactions/services/transactions'
import { GiftCardMaskedNumber } from './GiftCardMaskedNumber'
import styles from './MaskedCardNumber.module.scss'
import { PaymentLabel } from './PaymentLabel'
import { PaymentMethodMaskedNumber } from './PaymentMethodMaskedNumber'

export interface MaskedCardNumberProps {
  paymentMethod: PaymentMethod | null
  giftCardRedemptions?: GiftCardRedemption[]
}

export function MaskedCardNumber({
  paymentMethod,
  giftCardRedemptions = [],
}: MaskedCardNumberProps) {
  const { isSinglePaymentMethod, cardNumber } = useTransactionPaymentMethod({
    paymentMethod,
    giftCardRedemptions,
  })
  const isGiftCardNumber = !!(cardNumber.last4 && cardNumber.subBin)

  const renderMaskedPaymentMethodNumber = () => {
    if (isSinglePaymentMethod) {
      return isGiftCardNumber ? (
        <GiftCardMaskedNumber cardNumber={cardNumber} />
      ) : (
        <PaymentMethodMaskedNumber
          paymentMethod={paymentMethod}
          cardNumber={cardNumber}
        />
      )
    }

    const label = cardNumber.last4 || paymentMethod?.label
    return (
      <Box className={styles.wrapper}>
        <Box as="span" display="inline" className={styles.mask}>
          {isGiftCardNumber ? '•••••• ' : '•••• '}
        </Box>
        <PaymentLabel label={label} />
      </Box>
    )
  }

  return <Flex alignItems="center">{renderMaskedPaymentMethodNumber()}</Flex>
}

export default MaskedCardNumber
