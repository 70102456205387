export enum Flow {
  checkout = 'checkout',
  cardTransactions = 'card-transaction',
  nonCardTransactions = 'non-card-transaction',
  redirectTransactions = 'redirect-transaction',
}

export enum Action {
  selectPaymentOption = 'select-payment-options',
  routeTransaction = 'route-transaction',
  declineEarly = 'decline-early',
  skip3ds = 'skip-3ds',
}
