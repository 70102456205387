import { Navigate, useParams } from 'react-router-dom'
import { routes as auditLogRoutes } from 'audit-logs'
import { APILogsBrowsePage, KeysBrowsePage } from 'integrations/pages'
import { routes as resetRoutes } from 'reset'
import { Route } from 'shared/constants/routes'
import { routes as usersRoutes } from 'users'
import EditMerchantAccountNetworkTokensPage from './pages/EditMerchantAccountNetworkTokensPage'
import EditMerchantAccountOverCapturePage from './pages/EditMerchantAccountOverCapturePage'
import EditMerchantAccountPage from './pages/EditMerchantAccountPage'
import EditMerchantAccountUpdaterPage from './pages/EditMerchantAccountUpdaterPage'
import EditMerchantAccountVaultForwardingAuthenticationPage from './pages/EditMerchantAccountVaultForwardingAuthenticationPage'
import EditMerchantAccountVaultForwardingPage from './pages/EditMerchantAccountVaultForwardingPage'
import EditMerchantAccountWebhooksPage from './pages/EditMerchantAccountWebhookPage'
import MerchantsAccountsPage from './pages/MerchantsAccountsPage'
import {
  pathTo,
  EDIT_MERCHANT_PATH,
  EDIT_MERCHANT_OVERVIEW_PATH,
  EDIT_MERCHANT_NETWORK_TOKENS_PATH,
  EDIT_MERCHANT_VAULT_FORWARDING_PATH,
  EDIT_WEBHOOK_PATH,
  EDIT_ACCOUNT_UPDATER_PATH,
  EDIT_OVER_CAPTURE_PATH,
  EDIT_MERCHANT_VAULT_FORWARDING_AUTHENTICATION_PATH,
} from './paths'

const Default = () => <Navigate replace to={pathTo.merchants} />

export const adminRoutes: Route[] = [
  {
    title: 'Merchants',
    key: 'admin-merchants',
    path: pathTo.merchants,
    component: MerchantsAccountsPage,
    menu: true,
  },
  ...usersRoutes,
  ...auditLogRoutes,
  {
    title: 'Edit Merchant',
    key: 'admin-merchant-overview',
    path: EDIT_MERCHANT_OVERVIEW_PATH,
    component: EditMerchantAccountPage,
    menu: false,
  },
  {
    title: 'Edit Merchant',
    key: 'admin-merchant',
    path: EDIT_MERCHANT_PATH,
    component: function EditMerchant() {
      const { merchantAccountId } = useParams() as { merchantAccountId: string }

      return <Navigate replace to={pathTo.merchant(merchantAccountId)} />
    },
    menu: false,
  },
  {
    title: 'Edit Network Tokens',
    key: 'admin-merchant-network-tokens',
    path: EDIT_MERCHANT_NETWORK_TOKENS_PATH,
    component: EditMerchantAccountNetworkTokensPage,
    menu: false,
  },
  {
    title: 'Edit Vault Forward Endpoints',
    key: 'admin-merchant-network-tokens',
    path: EDIT_MERCHANT_VAULT_FORWARDING_PATH,
    component: EditMerchantAccountVaultForwardingPage,
    menu: false,
  },
  {
    title: 'Edit Vault Forward Authentication',
    key: 'admin-merchant-vault-forwarding-authentication',
    path: EDIT_MERCHANT_VAULT_FORWARDING_AUTHENTICATION_PATH,
    component: EditMerchantAccountVaultForwardingAuthenticationPage,
    menu: false,
  },
  {
    title: 'Edit Webhooks',
    key: 'admin-merchant-webhooks',
    path: EDIT_WEBHOOK_PATH,
    component: EditMerchantAccountWebhooksPage,
    menu: false,
  },
  {
    title: 'Edit Account Updater',
    key: 'admin-merchant-account-updater',
    path: EDIT_ACCOUNT_UPDATER_PATH,
    component: EditMerchantAccountUpdaterPage,
    menu: false,
  },
  {
    title: 'Edit Over-capture',
    key: 'admin-merchant-over-capture',
    path: EDIT_OVER_CAPTURE_PATH,
    component: EditMerchantAccountOverCapturePage,
    menu: false,
  },
  {
    title: 'Integrations',
    key: 'admin-integrations-keys',
    path: pathTo.apiKeys,
    menu: false,
    component: KeysBrowsePage,
  },
  {
    title: 'Integrations',
    key: 'admin-integrations-logs',
    path: pathTo.apiLogs,
    menu: false,
    component: APILogsBrowsePage,
  },
  {
    title: 'Admin',
    key: 'admin',
    path: pathTo.admin,
    menu: false,
    component: Default,
  },
  ...resetRoutes,
]
