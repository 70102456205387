import { matchPath, Navigate, Outlet } from 'react-router-dom'
import { pathTo } from 'shared/paths/errors'
import { useMultiMerchant } from 'shared/permissions/hooks/use-multi-merchant'

export const AdminRoute = () => {
  const { canAccessAdminMode } = useMultiMerchant()

  // allow user to access /admin/users/me/*
  const isAllowedPath = !!matchPath(
    {
      path: '/admin/users/me',
      end: false,
    },
    location.pathname
  )

  return canAccessAdminMode || isAllowedPath ? (
    <Outlet />
  ) : (
    <Navigate to={pathTo.accessDenied} replace />
  )
}
