import { Action, Flow } from 'shared/constants'

export enum OutcomeType {
  BOOLEAN = 'boolean',
  ACTION = 'action',
}

export type PaymentActionOutcome = {
  type: OutcomeType.ACTION
  actionType: 'payment-option'
  id: string
  label: string
  active: boolean
  iconUrl?: string
  countries?: string[]
  currencies?: string[]
}

export type TransactionActionOutcome = {
  type: OutcomeType.ACTION
  actionType: 'transaction-route'
  id: string
  label: string
  active: boolean
  iconUrl?: string
  countries: string[]
  currencies: string[]
  method?: string
  displayName: string
  instruments: OutcomeInstrument[]
  transformations: OutcomeTransformation[]
  networkTokensEnabled: boolean
  networkTokensSetupForMerchant: boolean
  openLoop: boolean
}

export type ActionOutcome = PaymentActionOutcome | TransactionActionOutcome

export type BooleanOutcome = {
  actionType: OutcomeType.BOOLEAN
  name: string
  value: boolean
}

export type Outcome = ActionOutcome | BooleanOutcome

// v2
export enum OutcomeTransformationName {
  FORCE_MIT = 'force_mit',
  USE_ADDITIONAL_SCHEME = 'use_additional_scheme',
}
export type OutcomeTransformation = {
  name: OutcomeTransformationName
}
export enum OutcomeInstrument {
  PAN = 'pan',
  NETWORK_TOKEN = 'network_token',
}
export type OutcomeResult = {
  paymentServiceId: string
  instrument: OutcomeInstrument
  transformations: OutcomeTransformation[]
}

export const DELETED_CONNECTION = 'Deleted Connection'

export type OutcomeFormValues = {
  paymentServiceIndex?: number
  paymentServiceId: string
  instrument: OutcomeInstrument
  transformationsForceMit: boolean
  transformationsUseAdditionalScheme: boolean
}

export const outcomeSectionConfig = {
  [Flow.checkout]: {
    [Action.selectPaymentOption]: {
      title: 'Outcome: Payment Options',
      text: `Choose which payment options are shown to your customer when this rule is
      triggered. Payment options will be displayed in the order below and are
      filtered based on the checkout country and currency.`,
    },
  },
  [Flow.cardTransactions]: {
    [Action.routeTransaction]: {
      title: 'Outcome: Route to connection',
      text: `Choose which connections are used when a transaction is processed.
      They will be used in the order below and are filtered based on the transaction country
      and currency. If all connections are unable to process the transaction,
      it will fail and a new transaction will be required.`,
      textV2:
        'Choose which connections to use for the transaction. Each connection will be attempted in order until the transaction succeeds or fails with all connections.',
    },
    [Action.declineEarly]: {
      title: 'Outcome: Decline or continue transaction',
      text: 'Choose whether to decline or continue the transaction given all of the conditions above are met.',
    },
    [Action.skip3ds]: {
      title: 'Outcome: 3-D Secure',
      text: `Choose to attempt, skip, or force 3-D Secure given all of the conditions above are matched. When forcing 3DS,
      the transaction is declined if 3DS could not performed. In contrary, attempting 3DS will
      continue the transaction in case the card could not be authenticated with 3DS.`,
    },
  },
  [Flow.redirectTransactions]: {
    [Action.routeTransaction]: {
      title: 'Outcome: Route to connection',
      text: `Choose which connections are used when a transaction is processed.
      They will be used in the order below and are filtered based on the transaction country
      and currency. If all connections are unable to process the transaction,
      it will fail and a new transaction will be required.`,
    },
  },
}

export type OutcomeSectionConfig = {
  title: string
  text: string
  textV2?: string
}
