import qs from 'qs'
import { Cursor } from 'shared/hooks'
import client, { CollectionResponse } from 'shared/services/client'

export interface ReportExecutionPayload {
  id: string
}

export interface DownloadReportExecutionPayload {
  reportId: string
  executionId: string
}

export interface ReportExecution {
  report: {
    type: 'report'
    id: string
    name: string
    creatorType: 'user' | 'private_key' | null
    creatorId: string | null
    creatorDisplayName: string | null
  }
  type: 'report-execution'
  id: string
  createdAt: string
  updatedAt: string
  status: 'dispatched' | 'failed' | 'pending' | 'processing' | 'succeeded'
}

export interface ReportExecutionsFilters {
  limit: number
  cursor: Cursor
  reportId?: string
  reportName?: string
  status?: ReportExecution['status'] | ReportExecution['status'][]
  createdAtGte?: string
  createdAtLte?: string
}

export const getReportExecutions = async ({
  reportId,
  ...params
}: Partial<ReportExecutionsFilters> = {}): Promise<
  CollectionResponse<ReportExecution>
> => {
  const { data } = await client.get(
    reportId ? `/reports/${reportId}/executions` : '/report-executions',
    {
      params,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat', encode: false }),
    }
  )
  return data
}

export const getReportExecution = async ({
  id,
}: ReportExecutionPayload): Promise<ReportExecution> => {
  const { data } = await client.get(`/report-executions/${id}`)
  return data
}

export const getDownloadReportExecutionUrl = async ({
  reportId,
  executionId,
}: DownloadReportExecutionPayload): Promise<{ url: string }> => {
  const { data } = await client.post(
    `/reports/${reportId}/executions/${executionId}/url`
  )
  return data
}
