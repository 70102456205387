import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import styles from './SubNavigationLink.module.scss'

export type SubNavigationLinkProps = {
  path: string
  title: string
  end?: boolean
}

export const SubNavigationLink = ({
  path,
  title,
  end = false,
}: SubNavigationLinkProps) => (
  <NavLink
    end={end}
    className={({ isActive }) =>
      clsx(styles.tabBarLink, {
        [styles.selected]: isActive,
      })
    }
    to={path}
    title={title}
  >
    {title}
  </NavLink>
)
