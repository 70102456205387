import { RuleObject } from 'antd/es/form'
import { fullyQualifiedDomainNamePattern } from 'shared/constants/regex-patterns'

export const fqdnValidator = (_: RuleObject, value: any) => {
  if (value && !new RegExp(fullyQualifiedDomainNamePattern, 'i').test(value)) {
    return Promise.reject(
      'This field must be a valid fully qualified domain name'
    )
  }
  return Promise.resolve()
}
