import { GridItem, Stack } from '@gr4vy/poutine-react'
import { ReactNode } from 'react'

type ItemProps = Parameters<typeof GridItem>[0] & {
  children: ReactNode
}

function Item({ children, ...rest }: ItemProps) {
  return (
    <GridItem as={Stack} gap={8} {...rest}>
      {children}
    </GridItem>
  )
}

export default Item

Item.displayName = 'SummaryGrid.Item'
