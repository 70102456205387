import { Badge, Flex, Heading, Stack } from '@gr4vy/poutine-react'
import { Fragment } from 'react'
import { Summary } from 'shared/components/Summary'
import { DirectoryResponseInfo } from 'transactions/components/ThreeDSecurePanel/DirectoryResponseInfo'
import { ECIInfo } from 'transactions/components/ThreeDSecurePanel/ECIInfo'
import { TransactionEvent } from 'transactions/services'
import { RequestEvent } from './RequestEvent'
import styles from './styles.module.scss'

export const ThreeDSecureAuthenticationRequestEvent = ({
  event,
}: {
  event?: TransactionEvent
}) => {
  return event ? (
    <Stack gap={32}>
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Flow
        </Heading>
        <Flex gap={8}>
          <Badge variant="subtle" color="neutral">
            {event.context['version']}
          </Badge>{' '}
          {event.context['directoryResponse'] === 'Y' && (
            <Badge variant="subtle" color="positive">
              Frictionless
            </Badge>
          )}
          {event.context['directoryResponse'] === 'C' && (
            <Badge variant="subtle" color="notice">
              Challenge
            </Badge>
          )}
        </Flex>
      </Stack>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Result
        </Heading>
        <Summary className={styles.summary} inline>
          <Summary.Key>Status</Summary.Key>
          <Summary.Value fallback={!event.context['directoryResponse']}>
            <Flex gap={4}>
              {event.context['directoryResponse']}
              <DirectoryResponseInfo
                code={event.context['directoryResponse']}
                version="2"
              />
            </Flex>
          </Summary.Value>
          {event.context['eci'] && (
            <Fragment>
              <Summary.Key>ECI</Summary.Key>
              <Summary.Value>
                <Flex gap={4}>
                  {event.context['eci']}
                  <ECIInfo eci={event.context['eci']} />
                </Flex>
              </Summary.Value>
            </Fragment>
          )}
          {event.context['cavv'] && (
            <Fragment>
              <Summary.Key>CAVV</Summary.Key>
              <Summary.Value>{event.context['cavv']}</Summary.Value>
            </Fragment>
          )}
          <Summary.Key>Transaction ID</Summary.Key>
          <Summary.Value fallback={!event.context['directoryTransactionId']}>
            {event.context['directoryTransactionId']}
          </Summary.Value>
        </Summary>
      </Stack>
      <RequestEvent event={event} />
    </Stack>
  ) : null
}
