import { Dropdown } from '@gr4vy/poutine-react'
import { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'shared/paths/users'
import {
  AccessLevel,
  Resource,
  useResourcePermissions,
} from 'shared/permissions'
import { useMultiMerchant } from 'shared/permissions/hooks/use-multi-merchant'
import { REMOVE_USER } from 'users/constants'
import { UserModalContext } from 'users/contexts/UserModalContext'
import { isSelectedUserAnAdmin } from 'users/helpers'
import { useLoggedInUser } from 'users/hooks/use-logged-in-user'
import { useResetPassword } from 'users/hooks/use-reset-password'
import { useUseManager } from 'users/hooks/use-use-manager'
import { User } from 'users/services/users'

interface UsersActions {
  user: User
}

const UsersActions = ({ user }: UsersActions) => {
  const { openUserModal } = useContext(UserModalContext)
  const { isLoggedInUser } = useLoggedInUser()
  const { regenerateInviteLink } = useResetPassword()
  const navigate = useNavigate()
  const [hasUserWritePermission, hasMeWritePermission] = useResourcePermissions(
    [Resource.users, Resource.me],
    AccessLevel.write
  )
  const { hasMultipleMerchantAccounts } = useMultiMerchant()
  const { isUserManager } = useUseManager()

  const hasWritePermission = isLoggedInUser(user)
    ? hasMeWritePermission
    : hasUserWritePermission

  const userMerchantAccountIds = useMemo(
    () => user.merchantAccounts.map((merchantAccount) => merchantAccount.id),
    [user.merchantAccounts]
  )

  return (
    <Dropdown>
      <Dropdown.Trigger asChild>
        <Dropdown.Button variant="primary">Actions</Dropdown.Button>
      </Dropdown.Trigger>
      <Dropdown.Content align="end">
        <Dropdown.Item
          opensInModal
          onSelect={() =>
            navigate(
              pathTo.editUser.name(isLoggedInUser(user) ? 'me' : user.id)
            )
          }
          disabled={!hasWritePermission || user.category === 'sso'}
        >
          Edit name
        </Dropdown.Item>
        {!isLoggedInUser(user) && (
          <Dropdown.Item
            opensInModal
            onSelect={() =>
              navigate(
                isUserManager
                  ? pathTo.editUser.roles(user.id, userMerchantAccountIds)
                  : pathTo.editUser.accessType(user.id)
              )
            }
            disabled={user.category === 'sso'}
          >
            Edit roles
          </Dropdown.Item>
        )}
        {hasMultipleMerchantAccounts && !isSelectedUserAnAdmin(user) && (
          <Dropdown.Item
            opensInModal
            onSelect={() => navigate(pathTo.editUser.merchantAccount(user.id))}
            disabled={
              isLoggedInUser(user) ||
              (isSelectedUserAnAdmin(user) && isUserManager) ||
              user.category === 'sso'
            }
          >
            Edit merchants
          </Dropdown.Item>
        )}
        <Dropdown.Item
          opensInModal
          onSelect={() => openUserModal(user, REMOVE_USER)}
          disabled={isLoggedInUser(user) || !hasWritePermission}
        >
          Remove user
        </Dropdown.Item>
        <Dropdown.Item
          opensInModal
          onSelect={() => regenerateInviteLink(user)}
          disabled={!hasWritePermission || user.category === 'sso'}
        >
          Reset password
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown>
  )
}

export default UsersActions
