import {
  Button,
  ButtonLink,
  Drawer,
  Flex,
  Stack,
  Text,
} from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { pathTo } from 'connections'
import { useSettlementReport } from 'reports/hooks/use-settlement-report'
import { LoadingSupportedConnections } from './LoadingSupportedConnections'
import { NoConnections } from './NoConnections'
import { NoSupportedConnections } from './NoSupportedConnections'
import { ScheduleAlert } from './ScheduleAlert'
import { SupportedConnections } from './SupportedConnections'

export interface SettlementReportDrawerProps {
  open: boolean
  onClose: () => void
}

export const SettlementReportDrawer = ({
  open,
  onClose,
}: SettlementReportDrawerProps) => {
  const { merchantAccountId } = useParams<{
    merchantAccountId: string
  }>()
  const { connections, schedule, settlement } = useSettlementReport()

  const resetAndClose = () => {
    schedule.reset()
    onClose()
  }

  const onSchedule = () =>
    settlement.report
      ? schedule.update({
          id: settlement.report.id,
          scheduleEnabled: true,
        })
      : schedule.create({
          name: 'Settlement',
          schedule: 'daily',
          scheduleEnabled: true,
          scheduleTimezone: 'UTC',
          spec: {
            model: 'detailed_settlement',
            params: {
              filters: {
                ingestedAt: {
                  start: 'day_start',
                  end: 'day_end',
                },
              },
            },
          },
        })

  return (
    <Drawer
      open={open}
      title="Settlement reporting"
      size="sm"
      onClose={resetAndClose}
      footer={
        <Flex gap={8} justifyContent="flex-end">
          {schedule.isSuccess ||
          !!settlement.report?.scheduleEnabled ||
          !connections.hasSupportedConnections ? (
            <Button type="button" onClick={resetAndClose}>
              Close
            </Button>
          ) : (
            <>
              <Button type="button" variant="secondary" onClick={resetAndClose}>
                Cancel
              </Button>
              <Button
                type="button"
                loading={schedule.isPending}
                loadingText="Scheduling"
                onClick={onSchedule}
              >
                Schedule
              </Button>
            </>
          )}
        </Flex>
      }
    >
      <Stack gap={32}>
        {(schedule.isSuccess || schedule.isError) && (
          <ScheduleAlert variant={schedule.isSuccess ? 'success' : 'notice'} />
        )}
        {(connections.isLoading || settlement.isLoading) && (
          <LoadingSupportedConnections />
        )}
        {connections.isSuccess && settlement.isSuccess && connections.data && (
          <>
            {!connections.hasUnsupportedConnections &&
              !connections.hasSupportedConnections && <NoConnections />}
            {!!connections.hasUnsupportedConnections &&
              !connections.hasSupportedConnections && (
                <NoSupportedConnections />
              )}
            {!!connections.hasSupportedConnections && (
              <SupportedConnections
                connections={connections.data.supportedConnections}
                merchantAccountId={merchantAccountId}
              />
            )}
            {!!connections.hasUnsupportedConnections &&
              !!connections.hasSupportedConnections && (
                <Text variant="med3">
                  All your other configured connections are not currently
                  supported for settlement reporting.
                </Text>
              )}
          </>
        )}
        {merchantAccountId && (
          <Flex gap={16}>
            <ButtonLink
              variant="secondary"
              href={pathTo.connectionsCatalog(merchantAccountId)}
            >
              Manage connections
            </ButtonLink>
            <ButtonLink
              variant="secondary"
              href="https://docs.gr4vy.com/guides/dashboard/reports/settlement/overview"
              opensInNewWindow
              isExternal
            >
              Documentation
            </ButtonLink>
          </Flex>
        )}
      </Stack>
    </Drawer>
  )
}
