import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { TRANSACTIONS } from 'shared/constants/query-keys'
import { is } from 'shared/helpers/is'
import { useCursor, useFilters } from 'shared/hooks'
import { TransactionFilters } from 'shared/services/transactions'
import { listTransactions } from 'transactions/services'
import View from './TransactionsBrowsePage'

interface TransactionBrowsePageProps {
  title: string
}

const TransactionBrowsePage = ({ title }: TransactionBrowsePageProps) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const [filters, setFilters] = useFilters<TransactionFilters>()
  const [cursor] = useCursor()

  const page = useQuery({
    queryKey: [TRANSACTIONS, merchantAccountId, cursor, filters],
    queryFn: () => listTransactions({ cursor, ...filters }),
    meta: {
      error: {
        redirect: true,
      },
    },
    enabled: !is.emptyObject(filters),
  })

  const updateFilter = (f: TransactionFilters) => {
    setFilters({ ...filters, ...f })
  }

  return (
    <View title={title} page={page} filters={filters} onFilter={updateFilter} />
  )
}

export default TransactionBrowsePage
