import { Button, Flex, Modal, Stack, Text } from '@gr4vy/poutine-react'
import React from 'react'

interface Props {
  onViewCertificates: () => void
  onCancel: () => void
}

export const ActiveAppleCertificateModal = ({
  onViewCertificates,
  onCancel,
}: Props) => (
  <Modal open title="Active Apple Certificate" type="information">
    <Stack gap={24}>
      <Text>
        This Apple Pay Connection cannot be deleted while it still has active
        certificates. In order to delete this Apple Pay connection, first remove
        the certificates.
      </Text>
      <Flex justifyContent="flex-end">
        <Button variant="secondary" marginRight={16} onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onViewCertificates}>
          View Certificates
        </Button>
      </Flex>
    </Stack>
  </Modal>
)
