import { Alert, AlertProps } from '@gr4vy/poutine-react'

export interface ScheduleAlertProps {
  variant: Extract<AlertProps['variant'], 'success' | 'notice'>
}

export const ScheduleAlert = ({ variant }: ScheduleAlertProps) => (
  <Alert variant={variant} padding={16}>
    <Alert.Content>
      {variant === 'success' ? (
        <>
          <Alert.Title>Schedule started successfully!</Alert.Title>
          <Alert.Text>
            The schedule is fixed and will run daily at 2AM UTC.
          </Alert.Text>
        </>
      ) : (
        <>
          <Alert.Title>Unable to start the settlement schedule</Alert.Title>
          <Alert.Text>Please try again.</Alert.Text>
        </>
      )}
    </Alert.Content>
  </Alert>
)
