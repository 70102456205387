import { useConnections } from './use-connections'
import { useCreateReport, useReports, useUpdateReport } from './use-report'

export const useSettlementReport = () => {
  const {
    data: connections,
    isLoading: isLoadingConnections,
    isSuccess: isSuccessConnections,
    hasSupportedConnections,
    hasUnsupportedConnections,
  } = useConnections()

  const {
    mutate: create,
    isPending: isScheduling,
    isSuccess: isScheduleSucceeded,
    isError: isScheduleFailed,
    reset: resetCreate,
  } = useCreateReport()

  const {
    mutate: update,
    isPending: isUpdatingSchedule,
    isSuccess: isUpdateScheduleSucceeded,
    isError: isUpdateScheduleFailed,
    reset: resetUpdate,
  } = useUpdateReport()

  const reset = () => {
    resetCreate()
    resetUpdate()
  }

  const isAddOrUpdatePending = isScheduling || isUpdatingSchedule
  const isAddOrUpdateSucceeded =
    isScheduleSucceeded || isUpdateScheduleSucceeded
  const isAddOrUpdateFailed = isScheduleFailed || isUpdateScheduleFailed

  const settlement = useReports(
    {
      limit: 1,
      schedule: 'daily',
      name: 'Settlement',
    },
    {
      select: (data) => data.items.at(0),
    }
  )

  return {
    connections: {
      data: connections,
      isLoading: isLoadingConnections,
      isSuccess: isSuccessConnections,
      hasSupportedConnections,
      hasUnsupportedConnections,
    },
    schedule: {
      create,
      update,
      reset,
      isPending: isAddOrUpdatePending,
      isSuccess: isAddOrUpdateSucceeded,
      isError: isAddOrUpdateFailed,
    },
    settlement: {
      report: settlement.data,
      isLoading: settlement.isLoading,
      isSuccess: settlement.isSuccess,
    },
  }
}
