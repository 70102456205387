import {
  GiftCardRedemption,
  PaymentMethod,
  Transaction,
} from 'transactions/services'

export const getPaymentMethodKey = (
  item: PaymentMethod | GiftCardRedemption
) => {
  if (item.type === 'payment-method') {
    return item.method
  }

  return item.id
}

export const getPaymentMethodByKey = (
  key: string | null,
  transaction: Transaction
) => {
  if (transaction.paymentMethod && transaction.paymentMethod.method === key) {
    return transaction.paymentMethod
  }

  return transaction.giftCardRedemptions.find(
    (giftCardRedemption) => giftCardRedemption.id === key
  )
}
