import { Flex, Heading, Skeleton, Stack, Text } from '@gr4vy/poutine-react'
import * as RadixTabs from '@radix-ui/react-tabs'
import { ReactNode } from 'react'
import styles from './Trigger.module.scss'

export type TriggerProps = RadixTabs.TabsTriggerProps & {
  title: ReactNode
  amount: string
  trend: ReactNode
  loading: boolean
}

export const TabLoading = () => (
  <Stack gap={8} marginY={4} justifyContent="center">
    <Skeleton width={80}>
      <Skeleton.Box borderRadius="rounded" height={20} />
    </Skeleton>
    <Skeleton width="full">
      <Skeleton.Box borderRadius="rounded" height={24} />
    </Skeleton>
  </Stack>
)

export const Trigger = ({
  title,
  amount,
  trend,
  loading,
  ...rest
}: TriggerProps) => {
  return (
    <RadixTabs.Trigger
      className={styles.trigger}
      data-loading={loading}
      {...rest}
    >
      {loading ? (
        <TabLoading />
      ) : (
        <Stack gap={4} marginY={4}>
          <Text variant="med2" as="span">
            {title}
          </Text>
          <Flex gap={8} alignItems="center">
            <Heading level={4} className={styles.truncate}>
              {amount}
            </Heading>
            {trend}
          </Flex>
        </Stack>
      )}
      <span className={styles.arrow} />
    </RadixTabs.Trigger>
  )
}
