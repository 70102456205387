import { useEffect, useMemo, useState } from 'react'
import { additionalPermissionNames } from 'users/constants'
import { getPermissionRoleIds } from 'users/helpers'
import { User } from 'users/services/users'
import { useRoles } from './use-roles'

export const useEditRoles = (user?: User | undefined) => {
  const { administratorRoleId, nonAdministratorRoles } = useRoles()
  const [permissionRoleIds, setPermissionRoleIds] = useState<string[]>([])
  const [roleIds, setRoleIds] = useState<string[]>([])

  const rolesWithAdditionalPermissions = useMemo(() => {
    if (!user?.roles) {
      return nonAdministratorRoles.filter((role) =>
        ['Analyst', 'Customer Support'].includes(role.name)
      )
    }

    const userRoles = user.roles.filter(
      (role) => !additionalPermissionNames.includes(role.name)
    )

    const formattedNonAdministratorRoles = nonAdministratorRoles
      .map((role) => {
        const userRole = userRoles.find(
          (userRole) => userRole.name === role.name
        )
        if (userRole) {
          return { ...role, id: userRole.id }
        }

        return role
      })
      .filter((role) => ['Analyst', 'Customer Support'].includes(role.name))

    return formattedNonAdministratorRoles
  }, [nonAdministratorRoles, user?.roles])

  const hasUserPermissionRoles = !!getPermissionRoleIds(user?.roles ?? [])
    .length

  useEffect(() => {
    if (user?.roles) {
      setPermissionRoleIds(getPermissionRoleIds(user.roles))
      setRoleIds(
        user.roles
          .filter(
            (userRole) =>
              !additionalPermissionNames.includes(userRole.name) &&
              userRole.id !== administratorRoleId
          )
          .map((userRole) => userRole.id)
      )
    }
  }, [administratorRoleId, user])

  return {
    roleIds,
    setRoleIds,
    permissionRoleIds,
    setPermissionRoleIds,
    hasUserPermissionRoles,
    rolesWithAdditionalPermissions,
  }
}
