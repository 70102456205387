import { Grid, GridItem } from '@gr4vy/poutine-react'
import { ConfiguredGroups } from 'connections/components/Configured/ConfiguredGroups'
import { useConfiguredSearch } from 'connections/hooks/use-configured-search'
import { Connection } from 'connections/services'
import { SearchBar } from 'shared/components/SearchBar/SearchBar'
import { ConnectionsConfiguredPageNav } from './ConnectionsConfiguredPageNav'
import { NoSearchResults } from './NoSearchResults'

export const ConnectionsConfiguredPage = ({
  connections,
}: {
  connections: Connection[]
}) => {
  const { searchTerm, search, configured } = useConfiguredSearch(connections)

  return (
    <Grid rowGap={48}>
      <GridItem gridColumn="span 6">
        <SearchBar
          searchTerm={searchTerm}
          itemsFoundCount={configured.count ?? 0}
          search={search}
        />
      </GridItem>
      <GridItem gridColumn="span 6" />

      {(configured.count ?? 0) === 0 && (
        <>
          <GridItem gridColumn="span 6">
            {' '}
            <NoSearchResults clearSearch={search} />
          </GridItem>
          <GridItem gridColumn="span 3" />
        </>
      )}
      {(configured.count ?? 0) > 0 && (
        <GridItem gridColumn="span 9">
          <ConfiguredGroups configured={configured} />
        </GridItem>
      )}
      <GridItem gridColumn="span 3" height="full">
        <ConnectionsConfiguredPageNav configured={configured} />
      </GridItem>
    </Grid>
  )
}
