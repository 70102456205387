import { AccessLevel, Resource } from 'shared/permissions/constants'
import { useResourcePermission } from 'shared/permissions/hooks/use-resource-permission'

export const useReportsPermission = () => {
  const hasWritePermission = useResourcePermission(
    Resource.reports,
    AccessLevel.write
  )

  return { hasWritePermission }
}
