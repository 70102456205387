import { env } from 'shared/constants/env'

const getRelativeBaseUrl = () => {
  const loc = document?.location
  return `${loc?.protocol}//api.${loc?.host}`
}

export const apiBaseUrl =
  env.REACT_APP_API_URL && env.REACT_APP_API_URL !== 'false'
    ? env.REACT_APP_API_URL
    : getRelativeBaseUrl()
