import { Heading } from '@gr4vy/poutine-react'
import { UseQueryResult } from '@tanstack/react-query'
import AuditLogEntriesTable from 'audit-logs/components/AuditLogEntriesTable'
import { auditLogColumns } from 'audit-logs/components/AuditLogEntriesTable/AuditLogEntriesTable'
import { Filters } from 'audit-logs/pages/AuditLogsBrowsePage/AuditLogsBrowsePage'
import { AuditLogEntry } from 'audit-logs/services'
import { Panel } from 'shared/components/Panel'
import { CollectionResponse } from 'shared/services/client'

interface ActivityPanelProps {
  page: UseQueryResult<CollectionResponse<AuditLogEntry>, any>
  filters: Filters | Partial<Filters>
  onFilter: (filter: Filters) => void
}

export const ActivityPanel = ({
  page,
  filters,
  onFilter,
}: ActivityPanelProps) => {
  const userLogColumns = auditLogColumns.map((column) => {
    if (column.header === 'User') {
      return { ...column, size: 280 }
    }

    if (column.header === 'Action') {
      return { ...column, size: 136 }
    }

    if (column.header === 'Date/Time') {
      return { ...column, size: 144 }
    }

    return column
  })

  return (
    <Panel>
      <Panel.Header>
        <Heading level={4}>Activity</Heading>
      </Panel.Header>
      <Panel.Content>
        <AuditLogEntriesTable
          page={page}
          pagination={{ filters, onFilter }}
          columns={userLogColumns}
        />
      </Panel.Content>
    </Panel>
  )
}
