import {
  Alert,
  Button,
  Flex,
  Grid,
  GridItem,
  Stack,
} from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { Form, Input, Select, SelectProps } from 'antd'
import { debounce } from 'lodash'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MerchantPagesLayout } from 'admin/components/MerchantsPageLayout'
import { pathTo } from 'admin/paths'
import { CredentialField } from 'shared/components/CredentialField/CredentialField'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { is } from 'shared/helpers/is'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'
import {
  MerchantAccount,
  UpdateMerchantAccount,
} from 'shared/services/merchant-accounts'

interface EditMerchantAccountUpdaterPageProps {
  title: string
  merchantAccount: MerchantAccount
  loading: boolean
  update: UseMutationResult<
    MerchantAccount,
    any,
    UpdateMerchantAccount,
    unknown
  >
  schemes: SelectProps['options']
}

const getBreadcrumbs = (displayName: string) => [
  { title: 'Merchants', url: pathTo.merchants },
  { title: displayName },
]

const REDACTED_VALUE = '********'

export const REQUIRED_CLIENT_KEY_ERROR = 'Client Key is required.'
export const REQUIRED_SECRET_KEY_ERROR = 'Secret Key is required.'

const EditMerchantAccountUpdaterPage = ({
  title,
  merchantAccount,
  loading,
  update,
  schemes,
}: EditMerchantAccountUpdaterPageProps) => {
  const [form] = Form.useForm<UpdateMerchantAccount>()
  const debouncedValidation = debounce(form.validateFields, 200)
  const loonClientKey = Form.useWatch('loonClientKey', form)
  const loonSecretKey = Form.useWatch('loonSecretKey', form)
  const loonAcceptedSchemes = Form.useWatch('loonAcceptedSchemes', form)
  const navigate = useNavigate()

  const preparePayload = (values: UpdateMerchantAccount) => {
    return {
      ...values,
      loonAcceptedSchemes:
        !loonClientKey && !loonSecretKey ? null : loonAcceptedSchemes,
    }
  }

  const onFinish = async (values: UpdateMerchantAccount) => {
    await form.validateFields()
    update.mutate(preparePayload(values), {
      onSuccess: () => form.resetFields(),
    })
  }

  const onBeforeFinish = () => {
    if (
      !!loonClientKey &&
      (is.null(loonSecretKey) || is.undefined(loonSecretKey))
    ) {
      form.setFieldValue('loonSecretKey', '')
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      id: merchantAccount.id,
      loonClientKey: merchantAccount.loonClientKey,
      loonSecretKey:
        merchantAccount.loonSecretKey === REDACTED_VALUE
          ? undefined
          : merchantAccount.loonSecretKey,
      loonAcceptedSchemes: merchantAccount.loonAcceptedSchemes || [],
    })
  }, [form, merchantAccount])

  const onClear = () => {
    form.resetFields()
    form.setFieldValue('loonSecretKey', '')
  }

  return (
    <MerchantPagesLayout
      title={title}
      breadcrumbs={getBreadcrumbs(merchantAccount.displayName)}
      isLoading={loading}
    >
      <Grid>
        <GridItem gridColumn="span 4">
          <Stack gap={32}>
            <Form
              name="update-account-updater"
              form={form}
              initialValues={{
                id: merchantAccount.id,
                loonClientKey: null,
                loonSecretKey: null,
                loonAcceptedSchemes: [],
              }}
              onFinish={onFinish}
              onSubmitCapture={onBeforeFinish}
              autoComplete="off"
              layout="vertical"
              requiredMark={false}
              onBlur={() => debouncedValidation()}
            >
              <Stack gap={24}>
                <FormItem name="id" hidden>
                  <Input type="hidden" />
                </FormItem>
                <FormItem
                  name="loonClientKey"
                  label={<Label>Client Key</Label>}
                  rules={[
                    {
                      required:
                        (!loonClientKey &&
                          (!!loonSecretKey || is.undefined(loonSecretKey))) ||
                        !!loonAcceptedSchemes?.length,

                      message: REQUIRED_CLIENT_KEY_ERROR,
                    },
                  ]}
                >
                  <Input />
                </FormItem>
                <CredentialField
                  item={{
                    key: 'loonSecretKey',
                    displayName: <Label>Secret Key</Label>,
                  }}
                  onEdit={(isEditing) => {
                    form.setFieldValue(
                      'loonSecretKey',
                      isEditing ? '' : undefined
                    )
                  }}
                  required={
                    (!!loonClientKey && !loonSecretKey) ||
                    !!loonAcceptedSchemes?.length
                  }
                  requiredMessage={REQUIRED_SECRET_KEY_ERROR}
                  secret={
                    !!merchantAccount.loonSecretKey &&
                    (is.null(loonSecretKey) || is.undefined(loonSecretKey))
                  }
                />
                <FormItem
                  label={<Label>Schemes</Label>}
                  name="loonAcceptedSchemes"
                >
                  <Select
                    data-testid="loonAcceptedSchemes"
                    mode="multiple"
                    allowClear
                    placeholder="Select or type a scheme"
                    maxTagCount="responsive"
                    maxTagPlaceholder={maxTagPlaceholderFormatter}
                    showSearch
                    options={schemes}
                  />
                </FormItem>
                <Alert variant="notice" width="readable">
                  <Alert.Icon />
                  <Alert.Content>
                    <Alert.Text>
                      Leaving this field empty will result in all schemes being
                      disabled for account updates.
                    </Alert.Text>
                  </Alert.Content>
                </Alert>
              </Stack>
            </Form>
            <Flex gap={16}>
              <Button
                form="update-account-updater"
                loading={update.isPending}
                variant="primary"
              >
                Update
              </Button>
              <Button
                variant="secondary"
                disabled={update.isPending}
                onClick={onClear}
              >
                Clear
              </Button>
              <Button
                variant="tertiary"
                disabled={update.isPending}
                onClick={() => navigate(pathTo.merchants)}
              >
                Cancel
              </Button>
            </Flex>
          </Stack>
        </GridItem>
      </Grid>
    </MerchantPagesLayout>
  )
}

export default EditMerchantAccountUpdaterPage
