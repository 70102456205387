import { Flex, Stack, Text, TextLink, atoms } from '@gr4vy/poutine-react'
import { connectionIds, getConnectionById } from 'admin/helpers/getting-started'
import { pathTo } from 'connections'
import { Step } from './Step'

export interface ConnectionStepProps {
  merchantAccountId: string
}

export const ConnectionStep = ({ merchantAccountId }: ConnectionStepProps) => (
  <Step title="Getting started">
    <Stack gap={16}>
      <Text variant="med2">
        Begin by adding your first connection with the defaults below or browse
        our complete{' '}
        <TextLink
          href={pathTo.connectionsCatalog(merchantAccountId)}
          variant="standalone"
        >
          connections catalog
        </TextLink>
        .
      </Text>
      <ConnectionsRow merchantAccountId={merchantAccountId} />
      <Text variant="med2">
        Next,{' '}
        <Text as="span" fontWeight="bold">
          Generate an API key
        </Text>
        ,{' '}
        <Text as="span" fontWeight="bold">
          Integrate using our SDKs
        </Text>{' '}
        and finally,{' '}
        <Text as="span" fontWeight="bold">
          Create your first transaction
        </Text>
      </Text>
    </Stack>
  </Step>
)

export interface ConnectionProps {
  merchantAccountId: string
}

export const ConnectionsRow = ({ merchantAccountId }: ConnectionProps) => (
  <Flex gap={24}>
    {connectionIds.map((connectionId) => {
      const { iconUrl, name } = getConnectionById(connectionId)
      return (
        <TextLink
          key={connectionId}
          href={pathTo.addConnectionPaymentService(
            merchantAccountId,
            connectionId
          )}
          variant="standalone"
        >
          <Flex gap={8} alignItems="center">
            <img className={atoms({ height: 32, width: 32 })} src={iconUrl} />
            {name}
          </Flex>
        </TextLink>
      )
    })}
  </Flex>
)
