import { Alert, Grid, GridItem } from '@gr4vy/poutine-react'
import { Form, Input, Select } from 'antd'
import {
  RULE_ERROR_CODE_PREFIX,
  RuleConfig,
  RuleConfigOutcome,
} from 'flows/constants'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'

interface OutcomeBooleanProps {
  outcomeConfig: RuleConfigOutcome
  hasErrorCode: RuleConfig['hasErrorCode']
}

export const OutcomeBoolean = ({
  outcomeConfig,
  hasErrorCode,
}: OutcomeBooleanProps) => {
  const form = Form.useFormInstance()
  const formOutcomeResult = Form.useWatch(['outcome', 'result'])
  const errorCode = Form.useWatch('errorCode')
  const showErrorCode = hasErrorCode && formOutcomeResult

  const handleChange = ({ target }: { target: HTMLInputElement }) => {
    const sanitizedValue = target.value.replace(/[^a-z_]/g, '')
    form.setFieldsValue({ errorCode: sanitizedValue })
  }

  return (
    <>
      <Grid>
        <GridItem gridColumn="span 3">
          <FormItem name={['outcome', 'type']} hidden>
            <Input />
          </FormItem>
          <FormItem
            name={['outcome', 'result']}
            label={<Label>Select outcome</Label>}
          >
            <Select data-testid="outcomeBoolean">
              <Select.Option value={true} data-testid="outcomeBooleanTrue">
                {outcomeConfig.values?.true}
              </Select.Option>
              <Select.Option value={false} data-testid="outcomeBooleanFalse">
                {outcomeConfig.values?.false}
              </Select.Option>
            </Select>
          </FormItem>
        </GridItem>
        {showErrorCode && (
          <GridItem gridColumn="span 4">
            <FormItem
              name="errorCode"
              label={
                <Label>
                  Custom error code (lower case letters & underscores only)
                </Label>
              }
            >
              <Input
                onChange={handleChange}
                data-testid="outcomeBooleanErrorCode"
                maxLength={250}
              />
            </FormItem>
          </GridItem>
        )}
      </Grid>
      {showErrorCode && (
        <Alert
          marginTop={8}
          gap={8}
          padding="none"
          style={{ backgroundColor: 'transparent' }}
        >
          <Alert.Icon size="medium" />
          <Alert.Content>
            <Alert.Text>
              A transaction declined by this rule will have its{' '}
              <strong>error_code</strong> set to{' '}
              <strong>
                {RULE_ERROR_CODE_PREFIX}
                {errorCode || 'decline'}
              </strong>
            </Alert.Text>
          </Alert.Content>
        </Alert>
      )}
    </>
  )
}
