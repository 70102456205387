import qs from 'qs'
import { GiftCardService } from 'connections/services'
import client, { CollectionResponse } from 'shared/services/client'
import { TransactionFilters } from 'shared/services/transactions'
import { GiftCard } from 'transactions/services'
import { Buyer } from './buyers'

export interface BuyerGiftCard extends Omit<GiftCard, 'id'> {
  id: string
  bin: string
  subBin: string
  last4: string
  expirationDate: string | null
  createdAt: string
  updatedAt: string
  merchantAccountId: string
  buyer: Buyer
  giftCardService: GiftCardService
}

export const getGiftCardsForBuyer = async (
  params: Partial<TransactionFilters> = {}
): Promise<CollectionResponse<BuyerGiftCard>> => {
  if (params.buyerId === '') {
    return {
      items: [],
      limit: 0,
    }
  }

  const response = await client.get('/gift-cards', {
    params,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat', encode: false }),
  })

  return response.data
}
