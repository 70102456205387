import { Badge, Heading, Stack } from '@gr4vy/poutine-react'
import EventsConnection from 'transactions/components/EventsPanel/EventsConnection'
import { getColorByDecision } from 'transactions/helpers'
import { TransactionEvent } from 'transactions/services'
import { RequestEvent } from './RequestEvent'

export const AntiFraudDecisionEvent = ({
  event,
}: {
  event: TransactionEvent
}) => {
  return event ? (
    <Stack gap={32}>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Connection
        </Heading>
        <EventsConnection event={event} />
      </Stack>
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Decision
        </Heading>
        <Badge
          variant="subtle"
          color={getColorByDecision(event.context['decision'])}
        >
          {event.context['decision']}
        </Badge>
      </Stack>
      <RequestEvent event={event} />
    </Stack>
  ) : null
}
