import { Stack, Box, Heading } from '@gr4vy/poutine-react'
import { ReactNode } from 'react'

const getNumberBackground = (highlighted: boolean, selected: boolean) => {
  if (selected) {
    return highlighted ? 'blue60' : 'black'
  }
  return 'gray70'
}

export const RulePanel = ({
  number,
  title,
  body,
  id,
  selected = false,
  highlighted = false,
  paddingBottom = 24,
  children,
}: {
  number: number
  title: ReactNode | string
  body: ReactNode
  id?: string
  selected?: boolean
  highlighted?: boolean
  paddingBottom?: 'none' | 8 | 16 | 24 | 32 | 40 | 48 | 56 | 64 | 72 | 80
  children?: ReactNode
}) => (
  <Stack id={id} direction="row">
    <Box height="auto" paddingX={32} paddingTop={24}>
      <Box
        borderRadius="circle"
        background={getNumberBackground(highlighted, selected)}
        color="white"
        width={24}
        height={24}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        {number}
      </Box>
      <Box
        style={{
          width: '12px',
          height: 'calc(100% - 24px)',
          borderRight: '2px solid #E8EBEE',
        }}
      />
    </Box>
    <Stack
      gap={32}
      direction="column"
      paddingX={32}
      paddingTop={24}
      paddingBottom={paddingBottom}
      width="full"
      background={highlighted ? 'gray10' : 'white'}
      borderRadius="rounded"
      style={{ overflow: 'hidden' }}
    >
      <Stack gap={8}>
        <Heading>{title}</Heading>
        {body}
      </Stack>
      {children}
    </Stack>
  </Stack>
)
