import {
  Tooltip,
  Flex,
  Button,
  Description,
  ButtonLink,
  Icon,
  Tag,
  atoms,
  Box,
} from '@gr4vy/poutine-react'
import { differenceInHours } from 'date-fns'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ReportExecutionStatus } from 'reports/components/ReportExecutionStatus'
import { ReportStatusFilter } from 'reports/components/ReportStatusFilter'
import { UsersFilter } from 'reports/components/UsersFilter'
import {
  isReportCustomizable,
  isReportDownloadable,
} from 'reports/helpers/report'
import { useDownloadExecutionUrl } from 'reports/hooks/use-report-execution'
import { ReportExecution } from 'reports/services/report-execution'
import {
  ColumnDef,
  DataTable,
  DateTime,
  DataTableProps,
} from 'shared/components/DataTable'
import { pathTo } from 'shared/paths/reports'
import { QueryResult } from 'shared/services/client'
import ReportDateTimeFilter from '../ReportDateTimeFilter'
import styles from './ReportExecutionsTable.module.scss'

export type ReportExecutionsTableProps = Pick<
  DataTableProps<ReportExecution>,
  'pagination'
> & {
  page: QueryResult<ReportExecution>
  panel?: boolean
}

export const ReportExecutionsTable = ({
  page,
  panel,
  pagination,
}: ReportExecutionsTableProps) => {
  const { data, isLoading: loading } = page
  const { mutate } = useDownloadExecutionUrl()
  const handleDownloadClick = (data: ReportExecution) => {
    mutate(
      { executionId: data.id, reportId: data.report.id },
      {
        onSuccess: ({ url }) => {
          window.open(url, '_blank')
        },
      }
    )
  }
  const columns: Array<ColumnDef<ReportExecution>> = useMemo(
    () => [
      {
        accessorKey: 'createdAt',
        size: 138,
        cell: function DatetimeCell({ getValue }) {
          const createdAt = getValue<ReportExecution['createdAt']>()
          return <DateTime value={createdAt} />
        },
        header: () => (
          <Box className={styles.filter}>
            <ReportDateTimeFilter label="Date" />
          </Box>
        ),
      },
      {
        accessorKey: 'report.name',
        size: panel ? 357 : 421,
        cell: function DatetimeCell({ getValue, row }) {
          const createdAt = row.original.createdAt
          const name = getValue<ReportExecution['report']['name']>()
          const isNew =
            Math.abs(differenceInHours(new Date(), new Date(createdAt))) <= 24
          const isFullyCustomizable = isReportCustomizable(name)
          return (
            <Flex gap={16} alignItems="center">
              {isNew && (
                <Flex className={atoms({ height: 20 })} alignItems="center">
                  <Tag variant="subtle" color="information">
                    New
                  </Tag>
                </Flex>
              )}
              <Description>
                <Description.Text>{name}</Description.Text>
                {isFullyCustomizable && (
                  <Description.SubText>Customized</Description.SubText>
                )}
              </Description>
            </Flex>
          )
        },
        header: 'Type',
      },
      {
        accessorKey: 'report.creatorDisplayName',
        size: 251,
        header: () => (
          <Box className={styles.filter}>
            <UsersFilter />
          </Box>
        ),
        cell: ({ getValue, row }) => {
          const creatorIsUser = row.original.report.creatorType == 'user'
          const creatorDisplayName =
            getValue<ReportExecution['report']['creatorDisplayName']>()
          return (creatorIsUser && creatorDisplayName) || '-'
        },
      },
      {
        accessorKey: 'status',
        size: 251,
        cell: ({ getValue }) => {
          const status = getValue<ReportExecution['status']>()
          return <ReportExecutionStatus value={status} />
        },
        header: () => (
          <Box className={styles.filter}>
            <ReportStatusFilter />
          </Box>
        ),
      },
      {
        id: 'action',
        size: 76,
        cell: ({ row }) => {
          const reportExecution = row.original
          const isDownloadable = isReportDownloadable(reportExecution.status)
          return (
            isDownloadable && (
              <Flex justifyContent="center">
                <Tooltip content="Download">
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={() => handleDownloadClick(reportExecution)}
                  >
                    <Icon name="download" />
                  </Button>
                </Tooltip>
              </Flex>
            )
          )
        },
        header: 'Action',
      },
      {
        id: 'details',
        size: 76,
        cell: function Details({ row }) {
          const { merchantAccountId } = useParams() as {
            merchantAccountId: string
          }
          const reportExecution = row.original
          return (
            <Flex justifyContent="flex-end">
              <Tooltip content="Go to details">
                <ButtonLink
                  variant="tertiary"
                  size="small"
                  href={pathTo.reportExecutionDetails(
                    merchantAccountId,
                    reportExecution.id
                  )}
                />
              </Tooltip>
            </Flex>
          )
        },
        header: '',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <DataTable
      data={data}
      loading={loading}
      columns={columns}
      pagination={pagination}
    />
  )
}
