import { ReportTemplate, reportTemplates } from 'reports/constants'
import { ReportExecution } from 'reports/services/report-execution'
import { is } from 'shared/helpers/is'

export const isReportDownloadable = (status?: ReportExecution['status']) => {
  return status === 'succeeded'
}

export const isReportCustomizable = (reportName: string) => {
  const reportTemplate = reportTemplates.find(
    ({ title }) => title === reportName
  )
  return is.undefined(reportTemplate?.isCustom)
    ? true
    : (reportTemplate as ReportTemplate).isCustom
}

export const getReportStatusName = (status: ReportExecution['status']) => {
  switch (status) {
    case 'succeeded':
      return 'Generated'
    case 'failed':
      return 'Failed'
    default:
      return 'Processing'
  }
}
