import qs from 'qs'
import client, { CollectionResponse } from 'shared/services/client'
import { TransactionFilters } from 'shared/services/transactions'
import { PaymentMethodMethod, PaymentMethodMode } from 'transactions/services'
import { Buyer } from './buyers'

export interface PaymentMethod {
  type: 'payment-method'
  id: string
  status: 'processing' | 'succeeded' | 'failed' | 'buyer_approval_required'
  method: PaymentMethodMethod
  mode: PaymentMethodMode
  externalIdentifier: string
  buyer: Buyer
  createdAt: string
  updatedAt: string
  label: string
  scheme: string
  expirationDate: string
  approvalUrl: string
  country: string
  currency: string
  additionalSchemes?: string[]
}

export const getPaymentMethodsForBuyer = async (
  params: Partial<TransactionFilters> = {}
): Promise<CollectionResponse<PaymentMethod>> => {
  if (params.buyerId === '') {
    return {
      items: [],
      limit: 0,
    }
  }
  // TA-753 - We only want to show successfully tokenised
  // payment methods in Dashboard
  const response = await client.get('/payment-methods', {
    params: { ...params, status: ['succeeded', 'processing'] },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat', encode: false }),
  })
  return response.data
}
