import { is } from './is'

/**
 * Converts a string to its typed value.
 * If the number starts with a zero treats it
 * as a string.
 * @param value string
 * @returns number|null|boolean|string
 */
const convertValue = (value: string) => {
  if (!isNaN(Number(value)) && value.length <= 15 && !value.startsWith('0')) {
    return Number(value)
  }

  switch (value) {
    case undefined:
    case null:
      return null
    case 'true':
    case 'false':
      return Boolean(value)
    default:
      return value
  }
}

/**
 * Serializes an object to a query string.
 * @param obj object
 * @returns string
 */
export const serialize = (obj: Record<string, any>) =>
  Object.keys(obj)
    .filter(
      (key) =>
        obj[key] !== undefined &&
        obj[key] !== null &&
        !is.emptyStringOrWhitespace(obj[key]) &&
        key !== 'cursor'
    )
    .map((key) => `${key}=${obj[key]}`)
    .join('&')

/**
 * Deserializes a query string to an object.
 * @param str string
 * @returns object
 */
export const deserialize = (str: string) => {
  if (!str.slice(1)) {
    return {}
  }

  let queryString

  try {
    queryString = decodeURIComponent(str)
  } catch {
    queryString = str
  }

  const searchParams = new URLSearchParams(queryString)
  const searchParamsArray = Array.from(searchParams).map(([key, value]) => [
    key,
    key !== 'metadata' && value.includes(',')
      ? value.split(',').map((currValue) => convertValue(currValue))
      : convertValue(value),
  ])

  return Object.fromEntries(searchParamsArray)
}
