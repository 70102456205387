import { Alert, Heading, Stack, Text, Tile } from '@gr4vy/poutine-react'
import { Form } from 'antd'
import { camelCase } from 'lodash'
import { TransactionActionOutcome } from 'flows/constants'
import { getTransformationOptions } from 'flows/utils/cardOutcome'
import { FormItem } from 'shared/components/FormItem'
import { InstrumentSection } from './InstrumentSection'

export type MessageStepProps = {
  selectedConnection: TransactionActionOutcome
  hidden: boolean
}

export const MessageStep = ({
  selectedConnection,
  hidden,
}: MessageStepProps) => {
  const form = Form.useFormInstance()
  const transformationsForceMit = Form.useWatch('transformationsForceMit', form)
  const instrument = form.getFieldValue('instrument')

  return (
    <Stack gap={8} display={hidden ? 'none' : 'flex'}>
      <Stack gap={32}>
        {instrument && <InstrumentSection instrument={instrument} />}
        <Stack gap={16}>
          <Heading as="h5" level={5}>
            Message
          </Heading>
          <Text variant="reg3">
            Select any number of changes to the original transaction request
            before sending this message to the connection.
          </Text>
          <Stack gap={8}>
            {getTransformationOptions(selectedConnection).map(
              (transformation) => (
                <FormItem
                  key={transformation.value}
                  name={camelCase(`transformations_${transformation.value}`)}
                  valuePropName="checked"
                  trigger="onCheckedChange"
                >
                  <Tile
                    title={transformation.title}
                    text={transformation.text}
                    value={transformation.value}
                  />
                </FormItem>
              )
            )}
          </Stack>
          {transformationsForceMit && (
            <Alert variant="notice" gap={8} padding={16}>
              <Alert.Icon size="medium" />
              <Alert.Content>
                <Text>
                  <Text as="span" fontWeight="bold">
                    Forcing MIT
                  </Text>{' '}
                  for non-recurring transactions might lead to scheme reprimands
                  or sanctions.
                </Text>
              </Alert.Content>
            </Alert>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
