import { Description, Heading, Stack, Text } from '@gr4vy/poutine-react'
import { Status } from 'shared/components/Status'
import { dateFormat } from 'shared/helpers/date-format'
import { getCurrentLocale } from 'shared/helpers/locale'
import EventsConnection from 'transactions/components/EventsPanel/EventsConnection'
import { TransactionEvent } from 'transactions/services'

export const ConnectorVoidSucceededEvent = ({
  event,
}: {
  event: TransactionEvent
}) => {
  return event ? (
    <Stack gap={32}>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Connection
        </Heading>
        <EventsConnection event={event} />
      </Stack>
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Status
        </Heading>
        <Description>
          <Description.Icon
            size={24}
            src={<Status value="authorization_voided" />}
          />
          <Description.Text>Voided</Description.Text>
        </Description>
      </Stack>
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Date
        </Heading>
        <Text as="span" variant="code1">
          {dateFormat(event.context['voidedAt'], getCurrentLocale(), {
            weekday: 'short',
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
          })}
        </Text>
      </Stack>
    </Stack>
  ) : null
}
