import { HomePage } from './pages'

export default [
  {
    title: 'Home',
    longTitle: 'Home',
    key: 'home',
    path: '/merchants/:merchantAccountId/home',
    menu: true,
    component: HomePage,
  },
]
