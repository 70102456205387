import { apiBaseUrl } from 'shared/helpers/api-url'

export const connectionIds = [
  'stripe-card',
  'adyen-card',
  'paypal-paypal',
  'afterpay-afterpay',
] as const

export type ConnectionId = (typeof connectionIds)[number]

export const getIconUrlById = (id: ConnectionId) =>
  `${apiBaseUrl}/assets/icons/payment-services/${id.split('-')[0]}.svg`

export const getConnectionById = (id: ConnectionId) => {
  const iconUrl = getIconUrlById(id)
  return {
    [id]: {
      iconUrl,
      name: 'Unknown',
    },
    'stripe-card': {
      iconUrl,
      name: 'Stripe',
    },
    'adyen-card': {
      iconUrl,
      name: 'Adyen',
    },
    'paypal-paypal': {
      iconUrl,
      name: 'PayPal',
    },
    'afterpay-afterpay': {
      iconUrl,
      name: 'Afterpay',
    },
  }[id]
}
