import { atoms } from '@gr4vy/poutine-react'
import { Category, ReportTemplate } from 'reports/constants'
import { SideNav } from 'shared/components/SideNav/SideNav'

export const AllReportsPageNav = ({
  templates,
  categories,
}: {
  templates: ReportTemplate[]
  categories: Category[]
}) => {
  return (
    <SideNav
      items={categories.map((category) => ({
        key: category,
        title: <SideNav.Title>{category}</SideNav.Title>,
        href: `#${category.toLowerCase()}`,
        className: 'titleLink',
        children: templates
          .filter(
            ({ category: templateCategory }) => templateCategory === category
          )
          .map(({ id, title }) => ({
            key: id,
            title,
            href: `#${id}`,
            className: atoms({ fontSize: 'sm' }),
          })),
      }))}
    />
  )
}
