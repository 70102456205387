import { Stack } from '@gr4vy/poutine-react'
import { useLocation, useParams } from 'react-router-dom'
import { AddNewRuleCard, InfoCard, RuleCard } from 'flows/components/Card'
import {
  DragArea,
  DragDropArea,
  DragProvided,
  DragSnapshot,
} from 'flows/components/DragAndDrop'
import { Rule } from 'flows/constants'
import { Flow, Action } from 'shared/constants'
import { AccessLevel, Resource, RestrictAccess } from 'shared/permissions'
import { useRuleList } from './useRuleList'

interface RulesListProps {
  flow: Flow
  action: Action
  rules?: Rule[]
  disabled?: boolean
  additionalParams?: URLSearchParams
}

export const RulesList = ({
  flow,
  action,
  rules = [],
  disabled = false,
  additionalParams = new URLSearchParams(),
}: RulesListProps) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const location = useLocation() as { state?: { newRuleId: string } }
  const { onSortRule } = useRuleList(flow, action, merchantAccountId, rules)

  return (
    <Stack direction="column" gap="none">
      <RestrictAccess resource={Resource.flows} accessLevel={AccessLevel.write}>
        <AddNewRuleCard
          flow={flow}
          action={action}
          disabled={disabled}
          additionalParams={additionalParams}
          merchantAccountId={merchantAccountId}
        />
      </RestrictAccess>
      <DragDropArea id={`${flow}-${action}`} onDragEnd={onSortRule.mutate}>
        <Stack direction="column" gap="none">
          {rules.map((rule, index) => (
            <DragArea id={rule.id} key={rule.id} index={index}>
              {(dragProvided: DragProvided, dragSnapshot: DragSnapshot) => (
                <RuleCard
                  rule={rule}
                  key={rule.id}
                  dragProvided={dragProvided}
                  dragSnapshot={dragSnapshot}
                  isNew={rule.id === location.state?.newRuleId}
                  additionalParams={additionalParams}
                  merchantAccountId={merchantAccountId}
                />
              )}
            </DragArea>
          ))}
        </Stack>
      </DragDropArea>
      <InfoCard action={action} />
    </Stack>
  )
}
