import { FormInstance } from 'antd'
import { useEffect } from 'react'

export const useBeforeUnloadAlert = (form: FormInstance) => {
  useEffect(() => {
    // show alert on page refresh/navigation when form is dirty
    const handleUnload = (e: BeforeUnloadEvent) => {
      if (form.isFieldsTouched()) {
        const confirmationMessage =
          'If you leave before saving, your changes will be lost.'

        ;(e || window.event).returnValue = confirmationMessage //Gecko + IE
        return confirmationMessage //Gecko + Webkit, Safari, Chrome etc.
      }
      return null
    }
    window.addEventListener('beforeunload', handleUnload)
    return () => window.removeEventListener('beforeunload', handleUnload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
