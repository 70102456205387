import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { listConnections, listPaymentServices } from 'connections/services'
import { ConfiguredConnection } from 'home/constants/connection'
import { HomeFilters } from 'home/constants/filter'
import { getConfiguredConnections } from 'home/helpers/format'
import { CONNECTIONS, PAYMENT_SERVICES } from 'shared/constants'
import { CollectionResponse } from 'shared/services/client'

interface ConnectionsProps {
  filters: Partial<HomeFilters>
  enabled: boolean
}

export const useConnections = ({ filters, enabled }: ConnectionsProps) => {
  const { merchantAccountId } = useParams<{
    merchantAccountId: string
  }>()
  const paymentServices = useQuery({
    queryKey: [PAYMENT_SERVICES, merchantAccountId],
    queryFn: () => listPaymentServices({ limit: 100 }),
    enabled,
  })

  const connections = useQuery({
    queryKey: [CONNECTIONS, merchantAccountId],
    queryFn: () => listConnections(),
    select: (data) =>
      ({
        items: getConfiguredConnections({
          connections: data.items,
          paymentServices: paymentServices.data?.items,
          currency: filters.currency,
        }),
      }) as CollectionResponse<ConfiguredConnection>,
    enabled: enabled && !!paymentServices.data,
  })

  return connections
}
