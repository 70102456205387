import { Navigate, useLocation, useSearchParams } from 'react-router-dom'
import { NotFoundPage } from 'errors/pages'
import {
  getAdminPathFromUrl,
  getMerchantAccountIdFromUrl,
} from 'shared/helpers/routes'
import { useMultiMerchant } from 'shared/permissions/hooks/use-multi-merchant'

export const UnknownRoute = () => {
  const isAdminPath = getAdminPathFromUrl()
  const isMerchantAccountPath = getMerchantAccountIdFromUrl()
  const { merchantAccountId } = useMultiMerchant()
  const { pathname } = useLocation()
  const [params] = useSearchParams()

  if (!isAdminPath && !isMerchantAccountPath && merchantAccountId) {
    if (
      pathname.startsWith('/reset') ||
      pathname.startsWith('/integrations') ||
      pathname.startsWith('/merchants')
    ) {
      return <Navigate to={`/admin${pathname}?${params}`} />
    }
    return (
      <Navigate to={`/merchants/${merchantAccountId}${pathname}?${params}`} />
    )
  }

  return <NotFoundPage title="Page Not Found" />
}
