import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query'
import { Rule } from 'flows/constants'
import { updateRule } from 'flows/services'
import { Flow, Action } from 'shared/constants'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import { handleSuccessResponse } from 'shared/utils/handleSuccessResponse'

const sortItems =
  ({
    queryClient,
    rules,
    flow,
    action,
    merchantAccountId,
  }: {
    queryClient: QueryClient
    rules: Rule[] | undefined
    flow: Flow
    action: Action
    merchantAccountId: string
  }) =>
  async ({ destination, source, draggableId }: any) => {
    if (rules && destination && destination?.index !== source?.index) {
      const from = source.index
      const to = destination.index
      const updatedRules = [...rules]
      updatedRules.splice(to, 0, updatedRules.splice(from, 1)[0])

      queryClient.setQueriesData(
        { queryKey: [flow, merchantAccountId] },
        (state: any) => {
          return { ...state, [action]: updatedRules }
        }
      )

      return await updateRule({
        flow,
        action,
        id: draggableId,
        rule: {
          position: rules[destination.index].position,
        },
      })
    }
    return false
  }

export const useRuleList = (
  flow: Flow,
  action: Action,
  merchantAccountId: string,
  rules?: Rule[]
) => {
  const queryClient = useQueryClient()

  const onSortRule: any = useMutation({
    mutationFn: sortItems({
      queryClient,
      rules,
      flow,
      action,
      merchantAccountId,
    }),
    onSuccess: (results) => {
      if (results) {
        queryClient.invalidateQueries({ queryKey: [flow] })
        handleSuccessResponse('Saved rule position')()
      }
    },
    onError: handleErrorResponse,
  })

  return {
    onSortRule,
  }
}
