import { Box } from '@gr4vy/poutine-react'
import { isSandbox } from 'shared/helpers/is-sandbox'
import style from './style.module.scss'

export const SandboxBanner = () => {
  return isSandbox ? (
    <Box background="amber50" className={style.sandbox}>
      <Box background="amber50" className={style.notch} width={120} height={20}>
        Sandbox
      </Box>
    </Box>
  ) : null
}
