import {
  ConnectionDefinition,
  ConnectionDefinitionCategory,
  ConnectionDefinitionGroup,
  Connection as ConnectionDef,
} from 'connections/services'

export enum Connection {
  catalog = 'catalog',
  configured = 'configured',
}

export const connectionNames: Record<Connection, string> = {
  [Connection.catalog]: 'Catalog',
  [Connection.configured]: 'Configured',
}

export const connectionDefinitionGroupNames: Record<
  ConnectionDefinitionGroup,
  string
> = {
  [ConnectionDefinitionGroup.cards]: 'Card',
  [ConnectionDefinitionGroup.nonCards]: 'Non-card',
  [ConnectionDefinitionGroup.other]: 'Other',
}

export interface ConnectionGroupDetails {
  name: string
  categories: ConnectionDefinitionCategory[]
}

export const categoryToGroupMap = {
  [ConnectionDefinitionCategory.card]: ConnectionDefinitionGroup.cards,
  [ConnectionDefinitionCategory.digitalWallet]: ConnectionDefinitionGroup.cards,
  [ConnectionDefinitionCategory.tokenization]: ConnectionDefinitionGroup.cards,
  [ConnectionDefinitionCategory.bank]: ConnectionDefinitionGroup.nonCards,
  [ConnectionDefinitionCategory.bnpl]: ConnectionDefinitionGroup.nonCards,
  [ConnectionDefinitionCategory.wallet]: ConnectionDefinitionGroup.nonCards,
  [ConnectionDefinitionCategory.cash]: ConnectionDefinitionGroup.nonCards,
  [ConnectionDefinitionCategory.crypto]: ConnectionDefinitionGroup.nonCards,
  [ConnectionDefinitionCategory.storedCredit]:
    ConnectionDefinitionGroup.nonCards,
  [ConnectionDefinitionCategory.antiFraud]: ConnectionDefinitionGroup.other,
}

export interface Catalog {
  [ConnectionDefinitionGroup.cards]: CatalogGroup
  [ConnectionDefinitionGroup.nonCards]: CatalogGroup
  [ConnectionDefinitionGroup.other]: CatalogGroup
  count?: number
}

export interface CatalogGroup {
  name: string
  count?: number
  categories: Partial<
    Record<ConnectionDefinitionCategory, ConnectionDefinition[]>
  >
}

export interface Configured {
  [ConnectionDefinitionGroup.cards]: ConfiguredGroup
  [ConnectionDefinitionGroup.nonCards]: ConfiguredGroup
  [ConnectionDefinitionGroup.other]: ConfiguredGroup
  count?: number
}

export interface ConfiguredGroup {
  id: string
  name: string
  count?: number
  connections: ConnectionDef[]
}

export const connectionGroups: Record<
  ConnectionDefinitionGroup,
  ConnectionGroupDetails
> = {
  [ConnectionDefinitionGroup.cards]: {
    name: connectionDefinitionGroupNames[ConnectionDefinitionGroup.cards],
    categories: [
      ConnectionDefinitionCategory.digitalWallet,
      ConnectionDefinitionCategory.card,
      ConnectionDefinitionCategory.tokenization,
    ],
  },
  [ConnectionDefinitionGroup.nonCards]: {
    name: connectionDefinitionGroupNames[ConnectionDefinitionGroup.nonCards],
    categories: [
      ConnectionDefinitionCategory.bank,
      ConnectionDefinitionCategory.bnpl,
      ConnectionDefinitionCategory.cash,
      ConnectionDefinitionCategory.crypto,
      ConnectionDefinitionCategory.storedCredit,
      ConnectionDefinitionCategory.wallet,
    ],
  },
  [ConnectionDefinitionGroup.other]: {
    name: connectionDefinitionGroupNames[ConnectionDefinitionGroup.other],
    categories: [ConnectionDefinitionCategory.antiFraud],
  },
}

export const connectionDefinitionCategoryNames: Record<
  ConnectionDefinitionCategory,
  string
> = {
  [ConnectionDefinitionCategory.digitalWallet]: 'Digital Wallets',
  [ConnectionDefinitionCategory.card]: 'Gateways & PSPs',
  [ConnectionDefinitionCategory.bank]: 'Bank',
  [ConnectionDefinitionCategory.bnpl]: 'Buy Now Pay Later',
  [ConnectionDefinitionCategory.cash]: 'Cash',
  [ConnectionDefinitionCategory.wallet]: 'Wallets',
  [ConnectionDefinitionCategory.storedCredit]: 'Gift Cards',
  [ConnectionDefinitionCategory.tokenization]: 'Tokenization only',
  [ConnectionDefinitionCategory.crypto]: 'Crypto',
  [ConnectionDefinitionCategory.antiFraud]: 'Anti-fraud',
}
