import { GridItem } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import { Operation } from 'flows/constants'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'

interface CardSchemaInputProps {
  name: number
}

const cardSchemas = [
  { value: 'amex', name: 'American Express' },
  { value: 'dankort', name: 'Dankort' },
  { value: 'diners-club', name: 'Diners Club' },
  { value: 'discover', name: 'Discover' },
  { value: 'eftpos-australia', name: 'eftpos Australia' },
  { value: 'elo', name: 'Elo' },
  { value: 'jcb', name: 'JCB' },
  { value: 'maestro', name: 'Maestro' },
  { value: 'mastercard', name: 'Mastercard' },
  { value: 'rupay', name: 'RuPay' },
  { value: 'unionpay', name: 'UnionPay' },
  { value: 'visa', name: 'Visa' },
]

const ERROR_CARD_SCHEME_REQUIRED = 'At least one card scheme is required'
const ERROR_OPERATOR_REQUIRED = 'Operator is required'

const CardSchemaInput = ({ name }: CardSchemaInputProps) => (
  <>
    <GridItem gridColumn="span 2">
      <FormItem
        label={<Label>Operator</Label>}
        name={[name, 'operator']}
        rules={[{ required: true, message: ERROR_OPERATOR_REQUIRED }]}
      >
        <Select placeholder="Select an operator">
          <Select.Option value={Operation.INCLUDES_AT_LEAST_ONE}>
            includes at least one
          </Select.Option>
          <Select.Option value={Operation.INCLUDES_NONE}>
            includes none
          </Select.Option>
        </Select>
      </FormItem>
    </GridItem>
    <GridItem gridColumn="span 6">
      <FormItem
        label={<Label>Value(s)</Label>}
        name={[name, 'value']}
        rules={[{ required: true, message: ERROR_CARD_SCHEME_REQUIRED }]}
      >
        <Select
          mode="multiple"
          allowClear
          maxTagCount="responsive"
          maxTagPlaceholder={maxTagPlaceholderFormatter}
          placeholder="Select an option"
        >
          {cardSchemas.map(({ value, name }) => (
            <Select.Option value={value} key={value}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    </GridItem>
  </>
)

export { ERROR_CARD_SCHEME_REQUIRED, ERROR_OPERATOR_REQUIRED }
export default CardSchemaInput
