import { Icon, Stack, Text } from '@gr4vy/poutine-react'
import { Input } from 'antd'
import { KeyboardEvent, ChangeEvent } from 'react'

export const SearchBar = ({
  searchTerm,
  itemsFoundCount,
  search,
}: {
  searchTerm: string
  itemsFoundCount: number
  search: (value: string) => void
}) => {
  const handleChange = ({
    target,
  }: KeyboardEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) => {
    search((target as HTMLInputElement).value)
  }

  return (
    <Stack gap={16} fontWeight="medium">
      <Stack as="label" gap={8}>
        <Text as="span" fontSize="xs">
          Search
        </Text>
        <Input
          allowClear
          name="search"
          placeholder="Search by name..."
          onPressEnter={handleChange}
          value={searchTerm}
          prefix={<Icon name="magnifying-glass" size="small" color="black" />}
          size="large"
          onChange={handleChange}
        />
      </Stack>
      {searchTerm && itemsFoundCount > 0 && (
        <Text as="span" fontSize="md">
          {itemsFoundCount} result
          {itemsFoundCount > 1 ? 's' : ''} found for &ldquo;{searchTerm}
          &rdquo;
        </Text>
      )}
      {searchTerm && itemsFoundCount === 0 && (
        <Text as="span" fontSize="md">
          No results found for &ldquo;{searchTerm}&rdquo;
        </Text>
      )}
    </Stack>
  )
}
