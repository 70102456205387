import { Button, Flex, Grid, GridItem, Stack, Text } from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { useNavigate } from 'react-router-dom'
import { MerchantPagesLayout } from 'admin/components/MerchantsPageLayout'
import { pathTo } from 'admin/paths'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import {
  MerchantAccount,
  UpdateMerchantAccountName,
} from 'shared/services/merchant-accounts'

interface EditMerchantAccountPageProps {
  title: string
  merchantAccount: MerchantAccount
  loading: boolean
  update: UseMutationResult<
    MerchantAccount,
    any,
    UpdateMerchantAccountName,
    unknown
  >
}

const getBreadcrumbs = (displayName: string) => [
  { title: 'Merchants', url: pathTo.merchants },
  { title: displayName },
]

const EditMerchantAccountPage = ({
  title,
  merchantAccount,
  loading,
  update,
}: EditMerchantAccountPageProps) => {
  const [form] = Form.useForm<UpdateMerchantAccountName>()
  const navigate = useNavigate()

  return (
    <MerchantPagesLayout
      title={title}
      breadcrumbs={getBreadcrumbs(merchantAccount.displayName)}
      isLoading={loading}
    >
      <Grid>
        <GridItem gridColumn="span 4">
          <Stack gap={32}>
            <Form
              name="update-merchant"
              form={form}
              initialValues={{
                id: merchantAccount.id,
                displayName: merchantAccount.displayName,
              }}
              onFinish={update.mutate}
              autoComplete="off"
              layout={'vertical'}
              requiredMark={false}
            >
              <Stack gap={24}>
                <FormItem
                  name="displayName"
                  label={<Label>Name</Label>}
                  required
                  rules={[
                    { required: true, message: 'Enter merchant name.' },
                    {
                      type: 'string',
                      max: 255,
                      message: 'Enter a maximum of 255 characters.',
                    },
                  ]}
                >
                  <Input autoFocus />
                </FormItem>
                <FormItem name="id">
                  <Stack gap={4}>
                    <Label>ID</Label>
                    <Text variant="med4" fontFamily="mono" color="gray90">
                      {merchantAccount.id}
                    </Text>
                  </Stack>
                </FormItem>
              </Stack>
            </Form>
            <Flex gap={16}>
              <Button
                form="update-merchant"
                loading={update.isPending}
                variant="primary"
              >
                Update
              </Button>
              <Button
                variant="secondary"
                disabled={update.isPending}
                onClick={() => navigate(pathTo.merchants)}
              >
                Cancel
              </Button>
            </Flex>
          </Stack>
        </GridItem>
      </Grid>
    </MerchantPagesLayout>
  )
}

export default EditMerchantAccountPage
