import { Stack, Flex, Divider } from '@gr4vy/poutine-react'
import { ReactNode } from 'react'

function Header({ children }: { children: ReactNode }) {
  return (
    <Stack gap={24}>
      <Flex alignItems="center" justifyContent="space-between" height={40}>
        {children}
      </Flex>
      <Divider width="md" />
    </Stack>
  )
}

Header.displayName = 'Panel.Header'

export default Header
