import { useQuery } from '@tanstack/react-query'
import { getPaymentService } from 'connections/services'
import { PAYMENT_SERVICES } from 'shared/constants'

export const usePaymentService = (merchantAccountId: string, id: string) => {
  const { data, isLoading } = useQuery({
    queryKey: [PAYMENT_SERVICES, merchantAccountId, id],
    queryFn: () => getPaymentService(id),
    refetchOnWindowFocus: false,
    meta: {
      error: {
        redirect: true,
      },
    },
  })

  return { data, isLoading }
}
