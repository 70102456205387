import { Button, Heading, Box, Stack, Alert } from '@gr4vy/poutine-react'
import { Form, Input } from 'antd'
import { Helmet } from 'react-helmet'
import { useSearchParams } from 'react-router-dom'
import { SSOError } from 'session/components/SSOError'
import { SessionPageLayout } from 'session/components/SessionPageLayout'
import { useSession } from 'session/hooks/use-session'

const SignInPage = () => {
  const { signIn, isLoading, errors } = useSession()
  const [searchParams] = useSearchParams()
  const provider = searchParams.get('provider')

  return (
    <>
      <Helmet>
        <title>Sign in</title>
      </Helmet>
      <SessionPageLayout>
        {provider === 'auth0' && errors?.length ? (
          <SSOError error={errors[0]} />
        ) : (
          <Box
            background="white"
            borderRadius="rounded"
            padding={32}
            alignItems="center"
            justifyContent="center"
            display={isLoading ? 'none' : 'flex'} // prevents 1password icon remaining
          >
            <Box style={{ width: 400 }}>
              <Heading level={2} as="h1">
                Sign in
              </Heading>
              <Form
                onFinish={({ email, password }) =>
                  signIn({ emailAddress: email, password })
                }
                requiredMark={false}
                layout="vertical"
              >
                <Stack gap={16}>
                  <Box>
                    <Form.ErrorList
                      errors={errors?.map((message) => (
                        <Alert variant="negative" key={0} marginY={8}>
                          <Alert.Icon />
                          <Alert.Content>
                            <Alert.Text>{message}</Alert.Text>
                          </Alert.Content>
                        </Alert>
                      ))}
                    />
                  </Box>
                  <Box>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        { required: true, message: 'Enter your email address' },
                      ]}
                    >
                      <Input type="email" autoComplete="username" />
                    </Form.Item>
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        { required: true, message: 'Enter your password' },
                      ]}
                    >
                      <Input.Password autoComplete="current-password" />
                    </Form.Item>
                  </Box>
                  <div>
                    <Button variant="primary">Sign in</Button>
                  </div>
                </Stack>
              </Form>
            </Box>
          </Box>
        )}
      </SessionPageLayout>
    </>
  )
}

export default SignInPage
