import client, { CollectionResponse } from 'shared/services/client'

export type CardSchemeDefinition = {
  displayName: string
  iconUrl: string
  id: string
}

export const listCardSchemeDefinitions = async (): Promise<
  CollectionResponse<CardSchemeDefinition>
> => {
  const response = await client.get('/card-scheme-definitions')
  return response.data
}
