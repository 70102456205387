import { matchPath, useLocation, useParams } from 'react-router-dom'
import { flowNames } from 'flows/constants'
import { useSession } from 'session'
import { Flow } from 'shared/constants'
import { pathTo } from 'shared/paths/flows'

export const useFlowRoutes = () => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const location = useLocation()
  const isNonCardTransactionsPath = !!matchPath(
    location.pathname,
    pathTo.flow(merchantAccountId, Flow.nonCardTransactions)
  )
  const session = useSession()

  if (session.isLoading) {
    return []
  }

  const routes = [
    {
      title: flowNames[Flow.checkout],
      url: pathTo.flow(merchantAccountId, Flow.checkout),
    },
    {
      title: flowNames[Flow.cardTransactions],
      url: pathTo.flow(merchantAccountId, Flow.cardTransactions),
    },
    {
      title: flowNames[Flow.redirectTransactions],
      url: isNonCardTransactionsPath
        ? pathTo.flow(merchantAccountId, Flow.nonCardTransactions)
        : pathTo.flow(merchantAccountId, Flow.redirectTransactions),
    },
  ]

  return routes
}
