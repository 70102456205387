import { BoxProps, Text, TextLine } from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { Children, ReactNode } from 'react'
import styles from './Value.module.scss'

interface ValueProps {
  monospace?: boolean
  children?: ReactNode
  fallback?: boolean
  height?: BoxProps['height']
}

export const Value = ({
  monospace = false,
  /**
   * Fallback will render an em dash if the value is empty.
   * This is done with CSS as we don't know the value of the children
   * until after the component has rendered.
   */
  fallback = true,
  height = 32,
  children,
}: ValueProps) => {
  const hasOnlyChildrenWithString = Children.toArray(children).every(
    (child) => typeof child === 'string'
  )

  return (
    <Text
      as="dd"
      className={clsx(styles.value, {
        [styles.monospace]: monospace,
      })}
      height={height}
    >
      {hasOnlyChildrenWithString ? (
        <TextLine as="span">{children as string}</TextLine>
      ) : (
        <span>{children}</span>
      )}
      {fallback && <span className={styles.fallback}>{'–'}</span>}
    </Text>
  )
}

Value.displayName = 'Summary.Value'
