import { FormInstance } from 'antd'
import {
  AmountCondition,
  Condition,
  Operation,
  ActionOutcome,
  RuleCondition,
  OutcomeInstrument,
} from 'flows/constants'
import { Action } from 'shared/constants'

type ValidationError = {
  [key in Action]?: string
}

const ERROR_INACTIVE: ValidationError = {
  [Action.selectPaymentOption]:
    'This payment option will not be displayed when this rule is triggered, because the connection is inactive.',
  [Action.routeTransaction]:
    'This connection will not be used when this rule is triggered, because the connection is inactive.',
}

const ERROR_COUNTRY: ValidationError = {
  [Action.selectPaymentOption]:
    'This payment option will not be displayed when this rule is being triggered, because the connection isn’t configured for the countries you selected.',
  [Action.routeTransaction]:
    'This connection will not be used when this rule is being triggered, because the connection isn’t configured for the countries you selected.',
}
const ERROR_CURRENCY: ValidationError = {
  [Action.selectPaymentOption]:
    'This payment option will not be displayed when this rule is being triggered, because the connection isn’t configured for the currencies you selected.',
  [Action.routeTransaction]:
    'This connection will not be used when this rule is being triggered, because the connection isn’t configured for the currencies you selected.',
}

const ERROR_NETWORK_TOKENS_SETUP: ValidationError = {
  [Action.routeTransaction]: '',
}

const validateCurrency = (
  amountCondition: RuleCondition,
  outcomeCurrencies: string[] = []
): boolean => {
  const isCurrencyCondition = amountCondition?.name === AmountCondition.CURRENCY
  const isAmountCondition = amountCondition?.name === AmountCondition.AMOUNT
  if (isCurrencyCondition || isAmountCondition) {
    const ruleCurrencies = [
      isCurrencyCondition
        ? amountCondition?.value
        : amountCondition?.value?.currency,
    ]
      .flat()
      .filter((currency) => currency)
    const operator = isCurrencyCondition
      ? amountCondition.operator
      : Operation.IS_ONE_OF

    if (ruleCurrencies.length === 0) {
      return true
    }
    if (operator === Operation.IS_ONE_OF) {
      return ruleCurrencies.some((currency: string) =>
        outcomeCurrencies.includes(currency)
      )
    }
    if (operator === Operation.IS_NOT_ONE_OF) {
      return outcomeCurrencies.some(
        (currency: string) => !ruleCurrencies.includes(currency)
      )
    }
  }
  return true
}

const validateCountry = (
  conditions: RuleCondition[] = [],
  outcomeCountries: string[] = []
): boolean => {
  const countryCondition = conditions.find(
    ({ name }) => name === Condition.COUNTRY
  )
  const ruleCountries = countryCondition?.value || []
  if (countryCondition && ruleCountries.length) {
    if (countryCondition.operator === Operation.IS_ONE_OF) {
      return (
        ruleCountries.length === 0 ||
        ruleCountries.some((country: string) =>
          outcomeCountries.includes(country)
        )
      )
    }
    if (countryCondition.operator === Operation.IS_NOT_ONE_OF) {
      return outcomeCountries.some(
        (country: string) => !ruleCountries.includes(country)
      )
    }
  }
  return true
}

type ValidationOutcome = {
  form: FormInstance
  outcome: ActionOutcome
  action: Action
  version?: 1 | 2
}

const validateOutcome = ({
  form,
  outcome,
  action,
  version = 1,
}: ValidationOutcome): string | undefined => {
  if (!outcome.active) {
    return ERROR_INACTIVE[action]
  }
  if (
    version === 2 &&
    'networkTokensSetupForMerchant' in outcome &&
    outcome.instruments.includes(OutcomeInstrument.NETWORK_TOKEN) &&
    outcome.networkTokensEnabled &&
    !outcome.networkTokensSetupForMerchant
  ) {
    return ERROR_NETWORK_TOKENS_SETUP[action]
  }
  if (
    !validateCurrency(form.getFieldValue('amountCondition'), outcome.currencies)
  ) {
    return ERROR_CURRENCY[action]
  }
  if (!validateCountry(form.getFieldValue('conditions'), outcome.countries)) {
    return ERROR_COUNTRY[action]
  }
  return undefined
}

export {
  validateOutcome,
  ERROR_INACTIVE,
  ERROR_CURRENCY,
  ERROR_COUNTRY,
  ERROR_NETWORK_TOKENS_SETUP,
}
