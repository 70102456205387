import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import MerchantContext from 'App/contexts/MerchantContext'
import { GET_CURRENT_USER, GET_USER, USERS } from 'shared/constants'
import { pathTo } from 'shared/paths/users'
import { useMultiMerchant } from 'shared/permissions/hooks/use-multi-merchant'
import { addMerchantAccount } from 'shared/services/merchant-accounts'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import { isSelectedUserAnAdmin } from 'users/helpers'
import { getUser, updateUser } from 'users/services/users'
import View from './UserMerchantAccountsPage'

const UserMerchantAccountsPage = ({ title }: { title: string }) => {
  const { hasMultipleMerchantAccounts } = useMultiMerchant()
  const { merchantAccounts } = useContext(MerchantContext)

  const { id } = useParams() as {
    id: string
  }
  const { data: user } = useQuery({
    queryKey: [GET_USER, id],
    queryFn: () => getUser(id),
    enabled: !!id,
  })
  const queryClient = useQueryClient()

  const create = useMutation({
    mutationFn: addMerchantAccount,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_CURRENT_USER] })
    },
  })

  const update = useMutation({
    mutationFn: updateUser,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [USERS] }),
    onError: handleErrorResponse,
  })

  return isSelectedUserAnAdmin(user) && !hasMultipleMerchantAccounts ? (
    <Navigate replace to={pathTo.users} />
  ) : (
    <View
      title={title}
      merchantAccounts={merchantAccounts}
      create={create}
      user={user}
      update={update}
    />
  )
}

export default UserMerchantAccountsPage
