import {
  Box,
  Flex,
  Stack,
  Heading,
  Container,
  Skeleton,
  StackProps,
} from '@gr4vy/poutine-react'
import { PropsWithChildren, ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem } from 'shared/components/Breadcrumb'
import { SubNavigationLink } from 'shared/components/SubNavigationLink'
import { Wrapper } from 'shared/components/Wrapper'
import styles from './PageLayout.module.scss'

export type LayoutProps = {
  gap?: StackProps['gap']
  background?: StackProps['background']
} & HeaderProps &
  ContentProps

export type HeaderProps = {
  title?: string
  titleChildren?: ReactNode
  titleSubChildren?: ReactNode
  isLoading?: boolean
  breadcrumbs?: Array<{ title: string; url?: string }>
  subNavigation?: Array<{ title: string; url: string; end?: boolean }>
  actions?: ReactNode
  className?: string
}

export type ContentProps = PropsWithChildren<{
  isLoading?: boolean
  noContainer?: boolean
}>

const Header = ({
  title = '',
  titleChildren,
  titleSubChildren,
  isLoading = false,
  breadcrumbs = [],
  subNavigation = [],
  actions,
}: HeaderProps) => (
  <Box paddingTop={breadcrumbs.length ? 16 : 56} background="gray10">
    <Container>
      <Stack gap={16} direction="column">
        {breadcrumbs.length ? (
          <Breadcrumb>
            {breadcrumbs.map(({ title, url }) => (
              <BreadcrumbItem key={`${title}${url}`}>
                {url ? <Link to={url}>{title}</Link> : title}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        ) : null}
        {isLoading ? null : (
          <Flex
            height={40}
            justifyContent="space-between"
            justifyItems="center"
            alignItems="center"
            gap={16}
          >
            <Flex gap={16} alignItems="center" width="full">
              {titleChildren ?? (
                <Heading level={3} as="h1">
                  {title}
                </Heading>
              )}
              {titleSubChildren && (
                <Heading level={5} as="h2" className={styles.subChildren}>
                  {titleSubChildren}
                </Heading>
              )}
            </Flex>
            {actions && (
              <Stack gap={16} direction="row">
                {actions}
              </Stack>
            )}
          </Flex>
        )}
        {subNavigation.length ? (
          <Stack gap={32} direction="row" alignItems="flex-end" as="nav">
            {subNavigation.map(({ url, title = '', end = false }) => (
              <SubNavigationLink key={url} path={url} title={title} end={end} />
            ))}
          </Stack>
        ) : (
          <Box paddingTop={32}></Box>
        )}
      </Stack>
    </Container>
  </Box>
)

const Content = ({
  children,
  isLoading = false,
  noContainer = false,
}: ContentProps) => (
  <Wrapper
    condition={!noContainer}
    render={(children) => <Container>{children}</Container>}
  >
    <>
      <Box>
        {isLoading ? (
          <Skeleton>
            <Skeleton.Header />
            <Skeleton.Paragraph />
          </Skeleton>
        ) : (
          children
        )}
      </Box>
      <Box height={64}></Box>
    </>
  </Wrapper>
)

export const PageLayout = ({
  title,
  titleChildren,
  titleSubChildren,
  children,
  isLoading = false,
  breadcrumbs = [],
  subNavigation = [],
  actions,
  noContainer = false,
  gap = 32,
  background,
}: LayoutProps) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Stack gap={gap} background={background} style={{ minHeight: '100%' }}>
      <Header
        title={title}
        isLoading={isLoading}
        breadcrumbs={breadcrumbs}
        subNavigation={subNavigation}
        actions={actions}
        titleChildren={titleChildren}
        titleSubChildren={titleSubChildren}
      />
      <Content isLoading={isLoading} noContainer={noContainer}>
        {children}
      </Content>
    </Stack>
  </>
)
