import {
  Box,
  Dropdown,
  Grid,
  GridItem,
  Icon,
  TextLine,
} from '@gr4vy/poutine-react'
import { useSearchParams } from 'react-router-dom'
import { ApplePayDomain } from 'connections/services/applePayDomains'
import { ClientPagination } from 'shared/components/ClientPagination'
import {
  ColumnDef,
  DataTable,
  DataTableProps,
} from 'shared/components/DataTable'
import DebouncedSearchBar from 'shared/components/DebouncedSearchBar'
import { host } from 'shared/helpers/get-environment'
import { useClientFiltering } from 'shared/hooks'
import { QueryResult } from 'shared/services/client'

export type DomainsTableProps = Pick<
  DataTableProps<ApplePayDomain>,
  'pagination'
> & {
  page: QueryResult<ApplePayDomain>
  onRemove: (data: ApplePayDomain) => void
  hasWritePermission: boolean
}

const DomainsTable = ({
  page,
  onRemove,
  hasWritePermission,
}: DomainsTableProps) => {
  const { data, isLoading: loading } = page
  const [searchParams] = useSearchParams()
  const items = data?.items?.filter((d) => ![host].includes(d.displayName))

  const { pagedData, setSearch, ...rest } = useClientFiltering(items || [], {
    limit: 20,
  })

  const columns: Array<ColumnDef<ApplePayDomain>> = [
    {
      header: 'Domain name',
      accessorKey: 'displayName',
      size: 400,
      cell: ({ getValue }) => {
        return <TextLine display="block">{getValue() as string}</TextLine>
      },
    },
    {
      id: 'actions',
      size: 60,
      header: () => <Box textAlign="center">Actions</Box>,
      cell: function ActionsRow({ row }) {
        return (
          <Box textAlign="center">
            <Dropdown>
              <Dropdown.Trigger asChild>
                <Dropdown.Button
                  variant="tertiary"
                  size="small"
                  data-testid="action-menu"
                >
                  <Icon name="more-horizontal" />
                </Dropdown.Button>
              </Dropdown.Trigger>
              <Dropdown.Content align="end">
                <Dropdown.Item
                  opensInModal
                  onSelect={() => onRemove(row.original)}
                  disabled={!hasWritePermission}
                  data-testid="action-menu-item-remove-domain"
                >
                  Remove
                </Dropdown.Item>
              </Dropdown.Content>
            </Dropdown>
          </Box>
        )
      },
    },
  ]

  return (
    <>
      <Grid gap={16}>
        <GridItem gridColumn={['span 12', 'span 12', 'span 3', 'span 3']}>
          <DebouncedSearchBar
            placeholder="Domain name"
            defaultValue={searchParams.get('search') || undefined}
            onChange={(value) => setSearch(`displayName:${value || ''}`)}
            size="small"
          />
        </GridItem>
      </Grid>
      <DataTable
        data={{ items: pagedData }}
        loading={loading}
        columns={columns}
      >
        <DataTable.Empty>No domains added yet.</DataTable.Empty>
        <DataTable.Loading>Loading domains...</DataTable.Loading>
      </DataTable>
      <ClientPagination {...rest} />
    </>
  )
}

export default DomainsTable
