import {
  Alert,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Stack,
} from '@gr4vy/poutine-react'
import { UseQueryResult } from '@tanstack/react-query'
import { ApiLogTable } from 'integrations/components/ApiLogTable'
import { ApiLog } from 'integrations/services/apiLogs'
import { CollectionResponse } from 'shared/services/client'

type APILogsBrowsePageType = {
  title: string
  page: UseQueryResult<CollectionResponse<ApiLog>>
}

const APILogsBrowsePage = ({ page }: APILogsBrowsePageType) => {
  return (
    <Stack gap={32}>
      <Alert variant="neutral">
        <Alert.Icon />
        <Alert.Content>
          <Alert.Title>Limitations</Alert.Title>
          <Grid>
            <GridItem gridColumn="span 8">
              <Alert.Text>
                Only requests that passed authentication and then failed further
                validation are returned. Results are limited to the last 24
                hours with a maximum of 250 entries.
              </Alert.Text>
            </GridItem>
          </Grid>
        </Alert.Content>
      </Alert>

      <ApiLogTable page={page} />
      <Flex gap={8}>
        {page.isFetched && (
          <Button
            variant="secondary"
            onClick={() => page.refetch()}
            loading={page.isFetching}
          >
            <Icon name="processing" />
            Refresh
          </Button>
        )}
      </Flex>
    </Stack>
  )
}

export default APILogsBrowsePage
