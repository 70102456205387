import { Navigate, useParams } from 'react-router-dom'
import {
  ALL_REPORTS_PATH,
  GENERATED_REPORTS_PATH,
  pathTo,
  REPORTS_PATH,
  SCHEDULED_REPORTS_PATH,
  SCHEDULE_DETAILS_PATH,
} from 'shared/paths/reports'
import { Resource } from 'shared/permissions/constants'
import { REPORT_DETAILS_PATH } from '../shared/paths/reports'
import { useReportsPermission } from './hooks/use-reports-permission'
import {
  AllReportsPage,
  GeneratedReportsPage,
  ScheduledReportsPage,
  ReportDetailsPage,
  ScheduleDetailsPage,
} from './pages'

const Default = () => {
  const { hasWritePermission } = useReportsPermission()
  const { merchantAccountId } = useParams() as { merchantAccountId: string }

  return (
    <Navigate
      replace
      to={
        hasWritePermission
          ? pathTo.allReports(merchantAccountId)
          : pathTo.generatedReports(merchantAccountId)
      }
    />
  )
}

export default [
  {
    title: 'Reports',
    key: 'reports.all',
    path: ALL_REPORTS_PATH,
    component: AllReportsPage,
    menu: false,
  },
  {
    title: 'Reports',
    key: 'reports.scheduled',
    path: SCHEDULED_REPORTS_PATH,
    component: ScheduledReportsPage,
    menu: false,
  },
  {
    title: 'Reports',
    key: 'reports.generated',
    path: GENERATED_REPORTS_PATH,
    component: GeneratedReportsPage,
    menu: false,
  },
  {
    title: 'Reports',
    longTitle: 'Reports',
    key: 'reports',
    path: REPORTS_PATH,
    component: Default,
    menu: true,
    resource: Resource.reports,
  },
  {
    title: 'Report Details',
    key: 'report.details',
    path: REPORT_DETAILS_PATH,
    component: ReportDetailsPage,
    menu: false,
  },
  {
    title: 'Schedule Details',
    key: 'schedule.details',
    path: SCHEDULE_DETAILS_PATH,
    component: ScheduleDetailsPage,
    menu: false,
  },
]
