import { Badge, Description, Heading, Stack } from '@gr4vy/poutine-react'
import { Status } from 'shared/components/Status'
import { Summary } from 'shared/components/Summary'
import { useCardSchemeDefinition } from 'shared/hooks'
import EventsConnection, {
  Service,
} from 'transactions/components/EventsPanel/EventsConnection'
import { getTransactionStatusConfig } from 'transactions/constants'
import { TransactionEvent } from 'transactions/services'
import styles from './styles.module.scss'

export const ConnectorAuthorizationResponseEvent = ({
  event,
}: {
  event: TransactionEvent
}) => {
  const Instruments = {
    pan: 'Card number (PAN)',
    // eslint-disable-next-line camelcase
    card_token: 'PSP tokenized card',
    redirect: 'Redirect',
    // eslint-disable-next-line camelcase
    redirect_token: 'Tokenized redirect',
    googlepay: 'Google Pay',
    applepay: 'Apple Pay',
    // eslint-disable-next-line camelcase
    network_token: 'Network token',
  }

  const scheme = useCardSchemeDefinition(event?.context?.paymentMethodScheme)

  return event ? (
    <Stack gap={32}>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Connection
        </Heading>
        <EventsConnection event={event} />
      </Stack>
      {scheme && (
        <Stack gap={8} alignItems="flex-start">
          <Heading as="h5" level={5}>
            Scheme
          </Heading>
          <Service iconUrl={scheme.iconUrl} name={scheme.displayName} />
        </Stack>
      )}
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Instrument
        </Heading>
        <Badge variant="subtle">
          {
            Instruments[
              event.context.instrumentType as keyof typeof Instruments
            ]
          }
        </Badge>
      </Stack>
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Response
        </Heading>
        <Summary className={styles.summary} inline>
          <Summary.Key>Status</Summary.Key>
          <Summary.Value fallback={!event.context.status}>
            <Description>
              <Description.Icon
                size={24}
                src={<Status value={event.context.status} />}
              />
              <Description.Text>
                {getTransactionStatusConfig(event.context.status).label}
              </Description.Text>
            </Description>
          </Summary.Value>
        </Summary>
        <Summary className={styles.summary} inline>
          <Summary.Key>Connection reference</Summary.Key>
          <Summary.Value fallback={!event.context.paymentServiceTransactionId}>
            {event.context.paymentServiceTransactionId}
          </Summary.Value>
        </Summary>
        <Summary className={styles.summary} inline>
          <Summary.Key>Response code</Summary.Key>
          <Summary.Value fallback={!event.context.rawResponseCode}>
            {event.context.rawResponseCode}
          </Summary.Value>
        </Summary>
        <Summary className={styles.summary} inline>
          <Summary.Key>Response description</Summary.Key>
          <Summary.Value fallback={!event.context.rawResponseDescription}>
            {event.context.rawResponseDescription}
          </Summary.Value>
        </Summary>
      </Stack>

      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Authorization
        </Heading>
        <Summary className={styles.summary} inline>
          <Summary.Key>Auth response</Summary.Key>
          <Summary.Value fallback={!event.context.authResponseCode}>
            {event.context.authResponseCode}
          </Summary.Value>
        </Summary>
        <Summary className={styles.summary} inline>
          <Summary.Key>AVS response</Summary.Key>
          <Summary.Value fallback={!event.context.avsResponseCode}>
            {event.context.avsResponseCode}
          </Summary.Value>
        </Summary>
        <Summary className={styles.summary} inline>
          <Summary.Key>CVV response</Summary.Key>
          <Summary.Value fallback={!event.context.cvvResponseCode}>
            {event.context.cvvResponseCode}
          </Summary.Value>
        </Summary>
      </Stack>
    </Stack>
  ) : null
}
