import {
  Grid,
  GridItem,
  Heading,
  Stack,
  TextLink,
  Checkbox,
} from '@gr4vy/poutine-react'
import {
  MerchantNameInput,
  MerchantUrlInput,
} from 'connections/components/DigitalWalletForm'
import { DigitalWalletFormValues as FormValues } from 'connections/services'
import { FormItem } from 'shared/components/FormItem'
import { host } from 'shared/helpers/get-environment'
import { DigitalWalletActivatePage } from './DigitalWalletsActivatePage'

export const DigitalWalletsActivateApplePayPage = ({
  title,
  initialValues = {},
}: {
  title: string
  initialValues?: Partial<FormValues>
}) => (
  <DigitalWalletActivatePage
    title={title}
    heading={
      <Heading level={3} as="h1">
        Apple Pay
      </Heading>
    }
    provider="apple"
    initialValues={initialValues}
    onBeforeSubmit={(values: FormValues) => {
      values.domainNames = [host]
    }}
    render={(acceptTermsAndConditions, setAcceptTermsAndConditions) => (
      <>
        <Grid>
          <GridItem gridColumn="span 6">
            <Stack gap={24}>
              <Heading level={4}>Merchant details</Heading>
              <MerchantNameInput />
              <MerchantUrlInput />
            </Stack>
          </GridItem>
        </Grid>
        <Grid>
          <GridItem gridColumn="span 6">
            <Stack gap={8}>
              <Heading level={4}>Acceptable use guidelines</Heading>
              <FormItem
                name="acceptTermsAndConditions"
                rules={[
                  {
                    validator: () => {
                      if (acceptTermsAndConditions) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject(
                          'Must agree to the Terms and Conditions.'
                        )
                      }
                    },
                  },
                ]}
                valuePropName="checked"
                trigger="onCheckedChange"
              >
                <Checkbox
                  onCheckedChange={(checked) =>
                    setAcceptTermsAndConditions(checked as boolean)
                  }
                >
                  I agree to follow the{' '}
                  <TextLink
                    href="https://developer.apple.com/apple-pay/acceptable-use-guidelines-for-websites/"
                    opensInNewWindow
                  >
                    Acceptable Use Guidelines for Apple Pay on the Web
                  </TextLink>
                </Checkbox>
              </FormItem>
            </Stack>
          </GridItem>
        </Grid>
      </>
    )}
  />
)
