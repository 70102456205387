import { Stack, Heading, Text, atoms } from '@gr4vy/poutine-react'
import { Form, FormInstance } from 'antd'
import React from 'react'
import { FormItem } from 'shared/components/FormItem'
import { InputName } from './InputName'

interface Props {
  form: FormInstance
}

export const StepName = ({ form }: Props) => {
  const initialValues = {
    name: '',
  }

  return (
    <Stack gap={24}>
      <Heading level={5}>Name your certificate</Heading>
      <Text>
        First, name the certificate so that it can be easily identified in the
        future. For example, you may want to name the certificate after the app
        it represents, e.g. &quot;iPhone App v1&quot;.
      </Text>
      <Form
        form={form}
        initialValues={initialValues}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
      >
        <FormItem
          style={{ flexGrow: 3 }}
          className={atoms({ fontWeight: 'normal' })}
          name="name"
          rules={[
            {
              required: true,
              message: 'Please add a name for the certificate',
            },
          ]}
        >
          <InputName autoFocus placeholder="Type certificate name here" />
        </FormItem>
      </Form>
      <Text>
        In the next step, you will download a file from Gr4vy that will be used
        to continue the process with Apple.
      </Text>
    </Stack>
  )
}
