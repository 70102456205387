import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { listBuyers } from 'buyers/services/buyers'
import { useCursor, useFilters } from 'shared/hooks'
import View, { BuyersBrowseFilters } from './BuyersBrowsePage'

const BUYERS = 'buyers'

export interface Filters {
  limit?: number
  search?: string
}

const BuyersBrowsePage = ({ title }: { title: string }) => {
  const { merchantAccountId } = useParams() as {
    merchantAccountId: string
  }
  const [cursor] = useCursor()
  const [filters, setFilters] = useFilters<BuyersBrowseFilters>()

  const page = useQuery({
    queryKey: [BUYERS, merchantAccountId, cursor, filters],
    queryFn: () => listBuyers({ cursor, ...filters }),
    meta: {
      error: {
        redirect: true,
      },
    },
  })

  const updateFilter = (f: Filters) => {
    setFilters({ ...filters, ...f })
  }

  return (
    <View title={title} page={page} onFilter={updateFilter} filters={filters} />
  )
}

export default BuyersBrowsePage
