import { useMatch } from 'react-router-dom'
import { useMultiMerchant } from 'shared/permissions/hooks/use-multi-merchant'

export type PathToFunc = (
  merchantAccountId: string,
  ...rest: unknown[]
) => string

export const useMatchPath = (pathToFunc?: PathToFunc) => {
  const { merchantAccountId } = useMultiMerchant()
  const match = !!useMatch(pathToFunc?.(merchantAccountId) ?? '')
  return match
}
