import client, { CollectionResponse } from 'shared/services/client'
import { BuyerBillingDetails } from 'transactions/services'

export interface Buyer {
  type: 'buyer'
  id: string
  externalIdentifier: string
  displayName: string
  createdAt: string
  updatedAt: string
  billingDetails: BuyerBillingDetails
}

export const listBuyers = async (
  params: { cursor?: string; limit?: number; search?: string } = {}
): Promise<CollectionResponse<Buyer>> => {
  const response = await client.get('/buyers', { params })
  return response.data
}

export const getBuyer = async (id: string, params = {}): Promise<Buyer> => {
  const response = await client.get(`/buyers/${id}`, { params })
  return response.data
}

export const addBuyer = async (
  resource: Pick<Buyer, 'externalIdentifier' | 'displayName'>
): Promise<Buyer> => {
  const response = await client.post('/buyers', resource)
  return response.data
}

export const updateBuyer = async (changes: Partial<Buyer>): Promise<Buyer> => {
  const { id, ...body } = changes
  const response = await client.put(`/buyers/${id}`, body)
  return response.data
}

export const removeBuyer = async (id: string) => {
  await client.delete(`/buyers/${id}`)
}
