import { Box, Stack, Grid, GridItem, Text } from '@gr4vy/poutine-react'
import { AnchorLinkItemProps } from 'antd/es/anchor/Anchor'
import { useState } from 'react'
import { Anchor, stepToHash, hashToStep } from 'flows/components/Anchor/Anchor'
import { RulePanel } from 'flows/components/RulePanel/RulePanel'
import { RulesList } from 'flows/components/RulesList/RulesList'
import { CardTransactionsStep } from 'flows/constants'
import { useFlowRoutes } from 'flows/hooks/useFlowRoutes'
import { useRules } from 'flows/hooks/useRules'
import { PageLayout } from 'shared/components/PageLayout'
import { Action, Flow } from 'shared/constants'

const TransactionRequestBody = () => (
  <Text isReadable>
    Your customer submits their card details and the transaction is processed by
    Gr4vy. The payment options presented at checkout can be controlled within
    the checkout flow.
  </Text>
)

const DeclineBody = () => (
  <Text isReadable>
    You will be able to choose to decline your transaction early to prevent any
    potential chargebacks.
  </Text>
)

const RouteToConnectionBody = () => (
  <Text isReadable>
    Select and prioritize connections to route your transactions. Rules are
    evaluated top to bottom where the outcome of the first matched rule is used.
  </Text>
)

const Skip3dSecureBody = () => (
  <Text isReadable>
    You will be able to use this action to control when 3-D Secure is required
    for transactions.
  </Text>
)

const TransactionProcessingBody = () => (
  <Text isReadable>
    The transaction is processed with your chosen connection.
  </Text>
)

const anchorItems: AnchorLinkItemProps[] = [
  {
    key: CardTransactionsStep.transactionRequest,
    href: stepToHash(CardTransactionsStep.transactionRequest),
    title: 'Transaction request',
  },
  {
    key: CardTransactionsStep.decline,
    href: stepToHash(CardTransactionsStep.decline),
    title: 'Decline',
    className: 'highlighted',
  },
  {
    key: CardTransactionsStep.routeToConnection,
    href: stepToHash(CardTransactionsStep.routeToConnection),
    title: 'Route to connection',
    className: 'highlighted',
  },
  {
    key: CardTransactionsStep.skip3dSecure,
    href: stepToHash(CardTransactionsStep.skip3dSecure),
    title: '3-D Secure',
    className: 'highlighted',
  },
  {
    key: CardTransactionsStep.transactionProcessing,
    href: stepToHash(CardTransactionsStep.transactionProcessing),
    title: 'Transaction processing',
  },
]

const CardTransactionsPage = ({ title }: { title: string }) => {
  const navigationTabs = useFlowRoutes()
  const { isLoading, data: rules } = useRules(Flow.cardTransactions)
  const [anchor, setAnchor] = useState(
    hashToStep(window.location.hash) || CardTransactionsStep.transactionRequest
  )

  if (isLoading) {
    return null
  }

  return (
    <PageLayout title={title} subNavigation={navigationTabs}>
      <Grid>
        <GridItem gridColumn="span 8">
          <Stack gap={32} direction="column">
            <RulePanel
              id={CardTransactionsStep.transactionRequest}
              number={1}
              selected={anchor === CardTransactionsStep.transactionRequest}
              title="Transaction request"
              body={<TransactionRequestBody />}
            />
            <RulePanel
              id={CardTransactionsStep.decline}
              number={2}
              highlighted
              selected={anchor === CardTransactionsStep.decline}
              title="Decline"
              body={<DeclineBody />}
            >
              <RulesList
                flow={Flow.cardTransactions}
                action={Action.declineEarly}
                rules={rules?.[Action.declineEarly]}
              />
            </RulePanel>
            <RulePanel
              id={CardTransactionsStep.routeToConnection}
              number={3}
              highlighted
              selected={anchor === CardTransactionsStep.routeToConnection}
              title="Route to connection"
              body={<RouteToConnectionBody />}
            >
              <RulesList
                flow={Flow.cardTransactions}
                action={Action.routeTransaction}
                rules={rules?.[Action.routeTransaction]}
              />
            </RulePanel>
            <RulePanel
              id={CardTransactionsStep.skip3dSecure}
              number={4}
              highlighted
              selected={anchor === CardTransactionsStep.skip3dSecure}
              title={'3-D Secure'}
              body={<Skip3dSecureBody />}
            >
              <RulesList
                flow={Flow.cardTransactions}
                action={Action.skip3ds}
                rules={rules?.[Action.skip3ds]}
              />
            </RulePanel>
            <RulePanel
              id={CardTransactionsStep.transactionProcessing}
              number={5}
              selected={anchor === CardTransactionsStep.transactionProcessing}
              title="Transaction processing"
              body={<TransactionProcessingBody />}
            />
          </Stack>
        </GridItem>
        <GridItem gridColumn="span 4" height="full">
          <Box paddingTop={32} paddingLeft={80}>
            <Anchor
              defaultAnchor={stepToHash(anchor)}
              onChange={(nextAnchor) => {
                setAnchor(hashToStep(nextAnchor))
              }}
              items={anchorItems}
            />
          </Box>
        </GridItem>
      </Grid>
    </PageLayout>
  )
}

export default CardTransactionsPage
