import { GridItem } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import { Operation } from 'flows/constants'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { countries } from 'shared/constants'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'

interface CountryInputProps {
  name: number
}

const filter = (value: string, option: any) =>
  option.value.toLowerCase().includes(value.toLowerCase()) ||
  option.label.toLowerCase().includes(value.toLowerCase())

const ERROR_COUNTRY_REQUIRED = 'At least one country is required'
const ERROR_OPERATOR_REQUIRED = 'Operator is required'

const CountryInput = ({ name }: CountryInputProps) => (
  <>
    <GridItem gridColumn="span 2">
      <FormItem
        label={<Label>Operator</Label>}
        name={[name, 'operator']}
        rules={[{ required: true, message: ERROR_OPERATOR_REQUIRED }]}
      >
        <Select placeholder="Select an operator">
          <Select.Option value={Operation.IS_ONE_OF}>includes</Select.Option>
          <Select.Option value={Operation.IS_NOT_ONE_OF}>
            excludes
          </Select.Option>
        </Select>
      </FormItem>
    </GridItem>
    <GridItem gridColumn="span 6">
      <FormItem
        label={<Label>Value(s)</Label>}
        name={[name, 'value']}
        rules={[{ required: true, message: ERROR_COUNTRY_REQUIRED }]}
      >
        <Select
          mode="multiple"
          allowClear
          maxTagCount="responsive"
          maxTagPlaceholder={maxTagPlaceholderFormatter}
          placeholder="Select an option"
          filterOption={filter}
        >
          {countries.map((country) => (
            <Select.Option
              value={country.key}
              label={country.name}
              key={country.key}
            >
              {country.name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    </GridItem>
  </>
)

export { ERROR_COUNTRY_REQUIRED, ERROR_OPERATOR_REQUIRED }
export default CountryInput
