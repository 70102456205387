import { pathTo } from 'admin/paths'
import {
  Integration,
  integrationNames,
} from 'integrations/constants/integrations'

export const useIntegrationRoutes = () => [
  {
    title: integrationNames[Integration.apiKeys],
    url: pathTo.apiKeys,
    end: true,
  },
  {
    title: integrationNames[Integration.apiLogs],
    url: pathTo.apiLogs,
  },
]
