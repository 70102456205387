import { Description } from '@gr4vy/poutine-react'
import { Status } from 'shared/components/Status'
import { getGiftCardRedemptionStatus } from 'transactions/helpers/get-gift-card-redemption-status'
import { GiftCardRedemption } from 'transactions/services/transactions'

export interface GiftCardStatusProps {
  giftCardRedemption: GiftCardRedemption
}

export const GiftCardStatus = ({ giftCardRedemption }: GiftCardStatusProps) => {
  const [label, subLabel] = getGiftCardRedemptionStatus(giftCardRedemption)

  return (
    <Description>
      <Description.Icon
        size={24}
        src={<Status value={giftCardRedemption.status} />}
      />
      <Description.Text>{label}</Description.Text>
      {!!subLabel && <Description.SubText>{subLabel}</Description.SubText>}
    </Description>
  )
}
