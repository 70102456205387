import { HTMLAttributes } from 'react'
import { PopoverItem } from './PopoverItem'
import styles from './PopoverMenu.module.scss'

function PopoverMenu({ children, ...props }: HTMLAttributes<HTMLUListElement>) {
  return (
    <ul {...props} className={styles.menu} role="menu">
      {children}
    </ul>
  )
}
PopoverMenu.Item = PopoverItem

export default PopoverMenu
