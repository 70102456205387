import { Stack, Table } from '@gr4vy/poutine-react'
import {
  useReactTable,
  getCoreRowModel,
  RowData,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'
import {
  Children,
  isValidElement,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react'
import { Pagination, PaginationProps } from 'shared/components/Pagination'
import { QueryResult } from 'shared/services/client'

export interface DataTableProps<T> {
  data?: Partial<QueryResult<T>['data']>
  columns?: Array<ColumnDef<T>>
  loading?: boolean
  error?: boolean
  pagination?: PaginationProps
  children?: Array<
    ReactElement<typeof DataTable.Empty | typeof DataTable.Loading>
  >
}

function DataTable<T extends RowData>({
  data,
  columns = [],
  loading,
  error,
  pagination,
  children,
}: DataTableProps<T>) {
  const items = useMemo(
    () => (loading || error || !data?.items ? [] : data.items),
    [loading, error, data?.items]
  )

  const instance = useReactTable({
    data: items,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const emptyChild = Children.toArray(children).find(
    (child) => isValidElement(child) && child.type === DataTable.Empty
  ) || <DataTable.Empty>No data</DataTable.Empty>

  const loadingChild = Children.toArray(children).find(
    (child) => isValidElement(child) && child.type === DataTable.Loading
  ) || <DataTable.Loading>Loading...</DataTable.Loading>

  return (
    <Stack gap={32}>
      <Table>
        <Table.Header>
          {instance.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeaderCell
                  key={header.id}
                  colSpan={header.colSpan}
                  {...{
                    style: {
                      width: header.getSize(),
                    },
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body>
          {instance.getRowModel().rows.map((row) => {
            const visibleCells = row.getVisibleCells()
            return (
              <Table.Row key={row.id}>
                {visibleCells.map((cell) => (
                  <Table.BodyCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.BodyCell>
                ))}
              </Table.Row>
            )
          })}
          {!loading && !instance.getRowModel().rows.length && (
            <Table.Row>
              <Table.EmptyCell colSpan={columns.length}>
                {emptyChild}
              </Table.EmptyCell>
            </Table.Row>
          )}
          {loading && !instance.getRowModel().rows.length && (
            <Table.Row>
              <Table.EmptyCell colSpan={columns.length}>
                {loadingChild}
              </Table.EmptyCell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {pagination && (
        <Pagination
          {...pagination}
          nextCursor={data?.nextCursor}
          previousCursor={data?.previousCursor}
          items={items}
        />
      )}
    </Stack>
  )
}

DataTable.Empty = function Empty({ children }: { children: ReactNode }) {
  return <>{children}</>
}

DataTable.Loading = function Loading({ children }: { children: ReactNode }) {
  return <>{children}</>
}

export default DataTable

export { DataTable }
export type { ColumnDef }
