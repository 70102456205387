import { useQuery } from '@tanstack/react-query'
import { getRuleConfig } from 'flows/services'
import { Action, Flow } from 'shared/constants'

export const useRuleConfig = (flow: Flow, action: Action) => {
  return useQuery({
    queryKey: [`rule-config-${flow}-${action}`],
    queryFn: async () => await getRuleConfig(flow, action),
    refetchOnWindowFocus: false,
    meta: {
      error: {
        message: 'Unable to load rule',
      },
    },
  })
}
