import { Button, Stack } from '@gr4vy/poutine-react'
import { useMutation } from '@tanstack/react-query'
import { Form } from 'antd'
import {
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  registerDigitalWallet,
  DigitalWalletFormValues as FormValues,
} from 'connections/services'
import { FormPromptUnsavedChanges } from 'shared/components/FormPromptUnsavedChanges'
import { PageLayout } from 'shared/components/PageLayout'
import { pathTo } from 'shared/paths/connections'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import { handleSuccessResponse } from 'shared/utils/handleSuccessResponse'
import { showErrorMessage } from 'shared/utils/showMessage'

export const DigitalWalletActivatePage = ({
  title,
  heading,
  initialValues = {},
  provider,
  render,
  onBeforeSubmit,
}: {
  title: string
  heading: ReactNode
  initialValues?: Partial<FormValues>
  provider: string
  onBeforeSubmit?: (values: FormValues) => void | undefined
  render: (
    acceptTermsAndConditions: boolean,
    setAcceptTermsAndConditions: Dispatch<SetStateAction<boolean>>
  ) => ReactNode
}) => {
  const [form] = Form.useForm<FormValues>()
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const navigate = useNavigate()
  const redirect = () =>
    navigate(pathTo.connectionsConfigured(merchantAccountId))
  const register = useMutation({
    mutationFn: registerDigitalWallet,
    onSuccess: handleSuccessResponse('Digital wallet added', redirect),
    onError: handleErrorResponse,
  })
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState<boolean>(initialValues?.acceptTermsAndConditions || false)
  const [formInitialValues, setFromInitialValues] =
    useState<Partial<FormValues>>(initialValues)

  const handleSubmit = (values: FormValues) => {
    form.validateFields()
    onBeforeSubmit && onBeforeSubmit(values)
    setFromInitialValues({
      ...values,
      acceptTermsAndConditions,
    })
    register.mutate({
      provider,
      ...values,
      acceptTermsAndConditions,
    })
  }

  const onFinishFailed = () => {
    showErrorMessage('Please check the form for any errors before continuing.')
  }

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault()
    navigate(pathTo.connectionsCatalog(merchantAccountId), { replace: true })
  }

  const breadcrumbs = (title: string) => [
    { title: 'Connections', url: pathTo.connectionsCatalog(merchantAccountId) },
    { title: title },
  ]
  return (
    <Form
      name="connection"
      form={form}
      initialValues={formInitialValues}
      onFinish={handleSubmit}
      autoComplete="off"
      layout="vertical"
      requiredMark={false}
      scrollToFirstError={{
        block: 'center',
      }}
      onFinishFailed={onFinishFailed}
    >
      <PageLayout
        title={title}
        breadcrumbs={breadcrumbs(title)}
        titleChildren={heading}
      >
        <Stack paddingTop={8} gap={40}>
          {render(acceptTermsAndConditions, setAcceptTermsAndConditions)}
          <Stack direction="row" gap={16}>
            <Button
              loading={register.isPending}
              loadingText="Saving connection"
              variant="primary"
            >
              Add connection
            </Button>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </Stack>
        </Stack>
        <FormPromptUnsavedChanges
          form={form}
          initialValues={formInitialValues}
        />
      </PageLayout>
    </Form>
  )
}
