import client, { CollectionResponse } from 'shared/services/client'

export type AuditLogAction =
  | 'created'
  | 'updated'
  | 'deleted'
  | 'captured'
  | 'voided'

export interface AuditLogFilters {
  cursor: string
  limit: number
  userId: string
}

export interface AuditLogEntry {
  id: string
  type: 'audit-log'
  timestamp: string
  action: AuditLogAction
  merchantAccountId: string | null
  user: {
    id: string
    name: string
    emailAddress: string
    isStaff: boolean
    status: string
  }
  resource: {
    id: string
    type: string
    name: string
  }
}

export const listAuditLogEntries = async (
  params: Partial<AuditLogFilters> = {}
): Promise<CollectionResponse<AuditLogEntry>> => {
  const response = await client.get('/audit-logs', { params })
  return response.data
}
