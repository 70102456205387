import { FilterButton, Box, Popover } from '@gr4vy/poutine-react'
import { compact, isEqual } from 'lodash'
import { useMemo } from 'react'
import { ReportExecutionStatus } from 'reports/components/ReportExecutionStatus'
import {
  ReportExecution,
  ReportExecutionsFilters,
} from 'reports/services/report-execution'
import { PopoverMenu } from 'shared/components/PopoverMenu'
import { useFilters } from 'shared/hooks'
import styles from './ReportStatusFilter.module.scss'

type StatusItem = ReportExecution['status'][]

const statusOptions: StatusItem[] = [
  ['pending', 'processing', 'dispatched'],
  ['succeeded'],
  ['failed'],
]

export const ReportStatusFilter = () => {
  const [filters, setFilters] = useFilters<ReportExecutionsFilters>()

  const selectedStatuses = useMemo(
    () => compact([(filters as ReportExecutionsFilters)?.status]).flat(),
    [filters]
  )

  const handleToggleFilter = (statuses: ReportExecution['status'][]) => {
    const nextStatus = isEqual(selectedStatuses, statuses)
      ? undefined
      : statuses
    setFilters({
      ...filters,
      status: nextStatus,
    })
  }

  return (
    <Popover>
      <Popover.Trigger asChild>
        <FilterButton
          data-selected={selectedStatuses && selectedStatuses.length > 0}
        >
          Status
          {selectedStatuses && selectedStatuses.length ? ' / 1' : null}
        </FilterButton>
      </Popover.Trigger>
      <Popover.Content align="start" side="bottom" avoidCollisions={false}>
        <Box className={styles.contentWrapper}>
          <PopoverMenu>
            {statusOptions.map((statuses) => (
              <PopoverMenu.Item
                key={statuses[0]}
                onClick={() => handleToggleFilter(statuses)}
                active={isEqual(selectedStatuses, statuses)}
              >
                <ReportExecutionStatus value={statuses[0]} />
              </PopoverMenu.Item>
            ))}
          </PopoverMenu>
        </Box>
      </Popover.Content>
    </Popover>
  )
}
