import { atoms, Stack, Tag } from '@gr4vy/poutine-react'

export const Banner = ({ iconUrl, tag }: { iconUrl: string; tag?: string }) => {
  return (
    <Stack
      direction="row"
      background="gray10"
      paddingX={16}
      paddingY={8}
      justifyContent="space-between"
      alignItems="center"
      className="connectionCardBanner"
    >
      <img className={atoms({ height: 48, width: 48 })} src={iconUrl} />
      {tag && (
        <Tag variant="subtle" color="white">
          via {tag}
        </Tag>
      )}
    </Stack>
  )
}
