import { Text } from '@gr4vy/poutine-react'

export const NoSupportedConnections = () => {
  return (
    <Text variant="med3">
      Your configured connections are not currently supported for settlement
      reporting.
    </Text>
  )
}
