import { Heading } from '@gr4vy/poutine-react'
import { Panel } from 'shared/components/Panel'
import { Summary } from 'shared/components/Summary'
import { useTransactionEvents } from 'transactions/hooks/use-transaction-events'
import { Transaction } from 'transactions/services'

export const ProcessorResponsePanel = ({
  transaction,
}: {
  transaction: Transaction
}) => {
  const { lastAuthorizationResponse, lastRawResponse } =
    useTransactionEvents(transaction)

  return (
    <Panel>
      <Panel.Header>
        <Heading level={4}>Response</Heading>
      </Panel.Header>
      <Panel.Content>
        <Summary inline>
          <Summary.Key>Error code</Summary.Key>
          <Summary.Value>{transaction.errorCode}</Summary.Value>
          {lastAuthorizationResponse && (
            <>
              <Summary.Key>Processor response</Summary.Key>
              <Summary.Value>{transaction.authResponseCode}</Summary.Value>
              <Summary.Key>Connector response</Summary.Key>
              <Summary.Value fallback={!lastRawResponse}>
                {lastRawResponse}
              </Summary.Value>
              <Summary.Key>Scheme transaction ID</Summary.Key>
              <Summary.Value>{transaction.schemeTransactionId}</Summary.Value>
              <Summary.Key>AVS response</Summary.Key>
              <Summary.Value>{transaction.avsResponseCode}</Summary.Value>
              <Summary.Key>CVV response</Summary.Key>
              <Summary.Value>{transaction.cvvResponseCode}</Summary.Value>
            </>
          )}
        </Summary>
      </Panel.Content>
    </Panel>
  )
}
