import { Flex, Icon, Text } from '@gr4vy/poutine-react'
import { ReactNode } from 'react'
import currencyFormat from 'shared/helpers/currency-format'
import { getMerchantAccountOverCapture } from 'shared/helpers/merchant-account'

const Example = ({ children }: { children: ReactNode }) => (
  <Flex gap={8} alignItems="center">
    <Icon name="info" size="large" color="blue60" />
    <Text>{children}</Text>
  </Flex>
)

export const OverCaptureExample = ({
  amount,
  percentage,
}: {
  amount: number | null
  percentage: number | null
}) => {
  const maxOverCapture = getMerchantAccountOverCapture(
    10000,
    amount,
    percentage
  )

  if (maxOverCapture === 0) {
    return (
      <Example>
        A <strong>$100.00</strong> authorization can not be over-captured.
      </Example>
    )
  }

  if (isFinite(maxOverCapture)) {
    const value = currencyFormat(maxOverCapture, { currency: 'USD' })
    return (
      <Example>
        A <strong>$100.00</strong> authorization can be captured for an amount
        up to <strong>{value}</strong>.
      </Example>
    )
  }
  return (
    <Example>
      A <strong>$100.00</strong> authorization can be captured for any amount.
    </Example>
  )
}

export default OverCaptureExample
