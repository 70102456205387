import {
  Divider,
  Dropdown,
  Heading,
  Stack,
  Text,
  VisuallyHidden,
} from '@gr4vy/poutine-react'
import { useNavigate } from 'react-router-dom'
import { useSession } from 'session'
import { pathTo } from 'shared/paths/users'
import { AccessLevel, Resource } from 'shared/permissions'
import { useResourcePermissions } from 'shared/permissions/hooks/use-resource-permissions'
import {
  getResetPasswordLink,
  useResetPassword,
} from 'users/hooks/use-reset-password'
import { Menu } from './Menu'
import styles from './Menu.module.scss'

export const UserMenu = () => {
  const { user, isStaff } = useSession()
  const navigate = useNavigate()
  const { reset } = useResetPassword()
  const [hasMeWritePermission] = useResourcePermissions(
    [Resource.me],
    AccessLevel.write
  )

  const handleChangePassword = async () => {
    if (!user) {
      return
    }

    try {
      const userData = await reset.mutateAsync(user.emailAddress)

      if (userData.resetToken) {
        const resetLink = getResetPasswordLink(userData.resetToken, true)
        navigate(resetLink)
      }
      // The mutateAsync returns a promise, and that's the reason for the catch
      // below. It is empty, though, because the error message for the mutation
      // is already handled inside the useResetPassword hook.
    } catch {}
  }

  return (
    <Menu
      tooltipContent="Your profile"
      trigger={
        <>
          <Text
            as="span"
            size={14}
            lineHeight={20}
            fontWeight="medium"
            color="blue60"
          >
            {user && user.name.substring(0, 1)}
          </Text>
          <VisuallyHidden>Signed in as {user && user.name}</VisuallyHidden>
        </>
      }
      triggerClass={styles.user}
    >
      <Stack direction="column" gap={8} padding={16} color="gray100">
        <Stack paddingX={8} direction="column">
          <Heading level={6} as="h6">
            {user && user.name}
          </Heading>
          <Text color="gray90" fontSize="xs">
            {user && user.emailAddress}
          </Text>
        </Stack>
        <Divider />
        {user && (
          <>
            <Dropdown.Item
              opensInModal
              onSelect={() => navigate(pathTo.editUser.name('me'))}
              disabled={!hasMeWritePermission || isStaff}
            >
              Edit name
            </Dropdown.Item>
            <Dropdown.Item
              opensInModal
              onSelect={handleChangePassword}
              disabled={!hasMeWritePermission || isStaff}
            >
              Change password
            </Dropdown.Item>
            <Dropdown.Item onSelect={() => navigate(pathTo.user('me'))}>
              My user details
            </Dropdown.Item>
            <Dropdown.Item onSelect={() => navigate('/sign-out')}>
              Sign out
            </Dropdown.Item>
          </>
        )}
      </Stack>
    </Menu>
  )
}
