import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addApplePayDomain } from 'connections/services/applePayDomains'
import { DIGITAL_WALLETS } from 'shared/constants'

export const useAddAppleDomain = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, domainName }: { id: string; domainName: string }) =>
      addApplePayDomain(id, domainName),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [DIGITAL_WALLETS] })
    },
  })
}
