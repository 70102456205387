import {
  Box,
  Container,
  Description,
  Icon,
  Stack,
  Tooltip,
} from '@gr4vy/poutine-react'
import { Status } from 'shared/components/Status'
import { Summary } from 'shared/components/Summary'
import { SummaryGrid } from 'shared/components/SummaryGrid'
import { usePaymentMethodDefinition } from 'shared/hooks'
import { getStatus } from 'transactions/helpers/get-status'
import {
  PaymentMethod,
  PayoutSummary,
  Transaction,
} from 'transactions/services'
import TransactionOverviewCardPayment from './TransactionOverviewCardPayment'
import TransactionOverviewConnection from './TransactionOverviewConnection'
import TransactionOverviewOtherPayment from './TransactionOverviewOtherPayment'

interface TransactionDetailsProps {
  transaction: Transaction | PayoutSummary
  paymentMethod?: PaymentMethod
  resource?: string
}

const TransactionOverview = ({
  transaction,
  paymentMethod,
}: TransactionDetailsProps) => {
  const paymentMethodDefinition = usePaymentMethodDefinition(
    transaction.paymentMethod?.method
  )
  const isCardTransaction =
    paymentMethodDefinition?.id === 'card' ||
    paymentMethodDefinition?.typeof === 'card' ||
    paymentMethodDefinition?.id === 'network-token'

  const isPayout = transaction.type === 'payout'

  const [statusLabel, statusLabelAddon] = getStatus(transaction)

  return (
    <Box>
      <Container>
        <SummaryGrid
          gridTemplateColumns={{ xs: 1, md: 2, lg: 3 }}
          columnGap={64}
          rowGap={40}
          paddingX={32}
          paddingY={48}
        >
          <SummaryGrid.Item>
            <Summary.Key>Status</Summary.Key>
            <Summary.Value>
              <Description>
                <Description.Icon
                  size={24}
                  src={<Status value={transaction.status} />}
                />
                <Description.Text>{statusLabel}</Description.Text>
                {!!statusLabelAddon && (
                  <Description.SubText>{statusLabelAddon}</Description.SubText>
                )}
              </Description>
            </Summary.Value>
          </SummaryGrid.Item>
          <SummaryGrid.Item>
            <Summary.Key>Payment method</Summary.Key>
            <Summary.Value>
              {isCardTransaction ? (
                <TransactionOverviewCardPayment
                  transaction={transaction}
                  paymentMethod={paymentMethod}
                />
              ) : (
                <TransactionOverviewOtherPayment
                  transaction={transaction}
                  paymentMethod={paymentMethod}
                />
              )}
            </Summary.Value>
          </SummaryGrid.Item>
          <SummaryGrid.Item>
            <Summary.Key>Connection</Summary.Key>
            <Summary.Value>
              <TransactionOverviewConnection transaction={transaction} />
            </Summary.Value>
          </SummaryGrid.Item>
          <SummaryGrid.Item>
            <Summary.Key>{`${isPayout ? 'Payout' : 'Transaction'} ID`}</Summary.Key>
            <Summary.Value monospace>{transaction.id}</Summary.Value>
          </SummaryGrid.Item>
          <SummaryGrid.Item>
            {isPayout ? (
              <>
                <Summary.Key>External identifier</Summary.Key>
                <Summary.Value monospace>
                  {transaction.externalIdentifier}
                </Summary.Value>
              </>
            ) : (
              <>
                <Summary.Key>
                  <Stack direction="row" gap={4}>
                    Reconciliation ID
                    <Tooltip content="The reconciliation ID is passed to connections to help identify Gr4vy transactions for reconciliation purposes.">
                      <Icon name="info" size="small" />
                    </Tooltip>
                  </Stack>
                </Summary.Key>
                <Summary.Value monospace>
                  {transaction.reconciliationId}
                </Summary.Value>
              </>
            )}
          </SummaryGrid.Item>
          <SummaryGrid.Item>
            {isPayout ? (
              <>
                <Summary.Key>{"Connection's payout ID"}</Summary.Key>
                <Summary.Value monospace>
                  {transaction.paymentServicePayoutId}
                </Summary.Value>
              </>
            ) : (
              <>
                <Summary.Key>External identifier</Summary.Key>
                <Summary.Value monospace>
                  {transaction.externalIdentifier}
                </Summary.Value>
              </>
            )}
          </SummaryGrid.Item>
        </SummaryGrid>
      </Container>
    </Box>
  )
}

export default TransactionOverview
