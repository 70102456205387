import { generatePath } from 'react-router-dom'
import { PaymentMethodTabs } from 'shared/constants'

export const PAYMENT_METHODS_PATH =
  '/merchants/:merchantAccountId/payment-methods'
export const PAYMENT_METHOD_PATH = `${PAYMENT_METHODS_PATH}/:paymentMethodId`

export const pathTo = {
  paymentMethodId: (
    merchantAccountId: string,
    paymentMethodId: string,
    tab: PaymentMethodTabs = PaymentMethodTabs.overview
  ) =>
    `${generatePath(PAYMENT_METHOD_PATH, {
      merchantAccountId,
      paymentMethodId,
    })}/${tab}`,
}
