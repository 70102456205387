import { uniq } from 'lodash'
import { RuleOutcome } from 'flows/constants/rule'

export const getCardOutcomeConnectorIds = (outcome?: RuleOutcome) => {
  if (!outcome || !('version' in outcome)) {
    return []
  }

  return uniq(
    outcome.result.map((connector) =>
      typeof connector === 'string' ? connector : connector.paymentServiceId
    )
  )
}
