import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { is } from 'shared/helpers/is'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import {
  advancedSearchOptions as defaultAdvancedSearchOptions,
  reconciliationId,
  connectorId,
  metadata,
  merchantReference,
  id,
  label,
  email,
  last4,
  fingerprint,
  SearchOption,
  giftCardLast4,
} from 'transactions/constants'
import {
  getDefaultValue,
  getFiltersBySearch,
  getKeyFromString,
} from 'transactions/helpers/advanced-search'

const useAdvancedSearch = (search = '') => {
  const hasBillingDetailsPermission = useResourcePermission(
    Resource.buyersBillingDetails,
    AccessLevel.read
  )
  const [searchParams] = useSearchParams()
  const [open, setOpen] = useState(false)
  const searchArr = useMemo(
    () =>
      search
        ?.split(/\b\s/g)
        .filter(Boolean)
        .map((value) => value.trim()),
    [search]
  )

  const advancedSearchOptions = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { email, ...restrictedAdvancedSearchOptions } = {
      ...defaultAdvancedSearchOptions,
    } as SearchOption

    return hasBillingDetailsPermission
      ? defaultAdvancedSearchOptions
      : (restrictedAdvancedSearchOptions as Omit<SearchOption, 'email'>)
  }, [hasBillingDetailsPermission])

  const commonSearchResults = Object.keys(advancedSearchOptions).filter((key) =>
    searchArr.find((currSearch) =>
      advancedSearchOptions[
        key as keyof typeof advancedSearchOptions
      ].title.startsWith(currSearch)
    )
  )

  const metadataSearchResults = useMemo(
    () =>
      searchArr.filter(
        (currSearch) =>
          !is.emptyStringOrWhitespace(currSearch) &&
          !(
            [
              id,
              reconciliationId,
              connectorId,
              label,
              last4,
              giftCardLast4,
              merchantReference,
              email,
              fingerprint,
            ] as string[]
          ).includes(getKeyFromString(currSearch))
      ),
    [searchArr]
  )

  const hasCommonSearchResults = !!commonSearchResults.length
  const hasMetadataSearchResults = !!metadataSearchResults.length

  const advancedSearchOptionsFilteredByValue = hasCommonSearchResults
    ? commonSearchResults
    : [metadata]

  const filteredAdvancedSearchOptions =
    advancedSearchOptionsFilteredByValue.length &&
    !!searchArr.find(
      (currSearch) => currSearch.length >= 2 && !currSearch.includes('=')
    )
      ? advancedSearchOptionsFilteredByValue
      : Object.keys(advancedSearchOptions)

  const hasCommonSearchInValue = (option: string) =>
    getKeyFromString(option) !== metadata &&
    !!search.match(
      new RegExp(
        `\\s${getKeyFromString(option)}=|^${getKeyFromString(option)}=`
      )
    )

  const hasMetadataSearchInValue = (option: string) =>
    getKeyFromString(option) === metadata &&
    hasMetadataSearchResults &&
    !!metadataSearchResults.find((metadataFitler) =>
      metadataFitler.includes('=')
    )

  const isActiveOption = (option: string) =>
    hasCommonSearchInValue(option)
      ? hasCommonSearchInValue(option)
      : hasMetadataSearchInValue(option)

  const defaultValue = useMemo(
    () => getDefaultValue(searchParams),
    [searchParams]
  )

  return {
    open,
    setOpen,
    filteredAdvancedSearchOptions,
    advancedSearchOptions,
    isActiveOption,
    getFiltersBySearch,
    defaultValue,
  }
}
export default useAdvancedSearch
