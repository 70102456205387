import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { HomeFilters } from 'home/constants/filter'
import {
  generateDateRange,
  generateSeries,
  transformSeriesData,
} from 'home/helpers/data'
import {
  getHealthDashboardTotalTransactions,
  Period,
} from 'home/services/health-dashboard'
import { HEALTH_DASHBOARD_TOTAL_TRANSACTIONS } from 'shared/constants'

export const useTotalTransactions = (filters: Partial<HomeFilters>) => {
  const { merchantAccountId } = useParams<{
    merchantAccountId: string
  }>()

  const {
    data: totalTransactions,
    isLoading: isLoadingTotalTransactions,
    isError: isErrorTotalTransactions,
  } = useQuery({
    queryKey: [
      HEALTH_DASHBOARD_TOTAL_TRANSACTIONS,
      merchantAccountId,
      filters.period,
    ],
    queryFn: () =>
      getHealthDashboardTotalTransactions({
        period: filters.period as Period,
      }),
    select: transformSeriesData,
    meta: {
      error: {
        ignore: [404],
      },
    },
    enabled: !!filters.period && filters.filterBy === 'transactions',
  })

  const dateRange = useMemo(
    () => generateDateRange(totalTransactions?.series),
    [totalTransactions?.series]
  )

  const series = useMemo(
    () =>
      generateSeries({
        series: totalTransactions?.series,
        period: filters.period as Period,
      }),
    [totalTransactions?.series, filters.period]
  )

  return {
    totalTransactions,
    isLoadingTotalTransactions,
    isErrorTotalTransactions,
    transactionsDateRange: dateRange,
    transactionsSeries: series,
  }
}
