import { Flex, FlexProps } from '@gr4vy/poutine-react'
import styles from './Item.module.scss'

type ItemProps = Omit<FlexProps, 'size'> & {
  active?: boolean
}

const Item = ({ children, active, className, ...props }: ItemProps) => (
  <Flex
    as="li"
    alignItems="center"
    className={[styles.item, active && styles.active, className]}
    role="menuitem"
    tabIndex={0}
    gap={8}
    data-selected={active ? 'true' : 'false'}
    {...props}
  >
    {children}
  </Flex>
)

export default Item
