import {
  ColorGray70,
  ColorGreen60,
  ColorRed80,
} from '@gr4vy/poutine-tokens/js/tokens'
import { ReportExecution } from 'reports/services/report-execution'

export const statusesMap: Record<
  ReportExecution['status'],
  { color: string; style: string }
> = {
  dispatched: {
    color: ColorGray70,
    style: 'processing',
  },
  pending: {
    color: ColorGray70,
    style: 'processing',
  },
  processing: {
    color: ColorGray70,
    style: 'processing',
  },
  failed: {
    color: ColorRed80,
    style: '',
  },
  succeeded: {
    color: ColorGreen60,
    style: '',
  },
}
