import { AxiosRequestConfig } from 'axios'
import { getMerchantAccountIdFromUrl } from 'shared/helpers/routes'

export const getMerchantAccountHeaderId = (config: AxiosRequestConfig<any>) => {
  const merchantAccountId = getMerchantAccountIdFromUrl()

  const adminUrls = [
    '/api-key-pairs',
    '/audit-logs',
    '/merchant-accounts',
    '/users',
  ]
  const isAdminUrl = adminUrls.includes(config.url || '')

  if (config.method === 'get' && isAdminUrl) {
    return '*'
  }

  return merchantAccountId
}
