import {
  Alert,
  atoms,
  Flex,
  Heading,
  Stack,
  Text,
  TextLink,
} from '@gr4vy/poutine-react'
import { Form, Select } from 'antd'
import { TransactionActionOutcome } from 'flows/constants'
import { FormItem } from 'shared/components/FormItem'

export type ConnectionStepProps = {
  selectedConnection?: TransactionActionOutcome
  outcomes?: TransactionActionOutcome[]
  loading: boolean
  hidden: boolean
}

export const ConnectionStep = ({
  selectedConnection,
  outcomes = [],
  loading,
  hidden,
}: ConnectionStepProps) => {
  const form = Form.useFormInstance()

  const resetConnectionSettings = () => {
    form.resetFields([
      'instrument',
      'transformationsForceMit',
      'transformationsUseAdditionalScheme',
    ])
  }

  return (
    <Stack gap={8} display={hidden ? 'none' : 'flex'}>
      <FormItem
        name="paymentServiceId"
        label={
          <Heading as="h5" level={5}>
            Connection
          </Heading>
        }
        rules={[{ required: true, message: 'Select a connection.' }]}
        hidden={hidden}
      >
        <Select
          placeholder="Select connection"
          dropdownStyle={{ pointerEvents: 'auto' }}
          listHeight={170}
          loading={loading}
          disabled={loading}
          onSelect={resetConnectionSettings}
        >
          {outcomes
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                <Flex gap={8} alignItems="center" height="full">
                  <img
                    alt={item.displayName}
                    className={atoms({ height: 24, width: 24 })}
                    src={item.iconUrl}
                  />
                  <Text as="span" truncate>
                    {item.label}
                  </Text>
                </Flex>
              </Select.Option>
            ))}
        </Select>
      </FormItem>
      {selectedConnection && !selectedConnection.openLoop && (
        <Alert variant="information" gap={8} padding={16}>
          <Alert.Icon size="medium" />
          <Alert.Content>
            <Alert.Text>
              {selectedConnection.displayName} is a closed loop connection, this
              means that subsequent recurring transactions can only occur if the
              initial transaction was made via the same connection. To learn
              more about closed and open loop connections please refer to the{' '}
              <TextLink
                href="https://docs.gr4vy.com/guides/features/recurring-payments/open-loop"
                opensInNewWindow
              >
                Gr4vy&rsquo;s docs
              </TextLink>
              .
            </Alert.Text>
          </Alert.Content>
        </Alert>
      )}
    </Stack>
  )
}
