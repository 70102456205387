import { Box } from '@gr4vy/poutine-react'
import * as React from 'react'

export const Card = (props: {
  className: string
  children: React.ReactNode
}) => {
  const { className, children, ...rest } = props
  return (
    <Box
      fontSize="md"
      borderWidth="sm"
      borderRadius="rounded"
      className={className}
      {...rest}
    >
      {children}
    </Box>
  )
}
