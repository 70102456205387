import {
  Box,
  ButtonLink,
  Divider,
  Flex,
  Icon,
  Popover,
  Stack,
  Text,
} from '@gr4vy/poutine-react'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { pathTo as adminPathTo } from 'admin/paths'
import DebouncedSearchBar from 'shared/components/DebouncedSearchBar'
import { getEntityPathFromUrl } from 'shared/helpers/routes'
import { MerchantAccount } from 'shared/services/merchant-accounts'
import styles from './MerchantAccountDropdown.module.scss'
import { TriggerButton } from './TriggerButton'

interface MerchantAccountDropdownProps {
  options: MerchantAccount[]
  active?: boolean
  value?: string
  readOnly?: boolean
  merchantManagementEnabled?: boolean
}

export const MerchantAccountDropdown = ({
  active,
  options,
  readOnly,
  value,
  merchantManagementEnabled,
}: MerchantAccountDropdownProps) => {
  const queryClient = useQueryClient()
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)
  const entityPath = getEntityPathFromUrl()

  const onChangeMerchantAccountId = () => {
    // clear react-query cache to avoid issues showing wrong merchant account data
    queryClient.clear()
    setOpen(false)
  }

  const handleOpenChange = (open: boolean) => {
    if (open) {
      setSearch('')
    }

    setOpen(open)
  }

  const merchantAccountDisplayName = useMemo(
    () =>
      options.find((merchantAccount) => value === merchantAccount.id)
        ?.displayName,
    [options, value]
  )

  const filteredMerchantAccounts = useMemo(
    () =>
      options
        .filter((merchantAccount) =>
          merchantAccount.displayName
            .toLowerCase()
            .includes(search.toLowerCase())
        )
        .sort((a, b) =>
          a.displayName.localeCompare(b.displayName, undefined, {
            sensitivity: 'base',
          })
        ),
    [options, search]
  )

  const label =
    active && merchantAccountDisplayName
      ? merchantAccountDisplayName
      : 'Select a merchant'

  return (
    <Popover open={open} onOpenChange={handleOpenChange}>
      <Popover.Trigger asChild>
        <TriggerButton open={open} readOnly={readOnly}>
          {label}
        </TriggerButton>
      </Popover.Trigger>
      <Popover.Content
        align="start"
        side="bottom"
        avoidCollisions={false}
        className={styles.content}
      >
        <Stack gap={16} paddingX={24} paddingY={24}>
          <DebouncedSearchBar
            placeholder="Search merchant..."
            onChange={(value) => setSearch(value || '')}
            size="medium"
            hideLabel
          />
          <Divider width="md" />
          <Stack
            background="white"
            gap={8}
            borderRadius="rounded"
            className={styles.accounts}
          >
            {!!filteredMerchantAccounts.length ? (
              filteredMerchantAccounts.map((merchantAccount) => (
                <Link
                  key={merchantAccount.id}
                  className={clsx([
                    styles.item,
                    active && merchantAccount.id === value && styles.active,
                  ])}
                  to={`/merchants/${merchantAccount.id}/${entityPath ?? ''}`}
                  onClick={onChangeMerchantAccountId}
                >
                  <Text variant="med3">{merchantAccount.displayName}</Text>
                  <Text fontFamily="mono" color="gray70">
                    {merchantAccount.id}
                  </Text>
                </Link>
              ))
            ) : (
              <Flex alignItems="center" height={80} padding={16}>
                <Text variant="code1">No merchants found.</Text>
              </Flex>
            )}
          </Stack>
          {merchantManagementEnabled && (
            <Stack gap={16}>
              <Divider width="md" />
              <Box borderRadius="rounded">
                <ButtonLink
                  variant="tertiary"
                  size="small"
                  onClick={() => setOpen(false)}
                  href={adminPathTo.admin}
                >
                  Manage merchants
                  <Icon name="arrow-right-md" />
                </ButtonLink>
              </Box>
            </Stack>
          )}
        </Stack>
      </Popover.Content>
    </Popover>
  )
}
