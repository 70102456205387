import { dateFormat } from 'shared/helpers/date-format'
import { getCurrentLocale } from 'shared/helpers/locale'

export const eventDateFormat = (date: string) =>
  dateFormat(date, getCurrentLocale(), {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  })
