import {
  Stack,
  Text,
  Grid,
  GridItem,
  Checkbox,
  TextLink,
  atoms,
} from '@gr4vy/poutine-react'
import { Input } from 'antd'
import { snakeCase } from 'lodash'
import { useState } from 'react'
import { AddConnectionForm } from 'connections/components/AddConnectionForm/AddConnectionForm'
import { CredentialsChecker } from 'connections/components/CredentialsChecker'
import {
  AntiFraudService,
  AntiFraudServiceDefinition,
  validateAntiFraudService,
} from 'connections/services'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'

type FormValues = {
  displayName: string
  siteId: string
  secretKey: string
  merchantId: string
  merchantDomain: string
  merchantName: string
  active: boolean
}

interface Props {
  saving: boolean
  definition: AntiFraudServiceDefinition
  onSubmit: (
    body: Omit<AntiFraudService, 'id' | 'type' | 'createdAt' | 'updatedAt'> & {
      fields: { key: string; value: string }[]
    }
  ) => void
  onCancel: () => void
}

export const Kount = ({ saving, definition, onSubmit, onCancel }: Props) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    displayName: definition.displayName,
    siteId: '',
    secretKey: '',
    merchantId: '',
    merchantDomain: '',
    merchantName: '',
    active: false,
  })
  const handleSubmit = ({ active, displayName, ...fields }: FormValues) => {
    setInitialValues({ active, displayName, ...fields })
    onSubmit({
      active,
      displayName,
      antiFraudServiceDefinitionId: 'kount-anti-fraud',
      fields: Object.keys(fields)
        .map((key) => ({
          key: snakeCase(key),
          value: fields[key as keyof typeof fields],
        }))
        .filter((entry) => !!entry.value),
    })
  }

  return (
    <AddConnectionForm
      saving={saving}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      title="Add new anti-fraud connection"
    >
      <Grid>
        <GridItem gridColumn="span 6">
          <Stack gap={16}>
            <Stack gap={8}>
              <Text>
                Learn how to configure Kount in{' '}
                <TextLink
                  href="https://developer.kount.com/hc/en-us/articles/4411889747988-How-to-Create-an-API-Key-for-Authentication-to-Kount"
                  opensInNewWindow
                >
                  this quick start guide
                </TextLink>
                . We will help you find all the information you need to set up
                your anti-fraud service correctly.
              </Text>
            </Stack>
            <Stack gap={24}>
              <FormItem
                name="merchantId"
                label={<Label>Merchant ID</Label>}
                required
                rules={[{ required: true, message: 'This field is required' }]}
                extra={
                  <Text
                    as="span"
                    className={atoms({ fontSize: 'xs', paddingTop: 4 })}
                  >
                    The unique ID of your Kount merchant account.
                  </Text>
                }
              >
                <Input />
              </FormItem>
              <FormItem
                name="apiKey"
                label={<Label>API Key</Label>}
                required
                rules={[{ required: true, message: 'This field is required' }]}
                extra={
                  <Text
                    as="span"
                    className={atoms({ fontSize: 'xs', paddingTop: 4 })}
                  >
                    The{' '}
                    <TextLink
                      href="https://awc.test.kount.net/users/api/keys.html"
                      opensInNewWindow
                    >
                      API key
                    </TextLink>{' '}
                    used to connect to the Kount API.
                  </Text>
                }
              >
                <Input />
              </FormItem>
              <CredentialsChecker
                fieldNames={['apiKey']}
                verifyCredentials={validateAntiFraudService}
                antiFraudServiceDefinitionId="kount-anti-fraud"
              />
            </Stack>
          </Stack>
        </GridItem>
      </Grid>
      <FormItem hidden={true} name="active">
        <Checkbox>Active</Checkbox>
      </FormItem>
    </AddConnectionForm>
  )
}
