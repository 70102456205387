import { isArray, isEmpty, isEqual, isObject, isPlainObject, xor } from 'lodash'

// Normalize empty strings (result of clearing a field)
// to undefined (default antd input value) when comparing.
const normalizeEmptyValues = (source: any) => {
  if (source === '') {
    source = undefined
  }

  if (isPlainObject(source)) {
    Object.keys(source).forEach((field) => {
      if (isPlainObject(source[field])) {
        normalizeEmptyValues(source[field])
      } else if (source[field] === '') {
        source[field] = undefined
      }
    })
  }

  return source
}

export const objectComparer = (
  objValue: Record<string, any>,
  otherValue: Record<string, any>
) => {
  const keys = Object.keys(objValue)
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    if (isArray(objValue[key])) {
      if (
        isObject(objValue[key][0]) &&
        !isEqual(objValue[key], otherValue[key])
      ) {
        return false
      } else if (
        !isObject(objValue[key][0]) &&
        !isEmpty(xor(objValue[key], otherValue[key]))
      ) {
        return false
      }
    } else {
      if (
        !isEqual(
          normalizeEmptyValues(objValue[key]),
          normalizeEmptyValues(otherValue[key])
        )
      ) {
        return false
      }
    }
  }

  return true
}
