import { Navigate, useParams } from 'react-router-dom'
import { useSession } from 'session'
import { UserTabs } from 'shared/constants'
import { pathTo as errorsPathTo } from 'shared/paths/errors'
import {
  EDIT_USER_NAME_PATH,
  NEW_USER_DETAILS_PATH,
  NEW_USER_INVITE_PATH,
  NEW_USER_ROLES_PATH,
  pathTo,
  UPDATED_USER_NAME_PATH,
  USERS_PATH,
  USER_OVERVIEW_PATH,
  USER_PATH,
  NEW_USER_PATH,
  NEW_USER_MERCHANT_ACCOUNT_PATH,
  NEW_USER_PERMISSIONS_PATH,
  UPDATED_USER_PATH,
  UPDATED_USER_MERCHANTS_PATH,
  EDIT_USER_ROLES_PATH,
  EDIT_USER_PATH,
  EDIT_USER_MERCHANT_ACCOUNT_PATH,
  EDIT_USER_PERMISSIONS_PATH,
} from 'shared/paths/users'
import { Resource } from 'shared/permissions/constants'
import UpdatedUserNamePage from 'users/pages/UpdatedUserNamePage'
import UserAccessPage from 'users/pages/UserAccessPage'
import UserEditNamePage from 'users/pages/UserEditNamePage'
import UserInvitePage from 'users/pages/UserInvitePage'
import UserOverviewPage from 'users/pages/UserOverviewPage'
import UsersPage from 'users/pages/UsersPage'
import UserAdditionalPermissionsPage from './pages/UserAdditionalPermissionsPage'
import UserDetailsPage from './pages/UserDetailsPage'
import UserMerchantAccounts from './pages/UserMerchantAccountsPage'
import UserRolesPage from './pages/UserRolesPage'
import UserUpdatedMerchantsPage from './pages/UserUpdatedMerchantsPage'
import UserUpdatedRolesPage from './pages/UserUpdatedRolesPage'

export default [
  {
    title: 'Users',
    key: 'users',
    path: USERS_PATH,
    menu: false,
    component: UsersPage,
    resource: Resource.users,
  },
  {
    title: 'Access type',
    key: 'user-access-type',
    path: NEW_USER_PATH,
    menu: false,
    component: UserAccessPage,
    resource: Resource.users,
  },
  {
    title: 'Access type',
    key: 'edit-user-access-type',
    path: EDIT_USER_PATH,
    menu: false,
    component: UserAccessPage,
    resource: Resource.users,
  },
  {
    title: 'Merchant account',
    key: 'user-merchant-account',
    path: NEW_USER_MERCHANT_ACCOUNT_PATH,
    menu: false,
    component: UserMerchantAccounts,
    resource: Resource.users,
  },
  {
    title: 'Merchant account',
    key: 'edit-user-merchant-account',
    path: EDIT_USER_MERCHANT_ACCOUNT_PATH,
    menu: false,
    component: UserMerchantAccounts,
    resource: Resource.users,
  },
  {
    title: 'User roles',
    key: 'user-roles',
    path: NEW_USER_ROLES_PATH,
    menu: false,
    component: UserRolesPage,
    resource: Resource.users,
  },
  {
    title: 'User roles',
    key: 'edit-user-roles',
    path: EDIT_USER_ROLES_PATH,
    menu: false,
    component: UserRolesPage,
    resource: Resource.users,
  },
  {
    title: 'Additional permissions',
    key: 'additional-permissions',
    path: NEW_USER_PERMISSIONS_PATH,
    menu: false,
    component: UserAdditionalPermissionsPage,
    resource: Resource.users,
  },
  {
    title: 'Additional permissions',
    key: 'edit-additional-permissions',
    path: EDIT_USER_PERMISSIONS_PATH,
    menu: false,
    component: UserAdditionalPermissionsPage,
    resource: Resource.users,
  },
  {
    title: 'User details',
    key: 'user-details',
    path: NEW_USER_DETAILS_PATH,
    menu: false,
    component: UserDetailsPage,
    resource: Resource.users,
  },
  {
    title: 'Edit name',
    key: 'edit-user-name',
    path: EDIT_USER_NAME_PATH,
    menu: false,
    resource: Resource.users,
    component: function EditName() {
      const { user, isStaff } = useSession()
      const { id } = useParams() as {
        id: string
      }

      return isStaff && user && user.id === id ? (
        <Navigate replace to={errorsPathTo.accessDenied} />
      ) : (
        <UserEditNamePage title="Edit name" />
      )
    },
  },
  {
    title: 'Updated name',
    key: 'updated-user-name',
    path: UPDATED_USER_NAME_PATH,
    menu: false,
    component: UpdatedUserNamePage,
    resource: Resource.users,
  },
  {
    title: 'Updated user',
    key: 'updated-user',
    path: UPDATED_USER_PATH,
    menu: false,
    component: UserUpdatedRolesPage,
    resource: Resource.users,
  },
  {
    title: 'Updated user merchant accounts',
    key: 'updated-user-merchants',
    path: UPDATED_USER_MERCHANTS_PATH,
    menu: false,
    component: UserUpdatedMerchantsPage,
    resource: Resource.users,
  },
  {
    title: 'Review and invite user',
    key: 'user-review-invite',
    path: NEW_USER_INVITE_PATH,
    menu: false,
    component: UserInvitePage,
    resource: Resource.users,
  },
  {
    title: 'User',
    longTitle: 'User Overview',
    key: 'user-overview',
    path: USER_OVERVIEW_PATH,
    component: UserOverviewPage,
    menu: false,
  },
  {
    title: 'User',
    longTitle: 'User Overview',
    key: 'user',
    path: USER_PATH,
    menu: false,
    component: function Default() {
      const { id } = useParams() as {
        id: string
      }

      return <Navigate replace to={pathTo.userTab(id, UserTabs.details)} />
    },
  },
]
