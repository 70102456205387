import { SummaryGrid } from 'shared/components/SummaryGrid'
import { SummaryGroup } from 'shared/components/SummaryGroup'
import { countryCodes } from 'shared/constants'
import { BuyerTaxId } from 'transactions/services'

export function BuyerPanelTaxInformationFields({
  taxInfo,
}: {
  taxInfo?: BuyerTaxId
}) {
  let taxType
  let countryCode

  if (taxInfo?.kind) {
    ;[countryCode, taxType] = taxInfo.kind.split('.')
  }

  return (
    <SummaryGroup title="Tax information">
      <SummaryGrid gridTemplateColumns={{ xs: 1, md: 2, lg: 3 }}>
        <SummaryGrid.Item>
          <SummaryGrid.Key>Tax ID</SummaryGrid.Key>
          <SummaryGrid.Value>{taxInfo?.value}</SummaryGrid.Value>
        </SummaryGrid.Item>
        <SummaryGrid.Item>
          <SummaryGrid.Key>Tax type</SummaryGrid.Key>
          <SummaryGrid.Value>
            {taxType && taxType.toUpperCase()}
          </SummaryGrid.Value>
        </SummaryGrid.Item>
        <SummaryGrid.Item>
          <SummaryGrid.Key>Country</SummaryGrid.Key>
          <SummaryGrid.Value>
            {countryCode && countryCodes[countryCode.toUpperCase()].name}
          </SummaryGrid.Value>
        </SummaryGrid.Item>
      </SummaryGrid>
    </SummaryGroup>
  )
}
