import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import {
  EditConnectionForm,
  EditConnectionFormData,
} from 'connections/components/EditConnectionForm/EditConnectionForm'
import { useGiftCardService } from 'connections/hooks/use-gift-card-service'
import { useGiftCardServices } from 'connections/hooks/use-gift-card-services'
import { GiftCardServiceUpdate } from 'connections/services'
import { pathTo } from 'shared/paths/connections'

interface EditGiftCardServiceFormProps {
  children: ReactNode
  submitButtonText: string
}

export const EditGiftCardServiceForm = ({
  children,
  submitButtonText,
}: EditGiftCardServiceFormProps) => {
  const { id, merchantAccountId } = useParams<{
    id: string
    merchantAccountId: string
  }>()

  const { remove, update } = useGiftCardServices()

  const { giftCardService, isLoading } = useGiftCardService(
    merchantAccountId,
    id
  )

  const mapFormToService = (values: EditConnectionFormData) => {
    const payload = {
      ...values,
      id,
      fields: [],
    } as GiftCardServiceUpdate
    if (values.hasOwnProperty('fields')) {
      const fields = Object.keys(values.fields).map((key) => ({
        key: key,
        value: values.fields[key],
      }))
      payload['fields'] = fields.filter((field) => field.value != undefined)
    }

    return payload
  }

  return (
    <EditConnectionForm
      subNavigation={
        merchantAccountId && id
          ? [
              {
                title: 'Overview',
                url: `${pathTo.addConnectionGiftCardService(
                  merchantAccountId,
                  id
                )}/overview`,
              },
              {
                title: 'Credentials',
                url: `${pathTo.addConnectionGiftCardService(
                  merchantAccountId,
                  id
                )}/credentials`,
              },
            ]
          : []
      }
      submitButtonText={submitButtonText}
      data={giftCardService}
      isLoading={isLoading}
      remove={remove}
      update={update}
      mapFormToService={mapFormToService}
    >
      {children}
    </EditConnectionForm>
  )
}
