import { generatePath } from 'react-router-dom'
export const CONNECTIONS_PATH = '/merchants/:merchantAccountId/connections'
export const CONNECTION_PAYMENT_SERVICE = `${CONNECTIONS_PATH}/payment-services/:id`
export const CONNECTION_ANTI_FRAUD_SERVICE = `${CONNECTIONS_PATH}/anti-fraud-services/:definitionId`
export const CONNECTION_ANTI_FRAUD_CYBERSOURCE_SERVICE = `${CONNECTIONS_PATH}/anti-fraud-services/cybersource-anti-fraud/:id`
export const CONNECTION_ANTI_FRAUD_SIFT_SERVICE = `${CONNECTIONS_PATH}/anti-fraud-services/sift-anti-fraud/:id`
export const CONNECTION_ANTI_FRAUD_FORTER_SERVICE = `${CONNECTIONS_PATH}/anti-fraud-services/forter-anti-fraud/:id`
export const CONNECTION_ANTI_FRAUD_KOUNT_SERVICE = `${CONNECTIONS_PATH}/anti-fraud-services/kount-anti-fraud/:id`
export const CONNECTION_ANTI_FRAUD_SIMULATOR_SERVICE = `${CONNECTIONS_PATH}/anti-fraud-services/mock-anti-fraud/:id`
export const CONNECTION_APPLE_PAY = `${CONNECTIONS_PATH}/digital-wallets/apple-pay/:id`
export const CONNECTION_SELECT_APPLE_PAY = `${CONNECTIONS_PATH}/select/apple-pay`
export const CONNECTION_SELECT_CLICK_TO_PAY = `${CONNECTIONS_PATH}/select/click-to-pay`
export const CONNECTION_GIFT_CARD_SERVICE = `${CONNECTIONS_PATH}/gift-card-services/:id`
export const CONNECTION_GOOGLE_PAY = `${CONNECTIONS_PATH}/digital-wallets/google-pay/:id`
export const CONNECTION_CLICK_TO_PAY = `${CONNECTIONS_PATH}/digital-wallets/click-to-pay/:id`
export const CONNECTION_SELECT_GOOGLE_PAY = `${CONNECTIONS_PATH}/select/google-pay`
export const CONNECTIONS_CONFIGURED = `${CONNECTIONS_PATH}/configured`
export const CONNECTIONS_CATALOG = `${CONNECTIONS_PATH}/catalog`

export const pathTo = {
  connectionsConfigured: (merchantAccountId: string) =>
    generatePath(CONNECTIONS_CONFIGURED, { merchantAccountId }),
  connectionsCatalog: (merchantAccountId: string) =>
    generatePath(CONNECTIONS_CATALOG, { merchantAccountId }),
  addConnectionSelector: (
    merchantAccountId: string,
    group: string,
    id: string
  ) => {
    switch (group) {
      case 'payment-service':
        return pathTo.addConnectionPaymentService(merchantAccountId, id)
      case 'anti-fraud-service':
        return pathTo.addConnectionAntiFraudService(merchantAccountId, id)
      case 'digital-wallet':
        if (id === 'apple') {
          return pathTo.addConnectionApplePay(merchantAccountId)
        }
        if (id === 'google') {
          return pathTo.addConnectionGooglePay(merchantAccountId)
        }
        if (id === 'click-to-pay') {
          return pathTo.addConnectionClickToPay(merchantAccountId)
        }

        return undefined
      case 'gift-card-service':
        return pathTo.addConnectionGiftCardService(merchantAccountId, id)
      default:
        return undefined
    }
  },
  addConnectionPaymentService: (merchantAccountId: string, id: string) =>
    generatePath(CONNECTION_PAYMENT_SERVICE, { merchantAccountId, id }),
  addConnectionAntiFraudService: (
    merchantAccountId: string,
    definitionId: string
  ) =>
    generatePath(CONNECTION_ANTI_FRAUD_SERVICE, {
      merchantAccountId,
      definitionId,
    }),
  addConnectionApplePay: (merchantAccountId: string) =>
    generatePath(CONNECTION_SELECT_APPLE_PAY, { merchantAccountId }),
  addConnectionGooglePay: (merchantAccountId: string) =>
    generatePath(CONNECTION_SELECT_GOOGLE_PAY, { merchantAccountId }),
  addConnectionClickToPay: (merchantAccountId: string) =>
    generatePath(CONNECTION_SELECT_CLICK_TO_PAY, { merchantAccountId }),
  addConnectionGiftCardService: (merchantAccountId: string, id: string) =>
    generatePath(CONNECTION_GIFT_CARD_SERVICE, { merchantAccountId, id }),
  editConnectionSelector: (
    merchantAccountId: string,
    group: string,
    id: string,
    serviceDefinitionId: string
  ) => {
    switch (group) {
      case 'payment-service':
        return pathTo.editConnectionPaymentService(merchantAccountId, id)
      case 'anti-fraud-service':
        return pathTo.editConnectionAntiFraudService(
          merchantAccountId,
          serviceDefinitionId,
          id
        )
      case 'digital-wallet':
        if (serviceDefinitionId === 'apple') {
          return pathTo.editConnectionApplePay(merchantAccountId, id)
        }
        if (serviceDefinitionId === 'google') {
          return pathTo.editConnectionGooglePay(merchantAccountId, id)
        }
        if (serviceDefinitionId === 'click-to-pay') {
          return pathTo.editConnectionClickToPay(merchantAccountId, id)
        }

        return undefined
      case 'gift-card-service':
        return pathTo.editConnectionGiftCardService(merchantAccountId, id)
      default:
        return undefined
    }
  },
  editConnectionPaymentService: (merchantAccountId: string, id: string) =>
    `${generatePath(CONNECTION_PAYMENT_SERVICE, {
      merchantAccountId,
      id,
    })}/overview`,
  editConnectionAntiFraudService: (
    merchantAccountId: string,
    definitionId: string,
    id: string
  ) =>
    `${generatePath(CONNECTION_ANTI_FRAUD_SERVICE, {
      merchantAccountId,
      definitionId,
    })}/${id}`,
  editConnectionAntiFraudCybersourceService: (
    merchantAccountId: string,
    id: string
  ) =>
    generatePath(CONNECTION_ANTI_FRAUD_CYBERSOURCE_SERVICE, {
      merchantAccountId,
      id,
    }),
  editConnectionAntiFraudSiftService: (merchantAccountId: string, id: string) =>
    generatePath(CONNECTION_ANTI_FRAUD_SIFT_SERVICE, { merchantAccountId, id }),
  editConnectionAntiFraudForterService: (
    merchantAccountId: string,
    id: string
  ) =>
    generatePath(CONNECTION_ANTI_FRAUD_FORTER_SERVICE, {
      merchantAccountId,
      id,
    }),
  editConnectionAntiFraudKountService: (
    merchantAccountId: string,
    id: string
  ) =>
    generatePath(CONNECTION_ANTI_FRAUD_KOUNT_SERVICE, {
      merchantAccountId,
      id,
    }),
  editConnectionAntiFraudSimulatorService: (
    merchantAccountId: string,
    id: string
  ) =>
    generatePath(CONNECTION_ANTI_FRAUD_SIMULATOR_SERVICE, {
      merchantAccountId,
      id,
    }),
  editConnectionApplePay: (merchantAccountId: string, id: string) =>
    generatePath(CONNECTION_APPLE_PAY, { merchantAccountId, id }),
  editConnectionApplePayOverview: (merchantAccountId: string, id: string) =>
    `${pathTo.editConnectionApplePay(merchantAccountId, id)}/overview`,
  editConnectionApplePayCertificates: (merchantAccountId: string, id: string) =>
    `${pathTo.editConnectionApplePay(merchantAccountId, id)}/certificates`,
  editConnectionApplePayDomains: (merchantAccountId: string, id: string) =>
    `${pathTo.editConnectionApplePay(merchantAccountId, id)}/domains`,
  editConnectionGooglePay: (merchantAccountId: string, id: string) =>
    generatePath(CONNECTION_GOOGLE_PAY, { merchantAccountId, id }),
  editConnectionClickToPay: (merchantAccountId: string, id: string) =>
    generatePath(CONNECTION_CLICK_TO_PAY, { merchantAccountId, id }),
  editConnectionGiftCardService: (merchantAccountId: string, id: string) =>
    `${generatePath(CONNECTION_GIFT_CARD_SERVICE, {
      merchantAccountId,
      id,
    })}/overview`,
}
