import { Button, Icon } from '@gr4vy/poutine-react'
import { useEffect, useState } from 'react'

export type ItemProps = {
  secret?: boolean
}

export const useEditFormItem = ({ secret }: ItemProps) => {
  const [isEditing, setEditing] = useState(false)
  const isMasked = secret && !isEditing
  const placeholder = isMasked ? '********' : ''

  const toggleEditing = () => setEditing(!isEditing)

  const Toggle = ({
    label,
    disabled,
  }: {
    label?: boolean | string
    disabled?: boolean
  }) => {
    label = label ? (isEditing ? 'Close' : 'Edit') : false
    return (
      <Button
        variant="tertiary"
        size="small"
        onClick={toggleEditing}
        disabled={disabled}
      >
        {label}
        <Icon name={isEditing ? 'close-md' : 'edit-pencil-sm'} size="small" />
      </Button>
    )
  }

  useEffect(() => setEditing(false), [secret])

  return {
    placeholder,
    isEditing,
    Toggle,
  }
}
