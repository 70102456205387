import { useContext, useMemo } from 'react'
import MerchantContext from 'App/contexts/MerchantContext'

export const useMerchantAccounts = () => {
  const { merchantAccounts } = useContext(MerchantContext)
  const hasMultipleMerchantAccounts = merchantAccounts.length > 1

  const merchantAccountIds = useMemo(
    () => merchantAccounts.map(({ id }) => id),
    [merchantAccounts]
  )

  return { merchantAccountIds, merchantAccounts, hasMultipleMerchantAccounts }
}
