import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { PathToFunc, useMatchPath } from 'App/hooks/use-match-path'
import { backgroundColor } from 'shared/constants/merchant'
import { AccessLevel, Resource, RestrictAccess } from 'shared/permissions'
import styles from './TopNavLink.module.scss'

export type TopNavLinkProps = {
  path: string
  title: string
  resource?: Resource
  activeMatch?: PathToFunc
}

export const TopNavLink = ({
  path,
  title,
  resource,
  activeMatch,
}: TopNavLinkProps) => {
  const active = !!useMatchPath(activeMatch)
  const link = (
    <NavLink
      className={({ isActive }) =>
        clsx(styles.titleBarLink, {
          [styles.active]: isActive || !!active,
        })
      }
      style={({ isActive }) =>
        isActive || !!active ? { backgroundColor } : undefined
      }
      to={path}
    >
      {title}
    </NavLink>
  )

  return resource ? (
    <RestrictAccess resource={resource} accessLevel={AccessLevel.read}>
      {link}
    </RestrictAccess>
  ) : (
    link
  )
}
