import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Action, Flow } from 'shared/constants'
import { pathTo } from 'shared/paths/flows'
import { useOutcomeOptions } from './useOutcomeOptions'
import { useSelectedRules } from './useSelectedRules'

export const useSelectedConnection = (
  flow: Flow,
  action: Action,
  searchParams: URLSearchParams
) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const navigate = useNavigate()
  const [selectedConnection, setSelectedConnection] = useState(
    searchParams.get('payment_method') ?? ''
  )
  const { options: outcomeOptions } = useOutcomeOptions(
    merchantAccountId,
    flow,
    action
  )

  const filter = outcomeOptions
    .filter((o) => o.key == selectedConnection)
    .flatMap((o) => o.ids)

  const { rules } = useSelectedRules(flow, filter)

  return {
    selectedConnection,
    setSelectedConnection: (value: string) => {
      setSelectedConnection(value)
      navigate(
        pathTo.flow(
          merchantAccountId,
          flow,
          // eslint-disable-next-line camelcase
          new URLSearchParams({ payment_method: value })
        ),
        {
          replace: true,
        }
      )
    },
    filter,
    outcomeOptions,
    rules,
  }
}
