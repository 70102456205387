import { TextLink } from '@gr4vy/poutine-react'

export const NoSearchResults = ({
  clearSearch,
}: {
  clearSearch: (value: string) => void
}) => (
  <>
    <p>
      <TextLink
        href="#"
        onClick={(e) => {
          e.preventDefault()
          clearSearch('')
        }}
      >
        View
      </TextLink>{' '}
      the full configured list or try a another search term.
    </p>
  </>
)
