import { Button, Icon, Tooltip } from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { Dispatch, useState } from 'react'
import { AddMerchantDrawer } from 'admin/components/AddMerchantDrawer'
import { AdminPagesLayout } from 'admin/components/AdminPagesLayout'
import MerchantsTable from 'admin/components/MerchantsTable/MerchantsTable'
import { useSession } from 'session'
import { useMultiMerchant } from 'shared/permissions/hooks/use-multi-merchant'
import { QueryResult } from 'shared/services/client'
import { MerchantAccount } from 'shared/services/merchant-accounts'

export interface Filters {
  limit?: number
  search?: string
}

interface MerchantAccountsPageProps {
  title: string
  page: QueryResult<MerchantAccount>
  filters: Filters
  onFilter: Dispatch<Filters>
  create: UseMutationResult<
    MerchantAccount,
    any,
    Pick<MerchantAccount, 'displayName' | 'id'>,
    unknown
  >
}

const MerchantAccountsPage = ({
  title,
  page,
  filters,
  onFilter,
  create,
}: MerchantAccountsPageProps) => {
  const [openAddMerchantDrawer, setOpenAddMerchantDrawer] = useState(false)
  const { canAccessMultiMerchant } = useMultiMerchant()
  const { isStaff } = useSession()

  const canAddNewMerchantAccounts = isStaff || canAccessMultiMerchant

  return (
    <>
      <AdminPagesLayout
        title={title}
        actions={
          <Tooltip
            content="Premium feature available in multi-merchant environments only"
            show={!canAccessMultiMerchant}
          >
            <span>
              <Button
                variant="primary"
                disabled={!canAddNewMerchantAccounts}
                onClick={() => setOpenAddMerchantDrawer(true)}
              >
                <Icon name="add-plus" />
                Add merchant
              </Button>
            </span>
          </Tooltip>
        }
      >
        <MerchantsTable page={page} pagination={{ filters, onFilter }} />
      </AdminPagesLayout>
      <AddMerchantDrawer
        open={openAddMerchantDrawer}
        onClose={() => setOpenAddMerchantDrawer(false)}
        create={create}
      />
    </>
  )
}

export default MerchantAccountsPage
