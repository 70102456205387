import client from 'shared/services/client'
import { MerchantAccount } from 'shared/services/merchant-accounts'
import { Role } from 'users/services/users'

export type User = {
  name: string
  id: string
  emailAddress: string
  featureResetEndpointEnabled: boolean
  hasValidPassword: boolean
  resetTokenExpiresAt: string | null
  resetToken: string | null
  roles: Role[]
  merchantAccounts: MerchantAccount[]
  category: 'email' | 'sso'
  isStaff: boolean
}

export const getCurrentUser = async (): Promise<User> => {
  const response = await client.get('/users/me')
  return response.data
}
