import {
  Box,
  Button,
  Icon,
  Flex,
  Stack,
  VisuallyHidden,
} from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { uniqueId } from 'lodash'
import {
  useState,
  useRef,
  useMemo,
  DragEvent,
  KeyboardEvent,
  ReactNode,
} from 'react'
import styles from './styles.module.scss'

interface UploaderProps {
  uploading?: boolean
  uploadingText?: string
  children?: string
  className?: string
  onChange?: (files: FileList | null) => void
  placeholder?: string
  variant?: 'standard' | 'small'
  disabled?: boolean
  editToggle?: ReactNode
}

const Uploader = ({
  className,
  uploading,
  uploadingText = 'Uploading...',
  children = 'Drop here the file or click to browse your files.',
  onChange,
  placeholder,
  variant,
  disabled = false,
  editToggle,
}: UploaderProps) => {
  const inputEl = useRef<HTMLInputElement>(null)
  const [hover, setHover] = useState(false)
  const [files, setFiles] = useState<FileList>()

  const handleDrag = (e: DragEvent) => {
    e.stopPropagation()
    e.preventDefault()
    setHover(e.type !== 'dragleave')
  }

  const handleDrop = (e: DragEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if (e.dataTransfer.files.length) {
      onChange && onChange(e.dataTransfer.files)
      setFiles(e.dataTransfer.files)
    }
    setHover(false)
  }

  const handleBrowseClick = () => {
    inputEl?.current?.click()
  }

  const getPlaceholder = () => {
    if (files?.length) {
      return files[0].name
    }
    if (placeholder) {
      return placeholder
    }
    return children
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.code === 'Space') {
      handleBrowseClick()
    }
  }

  const id = useMemo(() => `file${uniqueId()}`, [])

  return (
    <Stack
      className={clsx(className, styles.container, disabled && styles.disabled)}
      gap={24}
    >
      <Box
        as="label"
        htmlFor={id}
        role="button"
        className={clsx(
          styles.dropzone,
          variant && styles[`dropzone--${variant}`],
          {
            [styles.fileHover]: hover,
            [styles.uploading]: uploading,
            [styles.selected]: !!files?.length,
            [styles.disabled]: disabled,
          }
        )}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        {uploading ? (
          <Flex gap={8} alignItems="center">
            <Icon
              name="loader-spinner"
              size="large"
              className={styles.spinner}
            />
            {uploadingText}
          </Flex>
        ) : (
          getPlaceholder()
        )}
      </Box>
      <VisuallyHidden>
        <input
          ref={inputEl}
          id={id}
          type="file"
          onChange={(e) => {
            onChange && onChange(e.target.files)
            if (e.target.files) {
              setFiles(e.target.files)
            }
          }}
          tabIndex={-1}
          disabled={disabled}
        />
      </VisuallyHidden>
      <Flex alignItems="center">
        {!uploading && !disabled && (
          <Button onClick={handleBrowseClick}>Browse...</Button>
        )}
        {editToggle && <Box marginLeft="auto">{editToggle}</Box>}
      </Flex>
    </Stack>
  )
}

export { Uploader }
