import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { LIST_EVENTS } from 'shared/constants/query-keys/transactions'
import { listTransactionEvents, Transaction } from 'transactions/services'

export const useTransactionEvents = (transaction: Transaction) => {
  const events = useQuery({
    queryKey: [LIST_EVENTS, transaction.id],
    queryFn: () => listTransactionEvents(transaction.id),
    enabled: !!transaction?.id,
  })

  const tableData = useMemo(() => events?.data?.items ?? [], [events.data])

  const lastAuthorizationResponse = useMemo(
    () =>
      tableData
        .filter(
          ({ name }) =>
            name.startsWith(
              'payment-connector-response-transaction-authorization'
            ) ||
            name.startsWith('payment-connector-response-transaction-declined')
        )
        .slice(-1)[0],
    [tableData]
  )

  const lastRawResponse = useMemo(() => {
    const { rawResponseCode, rawResponseDescription } =
      lastAuthorizationResponse?.context || {}

    if (rawResponseDescription && rawResponseCode) {
      return `${rawResponseDescription} (${rawResponseCode})`
    }

    return rawResponseDescription ?? rawResponseCode
  }, [lastAuthorizationResponse])

  return {
    tableData,
    lastAuthorizationResponse,
    lastRawResponse,
  }
}
