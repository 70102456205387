import { useParams } from 'react-router-dom'
import { PaymentMethodDefinition } from 'shared/services'
import { usePaymentMethodDefinitions } from './use-payment-method-definitions'

export const usePaymentMethodDefinition = (
  method?: string | null,
  isGiftCard?: boolean
): PaymentMethodDefinition | null => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const definitions = usePaymentMethodDefinitions(merchantAccountId)

  if (isGiftCard) {
    return definitions['gift-card'] || definitions['gift_card'] || null
  }

  if (!method) {
    return null
  }

  return definitions[method] || null
}
