import { Button, Icon, Text } from '@gr4vy/poutine-react'

type ErrorMessageProps = {
  onClose: () => void
}

export const ErrorMessage = ({ onClose }: ErrorMessageProps) => (
  <>
    <Icon name="exclamation-circle-md" size="large" color="red90" />
    <Text fontSize="md">The domain could not be added. Please try again.</Text>
    <Button variant="secondary" onClick={onClose}>
      Close
    </Button>
  </>
)
