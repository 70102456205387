import { GiftCardService } from 'connections/services'
import client from 'shared/services/client'
import { Buyer, GiftCard as TransactionGiftCard } from 'transactions/services'

export interface GiftCard extends TransactionGiftCard {
  merchantAccountId: string
  giftCardService: Pick<
    GiftCardService,
    'type' | 'id' | 'giftCardServiceDefinitionId' | 'displayName'
  >
  expirationDate: string | null
  buyer?: Buyer
  createdAt: string
  updatedAt: string
}

export const getGiftCard = async (id?: string): Promise<GiftCard> => {
  const response = await client.get(`/gift-cards/${id}`)
  return response.data
}
