import { GridItem } from '@gr4vy/poutine-react'
import { Input, Select } from 'antd'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'

interface BooleanInputProps {
  name: number
}

const ERROR_OPERATOR_REQUIRED = 'Operator is required'
const ERROR_VALUE_REQUIRED = 'Value is required'

const BooleanInput = ({ name }: BooleanInputProps) => (
  <>
    <GridItem gridColumn="span 2">
      <FormItem
        label={<Label>Value(s)</Label>}
        name={[name, 'value']}
        rules={[{ required: true, message: ERROR_VALUE_REQUIRED }]}
        initialValue={true}
      >
        <Select
          allowClear
          maxTagCount="responsive"
          maxTagPlaceholder={maxTagPlaceholderFormatter}
          placeholder="Select an option"
        >
          {['true', 'false'].map((option) => (
            <Select.Option
              value={option === 'true'}
              label={option}
              key={option}
            >
              {`is ${option}`}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        name={[name, 'operator']}
        rules={[{ required: true, message: ERROR_OPERATOR_REQUIRED }]}
        hidden
      >
        <Input />
      </FormItem>
    </GridItem>
  </>
)

export { ERROR_VALUE_REQUIRED, ERROR_OPERATOR_REQUIRED }
export default BooleanInput
