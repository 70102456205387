import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { GET_MERCHANT_ACCOUNT } from 'shared/constants/query-keys/merchants'
import {
  MerchantAccount,
  getMerchantAccount,
  updateMerchantAccountNetworkTokens,
} from 'shared/services/merchant-accounts'
import { handleSuccessResponse } from 'shared/utils/handleSuccessResponse'
import { showErrorMessage } from 'shared/utils/showMessage'
import View from './EditMerchantAccountNetworkTokensPage'

const EditMerchantAccountNetworkTokensPage = ({ title }: { title: string }) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const queryClient = useQueryClient()

  const { data } = useQuery({
    queryKey: [GET_MERCHANT_ACCOUNT, merchantAccountId],
    queryFn: () => getMerchantAccount(merchantAccountId),
    enabled: !!merchantAccountId,
  })

  const update = useMutation({
    mutationFn: updateMerchantAccountNetworkTokens,
    onSuccess: (merchantAccount) => {
      handleSuccessResponse(
        <>{merchantAccount.displayName} network tokens successfully updated.</>
      )(),
        () =>
          queryClient.invalidateQueries({ queryKey: [GET_MERCHANT_ACCOUNT] })
    },
    onError: () =>
      showErrorMessage('Unable to update Merchant details. Please try again.'),
  })

  return (
    <View
      title={title}
      merchantAccount={data as MerchantAccount}
      update={update}
    />
  )
}

export default EditMerchantAccountNetworkTokensPage
