import client, { CollectionResponse } from 'shared/services/client'

export interface ApiLog {
  type: 'api-log'
  id: string
  requestMethod: string
  requestUrl: string
  requestBody?: object
  responseStatusCode: string
  responseSentAt: string
  responseBody?: object
  requestAuth?: {
    header?: {
      alg?: string
      kid?: string
      typ?: string
    }
    payload?: {
      scopes?: string[]
      iat?: number
      nbf?: number
      exp?: number
    }
  }
}

export const listApiLogs = async (): Promise<CollectionResponse<ApiLog>> => {
  const response = await client.get('/api-logs')
  return response.data
}
