import { ignoredPaths, PREVIOUS_PATH_KEY } from 'session/constants/path'
import { getItem, setItem } from 'shared/helpers/localstorage'

export const usePreviousPath = () => {
  const previousPath: string | undefined = getItem(PREVIOUS_PATH_KEY, undefined)
  const setPreviousPath = () => {
    if (!ignoredPaths.includes(location.pathname)) {
      setItem(PREVIOUS_PATH_KEY, `${location.pathname}${location.search}`)
    }
  }

  return { previousPath, setPreviousPath }
}
