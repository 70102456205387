import { createContext } from 'react'
import { useSessionManager } from 'session/hooks/use-session-manager'

export const SessionContext = createContext<
  Omit<ReturnType<typeof useSessionManager>, 'invalidate'>
>({
  signIn: () => {},
  start: () => {},
  signOut: () => {},
  isLoading: true,
  isSignedIn: true,
  isSignedOut: false,
  isStaff: false,
  isSSOEnabled: false,
  signInPath: '/sign-in',
  user: false,
  errors: [],
})
