import {
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  Box,
  Skeleton,
} from '@gr4vy/poutine-react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'shared/paths/users'
import NewUserPageLayout from 'users/components/NewUserPageLayout/NewUserPageLayout'
import { User } from 'users/services/users'

interface UserUpdatedMerchantsPageProps {
  title: string
  user?: User
}

export const HeaderLoader = (
  <>
    <Skeleton width={32}>
      <Skeleton.Box height={32} />
    </Skeleton>
    <Flex flexDirection="column" gap={4}>
      <Skeleton gap={4}>
        <Skeleton.Box style={{ maxWidth: 320 }} height={24} />
      </Skeleton>
    </Flex>
  </>
)

export default function UserUpdatedMerchantsPage({
  title,
  user,
}: UserUpdatedMerchantsPageProps) {
  const navigate = useNavigate()

  return (
    <NewUserPageLayout title={title}>
      {!user ? (
        HeaderLoader
      ) : (
        <>
          <Icon name="circle-check" size="large" color="green60" />
          <Heading as="h4">
            You&apos;ve successfully updated “{user?.name}”.
          </Heading>
        </>
      )}
      <Stack gap={16}>
        <Box>
          <Text variant="reg3" color="black" fontWeight="bold">
            Merchant accounts
          </Text>
          <Text variant="reg3" color="gray90">
            {user?.merchantAccounts
              .sort((a, b) => a.displayName.localeCompare(b.displayName))
              .map((merchantAccount) => merchantAccount.displayName)
              .join(', ')}
          </Text>
        </Box>
      </Stack>
      <Flex gap={16}>
        <Button onClick={() => navigate(pathTo.users)}>See all users</Button>
        {user && (
          <Button
            variant="secondary"
            onClick={() => navigate(pathTo.user(user.id))}
          >
            See user details
          </Button>
        )}
      </Flex>
    </NewUserPageLayout>
  )
}
