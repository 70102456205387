import { ReactNode } from 'react'
import { AccessLevel, Resource } from 'shared/permissions/constants'
import { useResourcePermission } from 'shared/permissions/hooks/use-resource-permission'

export const RestrictAccess = ({
  resource: role,
  accessLevel,
  fallback = null,
  children,
}: {
  resource: Resource
  accessLevel: AccessLevel
  fallback?: ReactNode
  children: ReactNode
}) => {
  const hasPermission = useResourcePermission(role, accessLevel)

  return hasPermission ? <>{children}</> : <>{fallback}</>
}
