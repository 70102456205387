import { Stack, TextLink, Text } from '@gr4vy/poutine-react'

interface AuditLogDescriptionProps {
  type: string
  name: string
  action: string
  href?: string
  after?: boolean
}

const AuditLogDescription = ({
  type,
  name,
  action,
  href,
}: AuditLogDescriptionProps) => (
  <Stack gap="none">
    <Text variant="med3">{type}</Text>
    <Text variant="reg4">
      {href && action !== 'deleted' && <TextLink href={href}>{name}</TextLink>}
      {(!href || action === 'deleted') && name}
    </Text>
  </Stack>
)

export default AuditLogDescription
