import {
  Button,
  Heading,
  Icon,
  Stack,
  Text,
  Tooltip,
} from '@gr4vy/poutine-react'
import { useState } from 'react'
import { ColumnDef, DataTable } from 'shared/components/DataTable'
import { Panel } from 'shared/components/Panel'
import { dateFormat } from 'shared/helpers/date-format'
import { getCurrentLocale } from 'shared/helpers/locale'
import { EventDrawer } from 'transactions/components/EventDrawer'
import { formatEventName } from 'transactions/helpers'
import { useTransactionEvents } from 'transactions/hooks/use-transaction-events'
import {
  PaymentMethod,
  Transaction,
  TransactionEvent,
} from 'transactions/services'
import EventsConnection from './EventsConnection'

const EventsPanel = ({
  transaction,
  paymentMethod,
}: {
  transaction: Transaction
  paymentMethod?: PaymentMethod
}) => {
  const [event, setEvent] = useState<TransactionEvent>()
  const { tableData } = useTransactionEvents(transaction)

  const columns: Array<ColumnDef<TransactionEvent>> = [
    {
      id: 'createdAt',
      accessorKey: 'createdAt',
      size: 100,
      header: 'Date',
      cell: ({ renderValue }) => {
        const createdAt = renderValue<TransactionEvent['createdAt']>()

        return createdAt ? (
          <Text as="span" variant="code1">
            {dateFormat(createdAt, getCurrentLocale(), {
              weekday: 'short',
              day: '2-digit',
              month: 'short',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              timeZoneName: 'short', // TODO: Update to `shortOffset` once supported by TypeScript
            })}
          </Text>
        ) : (
          '–'
        )
      },
    },
    {
      id: 'connection',
      accessorKey: 'connection',
      size: 100,
      header: 'Service',
      cell: ({ row }: { row: any }) => {
        return <EventsConnection event={row.original} />
      },
    },
    {
      id: 'name',
      accessorKey: 'name',
      size: 120,
      header: 'Event',
      cell: ({ getValue }) => formatEventName(getValue() as string),
    },
    {
      id: 'actions',
      size: 40,
      cell: ({ row }: { row: any }) => {
        return (
          <Stack alignItems="end">
            <Tooltip content="Open event details">
              <Button
                variant="tertiary"
                size="small"
                onClick={() => setEvent(row.original)}
              >
                <Icon name="arrow-right-md" />
              </Button>
            </Tooltip>
          </Stack>
        )
      },
    },
  ]

  const onDrawerClose = () => setEvent(undefined)

  return tableData.length ? (
    <>
      <Panel>
        <Panel.Header>
          <Heading level={4}>Events</Heading>
        </Panel.Header>
        <Panel.Content>
          <DataTable data={{ items: tableData }} columns={columns} />
        </Panel.Content>
      </Panel>

      <EventDrawer
        open={!!event}
        title={event && formatEventName(event.name)}
        event={event}
        transaction={transaction}
        paymentMethod={paymentMethod}
        onClose={onDrawerClose}
      />
    </>
  ) : (
    <></>
  )
}

export default EventsPanel
