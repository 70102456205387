import { Description } from '@gr4vy/poutine-react'
import { is } from 'shared/helpers/is'

export interface NumericDescriptionProps {
  children?: string | number
}

export const NumericDescription = ({ children }: NumericDescriptionProps) => {
  return (
    <Description align="flex-end">
      <Description.Text
        style={{
          fontVariantNumeric: 'tabular-nums',
        }}
      >
        {is.undefined(children) ? '-' : String(children)}
      </Description.Text>
    </Description>
  )
}
