import { GridItem } from '@gr4vy/poutine-react'
import { FormInstance, Select } from 'antd'
import { useState } from 'react'
import { Operation } from 'flows/constants'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { RangeAmountCondition } from './RangeAmountCondition'
import { SingleAmountCondition } from './SingleAmountCondition'

const SINGLE_AMOUNT_CONDITONS = [Operation.LESS_THAN, Operation.GREATER_THAN]

export const AmountCondition = ({ form }: { form: FormInstance }) => {
  const [operator, setOperator] = useState(
    form.getFieldValue(['amountCondition', 'operator']) || Operation.IS_BETWEEN
  )
  const onSelect = (value: any) => {
    setOperator(value)
    form.setFieldsValue({
      amountCondition: {
        operator: value,
        value: undefined,
      },
    })
  }
  return (
    <>
      <GridItem gridColumn="span 2" data-testid="amount-condition">
        <FormItem
          label={<Label>Operator</Label>}
          name={['amountCondition', 'operator']}
        >
          <Select
            // @ts-ignore - https://github.com/ant-design/ant-design/issues/21839
            value={null}
            placeholder="Select an operator"
            onSelect={onSelect}
            style={{ width: '100%' }}
          >
            <Select.Option value={Operation.GREATER_THAN}>
              greater than
            </Select.Option>
            <Select.Option value={Operation.IS_BETWEEN}>in range</Select.Option>
            <Select.Option value={Operation.LESS_THAN}>
              lower than
            </Select.Option>
          </Select>
        </FormItem>
      </GridItem>
      {operator === Operation.IS_BETWEEN && <RangeAmountCondition />}
      {SINGLE_AMOUNT_CONDITONS.includes(operator) && <SingleAmountCondition />}
      <GridItem gridColumn="span 1" />
    </>
  )
}
