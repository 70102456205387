import { FilterButton, Popover } from '@gr4vy/poutine-react'
import dayjs, { Dayjs } from 'dayjs'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DateTimeRangePicker } from 'shared/components/FilterDateTimeRangePicker/DateTimeRangePicker'
import { useFilters } from 'shared/hooks'
import { ReportExecutionsFilters } from '../../services/report-execution'

interface Props {
  label: string
}

const ReportDateTimeFilter = ({ label }: Props) => {
  const [filters, setFilters] = useFilters<ReportExecutionsFilters>()
  const [searchParams] = useSearchParams()
  const [open, setOpen] = useState(false)

  const handleApply = (newFilters: Partial<ReportExecutionsFilters>) => {
    setFilters({ ...filters, ...newFilters })
    setOpen(false)
  }

  const handleClear = () => {
    setFilters({
      ...filters,
      createdAtGte: undefined,
      createdAtLte: undefined,
    })
    setOpen(false)
  }

  const dateRangeParams = useMemo(
    () =>
      searchParams.get('createdAtGte') && searchParams.get('createdAtLte')
        ? [
            dayjs(searchParams.get('createdAtGte')),
            dayjs(searchParams.get('createdAtLte')),
          ]
        : undefined,
    [searchParams]
  ) as [Dayjs, Dayjs] | undefined

  const isFilterActive = !!(
    searchParams.get('createdAtGte') && searchParams.get('createdAtLte')
  )

  const disableFutureDates = (current: dayjs.Dayjs) =>
    current && current > dayjs().endOf('day')

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <FilterButton data-selected={isFilterActive}>{label}</FilterButton>
      </Popover.Trigger>
      <Popover.Content align="start" side="bottom" avoidCollisions={false}>
        <DateTimeRangePicker
          range={dateRangeParams}
          disabledDate={disableFutureDates}
          onApply={({ afterCreatedAt, beforeCreatedAt }) =>
            handleApply({
              createdAtGte: afterCreatedAt,
              createdAtLte: beforeCreatedAt,
            })
          }
          onClear={handleClear}
          enablePresets
        />
      </Popover.Content>
    </Popover>
  )
}

export default ReportDateTimeFilter
