import { generatePath } from 'react-router-dom'
export const INTEGRATIONS_PATH = '/merchants/:merchantAccountId/integrations'
export const INTEGRATIONS_LOGS_PATH = `${INTEGRATIONS_PATH}/logs`

export const pathTo = {
  apiKeys: (merchantAccountId: string) =>
    generatePath(INTEGRATIONS_PATH, { merchantAccountId }),
  apiLogs: (merchantAccountId: string) =>
    generatePath(INTEGRATIONS_LOGS_PATH, { merchantAccountId }),
}
