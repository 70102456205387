import { Outcome } from 'flows/constants'
import client, { CollectionResponse } from 'shared/services/client'

export const listOutcomes = async (
  flow: string,
  action: string,
  params?: { paymentMethod?: string }
): Promise<CollectionResponse<Outcome>> => {
  const response = await client.get(
    `/flows/${flow}/actions/${action}/outcomes`,
    {
      params,
    }
  )
  return response.data
}
