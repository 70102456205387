import { Heading, Stack, Text, Flex } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import { useParams } from 'react-router-dom'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { currencies } from 'shared/helpers/currency'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'
import {
  useCardSchemeDefinitions,
  usePaymentMethodDefinitions,
} from 'shared/hooks'

interface Props {
  visible: boolean
}

export const StepPaymentFilters = ({ visible }: Props) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const paymentMethodDefinitions =
    usePaymentMethodDefinitions(merchantAccountId)
  const cardSchemeDefinitions = useCardSchemeDefinitions()
  const paymentMethodDefinitionOptions = Object.values(paymentMethodDefinitions)
  const cardSchemeDefinitionOptions = Object.values(cardSchemeDefinitions)

  return (
    <Stack gap={24} display={visible ? 'flex' : 'none'}>
      <Stack gap={8}>
        <Heading level={5}>Select payment filters</Heading>
        <Text>
          Use the following multi selections to specify the report payment
          parameters. Payment schemes will appear when you select
          &quot;card&quot; from the payment method field.
        </Text>
      </Stack>
      <FormItem label={<Label>Payment method</Label>} name="paymentMethod">
        <Select
          listItemHeight={32}
          placeholder="All payment methods"
          style={{ width: '100%' }}
          dropdownStyle={{ pointerEvents: 'auto' }}
          mode="multiple"
          maxTagCount="responsive"
          maxTagPlaceholder={maxTagPlaceholderFormatter}
          allowClear
        >
          {paymentMethodDefinitionOptions.map(({ id, displayName }) => (
            <Select.Option key={id} value={id}>
              {displayName}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem noStyle shouldUpdate>
        {(form) => {
          return (
            (form.getFieldValue('paymentMethod') || []).includes('card') && (
              <FormItem
                label={<Label>Payment scheme</Label>}
                name="paymentScheme"
              >
                <Select
                  listItemHeight={32}
                  placeholder="All payment schemes"
                  style={{ width: '100%' }}
                  dropdownStyle={{ pointerEvents: 'auto' }}
                  mode="multiple"
                  maxTagCount="responsive"
                  maxTagPlaceholder={maxTagPlaceholderFormatter}
                  allowClear
                >
                  {cardSchemeDefinitionOptions.map(({ id, displayName }) => (
                    <Select.Option key={id} value={id}>
                      {displayName}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            )
          )
        }}
      </FormItem>
      <FormItem label={<Label>Currencies</Label>} name="currency">
        <Select
          listItemHeight={32}
          placeholder="All currencies"
          style={{ width: '100%' }}
          dropdownStyle={{ pointerEvents: 'auto' }}
          mode="multiple"
          maxTagCount="responsive"
          maxTagPlaceholder={maxTagPlaceholderFormatter}
          allowClear
        >
          {currencies.map(({ code, currency }) => (
            <Select.Option key={code} value={code}>
              <Flex gap={8}>
                <Text fontWeight="medium">{code}</Text>
                <Text>{currency}</Text>
              </Flex>
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    </Stack>
  )
}
