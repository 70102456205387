export const convertToBin = (
  binValue: string,
  padding = '0',
  maxLength = 8
) => {
  if (!binValue || binValue.length > maxLength || isNaN(Number(binValue))) {
    return undefined
  }

  const paddedBinValue = binValue.padEnd(maxLength, padding)

  return Number(paddedBinValue)
}

export const validateBinRange = (bin = '', maxLength = 8) => {
  const [start, end] = bin.split('-')
  const startNumber = convertToBin(start, '0', maxLength)
  const endNumber = convertToBin(end, String(maxLength + 1), maxLength)
  return !!startNumber && !!endNumber && startNumber <= endNumber
}
