import { pathTo } from 'shared/paths/errors'
import { AccessDeniedPage, NotFoundPage } from './pages'

/**
 * A list of pages to render, including their icon, component, path, name and
 * ID.
 */
export default [
  {
    title: 'Access Denied',
    key: '401',
    path: pathTo.accessDenied,
    component: AccessDeniedPage,
  },
  {
    title: 'Page Not Found',
    key: '404',
    path: pathTo.notFound,
    component: NotFoundPage,
  },
]
