import { Stack, Grid, GridItem, Timeline } from '@gr4vy/poutine-react'
import { Fragment, ReactNode } from 'react'
import { PageLayout } from 'shared/components/PageLayout'
import { pathTo } from 'shared/paths/users'
import { StepProps } from 'users/constants'
import { useSteps } from 'users/hooks/use-steps'
import styles from './styles.module.scss'

interface NewUserPageLayoutProps {
  title: string
  children: ReactNode
  step?: StepProps['step']
  hasPermissionsStep?: boolean
}

export default function NewUserPageLayout({
  title,
  children,
  step,
  hasPermissionsStep,
}: NewUserPageLayoutProps) {
  const { steps } = useSteps(step, hasPermissionsStep)

  return (
    <PageLayout
      title={title}
      gap={40}
      breadcrumbs={[{ title: 'Users', url: pathTo.users }, { title: title }]}
    >
      <Grid>
        <GridItem gridColumn="span 7">
          <Stack gap={32}>{children}</Stack>
        </GridItem>
        <GridItem
          gridColumn="span 3"
          justifySelf="end"
          className={styles.timeline}
        >
          <Timeline>
            {steps.map((stepItem) => (
              <Fragment key={stepItem.step}>
                <Timeline.Item variant={stepItem.variant} {...stepItem.style}>
                  {stepItem.title}
                </Timeline.Item>
              </Fragment>
            ))}
          </Timeline>
        </GridItem>
      </Grid>
    </PageLayout>
  )
}
