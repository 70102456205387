import { Grid, GridItem, Stack } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import { Section } from 'flows/pages/RulePage/Section'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'

export const OutcomeCardRouting = () => (
  <Stack gap={16}>
    <Section title="Behavior: Retries">
      Gr4vy will automatically retry failed transactions with the next connector
      in your list. The same retry behavior can be configured for declined
      transactions in some situations. To enable this you can configure up to
      three retry attempts. Each connector in the list will only be attempted
      once.
    </Section>
    <Grid>
      <GridItem gridColumn="span 3">
        <FormItem
          label={<Label>Retry declined transactions?</Label>}
          name={['outcome', 'retryDeclines']}
        >
          <Select data-testid="retryDeclines">
            <Select.Option value={0}>No, do not retry</Select.Option>
            <Select.Option value={1}>Yes, retry once</Select.Option>
            {[2, 3].map((value) => (
              <Select.Option
                value={value}
                key={value}
                data-testid={`retryDeclines${value}`}
              >
                Yes, retry {value} times
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      </GridItem>
    </Grid>
  </Stack>
)
