import { useQuery } from '@tanstack/react-query'
import { LIST_FLOW_ACTIONS } from 'shared/constants/query-keys/transactions'
import {
  Transaction,
  listFlowActions,
  TransactionFlowId,
  TransactionFlow,
} from 'transactions/services'
import { CardTransactionFlowPanel } from './CardTransactionFlowPanel'
import { OtherTransactionFlowPanel } from './OtherTransactionFlowPanel'

const formatActions = (transactionActions: TransactionFlow[]) => {
  return transactionActions.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.id]: curr,
    }),
    {}
  ) as Record<TransactionFlowId, TransactionFlow>
}

const TransactionFlowPanel = ({
  transaction,
}: {
  transaction: Transaction
}) => {
  const flowActions = useQuery({
    queryKey: [LIST_FLOW_ACTIONS, transaction.id],
    queryFn: () => listFlowActions(transaction.id),
    enabled: !!transaction.id,
  })

  if (flowActions.isLoading || !flowActions.isSuccess) {
    return null
  }

  const actions = flowActions.data.items
  const cardTransactionActions = actions.filter(
    (action) => action.flow === 'card-transaction'
  )
  const otherTransactionActions = actions.filter((action) =>
    ['redirect-transaction', 'non-card-transaction'].includes(action.flow)
  )

  return (
    <>
      {cardTransactionActions.length > 0 && (
        <CardTransactionFlowPanel
          actions={formatActions(cardTransactionActions)}
          transaction={transaction}
        />
      )}
      {otherTransactionActions.length > 0 && (
        <OtherTransactionFlowPanel
          actions={formatActions(otherTransactionActions)}
          transaction={transaction}
        />
      )}
    </>
  )
}

export { formatActions }
export default TransactionFlowPanel
