import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useIntegrationRoutes } from 'integrations/hooks/use-integration-routes'
import { listApiLogs } from 'integrations/services/apiLogs'
import { PageLayout } from 'shared/components/PageLayout'
import View from './APILogsBrowsePage'

const API_LOGS = 'api-logs'

const APILogsBrowsePage = ({ title }: { title: string }) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const navigationTabs = useIntegrationRoutes()

  const page = useQuery({
    queryKey: [API_LOGS, merchantAccountId],
    queryFn: () => listApiLogs(),
    refetchOnWindowFocus: false,
    meta: {
      error: {
        redirect: true,
      },
    },
  })

  return (
    <PageLayout title={title} subNavigation={navigationTabs}>
      <View title={title} page={page} />
    </PageLayout>
  )
}

export default APILogsBrowsePage
