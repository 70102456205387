import { Navigate, useParams } from 'react-router-dom'
import { Flow } from 'shared/constants'
import {
  FLOW_CARD_TRANSACTIONS_PATH,
  FLOW_CHECKOUT_PATH,
  FLOW_EDIT_RULE_PATH,
  FLOWS_PATH,
  FLOW_REDIRECT_TRANSACTIONS_PATH,
  FLOW_RULE_PATH,
  pathTo,
  FLOW_NON_CARD_TRANSACTIONS_PATH,
} from 'shared/paths/flows'
import { Resource } from 'shared/permissions/constants'
import {
  EditRulePage,
  AddRulePage,
  CheckoutPage,
  CardTransactionsPage,
  OtherTransactionsPage,
} from './pages'

export default [
  {
    title: 'Add new rule',
    longTitle: 'Add new rule',
    key: 'flows-checkout-select-payment-options-create',
    path: FLOW_RULE_PATH,
    menu: false,
    component: AddRulePage,
  },
  {
    title: 'Edit rule',
    longTitle: 'Edit rule',
    key: 'flows-checkout-select-payment-options-update',
    path: FLOW_EDIT_RULE_PATH,
    menu: false,
    component: EditRulePage,
  },
  {
    title: 'Flows',
    longTitle: 'Flows',
    key: 'flow-checkout',
    path: FLOW_CHECKOUT_PATH,
    exact: true,
    component: CheckoutPage,
  },
  {
    title: 'Flows',
    longTitle: 'Flows',
    key: 'flow-card-transaction',
    path: FLOW_CARD_TRANSACTIONS_PATH,
    exact: true,
    component: CardTransactionsPage,
  },
  {
    title: 'Flows',
    longTitle: 'Flows',
    key: 'flow-other-transaction',
    path: FLOW_REDIRECT_TRANSACTIONS_PATH,
    exact: true,
    component: OtherTransactionsPage,
  },
  {
    title: 'Flows',
    longTitle: 'Flows',
    key: 'flow-non-card-transaction',
    path: FLOW_NON_CARD_TRANSACTIONS_PATH,
    exact: true,
    component: OtherTransactionsPage,
  },
  {
    title: 'Flows',
    longTitle: 'Flows',
    key: 'flow',
    path: FLOWS_PATH,
    menu: true,
    component: function Default() {
      const { merchantAccountId } = useParams() as { merchantAccountId: string }

      return (
        <Navigate replace to={pathTo.flow(merchantAccountId, Flow.checkout)} />
      )
    },
    resource: Resource.flows,
  },
]
