import { generatePath } from 'react-router-dom'

export const REFUNDS_PATH = '/merchants/:merchantAccountId/refunds'
export const REFUND_PATH = `${REFUNDS_PATH}/:refundID`

export const pathTo = {
  refund: (merchantAccountId: string, refundID: string) =>
    `${generatePath(REFUND_PATH, {
      merchantAccountId,
      refundID,
    })}`,
}
