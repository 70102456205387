import { Heading } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { Link } from 'shared/components/Link'
import { Panel } from 'shared/components/Panel'
import { Summary } from 'shared/components/Summary'
import { Action, Flow } from 'shared/constants'
import { pathToFlow } from 'shared/paths'
import { pathTo } from 'shared/paths/flows'
import {
  AccessLevel,
  Resource,
  RestrictAccess,
  useResourcePermission,
} from 'shared/permissions'
import {
  Transaction,
  TransactionFlow,
  TransactionFlowId,
} from 'transactions/services'
import { ConnectorRouting } from './ConnectorRouting'
import { ThreeDSecureAction } from './ThreeDSecureAction'
import styles from './TransactionFlowPanel.module.scss'

interface CardTransactionFlowPanelProps {
  actions: Partial<Record<TransactionFlowId, TransactionFlow>>
  transaction?: Transaction
}

export const CardTransactionFlowPanel = ({
  actions,
  transaction,
}: CardTransactionFlowPanelProps) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const declined = Boolean(actions['decline-early']?.outcome?.result)
  const threeDSecureAction = actions['skip-3ds']

  const hasFlowReadPermission = useResourcePermission(
    Resource.flows,
    AccessLevel.read
  )

  return (
    <Panel>
      <Panel.Header>
        <Heading level={4}>Transaction flow</Heading>
        <RestrictAccess
          resource={Resource.flows}
          accessLevel={AccessLevel.read}
        >
          <Link
            href={pathToFlow.flow(merchantAccountId, Flow.cardTransactions)}
          >
            Go to flow
          </Link>
        </RestrictAccess>
      </Panel.Header>
      <Panel.Content>
        <Summary inline className={styles.flow}>
          <Summary.Key>
            {hasFlowReadPermission && actions['decline-early'] ? (
              <Link
                href={pathTo.rule(
                  merchantAccountId,
                  Flow.cardTransactions,
                  Action.declineEarly,
                  actions['decline-early'].ruleId
                )}
              >
                Decline
              </Link>
            ) : (
              <>Decline</>
            )}
          </Summary.Key>
          <Summary.Value>{declined ? 'Decline' : 'Continue'}</Summary.Value>
          <Summary.Key>
            {hasFlowReadPermission && actions['route-transaction'] ? (
              <Link
                href={pathTo.rule(
                  merchantAccountId,
                  Flow.cardTransactions,
                  Action.routeTransaction,
                  actions['route-transaction'].ruleId
                )}
              >
                Routing
              </Link>
            ) : (
              <>Routing</>
            )}
          </Summary.Key>
          <Summary.Value>
            {actions['route-transaction'] && (
              <ConnectorRouting
                transaction={transaction}
                transactionFlow={actions['route-transaction']}
              />
            )}
          </Summary.Value>
          <Summary.Key>
            {hasFlowReadPermission && actions['skip-3ds'] ? (
              <Link
                href={pathTo.rule(
                  merchantAccountId,
                  Flow.cardTransactions,
                  Action.skip3ds,
                  actions['skip-3ds'].ruleId
                )}
              >
                3D Secure
              </Link>
            ) : (
              <>3D Secure</>
            )}
          </Summary.Key>
          <Summary.Value>
            {!declined && <ThreeDSecureAction action={threeDSecureAction} />}
          </Summary.Value>
        </Summary>
      </Panel.Content>
    </Panel>
  )
}
