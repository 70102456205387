import { Stack, Button, Modal, Icon } from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { Dispatch, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSession } from 'session'
import DebouncedSearchBar from 'shared/components/DebouncedSearchBar'
import { PageLayout } from 'shared/components/PageLayout'
import { Filters } from 'shared/hooks'
import { pathTo } from 'shared/paths/users'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
  RestrictAccess,
} from 'shared/permissions'
import { useMerchantAccounts } from 'shared/permissions/hooks/use-merchant-accounts'
import { useMultiMerchant } from 'shared/permissions/hooks/use-multi-merchant'
import { QueryResult } from 'shared/services/client'
import InvitationModal from 'users/components/InvitationModal'
import RemoveUserModal from 'users/components/RemoveUserModal/RemoveUserModal'
import UsersTable from 'users/components/UsersTable'
import { INVITATION, REMOVE_USER } from 'users/constants'
import { ModalType, UserModalContext } from 'users/contexts/UserModalContext'
import { useUseManager } from 'users/hooks/use-use-manager'
import { User, UpdateUser } from 'users/services/users'

const { useModal } = Modal

export interface UsersFilters extends Filters {
  search?: string
}

interface UsersPageProps {
  page: QueryResult<User>
  remove: UseMutationResult<void, any, string>
  update: UseMutationResult<User, any, UpdateUser>
  onFilter: Dispatch<Partial<UsersFilters>>
  filters: Filters
}

const UsersPage = ({ page, remove, onFilter, filters }: UsersPageProps) => {
  const { isModalOpen, openModal, closeModal } = useModal()
  const navigate = useNavigate()
  const [selectedUser, setSelectedUser] = useState<User>()
  const [searchParams] = useSearchParams()
  const hasAuditLogsPermission = useResourcePermission(
    Resource.auditLogs,
    AccessLevel.read
  )
  const { isUserManager } = useUseManager()
  const { merchantAccountIds } = useMerchantAccounts()

  const { hasMultipleMerchantAccounts } = useMultiMerchant()
  const subNavigation = useMemo(
    () => [
      {
        title: 'All users',
        url: pathTo.users,
      },
      ...(hasAuditLogsPermission
        ? [
            {
              title: 'Activity',
              url: pathTo.usersActivity,
            },
          ]
        : []),
    ],
    [hasAuditLogsPermission]
  )

  const openUserModal = (user: User, modalName: ModalType) => {
    setSelectedUser(user)
    openModal(modalName)
  }

  const closeUserModal = (modalName: ModalType) => {
    setSelectedUser(undefined)
    closeModal(modalName)
  }

  const removeUser = (user: User) => {
    remove.mutate(user.id, {
      onSuccess: () => closeUserModal(REMOVE_USER),
    })
  }

  const getAddUserPath = () => {
    if (!isUserManager) {
      return pathTo.addUser.accessType
    }

    return hasMultipleMerchantAccounts
      ? pathTo.addUser.merchantAccount
      : pathTo.addUser.roles(merchantAccountIds)
  }

  const { user, isStaff } = useSession()

  return (
    <PageLayout
      title="Users"
      subNavigation={subNavigation}
      actions={
        <RestrictAccess
          resource={Resource.users}
          accessLevel={AccessLevel.write}
        >
          <Button
            variant="primary"
            onClick={() => navigate(getAddUserPath())}
            disabled={user && user.category === 'sso' && !isStaff}
          >
            <Icon name="add-plus" />
            Add user
          </Button>
        </RestrictAccess>
      }
    >
      <Stack gap={32}>
        <DebouncedSearchBar
          placeholder="Name or email..."
          defaultValue={searchParams.get('search') || undefined}
          onChange={(value) => onFilter({ search: value })}
          size="small"
        />
        <UserModalContext.Provider value={{ openUserModal }}>
          <UsersTable page={page} pagination={{ filters, onFilter }} />
        </UserModalContext.Provider>
      </Stack>
      <InvitationModal
        open={isModalOpen(INVITATION)}
        onClose={() => closeUserModal(INVITATION)}
        user={selectedUser}
      />
      <RemoveUserModal
        open={isModalOpen(REMOVE_USER)}
        onClose={() => closeUserModal(REMOVE_USER)}
        user={selectedUser}
        removeUser={removeUser}
        isRemovingUser={remove.isPending}
      />
    </PageLayout>
  )
}

export default UsersPage
