import { Prism } from 'react-syntax-highlighter'
import { oneLight as theme } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { formatCode, getCodeLanguage } from 'transactions/helpers'

export const EventCodeBlock = ({
  code,
  language,
}: {
  code: string
  language?: string
}) => {
  if (!language) {
    language = getCodeLanguage(code)
  }

  return (
    <Prism language={language} style={theme}>
      {formatCode(code)}
    </Prism>
  )
}
