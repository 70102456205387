import { Grid, GridItem } from '@gr4vy/poutine-react'
import { CatalogGroups } from 'connections/components/Catalog/CatalogGroups'
import { useCatalogSearch } from 'connections/hooks/use-catalog-search'
import { ConnectionDefinition } from 'connections/services'
import { SearchBar } from 'shared/components/SearchBar/SearchBar'
import { ConnectionsCatalogPageNav } from './ConnectionsCatalogPageNav'
import { NoSearchResults } from './NoSearchResults'

export const ConnectionsCatalogPage = ({
  connectionDefinitions,
}: {
  connectionDefinitions: ConnectionDefinition[]
}) => {
  const { searchTerm, search, catalog } = useCatalogSearch(
    connectionDefinitions
  )

  return (
    <Grid rowGap={48}>
      <GridItem gridColumn="span 6">
        <SearchBar
          searchTerm={searchTerm}
          itemsFoundCount={catalog.count ?? 0}
          search={search}
        />
      </GridItem>
      <GridItem gridColumn="span 6" />

      {(catalog.count ?? 0) === 0 && (
        <>
          <GridItem gridColumn="span 6">
            {' '}
            <NoSearchResults clearSearch={search} />
          </GridItem>
          <GridItem gridColumn="span 3" />
        </>
      )}
      {(catalog.count ?? 0) > 0 && (
        <GridItem gridColumn="span 9">
          <CatalogGroups catalog={catalog} />
        </GridItem>
      )}

      <GridItem gridColumn="span 3" height="full">
        <ConnectionsCatalogPageNav catalog={catalog} />
      </GridItem>
    </Grid>
  )
}
