import { Box, Flex, Heading, Stack, Text, TextLine } from '@gr4vy/poutine-react'

export const Content = ({
  title,
  subTitle,
  status,
}: {
  title: string
  subTitle?: string
  status?: string
}) => {
  const statusDotColour = status === 'Active' ? 'green60' : 'gray60'
  const statusTextColour = status === 'Active' ? 'green90' : 'gray80'

  return (
    <Stack paddingX={16} paddingY={8} className="connectionCardContent">
      <Flex height={24} flexDirection="row" alignItems="center" asChild>
        <Heading level={5}>
          <TextLine as="div">{title}</TextLine>
        </Heading>
      </Flex>
      {subTitle && (
        <Flex height={24} flexDirection="row" alignItems="center" asChild>
          <TextLine as="div" color="gray90">
            {subTitle}
          </TextLine>
        </Flex>
      )}
      {status && (
        <Flex height={24} gap={8} flexDirection="row" alignItems="center">
          <Box
            width={8}
            height={8}
            borderRadius={'circle'}
            background={statusDotColour}
          ></Box>
          <Text as="div" color={statusTextColour} truncate>
            {status}
          </Text>
        </Flex>
      )}
    </Stack>
  )
}
