export const getRoleDescription = (role: string, description?: string) =>
  ({
    [role]: description || `Assign the ${role} to this user.`,
    Analyst:
      'This role provides view-only access to transactions, including the payment method and buyer details. Access to billing and shipping details, as well as reports, is restricted and can be configured in the next steps.',
    'Customer Support':
      'This role provides access to view and manage transactions, including the ability to perform refunds, voids, and captures. Access to billing and shipping details, as well as reports, is restricted and can be configured in the next steps.',
    'PII Viewer':
      'This permission grants the user access to a buyer’s billing and shipping addresses',
    'Report Manager':
      'This role has read and write access to create, edit and download reports.',
    'Report Viewer':
      'This role has read-only access to view and download reports.',
    'System Manager':
      'This role provides access to view and manage connections and Flow configuration.',
    'System Support':
      'This role provides view-only access to any connections and Flow configuration.',
    'User Manager':
      'This role provides access to manager users, including the ability to invite new users, reset passwords, assign roles, and remove users. A User Manager will need to be assigned additional roles in order to allow them to assign those roles to other users.',
  })[role]

export const getPermissionNameByRoleName = (roleName: string) =>
  ({
    [roleName]: roleName,
    'Report Viewer': 'Download reports',
    'Report Manager': 'Create and schedule reports',
    'PII Viewer': 'Access buyer billing and shipping details',
  })[roleName]

export const additionalPermissionNames = [
  'PII Viewer',
  'Report Manager',
  'Report Viewer',
]

export const roleNames = [
  'Analyst',
  'Customer Support',
  'System Manager',
  'System Support',
  'User Manager',
]

export const rolesDrawer = [
  {
    name: 'Analyst',
    description:
      'This role provides view-only access to transactions, including the payment method and buyer details. This role is great for a user that is looking to perform analysis over transactions but is not expected to perform any actions on transactions, like performing a refund. By default this role has no access to billing details, shipping details, and reports. Additional access to these can be configured in the next steps.',
  },
  {
    name: 'Customer Support',
    description:
      'This role provides access to view and manage transactions, including the ability to perform refunds, voids, and captures. This role is great for a customer support agent who needs insight across all transactions and is expected to perform refund, void transactions, or capture an authorization. By default this role has no access to billing details, shipping details, and reports. Additional access to these can be configured in the next steps.',
  },
  {
    name: 'System Manager',
    description:
      'This role provides access to view and manage connections and Flow configuration. This role is great for a payment manager who needs to be able to manage the connections to payment services and anti-fraud services. Additionally, this role also allows a user to configure Flow rules including the ability to configure the payment options presented at checkout, and routing transactions to connections.',
  },
  {
    name: 'System Support',
    description:
      'This role provides view-only access to any connections and Flow configuration. This role is great for a support agent who may need to be able to inspect the configuration of payment service connections, anti-fraud connections, and Flow rules in order to understand how a transaction was processed.',
  },
  {
    name: 'User Manager',
    description:
      'This role provides access to manager users, including the ability to invite new users, reset passwords, assign roles, and remove users. A User Manager will need to be assigned additional roles in order to allow them to assign those roles to other users.',
  },
]

export const permissionsDrawer = [
  {
    name: 'Download reports',
    description:
      'This permission grants the user access to generated reports. This does not give the user permissions to generate or schedule new reports. This permission is only available when the user has the Analyst or Customer Support roles which allows the user to view transaction data.',
  },
  {
    name: 'Create and schedule reports',
    description:
      'This permission grants a user the ability to create new reports directly, or schedule new reports to be created periodically. This permission automatically also grants the user permission to download those generated reports. This permission is only available when the user has the Analyst or Customer Support roles which allows the user to view transaction data.',
  },
  {
    name: 'Access buyer billing and shipping details',
    description:
      'This permission grants the user access to a buyer’s billing and shipping addresses, tax ID, email, and any other personally identifiable information. Without this permission these fields will be redacted from any transaction reporting. This permission can be used to restrict users access to personally identifiable information while still allowing them to analyze transactions and perform actions on transactions.',
  },
]

export const nonAdministratorRoleNames = [
  'Administrator',
  'Integration',
  'Developer',
]
