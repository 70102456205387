import { useQuery } from '@tanstack/react-query'
import { listApiKeyPairs } from 'integrations/services/apiKeyPairs'
import { API_KEY_PAIRS } from 'shared/constants'

export interface ApiKeysProps {
  merchantAccountId?: string
  enabled: boolean
}

export const useApiKeys = ({
  enabled = false,
  merchantAccountId,
}: ApiKeysProps) => {
  const { data, isLoading } = useQuery({
    queryKey: [API_KEY_PAIRS],
    queryFn: () => listApiKeyPairs({ limit: 100 }),
    meta: {
      error: {
        redirect: true,
      },
    },
    enabled,
  })

  const hasValidApiKeys = !!data?.items.find(
    (item) =>
      item.merchantAccount === null ||
      item.merchantAccount?.id === merchantAccountId
  )

  const showApiKeyStep = enabled && (!data?.items.length || !hasValidApiKeys)

  return { showApiKeyStep, isLoadingApiKeys: isLoading }
}
