import { Input } from 'antd'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'

export const MerchantDisplayNameInput = ({
  help = <></>,
  disabled = false,
}) => (
  <FormItem
    name="merchantDisplayName"
    label={<Label>Merchant display name</Label>}
    required
    rules={[{ required: true, message: 'Merchant display name is required' }]}
    help={help}
  >
    <Input disabled={disabled} />
  </FormItem>
)
