import { StepProps } from 'users/constants'

const activeStepStyle = {
  variant: 'success',
  style: {
    color: 'black',
    fontWeight: 'bold',
  },
}

const previousStepStyle = {
  variant: 'black',
  style: {
    color: 'black',
    textVariant: 'med3',
  },
}

const nextStepStyle = {
  variant: 'neutral',
  style: { color: 'gray80' },
}

export const applyStyleSteps = (
  steps: StepProps[],
  step: StepProps['step']
) => {
  const stepIndex = steps.findIndex((stepItem) => stepItem.step === step)
  return steps.map((prevStep, index) => {
    if (prevStep.step === step) {
      return { ...prevStep, ...activeStepStyle }
    }

    if (index < stepIndex) {
      return { ...prevStep, ...previousStepStyle }
    }

    return { ...prevStep, ...nextStepStyle }
  }) as StepProps[]
}
