import { useLocation } from 'react-router-dom'
import { adminSteps, nonAdminSteps, StepProps } from 'users/constants'
import { applyStyleSteps } from 'users/helpers/steps'
import { useUseManager } from './use-use-manager'

export const useSteps = (
  step?: StepProps['step'],
  hasPermissionsStep?: boolean
) => {
  const location = useLocation()
  const isAdminStep = step && step.includes('ADMIN')
  const { isUserManager } = useUseManager()

  const getSteps = () => {
    if (!step) {
      return []
    }

    const hasAdditionalPermission =
      hasPermissionsStep ||
      !!location.state?.hasPermissionsStep ||
      step?.includes('PERMISSIONS')

    return applyStyleSteps(
      isAdminStep
        ? adminSteps
        : nonAdminSteps(hasAdditionalPermission, isUserManager),
      step
    )
  }

  return {
    steps: getSteps(),
  }
}
