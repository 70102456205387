import { Button, Icon, Text } from '@gr4vy/poutine-react'

type SuccessMessageProps = {
  onClose: () => void
}

export const SuccessMessage = ({ onClose }: SuccessMessageProps) => (
  <>
    <Icon name="circle-check" size="large" color="green60" />
    <Text fontSize="md">Domain successfully added to Apple Pay.</Text>
    <Button variant="secondary" onClick={onClose}>
      Close
    </Button>
  </>
)
