import { GridItem } from '@gr4vy/poutine-react'
import {
  ColorBlue10,
  ColorGray100,
  ColorRed10,
  ColorRed80,
  RadiusRounded,
  Size32,
} from '@gr4vy/poutine-tokens/js/tokens'
import { Select, SelectProps, Tag } from 'antd'
import { MouseEvent } from 'react'
import { Operation } from 'flows/constants'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'
import { validateBinRange } from './validation'

interface BinRangeInputProps {
  name: number
  maxLength?: number
}

type TagRenderProps = Parameters<NonNullable<SelectProps['tagRender']>>[0] & {
  maxLength: number
}

const ERROR_OPERATOR_REQUIRED = 'Operator is required'
const ERROR_VALUE_REQUIRED = 'BIN range is required'
const INVALID_BIN_RANGE = 'Invalid bin range entered'

function tagRender({
  label,
  value,
  closable,
  onClose,
  maxLength,
}: TagRenderProps) {
  const onPreventMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }
  const valid = validateBinRange(value, maxLength)
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: '3px',
        background: valid ? ColorBlue10 : ColorRed10,
        borderColor: valid ? ColorBlue10 : ColorRed10,
        borderRadius: RadiusRounded,
        color: valid ? ColorGray100 : ColorRed80,
        height: Size32,
        lineHeight: Size32,
        marginTop: 0,
      }}
    >
      {label}
    </Tag>
  )
}

const BinRangeInput = ({ name, maxLength = 8 }: BinRangeInputProps) => (
  <>
    <GridItem gridColumn="span 2">
      <FormItem
        label={<Label>Operator</Label>}
        name={[name, 'operator']}
        rules={[{ required: true, message: ERROR_OPERATOR_REQUIRED }]}
      >
        <Select placeholder="Select an operator">
          <Select.Option value={Operation.IS_ONE_OF}>includes</Select.Option>
          <Select.Option value={Operation.IS_NOT_ONE_OF}>
            excludes
          </Select.Option>
        </Select>
      </FormItem>
    </GridItem>
    <GridItem gridColumn="span 6">
      <FormItem
        label={<Label>Value(s)</Label>}
        name={[name, 'value']}
        rules={[
          { required: true, message: ERROR_VALUE_REQUIRED },
          () => ({
            validator(_, value: string[]) {
              if (
                value &&
                value.every((number) => validateBinRange(number, maxLength))
              ) {
                return Promise.resolve()
              }

              return Promise.reject(new Error(INVALID_BIN_RANGE))
            },
          }),
        ]}
      >
        <Select
          mode="tags"
          allowClear
          tagRender={(props) => tagRender({ ...props, maxLength })}
          maxTagCount="responsive"
          maxTagPlaceholder={maxTagPlaceholderFormatter}
          placeholder="1234-4567"
        />
      </FormItem>
    </GridItem>
  </>
)

export { ERROR_OPERATOR_REQUIRED, ERROR_VALUE_REQUIRED, INVALID_BIN_RANGE }
export default BinRangeInput
