import { Button } from '@gr4vy/poutine-react'
import { useQuery } from '@tanstack/react-query'
import { MouseEventHandler } from 'react'
import { reset } from 'reset/services/reset'
import { useSession } from 'session'
import { PageLayout } from 'shared/components/PageLayout'
import { showSuccessMessage } from 'shared/utils/showMessage'

const ResetPage = ({ title }: { title: string }) => {
  const { user } = useSession()

  const { isLoading, refetch } = useQuery({
    queryKey: ['reset'],
    queryFn: () =>
      new Promise((resolve) => {
        reset().then((data) => {
          showSuccessMessage('Successfully Reset')
          setTimeout(() => window.location.reload(), 1000)
          resolve(data)
        })
      }),
    refetchOnWindowFocus: false,
    enabled: false,
  })

  return (
    <PageLayout title={title}>
      {user && user.featureResetEndpointEnabled && (
        <Button
          variant="primaryNegative"
          loading={isLoading}
          onClick={refetch as MouseEventHandler}
        >
          Reset Environment
        </Button>
      )}
    </PageLayout>
  )
}

export default ResetPage
