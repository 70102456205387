export const getItem = (key: string, fallback: any): any => {
  if (window.localStorage) {
    const value = localStorage.getItem(key)
    if (value) {
      return JSON.parse(value)
    }
  }
  return fallback
}

export const setItem = (key: string, value: any): void => {
  if (window.localStorage) {
    const serialized = JSON.stringify(value)
    localStorage.setItem(key, serialized)
  }
}
