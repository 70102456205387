import { Stack } from '@gr4vy/poutine-react'
import { useParams, useSearchParams } from 'react-router-dom'
import { ReportSchedulesTable } from 'reports/components/ReportSchedulesTable'
import { ReportsSearch } from 'reports/components/ReportsSearch'
import { useReports } from 'reports/hooks/use-report'
import { useReportsRoutes } from 'reports/hooks/use-reports-routes'
import { ReportsFilters as ReportsFiltersType } from 'reports/services/report'
import { PageLayout } from 'shared/components/PageLayout'
import { useCursor, useFilters } from 'shared/hooks'

export const ScheduledReportsPage = ({ title }: { title: string }) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const [searchParams] = useSearchParams()
  const navigationTabs = useReportsRoutes(merchantAccountId)
  const [filters, setFilters] = useFilters<ReportsFiltersType>({
    scheduleEnabled: true,
  })
  const [cursor] = useCursor()
  const page = useReports({ cursor, ...filters }, { refetchInterval: 60000 })
  const updateFilter = (f: Partial<ReportsFiltersType>) => {
    setFilters({ ...filters, ...f })
  }

  return (
    <PageLayout title={title} subNavigation={navigationTabs}>
      <Stack gap={32}>
        <ReportsSearch
          defaultValue={searchParams.get('name') || undefined}
          onChange={(search) => updateFilter({ name: search })}
        />
        <ReportSchedulesTable
          page={page}
          pagination={{ filters, onFilter: updateFilter }}
        />
      </Stack>
    </PageLayout>
  )
}
