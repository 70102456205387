import { Box, BoxProps } from '@gr4vy/poutine-react'
import { ElementType } from 'react'
import styles from './styles.module.scss'

interface IconProps extends BoxProps {
  iconType: IconType
  as?: ElementType<any>
  dataTestId?: string
  size?: 16 | 20 | 24 | 32
}

export enum IconType {
  Add = 'icon_add',
  ArrowRight = 'icon_arrow_right',
  Info = 'icon_info',
  Danger = 'icon_danger',
  Drag = 'icon_drag',
  Line = 'icon_line',
  Edit = 'icon_edit',
  Bin = 'icon_bin',
  Warning = 'icon_warning',
  Top = 'icon_top',
}

export const Icon = ({
  iconType,
  as = 'div',
  dataTestId,
  color = 'gray80',
  size = 32,
  ...props
}: IconProps) => (
  <Box
    {...props}
    display="flex"
    alignItems="center"
    justifyContent="center"
    width={size}
    height={size}
    as={as}
    data-testid={dataTestId}
  >
    <Box
      className={styles[iconType] || styles.icon_add}
      background={color}
      role="icon"
      style={{
        maskSize: 'contain',
        WebkitMaskSize: 'contain',
      }}
    ></Box>
  </Box>
)

export default Icon
