import { Button, Text, Icon, Stack } from '@gr4vy/poutine-react'

interface Props {
  onClose: () => void
}

export const SuccessMessage = ({ onClose }: Props) => {
  return (
    <Stack gap={24}>
      <Icon name="circle-check" size="large" color="green70" />
      <Text fontSize="md">
        <b>Apple Pay certificate</b> successfully added.
      </Text>
      <Button onClick={onClose}>Close</Button>
    </Stack>
  )
}
