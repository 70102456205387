import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { listConnectionDefinitions } from 'connections/services'
import { CONNECTION_DEFINITIONS } from 'shared/constants'

export const useConnectionDefinitions = () => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }

  const query = useQuery({
    queryKey: [CONNECTION_DEFINITIONS, merchantAccountId],
    queryFn: listConnectionDefinitions,
    meta: {
      error: {
        redirect: true,
      },
    },
  })

  const definitions = query.data?.items

  return {
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
    definitions,
  }
}
