import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import {
  EditConnectionForm,
  EditConnectionFormData,
} from 'connections/components/EditConnectionForm/EditConnectionForm'
import { useAntiFraudService } from 'connections/hooks/use-anti-fraud-service'
import { useAntiFraudServices } from 'connections/hooks/use-anti-fraud-services'
import { AntiFraudServiceUpdate } from 'connections/services'
import { pathTo } from 'shared/paths/connections'

interface Props {
  children: ReactNode
  submitButtonText: string
  subNavigation: Array<{ title: string; url: string }>
}

export const EditForm = ({
  children,
  subNavigation,
  submitButtonText,
}: Props) => {
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }
  const { remove, update } = useAntiFraudServices()
  const { data, isLoading } = useAntiFraudService(merchantAccountId, id, {
    meta: {
      error: { redirect: pathTo.connectionsConfigured(merchantAccountId) },
    },
  })

  const mapFormToService = (values: EditConnectionFormData) => {
    const payload = {
      ...values,
      id,
      antiFraudServiceDefinitionId: data?.antiFraudServiceDefinitionId,
      fields: [],
    } as AntiFraudServiceUpdate
    if (values.hasOwnProperty('fields')) {
      const fields = Object.keys(values.fields).map((key) => ({
        key: key,
        value: values.fields[key],
      }))
      payload['fields'] = fields.filter((field) => field.value !== undefined)
    }

    return payload
  }

  return (
    <EditConnectionForm
      subNavigation={subNavigation}
      submitButtonText={submitButtonText}
      data={data}
      isLoading={isLoading}
      remove={remove}
      update={update}
      mapFormToService={mapFormToService}
    >
      {children}
    </EditConnectionForm>
  )
}
