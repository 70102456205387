import { saveAs } from 'file-saver'
import { ApiKeyPair } from 'integrations/services/apiKeyPairs'
import { environment, gr4vyId } from 'shared/helpers/get-environment'

export const saveApiKeyPair = async (apiKeyPair: ApiKeyPair) => {
  const filename = `gr4vy-${gr4vyId}-${environment}-${apiKeyPair.thumbprint}.pem`
  const data = new Blob([apiKeyPair.privateKey], {
    type: 'application/x-pem-file;charset=utf-8;',
  })
  saveAs(data, filename)
}
