import { Stack, Box } from '@gr4vy/poutine-react'
import { SandboxBanner } from 'App/components/SandboxBanner'
import { StickyBox } from 'shared/components/StickyBox'

export const SessionPageLayout = ({ children }: any) => (
  <Stack style={{ height: '100vh' }}>
    <StickyBox>
      <SandboxBanner />
    </StickyBox>
    <Box
      height="full"
      background="gray20"
      alignItems="center"
      justifyContent="center"
      display="flex"
    >
      {children}
    </Box>
  </Stack>
)
