import {
  Flex,
  Box,
  Badge,
  ButtonCopy,
  Text,
  Container,
  Stack,
  Heading,
} from '@gr4vy/poutine-react'
import { capitalize } from 'lodash'
import { useParams } from 'react-router-dom'
import { ReportExecutionsTable } from 'reports/components/ReportExecutionsTable'
import { useReport } from 'reports/hooks/use-report'
import { useReportExecutions } from 'reports/hooks/use-report-execution'
import { ReportExecutionsFilters as ReportExecutionsFiltersType } from 'reports/services/report-execution'
import { Loading } from 'shared/components/Loading'
import { PageLayout } from 'shared/components/PageLayout'
import { Panel } from 'shared/components/Panel'
import { SummaryGrid } from 'shared/components/SummaryGrid'
import { dateFormat } from 'shared/helpers/date-format'
import {
  formatOffset,
  getOffset,
  getTimezoneName,
} from 'shared/helpers/timezone'
import { useCursor, useFilters } from 'shared/hooks'
import { pathTo } from 'shared/paths/reports'

export const ScheduleDetailsPage = () => {
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }
  const [filters, setFilters] = useFilters<ReportExecutionsFiltersType>()
  const [cursor] = useCursor()
  const updateFilter = (f: Partial<ReportExecutionsFiltersType>) => {
    setFilters({ ...filters, ...f })
  }
  const { data: report } = useReport(merchantAccountId, id)
  const reportExecutions = useReportExecutions(
    { reportId: report?.id, cursor, ...filters },
    { refetchInterval: 60000, enabled: !!report?.id }
  )

  const breadcrumbs = [
    {
      title: 'Scheduled',
      url: pathTo.scheduledReports(merchantAccountId),
    },
    {
      title: 'Overview',
    },
  ]

  return report ? (
    <PageLayout
      title=""
      titleChildren={
        <Flex gap={16}>
          {report.name || ''}
          <Badge variant="subtle" color="information">
            {capitalize(report.schedule)}
          </Badge>
        </Flex>
      }
      breadcrumbs={breadcrumbs}
      noContainer
      gap="none"
      actions={
        <Flex gap={16}>
          <ButtonCopy variant="secondary" copy={document.location.href}>
            Copy link
          </ButtonCopy>
        </Flex>
      }
    >
      <Stack gap={32}>
        <Box>
          <Container>
            <SummaryGrid columnGap={64} rowGap={40} paddingY={48}>
              <SummaryGrid.Item gridColumn="span 4">
                <SummaryGrid.Key>Schedule ID</SummaryGrid.Key>
                <SummaryGrid.Value>{report.id}</SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item gridColumn="span 4">
                <SummaryGrid.Key>Last Generated</SummaryGrid.Key>
                <SummaryGrid.Value>
                  {report.latestExecution?.createdAt
                    ? dateFormat(report.latestExecution.createdAt)
                    : null}
                </SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item gridColumn="span 4">
                <SummaryGrid.Key>Time Zone</SummaryGrid.Key>
                <SummaryGrid.Value>
                  <Flex gap={4}>
                    {getTimezoneName(report.scheduleTimezone)}
                    <Text fontWeight="normal" color="gray90">
                      (UTC {formatOffset(getOffset(report.scheduleTimezone))})
                    </Text>
                  </Flex>
                </SummaryGrid.Value>
              </SummaryGrid.Item>
            </SummaryGrid>
          </Container>
        </Box>
        <Container>
          <Panel>
            <Panel.Header>
              <Heading level={4}>Reports</Heading>
            </Panel.Header>
            <Panel.Content>
              <Stack gap={32}>
                <ReportExecutionsTable
                  page={reportExecutions}
                  panel
                  pagination={{
                    filters,
                    onFilter: updateFilter,
                  }}
                />
              </Stack>
            </Panel.Content>
          </Panel>
        </Container>
      </Stack>
    </PageLayout>
  ) : (
    <Loading />
  )
}
