import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { PaymentServiceDefinition, getDefinition } from 'connections/services'
import { PAYMENT_SERVICE_DEFINITIONS } from 'shared/constants'

export const usePaymentServiceDefinition = (
  merchantAccountId: string,
  id?: string,
  options?: Pick<UseQueryOptions<PaymentServiceDefinition>, 'meta'>
) => {
  const { data, isLoading } = useQuery({
    queryKey: [PAYMENT_SERVICE_DEFINITIONS, id, merchantAccountId],
    queryFn: () => getDefinition(id as string),
    refetchOnWindowFocus: false,
    enabled: !!id,
    ...options,
  })

  return { isLoading, paymentServiceDefinition: data }
}
