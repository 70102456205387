import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { LIST_REFUNDS } from 'shared/constants/query-keys/transactions'
import { listRefunds, Refund, Transaction } from 'transactions/services'

export type HistoryData = {
  date?: string
  amount: number
  currency: string
  status: string
  event: string
}

export const INCOMPLETE_BUYER_APPROVAL_ERROR_CODE = 'incomplete_buyer_approval'

export const getTransactionHistoryData = (
  transaction: Transaction,
  refunds: Refund[]
) => {
  const tableData = [
    {
      amount: transaction.amount,
      currency: transaction.currency,
      date: transaction.createdAt,
      event: 'Transaction',
      status: 'Received',
    },
  ] as HistoryData[]

  if (transaction.intent === 'authorize') {
    if (
      (transaction.status === 'authorization_failed' ||
        transaction.status === 'authorization_declined') &&
      !transaction.authorizedAt &&
      !transaction.buyerApprovalTimedoutAt
    ) {
      tableData.unshift({
        amount: transaction.amount,
        currency: transaction.currency,
        event: 'Authorization',
        date: transaction.updatedAt,
        status:
          transaction.status === 'authorization_failed' ? 'Failed' : 'Declined',
      })
    }

    if (transaction.authorizedAt) {
      tableData.unshift({
        amount: transaction.amount,
        currency: transaction.currency,
        event: 'Authorization',
        date: transaction.authorizedAt,
        status: 'Completed',
      })
    }

    if (transaction.capturedAt && transaction.capturedAmount > 0) {
      const event =
        transaction.capturedAmount < transaction.amount
          ? 'Partial capture'
          : 'Capture'
      tableData.unshift({
        amount: transaction.capturedAmount,
        currency: transaction.currency,
        date: transaction.capturedAt,
        event: event,
        status: 'Completed',
      })
    }

    if (
      (transaction.status === 'authorization_failed' ||
        transaction.status === 'authorization_declined') &&
      transaction.authorizedAt
    ) {
      tableData.unshift({
        amount: transaction.amount,
        currency: transaction.currency,
        event: 'Capture',
        date: transaction.updatedAt,
        status:
          transaction.status === 'authorization_failed' ? 'Failed' : 'Declined',
      })
    }
  }

  if (transaction.intent === 'capture') {
    if (
      (transaction.status === 'authorization_failed' ||
        transaction.status === 'authorization_declined') &&
      !transaction.buyerApprovalTimedoutAt
    ) {
      tableData.unshift({
        amount: transaction.amount,
        currency: transaction.currency,
        event: 'Authorization',
        date: transaction.updatedAt,
        status:
          transaction.status === 'authorization_failed' ? 'Failed' : 'Declined',
      })
    }

    if (transaction.capturedAt || transaction.authorizedAt) {
      tableData.unshift({
        amount: transaction.amount,
        currency: transaction.currency,
        event: 'Capture',
        date: transaction.capturedAt || transaction.authorizedAt,
        status: 'Completed',
      })
    }
  }

  if (transaction.voidedAt || transaction.status === 'authorization_voided') {
    tableData.unshift({
      amount: transaction.amount,
      currency: transaction.currency,
      date: transaction?.voidedAt,
      event: 'Void',
      status: 'Completed',
    })
  }

  if (
    transaction.errorCode === INCOMPLETE_BUYER_APPROVAL_ERROR_CODE &&
    transaction.buyerApprovalTimedoutAt
  ) {
    tableData.unshift({
      amount: transaction.amount,
      currency: transaction.currency,
      event: 'Authentication',
      date: transaction.buyerApprovalTimedoutAt,
      status: 'Approval incomplete',
    })
  }

  const getRefundEvent = (refund: Refund) => {
    if (refund.targetType === 'payment-method') {
      return refund.amount == transaction.amount ||
        refund.amount == transaction.capturedAmount
        ? 'Full refund'
        : 'Partial refund'
    }

    if (refund.targetType === 'gift-card-redemption') {
      const giftCardRedemption = transaction.giftCardRedemptions.find(
        (giftCardRedempention) => giftCardRedempention.id === refund.targetId
      )

      if (giftCardRedemption) {
        return refund.amount == giftCardRedemption?.amount
          ? 'Full refund'
          : 'Partial refund'
      }
    }

    return '-'
  }

  const transactionHistoryRefunds = refunds.map(
    (refund) =>
      ({
        amount: refund.amount,
        currency: refund.currency,
        date: refund.createdAt,
        event: getRefundEvent(refund),
        status: refund.status.charAt(0).toUpperCase() + refund.status.slice(1),
      }) as HistoryData
  )

  return [...transactionHistoryRefunds, ...tableData]
}

export const useTransactionHistoryData = (transaction: Transaction) => {
  const refunds = useQuery({
    queryKey: [LIST_REFUNDS, transaction.id],
    queryFn: () => listRefunds(transaction.id, { limit: 100 }),
    enabled: !!transaction?.id,
  })

  const tableData = useMemo(
    () => getTransactionHistoryData(transaction, refunds?.data?.items ?? []),
    [transaction, refunds.data]
  )

  if (refunds.isLoading || !refunds.isSuccess) {
    return {
      tableData: [] as HistoryData[],
    }
  }

  return {
    tableData: tableData,
  }
}
