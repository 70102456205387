import client from 'shared/services/client'

export interface GiftCardServiceField {
  key: string
  value: string
}

export interface GiftCardService {
  type: 'gift-card-service'
  id: string
  merchantAccountId: string
  giftCardServiceDefinitionId: string
  displayName: string
  fields: GiftCardServiceField[]
  active: boolean
  createdAt: string
  updatedAt: string
}

export type GiftCardServiceUpdate = Partial<
  Pick<GiftCardService, 'id' | 'active' | 'displayName' | 'fields'>
>

export interface VerifyGiftCardCredentials {
  fields: Array<Record<string, string>>
  giftCardServiceId?: string
  giftCardServiceDefinitionId?: string
}

export const verifyGiftCardServiceCredentials = async (
  data: VerifyGiftCardCredentials
): Promise<void> => {
  const response = await client.post('/gift-card-services/verify', data)
  return response.data
}

export const addGiftCardService = async (
  data: Omit<GiftCardService, 'createdAt' | 'updatedAt'>
) => {
  const response = await client.post('/gift-card-services', data)
  return response.data
}

export const updateGiftCardService = async (
  changes: GiftCardServiceUpdate
): Promise<GiftCardService> => {
  const { id, ...body } = changes
  const response = await client.put(`/gift-card-services/${id}`, body)
  return response.data
}

export const removeGiftCardService = async (id: string) => {
  await client.delete(`/gift-card-services/${id}`)
}

export const getGiftCardService = async (
  id: string,
  params = {}
): Promise<GiftCardService> => {
  const response = await client.get(`/gift-card-services/${id}`, { params })
  return response.data
}
