import {
  Tooltip,
  Flex,
  TextLine,
  ButtonLink,
  TextLink,
  Box,
  Icon,
  Dropdown,
} from '@gr4vy/poutine-react'
import { generatePath, useNavigate } from 'react-router-dom'
import { MERCHANTS_PATH, pathTo } from 'admin/paths'
import {
  ColumnDef,
  DataTable,
  DataTableProps,
} from 'shared/components/DataTable'
import { QueryResult } from 'shared/services/client'
import { MerchantAccount } from 'shared/services/merchant-accounts'

export type MerchantsTableProps = Pick<
  DataTableProps<MerchantAccount>,
  'pagination'
> & { page: QueryResult<MerchantAccount> }

export const merchantsColumns: Array<ColumnDef<MerchantAccount>> = [
  {
    header: 'Name',
    accessorKey: 'displayName',
    size: 180,
    cell: ({ row }) => (
      <TextLink
        href={generatePath(MERCHANTS_PATH, {
          merchantAccountId: row.original.id,
        })}
      >
        {row.original.displayName}
      </TextLink>
    ),
  },
  {
    header: 'ID',
    accessorKey: 'id',
    size: 400,
    cell: ({ getValue }) => {
      return <TextLine fontFamily="mono">{getValue<string>()}</TextLine>
    },
  },
  {
    id: 'actions',
    accessorKey: 'id',
    size: 45,
    header: () => <Box textAlign="center">Actions</Box>,
    cell: function Actions({ getValue }) {
      const navigate = useNavigate()
      const merchantAccountId = getValue<MerchantAccount['id']>()

      return (
        <Box textAlign="center">
          <Dropdown>
            <Dropdown.Trigger asChild>
              <Dropdown.Button variant="tertiary" size="small">
                <Icon name="more-horizontal" />
              </Dropdown.Button>
            </Dropdown.Trigger>
            <Dropdown.Content align="end">
              <Dropdown.Item
                onSelect={() => navigate(pathTo.merchant(merchantAccountId))}
              >
                Edit merchant
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>
        </Box>
      )
    },
  },
  {
    id: 'open',
    header: '',
    accessorKey: 'id',
    size: 0,
    cell: ({ getValue }) => {
      const merchantAccountId = getValue<MerchantAccount['id']>()

      return merchantAccountId ? (
        <Flex justifyContent="flex-end">
          <Tooltip content="Go to merchant">
            <ButtonLink
              variant="tertiary"
              size="small"
              href={generatePath(MERCHANTS_PATH, {
                merchantAccountId,
              })}
            />
          </Tooltip>
        </Flex>
      ) : null
    },
  },
]

const MerchantsTable = ({ page, pagination }: MerchantsTableProps) => {
  const { data, isLoading: loading } = page

  return (
    <DataTable
      data={data}
      loading={loading}
      columns={merchantsColumns}
      pagination={pagination}
    />
  )
}

export default MerchantsTable
