import {
  Stack,
  Text,
  Modal,
  Icon,
  Button,
  Flex,
  ButtonCopy,
} from '@gr4vy/poutine-react'
import { Input } from 'antd'
import { useResetPassword } from 'users/hooks/use-reset-password'
import { User } from 'users/services/users'

interface InvitationModalProps {
  user?: User
  open: boolean
  onClose: () => void
}

interface DetailsProps {
  user?: User
  onClose: () => void
}

export const Details = ({ user, onClose }: DetailsProps) => {
  const { isInvalidToken, isExpiredToken, expirationTime, resetLink } =
    useResetPassword(user)

  if (isInvalidToken) {
    return <Text>Invalid reset token, please try again.</Text>
  }

  if (isExpiredToken) {
    return <Text>Expired token, please try again.</Text>
  }

  return (
    <Stack gap={24}>
      <Text>Please copy and share the following link.</Text>
      <Input value={resetLink} style={{ textOverflow: 'ellipsis' }} readOnly />
      <Text>
        <b>Note: </b>
        This link will expire in {expirationTime}.
      </Text>
      <Flex gap={16} justifyContent="flex-end">
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <ButtonCopy copy={resetLink}>Copy link</ButtonCopy>
      </Flex>
    </Stack>
  )
}

const InvitationModal = ({ user, open, onClose }: InvitationModalProps) => (
  <Modal
    id="invitation"
    icon={<Icon name="link" color="blue60" size="large" />}
    title={`${
      user?.hasValidPassword
        ? 'Reset password link for'
        : 'Generate new invitation link for'
    } ${user?.name}`}
    open={open}
    onOpenChange={(status) => {
      !status && onClose()
    }}
  >
    <Details user={user} onClose={onClose} />
  </Modal>
)

export default InvitationModal
