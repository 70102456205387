import { flushSync } from 'react-dom'
import { createRoot } from 'react-dom/client'
import { TooltipContent } from 'home/components/TooltipContent'
import { ConfigProps } from 'home/constants/config'
import type { TooltipComponentFormatterCallbackParams } from 'echarts'

export const tooltipFormatter = (
  params: TooltipComponentFormatterCallbackParams,
  numberFormat?: ConfigProps['numberFormat']
) => {
  if (!Array.isArray(params)) {
    return ''
  }

  const container = document.createElement('div')
  const root = createRoot(container)

  flushSync(() => {
    root.render(<TooltipContent params={params} numberFormat={numberFormat} />)
  })

  return container?.innerHTML || ''
}
