export const GET_BUYER_TRX = 'get_buyer_transaction'
export const GET_BUYER = 'get_buyer'
export const BUYERS_PAYMENT_METHODS = 'buyers_payment_methods'
export const BUYERS_GIFT_CARDS = 'buyers_gift_cards'
export const TRANSACTION = 'transaction'
export const LIST_FLOW_ACTIONS = 'list_flow_actions'
export const GET_PAYMENT_SERVICE = 'get_payment_service'
export const LIST_REFUNDS = 'list_refunds'
export const TRANSACTIONS = 'transactions'
export const LIST_EVENTS = 'list_events'
export const PAYMENT_METHODS = 'payment_methods'
export const LIST_SETTLEMENTS = 'list_settlements'
export const PAYOUTS = 'payouts'
export const PAYOUT = 'payout'
