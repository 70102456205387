import {
  ButtonCopy,
  Flex,
  Heading,
  Stack,
  Tag,
  Text,
} from '@gr4vy/poutine-react'
import { countryCodes } from 'shared/constants'
import { AdditionalSchemesTooltip } from 'transactions/components/AdditionalSchemesTooltip'
import { EventCodeBlock } from 'transactions/components/EventCodeBlock'
import TransactionOverviewCardPayment from 'transactions/components/TransactionOverview/TransactionOverviewCardPayment'
import { formatCode, getColorByStatusCode } from 'transactions/helpers'
import { PaymentMethod, TransactionEvent } from 'transactions/services'

export const BINLookupRequestEvent = ({
  event,
  paymentMethod,
}: {
  event: TransactionEvent
  paymentMethod?: PaymentMethod
}) => {
  const { bin, scheme, additionalSchemes } = event.context

  return event ? (
    <Stack gap={32}>
      {scheme && (
        <Stack gap={8} alignItems="flex-start">
          <Heading as="h5" level={5}>
            Card
          </Heading>
          <Flex>
            <TransactionOverviewCardPayment
              transaction={
                {
                  paymentMethod: {
                    scheme,
                    details: { bin },
                    label: ' ',
                  },
                } as any
              }
              paymentMethod={paymentMethod}
            />
            {!!additionalSchemes?.length && (
              <AdditionalSchemesTooltip additionalSchemes={additionalSchemes} />
            )}
          </Flex>
        </Stack>
      )}
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Type
        </Heading>
        <Text>{event.context['type']?.toUpperCase?.() ?? 'UNKNOWN'}</Text>
      </Stack>
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Country
        </Heading>
        <Text>
          {event.context['countryCode'] &&
            countryCodes[event.context['countryCode'].toUpperCase()].name}
        </Text>
      </Stack>
      {!!event.context['response'] && (
        <Stack gap={8}>
          <Flex alignItems="flex-end" justifyContent="space-between">
            <Flex alignItems="center" gap={8}>
              <Heading as="h5" level={5}>
                HTTP Response
              </Heading>
              <Tag
                variant="subtle"
                color={getColorByStatusCode(
                  String(event.context['responseStatusCode'])
                )}
              >
                {event.context['responseStatusCode']}
              </Tag>
            </Flex>
            <ButtonCopy
              type="button"
              variant="secondary"
              copy={formatCode(event.context['response'])}
              size="small"
            >
              Copy
            </ButtonCopy>
          </Flex>
          <EventCodeBlock code={event.context['response']} />
        </Stack>
      )}
    </Stack>
  ) : null
}
