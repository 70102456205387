import { Flex, Stack, Button, Drawer, Icon, Text } from '@gr4vy/poutine-react'
import { Form } from 'antd'
import { AxiosError } from 'axios'
import { get, snakeCase } from 'lodash'
import { useState, useEffect } from 'react'
import { saveCsr } from 'connections/helpers/file'
import {
  useAddApplePayCertificate,
  useUpdateApplePayCertificate,
} from 'connections/hooks/use-apple-pay-certificate'
import { ApplePayCertificate } from 'connections/services/applePayCertificates'
import { isSandbox } from 'shared/helpers/is-sandbox'
import { StepDownloadCsr } from './StepDownloadCsr'
import { StepName } from './StepName'
import { StepUploadCertificate } from './StepUploadCertificate'
import { StepUploadCsr } from './StepUploadCsr'
import { SuccessMessage } from './SuccessMessage'

interface Props {
  open: boolean
  certificate?: ApplePayCertificate | null
  onClose: () => void
}

export const AddCertificateDrawer = ({
  open,
  certificate: resumeCertificate = null,
  onClose,
}: Props) => {
  const [form] = Form.useForm<{ name: string }>()
  const [step, setStep] = useState(1)
  const [applePayCertificate, setApplePayCertificate] =
    useState<ApplePayCertificate | null>(null)
  const [uploadedCertificate, setUploadedCertificate] = useState<File>()
  const [uploadedCertificateError, setUploadedCertificateError] =
    useState<string>('')
  const {
    mutate: add,
    isPending: isAdding,
    reset: resetAddingMutation,
  } = useAddApplePayCertificate()
  const {
    mutate: upload,
    isPending: isUploading,
    isSuccess,
    reset: resetUploadingMutation,
  } = useUpdateApplePayCertificate()

  const addCertificate = async (
    onSuccess: (applePayCertificate: ApplePayCertificate) => void
  ) => {
    try {
      const values = await form.validateFields()
      add(values.name, {
        onError: (error) => {
          const { message } = (error as AxiosError).response?.data as {
            code: string
            message: string
          }
          form.setFields([
            {
              name: 'name',
              errors: [message],
            },
          ])
        },
        onSuccess,
      })
    } catch {}
  }
  const uploadCertificate = () => {
    const { id } = applePayCertificate as ApplePayCertificate
    if (uploadedCertificate) {
      setUploadedCertificateError('')
      upload(
        { id, certificate: uploadedCertificate },
        {
          onError: (error) => {
            const errorDetails = (error as AxiosError).response?.data as {
              message: string
              details: { message: string }[]
            }
            setUploadedCertificateError(
              get(errorDetails, 'details.0.message', errorDetails.message)
            )
          },
        }
      )
    } else {
      setUploadedCertificateError('Select a file to upload.')
    }
  }

  const handleSecondaryClick = () => {
    if (step === 1) {
      onClose()
    } else if (step === 3 && resumeCertificate) {
      saveCsr(
        `gr4vy_apple_${isSandbox ? 'sandbox' : 'production'}_${snakeCase(
          resumeCertificate.displayName
        )}`,
        resumeCertificate.certificateSigningRequest
      )
    } else {
      setStep(step - 1)
    }
  }
  const handlePrimaryClick = async () => {
    switch (step) {
      case 1:
        await addCertificate((applePayCertificate) => {
          setApplePayCertificate(applePayCertificate)
          setStep(2)
        })
        break
      case 2:
        const { displayName, certificateSigningRequest } =
          applePayCertificate as ApplePayCertificate
        saveCsr(
          `gr4vy_apple_${isSandbox ? 'sandbox' : 'production'}_${snakeCase(
            displayName
          )}`,
          certificateSigningRequest
        )
        setStep(3)
        break
      case 3:
        setStep(4)
        break
      case 4:
        uploadCertificate()
        break
    }
  }
  const handleChangeCertificate = async (files: FileList | null) => {
    const appleCertificateFile = files?.[0]
    if (appleCertificateFile) {
      setUploadedCertificate(appleCertificateFile)
    }
  }

  const reset = () => {
    setStep(1)
    setUploadedCertificate(undefined)
    setUploadedCertificateError('')
    form.resetFields()
    resetAddingMutation()
    resetUploadingMutation()
  }

  useEffect(() => {
    if (open) {
      reset()
      if (resumeCertificate) {
        setStep(3)
        setApplePayCertificate(resumeCertificate)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Drawer
      open={open}
      title="Add certificate"
      footer={
        !isSuccess && (
          <Flex gap={16} justifyContent="flex-end">
            {[1, 3, 4].includes(step) && (
              <Button variant="secondary" onClick={handleSecondaryClick}>
                {step === 1 && 'Cancel'}
                {step === 3 && resumeCertificate && (
                  <>
                    <Icon name="download" size="small" /> Download CSR
                  </>
                )}
                {step === 3 && !resumeCertificate && 'Back'}
                {step === 4 && 'Back'}
              </Button>
            )}
            <Button
              loading={isAdding || isUploading}
              onClick={handlePrimaryClick}
            >
              {step === 1 && 'Continue'}
              {step === 2 && 'Download and continue'}
              {step === 3 && 'Continue'}
              {step === 4 && 'Upload'}
              {step !== 4 && <Icon name="arrow-right-md" />}
            </Button>
          </Flex>
        )
      }
      onClose={onClose}
    >
      {isSuccess ? (
        <SuccessMessage onClose={onClose} />
      ) : (
        <Stack gap={24}>
          <Text variant="overline1">Step {step} of 4</Text>
          {step === 1 && <StepName form={form} />}
          {step === 2 && <StepDownloadCsr />}
          {step === 3 && <StepUploadCsr />}
          {step === 4 && (
            <StepUploadCertificate
              error={uploadedCertificateError}
              uploading={isUploading}
              onChange={handleChangeCertificate}
            />
          )}
        </Stack>
      )}
    </Drawer>
  )
}
