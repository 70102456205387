import { Flex, Stack, Skeleton } from '@gr4vy/poutine-react'

export const LoadingSupportedConnections = () => (
  <Stack gap={16} justifyContent="center">
    <Skeleton width={200}>
      <Skeleton.Box borderRadius="rounded" height={24} />
    </Skeleton>
    {Array.from({ length: 4 }, (_, k) => k).map((item) => (
      <Flex key={item} alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" gap={8}>
          <Skeleton width={32}>
            <Skeleton.Box borderRadius="rounded" height={32} />
          </Skeleton>
          <Skeleton width={200}>
            <Skeleton.Box borderRadius="rounded" height={24} />
          </Skeleton>
        </Flex>
        <Skeleton width={16}>
          <Skeleton.Box borderRadius="circle" height={16} />
        </Skeleton>
      </Flex>
    ))}
  </Stack>
)
