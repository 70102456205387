import {
  Box,
  Grid,
  GridItem,
  Stack,
  Text,
  ButtonLink,
  TextLink,
} from '@gr4vy/poutine-react'
import { AnchorLinkItemProps } from 'antd/es/anchor/Anchor'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Anchor, stepToHash, hashToStep } from 'flows/components/Anchor/Anchor'
import { RulePanel } from 'flows/components/RulePanel/RulePanel'
import { RulesList } from 'flows/components/RulesList/RulesList'
import { CheckoutStep } from 'flows/constants'
import { useFlowRoutes } from 'flows/hooks/useFlowRoutes'
import { useRules } from 'flows/hooks/useRules'
import { PageLayout } from 'shared/components/PageLayout'
import { Flow, Action } from 'shared/constants'
import { pathTo } from 'shared/paths/flows'

const CheckoutBody = () => (
  <Text isReadable>
    Your customer visits the checkout page and is presented with every payment
    option available to them. For a PCI - compliant experience, we recommend you{' '}
    <TextLink
      href="https://docs.gr4vy.com/guides/payments/embed/quick-start/overview"
      opensInNewWindow
      isExternal={true}
    >
      integrate Gr4vy Embed
    </TextLink>{' '}
    into your checkout.
  </Text>
)

const SelectPaymentTypeBody = () => (
  <Text isReadable>
    Modify the payment options available to your customers at checkout. Rules
    are evaluated top to bottom where the outcome of the first matched rule is
    used. Payment options are filtered based on the checkout country and
    currency.
  </Text>
)

const TransactionsBody = ({
  merchantAccountId,
}: {
  merchantAccountId: string
}) => (
  <>
    <Text isReadable>
      Your customer submits their payment and the transaction is processed by
      Gr4vy. Depending on the payment option selected, the transaction can be
      controlled through the card transactions flow.
    </Text>
    <ButtonLink href={pathTo.flow(merchantAccountId, Flow.cardTransactions)}>
      Card transactions
    </ButtonLink>
  </>
)

const anchorItems: AnchorLinkItemProps[] = [
  {
    key: CheckoutStep.checkout,
    href: stepToHash(CheckoutStep.checkout),
    title: 'Checkout',
  },
  {
    key: CheckoutStep.selectPaymentTypes,
    href: stepToHash(CheckoutStep.selectPaymentTypes),
    title: 'Select payment options',
    className: 'highlighted',
  },
  {
    key: CheckoutStep.transactions,
    href: stepToHash(CheckoutStep.transactions),
    title: 'Transactions',
  },
]

const CheckoutPage = ({ title }: { title: string }) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const navigationTabs = useFlowRoutes()
  const { isLoading, data: rules } = useRules(Flow.checkout)
  const [anchor, setAnchor] = useState(
    hashToStep(window.location.hash) || CheckoutStep.checkout
  )

  if (isLoading) {
    return null
  }

  return (
    <PageLayout title={title} subNavigation={navigationTabs}>
      <Grid>
        <GridItem gridColumn="span 8">
          <Stack gap={32} direction="column">
            <RulePanel
              id={CheckoutStep.checkout}
              number={1}
              selected={anchor === CheckoutStep.checkout}
              title="Checkout"
              body={<CheckoutBody />}
            />
            <RulePanel
              id={CheckoutStep.selectPaymentTypes}
              number={2}
              selected={anchor === CheckoutStep.selectPaymentTypes}
              title="Select payment options"
              body={<SelectPaymentTypeBody />}
              highlighted
            >
              <RulesList
                flow={Flow.checkout}
                action={Action.selectPaymentOption}
                rules={rules?.[Action.selectPaymentOption]}
              />
            </RulePanel>
            <RulePanel
              id={CheckoutStep.transactions}
              number={3}
              selected={anchor === CheckoutStep.transactions}
              title="Transactions"
              paddingBottom="none"
              body={<TransactionsBody merchantAccountId={merchantAccountId} />}
            />
          </Stack>
        </GridItem>
        <GridItem gridColumn="span 4" height="full">
          <Box paddingTop={32} paddingLeft={80}>
            <Anchor
              defaultAnchor={stepToHash(anchor)}
              onChange={(nextAnchor) => {
                setAnchor(hashToStep(nextAnchor))
              }}
              items={anchorItems}
            />
          </Box>
        </GridItem>
      </Grid>
    </PageLayout>
  )
}

export default CheckoutPage
