import { generatePath } from 'react-router-dom'
import { Flow, Action } from 'shared/constants'

export const FLOWS_PATH = '/merchants/:merchantAccountId/flow'
export const FLOW_CHECKOUT_PATH = `${FLOWS_PATH}/${Flow.checkout}`
export const FLOW_CARD_TRANSACTIONS_PATH = `${FLOWS_PATH}/${Flow.cardTransactions}`
export const FLOW_REDIRECT_TRANSACTIONS_PATH = `${FLOWS_PATH}/${Flow.redirectTransactions}`
export const FLOW_NON_CARD_TRANSACTIONS_PATH = `${FLOWS_PATH}/${Flow.nonCardTransactions}`
export const FLOW_RULE_PATH = `${FLOWS_PATH}/:flow/:action`
export const FLOW_EDIT_RULE_PATH = `${FLOWS_PATH}/:flow/:action/:id`

const appendQueryString = (url: string, queryParams: URLSearchParams) =>
  `${url}${
    queryParams.toString().length > 0 ? `?${queryParams.toString()}` : ''
  }`

export const pathTo = {
  flows: (merchantAccountId: string) =>
    generatePath(FLOWS_PATH, { merchantAccountId }),
  flow: (
    merchantAccountId: string,
    flow: Flow,
    queryParams: URLSearchParams = new URLSearchParams()
  ) =>
    appendQueryString(
      `${pathTo.flows(merchantAccountId)}/${flow}`,
      queryParams
    ),
  rules: (
    merchantAccountId: string,
    flow: Flow,
    action: Action,
    queryParams: URLSearchParams = new URLSearchParams()
  ) =>
    appendQueryString(
      `${pathTo.flow(merchantAccountId, flow)}/${action}`,
      queryParams
    ),
  // eslint-disable-next-line max-params
  rule: (
    merchantAccountId: string,
    flow: Flow,
    action: Action,
    id: string,
    queryParams: URLSearchParams = new URLSearchParams()
  ) =>
    appendQueryString(
      `${pathTo.rules(merchantAccountId, flow, action)}/${id}`,
      queryParams
    ),
}
