import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useSession } from 'session/hooks/use-session'

const SignOutPage = () => {
  const { isSignedOut, signOut, signInPath } = useSession()

  useEffect(() => {
    signOut()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isSignedOut ? (
    <Navigate data-testid="sign-out" replace to={signInPath} />
  ) : null
}

export default SignOutPage
