import dayjs, { Dayjs } from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { FilterRange } from 'shared/components/DateTimeRangePicker/dateTimeFilter'

dayjs.extend(quarterOfYear)

export const getDateRangeByFilter = (range: FilterRange) =>
  ({
    [FilterRange.CUSTOM]: undefined,
    [FilterRange.TODAY]: [dayjs().startOf('day'), dayjs().endOf('day')],
    [FilterRange.YESTERDAY]: [
      dayjs().subtract(1, 'day').startOf('day'),
      dayjs().subtract(1, 'day').endOf('day'),
    ],
    [FilterRange.THIS_WEEK]: [dayjs().startOf('week'), dayjs().endOf('week')],
    [FilterRange.LAST_WEEK]: [
      dayjs().subtract(1, 'week').startOf('week'),
      dayjs().subtract(1, 'week').endOf('week'),
    ],
    [FilterRange.THIS_MONTH]: [
      dayjs().startOf('month'),
      dayjs().endOf('month'),
    ],
    [FilterRange.LAST_MONTH]: [
      dayjs().subtract(1, 'month').startOf('month'),
      dayjs().subtract(1, 'month').endOf('month'),
    ],
    [FilterRange.THIS_QUARTER]: [
      dayjs().startOf('quarter'),
      dayjs().endOf('quarter'),
    ],
    [FilterRange.LAST_QUARTER]: [
      dayjs().subtract(1, 'quarter').startOf('quarter'),
      dayjs().subtract(1, 'quarter').endOf('quarter'),
    ],
    [FilterRange.THIS_YEAR]: [dayjs().startOf('year'), dayjs().endOf('year')],
  })[range] as [Dayjs?, Dayjs?] | undefined
