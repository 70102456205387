import { Description, Heading, Stack } from '@gr4vy/poutine-react'
import { useMemo } from 'react'
import { ClientPagination } from 'shared/components/ClientPagination'
import { ColumnDef, DataTable } from 'shared/components/DataTable'
import { Panel } from 'shared/components/Panel'
import currencyFormat from 'shared/helpers/currency-format'
import { useClientFiltering } from 'shared/hooks'
import { CartItem, Transaction } from 'transactions/services'

export const cartItemTableColumns: Array<ColumnDef<CartItem>> = [
  {
    accessorKey: 'name',
    size: 200,
    header: 'Item',
    cell: ({ row }) => {
      const cartItem = row.original

      return (
        <Description>
          <Description.Text>{cartItem.name}</Description.Text>
          <Description.SubText>{cartItem.sku ?? ''}</Description.SubText>
        </Description>
      )
    },
  },
  {
    accessorKey: 'unitAmount',
    size: 100,
    cell: ({ row }) => {
      const cartItem = row.original

      const formattedAmount = currencyFormat(cartItem.unitAmount, {
        currency: cartItem.currency || 'USD',
      })

      const quantity = `x${cartItem.quantity.toString()}`

      return (
        <Description>
          <Description.Text
            style={{
              fontVariantNumeric: 'tabular-nums',
            }}
          >
            {formattedAmount}
          </Description.Text>
          <Description.SubText>{quantity}</Description.SubText>
        </Description>
      )
    },
    header: () => 'Quantity',
  },
  {
    accessorKey: 'discount',
    size: 100,
    cell: ({ row }) => {
      const cartItem = row.original
      const formattedAmount = currencyFormat(cartItem.discountAmount, {
        currency: cartItem.currency || 'USD',
      })

      return (
        <Description>
          <Description.Text
            style={{
              fontVariantNumeric: 'tabular-nums',
            }}
          >
            {formattedAmount ?? '-'}
          </Description.Text>
        </Description>
      )
    },
    header: () => 'Discount',
  },
  {
    accessorKey: 'tax',
    size: 100,
    cell: ({ row }) => {
      const cartItem = row.original
      const formattedAmount = currencyFormat(cartItem.taxAmount, {
        currency: cartItem.currency || 'USD',
      })

      return (
        <Description>
          <Description.Text
            style={{
              fontVariantNumeric: 'tabular-nums',
            }}
          >
            {formattedAmount ?? '-'}
          </Description.Text>
        </Description>
      )
    },
    header: () => 'Tax',
  },
  {
    accessorKey: 'amount',
    size: 100,
    cell: ({ row }) => {
      const cartItem = row.original
      const amount =
        cartItem.quantity * cartItem.unitAmount -
        (cartItem.discountAmount ?? 0) +
        (cartItem.taxAmount ?? 0)
      const formattedAmount = currencyFormat(amount, {
        currency: cartItem.currency || 'USD',
      })

      return (
        <Description>
          <Description.Text
            style={{
              fontVariantNumeric: 'tabular-nums',
            }}
          >
            {formattedAmount}
          </Description.Text>
        </Description>
      )
    },
    header: () => 'Total',
  },

  {
    accessorKey: 'categories',
    size: 224,
    cell: ({ getValue }) => {
      const categories = getValue<CartItem['categories']>() ?? []

      return (
        <Description>
          <Description.Text>
            {categories.length === 0 ? '-' : categories.join(', ')}
          </Description.Text>
        </Description>
      )
    },
    header: () => 'Categories',
  },
]

const CartItemsPanel = ({ transaction }: { transaction: Transaction }) => {
  const cartItems = useMemo(
    () =>
      (transaction.cartItems ?? []).map((cartItem) => ({
        ...cartItem,
        currency: transaction.currency,
      })),
    [transaction]
  )

  const { pagedData, ...rest } = useClientFiltering(cartItems)

  return (
    <Panel>
      <Panel.Header>
        <Heading level={4}>Cart items</Heading>
      </Panel.Header>
      <Panel.Content>
        <Stack gap={32}>
          <DataTable
            data={{ items: pagedData }}
            columns={cartItemTableColumns}
          />
          <ClientPagination {...rest} />
        </Stack>
      </Panel.Content>
    </Panel>
  )
}

export default CartItemsPanel
