import { Flex, Stack, Button, Drawer, Icon } from '@gr4vy/poutine-react'
import { Form, FormInstance } from 'antd'
import { useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  OutcomeFormValues,
  OutcomeInstrument,
  OutcomeResult,
  TransactionActionOutcome,
} from 'flows/constants'
import { useOutcomes } from 'flows/hooks/useOutcomes'
import { preparePayload } from 'flows/utils/cardOutcome'
import { Action, Flow } from 'shared/constants'
import { ConnectionStep } from './ConnectionStep'
import { FinishStep } from './FinishStep'
import { InstrumentStep } from './InstrumentStep'
import { MessageStep } from './MessageStep'

export type AddConnectionDrawerProps = {
  open: boolean
  form: FormInstance<OutcomeFormValues>
  isEditing: boolean
  onClose: () => void
}

export const STEPS = {
  connection: 'connection',
  instrument: 'instrument',
  message: 'message',
  finish: 'finish',
} as const

export const AddConnectionDrawer = ({
  open,
  form,
  isEditing,
  onClose,
}: AddConnectionDrawerProps) => {
  const [step, setStep] = useState<keyof typeof STEPS>('connection')
  const outcomeForm = Form.useFormInstance()
  const { merchantAccountId } = useParams<{ merchantAccountId: string }>()
  const paymentServiceId = Form.useWatch('paymentServiceId', form)
  const { data, isLoading } = useOutcomes({
    merchantAccountId,
    flow: Flow.cardTransactions,
    action: Action.routeTransaction,
  })

  const selectedConnection = useMemo(
    () =>
      (data?.items as TransactionActionOutcome[]).find(
        (item) => item.id === paymentServiceId
      ),
    [data?.items, paymentServiceId]
  )

  const onCloseDrawer = () => {
    form.resetFields()
    onClose()
    setStep(STEPS.connection)
  }

  const getNextStep = (step: keyof typeof STEPS) =>
    ({
      [step]: step,
      connection: selectedConnection?.instruments.includes(
        OutcomeInstrument.NETWORK_TOKEN
      )
        ? STEPS.instrument
        : STEPS.message,
      instrument: STEPS.message,
      message: STEPS.finish,
    })[step]

  const getPreviousStep = (step: keyof typeof STEPS) =>
    ({
      [step]: step,
      instrument: STEPS.connection,
      message: selectedConnection?.instruments.includes(
        OutcomeInstrument.NETWORK_TOKEN
      )
        ? STEPS.instrument
        : STEPS.connection,
      finish: STEPS.message,
    })[step]

  const onNextStep = async () => {
    try {
      await form.validateFields()
      setStep((prevStep) => getNextStep(prevStep))
    } catch {}
  }

  const onPreviousStep = () => {
    if (step === STEPS.connection) {
      onClose()
      return
    }

    setStep((prevStep) => getPreviousStep(prevStep))
  }

  const handleUpdateConnections = (connection: OutcomeResult) => {
    const currentConnections = outcomeForm.getFieldValue(['outcome', 'result'])

    if (isEditing) {
      const connectionIndex = form.getFieldValue('paymentServiceIndex')

      return currentConnections.map((outcome: OutcomeResult, index: number) =>
        connectionIndex === index ? connection : outcome
      )
    }

    return [...currentConnections, connection]
  }

  const onFinish = (values: OutcomeFormValues) => {
    const connection = preparePayload(values)
    const updatedOutcomeResult = handleUpdateConnections(connection)

    outcomeForm.setFieldsValue({
      outcome: {
        result: updatedOutcomeResult,
      },
    })

    setStep('finish')
  }

  return (
    <Drawer
      open={open}
      title={`${isEditing ? 'Edit' : 'Add'} connection`}
      onClose={onCloseDrawer}
      footer={
        step !== 'finish' && (
          <Flex gap={16} justifyContent="flex-end">
            <Button type="button" variant="secondary" onClick={onPreviousStep}>
              {step === 'connection' ? (
                'Cancel'
              ) : (
                <>
                  <Icon name="arrow-left-md" size="small" />
                  Back
                </>
              )}
            </Button>
            {['connection', 'instrument'].includes(step) && (
              <Button type="button" onClick={onNextStep}>
                Next
                <Icon name="arrow-right-md" size="small" />
              </Button>
            )}
            {step === 'message' && (
              <Button form="connection">
                {isEditing ? 'Update' : 'Add'} connection
              </Button>
            )}
          </Flex>
        )
      }
    >
      <Stack gap={24}>
        <Form
          name="connection"
          form={form}
          layout="vertical"
          requiredMark={false}
          initialValues={{
            paymentServiceId: undefined,
            instrument: OutcomeInstrument.PAN,
            transformationsForceMit: false,
            transformationsUseAdditionalScheme: false,
          }}
          onFinish={onFinish}
        >
          <ConnectionStep
            selectedConnection={selectedConnection}
            outcomes={data?.items as TransactionActionOutcome[]}
            loading={isLoading}
            hidden={step !== 'connection'}
          />
          {selectedConnection && (
            <>
              <InstrumentStep
                selectedConnection={selectedConnection}
                hidden={step !== 'instrument'}
              />
              <MessageStep
                selectedConnection={selectedConnection}
                hidden={step !== 'message'}
              />
              <FinishStep
                selectedConnection={selectedConnection}
                hidden={step !== 'finish'}
                onClose={onCloseDrawer}
                isEditing={isEditing}
              />
            </>
          )}
        </Form>
      </Stack>
    </Drawer>
  )
}
