import {
  ButtonCopy,
  Container,
  Stack,
  Grid,
  GridItem,
} from '@gr4vy/poutine-react'
import { ReactNode } from 'react'
import { BuyerPanel } from 'transactions/components/BuyerPanel'
import TransactionOverview from 'transactions/components/TransactionOverview'
import { TransactionPageLayout } from 'transactions/components/TransactionPageLayout'
import { PaymentMethod, PayoutSummary } from 'transactions/services'

interface PayoutDetailsViewProps {
  payout?: PayoutSummary
  paymentMethod?: PaymentMethod
  title: string
  titleChildren: ReactNode
  titleSubChildren: ReactNode
}

const PayoutDetailsPageView = ({
  payout,
  paymentMethod,
}: PayoutDetailsViewProps) => {
  return (
    <>
      {payout && (
        <TransactionPageLayout
          transaction={payout}
          actions={
            <>
              <ButtonCopy variant="secondary" copy={document.location.href}>
                Copy link
              </ButtonCopy>
            </>
          }
        >
          <Stack gap={32}>
            <TransactionOverview
              transaction={payout}
              paymentMethod={paymentMethod}
            />
            <Container>
              <Grid>
                {payout.buyer && (
                  <GridItem gridColumn="span 12">
                    <BuyerPanel data={payout} />
                  </GridItem>
                )}
              </Grid>
            </Container>
          </Stack>
        </TransactionPageLayout>
      )}
    </>
  )
}

export default PayoutDetailsPageView
