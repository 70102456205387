import {
  Alert,
  Button,
  Checkbox,
  Drawer,
  Flex,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Text,
  TextLink,
} from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { Fragment, useEffect, useMemo, useState, MouseEvent } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { pathTo } from 'shared/paths/users'
import NewUserPageLayout from 'users/components/NewUserPageLayout'
import { getPermissionNameByRoleName, permissionsDrawer } from 'users/constants'
import { useEditRoles } from 'users/hooks/use-edit-roles'
import { useRoles } from 'users/hooks/use-roles'
import { useUseManager } from 'users/hooks/use-use-manager'
import { Role, UpdateUser, User } from 'users/services/users'

interface UserAdditionalPermissionsPageProps {
  title: string
  user?: User
  loading: boolean
  update: UseMutationResult<User, any, UpdateUser, unknown>
}

const PermissionsLoader = (
  <Skeleton gap={16} width={240}>
    {Array.from({ length: 3 }, (_, n) => (
      <Skeleton.Box key={n} height={24} />
    ))}
  </Skeleton>
)

export default function UserAdditionalPermissionsPage({
  title,
  user,
  loading,
  update,
}: UserAdditionalPermissionsPageProps) {
  const navigate = useNavigate()
  const { additionalPermissionsRoles } = useRoles()
  const [searchParams] = useSearchParams()
  const roleIds = useMemo(
    () => searchParams.get('roleIds')?.split(',') || [],
    [searchParams]
  )
  const merchantAccountIds = useMemo(
    () => searchParams.get('merchantAccountIds')?.split(',') || [],
    [searchParams]
  )
  const { permissionRoleIds, setPermissionRoleIds } = useEditRoles(user)
  const [showPermissionsDrawer, setShowPermissionsDrawer] = useState(false)
  const { isUserManager } = useUseManager()

  const handleNextStep = () => {
    navigate(
      pathTo.addUser.details(
        [...roleIds, ...permissionRoleIds],
        merchantAccountIds
      ),
      {
        state: {
          hasPermissionsStep: true,
        },
      }
    )
  }

  const handleUpdate = () => {
    if (!user) {
      return
    }

    update.mutate(
      {
        id: user.id,
        name: user?.name,
        roleIds: [...roleIds, ...permissionRoleIds],
        merchantAccountIds,
      },
      {
        onSuccess: () => navigate(pathTo.editUser.updatedUser(user.id)),
      }
    )
  }

  const handlePermissions = (checked: boolean, roleId: string) => {
    if (checked) {
      setPermissionRoleIds((currAdditionalRoleIds) => [
        ...currAdditionalRoleIds,
        roleId,
      ])

      return
    }

    setPermissionRoleIds((currAdditionalRoleIds) =>
      currAdditionalRoleIds.filter(
        (selectedRoleId) => selectedRoleId !== roleId
      )
    )
  }

  const handleShowDrawer = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setShowPermissionsDrawer(true)
  }

  const isChecked = (role: Pick<Role, 'id' | 'name'>) =>
    permissionRoleIds.includes(role.id) ||
    !!user?.roles?.find((userRole) => userRole.name === role.name)

  useEffect(() => {
    if (!roleIds.length) {
      navigate(pathTo.addUser.roles([]))
    }
  }, [navigate, roleIds.length])

  return (
    <>
      <NewUserPageLayout
        title={title}
        step={isUserManager ? 'USER_MANAGER_PERMISSIONS' : 'PERMISSIONS'}
      >
        <Stack gap={4}>
          <Heading as="h4">Will the user need additional permissions?</Heading>
          <Text variant="reg3" color="gray100">
            The role(s) you assigned allow you the option to grant more
            permissions to the user.{' '}
            <TextLink href="#" onClick={handleShowDrawer}>
              Learn more about additional permissions.
            </TextLink>
          </Text>
        </Stack>
        <Stack gap={32}>
          {isUserManager && (
            <Alert variant="information">
              <Alert.Icon />
              <Alert.Content>
                <Alert.Title>Information</Alert.Title>
                <Alert.Text>
                  You can only assign the following permissions because as a
                  User Manager you can only assign permissions you have access
                  to yourself. To assign other permissions, please reach out to
                  your Administrator.
                </Alert.Text>
              </Alert.Content>
            </Alert>
          )}
          <Stack gap={16}>
            {!additionalPermissionsRoles.length && !loading
              ? PermissionsLoader
              : additionalPermissionsRoles.map((role) => (
                  <Checkbox
                    key={role.id || role.name}
                    id={role.id}
                    defaultChecked={isChecked(role)}
                    onCheckedChange={(checked) =>
                      handlePermissions(checked as boolean, role.id)
                    }
                    disabled={!role.id}
                  >
                    {getPermissionNameByRoleName(role.name)}
                  </Checkbox>
                ))}
          </Stack>
        </Stack>
        <Flex gap={16}>
          {user ? (
            <>
              <Button onClick={handleUpdate} loading={update.isPending}>
                Update permissions
              </Button>
              <Button variant="secondary" onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button variant="secondary" onClick={() => navigate(-1)}>
                <Icon name="arrow-left-md" />
                Back
              </Button>
              <Button onClick={handleNextStep}>Continue</Button>
            </>
          )}
        </Flex>
      </NewUserPageLayout>
      <Drawer
        open={showPermissionsDrawer}
        title="Additional permissions"
        onClose={() => setShowPermissionsDrawer(false)}
      >
        <Stack gap={32}>
          {permissionsDrawer.map((role) => (
            <Fragment key={role.name}>
              <Stack gap={8}>
                <Heading as="h2" level={5}>
                  {role.name}
                </Heading>
                <Text>{role.description}</Text>
              </Stack>
            </Fragment>
          ))}
        </Stack>
      </Drawer>
    </>
  )
}
