import {
  Grid,
  GridItem,
  Heading,
  Stack,
  Checkbox,
  TextLink,
} from '@gr4vy/poutine-react'
import {
  MerchantNameInput,
  MerchantUrlInput,
} from 'connections/components/DigitalWalletForm'
import { FormItem } from 'shared/components/FormItem'
import { DigitalWalletActivatePage } from './DigitalWalletsActivatePage'

type FormValues = {
  merchantName: string
  merchantUrl: string
  acceptTermsAndConditions: boolean
  domainNames: Array<string>
}

export const DigitalWalletsActivateGooglePayPage = ({
  title,
  initialValues = {},
}: {
  title: string
  initialValues?: Partial<FormValues>
}) => (
  <DigitalWalletActivatePage
    title={title}
    heading={
      <Heading level={3} as="h1">
        Google Pay
      </Heading>
    }
    provider="google"
    initialValues={initialValues}
    render={(acceptTermsAndConditions, setAcceptTermsAndConditions) => (
      <>
        <Grid>
          <GridItem gridColumn="span 6">
            <Stack gap={24}>
              <Heading level={4}>Merchant details</Heading>
              <MerchantNameInput />
              <MerchantUrlInput />
            </Stack>
          </GridItem>
        </Grid>
        <Grid>
          <GridItem gridColumn="span 6">
            <Stack gap={8}>
              <Heading level={4}>Acceptable use guidelines</Heading>
              <FormItem
                name="acceptTermsAndConditions"
                rules={[
                  {
                    validator: () => {
                      if (acceptTermsAndConditions) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject(
                          'Must agree to the Terms and Conditions.'
                        )
                      }
                    },
                  },
                ]}
                valuePropName="checked"
                trigger="onCheckedChange"
              >
                <Checkbox
                  onCheckedChange={(checked) =>
                    setAcceptTermsAndConditions(checked as boolean)
                  }
                >
                  I agree to follow the{' '}
                  <TextLink
                    href="https://payments.developers.google.com/terms/sellertos"
                    opensInNewWindow
                  >
                    Acceptable Use Guidelines for Google Pay on the Web
                  </TextLink>
                </Checkbox>
              </FormItem>
            </Stack>
          </GridItem>
        </Grid>
      </>
    )}
  />
)
