import { Description, Heading, Stack } from '@gr4vy/poutine-react'
import { Status } from 'shared/components/Status'
import EventsConnection from 'transactions/components/EventsPanel/EventsConnection'
import { TransactionEvent } from 'transactions/services'

export const ConnectorVoidFailedEvent = ({
  event,
}: {
  event: TransactionEvent
}) => {
  return event ? (
    <Stack gap={32}>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Connection
        </Heading>
        <EventsConnection event={event} />
      </Stack>
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Status
        </Heading>
        <Description>
          <Description.Icon
            size={24}
            src={<Status value="authorization_failed" />}
          />
          <Description.Text>Void failed</Description.Text>
        </Description>
      </Stack>
    </Stack>
  ) : null
}
