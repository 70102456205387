import { useConnectionDefinitions } from 'connections/hooks/use-connection-definitions'
import { useConnectionRoutes } from 'connections/hooks/use-connection-routes'
import { ConnectionDefinition } from 'connections/services'
import { PageLayout } from 'shared/components/PageLayout'
import { ConnectionsCatalogPage as View } from './ConnectionsCatalogPage'

const ConnectionsCatalogPage = ({ title }: { title: string }) => {
  const navigationTabs = useConnectionRoutes()
  const { isLoading, isSuccess, definitions } = useConnectionDefinitions()

  if (isLoading || !isSuccess) {
    return null
  }

  return (
    <PageLayout title={title} subNavigation={navigationTabs}>
      <View connectionDefinitions={definitions as ConnectionDefinition[]} />
    </PageLayout>
  )
}
export default ConnectionsCatalogPage
