import { Box, Button, Flex, Icon, Modal, Text } from '@gr4vy/poutine-react'
import { User } from 'users/services/users'

interface RemoveUserModalProps {
  user: User | undefined
  open: boolean
  onClose: () => void
  removeUser: (user: User) => void
  isRemovingUser: boolean
}

const RemoveUserModal = ({
  user,
  open,
  onClose,
  removeUser,
  isRemovingUser,
}: RemoveUserModalProps) => (
  <Modal
    id="removeUser"
    icon={<Icon name="trash-empty-md" size="large" color="red60" />}
    title={`Remove ${user?.name}?`}
    open={open}
    onOpenChange={(status) => {
      !status && onClose()
    }}
    type="negative"
  >
    <Text>
      This action will remove {user?.name}&rsquo;s access to the dashboard. This
      user&rsquo;s content will not be deleted.
    </Text>
    <Box>
      <Text as="strong" fontWeight="bold">
        Warning:
      </Text>
      <Text as="span" paddingLeft={4}>
        This action can not be undone and the user will be removed permanently
      </Text>
    </Box>
    <Flex justifyContent="flex-end" gap={16}>
      <Button variant="secondary" onClick={onClose} disabled={isRemovingUser}>
        No, cancel
      </Button>
      <Button
        variant="primaryNegative"
        onClick={() => user && removeUser(user)}
        loading={isRemovingUser}
        loadingText="Removing"
      >
        Yes, remove
      </Button>
    </Flex>
  </Modal>
)

export default RemoveUserModal
