import {
  TextLine,
  Description,
  Flex,
  Tooltip,
  ButtonLink,
} from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { BuyerGiftCard } from 'buyers/services/giftCards'
import { PaymentMethod } from 'buyers/services/paymentMethods'
import { useGiftCardServiceDefinition } from 'connections/hooks/use-gift-card-service-definition'
import { formatGiftCardExpirationDate } from 'gift-cards/helpers'
import {
  DataTable,
  ColumnDef,
  DataTableProps,
} from 'shared/components/DataTable'
import { dateFormat } from 'shared/helpers/date-format'
import { getCurrentLocale } from 'shared/helpers/locale'
import { usePaymentMethodDefinition } from 'shared/hooks'
import { pathTo } from 'shared/paths/gift-cards'
import { QueryResult } from 'shared/services/client'
import { GiftCardMaskedNumber } from 'transactions/components/MaskedCardNumber/GiftCardMaskedNumber'

export type GiftCardsTableProps = Pick<
  DataTableProps<BuyerGiftCard>,
  'pagination'
> & {
  page: QueryResult<BuyerGiftCard>
}

const GiftCardsTable = ({ page, pagination }: GiftCardsTableProps) => {
  const { data, isLoading: loading } = page

  const columns: Array<ColumnDef<BuyerGiftCard>> = [
    {
      header: 'Type',
      size: 200,
      cell: function Method({ getValue }) {
        const details = usePaymentMethodDefinition(
          getValue<PaymentMethod['method']>(),
          true
        )
        return (
          <Description>
            <Description.Icon src={details?.iconUrl} size={24} />
            <Description.Text>Gift card</Description.Text>
          </Description>
        )
      },
    },
    {
      header: 'Label',
      size: 272,
      cell: function Label({ row }) {
        const cardNumber = {
          subBin: row.original.subBin,
          last4: row.original.last4,
          bin: row.original.bin,
        }

        return <GiftCardMaskedNumber cardNumber={cardNumber} />
      },
    },
    {
      header: 'Connection',
      size: 236,
      cell: function PaymentMethodScheme({ row }) {
        const { merchantAccountId } = useParams<{
          merchantAccountId: string
        }>()

        const { giftCardServiceDefinition } = useGiftCardServiceDefinition(
          merchantAccountId,
          row.original.giftCardService.giftCardServiceDefinitionId,
          {
            enabled: !!merchantAccountId,
          }
        )

        return (
          <Description>
            <Description.Icon
              size={24}
              src={giftCardServiceDefinition?.iconUrl}
            />
            {!!giftCardServiceDefinition?.displayName && (
              <Description.Text>
                {giftCardServiceDefinition?.displayName}
              </Description.Text>
            )}
          </Description>
        )
      },
    },
    {
      header: 'Expiration',
      accessorKey: 'expirationDate',
      size: 236,
      cell: ({ getValue }) => {
        const expirationDate = getValue<BuyerGiftCard['expirationDate']>()

        return (
          <TextLine display="block">
            {formatGiftCardExpirationDate(expirationDate)}
          </TextLine>
        )
      },
    },
    {
      header: 'Created',
      accessorKey: 'createdAt',
      size: 200,
      cell: ({ getValue }) => (
        <TextLine display="block">
          {dateFormat(
            getValue<BuyerGiftCard['createdAt']>(),
            getCurrentLocale()
          )}
        </TextLine>
      ),
    },
    {
      id: 'actions',
      header: '',
      size: 64,
      cell: function ActionsCell({ row }) {
        const { merchantAccountId } = useParams<{ merchantAccountId: string }>()

        return row.original.id && merchantAccountId ? (
          <Flex justifyContent="flex-end">
            <Tooltip content="Go to gift card">
              <ButtonLink
                variant="tertiary"
                size="small"
                href={pathTo.giftCardId(merchantAccountId, row.original.id)}
              />
            </Tooltip>
          </Flex>
        ) : null
      },
    },
  ]

  return (
    <DataTable
      data={data}
      loading={loading}
      columns={columns}
      pagination={pagination}
    />
  )
}

export default GiftCardsTable
