import { routes } from 'App/routes/routes'
import { useMultiMerchant } from 'shared/permissions/hooks/use-multi-merchant'

export const useMenuItems = () => {
  const { merchantAccountId } = useMultiMerchant()

  const nonAdminMenuItems = routes
    .filter(({ menu }) => menu)
    .map((route) => ({
      ...route,
      path: route.path.replace(':merchantAccountId', merchantAccountId),
    }))

  return [...nonAdminMenuItems]
}
