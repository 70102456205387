import { ReactNode } from 'react'
import { formatBin } from 'transactions/helpers/masked-card-number'
import { GiftCardRedemption, PaymentMethod } from 'transactions/services'

export interface UseTransactionPaymentMethodProps {
  paymentMethod: PaymentMethod | null
  giftCardRedemptions: GiftCardRedemption[]
}

export interface CardNumber {
  bin?: string
  mask: ReactNode
  prefix: string
  subBin: string | null
  last4: string | null
}

export const useTransactionPaymentMethod = ({
  paymentMethod,
  giftCardRedemptions,
}: UseTransactionPaymentMethodProps) => {
  const getPaymentMethodsCount = () => {
    if (!paymentMethod) {
      return giftCardRedemptions?.length > 1
        ? giftCardRedemptions?.length - 1
        : 1
    }

    return giftCardRedemptions?.length
  }

  const getCardMask = () => {
    if (!paymentMethod) {
      return '••••••'
    }

    return paymentMethod?.scheme === 'amex' ? '•••• •' : <>•• •••• </>
  }

  const paymentMethodsCount = getPaymentMethodsCount()

  const isSinglePaymentMethod = !!paymentMethod
    ? paymentMethod && !giftCardRedemptions?.length
    : giftCardRedemptions?.length === 1

  const bin = !paymentMethod
    ? giftCardRedemptions[0]?.giftCard.bin
    : paymentMethod?.details?.bin
  const prefix = bin ? formatBin(bin) : '•••• ••'
  const subBin = !paymentMethod ? giftCardRedemptions[0]?.giftCard.subBin : null
  const last4 = !paymentMethod ? giftCardRedemptions[0]?.giftCard.last4 : null

  return {
    paymentMethodsCount,
    isSinglePaymentMethod,
    formattedGiftCardRedemptions: !paymentMethod
      ? giftCardRedemptions.slice(1)
      : giftCardRedemptions,
    cardNumber: {
      bin,
      mask: getCardMask(),
      prefix,
      subBin,
      last4,
    },
  }
}
