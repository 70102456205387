import { Button, Heading, Icon } from '@gr4vy/poutine-react'

type SuccessMessageProps = {
  onClose: () => void
}

export const SuccessMessage = ({ onClose }: SuccessMessageProps) => (
  <>
    <Icon name="circle-check" size="large" color="green60" />
    <Heading as="h2" level={5}>
      Merchant created successfully.
    </Heading>
    <Button variant="secondary" onClick={onClose}>
      Close
    </Button>
  </>
)
