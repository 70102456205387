import { Button, Icon, Modal } from '@gr4vy/poutine-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { saveApiKeyPair } from 'integrations/helpers/download'
import { useIntegrationRoutes } from 'integrations/hooks/use-integration-routes'
import ApiKeyPairModal from 'integrations/modals/ApiKeyPairModal'
import {
  addApiKeyPair,
  listApiKeyPairs,
  NewApiKeyPair,
  removeApiKeyPair,
  updateApiKeyPair,
} from 'integrations/services/apiKeyPairs'
import { PageLayout } from 'shared/components/PageLayout'
import { API_KEY_PAIRS } from 'shared/constants/query-keys'
import { useCursor, Filters, useFilters } from 'shared/hooks'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import { handleSuccessResponse } from 'shared/utils/handleSuccessResponse'
import { useRoles } from 'users/hooks/use-roles'
import View from './KeysBrowsePage'

const { useModal } = Modal

const IntegrationsPage = ({ title }: { title: string }) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const queryClient = useQueryClient()
  const navigationTabs = useIntegrationRoutes()
  const [cursor] = useCursor()
  const [filters, setFilters] = useFilters()

  const invalidateApiKeyPairs = () =>
    queryClient.invalidateQueries({ queryKey: [API_KEY_PAIRS] })

  const { isModalOpen, openModal, closeModal } = useModal()

  const addKey = useMutation({
    mutationFn: async ({
      displayName,
      algorithm,
      merchantAccountId,
      roleId,
    }: NewApiKeyPair) => {
      const apiKeyPair = await addApiKeyPair({
        displayName,
        algorithm,
        merchantAccountId,
        roleId,
      })
      await saveApiKeyPair(apiKeyPair)
    },
    onSuccess: handleSuccessResponse(
      'New API key pair added',
      invalidateApiKeyPairs
    ),
    onError: handleErrorResponse,
  })

  const updateApiKey = useMutation({
    mutationFn: updateApiKeyPair,
    onSuccess: handleSuccessResponse(
      'API key pair updated',
      invalidateApiKeyPairs
    ),
    onError: handleErrorResponse,
  })

  const removeKey = useMutation({
    mutationFn: removeApiKeyPair,
    onSuccess: handleSuccessResponse(
      'API key pair removed',
      invalidateApiKeyPairs
    ),
    onError: handleErrorResponse,
  })

  const page = useQuery({
    queryKey: [API_KEY_PAIRS, merchantAccountId, cursor, filters],
    queryFn: () => listApiKeyPairs({ cursor, ...filters }),
    meta: {
      error: {
        redirect: true,
      },
    },
  })

  const { apiKeyPairRoles } = useRoles()

  const updateFilter = (f: Filters) => {
    setFilters({ ...filters, ...f })
  }

  return (
    <PageLayout
      title={title}
      subNavigation={navigationTabs}
      actions={
        <Button variant="primary" onClick={() => openModal('addApiKeyPair')}>
          <Icon name="add-plus" />
          Add API key
        </Button>
      }
    >
      <View
        title={title}
        page={page}
        updateApiKey={updateApiKey}
        removeKey={removeKey}
        filters={filters}
        onFilter={updateFilter}
      />
      <ApiKeyPairModal
        roles={apiKeyPairRoles}
        onAdd={addKey}
        onClose={() => closeModal('addApiKeyPair')}
        open={isModalOpen('addApiKeyPair')}
      />
    </PageLayout>
  )
}

export default IntegrationsPage
