import { Divider } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { CredentialsChecker } from 'connections/components/CredentialsChecker'
import { validateAntiFraudService } from 'connections/services'
import { CredentialField } from 'shared/components/CredentialField/CredentialField'
import { Label } from 'shared/components/Form'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { SimulatorEdit } from './SimulatorEdit'

export const SimulatorCredentials = () => {
  const { id } = useParams() as { id: string }
  const hasWritePermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )

  return (
    <SimulatorEdit submitButtonText="Save changes">
      <CredentialField
        item={{ key: 'api_key', displayName: <Label>API key</Label> }}
        required
        secret
        group="fields"
      />
      {hasWritePermission && (
        <CredentialsChecker
          fieldNames={[['fields', 'api_key']]}
          verifyCredentials={validateAntiFraudService}
          antiFraudServiceDefinitionId="mock-anti-fraud"
          antiFraudServiceId={id}
        />
      )}
      <Divider />
    </SimulatorEdit>
  )
}

export default SimulatorCredentials
