import { generatePath } from 'react-router-dom'
import { GiftCardTabs } from 'shared/constants'

export const GIFT_CARDS_PATH = '/merchants/:merchantAccountId/gift-cards'
export const GIFT_CARD_PATH = `${GIFT_CARDS_PATH}/:giftCardId`

export const pathTo = {
  giftCardId: (
    merchantAccountId: string,
    giftCardId: string,
    tab: GiftCardTabs = GiftCardTabs.overview
  ) =>
    `${generatePath(GIFT_CARD_PATH, {
      merchantAccountId,
      giftCardId,
    })}/${tab}`,
}
