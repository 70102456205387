import { Box } from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { ReactElement } from 'react'
import { Key } from './Key'
import styles from './Summary.module.scss'
import { Value } from './Value'

interface SummaryProps {
  inline?: boolean
  className?: string
  children: (ReactElement<typeof Key | typeof Value> | false)[]
}

export const Summary = ({
  inline = false,
  className,
  children,
}: SummaryProps) => {
  return (
    <Box
      as="dl"
      className={clsx(styles.summary, { [styles.inline]: inline }, className)}
    >
      {children}
    </Box>
  )
}

Summary.Key = Key
Summary.Value = Value
