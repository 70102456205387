import { Box, Grid, GridItem, Stack, Heading } from '@gr4vy/poutine-react'
import { ColorGray20 } from '@gr4vy/poutine-tokens/js/tokens'
import { ReportCard } from 'reports/components/ReportCard'
import { Category, ReportTemplate } from 'reports/constants'
import { useReportsSearch } from 'reports/hooks/use-reports-search'
import { SearchBar } from 'shared/components/SearchBar/SearchBar'
import { AllReportsPageNav } from './AllReportsPageNav'
import { NoSearchResults } from './NoSearchResults'

interface AllReportsPageProps {
  templates: ReportTemplate[]
  categories: Category[]
  onReportClick: (id: string, isCustom: boolean, schedule: boolean) => void
}

export const AllReportsPage = ({
  templates: allTemplates,
  categories: allCategories,
  onReportClick,
}: AllReportsPageProps) => {
  const { searchTerm, search, templates, categories } = useReportsSearch(
    allTemplates,
    allCategories
  )

  return (
    <Grid rowGap={48}>
      <GridItem gridColumn="span 4">
        <SearchBar
          searchTerm={searchTerm}
          itemsFoundCount={templates.length}
          search={search}
        />
      </GridItem>
      <GridItem gridColumn="span 8" />
      <GridItem gridColumn="span 8">
        {templates.length > 0 && (
          <Stack gap={64}>
            {categories.map((category) => (
              <Stack gap={32} key={category}>
                <Stack id={category.toLowerCase()} gap={16}>
                  <Heading level={4}>{category}</Heading>
                  <Box style={{ borderBottom: `2px solid ${ColorGray20}` }} />
                </Stack>
                <Grid gap={32}>
                  {templates
                    .filter(
                      ({ category: templateCategory }) =>
                        templateCategory === category
                    )
                    .map(
                      ({
                        id,
                        title,
                        description,
                        isCustom,
                        isScheduleOnly,
                      }) => (
                        <GridItem key={id} gridColumn="span 6">
                          <ReportCard
                            id={id}
                            title={title}
                            description={description}
                            {...(!isScheduleOnly && {
                              onGenerateClick: () =>
                                onReportClick(id, isCustom, false),
                            })}
                            onScheduleClick={() =>
                              onReportClick(id, isCustom, true)
                            }
                          />
                        </GridItem>
                      )
                    )}
                </Grid>
              </Stack>
            ))}
          </Stack>
        )}
        {templates.length === 0 && <NoSearchResults clearSearch={search} />}
      </GridItem>
      <GridItem gridColumn="span 4">
        <AllReportsPageNav templates={templates} categories={categories} />
      </GridItem>
    </Grid>
  )
}
