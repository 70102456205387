import { is } from 'shared/helpers/is'

export const mapObjectStructure = (
  source: Record<string, any> | undefined,
  template: any
) =>
  Object.keys(template).reduce(
    (previousValue, key) => {
      if (is.plainObject(template[key])) {
        previousValue[key] = mapObjectStructure(
          source ? source[key] : undefined,
          template[key]
        )
      } else {
        previousValue[key] = source ? source[key] : undefined
      }

      return previousValue
    },
    {} as Record<string, any>
  )
