import { Box, Grid, GridItem, Stack, Text } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import { AnchorLinkItemProps } from 'antd/es/anchor/Anchor'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Anchor, hashToStep, stepToHash } from 'flows/components/Anchor/Anchor'
import { RulePanel } from 'flows/components/RulePanel/RulePanel'
import { RulesList } from 'flows/components/RulesList/RulesList'
import { OtherTransactionsStep } from 'flows/constants'
import { useFlowRoutes } from 'flows/hooks/useFlowRoutes'
import { OutcomeOption } from 'flows/hooks/useOutcomeOptions'
import { useRules } from 'flows/hooks/useRules'
import { useSelectedConnection } from 'flows/hooks/useSelectedConnection'
import { PageLayout } from 'shared/components/PageLayout'
import { Action, Flow } from 'shared/constants'

const RouteToConnectionsBody = ({
  outcomeOptions,
  selectedConnection,
  setSelectedConnection,
}: {
  outcomeOptions: OutcomeOption[]
  selectedConnection: string
  setSelectedConnection: (value: string) => void
}) => (
  <Stack justifyContent="space-between" gap={16}>
    <Text>Select here which method to route transactions for.</Text>
    <Select
      defaultValue={selectedConnection === '' ? undefined : selectedConnection}
      placeholder="Select a method..."
      style={{ width: 300 }}
      disabled={outcomeOptions.length === 0}
      onChange={(value: string) => setSelectedConnection(value)}
    >
      {outcomeOptions.map((option) => (
        <Select.Option value={option.key} key={option.key}>
          {option.value}
        </Select.Option>
      ))}
    </Select>
    <Text isReadable>
      Select and prioritize connections to route your transactions. Rules are
      evaluated top to bottom where the outcome of the first matched rule is
      used.{' '}
    </Text>
  </Stack>
)

const TransactionRequestBody = () => (
  <>
    <Text isReadable>
      Your customer selects a non card payment method and submits the
      transaction.
    </Text>
  </>
)

const TransactionProcessingBody = () => (
  <Text isReadable>
    The transaction is processed with your chosen connection.
  </Text>
)

const DeclineBody = () => (
  <Stack width="readable" gap={16}>
    <Text>
      Choose to decline your transaction early to prevent any potential
      chargebacks or to comply with regulations. Rules are evaluated top to
      bottom where the outcome of the first matched rule is used.
    </Text>
    <Text>These rules apply to all types of non-card transactions.</Text>
  </Stack>
)

const anchorItems: AnchorLinkItemProps[] = [
  {
    key: OtherTransactionsStep.transactionRequest,
    href: stepToHash(OtherTransactionsStep.transactionRequest),
    title: 'Transaction request',
  },
  {
    key: OtherTransactionsStep.decline,
    href: stepToHash(OtherTransactionsStep.decline),
    title: 'Decline',
    className: 'highlighted',
  },
  {
    key: OtherTransactionsStep.routeToConnection,
    href: stepToHash(OtherTransactionsStep.routeToConnection),
    title: 'Route to connection',
    className: 'highlighted',
  },
  {
    key: OtherTransactionsStep.transactionProcessing,
    href: stepToHash(OtherTransactionsStep.transactionProcessing),
    title: 'Transaction processing',
  },
]

const OtherTransactionPage = ({ title }: { title: string }) => {
  const navigationTabs = useFlowRoutes()
  const [searchParams] = useSearchParams()

  const [anchor, setAnchor] = useState(
    hashToStep(window.location.hash) || OtherTransactionsStep.transactionRequest
  )
  const {
    outcomeOptions,
    rules: redirectTransactionRules,
    selectedConnection,
    setSelectedConnection,
  } = useSelectedConnection(
    Flow.redirectTransactions,
    Action.routeTransaction,
    searchParams
  )

  const { data: nonCardTransactionRules, isLoading } = useRules(
    Flow.nonCardTransactions
  )

  const rules = { ...nonCardTransactionRules, ...redirectTransactionRules }

  if (isLoading) {
    return null
  }

  return (
    <PageLayout title={title} subNavigation={navigationTabs}>
      <Grid>
        <GridItem gridColumn="span 8">
          <Stack gap={32} direction="column">
            <RulePanel
              id={OtherTransactionsStep.transactionRequest}
              number={1}
              selected={anchor === OtherTransactionsStep.transactionRequest}
              title="Transaction request"
              body={<TransactionRequestBody />}
            />
            <RulePanel
              id={OtherTransactionsStep.decline}
              number={2}
              highlighted
              selected={anchor === OtherTransactionsStep.decline}
              title="Decline"
              body={<DeclineBody />}
            >
              <RulesList
                flow={Flow.nonCardTransactions}
                action={Action.declineEarly}
                rules={rules?.[Action.declineEarly]}
              />
            </RulePanel>
            <RulePanel
              id={OtherTransactionsStep.routeToConnection}
              number={3}
              selected={anchor === OtherTransactionsStep.routeToConnection}
              title="Route to connection"
              body={
                <RouteToConnectionsBody
                  outcomeOptions={outcomeOptions.sort((a, b) =>
                    a.value.localeCompare(b.value)
                  )}
                  setSelectedConnection={setSelectedConnection}
                  selectedConnection={selectedConnection}
                />
              }
              highlighted
            >
              <RulesList
                flow={Flow.redirectTransactions}
                action={Action.routeTransaction}
                rules={rules[Action.routeTransaction]}
                additionalParams={
                  // eslint-disable-next-line camelcase
                  new URLSearchParams({ payment_method: selectedConnection })
                }
                disabled={!selectedConnection}
              />
            </RulePanel>
            <RulePanel
              id={OtherTransactionsStep.transactionProcessing}
              number={4}
              selected={anchor === OtherTransactionsStep.transactionProcessing}
              title="Transaction processing"
              paddingBottom="none"
              body={<TransactionProcessingBody />}
            />
          </Stack>
        </GridItem>
        <GridItem gridColumn="span 4" height="full">
          <Box paddingTop={32} paddingLeft={80}>
            <Anchor
              defaultAnchor={stepToHash(anchor)}
              onChange={(nextAnchor) => {
                setAnchor(hashToStep(nextAnchor))
              }}
              items={anchorItems}
            />
          </Box>
        </GridItem>
      </Grid>
    </PageLayout>
  )
}

export default OtherTransactionPage
