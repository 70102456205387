import {
  Checkbox,
  FilterButton,
  Flex,
  Popover,
  Stack,
} from '@gr4vy/poutine-react'
import { useMemo } from 'react'
import { useKeyboardListNavigation, Filters, useFilters } from 'shared/hooks'
import { useRoles } from 'users/hooks/use-roles'
import styles from './RolesFilter.module.scss'

interface RolesFilters extends Filters {
  roles?: string | string[]
}

const getToggledRolesFilter = (
  newRoleId: string,
  currentRolesFilter?: string | string[]
) => {
  if (!currentRolesFilter) {
    return newRoleId
  }

  const arrCurrentRolesFilter = [currentRolesFilter].flat()

  if (
    arrCurrentRolesFilter.includes(newRoleId) &&
    arrCurrentRolesFilter.length === 1
  ) {
    return undefined
  }

  return arrCurrentRolesFilter.includes(newRoleId)
    ? arrCurrentRolesFilter.filter((roleId) => roleId !== newRoleId)
    : [...arrCurrentRolesFilter, newRoleId]
}

export const RolesFilter = () => {
  const [filters, setFilters] = useFilters<RolesFilters>()
  const { onKeyDown } = useKeyboardListNavigation()
  const { userRoles } = useRoles()

  const rolesFilter = useMemo(() => (filters as RolesFilters)?.roles, [filters])
  const rolesFilterCounter = useMemo(() => {
    if (!rolesFilter) {
      return null
    }
    return ` / ${[rolesFilter].flat().length}`
  }, [rolesFilter])

  const onToggleRoleFilter = (roleId: string) => {
    setFilters({
      ...filters,
      roles: getToggledRolesFilter(roleId, rolesFilter),
    })
  }

  return (
    <Popover>
      <Popover.Trigger asChild>
        <FilterButton data-selected={!!rolesFilter}>
          Roles
          {rolesFilterCounter}
        </FilterButton>
      </Popover.Trigger>
      <Popover.Content
        align="start"
        side="bottom"
        avoidCollisions={false}
        onKeyDown={onKeyDown}
      >
        <Stack gap={4} className={styles.wrapper} role="listbox">
          {userRoles?.map((role) => (
            <Flex
              key={role.id}
              as="label"
              alignItems="center"
              className={styles.item}
              role="option"
              tabIndex={0}
            >
              <Checkbox
                id={role.id}
                onCheckedChange={() => onToggleRoleFilter(role.id)}
                checked={!!rolesFilter?.includes(role.id)}
                tabIndex={-1}
              >
                {role.name}
              </Checkbox>
            </Flex>
          ))}
        </Stack>
      </Popover.Content>
    </Popover>
  )
}
