import { Input } from 'antd'
import { HelpText, Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'

export const MerchantUrlInput = ({ disabled = false }) => (
  <FormItem
    name="merchantUrl"
    label={<Label>Merchant URL</Label>}
    rules={[{ type: 'url', message: 'Must enter a valid url' }]}
    help={
      <HelpText>
        The main URL of your company website. This is not verified.
      </HelpText>
    }
  >
    <Input disabled={disabled} />
  </FormItem>
)
