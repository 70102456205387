import { useQuery } from '@tanstack/react-query'
import { TRANSACTIONS } from 'shared/constants'
import { listTransactions } from 'transactions/services'

export interface TransactionsProps {
  merchantAccountId?: string
}

export const useTransactions = ({ merchantAccountId }: TransactionsProps) => {
  const { data, isLoading } = useQuery({
    queryKey: [TRANSACTIONS, merchantAccountId, null, { limit: 100 }],
    queryFn: () => listTransactions({ limit: 100 }),
    meta: {
      error: {
        redirect: true,
      },
    },
  })

  return {
    numberOfTransactions: data?.items.length ?? 0,
    isLoadingTransactions: isLoading,
  }
}
