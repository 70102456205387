import { Navigate } from 'react-router-dom'
import { pathTo as errorsPathTo } from 'shared/paths/errors'
import {
  TRANSACTION_OVERVIEW_PATH,
  TRANSACTIONS_PATH,
  RELATED_TRANSACTIONS_PATH,
  PAYOUTS_PATH,
  pathTo,
  PAYOUT_PATH,
} from 'shared/paths/transactions'
import { Resource } from 'shared/permissions/constants'
import { usePayoutsFeature } from './hooks/use-payouts-feature'
import {
  TransactionsBrowsePage,
  TransactionDetailsPage,
  RelatedTransactionsPage,
  PayoutsBrowsePage,
  PayoutDetailsPage,
} from './pages'

export default [
  {
    title: 'Transactions',
    longTitle: 'Transactions',
    key: 'transactions',
    path: TRANSACTIONS_PATH,
    menu: true,
    component: TransactionsBrowsePage,
    resource: Resource.transactions,
    activeMatch: pathTo.payoutsMatch,
  },
  {
    title: 'Transaction details',
    longTitle: 'Transaction details',
    key: 'transaction-details',
    path: TRANSACTION_OVERVIEW_PATH,
    menu: false,
    component: TransactionDetailsPage,
  },
  {
    title: 'Related transactions',
    longTitle: 'Related transactions',
    key: 'related-transactions',
    path: RELATED_TRANSACTIONS_PATH,
    menu: false,
    component: RelatedTransactionsPage,
  },
  {
    title: 'Transactions',
    longTitle: 'Transactions',
    key: 'transactions-payouts',
    path: PAYOUTS_PATH,
    menu: false,
    component: function Payouts() {
      const { isPayoutsFeatureEnabled } = usePayoutsFeature()
      return !isPayoutsFeatureEnabled ? (
        <Navigate replace to={errorsPathTo.accessDenied} />
      ) : (
        <PayoutsBrowsePage title="Transactions" />
      )
    },
    resource: Resource.transactions,
  },
  {
    title: 'Payout details',
    longTitle: 'Payout details',
    key: 'payout-details',
    path: PAYOUT_PATH,
    menu: false,
    component: function PayoutDetails() {
      const { isPayoutsFeatureEnabled } = usePayoutsFeature()
      return !isPayoutsFeatureEnabled ? (
        <Navigate replace to={errorsPathTo.accessDenied} />
      ) : (
        <PayoutDetailsPage />
      )
    },
  },
]
