import { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { Dispatch } from 'react'
import { ApiKeysTable } from 'integrations/components/ApiKeysTable'
import { ApiKeyPair, UpdateApiKeyPair } from 'integrations/services/apiKeyPairs'
import { CollectionResponse } from 'shared/services/client'

type APIKeysBrowsePageType = {
  title: string
  page: UseQueryResult<CollectionResponse<ApiKeyPair>>
  updateApiKey: UseMutationResult<ApiKeyPair, any, UpdateApiKeyPair, unknown>
  removeKey: UseMutationResult<void, any, string>
  filters: Filters
  onFilter: Dispatch<Filters>
}

export interface Filters {
  limit?: number
}

const APIKeysBrowsePage = ({
  page,
  updateApiKey,
  removeKey,
  onFilter,
  filters,
}: APIKeysBrowsePageType) => (
  <ApiKeysTable
    page={page}
    pagination={{ filters, onFilter }}
    removeKey={removeKey}
    updateApiKey={updateApiKey}
  />
)

export default APIKeysBrowsePage
