import { TransactionFlow } from 'transactions/services'

export const ThreeDSecureAction = ({
  action,
}: {
  action?: TransactionFlow
}) => {
  if (action?.outcome?.type == 'three-d-secure') {
    switch (action?.outcome?.result) {
      case 'attempt':
        return <>Attempted</>
      case 'force':
        return <>Forced</>
      case 'skip':
        return <>Skipped</>
      default:
        return <>N/A</>
    }
  }
  return <>-</>
}
