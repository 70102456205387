import { GridItem } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import { Operation } from 'flows/constants'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'

interface PaymentSourceInputProps {
  name: number
}

const ERROR_PAYMENT_SOURCE_REQUIRED = 'At least one payment source is required'
const ERROR_OPERATOR_REQUIRED = 'Operator is required'

const paymentSources = [
  {
    key: 'card_on_file',
    name: 'Card on File',
  },
  {
    key: 'ecommerce',
    name: 'E-commerce',
  },
  {
    key: 'installment',
    name: 'Installment',
  },
  {
    key: 'moto',
    name: 'MOTO',
  },
  {
    key: 'recurring',
    name: 'Recurring',
  },
].sort((a, b) => a.name.localeCompare(b.name))

const PaymentSourceInput = ({ name }: PaymentSourceInputProps) => (
  <>
    <GridItem gridColumn="span 2">
      <FormItem
        label={<Label>Operator</Label>}
        name={[name, 'operator']}
        rules={[{ required: true, message: ERROR_OPERATOR_REQUIRED }]}
      >
        <Select placeholder="Select an operator">
          <Select.Option value={Operation.IS_ONE_OF}>includes</Select.Option>
          <Select.Option value={Operation.IS_NOT_ONE_OF}>
            excludes
          </Select.Option>
        </Select>
      </FormItem>
    </GridItem>
    <GridItem gridColumn="span 6">
      <FormItem
        label={<Label>Value(s)</Label>}
        name={[name, 'value']}
        rules={[{ required: true, message: ERROR_PAYMENT_SOURCE_REQUIRED }]}
      >
        <Select
          mode="multiple"
          allowClear
          maxTagCount="responsive"
          maxTagPlaceholder={maxTagPlaceholderFormatter}
          placeholder="Select an option"
        >
          {paymentSources.map((paymentSource) => (
            <Select.Option
              value={paymentSource.key}
              label={paymentSource.name}
              key={paymentSource.key}
            >
              {paymentSource.name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    </GridItem>
  </>
)

export { ERROR_OPERATOR_REQUIRED, ERROR_PAYMENT_SOURCE_REQUIRED }
export default PaymentSourceInput
