import { useQuery } from '@tanstack/react-query'
import { Navigate, useParams } from 'react-router-dom'
import { getRefund } from 'refunds/services'
import { Loading } from 'shared/components/Loading'
import { REFUND } from 'shared/constants/query-keys/refunds'
import { pathTo as errorsPathTo } from 'shared/paths/errors'
import { pathTo } from 'shared/paths/transactions'

const RefundPage = () => {
  const { refundID, merchantAccountId } = useParams() as {
    refundID: string
    merchantAccountId: string
  }
  const { data, isLoading } = useQuery({
    queryKey: [REFUND, refundID, merchantAccountId],
    queryFn: () => getRefund(refundID),
    meta: {
      error: {
        redirect: true,
      },
    },
  })

  const to = data?.transactionId
    ? pathTo.transaction(merchantAccountId, data?.transactionId)
    : errorsPathTo.notFound

  if (isLoading || !data) {
    return <Loading />
  }

  return <Navigate replace to={to} />
}

export default RefundPage
