import { useParams } from 'react-router-dom'
import { Connection, connectionNames } from 'connections/constants'
import { pathTo } from 'shared/paths/connections'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'

export const useConnectionRoutes = () => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const hasCatalogPermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )

  return [
    {
      title: connectionNames[Connection.configured],
      url: pathTo.connectionsConfigured(merchantAccountId),
    },
    ...(hasCatalogPermission
      ? [
          {
            title: connectionNames[Connection.catalog],
            url: pathTo.connectionsCatalog(merchantAccountId),
          },
        ]
      : []),
  ]
}
