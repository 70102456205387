import { Badge, Heading, Stack, Text } from '@gr4vy/poutine-react'
import EventsConnection from 'transactions/components/EventsPanel/EventsConnection'
import { getColorByStatusCode } from 'transactions/helpers'
import { TransactionEvent } from 'transactions/services'

export const AntiFraudDecisionErrorEvent = ({
  event,
}: {
  event: TransactionEvent
}) => {
  return event ? (
    <Stack gap={32}>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Connection
        </Heading>
        <EventsConnection event={event} />
      </Stack>
      {event.context['statusCode'] && (
        <Stack gap={8} alignItems="flex-start">
          <Heading as="h5" level={5}>
            HTTP Status Code
          </Heading>
          <Badge
            variant="subtle"
            color={getColorByStatusCode(String(event.context['statusCode']))}
          >
            {event.context['statusCode']}
          </Badge>
        </Stack>
      )}
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Reason
        </Heading>
        <Text>{event.context['reason'] || '-'}</Text>
      </Stack>
    </Stack>
  ) : null
}
