import { Heading, Stack, Text } from '@gr4vy/poutine-react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import {
  PAYMENT_METHODS,
  TRANSACTION,
} from 'shared/constants/query-keys/transactions'
import currencyFormat from 'shared/helpers/currency-format'
import { dateFormatHeader } from 'shared/helpers/date-format'
import { getPaymentMethod, getTransaction } from 'transactions/services'
import View from './TransactionDetailsPage'

const TransactionDetailsPage = () => {
  const { transactionID, merchantAccountId } = useParams() as {
    transactionID: string
    merchantAccountId: string
  }
  const transaction = useQuery({
    queryKey: [TRANSACTION, transactionID, merchantAccountId],
    queryFn: () => getTransaction(transactionID),
    meta: {
      error: {
        redirect: true,
      },
    },
  })
  const paymentMethodId = transaction.data?.paymentMethod?.id
  const paymentMethod = useQuery({
    queryKey: [PAYMENT_METHODS, paymentMethodId, merchantAccountId],
    queryFn: () => getPaymentMethod(paymentMethodId as string),
    enabled: !!paymentMethodId,
    meta: {
      error: {
        ignore: [404],
      },
    },
  })

  const amount =
    currencyFormat(
      transaction.data?.amount || 0,
      { currency: transaction.data?.currency || 'USD', style: 'decimal' },
      'en'
    ) || 'Unknown'

  return (
    <View
      transaction={transaction.data}
      paymentMethod={paymentMethod.data}
      title={`${amount} ${transaction.data?.currency}`}
      titleChildren={
        <Heading level={3} as="h1">
          <Stack direction="row" gap={8}>
            <Text as="span">{amount}</Text>
            <Text as="span" color="gray80" fontWeight="semibold">
              {transaction.data?.currency}
            </Text>
          </Stack>
        </Heading>
      }
      titleSubChildren={
        <Text as="span" color="gray80" fontWeight="semibold">
          {dateFormatHeader(transaction.data?.createdAt)}
        </Text>
      }
    />
  )
}

export default TransactionDetailsPage
