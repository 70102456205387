import { Stack } from '@gr4vy/poutine-react'
import { UseQueryResult } from '@tanstack/react-query'
import { CollectionResponse } from 'shared/services/client'
import TransactionTable from 'transactions/components/TransactionTable'
import { TransactionsPageLayout } from 'transactions/components/TransactionsPageLayout'
import { PayoutFilters, PayoutSummary } from 'transactions/services/payouts'

interface PayoutsBrowsePageViewProps {
  title: string
  filters: Partial<PayoutFilters>
  page: UseQueryResult<CollectionResponse<PayoutSummary>>
  onFilter: (filter: PayoutFilters) => void
}

const PayoutsBrowsePageView = ({
  title,
  page,
  filters,
  onFilter,
}: PayoutsBrowsePageViewProps) => {
  return (
    <TransactionsPageLayout title={title}>
      <Stack gap={32}>
        <TransactionTable
          page={page}
          pagination={{
            filters,
            onFilter,
          }}
          disableColumnFilters
          resource="payouts"
        />
      </Stack>
    </TransactionsPageLayout>
  )
}

export default PayoutsBrowsePageView
