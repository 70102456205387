import { useContext } from 'react'
import MerchantContext from 'App/contexts/MerchantContext'
import { env } from 'shared/constants/env'
import { getItem, setItem } from 'shared/helpers/localstorage'
import {
  getAdminPathFromUrl,
  getMerchantAccountIdFromUrl,
} from 'shared/helpers/routes'
import { AccessLevel, Resource } from 'shared/permissions/constants'
import { useResourcePermission } from './use-resource-permission'

export const CACHE_KEY = 'gr4vy-merchant-account-id'

const isMultiMerchantEnabled = () => {
  // local storage value used by e2e to test both variants
  const featureFlag = localStorage.getItem('feature-multi-merchant')
  switch (featureFlag) {
    case 'disabled':
      return false
    case 'enabled':
      return true
    default:
      return env.REACT_APP_MULTI_MERCHANT === 'true'
  }
}

export const useMultiMerchant = () => {
  const { merchantAccounts } = useContext(MerchantContext)

  const canAccessMerchants = useResourcePermission(
    Resource.merchantAccounts,
    AccessLevel.write
  )

  const canAccessUsers = useResourcePermission(
    Resource.users,
    AccessLevel.write
  )

  const canAccessAdminMode = canAccessMerchants || canAccessUsers
  const canAccessMultiMerchant = isMultiMerchantEnabled()
  const hasMultipleMerchantAccounts = merchantAccounts.length > 1

  const getMerchantAccountId = () => {
    const merchantAccountIdFromUrl = getMerchantAccountIdFromUrl()
    const cachedMerchantAccountId = getItem(CACHE_KEY, null)
    const merchantAccountIds = merchantAccounts
      .sort((a, b) =>
        a.displayName.localeCompare(b.displayName, undefined, {
          sensitivity: 'base',
        })
      )
      .map((account) => account.id)

    let selectedMerchantAccountId = null

    if (merchantAccountIdFromUrl) {
      selectedMerchantAccountId = merchantAccountIdFromUrl
    } else if (
      cachedMerchantAccountId &&
      merchantAccountIds.includes(cachedMerchantAccountId)
    ) {
      selectedMerchantAccountId = cachedMerchantAccountId
    } else {
      selectedMerchantAccountId = merchantAccountIds[0]
    }

    if (selectedMerchantAccountId) {
      setItem(CACHE_KEY, selectedMerchantAccountId)
    }
    return selectedMerchantAccountId
  }

  return {
    isAdminMode: !!getAdminPathFromUrl(),
    merchantAccountId: getMerchantAccountId(),
    canAccessAdminMode,
    canAccessMultiMerchant,
    canAccessMerchants,
    hasMultipleMerchantAccounts,
  }
}
