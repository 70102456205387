import client from 'shared/services/client'

export type RefundStatus =
  | 'processing'
  | 'succeeded'
  | 'failed'
  | 'declined'
  | 'voided'

export interface Refund {
  type: 'refund'
  id: string
  transactionId: string
  paymentServiceRefundId: string
  status: RefundStatus
  currency: string
  amount: number
  createdAt: string
  updatedAt: string
  targetType: 'payment-method' | 'gift-card-redemption'
  targetId?: string
  reason: string | null
}

export const getRefund = async (id: string): Promise<Refund> => {
  const response = await client.get(`/refunds/${id}`)
  return response.data
}
