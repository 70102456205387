import { Flex, Icon } from '@gr4vy/poutine-react'
import styles from './SessionOverlay.module.scss'

export const SessionOverlay = () => (
  <Flex
    data-testid="session-overlay"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    position="fixed"
    left={0}
    right={0}
    top={0}
    bottom={0}
    textAlign="center"
    style={{
      zIndex: 100,
    }}
  >
    <Icon
      name="loader-spinner"
      width={48}
      height={48}
      color="blue100"
      className={styles.spinner}
    />
  </Flex>
)
