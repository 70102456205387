import { Icon, Tooltip } from '@gr4vy/poutine-react'

interface Props {
  eci: string
}

const getECIHint = (eci: string) => {
  if (['02', '05'].includes(eci)) {
    return 'Authentication successful. Liability is shifted if a CAVV value is provided.'
  }
  if (['01', '06'].includes(eci)) {
    return 'Authentication attempted but not completed. Either the card or or the issuing bank does not support 3DS, or the cardholder ran out of time to authorize.'
  }
  if (['00', '07'].includes(eci)) {
    return 'Authentication failed or could not be attempted. Issuer is unable to authenticate or the issuer did not respond.'
  }

  return ''
}

export const ECIInfo = ({ eci }: Props) => {
  const hint = getECIHint(eci)

  return hint ? (
    <Tooltip content={hint}>
      <Icon name="info" size="small" />
    </Tooltip>
  ) : null
}
