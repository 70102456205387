import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Skeleton,
  Stack,
  Text,
} from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { useNavigate } from 'react-router-dom'
import { FormItem } from 'shared/components/FormItem'
import { pathTo } from 'shared/paths/users'
import NewUserPageLayout from 'users/components/NewUserPageLayout'
import { useLoggedInUser } from 'users/hooks/use-logged-in-user'
import { UpdateUser, User } from 'users/services/users'

interface UserEditNameProps {
  title: string
  user?: User
  loading: boolean
  update: UseMutationResult<User, any, UpdateUser, unknown>
}

const HeaderLoader = (
  <Skeleton width={240}>
    <Skeleton.Box height={24} />
  </Skeleton>
)

const InputLoader = (
  <Grid>
    <GridItem gridColumn="span 8">
      <Skeleton gap={4}>
        <Skeleton.Box style={{ maxWidth: 40 }} height={20} />
        <Skeleton.Box height={40} />
      </Skeleton>
    </GridItem>
  </Grid>
)

export default function UserEditName({
  title,
  user,
  loading,
  update,
}: UserEditNameProps) {
  const navigate = useNavigate()
  const [form] = Form.useForm<{ name: string }>()
  const { isLoggedInUser } = useLoggedInUser()

  const preparePayload = (user: User, name: string) => {
    const id = isLoggedInUser(user) ? 'me' : (user.id as string)

    return {
      id,
      name,
      ...(id !== 'me' && { roleIds: user.roles.map(({ id }) => id) }),
      ...(id !== 'me' &&
        user.roles.find(({ name }) => name === 'Administrator') && {
          merchantAccountIds: [],
        }),
    }
  }

  const onSubmit = ({ name }: { name: string }) => {
    if (!user) {
      return
    }

    const payload = preparePayload(user, name)

    update.mutate(payload, {
      onSuccess: () => navigate(pathTo.editUser.updatedName(payload.id)),
    })
  }

  return (
    <NewUserPageLayout title={title}>
      <Stack gap={4}>
        {loading ? (
          HeaderLoader
        ) : (
          <Heading as="h4">
            Profile name for {`“${user?.emailAddress}”`}
          </Heading>
        )}

        <Text variant="reg3" color="gray100">
          Update the name of the user below.
        </Text>
      </Stack>
      {loading ? (
        InputLoader
      ) : (
        <Form
          id="updateUserName"
          form={form}
          onFinish={onSubmit}
          initialValues={{ name: user?.name }}
          preserve={false}
          layout="vertical"
          requiredMark={false}
        >
          <Grid>
            <GridItem gridColumn="span 8">
              <Stack gap={32}>
                <FormItem
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: 'Enter user’s name' }]}
                >
                  <Input autoComplete="off" autoFocus />
                </FormItem>
              </Stack>
            </GridItem>
          </Grid>
        </Form>
      )}
      <Flex gap={16}>
        <Button form="updateUserName" loading={update.isPending}>
          Update name
        </Button>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </Flex>
    </NewUserPageLayout>
  )
}
