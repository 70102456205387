import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { HomeFilters } from 'home/constants/filter'
import {
  generateDateRange,
  generateSeries,
  transformSeriesData,
} from 'home/helpers/data'
import {
  getHealthDashboardTotalVolume,
  Period,
} from 'home/services/health-dashboard'
import { HEALTH_DASHBOARD_TOTAL_VOLUME } from 'shared/constants'

export const useTotalVolume = (filters: Partial<HomeFilters>) => {
  const { merchantAccountId } = useParams<{
    merchantAccountId: string
  }>()

  const {
    data: totalVolume,
    isLoading: isLoadingTotalVolume,
    isError: isErrorTotalVolume,
  } = useQuery({
    queryKey: [
      HEALTH_DASHBOARD_TOTAL_VOLUME,
      merchantAccountId,
      filters.period,
      filters.currency,
    ],
    queryFn: () =>
      getHealthDashboardTotalVolume({
        period: filters.period as Period,
        currency: filters.currency as Uppercase<string>,
      }),
    select: transformSeriesData,
    meta: {
      error: {
        ignore: [404],
      },
    },
    enabled:
      !!filters.period &&
      !!filters.currency &&
      filters.filterBy !== 'transactions',
  })

  const dateRange = useMemo(
    () => generateDateRange(totalVolume?.series),
    [totalVolume?.series]
  )

  const series = useMemo(
    () =>
      generateSeries({
        series: totalVolume?.series,
        period: filters.period as Period,
      }),
    [totalVolume?.series, filters.period]
  )

  return {
    totalVolume,
    isLoadingTotalVolume,
    isErrorTotalVolume,
    volumeDateRange: dateRange,
    volumeSeries: series,
  }
}
