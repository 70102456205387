import { Stack, Grid, GridItem, TextLine } from '@gr4vy/poutine-react'
import { Row } from '@tanstack/react-table'
import { Dispatch } from 'react'
import { useSearchParams } from 'react-router-dom'
import BuyersTable from 'buyers/components/BuyersTable'
import { buyersColumns } from 'buyers/components/BuyersTable/BuyersTable'
import { Buyer } from 'buyers/services/buyers'
import DebouncedSearchBar from 'shared/components/DebouncedSearchBar'
import { PageLayout } from 'shared/components/PageLayout'
import { Filters } from 'shared/hooks'
import {
  AccessLevel,
  Resource,
  useResourcePermissions,
} from 'shared/permissions'
import { QueryResult } from 'shared/services/client'

export interface BuyersBrowseFilters extends Filters {
  search?: string
}

interface BuyersBrowsePageProps {
  title: string
  page: QueryResult<Buyer>
  filters: Partial<BuyersBrowseFilters>
  onFilter: Dispatch<BuyersBrowseFilters>
}

const BuyersBrowsePage = ({
  title,
  page,
  onFilter,
  filters,
}: BuyersBrowsePageProps) => {
  const [searchParams] = useSearchParams()
  const [buyersRead, buyersBillingDetailsRead] = useResourcePermissions(
    [Resource.buyers, Resource.buyersBillingDetails],
    AccessLevel.read
  )

  const columns =
    buyersRead && buyersBillingDetailsRead
      ? [
          {
            header: 'Display Name',
            accessorKey: 'displayName',
            size: 180,
            cell: ({ row }: { row: Row<Buyer> }) => {
              return (
                <TextLine display="block">
                  {row.original?.displayName ?? '-'}
                </TextLine>
              )
            },
          },
          ...buyersColumns.filter(
            (column) => !['Name', 'Email'].includes(column.header as string)
          ),
        ]
      : undefined

  return (
    <PageLayout title={title}>
      <Stack gap={32}>
        <Grid gap={16}>
          <GridItem gridColumn={['span 12', 'span 12', 'span 3', 'span 3']}>
            <DebouncedSearchBar
              placeholder="Name or merchant ID"
              defaultValue={searchParams.get('search') || undefined}
              onChange={(value) => onFilter({ search: value })}
              size="small"
            />
          </GridItem>
        </Grid>
        <BuyersTable
          page={page}
          columns={columns}
          pagination={{ filters, onFilter }}
        />
      </Stack>
    </PageLayout>
  )
}

export default BuyersBrowsePage
