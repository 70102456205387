import { Anchor as AnchorAntd } from 'antd'
import { AnchorLinkItemProps } from 'antd/es/anchor/Anchor'
import { useRef } from 'react'
import { isSandbox } from 'shared/helpers/is-sandbox'
import styles from './Anchor.module.scss'

const BANNER_HEIGHT = 6
const TITLE_BAR_HEIGHT = 120

const stepToHash = (step = '') => `#${step}`
const hashToStep = (hash = '') => hash.replace('#', '')

const Anchor = ({
  items,
  defaultAnchor = '',
  offsetTop = 24,
  onChange,
}: {
  items: AnchorLinkItemProps[]
  defaultAnchor?: string
  offsetTop?: number
  onChange?: (anchor: string) => void
}) => {
  const anchor = useRef(defaultAnchor)
  const onChangeAnchor = (link: string) => {
    const nextLink = link || defaultAnchor
    anchor.current = nextLink
    onChange && onChange(nextLink)
  }

  return (
    <AnchorAntd
      offsetTop={TITLE_BAR_HEIGHT + (isSandbox ? BANNER_HEIGHT : 0) + offsetTop}
      onClick={(e, link) => onChangeAnchor(link.href)}
      onChange={(link) => onChangeAnchor(link)}
      getCurrentAnchor={() => anchor.current}
      className={styles.anchor}
      items={items}
    />
  )
}

export { Anchor, stepToHash, hashToStep }
