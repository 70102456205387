import { ConnectionSelect } from 'connections/components/ConnectionSelect'
import { filter } from 'connections/components/Filters/select-filter'
import { useCurrencies } from 'connections/hooks/use-currencies'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'

export const CurrencyInput = ({
  supportedCurrencies,
  disabled,
}: {
  supportedCurrencies?: string[]
  disabled?: boolean
}) => {
  const mapppedCurrencies = useCurrencies(supportedCurrencies)

  return (
    <FormItem
      label={<Label>Accepted currencies</Label>}
      name="acceptedCurrencies"
      rules={[
        {
          required: true,
          message: 'Please select at least one currency',
        },
      ]}
    >
      <ConnectionSelect
        mode="multiple"
        allowClear
        placeholder="Select or type currency name"
        filterOption={filter}
        maxTagCount="responsive"
        maxTagPlaceholder={maxTagPlaceholderFormatter}
        showSearch
        options={mapppedCurrencies}
        disabled={disabled}
      />
    </FormItem>
  )
}
