import { Description } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { useAntiFraudServiceDefinition } from 'connections/hooks/use-anti-fraud-service-definition'
import { useGiftCardServiceDefinition } from 'connections/hooks/use-gift-card-service-definition'
import { usePaymentServiceDefinition } from 'connections/hooks/use-payment-service-definition'
import { usePaymentMethodDefinition } from 'shared/hooks/use-payment-method-definition'
import { pathTo } from 'shared/paths/connections'
import { AccessLevel, Resource } from 'shared/permissions/constants'
import { useResourcePermissions } from 'shared/permissions/hooks'
import { TransactionEvent } from 'transactions/services'

interface ServiceProps {
  iconUrl?: string
  url?: string
  name: string
  label?: string
}

export const Service = ({ iconUrl, url, name, label }: ServiceProps) => {
  return (
    <Description>
      {!!iconUrl && <Description.Icon src={iconUrl} />}
      {url ? (
        <Description.Link href={url}>{name}</Description.Link>
      ) : (
        <Description.Text>{name}</Description.Text>
      )}
      {!!label && <Description.SubText>{label}</Description.SubText>}
    </Description>
  )
}
interface EventsConnectionProps {
  event: TransactionEvent
}

const EventsConnection = ({ event }: EventsConnectionProps) => {
  const [canAccessAFDs, canAccessGCDs, canAccessPSDs] = useResourcePermissions(
    [
      Resource.antiFraudServiceDefinitions,
      Resource.giftCardServices,
      Resource.paymentServiceDefinitions,
    ],
    AccessLevel.read
  )
  const hasPermission = canAccessAFDs && canAccessGCDs && canAccessPSDs

  if (event.context.paymentServiceDefinitionId) {
    return hasPermission ? (
      <PaymentService event={event} />
    ) : (
      <Service name={event.context.paymentServiceDisplayName} />
    )
  } else if (event.context.antiFraudServiceDefinitionId) {
    return hasPermission ? (
      <AntiFraudService event={event} />
    ) : (
      <Service name={event.context.antiFraudServiceName} />
    )
  } else if (event.context.giftCardServiceDefinitionId) {
    return hasPermission ? (
      <GiftCardService event={event} />
    ) : (
      <Service name={event.context.giftCardServiceName} />
    )
  } else if (event.name.startsWith('digital-wallet-google-pay')) {
    return hasPermission ? (
      <DigitalWalletService method="googlepay" />
    ) : (
      <Service name="Google Pay" />
    )
  } else if (event.name.startsWith('digital-wallet-apple-pay')) {
    return hasPermission ? (
      <DigitalWalletService method="applepay" />
    ) : (
      <Service name="Apple Pay" />
    )
  } else if (event.name.startsWith('digital-wallet-click-to-pay')) {
    return hasPermission ? (
      <DigitalWalletService method="click-to-pay" />
    ) : (
      <Service name="Click to Pay" />
    )
  } else if (event.name.startsWith('three-d-secure')) {
    return <Service iconUrl={'/img/logos/shield.svg'} name="3-D Secure" />
  } else if (event.name.startsWith('the-giving-block')) {
    return (
      <Service
        iconUrl={'/img/logos/the-giving-block.svg'}
        name="The Giving Block"
      />
    )
  } else if (event.name.startsWith('bin-')) {
    return <Service iconUrl={'/img/logos/bin.svg'} name="BIN insights" />
  } else if (event.provider?.startsWith('giesecke-devrient')) {
    return <Service iconUrl={'/img/logos/g-d.svg'} name="Giesecke+Devrient" />
  } else if (event.name.startsWith('network-token-')) {
    return (
      <Service iconUrl={'/img/logos/network-token.svg'} name="Network token" />
    )
  } else {
    return <>-</>
  }
}

const DigitalWalletService = ({ method }: { method: string }) => {
  const definition = usePaymentMethodDefinition(method)
  const name = definition?.displayName || '-'
  return <Service iconUrl={definition?.iconUrl} name={name} />
}

const AntiFraudService = ({ event }: EventsConnectionProps) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const { antiFraudServiceDefinition } = useAntiFraudServiceDefinition(
    merchantAccountId,
    event.context.antiFraudServiceDefinitionId
  )
  const url = pathTo.editConnectionAntiFraudService(
    merchantAccountId,
    event.context.antiFraudServiceDefinitionId,
    event.context.antiFraudServiceId
  )
  return (
    <Service
      iconUrl={antiFraudServiceDefinition?.iconUrl}
      url={url}
      name={event.context.antiFraudServiceName}
      label={antiFraudServiceDefinition?.displayName}
    />
  )
}

const GiftCardService = ({ event }: EventsConnectionProps) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const { giftCardServiceDefinition } = useGiftCardServiceDefinition(
    merchantAccountId,
    event.context.giftCardServiceDefinitionId
  )
  const url = pathTo.editConnectionGiftCardService(
    merchantAccountId,
    event.context.giftCardServiceId
  )
  return (
    <Service
      iconUrl={giftCardServiceDefinition?.iconUrl}
      url={url}
      name={event.context.giftCardServiceName}
      label={giftCardServiceDefinition?.displayName}
    />
  )
}

const PaymentService = ({ event }: EventsConnectionProps) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const { paymentServiceDefinition } = usePaymentServiceDefinition(
    merchantAccountId,
    event.context.paymentServiceDefinitionId
  )
  const url = pathTo.editConnectionPaymentService(
    merchantAccountId,
    event.context.paymentServiceId
  )
  return (
    <Service
      iconUrl={paymentServiceDefinition?.iconUrl}
      url={url}
      name={event.context.paymentServiceDisplayName}
      label={paymentServiceDefinition?.displayName}
    />
  )
}

export default EventsConnection
