import { Heading } from '@gr4vy/poutine-react'
import { Panel } from 'shared/components/Panel'
import { Summary } from 'shared/components/Summary'
import { useTransactionEvents } from 'transactions/hooks/use-transaction-events'
import { Transaction } from 'transactions/services'

export const ConnectionResponsePanel = ({
  transaction,
}: {
  transaction: Transaction
}) => {
  const { lastRawResponse } = useTransactionEvents(transaction)

  return (
    <Panel>
      <Panel.Header>
        <Heading level={4}>Response</Heading>
      </Panel.Header>
      <Panel.Content>
        <Summary inline>
          <Summary.Key>Connector reference</Summary.Key>
          <Summary.Value>
            {transaction.paymentServiceTransactionId}
          </Summary.Value>
          <Summary.Key>Connector response</Summary.Key>
          <Summary.Value fallback={!lastRawResponse}>
            {lastRawResponse}
          </Summary.Value>
        </Summary>
      </Panel.Content>
    </Panel>
  )
}
