import { Text, TextLink } from '@gr4vy/poutine-react'
import styles from './ControlLink.module.scss'

export const ControlLink = ({
  label,
  onMoveAll,
  disabled,
  from,
  to,
}: {
  label: string
  onMoveAll: any
  disabled: boolean
  from: 'visibleOutcomes' | 'hiddenOutcomes'
  to: 'visibleOutcomes' | 'hiddenOutcomes'
}) => {
  if (disabled) {
    return (
      <Text as="div" className={styles.disabledControlLink}>
        {label}
      </Text>
    )
  }
  return (
    <TextLink
      href="#"
      onClick={(e) => {
        e.preventDefault()
        onMoveAll({ from, to })
      }}
      className={styles.controlLink}
    >
      <Text as="div">{label}</Text>
    </TextLink>
  )
}
