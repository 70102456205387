import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import {
  GiftCardServiceDefinition,
  getGiftCardDefinition,
} from 'connections/services'
import { GIFT_CARD_SERVICE_DEFINITIONS } from 'shared/constants'

export const useGiftCardServiceDefinition = (
  merchantAccountId?: string,
  id?: string,
  options?: Pick<UseQueryOptions<GiftCardServiceDefinition>, 'enabled'>
) => {
  const { data: giftCardServiceDefinition, isLoading } = useQuery({
    queryKey: [GIFT_CARD_SERVICE_DEFINITIONS, id, merchantAccountId],
    queryFn: () => getGiftCardDefinition(id as string),
    refetchOnWindowFocus: false,
    enabled: !!id && !!merchantAccountId,
    ...options,
  })

  return { isLoading, giftCardServiceDefinition }
}
