import { KeyboardEvent } from 'react'
import { NavigationKeys } from 'shared/constants'

export const useKeyboardListNavigation = () => {
  const onKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    const activeElement = event.target as HTMLElement
    const nextOption = activeElement?.nextElementSibling as HTMLLabelElement
    const previousOption =
      activeElement?.previousElementSibling as HTMLLabelElement

    if ([event.key, event.code].includes(NavigationKeys.ARROW_DOWN_KEY)) {
      event.preventDefault()

      if (nextOption) {
        nextOption?.focus()
      } else {
        ;(
          activeElement?.parentElement?.firstElementChild as HTMLLabelElement
        ).focus()
      }
    }

    if ([event.key, event.code].includes(NavigationKeys.ARROW_UP_KEY)) {
      event.preventDefault()

      if (previousOption) {
        previousOption?.focus()
      } else {
        ;(
          activeElement?.parentElement?.lastElementChild as HTMLLabelElement
        ).focus()
      }
    }

    if (
      [event.key, event.code].includes(NavigationKeys.SPACE_KEY) ||
      [event.key, event.code].includes(NavigationKeys.ENTER_KEY)
    ) {
      event.preventDefault()
      ;(event.target as HTMLLabelElement)?.click()
      ;(event.target as HTMLLabelElement)?.focus()
    }
  }

  return { onKeyDown }
}
