import { Heading } from '@gr4vy/poutine-react'
import { Panel } from 'shared/components/Panel'
import { Summary } from 'shared/components/Summary'
import { Transaction } from 'transactions/services'

function RecurringPanel({ transaction }: { transaction: Transaction }) {
  const getValue = (value?: string | boolean | null) => {
    if (typeof value === 'boolean') {
      return value ? 'true' : 'false'
    }
    return value
  }

  return (
    <Panel>
      <Panel.Header>
        <Heading level={4}>Recurring</Heading>
      </Panel.Header>
      <Panel.Content>
        <Summary inline>
          <Summary.Key>Payment source</Summary.Key>
          <Summary.Value>{transaction.paymentSource}</Summary.Value>
          <Summary.Key>Merchant initiated</Summary.Key>
          <Summary.Value>
            {getValue(transaction.merchantInitiated)}
          </Summary.Value>
          <Summary.Key>Subsequent transaction</Summary.Key>
          <Summary.Value>
            {getValue(transaction.isSubsequentPayment)}
          </Summary.Value>
        </Summary>
      </Panel.Content>
    </Panel>
  )
}

export default RecurringPanel
