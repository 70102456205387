import { Heading, Stack, Text } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import React from 'react'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'

interface Props {
  visible: boolean
}

export const StepThreeDSecure = ({ visible }: Props) => {
  const statuses = [
    { value: 'setup_error', label: 'setup_error' },
    { value: 'error', label: 'error' },
    { value: 'declined', label: 'declined' },
    { value: 'cancelled', label: 'cancelled' },
    { value: 'complete', label: 'complete' },
  ]
  const ecis = [
    { value: '00', label: '00' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
  ]
  const authResponses = [
    { value: 'Y', label: 'Y' },
    { value: 'N', label: 'N' },
  ]

  return (
    <Stack gap={24} display={visible ? 'flex' : 'none'}>
      <Stack gap={8}>
        <Heading level={5}>3D secure filters</Heading>
        <Text>
          Use the following multi selections to specify the report 3D secure
          parameters.
        </Text>
      </Stack>
      <FormItem label={<Label>Status</Label>} name="threeDSecureStatus">
        <Select
          listItemHeight={32}
          placeholder="All statuses"
          style={{ width: '100%' }}
          dropdownStyle={{ pointerEvents: 'auto' }}
          mode="multiple"
          maxTagCount="responsive"
          allowClear
        >
          {statuses.map(({ value, label }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem label={<Label>ECI</Label>} name="threeDSecureEci">
        <Select
          listItemHeight={32}
          placeholder="All ECIs"
          style={{ width: '100%' }}
          dropdownStyle={{ pointerEvents: 'auto' }}
          mode="multiple"
          maxTagCount="responsive"
          allowClear
        >
          {ecis.map(({ value, label }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        label={<Label>Authentication response</Label>}
        name="threeDSecureAuthResp"
      >
        <Select
          listItemHeight={32}
          placeholder="All authentication responses"
          style={{ width: '100%' }}
          dropdownStyle={{ pointerEvents: 'auto' }}
          mode="multiple"
          maxTagCount="responsive"
          allowClear
        >
          {authResponses.map(({ value, label }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    </Stack>
  )
}
