import { Grid, GridItem, Stack, Text } from '@gr4vy/poutine-react'
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from '@hello-pangea/dnd'
import { Form, FormInstance, Input } from 'antd'
import clsx from 'clsx'
import React, { useContext } from 'react'
import {
  DragArea,
  DragProvided,
  DragSnapshot,
} from 'flows/components/DragAndDrop'
import { OutcomeMessage } from 'flows/components/OutcomeAction/OutcomeAction'
import { OutcomeActionV2 } from 'flows/components/OutcomeAction/OutcomeActionV2'
import {
  OutcomeFormValues,
  OutcomeResult,
  OutcomeTransformationName,
  RuleOutcomeConfig,
  ruleOutcomeConfig,
  TransactionActionOutcome,
} from 'flows/constants'
import { FlowContext } from 'flows/contexts/FlowContext'
import {
  formatOutcomes,
  removeConnectionByIndex,
  reorderConnections,
} from 'flows/utils/cardOutcome'
import { componentConfigSelector } from 'flows/utils/componentConfigSelector'
import { FormItem } from 'shared/components/FormItem'
import { Action } from 'shared/constants'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import styles from './OutcomeList.module.scss'

export type RuleOutcomesSectionProps = {
  id: string
  action: Action
  connections: TransactionActionOutcome[]
  form: FormInstance
  children?: React.ReactNode
  onRemoveConnection: (index: number) => void
  onEditConnection: (
    connection: TransactionActionOutcome,
    connectionIndex: number
  ) => void
}

const RuleOutcomesSection = ({
  id,
  action,
  connections,
  form,
  children,
  onRemoveConnection,
  onEditConnection,
}: RuleOutcomesSectionProps) => (
  <Droppable droppableId={id}>
    {(
      dropProvided: DroppableProvided,
      dropSnapshot: DroppableStateSnapshot
    ) => {
      const classNames = clsx({
        [styles.draggingOver]: dropSnapshot.isDraggingOver,
      })
      return (
        <div
          ref={dropProvided.innerRef}
          {...dropProvided.droppableProps}
          data-dropping={dropSnapshot.isDraggingOver}
        >
          <Stack direction="column" gap="none" className={classNames}>
            {children}
            {connections.map((connection, index) => (
              <DragArea
                id={`${connection.id}:${index}`}
                key={`${connection.id}:${index}`}
                index={index}
              >
                {(dragProvided: DragProvided, dragSnapshot: DragSnapshot) => (
                  <FormItem shouldUpdate noStyle>
                    {() => (
                      <>
                        <OutcomeActionV2
                          key={`${connection.id}:${index}`}
                          action={action}
                          connection={connection}
                          dragProvided={dragProvided}
                          dragSnapshot={dragSnapshot}
                          form={form}
                          onRemoveConnection={() => onRemoveConnection(index)}
                          onEditConnection={() =>
                            onEditConnection(connection, index)
                          }
                        />
                        {connections.length > 10 && index === 9 && (
                          <Text variant="reg3" marginY={24} alignSelf="center">
                            If any of the connections above fail the following
                            connections will be used.
                          </Text>
                        )}
                      </>
                    )}
                  </FormItem>
                )}
              </DragArea>
            ))}
            {dropProvided.placeholder}
          </Stack>
        </div>
      )
    }}
  </Droppable>
)

export const OutcomeListV2 = ({
  form,
  drawerForm,
  outcomes,
  onEdit,
}: {
  form: FormInstance
  drawerForm: FormInstance<OutcomeFormValues>
  outcomes: TransactionActionOutcome[]
  onEdit: () => void
}) => {
  const { flow, action } = useContext(FlowContext)
  const formOutcomeResult = Form.useWatch<OutcomeResult[]>([
    'outcome',
    'result',
  ])
  const hasWriteAccess = useResourcePermission(
    Resource.flows,
    AccessLevel.write
  )

  const config = componentConfigSelector<RuleOutcomeConfig>(
    flow,
    action,
    ruleOutcomeConfig
  )

  const onDragEnd = (event: DropResult) => {
    const reorderedConnections = reorderConnections(event, formOutcomeResult)

    form.setFieldsValue({
      outcome: {
        result: reorderedConnections,
      },
    })

    return reorderedConnections
  }

  const onRemoveConnection = (index: number) => {
    const updatedConnections = removeConnectionByIndex(index, formOutcomeResult)

    form.setFieldsValue({
      outcome: {
        result: updatedConnections,
      },
    })
  }

  const onEditConnection = (
    connection: TransactionActionOutcome,
    index: number
  ) => {
    drawerForm.setFieldsValue({
      paymentServiceIndex: index,
      paymentServiceId: connection.id,
      instrument: connection.instruments[0],
      transformationsForceMit: !!connection.transformations.find(
        (transformation) =>
          transformation.name === OutcomeTransformationName.FORCE_MIT
      ),
      transformationsUseAdditionalScheme: !!connection.transformations.find(
        (transformation) =>
          transformation.name ===
          OutcomeTransformationName.USE_ADDITIONAL_SCHEME
      ),
    })
    onEdit()
  }

  const connectionsInUse = formatOutcomes(outcomes, formOutcomeResult)

  return (
    <Grid>
      <GridItem gridColumn="span 6">
        <FormItem name={['outcome', 'type']} hidden>
          <Input />
        </FormItem>
        <DragDropContext onDragEnd={onDragEnd}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            paddingTop={16}
          >
            <Text variant="overline1" as="div">
              {config?.selectedLabelV2}
            </Text>
          </Stack>
          <RuleOutcomesSection
            id="outcomesV2"
            connections={connectionsInUse}
            form={form}
            action={action}
            onRemoveConnection={onRemoveConnection}
            onEditConnection={onEditConnection}
          >
            {hasWriteAccess && !connectionsInUse.length && (
              <OutcomeMessage isError>
                {config?.selectedPlaceholderV2}
              </OutcomeMessage>
            )}
          </RuleOutcomesSection>
          <FormItem
            className={styles.hiddenInput}
            name={['outcome', 'result']}
            hidden
          >
            <Input />
          </FormItem>
          <FormItem
            className={styles.hiddenInput}
            name={['outcome', 'version']}
            hidden
          >
            <Input value={2} />
          </FormItem>
        </DragDropContext>
      </GridItem>
    </Grid>
  )
}
