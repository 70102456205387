import { isValidElement, Children, cloneElement } from 'react'
import styles from './Breadcrumb.module.scss'

export const Breadcrumb = ({ children }: any) => {
  const items = Children.toArray(children)
  const isLastChild = (i: number) => items.length === i + 1
  return (
    <nav aria-label="breadcrumb">
      <ol className={styles.breadcrumb}>
        {items.map(
          (child, i) =>
            isValidElement(child) &&
            cloneElement(child, {
              ...(isLastChild(i) ? { ['aria-current']: 'page' } : {}),
            })
        )}
      </ol>
    </nav>
  )
}

export const BreadcrumbItem = ({ children, ...rest }: any) => (
  <li className={styles.breadcrumbItem} {...rest}>
    {children}
  </li>
)
