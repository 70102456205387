import { AuditLogsBrowsePage } from 'audit-logs/pages'
import { pathTo } from 'shared/paths/activity-logs'
import { Resource } from 'shared/permissions/constants'

export default [
  {
    title: 'User Activity',
    longTitle: 'User Activity',
    key: 'users/activity',
    path: pathTo.auditLogs,
    menu: false,
    component: AuditLogsBrowsePage,
    resource: Resource.auditLogs,
  },
]
