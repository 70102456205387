import { Frequency } from '../services/report'

const getRangeByFrequency = (frequency: Frequency) => {
  switch (frequency) {
    case 'daily': {
      return { from: 'day_start', to: 'day_end' }
    }
    case 'weekly': {
      return { from: 'week_start', to: 'week_end' }
    }
    case 'monthly': {
      return { from: 'month_start', to: 'month_end' }
    }
  }
  return { from: undefined, to: undefined }
}

export { getRangeByFrequency }
