import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from '@hello-pangea/dnd'
import { ReactNode } from 'react'

export const DragDropArea = ({
  onDragEnd,
  id,
  children,
}: {
  onDragEnd: any
  id: string
  children: ReactNode
}) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId={id}>
      {(
        dropProvided: DroppableProvided,
        dropSnapshot: DroppableStateSnapshot
      ) => (
        <div
          ref={dropProvided.innerRef}
          {...dropProvided.droppableProps}
          data-dropping={dropSnapshot.isDraggingOver}
        >
          {children}
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
)
