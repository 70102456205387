import { isObject, isArray, snakeCase, camelCase } from 'lodash'

type Obj = Record<string, unknown>
type Options = {
  // ignore one key
  ignore?: string[]
  // ignore all keys recursively under that key
  ignoreDeep?: string[]
}

function transformKeys(
  obj: any,
  fn: (key: string) => string,
  options: Options = {}
): any {
  if (isArray(obj)) {
    return obj.map((item) => transformKeys(item, fn, options))
  }
  if (isObject(obj)) {
    obj = obj as Obj
    return Object.keys(obj).reduce((acc: Obj, key) => {
      const transformedKey =
        options.ignore?.includes(key) || options.ignoreDeep?.includes(key)
          ? key
          : fn(key)
      acc[transformedKey] = options.ignoreDeep?.includes(key)
        ? obj[key]
        : transformKeys(obj[key] as Obj, fn, options)
      return acc
    }, {})
  }
  return obj
}

const snakeCaseKeys = (data: unknown, options?: Options) =>
  transformKeys(data, snakeCase, options)
const camelCaseKeys = (data: unknown, options?: Options) =>
  transformKeys(data, camelCase, options)

export { snakeCaseKeys, camelCaseKeys }
