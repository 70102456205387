import {
  Button,
  Flex,
  Icon,
  Drawer,
  Stack,
  Tag,
  TagProps,
  Tooltip,
  Heading,
  Description,
  ButtonCopy,
} from '@gr4vy/poutine-react'
import { useState } from 'react'
import { Prism } from 'react-syntax-highlighter'
import { oneLight as theme } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { ApiLog } from 'integrations/services/apiLogs'
import { ColumnDef, DataTable, TimeDate } from 'shared/components/DataTable'
import { Summary } from 'shared/components/Summary'
import { dateFormat } from 'shared/helpers/date-format'
import { getCurrentLocale } from 'shared/helpers/locale'
import { QueryResult } from 'shared/services/client'
import { formatCode } from 'transactions/helpers'
import styles from './ApiLogTable.module.scss'

export type ApiLogTableProps = {
  page: QueryResult<ApiLog>
}

const METHOD_COLORS: Record<string, TagProps['color']> = {
  GET: 'positive',
  POST: 'information',
  PATCH: 'notice',
  PUT: 'notice',
  DELETE: 'negative',
}

export const ApiLogTable = ({ page }: ApiLogTableProps) => {
  const [selected, setSelected] = useState<ApiLog | null>(null)
  const [open, setOpen] = useState<boolean>(false)

  const { data, isFetching: loading } = page

  const onClick = (apiLog: ApiLog) => {
    setSelected(apiLog)
    setOpen(true)
  }

  const columns: Array<ColumnDef<ApiLog>> = [
    {
      header: 'Endpoint',
      accessorKey: 'requestMethod',
      size: 400,
      cell: ({ row }) => {
        return (
          <Stack gap={16} direction="row" alignItems="center">
            <Tag
              variant="subtle"
              color={METHOD_COLORS[row.original.requestMethod] ?? 'neutral'}
            >
              {row.original.requestMethod}
            </Tag>
            <Description.Text fontFamily="mono">
              {row.original.requestUrl.split('gr4vy.app')[1]}
            </Description.Text>
          </Stack>
        )
      },
    },
    {
      header: 'Status',
      accessorKey: 'responseStatusCode',
      size: 50,
      cell: ({ getValue }) => {
        return (
          <Tag variant="subtle" color="notice">
            {getValue<ApiLog['responseStatusCode']>()}
          </Tag>
        )
      },
    },
    {
      header: 'Received',
      accessorKey: 'responseSentAt',
      size: 50,
      cell: function DatetimeCell({ getValue }) {
        const createdAt = getValue<ApiLog['responseSentAt']>()
        return <TimeDate value={createdAt} />
      },
    },
    {
      header: '',
      id: 'actions',
      size: 50,
      cell: ({ row }) => {
        const apiLog = row.original as ApiLog
        return (
          <Flex justifyContent="flex-end" alignItems="center">
            <Tooltip content="Inspect the response">
              <Button
                variant="tertiary"
                size="small"
                onClick={() => onClick(apiLog)}
              >
                <Icon name="arrow-right-md" />
              </Button>
            </Tooltip>
          </Flex>
        )
      },
    },
  ]

  return (
    <>
      <Drawer
        open={open}
        title="API Log"
        size="md"
        onClose={() => setOpen(false)}
      >
        {selected && (
          <Stack gap={32}>
            <Stack gap={8}>
              <Heading as="h2" level={5}>
                Endpoint
              </Heading>
              <Stack gap={8} direction="row" alignItems="center">
                <Tag
                  variant="subtle"
                  color={METHOD_COLORS[selected.requestMethod ?? 'GET']}
                >
                  {selected.requestMethod}
                </Tag>
                <Prism language="curl" style={theme}>
                  {(selected.requestUrl ?? '').split('gr4vy.app')[1]}
                </Prism>
              </Stack>
            </Stack>
            {selected.requestAuth?.header && (
              <Stack gap={8}>
                <Heading as="h2" level={5}>
                  Authentication
                </Heading>
                <Summary className={styles.summary} inline>
                  <Summary.Key>JWT Algorithm</Summary.Key>
                  <Summary.Value>
                    {selected.requestAuth.header.alg}
                  </Summary.Value>
                  <Summary.Key>API Key ID</Summary.Key>
                  <Summary.Value>
                    {selected.requestAuth.header.kid}
                  </Summary.Value>
                  <Summary.Key>Scopes</Summary.Key>
                  <Summary.Value>
                    {selected.requestAuth.payload?.scopes?.join?.(', ')}
                  </Summary.Value>
                  <Summary.Key>JWT issued</Summary.Key>
                  <Summary.Value fallback={!selected.requestAuth.payload?.iat}>
                    {selected.requestAuth.payload?.iat &&
                      dateFormat(
                        new Date(
                          selected.requestAuth.payload.iat * 1000
                        ).toString(),
                        getCurrentLocale(),
                        {
                          weekday: 'short',
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          timeZoneName: 'short',
                        }
                      )}
                  </Summary.Value>
                  <Summary.Key>JWT not valid before</Summary.Key>
                  <Summary.Value fallback={!selected.requestAuth.payload?.nbf}>
                    {selected.requestAuth.payload?.nbf &&
                      dateFormat(
                        new Date(
                          selected.requestAuth.payload.nbf * 1000
                        ).toString(),
                        getCurrentLocale(),
                        {
                          weekday: 'short',
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          timeZoneName: 'short',
                        }
                      )}
                  </Summary.Value>
                  <Summary.Key>JWT expires</Summary.Key>
                  <Summary.Value fallback={!selected.requestAuth.payload?.exp}>
                    {selected.requestAuth.payload?.exp &&
                      dateFormat(
                        new Date(
                          selected.requestAuth.payload.exp * 1000
                        ).toString(),
                        getCurrentLocale(),
                        {
                          weekday: 'short',
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          timeZoneName: 'short',
                        }
                      )}
                  </Summary.Value>
                  <Summary.Key>Request received</Summary.Key>
                  <Summary.Value>
                    {dateFormat(selected.responseSentAt, getCurrentLocale(), {
                      weekday: 'short',
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      timeZoneName: 'short',
                    })}
                  </Summary.Value>
                </Summary>
              </Stack>
            )}
            {selected.requestBody && (
              <Stack gap={8}>
                <Flex alignItems="flex-end" justifyContent="space-between">
                  <Heading as="h2" level={5}>
                    API request
                  </Heading>
                  <ButtonCopy
                    type="button"
                    variant="secondary"
                    copy={formatCode(selected.requestBody)}
                    size="small"
                  >
                    Copy
                  </ButtonCopy>
                </Flex>

                <Prism language="json" style={theme}>
                  {formatCode(selected.requestBody)}
                </Prism>
              </Stack>
            )}
            {selected.responseBody && (
              <Stack gap={8}>
                <Flex alignItems="flex-end" justifyContent="space-between">
                  <Heading as="h2" level={5}>
                    API response
                  </Heading>
                  <ButtonCopy
                    type="button"
                    variant="secondary"
                    copy={formatCode(selected.responseBody ?? '')}
                    size="small"
                  >
                    Copy
                  </ButtonCopy>
                </Flex>

                <Prism language="json" style={theme}>
                  {formatCode(selected.responseBody ?? '')}
                </Prism>
              </Stack>
            )}
          </Stack>
        )}
      </Drawer>

      <DataTable data={data} loading={loading} columns={columns} />
    </>
  )
}
