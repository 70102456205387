import { Condition, RuleCondition, RuleConfigCondition } from 'flows/constants'
import { HIDDEN_CONDITIONS } from 'flows/utils/form'

export const getInitValues = (
  condition: Condition,
  availableConditions: RuleConfigCondition[]
): RuleCondition => {
  const conditionConfig = availableConditions.find(
    ({ name }) => name === condition
  ) as RuleConfigCondition
  return {
    name: conditionConfig.name,
    operator: conditionConfig.operator,
  }
}

export const getOptions = (
  currentConditionName: Condition | null,
  formConditions: any[],
  availableConditions: RuleConfigCondition[]
) =>
  availableConditions.filter(({ name: condition, unique, hidden }) => {
    // the unique condition can be presented on rule page only once
    if (HIDDEN_CONDITIONS.includes(condition) && hidden) {
      return false
    }
    if (unique) {
      return (
        !formConditions
          .map((formCondition: any) => formCondition.name)
          .includes(condition) || currentConditionName === condition
      )
    }

    return true
  })
