import { Flex, Text } from '@gr4vy/poutine-react'
import { ArrowDownIcon } from './ArrowDownIcon'
import { ArrowUpIcon } from './ArrowUpIcon'
import styles from './PercentageBadge.module.scss'

export type PercentageBadgeProps =
  | {
      percentage: number
      variant: 'positive' | 'negative'
      direction: 'up' | 'down'
    }
  | {
      percentage: number
      variant: 'neutral'
    }

export const PercentageBadge = ({
  percentage,
  variant,
  ...rest
}: PercentageBadgeProps) => {
  const sign = percentage > 0 ? '+' : percentage < -500 ? '-' : ''
  const label = Math.abs(percentage) > 500 ? 'over 500' : percentage
  const direction = 'direction' in rest ? rest.direction : undefined

  return (
    <Flex
      className={styles.badge}
      alignItems="center"
      gap={4}
      data-variant={variant}
    >
      <Text as="span" variant="med4">{`${sign}${label}%`}</Text>
      {!!direction &&
        (direction === 'up' ? <ArrowUpIcon /> : <ArrowDownIcon />)}
    </Flex>
  )
}
