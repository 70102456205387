import { jwtDecode } from 'jwt-decode'
import { useMemo } from 'react'
import { AccessLevel, Resource, Token } from 'shared/permissions/constants'

const emptyScopes = {
  [AccessLevel.read]: [],
  [AccessLevel.write]: [],
} as Record<AccessLevel, Resource[]>

export const useScopes = () => {
  const accessToken = localStorage.getItem('access_token')

  return useMemo(() => {
    if (!accessToken) {
      return emptyScopes
    }

    const { scopes = [] } = jwtDecode<Token>(accessToken)

    return scopes.reduce((scopes, scope) => {
      const parts = scope.split('.')

      const level = (
        parts.length > 1 ? parts.pop() : AccessLevel.read
      ) as AccessLevel

      const resource = parts.join('.') as Resource

      const currentAccessLevel = Object.keys(scopes).includes(level)
        ? scopes[level]
        : []

      return {
        ...scopes,
        [level]: [resource, ...currentAccessLevel],
      }
    }, emptyScopes)
  }, [accessToken])
}
