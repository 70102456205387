import { Button, Flex, Icon } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import { ClientFilter } from 'shared/hooks'

interface ClientPaginationProps {
  filter: ClientFilter
  setLimit: (limit: number) => void
  previousPage: () => void
  nextPage: () => void
  totalPages: number
  limits?: Array<number>
}

export const ClientPagination = ({
  filter,
  setLimit,
  previousPage,
  nextPage,
  totalPages,
  limits = [5, 20, 50, 100],
}: ClientPaginationProps) => (
  <Flex gap={8}>
    <Select value={filter.limit} onChange={(v) => setLimit(v)}>
      {limits.map((value) => (
        <Select.Option key={value} value={value}>
          {value} / page
        </Select.Option>
      ))}
    </Select>
    {filter.page > 0 && (
      <Button variant="secondary" onClick={() => previousPage()}>
        <Icon name="chevron-left-lg" />
        Previous
      </Button>
    )}
    {filter.page < totalPages && (
      <Button variant="secondary" onClick={() => nextPage()}>
        Next
        <Icon name="chevron-right-lg" />
      </Button>
    )}
  </Flex>
)
