import { useQuery } from '@tanstack/react-query'
import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { getBuyer } from 'buyers/services/buyers'
import { GET_BUYER } from 'shared/constants/query-keys/transactions'
import BuyerPageLayout from './BuyerPageLayout'

interface BuyerPageProps {
  children: ReactNode
}
export default function BuyerPage({ children }: BuyerPageProps) {
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }

  const { data: buyer } = useQuery({
    queryKey: [GET_BUYER, id, merchantAccountId],
    queryFn: () => getBuyer(id),
    refetchOnWindowFocus: false,
  })

  return <BuyerPageLayout buyer={buyer}>{children}</BuyerPageLayout>
}
