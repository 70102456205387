import { AccessLevel, Resource } from 'shared/permissions/constants'
import { useScopes } from './use-scopes'

const explicitResources = [Resource.apiKeyPairs, Resource.users]

export const useResourcePermission = (
  resource: Resource,
  accessLevel: AccessLevel
) => {
  const userScopes = useScopes()

  return (
    userScopes[accessLevel].includes(resource) ||
    (!explicitResources.includes(resource) &&
      userScopes[accessLevel].includes(Resource.all))
  )
}
