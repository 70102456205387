import { Navigate, useParams } from 'react-router-dom'
import { GiftCardTabs } from 'shared/constants'
import { GIFT_CARD_PATH, pathTo } from 'shared/paths/gift-cards'
import { GiftCardOverviewPage, GiftCardTransactionsPage } from './pages'

export default [
  {
    title: 'Gift Card Overview',
    longTitle: 'Gift Card Overview',
    key: 'gift-card-overview',
    path: `${GIFT_CARD_PATH}/${GiftCardTabs.overview}`,
    menu: false,
    exact: true,
    component: GiftCardOverviewPage,
  },
  {
    title: 'Gift Card Transactions',
    longTitle: 'Gift Card Transactions',
    key: 'gift-cardtransactions',
    path: `${GIFT_CARD_PATH}/${GiftCardTabs.transactions}`,
    menu: false,
    exact: true,
    component: GiftCardTransactionsPage,
  },
  {
    title: 'Gift Card Overview',
    longTitle: 'Gift Card',
    key: 'gift-card',
    path: GIFT_CARD_PATH,
    menu: false,
    component: function Default() {
      const { giftCardId, merchantAccountId } = useParams() as {
        giftCardId: string
        merchantAccountId: string
      }

      return (
        <Navigate
          replace
          to={pathTo.giftCardId(
            merchantAccountId,
            giftCardId,
            GiftCardTabs.overview
          )}
        />
      )
    },
  },
]
