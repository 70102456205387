import { useState } from 'react'
import { reportTemplates } from 'reports/constants'

export const useReportTemplates = () => {
  const [template, setTemplate] = useState<{
    id: string
    isCustom: boolean
    schedule: boolean
  } | null>(null)
  const isSettlementTemplate = template?.id === 'settlement'

  return {
    template,
    setTemplate,
    isSettlementTemplate,
    reportTemplates,
  }
}
