import { Stack, Heading, Flex } from '@gr4vy/poutine-react'
import { capitalize } from 'lodash'
import { Panel } from 'shared/components/Panel'
import { Summary } from 'shared/components/Summary'
import { ThreeDSecure } from 'transactions/services'
import { DirectoryResponseInfo } from './DirectoryResponseInfo'
import { ECIInfo } from './ECIInfo'

function ThreeDSecurePanel({
  threeDSecure,
}: {
  threeDSecure?: ThreeDSecure | null
}) {
  const isV1 = (threeDSecure?.version || '').startsWith('1')
  const isV2 = (threeDSecure?.version || '').startsWith('2')

  return threeDSecure?.responseData ? (
    <Panel>
      <Panel.Header>
        <Heading level={4}>3-D Secure</Heading>
      </Panel.Header>
      <Panel.Content>
        <Stack gap={56}>
          <Summary inline>
            <Summary.Key>Version</Summary.Key>
            <Summary.Value>{threeDSecure?.version}</Summary.Value>
            <Summary.Key>Method</Summary.Key>
            <Summary.Value>{capitalize(threeDSecure?.method)}</Summary.Value>

            <Summary.Key>Status</Summary.Key>
            <Summary.Value>
              <Flex gap={4}>
                {threeDSecure.responseData.authenticationResponse ??
                  threeDSecure.responseData.directoryResponse}
                <DirectoryResponseInfo
                  code={
                    threeDSecure.responseData.authenticationResponse ??
                    threeDSecure.responseData.directoryResponse
                  }
                  version={threeDSecure.version.charAt(0)}
                />
              </Flex>
            </Summary.Value>
            <Summary.Key>ECI</Summary.Key>
            <Summary.Value>
              <Flex gap={4}>
                {threeDSecure.responseData.eci}
                <ECIInfo eci={threeDSecure.responseData.eci} />
              </Flex>
            </Summary.Value>
            <Summary.Key>CAVV</Summary.Key>
            <Summary.Value>{threeDSecure.responseData.cavv}</Summary.Value>
            {!!threeDSecure.responseData?.transactionReason && (
              <>
                <Summary.Key>Reason</Summary.Key>
                <Summary.Value>
                  {threeDSecure.responseData.transactionReason}
                </Summary.Value>
              </>
            )}
            {isV1 && (
              <>
                <Summary.Key>CAVV algorithm</Summary.Key>
                <Summary.Value>
                  {threeDSecure.responseData.cavvAlgorithm}
                </Summary.Value>
              </>
            )}
            {isV2 && (
              <>
                <Summary.Key>Transaction ID</Summary.Key>
                <Summary.Value>
                  {threeDSecure.responseData.directoryTransactionId}
                </Summary.Value>
              </>
            )}

            {isV1 && (
              <>
                <Summary.Key>XID</Summary.Key>
                <Summary.Value>{threeDSecure.responseData.xid}</Summary.Value>
              </>
            )}
          </Summary>
        </Stack>
      </Panel.Content>
    </Panel>
  ) : (
    <></>
  )
}

export default ThreeDSecurePanel
