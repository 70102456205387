import { pathTo as admin } from 'admin/paths'
import { pathTo as buyers } from 'shared/paths/buyers'
import { pathTo as connections } from 'shared/paths/connections'
import { pathTo as flow } from 'shared/paths/flows'
import { pathTo as integrations } from 'shared/paths/integrations'
import { pathTo as refunds } from 'shared/paths/refunds'
import { pathTo as transactions } from 'shared/paths/transactions'
import { USERS_ACTIVITY_LOG_PATH } from 'shared/paths/users'

export const pathTo = {
  auditLogs: USERS_ACTIVITY_LOG_PATH,
  resource: (merchantAccountId: string, type: string, id: string) => {
    switch (type) {
      case 'payment-service':
        return connections.editConnectionPaymentService(merchantAccountId, id)
      case 'transaction':
        return transactions.transaction(merchantAccountId, id)
      case 'api-key-pair':
        return integrations.apiKeys(merchantAccountId)
      case 'buyer':
        return buyers.buyer(merchantAccountId, id)
      case 'refund':
        return refunds.refund(merchantAccountId, id)
      case 'rule':
        return flow.flows(merchantAccountId)
      case 'merchant-account':
        return admin.merchant(id)
      default:
        return undefined
    }
  },
}
