import { TextLink } from '@gr4vy/poutine-react'

export const NoSearchResults = ({
  clearSearch,
}: {
  clearSearch: (value: string) => void
}) => (
  <>
    <p>
      <TextLink
        href="#"
        onClick={(e) => {
          e.preventDefault()
          clearSearch('')
        }}
      >
        View
      </TextLink>{' '}
      the full catalog list or try a another search term.
    </p>
    <p>
      If you&rsquo;re looking for a connection Gr4vy doesn&rsquo;t have at the
      moment, you can request it by{' '}
      <a href="mailto:info@gr4vy.com">contacting</a> our Sales team or using the
      Support widget at the bottom of the page.
    </p>
  </>
)
