import { Stack, Heading, Text } from '@gr4vy/poutine-react'

export const Section = ({ title, children }: any) => (
  <Stack gap={8} direction="column" width="full" borderRadius="rounded">
    <Heading>{title}</Heading>
    <Text isReadable margin="none" color="gray100">
      {children}
    </Text>
  </Stack>
)
