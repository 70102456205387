import { Heading, Stack, Text } from '@gr4vy/poutine-react'
import React from 'react'
import { Uploader } from 'shared/components/Uploader'

interface Props {
  error?: string
  uploading: boolean
  onChange: (files: FileList | null) => void
}

export const StepUploadCertificate = ({
  error,
  uploading,
  onChange,
}: Props) => {
  return (
    <Stack gap={24}>
      <Heading level={5}>Upload Apple Pay certificate</Heading>
      <Text>
        Upload the certificate you generated on the Apple developer dashboard.
      </Text>
      <Uploader
        uploading={uploading}
        uploadingText="Uploading certificate..."
        onChange={onChange}
      >
        Drop here the certificate or click to browse your files.
      </Uploader>
      {error && <Text color="red90">{error}</Text>}
    </Stack>
  )
}
