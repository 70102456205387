import { Button, Icon, Stack, Text } from '@gr4vy/poutine-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { AddAppleDomainDrawer } from 'connections/components/AddAppleDomainDrawer'
import DomainsTable from 'connections/components/DomainsTable'
import {
  ApplePayDomain,
  listApplePayDomains,
  removeApplePayDomain,
} from 'connections/services/applePayDomains'
import { ModalRemove } from 'shared/components/Modal/ModalRemove'
import { DIGITAL_WALLETS } from 'shared/constants'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { handleSuccessResponse } from 'shared/utils/handleSuccessResponse'
import { showErrorMessage } from 'shared/utils/showMessage'
import { ApplePayLayout } from './ApplePayLayout'

export const ApplePayDomains = ({ title }: { title: string }) => {
  const [addingDomain, setAddingDomain] = useState<ApplePayDomain | boolean>(
    false
  )
  const [removingDomain, setRemovingDomain] = useState<ApplePayDomain | null>(
    null
  )
  const queryClient = useQueryClient()
  const { id } = useParams() as { id: string }

  const hasWritePermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )

  const page = useQuery({
    queryKey: [DIGITAL_WALLETS],
    queryFn: () => listApplePayDomains(id),
    meta: {
      error: {
        redirect: true,
      },
    },
  })

  const { mutate: remove, isPending: removing } = useMutation({
    mutationFn: (domainName: string) => removeApplePayDomain(id, domainName),
    onSuccess: handleSuccessResponse(
      `Domain ${removingDomain?.displayName} successfully removed.`,
      () => {
        queryClient.invalidateQueries({ queryKey: [DIGITAL_WALLETS] })
        setRemovingDomain(null)
      }
    ),
    onError: () => {
      showErrorMessage('Unable to remove the certificate. Try again.')
    },
  })

  return (
    <ApplePayLayout
      title={title}
      actions={
        <Button
          disabled={!hasWritePermission}
          variant="primary"
          onClick={() => setAddingDomain(true)}
        >
          <Icon name="add-plus" />
          Add domain
        </Button>
      }
    >
      <Stack gap={32}>
        <DomainsTable
          page={page}
          onRemove={setRemovingDomain}
          hasWritePermission={hasWritePermission}
        />
      </Stack>
      <AddAppleDomainDrawer
        open={!!addingDomain}
        onClose={() => setAddingDomain(false)}
        resourceId={id}
        domains={page.data?.items}
      />
      {removingDomain && (
        <ModalRemove
          title="Remove domain"
          okText="Yes, remove domain"
          loading={removing}
          loadingText="Removing..."
          onCancel={() => setRemovingDomain(null)}
          onOk={() => {
            remove(removingDomain.displayName)
          }}
        >
          <Stack gap={16}>
            <Text>
              You are about to remove the domain{' '}
              <strong>{removingDomain.displayName}</strong> from this
              connection. This domain can be re-added again at a later time.
            </Text>
            <Text>This domain will no longer be able to accept Apple Pay.</Text>
            <Text>Are you sure you want to proceed?</Text>
            <Text>
              <strong>Warning:</strong> This action can not be undone.
            </Text>
          </Stack>
        </ModalRemove>
      )}
    </ApplePayLayout>
  )
}
