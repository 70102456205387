import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { LIST_REFUNDS } from 'shared/constants'
import {
  GiftCardRedemption,
  PaymentMethod,
  Refund,
  Transaction,
  listRefunds,
} from 'transactions/services'

export type RefundData = Refund & {
  paymentMethod?: PaymentMethod | GiftCardRedemption
}

export const useTransactionRefundsData = (transaction: Transaction) => {
  const refunds = useQuery({
    queryKey: [LIST_REFUNDS, transaction.id],
    queryFn: () => listRefunds(transaction.id, { limit: 100 }),
    enabled: !!transaction.id,
  })

  const tableData = useMemo(
    () =>
      refunds.data?.items.reduce<Array<RefundData>>((acc, refund) => {
        const paymentMethodItems = [
          ...(transaction.paymentMethod ? [transaction.paymentMethod] : []),
          ...(transaction.giftCardRedemptions || []),
        ]
        const paymentMethod = paymentMethodItems.find(
          (paymentMethod) => paymentMethod.id === refund.targetId
        )
        acc.push({
          ...refund,
          ...(paymentMethod ? { paymentMethod } : {}),
        })
        return acc
      }, []),
    [transaction, refunds.data]
  )

  if (refunds.isLoading || !refunds.isSuccess || !tableData) {
    return {
      tableData: [],
    }
  }

  return {
    tableData,
  }
}
