import {
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  Checkbox,
} from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AddMerchantDrawer } from 'admin/components/AddMerchantDrawer'
import { pathTo } from 'shared/paths/users'
import { MerchantAccount } from 'shared/services/merchant-accounts'
import NewUserPageLayout from 'users/components/NewUserPageLayout/NewUserPageLayout'
import { errorMessage, STEPS } from 'users/constants'
import { useEditRoles } from 'users/hooks/use-edit-roles'
import { UpdateUser, User } from 'users/services/users'

interface UserMerchantAccountsProps {
  title: string
  merchantAccounts: MerchantAccount[]
  create: UseMutationResult<
    MerchantAccount,
    any,
    Pick<MerchantAccount, 'displayName' | 'id'>,
    unknown
  >
  user?: User
  update: UseMutationResult<User, any, UpdateUser, unknown>
}

export default function UserMerchantAccounts({
  title,
  merchantAccounts,
  create,
  user,
  update,
}: UserMerchantAccountsProps) {
  const navigate = useNavigate()
  const [openAddMerchantDrawer, setOpenAddMerchantDrawer] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [merchantAccountIds, setMerchantAccountIds] = useState<string[]>([])
  const { roleIds, permissionRoleIds } = useEditRoles(user)

  useEffect(() => {
    if (user?.merchantAccounts) {
      setMerchantAccountIds(
        user?.merchantAccounts.map((merchantAccount) => merchantAccount.id)
      )
    }
  }, [user])

  const handleNextStep = () => {
    if (!merchantAccountIds.length) {
      setShowErrorMessage(true)
      return
    }

    navigate(pathTo.addUser.roles(merchantAccountIds))
  }

  const handleToggleMerchantAccount = (merchantAccount: MerchantAccount) => {
    setShowErrorMessage(false)
    const isExistentMerchantAccountId = merchantAccountIds.find(
      (merchantAccountId) => merchantAccountId === merchantAccount.id
    )

    if (isExistentMerchantAccountId) {
      setMerchantAccountIds((currMerchantAccountIds) =>
        currMerchantAccountIds.filter(
          (merchantAccountId) => merchantAccountId !== merchantAccount.id
        )
      )
      return
    }

    setMerchantAccountIds((currMerchantAccountIds) => [
      ...currMerchantAccountIds,
      merchantAccount.id,
    ])
  }

  const onOpenAddMerchantAccountDrawer = () => {
    setShowErrorMessage(false)
    setOpenAddMerchantDrawer(true)
  }

  const handleUpdate = () => {
    if (!user?.id) {
      return
    }

    if (!merchantAccountIds.length) {
      setShowErrorMessage(true)
      return
    }

    update.mutate(
      {
        id: user.id,
        name: user.name,
        merchantAccountIds,
        roleIds: [...roleIds, ...permissionRoleIds],
      },
      {
        onSuccess: () =>
          navigate(pathTo.editUser.updatedMerchantAccount(user.id)),
      }
    )
  }

  return (
    <>
      <NewUserPageLayout title={title} step={STEPS.MERCHANT_ACCOUNT}>
        <Stack gap={4}>
          <Heading as="h4">
            Which merchant accounts will this user be associated with?
          </Heading>
          <Text variant="reg3" color="gray100">
            Each merchant account selected from the list below will allow this
            user to access the account information in the portal. A user can
            have access to many merchant accounts.
          </Text>
        </Stack>

        <Stack gap={24}>
          <Stack gap={16}>
            {merchantAccounts
              .sort((a, b) => a.displayName.localeCompare(b.displayName))
              .map((merchantAccount) => (
                <Checkbox
                  key={merchantAccount.id}
                  id={merchantAccount.id}
                  onCheckedChange={() =>
                    handleToggleMerchantAccount(merchantAccount)
                  }
                  checked={merchantAccountIds.includes(merchantAccount.id)}
                >
                  {merchantAccount.displayName}
                </Checkbox>
              ))}
          </Stack>
          <Stack gap={40}>
            <Button
              variant="secondary"
              onClick={onOpenAddMerchantAccountDrawer}
            >
              <Icon name="add-plus" size="small" />
              Add merchant
            </Button>
            <Stack gap={16}>
              {showErrorMessage && (
                <Text variant="reg4" color="red90">
                  {errorMessage.MERCHANT_ACCOUNTS}
                </Text>
              )}
              <Flex gap={16}>
                {user ? (
                  <>
                    <Button onClick={handleUpdate} loading={update.isPending}>
                      Update merchants
                    </Button>
                    <Button variant="secondary" onClick={() => navigate(-1)}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Button variant="secondary" onClick={() => navigate(-1)}>
                      <Icon name="arrow-left-md" />
                      Back
                    </Button>
                    <Button onClick={handleNextStep}>Continue</Button>
                  </>
                )}
              </Flex>
            </Stack>
          </Stack>
        </Stack>
      </NewUserPageLayout>
      <AddMerchantDrawer
        open={openAddMerchantDrawer}
        onClose={() => setOpenAddMerchantDrawer(false)}
        create={create}
      />
    </>
  )
}
