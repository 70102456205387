import { Heading, Stack, Text } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { LayoutProps, PageLayout } from 'shared/components/PageLayout'
import currencyFormat from 'shared/helpers/currency-format'
import { dateFormatHeader } from 'shared/helpers/date-format'
import { pathTo } from 'shared/paths/transactions'
import { PayoutSummary, Transaction } from 'transactions/services'

const getTabs = (
  merchantAccountId: string,
  transactionId: string,
  checkoutSessionId?: string
) => {
  const tabs = [
    {
      title: 'Overview',
      url: pathTo.transaction(merchantAccountId, transactionId),
    },
  ]

  if (checkoutSessionId) {
    tabs.push({
      title: 'Related transactions',
      url: pathTo.relatedTransactions(merchantAccountId, transactionId),
    })
  }
  return tabs
}

export const TransactionPageLayout = ({
  children,
  transaction,
  ...rest
}: LayoutProps & {
  transaction?: Transaction | PayoutSummary
}) => {
  const { merchantAccountId } = useParams() as {
    merchantAccountId: string
  }

  const amount =
    currencyFormat(
      transaction?.amount || 0,
      { currency: transaction?.currency || 'USD', style: 'decimal' },
      'en'
    ) || 'Unknown'

  const isPayout = transaction?.type === 'payout'
  const resource = isPayout ? 'payouts' : 'transactions'

  return transaction ? (
    <PageLayout
      title={`${amount} ${transaction?.currency}`}
      titleChildren={
        <Heading level={3} as="h1">
          <Stack direction="row" gap={8}>
            <Text as="span">{amount}</Text>
            <Text as="span" color="gray80" fontWeight="semibold">
              {transaction?.currency}
            </Text>
          </Stack>
        </Heading>
      }
      titleSubChildren={
        <Text as="span" color="gray80" fontWeight="semibold">
          {dateFormatHeader(transaction?.createdAt)}
        </Text>
      }
      breadcrumbs={[
        {
          title: isPayout ? 'Payouts' : 'Payins',
          url: pathTo[resource](merchantAccountId),
        },
        {
          title: 'Details',
        },
      ]}
      noContainer
      gap="none"
      subNavigation={
        isPayout
          ? []
          : getTabs(
              merchantAccountId,
              transaction.id,
              transaction.checkoutSessionId
            )
      }
      {...rest}
    >
      {children}
    </PageLayout>
  ) : null
}
