import { Grid, GridItem, Stack, Divider } from '@gr4vy/poutine-react'
import { Form, FormInstance } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import { Fragment } from 'react'
import { AmountOrCurrencyInput } from 'flows/components/conditions/AmountOrCurrencyInput'
import {
  ConditionRow,
  AddConditionRow,
} from 'flows/components/conditions/ConditionRow'
import { RuleConfigCondition } from 'flows/constants'
import { AccessLevel, Resource, RestrictAccess } from 'shared/permissions'
import { Section } from './Section'

interface ConditionSectionProps {
  form: FormInstance
  conditions: RuleConfigCondition[]
}

export const ConditionSection = ({
  form,
  conditions,
}: ConditionSectionProps) => (
  <Stack direction="column" gap={24}>
    <Section title="Conditions">
      The conditions added to a rule allow you to control when it is triggered.
      The more conditions you add, the more targeted the rule becomes.
    </Section>
    <FormItem hidden name={['hiddenConditions']}>
      <input hidden />
    </FormItem>
    <AmountOrCurrencyInput form={form} />
    <Form.List name="conditions">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name }) => (
            <Fragment key={key}>
              <FormItem shouldUpdate>
                {() => (
                  <ConditionRow
                    form={form}
                    name={name}
                    conditions={conditions}
                    remove={() => remove(name)}
                  />
                )}
              </FormItem>
              <Divider />
            </Fragment>
          ))}
          <RestrictAccess
            resource={Resource.flows}
            accessLevel={AccessLevel.write}
          >
            <Grid>
              <GridItem gridColumn="span 3">
                <AddConditionRow
                  form={form}
                  conditions={conditions}
                  add={add}
                />
              </GridItem>
            </Grid>
            <Divider />
          </RestrictAccess>
        </>
      )}
    </Form.List>
  </Stack>
)
