import { GridItem } from '@gr4vy/poutine-react'
import { Input, Select } from 'antd'
import { Operation } from 'flows/constants'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'

interface MetadataInputProps {
  name: number
}

const ERROR_OPERATOR_REQUIRED = 'Operator is required'
const ERROR_KEY_REQUIRED = 'Key is required'
const ERROR_VALUE_REQUIRED = 'Value is required'

const MetadataInput = ({ name }: MetadataInputProps) => (
  <>
    <GridItem gridColumn="span 2">
      <FormItem
        label={<Label>Key</Label>}
        name={[name, 'value', 'key']}
        rules={[{ required: true, message: ERROR_KEY_REQUIRED }]}
      >
        <Input />
      </FormItem>
    </GridItem>
    <GridItem gridColumn="span 2">
      <FormItem
        label={<Label>Operator</Label>}
        name={[name, 'operator']}
        rules={[{ required: true, message: ERROR_OPERATOR_REQUIRED }]}
      >
        <Select placeholder="Select an operator">
          <Select.Option value={Operation.EXCLUDES}>
            includes none
          </Select.Option>
          <Select.Option value={Operation.INCLUDES}>includes any</Select.Option>
        </Select>
      </FormItem>
    </GridItem>
    <GridItem gridColumn="span 4">
      <FormItem
        label={<Label>Value</Label>}
        name={[name, 'value', 'value']}
        rules={[{ required: true, message: ERROR_VALUE_REQUIRED }]}
      >
        <Select
          mode="tags"
          allowClear
          maxTagCount="responsive"
          maxTagPlaceholder={maxTagPlaceholderFormatter}
        />
      </FormItem>
    </GridItem>
  </>
)

export { ERROR_OPERATOR_REQUIRED, ERROR_KEY_REQUIRED, ERROR_VALUE_REQUIRED }
export default MetadataInput
