import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { EditForm } from 'connections/pages/AntiFraudServiceEditPage'
import { pathTo } from 'shared/paths/connections'

interface Props {
  children: ReactNode
  submitButtonText: string
}

export const Cybersource = ({ children, submitButtonText }: Props) => {
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }

  return (
    <EditForm
      subNavigation={[
        {
          title: 'Overview',
          url: `${pathTo.editConnectionAntiFraudCybersourceService(
            merchantAccountId,
            id
          )}/overview`,
        },
        {
          title: 'Credentials',
          url: `${pathTo.editConnectionAntiFraudCybersourceService(
            merchantAccountId,
            id
          )}/credentials`,
        },
      ]}
      submitButtonText={submitButtonText}
    >
      {children}
    </EditForm>
  )
}
