import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useAntiFraudServiceDefinition } from 'connections/hooks/use-anti-fraud-service-definition'
import { useAntiFraudServices } from 'connections/hooks/use-anti-fraud-services'
import { Loading } from 'shared/components/Loading'
import { pathTo } from 'shared/paths/connections'
import { Cybersource } from './Cybersource'
import { Forter } from './Forter'
import { Kount } from './Kount'
import { Sift } from './Sift'
import { Simulator } from './Simulator'

const AntiFraudServiceAddPage = () => {
  const { definitionId, merchantAccountId } = useParams() as {
    definitionId: string
    merchantAccountId: string
  }
  const navigate = useNavigate()
  const { create } = useAntiFraudServices()
  const { antiFraudServiceDefinition, isLoading } =
    useAntiFraudServiceDefinition(merchantAccountId, definitionId)

  const handleSubmit = async (values: any) => {
    create.mutate(values, {
      onSuccess: (data) => {
        navigate(
          pathTo.editConnectionAntiFraudService(
            merchantAccountId,
            definitionId,
            data.id
          )
        )
      },
    })
  }
  const handleCancel = () => {
    navigate(pathTo.connectionsCatalog(merchantAccountId), { replace: true })
  }

  if (isLoading || !antiFraudServiceDefinition) {
    return <Loading />
  }

  switch (definitionId) {
    case 'sift-anti-fraud':
      return (
        <Sift
          definition={antiFraudServiceDefinition}
          saving={create.isPending}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )
    case 'cybersource-anti-fraud':
      return (
        <Cybersource
          definition={antiFraudServiceDefinition}
          saving={create.isPending}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )
    case 'forter-anti-fraud':
      return (
        <Forter
          definition={antiFraudServiceDefinition}
          saving={create.isPending}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )
    case 'kount-anti-fraud':
      return (
        <Kount
          definition={antiFraudServiceDefinition}
          saving={create.isPending}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )
    case 'mock-anti-fraud':
      return (
        <Simulator
          definition={antiFraudServiceDefinition}
          saving={create.isPending}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )
    default:
      return <Navigate to={pathTo.connectionsConfigured(merchantAccountId)} />
  }
}

export { AntiFraudServiceAddPage }
