import { useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import { SSOError } from 'session/components/SSOError'
import { SessionOverlay } from 'session/components/SessionOverlay'
import { SessionPageLayout } from 'session/components/SessionPageLayout'

const Auth0User = ({}) => {
  const {
    isAuthenticated,
    isLoading,
    user,
    getAccessTokenSilently,
    error: Auth0Error,
  } = useAuth0()
  const navigate = useNavigate()
  const location = useLocation()
  const [error, setError] = useState<string>()

  useEffect(() => {
    async function getToken() {
      try {
        const token = await getAccessTokenSilently()
        // reload the app with the token and the returnTo url
        window.location.href = `/sign-in?token=${token}&provider=auth0&returnTo=${location.state?.returnTo}`
      } catch (error: any) {
        Sentry.captureException(error)
        setError(error?.message)
      }
    }

    if (user && isAuthenticated && !isLoading) {
      getToken()
    }
  }, [
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    navigate,
    location.state?.returnTo,
  ])

  if (error || Auth0Error) {
    return <SSOError error={error || (Auth0Error?.message as string)} />
  }

  return <SessionOverlay />
}

const IdpLogin = ({ connection }: { connection: string }) => {
  const { loginWithRedirect } = useAuth0()
  useEffect(() => {
    async function redirect() {
      await loginWithRedirect({
        appState: { returnTo: '/' },
        authorizationParams: { connection },
      })
    }
    redirect()
  }, [loginWithRedirect, connection])
  return <SessionOverlay />
}

const SSOCallbackPage = () => {
  const params = new URLSearchParams(window.location.search)
  const connection = params.get('connection') || undefined
  return (
    <>
      <Helmet>
        <title>SSO callback</title>
      </Helmet>
      <SessionPageLayout>
        {connection ? <IdpLogin connection={connection} /> : <Auth0User />}
      </SessionPageLayout>
    </>
  )
}

export default SSOCallbackPage
