import {
  Checkbox,
  FilterButton,
  Popover,
  Stack,
  Text,
} from '@gr4vy/poutine-react'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { PopoverMenu } from 'shared/components/PopoverMenu'
import { USERS } from 'shared/constants'
import { useKeyboardListNavigation, Filters, useFilters } from 'shared/hooks'
import { CollectionResponse } from 'shared/services/client'
import { User, listUsers } from 'users/services/users'
import styles from './UsersFilter.module.scss'

interface UsersFilters extends Filters {
  creatorId?: string | string[]
}

const getToggledUsersFilter = (
  newUserId: string,
  currentUsersFilter?: string | string[]
) => {
  if (!currentUsersFilter) {
    return newUserId
  }

  const arrCurrentUsersFilter = [currentUsersFilter].flat()

  if (
    arrCurrentUsersFilter.includes(newUserId) &&
    arrCurrentUsersFilter.length === 1
  ) {
    return undefined
  }

  return arrCurrentUsersFilter.includes(newUserId)
    ? arrCurrentUsersFilter.filter((userId) => userId !== newUserId)
    : [...arrCurrentUsersFilter, newUserId]
}

export const UsersFilter = () => {
  const [filters, setFilters] = useFilters<UsersFilters>()
  const { onKeyDown } = useKeyboardListNavigation()
  const { merchantAccountId } = useParams() as { merchantAccountId: string }

  const { data: { items: users } = {} } = useQuery<CollectionResponse<User>>({
    queryKey: [USERS, merchantAccountId],
    queryFn: () => listUsers({ limit: 100 }),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
  const sortedUsers = useMemo(
    () => users?.sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [users]
  )

  const usersFilter = useMemo(() => filters?.creatorId, [filters])
  const usersFilterCounter = useMemo(() => {
    if (!usersFilter) {
      return null
    }
    return ` / ${[usersFilter].flat().length}`
  }, [usersFilter])

  const onToggleUserFilter = (userId: string) => {
    setFilters({
      ...filters,
      creatorId: getToggledUsersFilter(userId, usersFilter),
    })
  }

  return (
    <Popover>
      <Popover.Trigger asChild>
        <FilterButton data-selected={!!usersFilter}>
          Created by
          {usersFilterCounter}
        </FilterButton>
      </Popover.Trigger>
      <Popover.Content
        align="start"
        side="bottom"
        avoidCollisions={false}
        onKeyDown={onKeyDown}
      >
        <Stack gap={4} role="listbox" className={styles.contentWrapper}>
          <PopoverMenu>
            {sortedUsers?.map((user) => (
              <PopoverMenu.Item
                key={user.id}
                as="label"
                className={styles.item}
              >
                <Checkbox
                  id={user.id}
                  onCheckedChange={() => onToggleUserFilter(user.id)}
                  checked={!!usersFilter?.includes(user.id)}
                  tabIndex={-1}
                ></Checkbox>
                <Text as="span" flexGrow={1} truncate>
                  {user.name}
                </Text>
              </PopoverMenu.Item>
            ))}
          </PopoverMenu>
        </Stack>
      </Popover.Content>
    </Popover>
  )
}
