import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { TRANSACTION, TRANSACTIONS } from 'shared/constants/query-keys'
import { is } from 'shared/helpers/is'
import { useCursor, useFilters } from 'shared/hooks'
import { TransactionFilters } from 'shared/services/transactions'
import { getTransaction, listTransactions } from 'transactions/services'
import View from './RelatedTransactionsPage'

const RelatedTransactionsPage = () => {
  const { merchantAccountId, transactionID } = useParams() as {
    merchantAccountId: string
    transactionID: string
  }

  const transaction = useQuery({
    queryKey: [TRANSACTION, transactionID, merchantAccountId],
    queryFn: () => getTransaction(transactionID),
    meta: {
      error: {
        redirect: true,
      },
    },
  })
  const [filters, setFilters] = useFilters<TransactionFilters>({})
  const [cursor] = useCursor()

  const page = useQuery({
    queryKey: [
      TRANSACTIONS,
      merchantAccountId,
      cursor,
      filters,
      transaction.data?.checkoutSessionId,
    ],
    queryFn: () =>
      listTransactions({
        cursor,
        ...filters,
        checkoutSessionId: transaction.data?.checkoutSessionId,
      }),
    enabled:
      !is.emptyObject(filters) && Boolean(transaction.data?.checkoutSessionId),
  })

  const updateFilter = (f: TransactionFilters) => {
    setFilters({
      ...filters,
      ...f,
    })
  }

  return (
    <View
      page={page}
      filters={filters}
      onFilter={updateFilter}
      transaction={transaction.data}
    />
  )
}

export default RelatedTransactionsPage
