import { Stack, StackProps } from '@gr4vy/poutine-react'
import { ReactElement } from 'react'
import Content from './Content'
import Header from './Header'
import styles from './Panel.module.scss'

interface PanelProps extends StackProps {
  children: ReactElement<typeof Header, typeof Content>[]
}

function Panel({ children, ...rest }: PanelProps) {
  return (
    <Stack
      background="white"
      paddingX={32}
      paddingTop={24}
      paddingBottom={40}
      boxShadow={2}
      borderRadius="rounded"
      gap={32}
      className={styles.panel}
      {...rest}
    >
      {children}
    </Stack>
  )
}

Panel.Header = Header
Panel.Content = Content

export default Panel
