import { Stack, Text } from '@gr4vy/poutine-react'
import { useState } from 'react'
import { ActionsDropdown } from 'connections/components/ActionsDropdown'
import { ActiveAppleCertificateModal } from 'connections/components/ActiveAppleCertificateModal'
import { ModalRemove } from 'shared/components/Modal/ModalRemove'

type ApplePayActionsProps = {
  activeCertificateCount?: number
  pendingCertificateCount?: number
  expiredCertificateCount?: number
  onViewCertificates: () => void
  onDelete: () => void
  isReadOnlyPermission: boolean
}

export const ApplePayActions = ({
  activeCertificateCount,
  pendingCertificateCount,
  expiredCertificateCount,
  onViewCertificates,
  onDelete,
  isReadOnlyPermission,
}: ApplePayActionsProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const activeAppleCertificates = !!activeCertificateCount
  const otherAppleCertificates =
    !activeAppleCertificates &&
    !!(pendingCertificateCount || expiredCertificateCount)

  return (
    <>
      <ActionsDropdown
        onDelete={() => setShowDeleteModal(true)}
        isReadOnlyPermission={isReadOnlyPermission}
      />
      {showDeleteModal && activeAppleCertificates && (
        <ActiveAppleCertificateModal
          onViewCertificates={() => {
            onViewCertificates()
            setShowDeleteModal(false)
          }}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
      {showDeleteModal && otherAppleCertificates && (
        <ModalRemove
          title="Are you sure you want to remove this Apple Pay connection?"
          onCancel={() => setShowDeleteModal(false)}
          onOk={() => {
            onDelete()
            setShowDeleteModal(false)
          }}
        >
          <Stack gap={24}>
            <Text>
              By performing this action the connection will no longer be
              available and we won&apos;t be able to process any Apple Pay
              transactions.
            </Text>
            <Text>
              This connection can later be added up to your configured list of
              available connections and start again processing transactions with
              Apple Pay.
            </Text>
          </Stack>
        </ModalRemove>
      )}
      {showDeleteModal &&
        !activeAppleCertificates &&
        !otherAppleCertificates && (
          <ModalRemove
            title="Delete connection"
            onCancel={() => setShowDeleteModal(false)}
            onOk={() => {
              onDelete()
              setShowDeleteModal(false)
            }}
          >
            You are about to delete this connection and this change is
            non-reversable. Are you sure you want to proceed?
          </ModalRemove>
        )}
    </>
  )
}
