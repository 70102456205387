import { Box, Flex } from '@gr4vy/poutine-react'
import { Select, SelectProps } from 'antd'
import clsx from 'clsx'
import { PaymentMethodDescription } from 'transactions/components/PaymentMethodDescription'
import { getPaymentMethodKey } from 'transactions/helpers/get-payment-method'
import { GiftCardRedemption, PaymentMethod } from 'transactions/services'
import styles from './PaymentMethodsSelect.module.scss'

export type PaymentMethodOptionProps = (PaymentMethod | GiftCardRedemption) & {
  disabled: boolean
  remaining: string
}

export interface PaymentMethodsSelectProps
  extends Omit<SelectProps, 'options'> {
  options: PaymentMethodOptionProps[]
}

export const PaymentMethodsSelect = ({
  options,
  ...rest
}: PaymentMethodsSelectProps) => {
  return (
    <Select
      dropdownStyle={{ pointerEvents: 'auto' }}
      popupClassName={styles.dropdown}
      {...rest}
    >
      {options.map((item) => (
        <Select.Option
          key={getPaymentMethodKey(item)}
          value={getPaymentMethodKey(item)}
          disabled={item.disabled}
          aria-disabled={item.disabled}
          role="option"
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            height="full"
            className={clsx(item.disabled && styles.disabled)}
          >
            <PaymentMethodDescription
              paymentMethod={item.type === 'payment-method' ? item : null}
              giftCardRedemptions={
                item.type === 'gift-card-redemption' ? [item] : []
              }
            />
            <Box display="none" className={clsx(styles.remaining)}>
              {`${item.remaining} remaining`}
            </Box>
          </Flex>
        </Select.Option>
      ))}
    </Select>
  )
}
