import { Description } from '@gr4vy/poutine-react'
import { dateFormat } from 'shared/helpers/date-format'
import { getCurrentLocale } from 'shared/helpers/locale'
import { timeFormat } from 'shared/helpers/time-format'

interface DateTimeProps {
  value: string
  timeFirst?: boolean
}

export const DateTime = ({ value, timeFirst = false }: DateTimeProps) => {
  const date = dateFormat(value, getCurrentLocale(), {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
  const time = timeFormat(value, getCurrentLocale(), {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })

  return (
    <Description>
      <Description.Text>{timeFirst ? time : date}</Description.Text>
      <Description.SubText>{timeFirst ? date : time}</Description.SubText>
    </Description>
  )
}

export const TimeDate = ({ value }: Omit<DateTimeProps, 'timeFirst'>) => (
  <DateTime value={value} timeFirst />
)
