import { Icon, Tooltip } from '@gr4vy/poutine-react'

interface Props {
  code: string
  version: string
}

type Codes = Record<string, Record<string, string>>

const codes: Codes = {
  '1': {
    Y: 'Card authenticated',
    U: 'Card issuer is unable to respond',
    A: 'Attempted to authenticate card',
    N: 'Card authentication failed',
  },
  '2': {
    Y: 'Authentication/ Account Verification Successful',
    N: 'Not Authenticated /Account Not Verified; Transaction denied',
    U: 'Authentication/ Account Verification Could Not Be Performed; Technical or other problem, as indicated in ARes or RReq',
    A: 'Attempts Processing Performed; Not Authenticated/Verified , but a proof of attempted authentication/verification is provided',
    C: 'Challenge Required; Additional authentication is required using the CReq/CRes',
    R: 'Authentication/ Account Verification Rejected; Issuer is rejecting authentication/verification and request that authorisation not be attempted.',
  },
}

const getCodeHint = (code: string, version: string) =>
  codes[version]?.[code] ? codes[version][code] : ''

export const DirectoryResponseInfo = ({ code, version }: Props) => {
  const hint = getCodeHint(code, version)

  return hint ? (
    <Tooltip content={hint}>
      <Icon name="info" size="small" />
    </Tooltip>
  ) : null
}
