import { Rule, RuleConfig } from 'flows/constants'
import ruleConfig from 'flows/constants/rule-config.json'
import { Action, Flow } from 'shared/constants'
import client, { CollectionResponse } from 'shared/services/client'

export type RulePagesConfig = {
  [key in Flow]: {
    [key in Action]: RuleConfig
  }
}

export interface ListRules {
  flow: Flow
}

export interface SaveRule {
  flow: Flow
  action: Action
  id?: string
  rule: Partial<Omit<Rule, 'flow' | 'action' | 'id'>>
}

export interface RemoveRule {
  flow: Flow
  action: Action
  id: string
}

export const listRules = async ({
  flow,
}: ListRules): Promise<CollectionResponse<Rule>> => {
  const response = await client.get(`/flows/${flow}`)
  return response.data
}

export const getRule = async (
  flow: Flow,
  action: Action,
  id: string
): Promise<Rule> => {
  const { data } = await client.get(
    `/flows/${flow}/actions/${action}/rules/${id}`
  )
  return data
}

export const getRuleConfig = async (
  flow: Flow,
  action: Action
): Promise<RuleConfig> => {
  return Promise.resolve((ruleConfig as RulePagesConfig)[flow][action])
}

export const addRule = async ({
  rule,
  flow,
  action,
}: SaveRule): Promise<Rule> => {
  const response = await client.post(
    `/flows/${flow}/actions/${action}/rules`,
    rule
  )
  return response.data
}

export const updateRule = async ({
  flow,
  action,
  id,
  rule,
}: SaveRule): Promise<Rule> => {
  const response = await client.put(
    `/flows/${flow}/actions/${action}/rules/${id}`,
    rule
  )
  return response.data
}

export const removeRule = async ({ flow, action, id }: RemoveRule) => {
  await client.delete(`/flows/${flow}/actions/${action}/rules/${id}`)
}
