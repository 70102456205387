import { Text, TextLink, Divider } from '@gr4vy/poutine-react'
import { NamePath } from 'antd/lib/form/interface'
import { CredentialsChecker } from 'connections/components/CredentialsChecker'
import { EditPaymentServiceForm } from 'connections/components/EditPaymentServiceForm/EditPaymentServiceForm'
import { usePaymentServiceAndDefinition } from 'connections/hooks/use-payment-service-and-definition'
import { verifyPaymentServiceCredentials } from 'connections/services'
import { CredentialField } from 'shared/components/CredentialField/CredentialField'
import { Loading } from 'shared/components/Loading'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'

export const PaymentServiceCredentialsPage = () => {
  const { paymentService, definition, isLoading } =
    usePaymentServiceAndDefinition()

  const hasPermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )

  if (isLoading) {
    return <Loading />
  }

  return (
    <EditPaymentServiceForm submitButtonText="Save changes">
      <Text margin="none">
        Learn how to configure {definition?.displayName} in{' '}
        <TextLink
          href={`https://docs.gr4vy.com/connections/payments/${definition?.id}`}
          opensInNewWindow
        >
          this quick start guide.
        </TextLink>{' '}
        We will help you find all the information you need to set up your
        payment service correctly.
      </Text>
      {definition?.fields.map((item) => (
        <CredentialField
          key={item.key}
          item={item}
          required={item.required}
          secret={item.secret}
          disabled={!hasPermission}
          group="fields"
        />
      ))}
      {definition?.supportedFeatures.verifyCredentials && hasPermission && (
        <CredentialsChecker
          fieldNames={
            definition?.fields.map((field) => [
              'fields',
              field.key,
            ]) as NamePath[]
          }
          verifyCredentials={verifyPaymentServiceCredentials}
          paymentServiceDefinitionId={definition?.id}
          paymentServiceId={paymentService?.id}
        />
      )}
      <Divider />
    </EditPaymentServiceForm>
  )
}
