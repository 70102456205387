import {
  Button,
  Drawer,
  Flex,
  Heading,
  Stack,
  Text,
} from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { ChangeEvent, FocusEvent, useState } from 'react'
import { formatId } from 'admin/helpers/merchant-account-id'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { MerchantAccount } from 'shared/services/merchant-accounts'
import { ErrorMessage } from './ErrorMessage'
import { SuccessMessage } from './SuccessMessage'

type FormValues = {
  displayName: string
  id: string
}

type AddMerchantDrawerProps = {
  open: boolean
  onClose: () => void
  create: UseMutationResult<
    MerchantAccount,
    any,
    Pick<MerchantAccount, 'displayName' | 'id'>,
    unknown
  >
}

const ID_MAX_LENGTH = 22
const validIdFormatRegex = new RegExp(/^[a-zA-Z0-9-]+$/)

export const AddMerchantDrawer = ({
  open,
  onClose,
  create,
}: AddMerchantDrawerProps) => {
  const [form] = Form.useForm<FormValues>()
  const [isIdManuallyTouched, setIsIdManuallyTouched] = useState(false)

  const onChangeDisplayName = (e: ChangeEvent<HTMLInputElement>) => {
    const id = formatId(e.target.value.toLowerCase())

    if (id.length <= ID_MAX_LENGTH && id.match(validIdFormatRegex)) {
      form.setFieldsValue({ id })

      if (id.length > 0) {
        form.validateFields(['id'])
      }
    }
  }

  const onChangeId = (e: ChangeEvent<HTMLInputElement>) => {
    const id = formatId(e.target.value)
    form.setFieldsValue({ id })
    setIsIdManuallyTouched(true)
  }

  const onBlurDisplayName = (e: FocusEvent<HTMLInputElement>) => {
    form.setFieldsValue({ displayName: e.target.value.trim() })

    if (!isIdManuallyTouched) {
      form.setFieldsValue({
        id: formatId(e.target.value.toLowerCase().trim()),
      })
    }
  }

  const onCancel = () => {
    form.resetFields()
    create.reset()
    onClose()
  }

  return (
    <Drawer open={open} title="Create merchant account" onClose={onCancel}>
      <Stack gap={32}>
        {!create.isError && !create.isSuccess && (
          <>
            <Stack gap={8}>
              <Heading as="h2" level={5}>
                Merchant name and identifier
              </Heading>
              <Text variant="reg3" color="gray90">
                A merchant name can be edited at any point. The merchant
                identifier is permanent and can not be changed.
              </Text>
            </Stack>
            <Form
              name="add-merchant"
              form={form}
              initialValues={{
                displayName: '',
                id: '',
              }}
              onFinish={create.mutate}
              autoComplete="off"
              layout={'vertical'}
              requiredMark={false}
            >
              <Stack gap={24}>
                <FormItem
                  name="displayName"
                  label={<Label>Name</Label>}
                  required
                  rules={[
                    { required: true, message: 'Enter merchant name.' },
                    {
                      type: 'string',
                      max: 255,
                      message: 'Enter a maximum of 255 characters.',
                    },
                  ]}
                >
                  <Input
                    onChange={onChangeDisplayName}
                    onBlur={onBlurDisplayName}
                    autoFocus
                  />
                </FormItem>
                <FormItem
                  name="id"
                  label={<Label>ID</Label>}
                  required
                  rules={[
                    { required: true, message: 'Enter merchant ID.' },
                    {
                      type: 'string',
                      max: 22,
                      message: 'Enter a maximum of 22 characters.',
                    },
                    {
                      validator: (_, value) => {
                        if (['*', 'default'].includes(value)) {
                          return Promise.reject(
                            'Reserved name, please enter a different value.'
                          )
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}
                >
                  <Input onChange={onChangeId} />
                </FormItem>
              </Stack>
            </Form>
            <Flex gap={16}>
              <Button variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                form="add-merchant"
                loading={create.isPending}
                variant="primary"
              >
                Create merchant
              </Button>
            </Flex>
          </>
        )}
        {create.isSuccess && <SuccessMessage onClose={onCancel} />}
        {create.isError && (
          <ErrorMessage onClose={onCancel} onGoBack={() => create.reset()} />
        )}
      </Stack>
    </Drawer>
  )
}
