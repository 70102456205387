import { Description, Flex, Heading, Icon, Text } from '@gr4vy/poutine-react'
import { ColumnDef, DataTable } from 'shared/components/DataTable'
import { Panel } from 'shared/components/Panel'
import currencyFormat from 'shared/helpers/currency-format'
import { dateFormat } from 'shared/helpers/date-format'
import { getCurrentLocale } from 'shared/helpers/locale'
import {
  HistoryData,
  useTransactionHistoryData,
} from 'transactions/hooks/use-transaction-history-data'
import { Transaction } from 'transactions/services'

const StatusIcon = ({ status }: { status: string }) => {
  const props = { width: 20, height: 20 }
  switch (status.toLocaleLowerCase()) {
    case 'completed':
      return <Icon name="circle-check" color="black" {...props} />
    case 'declined':
    case 'failed':
      return <Icon name="arrow-circle-left" color="red60" {...props} />
    case 'pending':
    case 'processing':
      return <Icon name="clock" color="gray70" {...props} />
    case 'received':
      return <Icon name="add-plus-circle" color="black" {...props} />
    case 'succeeded':
      return <Icon name="arrow-circle-left" color="green60" {...props} />
    case 'voided':
      return <Icon name="strike" color="red60" {...props} />
    case 'approval incomplete':
      return <Icon name="close-circle" color="red60" {...props} />
  }

  return null
}

const columns: Array<ColumnDef<HistoryData>> = [
  {
    id: 'date',
    accessorKey: 'date',
    size: 400,
    cell: function DateCell({ renderValue }) {
      const date = renderValue<HistoryData['date']>()

      return date ? (
        <Text as="span" variant="code1">
          {dateFormat(date, getCurrentLocale(), {
            weekday: 'short',
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short', // TODO: Update to `shortOffset` once supported by TypeScript
          })}
        </Text>
      ) : (
        '–'
      )
    },
    header: 'Date',
  },
  {
    id: 'amount',
    cell: ({ row }) => {
      const historyData = row.original
      const formattedAmount = currencyFormat(
        historyData.amount,
        { currency: historyData.currency },
        'en'
      )

      return (
        <Description>
          <Description.Text
            style={{
              fontVariantNumeric: 'tabular-nums',
            }}
          >
            {formattedAmount}
          </Description.Text>
        </Description>
      )
    },
    size: 220,
    header: 'Amount',
  },
  {
    id: 'event',
    accessorKey: 'event',
    size: 220,
    header: 'Event',
  },
  {
    id: 'status',
    accessorKey: 'status',
    size: 200,
    cell: function StatusCell({ renderValue }) {
      const status = renderValue<Transaction['status']>()
      return (
        <Flex gap={8} alignItems="center">
          <StatusIcon status={status} />
          {status}
        </Flex>
      )
    },
    header: 'Status',
  },
]

const HistoryPanel = ({ transaction }: { transaction: Transaction }) => {
  const { tableData } = useTransactionHistoryData(transaction)

  return (
    <Panel>
      <Panel.Header>
        <Heading level={4}>History</Heading>
      </Panel.Header>
      <Panel.Content>
        <DataTable data={{ items: tableData }} columns={columns} />
      </Panel.Content>
    </Panel>
  )
}

export default HistoryPanel
