import { Box, ButtonLink, Stack, Text, Badge } from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { useRef, useEffect } from 'react'
import { DragProvided, DragSnapshot } from 'flows/components/DragAndDrop'
import { Rule } from 'flows/constants'
import { Icon, IconType } from 'shared/components/Icon'
import { pathTo } from 'shared/paths/flows'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { Card } from './Card'
import styles from './RuleCard.module.scss'

interface RuleCardProps {
  rule: Rule
  dragProvided: DragProvided
  dragSnapshot: DragSnapshot
  isNew?: boolean
  additionalParams?: URLSearchParams
  merchantAccountId: string
}

export const RuleCard = ({
  rule,
  dragProvided,
  dragSnapshot,
  isNew = false,
  additionalParams = new URLSearchParams(),
  merchantAccountId,
}: RuleCardProps) => {
  const classNames = clsx(styles.ruleCard, {
    [styles.newRuleCard]: isNew,
  })
  const cardEl = useRef<HTMLDivElement>(null)
  useEffect(() => {
    dragProvided.innerRef(cardEl.current)
  }, [dragProvided])
  useEffect(() => {
    if (isNew && cardEl.current) {
      cardEl.current.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }, [isNew])

  const hasWritePermission = useResourcePermission(
    Resource.flows,
    AccessLevel.write
  )

  return (
    <div ref={cardEl} {...dragProvided.draggableProps}>
      <Card className={classNames} data-dragging={dragSnapshot.isDragging}>
        <Box paddingX={16}>
          <Stack
            gap={16}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              paddingY={16}
              gap={16}
              direction="row"
              alignItems="center"
              style={{ overflow: 'hidden' }}
            >
              {hasWritePermission && (
                <Box width={32} height={32} {...dragProvided.dragHandleProps}>
                  <Icon iconType={IconType.Drag} />
                </Box>
              )}
              <Text
                fontWeight="medium"
                margin="none"
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {rule.description}
              </Text>
            </Stack>
            <Stack direction="row" alignItems="center">
              {isNew && (
                <Badge color="positive" variant="subtle">
                  New
                </Badge>
              )}
              <Icon iconType={IconType.Line} color="gray30" />
              <Box fontSize="sm">
                <ButtonLink
                  href={pathTo.rule(
                    merchantAccountId,
                    rule.flow,
                    rule.action,
                    rule.id,
                    additionalParams
                  )}
                  className={styles.ruleCardButtonLink}
                  variant="tertiary"
                >
                  {hasWritePermission ? 'Edit' : 'View'}
                  {hasWritePermission ? (
                    <Box marginLeft={8}>
                      <Icon
                        iconType={IconType.Edit}
                        color="blue60"
                        className="icon-edit"
                        size={20}
                      />
                    </Box>
                  ) : undefined}
                </ButtonLink>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Card>
    </div>
  )
}
