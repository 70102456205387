import { useAuth0 } from '@auth0/auth0-react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { SessionOverlay } from 'session/components/SessionOverlay'

const RedirectAuth0 = () => {
  const location = useLocation()
  const { loginWithRedirect } = useAuth0()
  loginWithRedirect({
    appState: { returnTo: location.state?.previousPath || '/' },
  })
  return <SessionOverlay />
}

const SSOPage = () => {
  return (
    <>
      <Helmet>
        <title>SSO</title>
      </Helmet>
      <RedirectAuth0 />
    </>
  )
}

export default SSOPage
