import { createContext } from 'react'
import { MerchantAccount } from 'shared/services/merchant-accounts'

type MerchantContextProps = {
  merchantAccounts: MerchantAccount[]
  setMerchantAccounts: (merchantAccounts: MerchantAccount[]) => void
}

const MerchantContext = createContext<MerchantContextProps>({
  merchantAccounts: [],
  setMerchantAccounts: () => {},
})

export default MerchantContext
