import { Box, Stack } from '@gr4vy/poutine-react'
import { FormInstance } from 'antd'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { Card } from 'flows/components/Card/Card'
import { DragProvided, DragSnapshot } from 'flows/components/DragAndDrop'
import { ActionOutcome } from 'flows/constants'
import { Action } from 'shared/constants'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { Outcome } from './Outcome'
import styles from './OutcomeAction.module.scss'
import { validateOutcome } from './outcomeValidation'

interface OutcomeCardProps {
  action: Action
  outcome: ActionOutcome
  isHidden?: boolean
  dragProvided: DragProvided
  dragSnapshot: DragSnapshot
  form: FormInstance
}

interface OutcomeMessageProps {
  children: ReactNode
  isError?: boolean
}

export const OutcomeAction = ({
  action,
  outcome,
  isHidden = false,
  dragProvided,
  dragSnapshot,
  form,
}: OutcomeCardProps) => {
  const hasWritePermission = useResourcePermission(
    Resource.flows,
    AccessLevel.write
  )

  const { label } = outcome
  const warning = validateOutcome({ form, outcome, action })
  const classNames = clsx(styles.outcomeCard, {
    [styles.hiddenOutcomeCard]: isHidden,
    [styles.warningOutcomeCard]: !!warning,
  })
  return (
    <Outcome
      ref={dragProvided.innerRef}
      className={classNames}
      isDragging={dragSnapshot.isDragging}
      {...dragProvided.draggableProps}
    >
      <Outcome.Item
        version={1}
        label={label}
        iconUrl={outcome.iconUrl}
        hasWritePermission={hasWritePermission}
        warning={warning}
        isHidden={isHidden}
        {...dragProvided.dragHandleProps}
      />
    </Outcome>
  )
}

export const OutcomeMessage = ({ children, isError }: OutcomeMessageProps) => {
  const classNames = clsx(styles.outcomeMessage, {
    [styles.outcomeError]: isError,
  })
  return (
    <Card
      className={classNames}
      data-message-type={isError ? 'outcomeError' : 'outcomeMessage'}
    >
      <Stack
        direction="row"
        padding={16}
        height={64}
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <Box>{children}</Box>
      </Stack>
    </Card>
  )
}
