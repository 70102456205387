import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import {
  EditConnectionForm,
  EditConnectionFormData,
} from 'connections/components/EditConnectionForm/EditConnectionForm'
import { usePaymentServiceAndDefinition } from 'connections/hooks/use-payment-service-and-definition'
import { usePaymentServices } from 'connections/hooks/use-payment-services'
import { PaymentServiceUpdate } from 'connections/services'
import { pathTo } from 'shared/paths/connections'

interface EditPaymentServiceFormProps {
  children: ReactNode
  submitButtonText?: string
  hasFooterButtons?: boolean
}

export const EditPaymentServiceForm = ({
  children,
  submitButtonText,
  hasFooterButtons,
}: EditPaymentServiceFormProps) => {
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }

  const { remove, update } = usePaymentServices()

  const { paymentService, definition, isLoading } =
    usePaymentServiceAndDefinition()

  const preparePayload = (payload: PaymentServiceUpdate) => {
    if (Object.hasOwn(payload, 'openLoop')) {
      return !payload.openLoop
        ? {
            ...payload,
            networkTokensEnabled: null,
            ...(Object.hasOwn(payload, 'paymentMethodTokenizationEnabled')
              ? {}
              : { paymentMethodTokenizationEnabled: null }),
          }
        : {
            ...payload,
            paymentMethodTokenizationEnabled: null,
            ...(Object.hasOwn(payload, 'networkTokensEnabled')
              ? {}
              : { networkTokensEnabled: null }),
          }
    }

    return payload
  }

  const mapFormToService = (values: EditConnectionFormData) => {
    const payload = {
      ...values,
      id,
      fields: [],
    } as PaymentServiceUpdate
    if (values.hasOwnProperty('fields')) {
      const fields = Object.keys(values.fields).map((key) => ({
        key: key,
        value: values.fields[key],
      }))
      payload['fields'] = fields.filter((field) => field.value != undefined)
    }

    return preparePayload(payload)
  }

  const threeDSecureSupported =
    definition?.supportedFeatures.threeDSecurePassThrough
  const settlementReportingSupported =
    definition?.supportedFeatures.settlementReporting

  return (
    <EditConnectionForm
      subNavigation={[
        {
          title: 'Overview',
          url: `${pathTo.addConnectionPaymentService(
            merchantAccountId,
            id
          )}/overview`,
        },
        {
          title: 'Credentials',
          url: `${pathTo.addConnectionPaymentService(
            merchantAccountId,
            id
          )}/credentials`,
        },
        {
          title: 'Region details',
          url: `${pathTo.addConnectionPaymentService(
            merchantAccountId,
            id
          )}/region-details`,
        },
        ...(threeDSecureSupported
          ? [
              {
                title: '3-D Secure',
                url: `${pathTo.addConnectionPaymentService(
                  merchantAccountId,
                  id
                )}/3-d-secure`,
              },
            ]
          : []),
        ...(settlementReportingSupported
          ? [
              {
                title: 'Settlement',
                url: `${pathTo.addConnectionPaymentService(
                  merchantAccountId,
                  id
                )}/settlement-reporting`,
              },
            ]
          : []),
      ]}
      submitButtonText={submitButtonText}
      data={paymentService}
      isLoading={isLoading}
      remove={remove}
      update={update}
      mapFormToService={mapFormToService}
      hasFooterButtons={hasFooterButtons}
    >
      {children}
    </EditConnectionForm>
  )
}
