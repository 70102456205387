import { Flex, FlexProps } from '@gr4vy/poutine-react'
import styles from './PopoverItem.module.scss'

type PopoverItemProps = Omit<FlexProps, 'size'> & {
  disabled?: boolean
  active?: boolean
  size?: 'sm' | 'md' | undefined
}

export const PopoverItem = ({
  children,
  disabled,
  active,
  className,
  size = 'md',
  ...props
}: PopoverItemProps) => (
  <Flex
    as="li"
    {...props}
    alignItems="center"
    className={[
      styles.item,
      disabled && styles.disabled,
      active && styles.active,
      styles[size],
      className,
    ]}
    role="menuItem"
    tabIndex={0}
    gap={8}
  >
    {children}
  </Flex>
)
