import { Box, Stack } from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { Banner } from './Banner'
import styles from './ConnectionCard.module.scss'
import { Content } from './Content'

interface ConnectionCardProps {
  url?: string
  disabled?: boolean
  children: [ReactElement<typeof Banner>, ReactElement<typeof Content>]
}

export const ConnectionCard = ({
  url,
  disabled = false,
  children,
}: ConnectionCardProps) => {
  const classNames = clsx(styles.connectionCard, {
    [styles.disabledConnectionCard]: disabled,
  })
  const card = (
    <Box className={classNames}>
      <Stack>{children}</Stack>
    </Box>
  )

  return url && !disabled ? (
    <Link to={url} className={styles.connectionCardLink}>
      {card}
    </Link>
  ) : (
    card
  )
}

ConnectionCard.Banner = Banner
ConnectionCard.Content = Content
