import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  removeAntiFraudService,
  updateAntiFraudService,
  addAntiFraudService,
} from 'connections/services'
import { ANTI_FRAUD_SERVICES } from 'shared/constants'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import { handleSuccessResponse } from 'shared/utils/handleSuccessResponse'

export const useAntiFraudServices = () => {
  const queryClient = useQueryClient()
  const invalidateAntiFraudServices = () =>
    queryClient.invalidateQueries({ queryKey: [ANTI_FRAUD_SERVICES] })

  const create = useMutation({
    mutationFn: addAntiFraudService,
    onSuccess: handleSuccessResponse(
      'Connection created',
      invalidateAntiFraudServices
    ),
    onError: handleErrorResponse,
  })
  const update = useMutation({
    mutationFn: updateAntiFraudService,
    onSuccess: (data) => {
      handleSuccessResponse(
        <>
          <b>{data.displayName || 'Connection'}</b> has been updated
        </>,
        invalidateAntiFraudServices
      )()
    },
    onError: handleErrorResponse,
  })
  const remove = useMutation({
    mutationFn: removeAntiFraudService,
    onSuccess: handleSuccessResponse(
      'Connection removed',
      invalidateAntiFraudServices
    ),
    onError: handleErrorResponse,
  })

  return { create, update, remove }
}
