import { DefaultError, Query } from '@tanstack/react-query'
import { is } from 'shared/helpers/is'
import { pathTo as pathToError } from 'shared/paths/errors'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import { showErrorMessage } from 'shared/utils/showMessage'

const handleErrorRedirect = (
  query: Query<unknown, unknown, unknown>,
  status?: number
) => {
  if (typeof query.meta?.error?.redirect === 'string') {
    return window.location.assign(query.meta.error.redirect)
  }

  if (typeof status === 'number' && [401, 403, 404].includes(status)) {
    return window.location.assign(
      [401, 403].includes(status)
        ? pathToError.accessDenied
        : pathToError.notFound
    )
  }

  return handleErrorResponse(query.state.error)
}

const ignoreQueryError = (
  ignore: boolean | Array<number>,
  query: Query<unknown, unknown, unknown>,
  status?: number
) => {
  if (is.boolean(ignore) && ignore) {
    return null
  }

  if (
    is.array(ignore) &&
    status &&
    (ignore as Array<number>).includes(status)
  ) {
    return null
  }

  return handleErrorResponse(query.state.error)
}

export const handleQueryError = (
  error: DefaultError,
  query: Query<unknown, unknown, unknown>
) => {
  if (query.meta?.error?.redirect) {
    return handleErrorRedirect(query, error.response?.status)
  }

  if (query.meta?.error?.message) {
    return showErrorMessage(query.meta.error.message)
  }

  if (query.meta?.error?.ignore) {
    return ignoreQueryError(
      query.meta.error.ignore,
      query,
      error.response?.status
    )
  }

  return handleErrorResponse(query.state.error)
}
