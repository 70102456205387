import { Button, Icon, Flex } from '@gr4vy/poutine-react'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { NamePath } from 'antd/lib/form/interface'
import { snakeCase } from 'lodash'
import { SyntheticEvent, useCallback, useState } from 'react'
import {
  VerifyAntiFraudCredentials,
  VerifyConnectionCredentials,
  VerifyGiftCardCredentials,
} from 'connections/services'
import { VERIFY_CREDENTIALS } from 'shared/constants'

type Status = 'passedVerification' | 'failedVerification' | 'failedQuery'

interface CredentialsCheckerProps
  extends Omit<VerifyConnectionCredentials, 'fields'>,
    Omit<VerifyAntiFraudCredentials, 'fields'>,
    Omit<VerifyGiftCardCredentials, 'fields'> {
  fieldNames: NamePath[]
  verifyCredentials: (
    data:
      | VerifyConnectionCredentials
      | VerifyAntiFraudCredentials
      | VerifyGiftCardCredentials
  ) => Promise<void>
}

const CredentialsChecker = ({
  fieldNames,
  verifyCredentials,
  ...additionalVerifyCredentialParams
}: CredentialsCheckerProps) => {
  const form = Form.useFormInstance()
  const [verifiedState, setVerifiedState] = useState<Status | undefined>()

  const queryFunction = useCallback(() => {
    let formFields = form.getFieldsValue(fieldNames)
    // If we're using the `CredentialField` components we need to get the
    // values from the fields property
    formFields = formFields.fields ?? formFields
    const mappedFields = Object.keys(formFields)
      .filter((key) => formFields[key])
      .map((key) => ({
        key: snakeCase(key),
        value: formFields[key],
      }))

    return verifyCredentials({
      ...additionalVerifyCredentialParams,
      fields: mappedFields,
    })
  }, [form, fieldNames, verifyCredentials, additionalVerifyCredentialParams])

  const { refetch, isFetching } = useQuery({
    queryKey: [VERIFY_CREDENTIALS],
    queryFn: () =>
      queryFunction()
        .then((data) => {
          setVerifiedState('passedVerification')
          return data
        })
        .catch((error) => {
          const errorState =
            error?.response?.status === 400
              ? 'failedVerification'
              : 'failedQuery'

          setVerifiedState(errorState)
          return Promise.reject(error)
        }),
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
  })

  const onClick = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()
      form
        .validateFields(fieldNames)
        .then(() => {
          setVerifiedState(undefined)
          refetch()
        })
        .catch(() => undefined)
    },
    [form, fieldNames, refetch]
  )

  return (
    <Flex alignItems="center" gap={16} marginTop={8}>
      <Button
        variant="secondary"
        loading={isFetching}
        loadingText="Verifying..."
        onClick={onClick}
      >
        Verify credentials
      </Button>
      {verifiedState === 'passedVerification' && (
        <Flex gap={4} color="green60">
          <Icon name="check" size="medium" /> Verified
        </Flex>
      )}
      {verifiedState === 'failedVerification' && (
        <Flex gap={4} color="red70">
          <Icon name="strike" size="medium" /> Invalid credentials
        </Flex>
      )}
      {verifiedState === 'failedQuery' && (
        <Flex gap={4} color="red70">
          <Icon name="strike" size="medium" /> Unable to verify
        </Flex>
      )}
    </Flex>
  )
}

export default CredentialsChecker
