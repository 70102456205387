import { Timeline, TimelineProps } from '@gr4vy/poutine-react'
import { ComponentProps } from 'react'

export const STEPS = {
  ACCESS: 'ACCESS',
  ADMIN_ACCESS: 'ADMIN_ACCESS',
  ROLES: 'ROLES',
  USER_MANAGER_ROLES: 'USER_MANAGER_ROLES',
  DETAILS: 'DETAILS',
  ADMIN_DETAILS: 'ADMIN_DETAILS',
  USER_MANAGER_DETAILS: 'USER_MANAGER_DETAILS',
  INVITE: 'INVITE',
  ADMIN_INVITE: 'ADMIN_INVITE',
  PERMISSIONS: 'PERMISSIONS',
  USER_MANAGER_PERMISSIONS: 'USER_MANAGER_PERMISSIONS',
  MERCHANT_ACCOUNT: 'MERCHANT_ACCOUNT',
} as const

export const errorMessage = {
  ACCESS: 'Please select one access type from above.',
  ROLES: 'Please select at least one role from above.',
  USER_MANAGER_ONLY:
    'Please select at least one additional role along with the User Manager role.',
  MERCHANT_ACCOUNTS: 'Please select at least one merchant account from above.',
}

type TimelineItemProps = ComponentProps<typeof Timeline.Item>

type TextStyleProps = Pick<
  TimelineItemProps,
  'style' | 'fontWeight' | 'textVariant'
> &
  Pick<TimelineProps, 'color'>

export type StepProps = {
  title: string
  step: keyof typeof STEPS
  variant?: TimelineItemProps['variant']
  style?: TextStyleProps
}

export const adminSteps = [
  {
    title: 'Access type',
    step: STEPS.ADMIN_ACCESS,
  },
  {
    title: 'User details',
    step: STEPS.ADMIN_DETAILS,
  },
  {
    title: 'Review invite',
    step: STEPS.ADMIN_INVITE,
  },
] as StepProps[]

export const nonAdminSteps = (
  hasAdditionalPermission?: boolean,
  isUserManager?: boolean
) =>
  [
    ...(!isUserManager
      ? [
          {
            title: 'Access type',
            step: STEPS.ACCESS,
          },
        ]
      : []),
    {
      title: 'Merchant account',
      step: STEPS.MERCHANT_ACCOUNT,
    },
    {
      title: 'User roles',
      step: isUserManager ? STEPS.USER_MANAGER_ROLES : STEPS.ROLES,
    },
    ...(hasAdditionalPermission
      ? [
          {
            title: 'Additional permissions',
            step: isUserManager
              ? STEPS.USER_MANAGER_PERMISSIONS
              : STEPS.PERMISSIONS,
          },
        ]
      : []),
    {
      title: 'User details',
      step: isUserManager ? STEPS.USER_MANAGER_DETAILS : STEPS.DETAILS,
    },
    {
      title: 'Review invite',
      step: STEPS.INVITE,
    },
  ] as StepProps[]
