import { useSearchParams } from 'react-router-dom'
import currencyFormat from 'shared/helpers/currency-format'
import { PaymentMethodOptionProps } from 'transactions/components/PaymentMethodsSelect'
import {
  getPaymentMethodByKey,
  getPaymentMethodKey,
} from 'transactions/helpers/get-payment-method'
import {
  GiftCardRedemption,
  PaymentMethod,
  Transaction,
} from 'transactions/services'
import { useTransactionDetails } from './use-transaction-details'

const isItemDisabled = (
  item: PaymentMethod | GiftCardRedemption,
  isRefundable: boolean
) => {
  if (item.type === 'gift-card-redemption') {
    return item.amount - item.refundedAmount === 0
  }

  return !isRefundable
}

export const getPaymentMethodOptions = (
  transaction: Transaction,
  isRefundable: boolean
): PaymentMethodOptionProps[] =>
  [
    ...(transaction.paymentMethod ? [transaction.paymentMethod] : []),
    ...(transaction.giftCardRedemptions || []),
  ].map((item) => ({
    ...item,
    disabled: isItemDisabled(item, isRefundable),
    remaining: currencyFormat(
      item.type == 'gift-card-redemption'
        ? item.amount - item.refundedAmount
        : (transaction.capturedAmount || transaction.authorizedAmount) -
            transaction.refundedAmount,
      {
        currency: transaction?.currency,
      }
    ),
  }))

export const usePaymentMethodOptions = (transaction: Transaction) => {
  const { refundSupported, refundEnabled } = useTransactionDetails(transaction)
  const [params] = useSearchParams()
  const paymentMethodKey = params.get('paymentMethodKey')
  const paymentMethod = getPaymentMethodByKey(paymentMethodKey, transaction)
  const paymentMethodOptions = getPaymentMethodOptions(
    transaction,
    refundSupported && refundEnabled
  )

  const isRefundablePaymentMethod = (
    paymentMethod: PaymentMethodOptionProps | PaymentMethod | GiftCardRedemption
  ) =>
    (paymentMethod.type === 'payment-method' &&
      refundSupported &&
      refundEnabled) ||
    (paymentMethod.type === 'gift-card-redemption' &&
      paymentMethod.amount > paymentMethod.refundedAmount)

  const getDefaultValue = (
    paymentMethodOptions: PaymentMethodOptionProps[]
  ) => {
    if (
      paymentMethod &&
      isRefundablePaymentMethod(paymentMethod) &&
      paymentMethodKey
    ) {
      return paymentMethodKey
    }

    const firstRefundablePaymentMethod = paymentMethodOptions.find(
      isRefundablePaymentMethod
    )

    return firstRefundablePaymentMethod
      ? getPaymentMethodKey(firstRefundablePaymentMethod)
      : undefined
  }

  return {
    defaultValue: getDefaultValue(paymentMethodOptions),
    paymentMethodOptions,
  }
}
