import { useEffect, useMemo, useState } from 'react'
import {
  categoryToGroupMap,
  Configured,
  ConfiguredGroup,
  connectionDefinitionGroupNames,
} from 'connections/constants'
import { Connection, ConnectionDefinitionGroup } from 'connections/services'

const initialConfigured: Configured = {
  [ConnectionDefinitionGroup.cards]: {
    id: ConnectionDefinitionGroup.cards,
    name: connectionDefinitionGroupNames[ConnectionDefinitionGroup.cards],
    connections: [],
  },
  [ConnectionDefinitionGroup.nonCards]: {
    id: ConnectionDefinitionGroup.nonCards,
    name: connectionDefinitionGroupNames[ConnectionDefinitionGroup.nonCards],
    connections: [],
  },
  [ConnectionDefinitionGroup.other]: {
    id: ConnectionDefinitionGroup.other,
    name: connectionDefinitionGroupNames[ConnectionDefinitionGroup.other],
    connections: [],
  },
}

const addConnectionToGroup = (
  group: ConfiguredGroup,
  connection: Connection
) => {
  return {
    ...group,
    count: (group.count ?? 0) + 1,
    connections: [...group.connections, connection],
  }
}

export const useConfiguredSearch = (connections: Connection[]) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredConnection, setFilteredConnection] = useState(connections)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => search(searchTerm), [connections])

  const search = (value: string) => {
    const filteredConnection = connections.filter(
      (c) =>
        c.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
        c.definition.name
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase()) ||
        (c.definition.provider &&
          c.definition.provider
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()))
    )

    setSearchTerm(value)
    setFilteredConnection(filteredConnection)
  }

  const configured = useMemo(
    () =>
      filteredConnection.reduce((configured, connection) => {
        const group = categoryToGroupMap[connection.definition.category]

        return {
          ...configured,
          count: (configured.count ?? 0) + 1,
          [group]: addConnectionToGroup(configured[group], connection),
        }
      }, initialConfigured),
    [filteredConnection]
  )

  return {
    searchTerm,
    search,
    configured,
  }
}
