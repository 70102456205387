import { Configured } from 'connections/constants'
import { SideNav } from 'shared/components/SideNav/SideNav'

export const ConnectionsConfiguredPageNav = ({
  configured,
}: {
  configured: Configured
}) => {
  const { cards, nonCards, other } = configured
  const children = [cards, nonCards, other]
    .filter((child) => child.count)
    .map((child) => ({
      key: child.id,
      title: child.name,
      href: `#${child.id}`,
    }))

  return (
    <SideNav
      items={[
        {
          key: 'configured',
          title: <SideNav.Title>Configured</SideNav.Title>,
          className: 'title',
          href: '',
          children,
        },
      ]}
    />
  )
}
