import {
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  Icon,
  Grid,
  GridItem,
} from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { useCallback, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { FormItem } from 'shared/components/FormItem'
import { pathTo } from 'shared/paths/users'
import NewUserPageLayout from 'users/components/NewUserPageLayout'
import { useRoles } from 'users/hooks/use-roles'
import { useUseManager } from 'users/hooks/use-use-manager'
import { NewUser, User } from 'users/services/users'

type FormData = {
  name: string
  emailAddress: string
  roleIds: string[]
  merchantAccountIds: string[]
}

interface UserDetailsPageProps {
  add: UseMutationResult<User, any, NewUser, unknown>
  title: string
  merchantAccountId: string
}

export default function UserDetailsPage({
  title,
  add,
  merchantAccountId,
}: UserDetailsPageProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { administratorRoleId } = useRoles()
  const { isUserManager } = useUseManager()
  const [form] = Form.useForm<FormData>()

  const roleIds = useMemo(
    () => searchParams.get('roleIds')?.split(',') || [],
    [searchParams]
  )
  const merchantAccountIds = useMemo(
    () =>
      searchParams.get('merchantAccountIds')
        ? (searchParams.get('merchantAccountIds') as string).split(',')
        : [],
    [searchParams]
  )

  const initialValues: FormData = {
    name: '',
    emailAddress: '',
    roleIds,
    merchantAccountIds,
  }

  const onSubmit = (resource: FormData) => {
    add.mutate(resource, {
      onSuccess: (user: User) => {
        navigate(pathTo.addUser.invite(user.id, user.resetToken as string), {
          state: {
            hasPermissionsStep: !!location.state?.hasPermissionsStep,
          },
        })
      },
      onError: () => form.resetFields(),
    })
  }

  const isAdmin = useMemo(
    () => administratorRoleId === roleIds.at(0),
    [administratorRoleId, roleIds]
  )

  const getStepName = useCallback(() => {
    if (isAdmin) {
      return 'ADMIN_DETAILS'
    }

    if (isUserManager) {
      return 'USER_MANAGER_DETAILS'
    }

    return 'DETAILS'
  }, [isAdmin, isUserManager])

  useEffect(() => {
    if (!roleIds.length) {
      navigate(pathTo.addUser.accessType)
    }
  }, [merchantAccountId, navigate, roleIds.length])

  return (
    <NewUserPageLayout title={title} step={getStepName()}>
      <Stack gap={4}>
        <Heading as="h4">Which user do you want to add?</Heading>
        <Text variant="reg3" color="gray100">
          Enter the details of the user to give access to Gr4vy. After you
          submit the following details an invitation link will be generated that
          you can share with the user.
        </Text>
      </Stack>
      <Form
        id="addUser"
        form={form}
        onFinish={onSubmit}
        initialValues={initialValues}
        preserve={false}
        layout="vertical"
        requiredMark={false}
      >
        <Grid>
          <GridItem gridColumn="span 8">
            <Stack gap={32}>
              <FormItem
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Enter user’s name' }]}
              >
                <Input disabled={false} autoComplete="off" autoFocus />
              </FormItem>
              <FormItem
                label="Email"
                name="emailAddress"
                rules={[
                  { required: true, message: 'Enter user’s email' },
                  {
                    type: 'email',
                    message: 'Please enter a valid email address',
                  },
                ]}
              >
                <Input disabled={false} />
              </FormItem>
              <Form.Item name="roleIds" hidden>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item name="merchantAccountIds" hidden>
                <Input type="hidden" />
              </Form.Item>
            </Stack>
          </GridItem>
        </Grid>
      </Form>
      <Flex gap={16}>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon name="arrow-left-md" />
          Back
        </Button>
        <Button form="addUser" loading={add.isPending}>
          Create and invite user
        </Button>
      </Flex>
    </NewUserPageLayout>
  )
}
