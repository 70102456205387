import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { SelectProps } from 'antd'
import { useParams } from 'react-router-dom'
import { LIST_CARD_SCHEME_DEFINITIONS } from 'shared/constants'
import { GET_MERCHANT_ACCOUNT } from 'shared/constants/query-keys/merchants'
import { GET_CURRENT_USER } from 'shared/constants/query-keys/users'
import { listCardSchemeDefinitions } from 'shared/services'
import {
  getMerchantAccount,
  MerchantAccount,
  updateMerchantAccount,
} from 'shared/services/merchant-accounts'
import { showErrorMessage, showSuccessMessage } from 'shared/utils/showMessage'
import View from './EditMerchantAccountUpdaterPage'

const EditMerchantAccountUpdaterPage = ({ title }: { title: string }) => {
  const { merchantAccountId } = useParams() as {
    merchantAccountId: string
  }
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery({
    queryKey: [GET_MERCHANT_ACCOUNT, merchantAccountId],
    queryFn: () => getMerchantAccount(merchantAccountId),
    meta: {
      error: {
        redirect: true,
      },
    },
    enabled: !!merchantAccountId,
  })

  const { data: cardSchemes } = useQuery({
    queryKey: [LIST_CARD_SCHEME_DEFINITIONS],
    queryFn: () => listCardSchemeDefinitions(),
    gcTime: 1000 * 60 * 15,
    staleTime: 1000 * 60 * 15,
  })

  const update = useMutation({
    mutationFn: updateMerchantAccount,
    onSuccess: (merchantAccount) => {
      showSuccessMessage(
        <>
          {merchantAccount.displayName || 'Merchant account'} account updater
          successfully updated.
        </>
      )

      queryClient.invalidateQueries({ queryKey: [GET_CURRENT_USER] })
      queryClient.invalidateQueries({
        queryKey: [GET_MERCHANT_ACCOUNT, merchantAccountId],
      })
    },
    onError: () =>
      showErrorMessage('Unable to update Merchant details. Please try again.'),
  })

  const schemeDefinitionOptions: SelectProps['options'] =
    cardSchemes?.items.map((scheme) => ({
      label: scheme.displayName,
      value: scheme.id,
    }))

  return (
    <View
      title={title}
      merchantAccount={data || ({ displayName: '...' } as MerchantAccount)}
      loading={isLoading}
      update={update}
      schemes={schemeDefinitionOptions}
    />
  )
}

export default EditMerchantAccountUpdaterPage
