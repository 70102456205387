import { ButtonLink, Flex, Tooltip, Box, Text } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { ColumnDef, TimeDate } from 'shared/components/DataTable'
import { pathTo } from 'shared/paths/transactions'
import { PaymentMethodDescription } from 'transactions/components/PaymentMethodDescription'
import { PaymentMethodsTag } from 'transactions/components/PaymentMethodsTag'
import { TransactionAmount } from 'transactions/components/TransactionAmount'
import { TransactionStatus } from 'transactions/components/TransactionStatus'
import { Summary } from 'transactions/components/TransactionTable'
import { TransactionTableColumnId } from 'transactions/constants/table'

export const homeTransactionColumns: Array<ColumnDef<Summary>> = [
  {
    id: TransactionTableColumnId.PAYMENT_METHOD,
    accessorKey: TransactionTableColumnId.PAYMENT_METHOD,
    size: 229,
    cell: function PaymentMethodCell({ row }) {
      const giftCardRedemptions = row.original.giftCardRedemptions
      const paymentMethod = row.original.paymentMethod

      return (
        <Flex alignItems="center">
          <Box minWidth={0}>
            <PaymentMethodDescription
              paymentMethod={paymentMethod}
              giftCardRedemptions={giftCardRedemptions}
            />
          </Box>
          <PaymentMethodsTag
            paymentMethod={paymentMethod}
            giftCardRedemptions={giftCardRedemptions}
          />
        </Flex>
      )
    },
    header: 'Payment Method',
  },
  {
    accessorKey: 'amount',
    size: 184,
    cell: ({ row }) => {
      return <TransactionAmount transaction={row.original} />
    },
    header: () => <Text textAlign="right">Amount</Text>,
  },
  {
    accessorKey: 'createdAt',
    size: 157,
    cell: function DatetimeCell({ getValue }) {
      const createdAt = getValue<Summary['createdAt']>()
      return <TimeDate value={createdAt} />
    },
    header: 'Date',
  },
  {
    accessorKey: 'status',
    size: 168,
    cell: ({ row }) => {
      return <TransactionStatus transaction={row.original} />
    },
    header: 'Status',
  },
  {
    id: TransactionTableColumnId.ACTIONS,
    header: '',
    size: 59,
    cell: function ActionsCell({ row }) {
      const { merchantAccountId } = useParams() as { merchantAccountId: string }
      const transaction = row.original
      return transaction ? (
        <Flex justifyContent="flex-end">
          <Tooltip content="Go to transaction">
            <ButtonLink
              variant="tertiary"
              size="small"
              href={pathTo.transaction(merchantAccountId, transaction?.id)}
            />
          </Tooltip>
        </Flex>
      ) : null
    },
  },
]
