import { useParams } from 'react-router-dom'
import { pathTo } from 'admin/paths'
import { useSession } from 'session'
import { LayoutProps, PageLayout } from 'shared/components/PageLayout'

const getTabs = (merchantAccountId: string, isStaff: boolean) => {
  const staffTabs = [
    {
      title: 'Account updater',
      url: pathTo.accountUpdater(merchantAccountId),
    },
    {
      title: 'Network tokens',
      url: pathTo.merchantNetworkTokens(merchantAccountId),
    },
  ]

  const tabs = [
    {
      title: 'Overview',
      url: pathTo.merchant(merchantAccountId),
    },
    {
      title: 'Webhooks',
      url: pathTo.merchantWebhook(merchantAccountId),
      end: true,
    },
    ...(isStaff ? [...staffTabs] : []),
    {
      title: 'Vault Forward',
      url: pathTo.merchantVaultForwarding(merchantAccountId),
    },
    {
      title: 'Over-capture',
      url: pathTo.overCapture(merchantAccountId),
    },
  ]

  return tabs
}

export const MerchantPagesLayout = ({
  title,
  children,
  ...rest
}: LayoutProps) => {
  const { isStaff } = useSession()
  const { merchantAccountId } = useParams() as {
    merchantAccountId: string
  }

  return (
    <PageLayout
      title={title}
      subNavigation={getTabs(merchantAccountId, isStaff)}
      {...rest}
    >
      {children}
    </PageLayout>
  )
}
