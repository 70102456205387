import qs from 'qs'
import client, { CollectionResponse } from 'shared/services/client'
import { TransactionFilters } from 'shared/services/transactions'
import {
  Address,
  Buyer,
  PaymentMethod,
  TransactionEventFilters,
} from './transactions'

export interface PayoutMerchant {
  name: string
  identificationNumber: string
  statementDescriptor: string
  phoneNumber: string
  url: string
  merchantCategoryCode: string
  address: Address | null
}

export type PayoutCategory = 'online_gambling'

export type PayoutStatus = 'declined' | 'failed' | 'pending' | 'succeeded'

export interface PayoutFilters {
  cursor: string
  limit: number
  search: string
  payoutStatus: PayoutStatus | PayoutStatus[]
}

export interface PayoutQuickFilters {
  label: string
  toggleArgs: {
    transactionStatus: PayoutStatus
  }
  selected?: boolean
}

export interface PayoutPaymentService {
  id: string
  type: 'payment-service'
  paymentServiceDefinitionId: string
  method: string
  displayName: string
}

export interface PayoutSummary {
  id: string
  type: 'payout'
  merchantAccountId: string
  amount: number
  currency: string
  createdAt: string
  updatedAt: string
  paymentService: PayoutPaymentService | null
  paymentServicePayoutId: string | null
  paymentMethod: PaymentMethod
  category: PayoutCategory
  externalIdentifier: string | null
  buyer?: Buyer
  merchant: PayoutMerchant | null
  status: PayoutStatus
  giftCardService: null
  giftCardRedemptions: []
}

export const listPayouts = async (
  params: Partial<TransactionEventFilters> = {}
): Promise<CollectionResponse<PayoutSummary>> => {
  const response = await client.get('/payouts', {
    params,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat', encode: false }),
  })
  return response.data
}

export const getPayout = async (
  id: string,
  params: Partial<TransactionFilters> = {}
): Promise<PayoutSummary> => {
  const response = await client.get(`/payouts/${id}`, { params })

  return response.data
}
