import { ConnectionSelect } from 'connections/components/ConnectionSelect'
import { filter } from 'connections/components/Filters/select-filter'
import { useCountries } from 'connections/hooks/use-countries'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'

export const CountryInput = ({
  supportedCountries,
  disabled,
}: {
  supportedCountries?: string[]
  disabled?: boolean
}) => {
  const mappedCountries = useCountries(supportedCountries)

  return (
    <FormItem
      label={<Label>Accepted countries</Label>}
      name="acceptedCountries"
      rules={[
        {
          required: true,
          message: 'Please select at least one country',
        },
      ]}
    >
      <ConnectionSelect
        mode="multiple"
        allowClear
        placeholder="Select or type country name"
        filterOption={filter}
        maxTagCount="responsive"
        maxTagPlaceholder={maxTagPlaceholderFormatter}
        showSearch
        options={mappedCountries}
        disabled={disabled}
      />
    </FormItem>
  )
}
