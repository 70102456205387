import { Heading, Stack, Text, TextLink } from '@gr4vy/poutine-react'
import React from 'react'
import { OrderedList } from 'shared/components/OrderedList'

export const StepUploadCsr = () => {
  return (
    <Stack gap={24}>
      <Heading level={5}>Upload Gr4vy CSR to Apple</Heading>
      <Text>
        Visit the{' '}
        <TextLink
          href="https://developer.apple.com/account/resources/identifiers/list/merchant"
          opensInNewWindow
        >
          Apple developer dashboard
        </TextLink>{' '}
        to generate a payment processing certificate.
      </Text>
      <OrderedList>
        <OrderedList.Item>
          Select the Merchant ID to add a payment processing certificate to.
        </OrderedList.Item>
        <OrderedList.Item>
          In the <b>Apple Pay Payment Processing Certificate</b> section click
          on <b>Create Certificate</b>.
        </OrderedList.Item>
        <OrderedList.Item>
          Select <b>Choose File</b> to upload the CSR you downloaded from Gr4vy,
          and then <b>Continue</b>.
        </OrderedList.Item>
        <OrderedList.Item>
          Verify the certificate details and <b>Download</b> signed certificate
          from Apple.
        </OrderedList.Item>
      </OrderedList>
      <Text>
        In the next step you will upload the signed Apple certificate to Gr4vy
        for use with Apple Pay.
      </Text>
    </Stack>
  )
}
