import { Form, FormItemProps as AntdFormItemProps } from 'antd'
import { EditableFormItem } from 'shared/components/EditableFormItem'
import styles from './FormItem.module.scss'

export type FormItemProps = AntdFormItemProps & {
  editable?: boolean
  secret?: boolean
}

const FormItem = ({
  children,
  style,
  editable,
  secret,
  ...rest
}: FormItemProps) => {
  const props = {
    className: styles.formItem,
    style: { marginBottom: 0, ...style },
    labelAlign: 'left',
    ...rest,
  } as FormItemProps

  if (editable) {
    return (
      <EditableFormItem secret={secret} {...props}>
        {children}
      </EditableFormItem>
    )
  }

  return <Form.Item {...props}>{children}</Form.Item>
}

export default FormItem
