import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { GET_USER } from 'shared/constants'
import { getUser } from 'users/services/users'
import View from './UpdatedUserNamePage'

const UpdatedUserNamePage = ({ title }: { title: string }) => {
  const { id } = useParams() as {
    id: string
  }

  const response = useQuery({
    queryKey: [GET_USER, id],
    queryFn: () => getUser(id as string),
    meta: {
      error: {
        redirect: true,
      },
    },
    refetchOnWindowFocus: false,
    enabled: !!id,
  })

  return (
    <View
      title={title}
      user={response?.data ? { ...response.data, id } : undefined}
      loading={response.isLoading}
    />
  )
}

export default UpdatedUserNamePage
