import {
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  Tile,
  Skeleton,
  TextLink,
  Drawer,
  Alert,
} from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { Fragment, MouseEvent, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { pathTo } from 'shared/paths/users'
import NewUserPageLayout from 'users/components/NewUserPageLayout'
import { errorMessage, getRoleDescription, rolesDrawer } from 'users/constants'
import { useEditRoles } from 'users/hooks/use-edit-roles'
import { useRoles } from 'users/hooks/use-roles'
import { useUseManager } from 'users/hooks/use-use-manager'
import { Role, UpdateUser, User } from 'users/services/users'

interface UserRolesPageProps {
  title: string
  user?: User
  loading: boolean
  update: UseMutationResult<User, any, UpdateUser, unknown>
}

type PickedRole = Pick<Role, 'id' | 'name'>

const RolesLoader = (
  <Skeleton gap={32}>
    {Array.from({ length: 8 }, (_, n) => (
      <Skeleton.Box height={120} key={n} />
    ))}
  </Skeleton>
)

export default function UserRolesPage({
  title,
  user,
  loading,
  update,
}: UserRolesPageProps) {
  const navigate = useNavigate()
  const {
    nonAdministratorRoles,
    additionalPermissionsRoles,
    userManagerRoleId,
  } = useRoles()
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showRolesDrawer, setShowRolesDrawer] = useState(false)
  const { isUserManager } = useUseManager()
  const [searchParams] = useSearchParams()
  const {
    setRoleIds,
    roleIds,
    permissionRoleIds,
    rolesWithAdditionalPermissions,
    hasUserPermissionRoles,
  } = useEditRoles(user)

  const merchantAccountIds = useMemo(
    () => searchParams.get('merchantAccountIds')?.split(',') || [],
    [searchParams]
  )
  const handleToggleRole = (role: PickedRole) => {
    setShowErrorMessage(false)
    const isExistentRole = roleIds.find((roleId) => roleId === role.id)

    if (isExistentRole) {
      setRoleIds((currRoleIds) =>
        currRoleIds.filter((roleId) => roleId !== role.id)
      )
      return
    }

    setRoleIds((currRoleIds) => [...currRoleIds, role.id])
  }

  const hasOnlyUserManagerSelected = useMemo(
    () => roleIds.length === 1 && roleIds.at(0) === userManagerRoleId,
    [roleIds, userManagerRoleId]
  )

  const hasSelectedRolesWithPermissions = !!rolesWithAdditionalPermissions.find(
    (role) => roleIds.includes(role.id)
  )

  const hasPermissionsAvailable =
    additionalPermissionsRoles.some(
      (additionalPermissionsRole) => !!additionalPermissionsRole.id
    ) || hasUserPermissionRoles

  const handleNextStep = () => {
    if (!roleIds.length || hasOnlyUserManagerSelected) {
      setShowErrorMessage(true)
      return
    }

    if (hasSelectedRolesWithPermissions && hasPermissionsAvailable) {
      navigate(pathTo.addUser.permissions(roleIds, merchantAccountIds), {
        state: {
          hasPermissionsStep:
            hasSelectedRolesWithPermissions && hasPermissionsAvailable,
        },
      })
      return
    }

    navigate(pathTo.addUser.details(roleIds, merchantAccountIds))
  }

  const handleUpdate = () => {
    if (!user?.id) {
      return
    }

    if ((!isUserManager && !roleIds.length) || hasOnlyUserManagerSelected) {
      setShowErrorMessage(true)
      return
    }

    if (hasSelectedRolesWithPermissions && hasPermissionsAvailable) {
      navigate(
        pathTo.editUser.permissions(user.id, roleIds, merchantAccountIds)
      )
      return
    }

    update.mutate(
      {
        id: user.id,
        name: user.name,
        roleIds:
          hasSelectedRolesWithPermissions && hasPermissionsAvailable
            ? [...roleIds, ...permissionRoleIds]
            : roleIds,
        merchantAccountIds,
      },
      {
        onSuccess: () => navigate(pathTo.editUser.updatedUser(user.id)),
      }
    )
  }

  const handleShowDrawer = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setShowRolesDrawer(true)
  }

  const isDisabledRole = useMemo(() => {
    return (
      user: User | undefined,
      role: (typeof nonAdministratorRoles)[number]
    ) => {
      if (!role.id) {
        return true
      }

      if (
        !user ||
        (user && !user.roles.find((userRole) => userRole.id === role.id))
      ) {
        return false
      }

      return (
        !!rolesWithAdditionalPermissions.find(
          (roleWithAdditionalPermissions) =>
            roleWithAdditionalPermissions.id === role.id
        ) &&
        !additionalPermissionsRoles.every(
          (additionalPermissionsRole) => !!additionalPermissionsRole.id
        )
      )
    }
  }, [additionalPermissionsRoles, rolesWithAdditionalPermissions])

  return (
    <>
      <NewUserPageLayout
        title={title}
        step={isUserManager ? 'USER_MANAGER_ROLES' : 'ROLES'}
        hasPermissionsStep={
          hasSelectedRolesWithPermissions && hasPermissionsAvailable
        }
      >
        <Stack gap={4}>
          <Heading as="h4">What role(s) will the user have?</Heading>
          <Text variant="reg3" color="gray100">
            Select the roles that you&apos;d like to assign to the user. Each
            role provides access to parts of the system or allows for actions to
            be performed on certain pages. Multiple roles can be assigned to a
            user, giving the user the combined set of permissions granted by
            those roles.{' '}
            <TextLink href="#" onClick={handleShowDrawer}>
              Learn more about user roles.
            </TextLink>
          </Text>
        </Stack>
        <Stack gap={16}>
          <Stack gap={32}>
            {isUserManager && (
              <Alert variant="information">
                <Alert.Icon />
                <Alert.Content>
                  <Alert.Title>Information</Alert.Title>
                  <Alert.Text>
                    You can only assign the following roles because as a User
                    Manager you can only assign roles you have access to
                    yourself. To access other roles, please reach out to your
                    Administrator.
                  </Alert.Text>
                </Alert.Content>
              </Alert>
            )}
            {!!nonAdministratorRoles.length && !loading
              ? Object.values(nonAdministratorRoles).map((role) => (
                  <Tile
                    key={role.id || role.name}
                    title={role.name}
                    text={getRoleDescription(role.name, role.description)}
                    onCheckedChange={() =>
                      handleToggleRole({ id: role.id, name: role.name })
                    }
                    defaultChecked={
                      !!user?.roles.find(
                        (userRole) => userRole.name === role.name
                      )
                    }
                    disabled={isDisabledRole(user, role)}
                  />
                ))
              : RolesLoader}
          </Stack>
          {showErrorMessage && (
            <Text variant="reg4" color="red90">
              {hasOnlyUserManagerSelected
                ? errorMessage.USER_MANAGER_ONLY
                : errorMessage.ROLES}
            </Text>
          )}
        </Stack>
        <Flex gap={16}>
          {user ? (
            <>
              <Button onClick={handleUpdate} loading={update.isPending}>
                Update roles
              </Button>
              <Button variant="secondary" onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button variant="secondary" onClick={() => navigate(-1)}>
                <Icon name="arrow-left-md" />
                Back
              </Button>
              <Button onClick={handleNextStep}>Continue</Button>
            </>
          )}
        </Flex>
      </NewUserPageLayout>
      <Drawer
        open={showRolesDrawer}
        title="User roles"
        onClose={() => setShowRolesDrawer(false)}
      >
        <Stack gap={32}>
          {rolesDrawer.map((role) => (
            <Fragment key={role.name}>
              <Stack gap={8}>
                <Heading as="h2" level={5}>
                  {role.name}
                </Heading>
                <Text>{role.description}</Text>
              </Stack>
            </Fragment>
          ))}
        </Stack>
      </Drawer>
    </>
  )
}
