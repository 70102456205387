import {
  Button,
  ButtonLink,
  Flex,
  Heading,
  Icon,
  Text,
} from '@gr4vy/poutine-react'
import { Size80 } from '@gr4vy/poutine-tokens/js/tokens'
import { useNavigate } from 'react-router-dom'
import ErrorPageLayout from 'errors/components/ErrorPageLayout'

const NotFoundPage = ({ title }: { title: string }) => {
  const navigate = useNavigate()

  return (
    <ErrorPageLayout title={title}>
      <Text
        color="gray60"
        fontSize="xxd"
        fontWeight="bold"
        style={{ lineHeight: Size80 }}
        as="div"
      >
        404
      </Text>
      <Heading level={1}>Page not found</Heading>
      <Text as="div">
        Sorry, the page you are looking for doesn&rsquo;t exist or has been
        moved. Here are some helpful links:
      </Text>
      <Flex gap={16} justifyContent="center" paddingTop={32}>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon name="arrow-left-md" />
          Go back
        </Button>
        <ButtonLink href={'/'}>Take me home</ButtonLink>
      </Flex>
    </ErrorPageLayout>
  )
}

export default NotFoundPage
