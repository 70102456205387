import { Icon, Stack, Text, VisuallyHidden } from '@gr4vy/poutine-react'
import { Input } from 'antd'
import clsx from 'clsx'
import { debounce } from 'lodash'
import { ChangeEvent, KeyboardEvent, useCallback } from 'react'
import styles from './DebouncedSearchBar.module.scss'

interface DebouncedSearchBarProps {
  placeholder: string
  defaultValue?: string
  onChange: (value?: string) => void
  size?: 'small' | 'medium'
  className?: string
  hideLabel?: boolean
}

const DebouncedSearchBar = ({
  placeholder,
  defaultValue,
  onChange,
  size = 'medium',
  className = '',
  hideLabel = false,
}: DebouncedSearchBarProps) => {
  const onSearch = useCallback(
    ({ target }: KeyboardEvent | ChangeEvent) => {
      onChange((target as HTMLInputElement).value || undefined)
    },
    [onChange]
  )

  const debouncedSearch = debounce(onSearch, 400)

  const onChangeSearch = ({ target }: KeyboardEvent | ChangeEvent) => {
    if ((target as HTMLInputElement).value === '') {
      debouncedSearch.cancel()
      return onSearch({ target } as ChangeEvent)
    }
    return debouncedSearch({ target } as ChangeEvent)
  }

  const renderLabel = () => {
    if (hideLabel) {
      return <VisuallyHidden>Search</VisuallyHidden>
    }
    return (
      <Text margin="none" fontWeight="medium">
        Search
      </Text>
    )
  }

  return (
    <Stack as="label" gap={8} className={className}>
      {renderLabel()}
      <Input
        allowClear
        name="search"
        placeholder={placeholder}
        onPressEnter={onSearch}
        onChange={onChangeSearch}
        defaultValue={defaultValue}
        prefix={<Icon name="magnifying-glass" size="small" color="black" />}
        className={clsx(size === 'small' && styles.inputSmall)}
      />
    </Stack>
  )
}

export default DebouncedSearchBar
