import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { Rule } from 'flows/constants'
import { listRules } from 'flows/services'
import { groupBy } from 'flows/utils/array'
import { Action, Flow } from 'shared/constants'

export const useRules = (
  flow: Flow
): UseQueryResult<Record<Action, Rule[]>> => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }

  return useQuery({
    queryKey: [flow, merchantAccountId],
    queryFn: async () => {
      const list = await listRules({ flow })
      return groupBy(list.items, 'action')
    },
    meta: {
      error: {
        redirect: true,
      },
    },
  })
}
