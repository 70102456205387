import { atoms, Button, Stack } from '@gr4vy/poutine-react'
import { Form } from 'antd'
import { useState, SyntheticEvent } from 'react'
import { Navigate } from 'react-router-dom'
import { Outcome, Rule, RuleConfig, flowNames } from 'flows/constants'
import { FlowProvider } from 'flows/contexts/FlowProvider'
import { SaveRule, RemoveRule } from 'flows/services'
import {
  formValuesToRule,
  ruleToFormValues,
  FormValues,
  HIDDEN_CONDITIONS,
} from 'flows/utils/form'
import { FormItem } from 'shared/components/FormItem'
import { FormPromptUnsavedChanges } from 'shared/components/FormPromptUnsavedChanges'
import { PageLayout } from 'shared/components/PageLayout'
import { PageTitleInput } from 'shared/components/PageTitleInput'
import { Flow, Action } from 'shared/constants'
import { useBeforeUnloadAlert } from 'shared/hooks'
import { pathTo } from 'shared/paths/flows'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { ConditionSection } from './ConditionSection'
import { OutcomeSection } from './OutcomeSection'
import { RemoveRuleButton } from './RemoveRuleButton'

type RulePageProps = {
  title: string
  flow: Flow
  action: Action
  outcomes: Outcome[]
  rule?: Rule
  config: RuleConfig
  onSaveRule: (data: SaveRule) => void
  isSaving: boolean
  onRemoveRule?: (data: RemoveRule) => void
  onCancelRule: () => void
  searchParams: URLSearchParams
  merchantAccountId: string
}

const RulePage = ({
  title,
  flow,
  action,
  outcomes,
  rule,
  config,
  onSaveRule,
  isSaving,
  onRemoveRule,
  onCancelRule,
  searchParams,
  merchantAccountId,
}: RulePageProps) => {
  const hasWriteAccess = useResourcePermission(
    Resource.flows,
    AccessLevel.write
  )
  const flowUrl = pathTo.flow(merchantAccountId, flow, searchParams)
  const [form] = Form.useForm()
  const [initialValues, setInitialValues] = useState(
    ruleToFormValues(rule, outcomes, config, searchParams)
  )
  useBeforeUnloadAlert(form)

  // Validate hidden fields
  if (
    config.conditions.filter(
      (c) => (HIDDEN_CONDITIONS as string[]).includes(c.name) && c.hidden
    ).length > 0 &&
    initialValues.hiddenConditions.filter(({ value }) => !!value).length === 0
  ) {
    return <Navigate to={flowUrl} replace />
  }

  const onSubmit = (values: FormValues) => {
    setInitialValues(values)
    onSaveRule({
      id: rule?.id,
      flow,
      action,
      rule: formValuesToRule(values),
    })
  }
  const onDelete = () => {
    if (onRemoveRule && rule) {
      setInitialValues(form.getFieldsValue())
      onRemoveRule({ flow, action, id: rule.id })
    }
  }
  const onCancel = (e: SyntheticEvent) => {
    e.preventDefault()
    onCancelRule()
  }

  const breadcrumbs = [{ title: flowNames[flow], url: flowUrl }, { title }]

  const outcomeCardVersion =
    !!rule?.outcome && 'version' in rule.outcome
      ? rule.outcome.version
      : undefined

  return (
    <FlowProvider flow={flow} action={action}>
      <Form
        name="rule"
        form={form}
        initialValues={initialValues}
        onFinish={onSubmit}
        autoComplete="off"
        layout={'vertical'}
        requiredMark={false}
        scrollToFirstError={{
          block: 'center',
        }}
        disabled={!hasWriteAccess}
      >
        <PageLayout
          breadcrumbs={breadcrumbs}
          title={title}
          titleChildren={
            <FormItem
              className={atoms({ fontWeight: 'normal', width: 'full' })}
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Please add a description for the rule',
                },
              ]}
            >
              <PageTitleInput
                data-testid="rule-desc-input"
                placeholder="Type rule name here"
              />
            </FormItem>
          }
          actions={
            <FormItem shouldUpdate>
              {() => (
                <Stack direction="row" gap={16}>
                  {rule && hasWriteAccess && (
                    <RemoveRuleButton onDelete={onDelete} />
                  )}
                  <Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                  {hasWriteAccess && (
                    <Button
                      variant="primary"
                      disabled={(rule && !form.isFieldsTouched()) || isSaving}
                    >
                      Save rule
                    </Button>
                  )}
                </Stack>
              )}
            </FormItem>
          }
        >
          <Stack gap={64} paddingTop={32} direction="column">
            <ConditionSection form={form} conditions={config.conditions} />
            <OutcomeSection
              outcomes={outcomes}
              outcomeConfig={config.outcome}
              outcomeCardVersion={outcomeCardVersion}
              hasErrorCode={config.hasErrorCode}
            />
            <FormItem shouldUpdate>
              {() => (
                <Stack direction="row" gap={16}>
                  {hasWriteAccess && (
                    <Button
                      variant="primary"
                      disabled={rule && !form.isFieldsTouched()}
                    >
                      Save rule
                    </Button>
                  )}
                  <Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                  {rule && hasWriteAccess && (
                    <RemoveRuleButton onDelete={onDelete} />
                  )}
                </Stack>
              )}
            </FormItem>
          </Stack>
          <FormPromptUnsavedChanges form={form} initialValues={initialValues} />
        </PageLayout>
      </Form>
    </FlowProvider>
  )
}

export default RulePage
