import {
  Button,
  Flex,
  Icon,
  Stack,
  RadioGroup,
  Box,
} from '@gr4vy/poutine-react'
import { DatePicker } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  FilterRange,
  RADIO_FILTER_RANGES,
} from 'shared/components/DateTimeRangePicker/dateTimeFilter'
import { getDateRangeByFilter } from 'shared/components/DateTimeRangePicker/get-date-by-range'
import { TransactionFilters } from 'shared/services/transactions'
import styles from './DateTimeRangePicker.module.scss'

const { RangePicker } = DatePicker

interface DateTimeRangePickerProps {
  onApply: (newFilters: Partial<TransactionFilters>) => void
  onClear: () => void
  range?: RangePickerProps['value']
  disabledDate?: RangePickerProps['disabledDate']
  enablePresets?: boolean
}

export const DateTimeRangePicker = ({
  range,
  onApply,
  onClear,
  disabledDate,
  enablePresets,
}: DateTimeRangePickerProps) => {
  const [dateRangeFilter, setDateRangeFilter] = useState<FilterRange>(
    FilterRange.CUSTOM
  )
  const [values, setValues] = useState([])
  const afterCreatedAtRef = useRef<HTMLInputElement | null>(null)
  const beforeCreatedAtRef = useRef<HTMLInputElement | null>(null)

  const value = useMemo(
    () =>
      dateRangeFilter === FilterRange.CUSTOM
        ? range
        : getDateRangeByFilter(dateRangeFilter),
    [dateRangeFilter, range]
  )

  const initialDateRangeFilter = useMemo(
    () =>
      Object.keys(FilterRange).find((filterRange) => {
        const dateTimeFormat = 'YYYY-MM-DDTHH:mm'
        const dateRangeByFilter = getDateRangeByFilter(
          filterRange as FilterRange
        )
        return (
          value &&
          dateRangeByFilter &&
          (dateRangeByFilter as Dayjs[])[0].format(dateTimeFormat) ===
            (value as Dayjs[])[0].format(dateTimeFormat) &&
          (dateRangeByFilter as Dayjs[])[1].format(dateTimeFormat) ===
            (value as Dayjs[])[1].format(dateTimeFormat)
        )
      }) ?? FilterRange.CUSTOM,
    [value]
  )

  const handleUpdateValues = () => {
    setValues([])
  }

  const handleChangeRangeFilter = (filter: FilterRange) => {
    setDateRangeFilter(filter)
    handleUpdateValues()
  }

  const defaultShowTime = {
    defaultValue: [
      dayjs('00:00:00', 'HH:mm:ss'),
      dayjs('23:59:59', 'HH:mm:ss'),
    ],
  }

  const handleApply = () => {
    onApply({
      afterCreatedAt:
        afterCreatedAtRef.current?.value &&
        dayjs(afterCreatedAtRef.current.value).toISOString(),
      beforeCreatedAt:
        beforeCreatedAtRef.current?.value &&
        dayjs(beforeCreatedAtRef.current.value).toISOString(),
    })
  }

  const handleClear = () => {
    setDateRangeFilter(FilterRange.CUSTOM)
    afterCreatedAtRef.current = null
    beforeCreatedAtRef.current = null
    onClear()
  }

  useEffect(() => {
    const [afterCreatedAt, beforeCreatedAt] =
      (Array.from(
        document.querySelectorAll('.ant-picker-range .ant-picker-input input')
      ) as [HTMLInputElement, HTMLInputElement]) ?? []

    if (afterCreatedAt && beforeCreatedAt) {
      afterCreatedAtRef.current = afterCreatedAt
      beforeCreatedAtRef.current = beforeCreatedAt
    }
  }, [values])

  return (
    <Flex gap={48} padding={24}>
      {enablePresets && (
        <Box paddingTop={40}>
          <RadioGroup
            defaultValue={initialDateRangeFilter}
            onValueChange={(value) =>
              handleChangeRangeFilter(value as FilterRange)
            }
            gap={8}
          >
            {RADIO_FILTER_RANGES.map((filter) => (
              <RadioGroup.Item
                key={filter.value}
                id={filter.value}
                value={filter.value}
                autoFocus={initialDateRangeFilter === filter.value}
              >
                {filter.label}
              </RadioGroup.Item>
            ))}
          </RadioGroup>
        </Box>
      )}
      <Stack className={styles.container} onClick={handleUpdateValues}>
        <RangePicker
          value={value as RangePickerProps['value']}
          open={true}
          showTime={defaultShowTime}
          getPopupContainer={(triggerNode: HTMLElement) => {
            return triggerNode.parentElement as HTMLElement
          }}
          disabledDate={disabledDate}
          placeholder={['Created after', 'Created before']}
          suffixIcon={<Icon name="calendar" size="small" color="black" />}
          className={styles.datePicker}
          renderExtraFooter={() => (
            <Flex gap={8} justifyContent="flex-end" padding={8}>
              <Button variant="secondary" size="small" onClick={handleClear}>
                Clear
              </Button>
              <Button size="small" onClick={handleApply}>
                Apply
              </Button>
            </Flex>
          )}
        />
      </Stack>
    </Flex>
  )
}
