import { ButtonLink, Icon, Stack, Text, TextLink } from '@gr4vy/poutine-react'
import { pathTo } from 'admin/paths'
import { Step } from './Step'

export const ApiKeyStep = () => (
  <Step title="Your first connection is set up">
    <Stack gap={16}>
      <Text variant="med2">
        Next, you need to create an API key in order to connect to our API. You
        can learn more about API keys in our{' '}
        <TextLink
          href="https://docs.gr4vy.com/guides/api/authentication#create-an-api-key"
          variant="standalone"
          opensInNewWindow
        >
          developer documentation
        </TextLink>
        .
      </Text>
      <ButtonLink href={pathTo.apiKeys}>
        Create an API key
        <Icon name="add-plus" />
      </ButtonLink>
      <Text variant="med2">
        Once created, you can{' '}
        <Text as="span" fontWeight="bold">
          Integrate using our SDKs
        </Text>{' '}
        and, finnaly,{' '}
        <Text as="span" fontWeight="bold">
          Create your first transaction
        </Text>
      </Text>
    </Stack>
  </Step>
)
