import { ButtonCopy } from '@gr4vy/poutine-react'
import { ReactNode, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import Header from 'buyers/components/Header'
import { buyerTabName, BuyerTabs } from 'buyers/constants'
import { Buyer } from 'buyers/services/buyers'
import { getBuyerName } from 'buyers/utils/buyer'
import { PageLayout } from 'shared/components/PageLayout'
import { pathTo } from 'shared/paths/buyers'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'

interface BuyerPageLayoutProps {
  children: ReactNode
  buyer?: Buyer
}
export default function BuyerPageLayout({
  children,
  buyer,
}: BuyerPageLayoutProps) {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const hasTransactionPageAccess = useResourcePermission(
    Resource.transactions,
    AccessLevel.read
  )
  const tabs = useMemo(
    () => [
      {
        title: buyerTabName[BuyerTabs.overview],
        url: pathTo.buyerTab(
          merchantAccountId,
          buyer?.id ?? '',
          BuyerTabs.overview
        ),
      },
      ...(hasTransactionPageAccess
        ? [
            {
              title: buyerTabName[BuyerTabs.transactions],
              url: pathTo.buyerTab(
                merchantAccountId,
                buyer?.id ?? '',
                BuyerTabs.transactions
              ),
            },
          ]
        : []),
      ...[
        {
          title: buyerTabName[BuyerTabs.paymentMethods],
          url: pathTo.buyerTab(
            merchantAccountId,
            buyer?.id ?? '',
            BuyerTabs.paymentMethods
          ),
        },
        {
          title: buyerTabName[BuyerTabs.giftCards],
          url: pathTo.buyerTab(
            merchantAccountId,
            buyer?.id ?? '',
            BuyerTabs.giftCards
          ),
        },
      ],
    ],
    [merchantAccountId, buyer?.id, hasTransactionPageAccess]
  )

  return (
    <PageLayout
      titleChildren={
        buyer && (
          <Header title={getBuyerName(buyer)} createdAt={buyer?.createdAt} />
        )
      }
      subNavigation={tabs}
      actions={
        <ButtonCopy variant="secondary" copy={document.location.href}>
          Copy link
        </ButtonCopy>
      }
      noContainer
      gap="none"
    >
      {children}
    </PageLayout>
  )
}
