import { Flex, Text } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import { currencies as currencyList } from 'shared/helpers/currency'
import styles from './CurrencySelect.module.scss'

type CurrencySelectProps = {
  currencies: string[]
  onChange: (value: string[]) => void
  mode?: 'multiple' | 'tags' | undefined
}

const CurrencySelect = ({
  currencies,
  onChange,
  mode,
}: CurrencySelectProps) => {
  return (
    <Select
      className={styles.select}
      placeholder="Select option(s)..."
      mode={mode}
      optionLabelProp="label"
      allowClear={!!mode}
      showSearch
      onChange={onChange}
      value={currencies}
      optionFilterProp="data-filter"
    >
      {currencyList?.map(({ code, currency }) => (
        <Select.Option
          key={code}
          label={code}
          data-filter={`${code} ${currency}`}
        >
          <Flex gap={8} alignItems="center">
            <Text
              as="span"
              display="inline-block"
              fontWeight="medium"
              width={32}
            >
              {code}
            </Text>
            <Text as="span" truncate>
              {currency}
            </Text>
          </Flex>
        </Select.Option>
      ))}
    </Select>
  )
}

export default CurrencySelect
