import { Box } from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { ReactElement } from 'react'
import { Item } from './Item'
import styles from './styles.module.scss'

interface OrderedListProps {
  className?: string
  children: ReactElement<typeof Item | false>[]
}

const OrderedList = ({ children, className }: OrderedListProps) => {
  return (
    <Box as="ol" className={clsx(styles.list, className)}>
      {children}
    </Box>
  )
}

OrderedList.Item = Item

export { OrderedList }
