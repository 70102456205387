import { useQuery } from '@tanstack/react-query'
import { getAntiFraudServiceDefinition } from 'connections/services'
import { ANTI_FRAUD_SERVICE_DEFINITIONS } from 'shared/constants'

export const useAntiFraudServiceDefinition = (
  merchantAccountId: string,
  id?: string
) => {
  const { data, isLoading } = useQuery({
    queryKey: [ANTI_FRAUD_SERVICE_DEFINITIONS, id, merchantAccountId],
    queryFn: () => getAntiFraudServiceDefinition(id as string),
    refetchOnWindowFocus: false,
    enabled: !!id,
  })

  return { isLoading, antiFraudServiceDefinition: data }
}
