import { Grid, GridItem } from '@gr4vy/poutine-react'
import { Input, Select } from 'antd'
import { FormItem } from 'shared/components/FormItem'

export const Outcome3DS = () => (
  <Grid>
    <GridItem gridColumn="span 4">
      <FormItem name={['outcome', 'type']} hidden>
        <Input />
      </FormItem>
      <FormItem name={['outcome', 'result']}>
        <Select>
          <Select.Option value="attempt">
            Attempt 3DS, continue if not enrolled
          </Select.Option>
          <Select.Option value="force">
            Force 3DS, decline if not enrolled
          </Select.Option>
          <Select.Option value="skip">Skip 3DS</Select.Option>
        </Select>
      </FormItem>
    </GridItem>
  </Grid>
)
