import { Button, Icon, Stack, Text } from '@gr4vy/poutine-react'
import { TransactionActionOutcome } from 'flows/constants'

type FirstStepProps = {
  selectedConnection: TransactionActionOutcome
  isEditing: boolean
  hidden: boolean
  onClose: () => void
}

export const FinishStep = ({
  selectedConnection,
  isEditing,
  hidden,
  onClose,
}: FirstStepProps) => (
  <Stack gap={32} display={hidden ? 'none' : 'flex'}>
    <Icon name="circle-check" color="green60" size="medium" />
    <Text>
      <Text as="span" fontWeight="bold">
        {selectedConnection.label} connection
      </Text>{' '}
      successfully {isEditing ? 'updated' : 'added'}.
    </Text>
    <Button variant="secondary" onClick={onClose}>
      Close
    </Button>
  </Stack>
)
