import { ReactNode } from 'react'
import { useSearchParams } from 'react-router-dom'
import { HomeFilters } from 'home/constants/filter'
import { useFilters } from 'shared/hooks'
import FilterContext from './FilterContext'

const FilterProvider = ({ children }: { children: ReactNode }) => {
  const [searchParams] = useSearchParams()
  const currentCurrency = searchParams.get('currency')
  const [filters, setFilters] = useFilters<HomeFilters>(
    {
      filterBy: searchParams.get('filterBy') || 'volume',
      period: searchParams.get('period') || '7-days',
      limit: 10,
      ...(currentCurrency && { currency: currentCurrency }),
    },
    { skipLimitStorage: true }
  )

  return (
    <FilterContext.Provider
      value={{
        filters,
        setFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export default FilterProvider
