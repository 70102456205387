import { useQuery } from '@tanstack/react-query'
import { getGiftCardService } from 'connections/services'
import { GIFT_CARD_SERVICES } from 'shared/constants'

export const useGiftCardService = (merchantAccountId?: string, id?: string) => {
  const { data: giftCardService, isLoading } = useQuery({
    queryKey: [GIFT_CARD_SERVICES, merchantAccountId, id],
    queryFn: () => getGiftCardService(id as string),
    refetchOnWindowFocus: false,
    enabled: !!id && !!merchantAccountId,
  })

  return { giftCardService, isLoading }
}
