import { Text, Divider, atoms, TextLink } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { CredentialsChecker } from 'connections/components/CredentialsChecker'
import { validateAntiFraudService } from 'connections/services'
import { CredentialField } from 'shared/components/CredentialField/CredentialField'
import { Label } from 'shared/components/Form'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { ForterEdit } from './ForterEdit'

export const ForterCredentials = () => {
  const { id } = useParams() as { id: string }
  const hasWritePermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )

  return (
    <ForterEdit submitButtonText="Save changes">
      <CredentialField
        item={{ key: 'site_id', displayName: <Label>Site ID</Label> }}
        required
        secret={false}
        disabled={!hasWritePermission}
        group="fields"
        extra={
          <Text as="span" className={atoms({ fontSize: 'xs', paddingTop: 4 })}>
            The{' '}
            <TextLink
              href="https://portal.forter.com/app/psp-experience/settings/general"
              opensInNewWindow
            >
              unique ID for your site
            </TextLink>{' '}
            used to connect to the Forter API.
          </Text>
        }
      />
      <CredentialField
        item={{ key: 'secret_key', displayName: <Label>Secret Key</Label> }}
        required
        secret
        disabled={!hasWritePermission}
        group="fields"
        extra={
          <Text as="span" className={atoms({ fontSize: 'xs', paddingTop: 4 })}>
            The{' '}
            <TextLink
              href="https://portal.forter.com/app/psp-experience/settings/general"
              opensInNewWindow
            >
              secret API key
            </TextLink>{' '}
            used to connect to the Forter API.
          </Text>
        }
      />
      {hasWritePermission && (
        <CredentialsChecker
          fieldNames={[
            ['fields', 'site_id'],
            ['fields', 'secret_key'],
          ]}
          verifyCredentials={validateAntiFraudService}
          antiFraudServiceDefinitionId="forter-anti-fraud"
          antiFraudServiceId={id}
        />
      )}
      <Divider />
    </ForterEdit>
  )
}
