import { useContext, useMemo } from 'react'
import MerchantContext from 'App/contexts/MerchantContext'

interface MerchantAccountDropdownProps {
  canAccessAdminMode: boolean
}

export const useMerchantAccountDropdown = ({
  canAccessAdminMode,
}: MerchantAccountDropdownProps) => {
  const { merchantAccounts } = useContext(MerchantContext)

  const isReadOnly = useMemo(
    () => merchantAccounts.length === 1 && !canAccessAdminMode,
    [canAccessAdminMode, merchantAccounts.length]
  )

  return {
    merchantAccounts,
    isReadOnly,
  }
}
