import { Box, Description, Dropdown, Icon } from '@gr4vy/poutine-react'
import { MerchantProfileSchemeTableData } from 'connections/services'
import { ColumnDef, DataTable, DateTime } from 'shared/components/DataTable'
import { Status } from 'shared/components/Status'

interface PaymentService3DSecureSchemesTableProps {
  configure: CallableFunction
  data: any
  deactivate: CallableFunction
  hasWritePermission: boolean
}

export const PaymentService3DSecureSchemesTable = ({
  configure,
  data,
  deactivate,
  hasWritePermission,
}: PaymentService3DSecureSchemesTableProps) => {
  const columns: Array<ColumnDef<MerchantProfileSchemeTableData>> = [
    {
      header: 'Scheme',
      accessorKey: 'scheme',
      size: 230,
      cell: ({ getValue }) => {
        return getValue<MerchantProfileSchemeTableData['scheme']>().label
      },
    },
    {
      header: 'Activated',
      accessorKey: 'activated',
      cell: ({ getValue }) => {
        const createdAt = getValue<string>()
        return createdAt ? <DateTime value={createdAt} /> : '-'
      },
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: ({ getValue }) => {
        const { id, label } =
          getValue<MerchantProfileSchemeTableData['status']>()
        return (
          <Description>
            <Description.Icon size={24} src={<Status value={id} />} />
            <Description.Text>{label}</Description.Text>
          </Description>
        )
      },
    },
    {
      id: 'actions',
      size: 90,
      header: () => <Box textAlign="center">Actions</Box>,
      cell: function Actions({ row }) {
        const { activated, scheme } = row.original
        return (
          <Box textAlign="center">
            <Dropdown>
              <Dropdown.Trigger asChild>
                <Dropdown.Button variant="tertiary" size="small">
                  <Icon name="more-horizontal" />
                </Dropdown.Button>
              </Dropdown.Trigger>
              <Dropdown.Content align="end">
                <Dropdown.Item
                  onSelect={() => configure(scheme?.id)}
                  opensInModal
                >
                  {!activated ? 'Activate' : 'Update'}
                </Dropdown.Item>
                {activated && hasWritePermission && (
                  <Dropdown.Item
                    onSelect={() => deactivate(scheme?.id)}
                    opensInModal
                  >
                    Deactivate
                  </Dropdown.Item>
                )}
              </Dropdown.Content>
            </Dropdown>
          </Box>
        )
      },
    },
  ]

  return (
    <div style={{ width: '135%' }}>
      <DataTable data={data} columns={columns} />
    </div>
  )
}
