import { Box, Heading, Stack, Text } from '@gr4vy/poutine-react'
import { OutcomeInstrument } from 'flows/constants'
import { getInstrumentDescription } from 'flows/utils/cardOutcome'
import styles from './InstrumentSection.module.scss'

export const InstrumentSection = ({
  instrument,
}: {
  instrument: OutcomeInstrument
}) => {
  return (
    <Stack gap={16}>
      <Heading as="h5" level={5}>
        Instrument selected
      </Heading>
      <Text variant="reg3">
        The instrument defines the way in which we send the card data to the
        connection.
      </Text>
      <Box
        background="white"
        borderColor="gray40"
        borderWidth="sm"
        borderRadius="rounded"
        borderStyle="solid"
        padding={16}
        className={styles.card}
      >
        <Heading as="h5" level={5}>
          {getInstrumentDescription(instrument).title}
        </Heading>
        <Text variant="reg3" color="gray100">
          {getInstrumentDescription(instrument).text}
        </Text>
      </Box>
    </Stack>
  )
}
