import { PropsWithChildren, useEffect } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { SessionOverlay } from 'session/components/SessionOverlay'
import { usePreviousPath } from 'session/hooks/use-previous-path'
import { useSessionManager } from 'session/hooks/use-session-manager'
import SSOCallbackPage from 'session/pages/SSOCallbackPage'
import SSOPage from 'session/pages/SSOPage'
import SetPasswordPage from 'session/pages/SetPasswordPage'
import SignInPage from 'session/pages/SignInPage'
import SignOutPage from 'session/pages/SignOutPage'
import { TokenConfiguration } from 'session/services/sessions'
import { setFailedAuthCallback } from 'shared/services/client'
import { SessionContext } from './SessionContext'

export const SessionProvider = ({
  children,
  token,
  provider = 'gr4vy',
  returnTo,
}: PropsWithChildren<Partial<TokenConfiguration> & { returnTo?: string }>) => {
  const sessionManager = useSessionManager()
  const location = useLocation()
  const { previousPath, setPreviousPath } = usePreviousPath()

  useEffect(() => {
    sessionManager.start({ token, provider })

    // Notify session manager when an auth failure in the client occurs
    setFailedAuthCallback(() => {
      sessionManager.invalidate()
      setPreviousPath()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SessionContext.Provider value={sessionManager}>
      {sessionManager.isLoading && <SessionOverlay />}
      <Routes>
        <Route path="/sso" element={<SSOPage />} />
        <Route path="/callback" element={<SSOCallbackPage />} />
        <Route path="/set-password" element={<SetPasswordPage />} />

        <Route
          path="/sign-in"
          element={
            sessionManager.isSignedIn ? (
              <Navigate
                replace
                to={returnTo || location.state?.previousPath || '/'}
              />
            ) : (
              <SignInPage />
            )
          }
        />
        <Route
          path="/sign-out"
          element={
            sessionManager.isSignedOut ? (
              <Navigate replace to={sessionManager.signInPath} />
            ) : (
              <SignOutPage />
            )
          }
        />

        <Route path="/sso" element={<SSOPage />} />

        <Route
          path="*"
          element={
            sessionManager.isSignedOut ? (
              <Navigate
                replace
                to={sessionManager.signInPath}
                state={{
                  previousPath,
                }}
              />
            ) : sessionManager.isLoading ? (
              <></>
            ) : (
              children
            )
          }
        />
      </Routes>
    </SessionContext.Provider>
  )
}
