import { GridItem } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import { Operation } from 'flows/constants'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'

interface AntiFraudDecisionInputProps {
  name: number
}

const ERROR_VALUE_REQUIRED = 'At least one decision is required'
const ERROR_OPERATOR_REQUIRED = 'Operator is required'
const VALUES: Record<string, string> = {
  accept: 'Accept',
  error: 'Error',
  reject: 'Reject',
  review: 'Review',
  skipped: 'Skipped (unable to get decision)',
  exception: 'Error (network error)',
}

const AntiFraudDecisionInput = ({ name }: AntiFraudDecisionInputProps) => (
  <>
    <GridItem gridColumn="span 2">
      <FormItem
        label={<Label>Operator</Label>}
        name={[name, 'operator']}
        rules={[{ required: true, message: ERROR_OPERATOR_REQUIRED }]}
      >
        <Select placeholder="Select an operator">
          <Select.Option value={Operation.IS_ONE_OF}>includes</Select.Option>
          <Select.Option value={Operation.IS_NOT_ONE_OF}>
            excludes
          </Select.Option>
        </Select>
      </FormItem>
    </GridItem>
    <GridItem gridColumn="span 6">
      <FormItem
        label={<Label>Value(s)</Label>}
        name={[name, 'value']}
        rules={[{ required: true, message: ERROR_VALUE_REQUIRED }]}
      >
        <Select
          mode="multiple"
          allowClear
          maxTagCount="responsive"
          maxTagPlaceholder={maxTagPlaceholderFormatter}
          placeholder="Select an option"
        >
          {Object.keys(VALUES).map((key) => (
            <Select.Option value={key} label={key} key={key}>
              {VALUES[key]}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    </GridItem>
  </>
)

export { ERROR_VALUE_REQUIRED, ERROR_OPERATOR_REQUIRED }
export default AntiFraudDecisionInput
