import { FormInstance } from 'antd'
import { ConditionSelect } from 'flows/components/conditions/ConditionSelect'
import { Condition, RuleCondition, RuleConfigCondition } from 'flows/constants'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { getInitValues, getOptions } from './helpers'

interface AddConditionRowProps {
  form: FormInstance
  conditions: RuleConfigCondition[]
  add: (conditionInitValue: RuleCondition) => void
}

export const AddConditionRow = ({
  form,
  conditions: availableConditions,
  add,
}: AddConditionRowProps) => {
  const conditions = form.getFieldValue('conditions')
  const options = getOptions(null, conditions, availableConditions)

  return (
    <FormItem label={<Label>Add condition</Label>} shouldUpdate>
      {() => (
        <ConditionSelect
          // https://github.com/ant-design/ant-design/issues/21839
          value={null}
          options={options}
          onSelect={(condition: Condition) => {
            add(getInitValues(condition, availableConditions))
          }}
        />
      )}
    </FormItem>
  )
}
