import { useSession } from 'session'
import { User } from 'users/services/users'

export const useLoggedInUser = () => {
  const { user: loggedUser } = useSession()

  const isLoggedInUser = (user: User) =>
    loggedUser && user && loggedUser.id === user.id

  return { isLoggedInUser }
}
