import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { GET_MERCHANT_ACCOUNT } from 'shared/constants/query-keys/merchants'
import { GET_CURRENT_USER } from 'shared/constants/query-keys/users'
import {
  getMerchantAccount,
  updateMerchantAccountName,
  MerchantAccount,
} from 'shared/services/merchant-accounts'
import { showErrorMessage, showSuccessMessage } from 'shared/utils/showMessage'
import View from './EditMerchantAccountPage'

const EditMerchantAccountPage = ({ title }: { title: string }) => {
  const { merchantAccountId } = useParams() as {
    merchantAccountId: string
  }
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery({
    queryKey: [GET_MERCHANT_ACCOUNT, merchantAccountId],
    queryFn: () => getMerchantAccount(merchantAccountId),
    meta: {
      error: {
        redirect: true,
      },
    },
    enabled: !!merchantAccountId,
  })

  const update = useMutation({
    mutationFn: updateMerchantAccountName,
    onSuccess: (merchantAccount) => {
      showSuccessMessage(
        <>
          {merchantAccount.displayName || 'Merchant account name'} successfully
          updated.
        </>
      )

      queryClient.invalidateQueries({ queryKey: [GET_CURRENT_USER] })
      queryClient.invalidateQueries({
        queryKey: [GET_MERCHANT_ACCOUNT, merchantAccountId],
      })
    },
    onError: () =>
      showErrorMessage('Unable to update Merchant details. Please try again.'),
  })

  return (
    <View
      title={title}
      merchantAccount={data || ({ displayName: '...' } as MerchantAccount)}
      loading={isLoading}
      update={update}
    />
  )
}

export default EditMerchantAccountPage
