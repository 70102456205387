import {
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  Box,
  Skeleton,
} from '@gr4vy/poutine-react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'shared/paths/users'
import NewUserPageLayout from 'users/components/NewUserPageLayout/NewUserPageLayout'
import { getRolesAndPermissionsNames } from 'users/helpers'
import { User } from 'users/services/users'

interface UserUpdatedRolesPageProps {
  title: string
  user?: User
}

export const HeaderLoader = (
  <>
    <Skeleton width={32}>
      <Skeleton.Box height={32} />
    </Skeleton>
    <Flex flexDirection="column" gap={4}>
      <Skeleton gap={4}>
        <Skeleton.Box style={{ maxWidth: 320 }} height={24} />
      </Skeleton>
    </Flex>
  </>
)

const InputLoader = (
  <Skeleton width={160} height={20}>
    <Skeleton.Box />
  </Skeleton>
)

export default function UserUpdatedRolesPage({
  title,
  user,
}: UserUpdatedRolesPageProps) {
  const navigate = useNavigate()
  const userRoles = useMemo(() => user?.roles ?? [], [user?.roles])

  return (
    <NewUserPageLayout title={title} step="INVITE">
      {!user ? (
        HeaderLoader
      ) : (
        <>
          <Icon name="circle-check" size="large" color="green60" />
          <Heading as="h4">
            You&apos;ve successfully updated “{user?.name}”.
          </Heading>
        </>
      )}
      <Stack gap={16}>
        <Box>
          <Text variant="reg3" color="black" fontWeight="bold">
            Roles
          </Text>
          <Text variant="reg3" color="gray90">
            {user
              ? getRolesAndPermissionsNames(userRoles).rolesNames
              : InputLoader}
          </Text>
        </Box>
        <Box>
          <Text variant="reg3" color="black" fontWeight="bold">
            Additional permissions
          </Text>
          <Text variant="reg3" color="gray90">
            {user
              ? getRolesAndPermissionsNames(userRoles)
                  .additionalPermissionsNames
              : InputLoader}
          </Text>
        </Box>
      </Stack>
      <Flex gap={16}>
        <Button onClick={() => navigate(pathTo.users)}>See all users</Button>
        {user && (
          <Button
            variant="secondary"
            onClick={() => navigate(pathTo.user(user.id))}
          >
            See user details
          </Button>
        )}
      </Flex>
    </NewUserPageLayout>
  )
}
