import { Box, Heading, Text, Col, Button, Alert } from '@gr4vy/poutine-react'
import { useMutation } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { Helmet } from 'react-helmet'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { SessionPageLayout } from 'session/components/SessionPageLayout'
import { useSession } from 'session/hooks/use-session'
import { setPassword } from 'session/services/sessions'
import { handleSuccessResponse } from 'shared/utils/handleSuccessResponse'

const GenericError = () => (
  <Alert variant="negative" marginBottom={8}>
    <Alert.Icon />
    <Alert.Content>
      <Alert.Text>Something went wrong, please try again</Alert.Text>
    </Alert.Content>
  </Alert>
)

const SetPasswordPage = () => {
  const { user } = useSession()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const resetToken = searchParams.get('resetToken')

  const { mutate, isPending, isError } = useMutation({
    mutationFn: setPassword,
    onSuccess: () => {
      handleSuccessResponse(
        `Your password was successfully ${user ? 'updated' : 'set'}.`,
        () => navigate('/')
      )()
    },
  })

  if (!resetToken) {
    return <Navigate replace to="/" />
  }

  return (
    <>
      <Helmet>
        <title>{`${user ? 'Change' : 'Set'} Password`}</title>
      </Helmet>
      <SessionPageLayout>
        <Col span={4}>
          <Box
            background="white"
            borderRadius="rounded"
            padding={32}
            justifyContent="center"
            display={isPending ? 'none' : 'flex'} // prevents 1password icon remaining
            flexDirection="column"
            gap={16}
          >
            <Heading level={2} as="h1">
              {`${user ? 'Change' : 'Set'} password`}
            </Heading>
            <Text>
              {user
                ? 'Please update your password below.'
                : 'Please set your password below to access your account.'}
            </Text>
            <Form
              onFinish={({ password1 }) => {
                mutate({ password: password1, resetToken })
              }}
              requiredMark={false}
              layout="vertical"
            >
              <Form.ErrorList
                errors={isError ? [<GenericError key="1" />] : []}
              />
              <Form.Item
                label="New password"
                name="password1"
                rules={[
                  { required: true, message: 'Enter your password' },
                  {
                    min: 12,
                    message:
                      'Please set a password of minimum 12 characters to access the dashboard',
                  },
                ]}
              >
                <Input.Password autoComplete="new-password" />
              </Form.Item>
              <Form.Item
                label="Confirm new password"
                name="password2"
                dependencies={['password1']}
                rules={[
                  { required: true, message: 'Enter your password' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password1') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error('The passwords that you entered do not match')
                      )
                    },
                  }),
                ]}
              >
                <Input.Password autoComplete="new-password" />
              </Form.Item>
              <Box>
                <Button variant="primary" type="submit">
                  Set password
                </Button>
              </Box>
            </Form>
          </Box>
        </Col>
      </SessionPageLayout>
    </>
  )
}

export default SetPasswordPage
