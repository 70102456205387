import {
  ButtonLink,
  Stack,
  Text,
  TextLink,
  Tooltip,
} from '@gr4vy/poutine-react'
import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { Icon, IconType } from 'shared/components/Icon'
import { pathTo } from 'shared/paths/connections'

interface TooltipTextProps {
  children: ReactNode
}

interface OutcomeCardWarningProps {
  isHidden?: boolean
  children: ReactNode
  version: 1 | 2
}

const NetworkTokensTooltipContent = () => (
  <>
    <Text>
      This connection will be not be used when it is being triggered, because
      Network Tokens are not active. To learn more read the{' '}
      <TextLink href="https://docs.gr4vy.com" opensInNewWindow>
        Gr4vy Docs
      </TextLink>{' '}
      or contact sales.
    </Text>
    <ButtonLink opensInNewWindow href="https://gr4vy.com/contact">
      Contact sales
    </ButtonLink>
  </>
)

const TooltipText = ({ children }: TooltipTextProps) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  return children === '' ? (
    <NetworkTokensTooltipContent />
  ) : (
    <>
      <Text>{children}</Text>
      <ButtonLink
        opensInNewWindow
        href={pathTo.connectionsConfigured(merchantAccountId)}
      >
        Edit this connection
      </ButtonLink>
    </>
  )
}

const OutcomeWarning = (props: OutcomeCardWarningProps) => (
  <Tooltip
    mode="light"
    content={
      <Stack gap={24} alignItems="flex-end" paddingX={8} paddingY={16}>
        <TooltipText>{props.children}</TooltipText>
      </Stack>
    }
    side="right"
  >
    <span>
      <Icon
        iconType={IconType.Warning}
        color={props.version === 1 && props.isHidden ? 'gray40' : 'amber60'}
        dataTestId="outcome-card-warning"
        style={{ cursor: 'pointer' }}
      />
    </span>
  </Tooltip>
)

export default OutcomeWarning
