import { saveAs } from 'file-saver'

export const saveCsr = (
  filename: string,
  certificateSigningRequest: string
) => {
  const data = new Blob([certificateSigningRequest], {
    type: 'text/plain;charset=utf-8',
  })
  saveAs(data, `${filename}.csr`)
}
