import { Box, Stack, Text } from '@gr4vy/poutine-react'
import { Icon, IconType } from 'shared/components/Icon'
import { Action } from 'shared/constants'
import { Card } from './Card'
import styles from './InfoCard.module.scss'

interface InfoCardProps {
  action: Action
}

const getInfoText = (action: Action) => {
  if (action === Action.selectPaymentOption) {
    return 'If no rules apply, all payment options for the checkout country and currency are displayed.'
  }
  if (action === Action.routeTransaction) {
    return 'If no rules apply, the transaction will be routed to the first configured connection for the checkout currency.'
  }
  if (action === Action.declineEarly) {
    return 'If no rules apply, the transaction will proceed to the next action.'
  }
  if (action === Action.skip3ds) {
    return 'If no rules apply, we will only attempt authentication when the card is enrolled and the connection is enabled for 3DS.'
  }
  return ''
}

export const InfoCard = ({ action }: InfoCardProps) => (
  <Card className={styles.infoCard}>
    <Box padding={16}>
      <Stack gap={16} direction="row">
        <Box width={32} height={32} color="gray80">
          <Icon iconType={IconType.Info} />
        </Box>
        <Text isReadable marginBottom={'none'} alignSelf="center">
          {getInfoText(action)}
        </Text>
      </Stack>
    </Box>
  </Card>
)
