import {
  Alert,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
  TextLink,
  Checkbox,
} from '@gr4vy/poutine-react'
import { NamePath } from 'antd/lib/form/interface'
import { useState } from 'react'
import { AddConnectionForm } from 'connections/components/AddConnectionForm/AddConnectionForm'
import { CountryInput } from 'connections/components/CountryInput/CountryInput'
import { CredentialsChecker } from 'connections/components/CredentialsChecker'
import { CurrencyInput } from 'connections/components/CurrencyInput/CurrencyInput'
import { fieldsObjToArray } from 'connections/helpers/fields'
import {
  PaymentService,
  PaymentServiceDefinition,
  verifyPaymentServiceCredentials,
} from 'connections/services'
import { CredentialField } from 'shared/components/CredentialField/CredentialField'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'

type FormValues = {
  active: boolean
  displayName: string
  acceptedCountries: string[]
  acceptedCurrencies: string[]
  fields: Record<string, any>
}

interface Props {
  definition: PaymentServiceDefinition
  saving: boolean
  onSubmit: (
    body: Pick<
      PaymentService,
      | 'active'
      | 'displayName'
      | 'paymentServiceDefinitionId'
      | 'acceptedCountries'
      | 'acceptedCurrencies'
    > & {
      fields: { key: string; value: string }[]
    }
  ) => void
  onCancel: () => void
}

export const PaymentServiceAddPage = ({
  definition,
  saving,
  onSubmit,
  onCancel,
}: Props) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    active: true,
    displayName: definition.displayName,
    acceptedCountries: definition.supportedCountries,
    acceptedCurrencies: definition.supportedCurrencies,
    fields: {},
  })
  const threeDSecureSupported =
    definition.supportedFeatures.threeDSecurePassThrough

  const handleSubmit = ({
    active,
    displayName,
    acceptedCountries,
    acceptedCurrencies,
    fields,
  }: FormValues) => {
    setInitialValues({
      active,
      displayName,
      acceptedCountries,
      acceptedCurrencies,
      fields,
    })

    onSubmit({
      active,
      displayName,
      paymentServiceDefinitionId: definition.id,
      acceptedCountries: acceptedCountries,
      acceptedCurrencies: acceptedCurrencies,
      fields: fieldsObjToArray(fields),
    })
  }

  return (
    <AddConnectionForm
      saving={saving}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      title="Add new connection"
    >
      <Grid>
        <GridItem gridColumn="span 6">
          <Stack gap={16}>
            <Stack gap={32}>
              {threeDSecureSupported && (
                <Alert variant="information">
                  <Alert.Icon />
                  <Alert.Content>
                    <Alert.Title>3-D Secure</Alert.Title>
                    <Alert.Text>
                      You can configure 3-D Secure after creating this
                      connection. The connection will not process transactions
                      unless you enable it below.
                    </Alert.Text>
                  </Alert.Content>
                </Alert>
              )}
              <Stack gap={8}>
                <Heading level={4}>Credentials</Heading>
                <Text>
                  Learn how to configure {definition.displayName} in{' '}
                  <TextLink
                    href={`https://docs.gr4vy.com/connections/payments/${definition.id}`}
                    opensInNewWindow
                  >
                    this quick start guide.
                  </TextLink>{' '}
                  We will help you find all the information you need to set up
                  your payment service correctly.
                </Text>
              </Stack>
            </Stack>
            <Stack gap={24}>
              {definition.fields.map((item) => (
                <CredentialField
                  key={item.key}
                  item={item}
                  required={item.required}
                  secret={false}
                  group="fields"
                  requiredMessage="This field is required"
                />
              ))}
              {definition.supportedFeatures.verifyCredentials && (
                <CredentialsChecker
                  fieldNames={
                    definition?.fields.map((field) => [
                      'fields',
                      field.key,
                    ]) as NamePath[]
                  }
                  verifyCredentials={verifyPaymentServiceCredentials}
                  paymentServiceDefinitionId={definition.id}
                />
              )}
            </Stack>
          </Stack>
        </GridItem>
      </Grid>
      <Grid>
        <GridItem gridColumn="span 6">
          <Stack gap={16}>
            <Stack gap={8}>
              <Heading level={4}>Region details</Heading>
              <Text>
                Specify the countries and currencies that you know are supported
                by this service for your account. This may depend on your
                contract with this service provider.
              </Text>
            </Stack>
            <Stack gap={24}>
              <CountryInput
                supportedCountries={definition?.supportedCountries}
              />
              <CurrencyInput
                supportedCurrencies={definition?.supportedCurrencies}
              />
            </Stack>
          </Stack>
        </GridItem>
      </Grid>
      <Grid>
        <GridItem gridColumn="span 6">
          <Stack gap={24}>
            <FormItem
              name="active"
              label={<Label>Connection status</Label>}
              valuePropName="checked"
              trigger="onCheckedChange"
            >
              <Checkbox>Active</Checkbox>
            </FormItem>
          </Stack>
        </GridItem>
      </Grid>
    </AddConnectionForm>
  )
}
