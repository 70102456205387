import client, { CollectionResponse } from 'shared/services/client'

export interface ClickToPayFields {
  digitalPaymentApplicationId: string
  digitalPaymentApplicationName: string
}

export interface DigitalWallet {
  id: string
  type: 'digital-wallet'
  merchantName: string | null
  merchantUrl: string | null
  provider: string
  domainNames: Array<string>
  createdAt: string
  activeCertificateCount: number
  pendingCertificateCount: number
  expiredCertificateCount: number
  merchantDisplayName?: string
  merchantCountryCode?: string
  fields?: ClickToPayFields
}

export type DigitalWalletFormValues = {
  merchantName: string
  merchantUrl: string
  acceptTermsAndConditions: boolean
  domainNames: Array<string>
  merchantDisplayName: string
  merchantCountryCode: string
}

export type DigitalWalletEditFormValues = Pick<
  DigitalWalletFormValues,
  | 'merchantName'
  | 'domainNames'
  | 'merchantDisplayName'
  | 'merchantCountryCode'
  | 'merchantUrl'
>

export const getDigitalWallet = async (id: string): Promise<DigitalWallet> => {
  const response = await client.get(`/digital-wallets/${id}`)
  return response.data
}

export const listDigitalWallets = async (): Promise<
  Omit<
    CollectionResponse<DigitalWallet>,
    'limit' | 'nextCursor' | 'previousCursor'
  >
> => {
  const response = await client.get('/digital-wallets')
  return response.data
}

export const registerDigitalWallet = async (data: {
  provider: string
  merchantName: string
  merchantUrl?: string
  domainNames: Array<string>
  acceptTermsAndConditions: boolean
}): Promise<any> => {
  const response = await client.post('/digital-wallets', data)
  return response.data
}

export const updateDigitalWallet = async (
  id: string,
  values: Pick<DigitalWallet, 'merchantName' | 'domainNames'>
): Promise<DigitalWallet> => {
  const response = await client.put(`/digital-wallets/${id}`, values)
  return response.data
}

export const removeDigitalWallet = async (id: string) => {
  await client.delete(`/digital-wallets/${id}`)
}
