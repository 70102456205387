import client, { clientWithoutToken } from 'shared/services/client'

export type Credentials = {
  emailAddress: string
  password: string
}

export type Token = string

export type TokenProvider = 'auth0' | 'gr4vy'

export type TokenConfiguration = {
  token: Token
  provider: TokenProvider
}

export interface Session {
  accessToken: string
  expiresIn: string
  refreshToken: string
  tokenType: string
  type: string
}

export const createSession = async (body: Credentials | TokenConfiguration) => {
  const response = await clientWithoutToken.post<Session>(
    '/auth/sessions',
    body,
    {
      skipAuthRefresh: true,
    } as any
  )
  localStorage.setItem('access_token', response.data.accessToken)
  localStorage.setItem('refresh_token', response.data.refreshToken)
  return response.data
}

export const endSession = async () => {
  const response = await client.delete('/auth/sessions')
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  return response.data
}

export const setPassword = async (body: {
  password: string
  resetToken: string
}) => {
  const response = await clientWithoutToken.post('/users/set-password', body)
  return response.data
}
