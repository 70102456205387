import { useMemo } from 'react'
import { countryCodes } from 'shared/constants'

export const useCountries = (supportedCountries: string[] | undefined) =>
  useMemo(
    () =>
      supportedCountries
        ?.map((value) => ({
          value,
          label: countryCodes[value]?.name as string,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [supportedCountries]
  )
