import { useParams } from 'react-router-dom'
import { LayoutProps, PageLayout } from 'shared/components/PageLayout'
import { pathTo } from 'shared/paths/transactions'
import { usePayoutsFeature } from 'transactions/hooks/use-payouts-feature'

const getTabs = (
  merchantAccountId: string,
  isPayoutsFeatureEnabled: boolean
) => [
  {
    title: 'Payins',
    url: pathTo.transactions(merchantAccountId),
  },
  ...(isPayoutsFeatureEnabled
    ? [
        {
          title: 'Payouts',
          url: pathTo.payouts(merchantAccountId),
        },
      ]
    : []),
]

export const TransactionsPageLayout = ({
  title,
  children,
  ...rest
}: LayoutProps) => {
  const { merchantAccountId } = useParams<{ merchantAccountId: string }>()
  const { isPayoutsFeatureEnabled } = usePayoutsFeature()

  return (
    <PageLayout
      title={title}
      subNavigation={getTabs(
        merchantAccountId as string,
        isPayoutsFeatureEnabled
      )}
      {...rest}
    >
      {children}
    </PageLayout>
  )
}
