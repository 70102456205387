import clsx from 'clsx'
import { ReactNode, CSSProperties } from 'react'
import styles from './Status.module.scss'
import { statusesMap } from './statuses-map'

interface StatusProps {
  value: keyof typeof statusesMap
  children?: ReactNode
}

export const Status = ({ value, children }: StatusProps) => {
  return (
    <span
      className={clsx(styles.dot, styles[statusesMap[value].style])}
      style={
        {
          '--status-dot-color': statusesMap[value].color,
        } as CSSProperties
      }
    >
      {children}
    </span>
  )
}
