import { ColorBlue100 } from '@gr4vy/poutine-tokens'
import { env } from './env'

export const merchantLogo =
  !!env.REACT_APP_MERCHANT_LOGO && env.REACT_APP_MERCHANT_LOGO !== 'false'
    ? env.REACT_APP_MERCHANT_LOGO
    : false

export const merchantFaviconPath =
  !!env.REACT_APP_MERCHANT_FAVICON_PATH &&
  env.REACT_APP_MERCHANT_FAVICON_PATH !== 'false'
    ? `/img/favicons/${env.REACT_APP_MERCHANT_FAVICON_PATH}`
    : '/img/favicons/gr4vy'

export const merchantLogoFooterPosition =
  !!env.REACT_APP_MERCHANT_LOGO_FOOTER_POSITION &&
  env.REACT_APP_MERCHANT_LOGO_FOOTER_POSITION !== 'false'
    ? env.REACT_APP_MERCHANT_LOGO_FOOTER_POSITION
    : false

export const backgroundColor =
  !!env.REACT_APP_MERCHANT_BGCOLOR && env.REACT_APP_MERCHANT_BGCOLOR !== 'false'
    ? env.REACT_APP_MERCHANT_BGCOLOR
    : ColorBlue100
