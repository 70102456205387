export const RESOURCE_NAMES: Record<string, string> = {
  'payment-service': 'Connection',
  'anti-fraud-service': 'Anti-Fraud Service',
  'gift-card-service': 'Gift Card Service',
  'api-key-pair': 'API key pair',
  'apple-pay-certificate': 'Apple Pay Certificate',
  'digital-wallet': 'Digital wallet',
  'role-assignment': 'Role Assignment',
  'merchant-account': 'Merchant Account',
  session: 'Session',
  transaction: 'Transaction',
  refund: 'Refund',
  buyer: 'Buyer',
  rule: 'Flow rule',
}

export const BADGE_COLOURS: Record<string, any> = {
  created: 'positive',
  captured: 'positive',
  deleted: 'negative',
  voided: 'negative',
  updated: 'notice',
}
