import { TextLine } from '@gr4vy/poutine-react'
import { Fragment } from 'react'
import { Summary } from 'shared/components/Summary'
import { deserialize } from 'shared/helpers/serialize'
import { getCodeLanguage, isCensored } from 'transactions/helpers'
import { EventCodeBlock } from '../EventCodeBlock'
import styles from './styles.module.scss'

export const HTTPBody = ({
  code,
  language,
}: {
  code: string
  language?: string
}) => {
  if (!language) {
    language = getCodeLanguage(code)
  }

  if (!language && code && !isCensored(code)) {
    const params = Object.entries(deserialize(code))

    return (
      <Summary inline className={styles.params}>
        {params.map(([key, value]) => (
          <Fragment key={key}>
            <Summary.Key>
              <TextLine fontFamily="mono" variant="med3">
                {`${key}`}
              </TextLine>
            </Summary.Key>
            <Summary.Value>
              <TextLine fontFamily="mono" variant="med3">
                {`${value}`}
              </TextLine>
            </Summary.Value>
          </Fragment>
        ))}
      </Summary>
    )
  }

  if (isCensored(code)) {
    return (
      <TextLine fontFamily="mono" variant="med3" className={styles.params}>
        {code}
      </TextLine>
    )
  }

  if (!code) {
    return <></>
  }

  return <EventCodeBlock code={code} language={language} />
}
