import { Container, Box } from '@gr4vy/poutine-react'
import { UseQueryResult } from '@tanstack/react-query'
import { CollectionResponse } from 'shared/services/client'
import { TransactionFilters } from 'shared/services/transactions'
import { TransactionPageLayout } from 'transactions/components/TransactionPageLayout'
import TransactionTable from 'transactions/components/TransactionTable'
import { Transaction, TransactionSummary } from 'transactions/services'

interface RelatedTransactionsPageViewProps {
  filters: Partial<TransactionFilters>
  page: UseQueryResult<CollectionResponse<TransactionSummary>>
  onFilter: (filter: TransactionFilters) => void
  transaction?: Transaction
}

const RelatedTransactionsPageView = ({
  page,
  filters,
  onFilter,
  transaction,
}: RelatedTransactionsPageViewProps) => (
  <TransactionPageLayout transaction={transaction}>
    <Box paddingY={32}>
      <Container>
        <TransactionTable
          page={page}
          pagination={{
            filters,
            onFilter,
          }}
        />
      </Container>
    </Box>
  </TransactionPageLayout>
)

export default RelatedTransactionsPageView
