import { Rule } from 'flows/constants'
import { Action, Flow } from 'shared/constants'
import { useRules } from './useRules'

export const useSelectedRules = (flow: Flow, outcomeIds: string[]) => {
  const rules = useRules(flow)

  if (rules.isLoading || !rules.isSuccess || outcomeIds.length === 0) {
    return {
      rules: {} as Record<Action, Rule[]>,
    }
  }

  const filteredRules = { ...rules.data }

  Object.keys(filteredRules).forEach((key) => {
    filteredRules[key as Action] = filteredRules[key as Action].filter((r) =>
      outcomeIds.includes((r.outcome.result as string[])[0])
    )
  })

  return {
    rules: filteredRules,
  }
}
