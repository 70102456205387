import { Box } from '@gr4vy/poutine-react'
import { CardNumber } from 'transactions/hooks/use-transaction-payment-methods'
import styles from './MaskedCardNumber.module.scss'
import { PaymentLabel } from './PaymentLabel'

export interface GiftCardMaskedNumberProps {
  cardNumber: Omit<CardNumber, 'mask' | 'prefix'>
}

export const GiftCardMaskedNumber = ({
  cardNumber,
}: GiftCardMaskedNumberProps) => {
  return (
    <Box className={styles.wrapper}>
      {cardNumber.bin && (
        <Box as="span" display="inline" className={styles.number}>
          {cardNumber.bin}
        </Box>
      )}{' '}
      {cardNumber.subBin && (
        <Box as="span" display="inline" className={styles.number}>
          {cardNumber.subBin}
        </Box>
      )}{' '}
      <Box as="span" display="inline" aria-hidden className={styles.mask}>
        ••••••
      </Box>{' '}
      {cardNumber.last4 && <PaymentLabel label={cardNumber.last4} />}
    </Box>
  )
}
