import qs from 'qs'
import { generatePath } from 'react-router-dom'
import { UserTabs } from 'shared/constants'

export const USERS_PATH = '/admin/users'
export const USER_PATH = `${USERS_PATH}/:id`

export const NEW_USER_PATH = `${USERS_PATH}/new`
export const NEW_USER_MERCHANT_ACCOUNT_PATH = `${NEW_USER_PATH}/merchant-account`
export const NEW_USER_ROLES_PATH = `${NEW_USER_PATH}/roles`
export const NEW_USER_PERMISSIONS_PATH = `${NEW_USER_PATH}/permissions`
export const NEW_USER_DETAILS_PATH = `${NEW_USER_PATH}/details`
export const NEW_USER_INVITE_PATH = `${NEW_USER_PATH}/invite/:id`

export const EDIT_USER_NAME_PATH = `${USERS_PATH}/:id/edit-name`
export const EDIT_USER_PATH = `${USER_PATH}/edit`
export const EDIT_USER_MERCHANT_ACCOUNT_PATH = `${USER_PATH}/edit/merchant-account`
export const EDIT_USER_ROLES_PATH = `${USER_PATH}/edit/roles`
export const EDIT_USER_PERMISSIONS_PATH = `${USER_PATH}/edit/permissions`
export const UPDATED_USER_PATH = `${USERS_PATH}/:id/updated`
export const UPDATED_USER_NAME_PATH = `${USERS_PATH}/:id/updated-name`
export const UPDATED_USER_MERCHANTS_PATH = `${USERS_PATH}/:id/updated/merchant-accounts`

export const USER_OVERVIEW_PATH = `${USERS_PATH}/:id/${UserTabs.details}`
export const USERS_ACTIVITY_LOG_PATH = `${USERS_PATH}/activity`

const editUser = {
  name: (id: string) => generatePath(EDIT_USER_NAME_PATH, { id }),
  accessType: (id: string) => generatePath(EDIT_USER_PATH, { id }),
  merchantAccount: (id: string) =>
    generatePath(EDIT_USER_MERCHANT_ACCOUNT_PATH, {
      id,
    }),
  roles: (id: string, merchantAccountIds?: string[]) =>
    `${generatePath(EDIT_USER_ROLES_PATH, { id })}${qs.stringify(
      { merchantAccountIds },
      { addQueryPrefix: true, arrayFormat: 'comma', encode: false }
    )}`,
  permissions: (id: string, roleIds: string[], merchantAccountIds: string[]) =>
    `${generatePath(EDIT_USER_PERMISSIONS_PATH, { id })}${qs.stringify(
      { roleIds, merchantAccountIds },
      { addQueryPrefix: true, arrayFormat: 'comma', encode: false }
    )}`,
  updatedUser: (id: string) => generatePath(UPDATED_USER_PATH, { id }),
  updatedName: (id: string) => generatePath(UPDATED_USER_NAME_PATH, { id }),
  updatedMerchantAccount: (id: string) =>
    generatePath(UPDATED_USER_MERCHANTS_PATH, { id }),
}

const addUser = {
  accessType: NEW_USER_PATH,
  merchantAccount: NEW_USER_MERCHANT_ACCOUNT_PATH,
  roles: (merchantAccountIds?: string[]) =>
    `${NEW_USER_ROLES_PATH}${qs.stringify(
      { merchantAccountIds },
      { addQueryPrefix: true, arrayFormat: 'comma', encode: false }
    )}`,
  permissions: (roleIds: string[], merchantAccountIds: string[]) =>
    `${NEW_USER_PERMISSIONS_PATH}${qs.stringify(
      { roleIds, merchantAccountIds },
      { addQueryPrefix: true, arrayFormat: 'comma', encode: false }
    )}`,
  details: (roleIds: string[], merchantAccountIds: string[]) =>
    `${NEW_USER_DETAILS_PATH}${qs.stringify(
      { roleIds, merchantAccountIds },
      { addQueryPrefix: true, arrayFormat: 'comma', encode: false }
    )}`,
  invite: (id: string, resetToken: string) =>
    `${generatePath(NEW_USER_INVITE_PATH, { id })}?resetToken=${resetToken}`,
}

export const pathTo = {
  users: USERS_PATH,
  usersActivity: USERS_ACTIVITY_LOG_PATH,
  user: (id: string) => generatePath(USER_PATH, { id }),
  userTab: (id: string, tab: UserTabs) => `${pathTo.user(id)}/${tab}`,
  editUser,
  addUser,
}
