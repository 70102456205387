import {
  Button,
  Drawer,
  Flex,
  Heading,
  Skeleton,
  Stack,
  Text,
  TextLink,
  TileGroup,
} from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { MouseEvent, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getUserMerchantAccountIds } from 'shared/helpers/merchant-account'
import { pathTo } from 'shared/paths/users'
import { useMerchantAccounts } from 'shared/permissions/hooks/use-merchant-accounts'
import { useMultiMerchant } from 'shared/permissions/hooks/use-multi-merchant'
import NewUserPageLayout from 'users/components/NewUserPageLayout'
import { errorMessage } from 'users/constants'
import { useRoles } from 'users/hooks/use-roles'
import { UpdateUser, User } from 'users/services/users'

interface UserAccessPageProps {
  title: string
  user?: User
  loading: boolean
  update: UseMutationResult<User, any, UpdateUser, unknown>
}

const AccessLoader = (
  <Skeleton gap={32}>
    {Array.from({ length: 2 }, (_, n) => (
      <Skeleton.Box height={80} key={n} />
    ))}
  </Skeleton>
)

export default function UserAccessPage({
  title,
  user,
  loading,
  update,
}: UserAccessPageProps) {
  const navigate = useNavigate()
  const { administratorRoleId, isAdmin } = useRoles()
  const [searchParams] = useSearchParams()
  const { hasMultipleMerchantAccounts } = useMultiMerchant()
  const [role, setRole] = useState<string>(searchParams.get('role') ?? '')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showAccessTypeDrawer, setShowAccessTypeDrawer] = useState(false)
  const { merchantAccountIds } = useMerchantAccounts()

  const getNextStepPath = () => {
    if (role === 'administrator') {
      return pathTo.addUser.details([administratorRoleId as string], [])
    }

    return hasMultipleMerchantAccounts
      ? pathTo.addUser.merchantAccount
      : pathTo.addUser.roles(merchantAccountIds)
  }

  const handleNextStep = () => {
    if (!role) {
      setShowErrorMessage(true)
      return
    }

    navigate(getNextStepPath())
  }

  const handleUpdateRole = () => {
    if (!user?.id) {
      return
    }

    if (!role) {
      setShowErrorMessage(true)
      return
    }

    if (role === 'nonAdministrator') {
      navigate(pathTo.editUser.roles(user.id, getUserMerchantAccountIds(user)))
      return
    }

    update.mutate(
      {
        id: user.id,
        name: user.name,
        roleIds: [administratorRoleId as string],
        merchantAccountIds: [],
      },
      {
        onSuccess: () => navigate(pathTo.editUser.updatedUser(user.id)),
      }
    )
  }

  const handleShowDrawer = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setShowAccessTypeDrawer(true)
  }

  useEffect(() => {
    if (!!role && showErrorMessage) {
      setShowErrorMessage(false)
    }
  }, [role, showErrorMessage])

  useEffect(() => {
    if (user) {
      const userRole = user.roles.find(
        (userRole) => userRole.name === 'Administrator'
      )
        ? 'administrator'
        : 'nonAdministrator'

      setRole(userRole)
    }
  }, [user])

  return (
    <>
      <NewUserPageLayout
        title={title}
        step={role === 'administrator' ? 'ADMIN_ACCESS' : 'ACCESS'}
      >
        <Stack gap={4}>
          <Heading as="h4">What access will the user have?</Heading>
          <Text variant="reg3" color="gray100">
            Select the type of access to determine what permissions the user
            will have when accessing their Gr4vy account.{' '}
            <TextLink href="#" onClick={handleShowDrawer}>
              Learn more about user roles.
            </TextLink>
          </Text>
        </Stack>
        <Stack gap={16}>
          {loading ? (
            AccessLoader
          ) : (
            <TileGroup
              defaultValue={role}
              onValueChange={(value: string) => setRole(value)}
            >
              <TileGroup.Tile
                disabled={!isAdmin}
                title="Administrator"
                text="This role provides access to all functionality available to users. This is the equivalent of assigning every other role to this user."
                value="administrator"
                checked={role === 'administrator'}
              />
              <TileGroup.Tile
                title="Analyst, Support, and Managers"
                text="These roles have limited access. You can select the specific roles to assign to this user in the following steps."
                value="nonAdministrator"
                checked={role === 'nonAdministrator'}
              />
            </TileGroup>
          )}
          {showErrorMessage && (
            <Text variant="reg4" color="red90">
              {errorMessage.ACCESS}
            </Text>
          )}
        </Stack>
        <Flex gap={16}>
          <Button
            onClick={user ? handleUpdateRole : handleNextStep}
            loading={update?.isPending}
          >
            Continue
          </Button>
          {user && (
            <Button variant="secondary" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          )}
        </Flex>
      </NewUserPageLayout>
      <Drawer
        open={showAccessTypeDrawer}
        title="Access type"
        onClose={() => setShowAccessTypeDrawer(false)}
      >
        <Stack gap={32}>
          <Stack gap={8}>
            <Heading as="h2" level={5}>
              Administrator
            </Heading>
            <Text>
              This role provides access to all functionality available to users.
              This is the equivalent of assigning every other role to this user.
              An administrator has read and write access to all parts of the
              system, including (but not limited to) the ability to see all
              transaction details and perform refunds, configure connections and
              Flow rules, manage API keys, and control the access of admin and
              non-admin users.
            </Text>
          </Stack>
          <Stack gap={8}>
            <Heading as="h2" level={5}>
              Analyst, Support, and Managers
            </Heading>
            <Text>
              These non-administrator roles have limited access to the system.
              Each of these roles has limited read (and optionally write) access
              to specific parts of the system. Multiple roles can be selected
              for a user, allowing you to customize a user&apos;s access to your
              needs. Each specific role and the optional additional permissions
              are detailed in the next steps.
            </Text>
          </Stack>
        </Stack>
      </Drawer>
    </>
  )
}
