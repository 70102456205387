import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { EditForm } from 'connections/pages/AntiFraudServiceEditPage'
import { pathTo } from 'shared/paths/connections'
interface SiftEditProps {
  children: ReactNode
  submitButtonText: string
}

export const ForterEdit = ({ children, submitButtonText }: SiftEditProps) => {
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }

  return (
    <EditForm
      subNavigation={[
        {
          title: 'Overview',
          url: `${pathTo.editConnectionAntiFraudForterService(
            merchantAccountId,
            id
          )}/overview`,
        },
        {
          title: 'Credentials',
          url: `${pathTo.editConnectionAntiFraudForterService(
            merchantAccountId,
            id
          )}/credentials`,
        },
        {
          title: 'Merchant Information',
          url: `${pathTo.editConnectionAntiFraudForterService(
            merchantAccountId,
            id
          )}/merchant-info`,
        },
      ]}
      submitButtonText={submitButtonText}
    >
      {children}
    </EditForm>
  )
}
