import { Navigate, useParams } from 'react-router-dom'
import { BuyerTabs } from 'buyers/constants'
import {
  BuyerOverviewPage,
  BuyerTransactionsPage,
  BuyerPaymentMethodsPage,
} from 'buyers/pages'
import BuyersBrowsePage from 'buyers/pages/BuyersBrowsePage'
import { BUYER_PATH, pathTo, BUYERS_PATH } from 'shared/paths/buyers'
import { Resource } from 'shared/permissions/constants'
import BuyerGiftCardsPage from './pages/BuyerGiftCardsPage'

export default [
  {
    title: 'Buyers',
    longTitle: 'Buyers',
    key: 'buyers',
    path: BUYERS_PATH,
    menu: true,
    component: BuyersBrowsePage,
    resource: Resource.buyers,
  },
  {
    title: 'Buyer',
    longTitle: 'Buyer Overview',
    key: 'buyer-overview',
    path: pathTo.buyerOverviewTab,
    component: BuyerOverviewPage,
    menu: false,
  },
  {
    title: 'Buyer',
    longTitle: 'Buyer Transactions',
    key: 'buyer-transactions',
    path: pathTo.buyerTransactionsTab,
    component: BuyerTransactionsPage,
    menu: false,
  },
  {
    title: 'Buyer',
    longTitle: 'Buyer Payment Methods',
    key: 'buyer-payment-methods',
    path: pathTo.buyerPaymentMethodsTab,
    component: BuyerPaymentMethodsPage,
    menu: false,
  },
  {
    title: 'Buyer',
    longTitle: 'Buyer Gift Cards',
    key: 'buyer-gift-cards',
    path: pathTo.buyerGiftCardsTab,
    component: BuyerGiftCardsPage,
    menu: false,
  },
  {
    title: 'Buyer',
    longTitle: 'Buyer Overview',
    key: 'buyer-overview',
    path: BUYER_PATH,
    menu: false,
    component: function Default() {
      const { id, merchantAccountId } = useParams() as {
        id: string
        merchantAccountId: string
      }
      return (
        <Navigate
          replace
          to={pathTo.buyerTab(merchantAccountId, id, BuyerTabs.overview)}
        />
      )
    },
  },
]
