import {
  Box,
  Button,
  Description,
  Dropdown,
  Heading,
  Icon,
  Stack,
  Text,
} from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { AuthenticationDrawer } from 'admin/components/AuthenticationDrawer'
import { MerchantPagesLayout } from 'admin/components/MerchantsPageLayout'
import { pathTo } from 'admin/paths'
import { DataTable, DateTime } from 'shared/components/DataTable'
import { Loading } from 'shared/components/Loading'
import { ModalRemove } from 'shared/components/Modal/ModalRemove'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { CollectionResponse } from 'shared/services/client'
import { MerchantAccount } from 'shared/services/merchant-accounts'
import {
  AddVaultForwardConfigAuthentication,
  RemoveVaultForwardConfigAuthentication,
  UpdateVaultForwardConfigAuthentication,
  VaultForwardConfig,
  VaultForwardConfigAuthentication,
  VaultForwardDefinition,
} from 'shared/services/vault-forward'

interface EditMerchantAccountVaultForwardingAuthenticationPageProps {
  title: string
  merchantAccount: MerchantAccount | undefined
  config?: VaultForwardConfig
  authentications: Partial<CollectionResponse<VaultForwardConfigAuthentication>>
  definitions?: Partial<CollectionResponse<VaultForwardDefinition>>
  create: UseMutationResult<
    VaultForwardConfigAuthentication,
    any,
    AddVaultForwardConfigAuthentication,
    unknown
  >
  update: UseMutationResult<
    VaultForwardConfigAuthentication,
    any,
    UpdateVaultForwardConfigAuthentication,
    unknown
  >
  remove: UseMutationResult<
    VaultForwardConfigAuthentication,
    any,
    RemoveVaultForwardConfigAuthentication,
    unknown
  >
}

const EditMerchantAccountVaultForwardingAuthenticationPage = ({
  title,
  merchantAccount,
  config,
  authentications,
  definitions,
  create,
  update,
  remove,
}: EditMerchantAccountVaultForwardingAuthenticationPageProps) => {
  const [openAuthenticationDrawer, setOpenAuthenticationDrawer] = useState<{
    open: boolean
    authentication?: VaultForwardConfigAuthentication
  }>({
    open: false,
    authentication: undefined,
  })
  const [removingAuthentication, setRemovingAuthentication] = useState<
    VaultForwardConfigAuthentication | undefined
  >(undefined)
  const hasWritePermission = useResourcePermission(
    Resource.vaultForwardAuthentications,
    AccessLevel.write
  )

  const columns: Array<ColumnDef<VaultForwardConfigAuthentication>> = [
    {
      header: 'Name',
      accessorKey: 'displayName',
      size: 90,
      cell: ({ row }) => <Text truncate>{row.original.displayName}</Text>,
    },
    {
      header: 'Authentication method',
      accessorKey: 'label',
      size: 90,
      cell: ({ row }) => <Text>{row.original.label}</Text>,
    },
    {
      header: 'UUID',
      accessorKey: 'type',
      size: 140,
      cell: ({ row }) => <Text>{row.original.id}</Text>,
    },
    {
      header: 'Creator',
      size: 60,
      cell: ({ row }) => (
        <Description>
          <Description.Text>{row.original.creator.name}</Description.Text>
          <Description.SubText>
            {row.original.creator.emailAddress}
          </Description.SubText>
        </Description>
      ),
    },
    {
      id: 'configured',
      accessorKey: 'createdAt',
      size: 50,
      header: () => <Text>Configured</Text>,
      cell: ({ getValue }) => {
        const createdAt =
          getValue<VaultForwardConfigAuthentication['createdAt']>()
        return <DateTime value={createdAt} />
      },
    },
    {
      id: 'actions',
      accessorKey: 'id',
      size: 45,
      header: () => <Box textAlign="center">Actions</Box>,
      cell: ({ row }) => {
        const authentication = config?.authentications?.find(
          (authentication) => authentication.id === row.original.id
        )

        return (
          <Box textAlign="center">
            <Dropdown>
              <Dropdown.Trigger asChild>
                <Dropdown.Button variant="tertiary" size="small">
                  <Icon name="more-horizontal" />
                </Dropdown.Button>
              </Dropdown.Trigger>
              <Dropdown.Content align="end">
                <Dropdown.Item
                  opensInModal
                  onSelect={() =>
                    setOpenAuthenticationDrawer({
                      open: true,
                      authentication,
                    })
                  }
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  opensInModal
                  onSelect={() => setRemovingAuthentication(authentication)}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Content>
            </Dropdown>
          </Box>
        )
      },
    },
  ]

  useEffect(() => {
    setRemovingAuthentication(undefined)
  }, [remove.isSuccess])

  return (
    <>
      <MerchantPagesLayout
        title={title}
        breadcrumbs={[
          { title: 'Merchants', url: pathTo.merchants },
          { title: merchantAccount?.displayName || '' },
          {
            title: 'Vault Forward',
            url:
              merchantAccount?.id &&
              pathTo.vaultForwarding(merchantAccount?.id),
          },
          { title: config?.displayName || '' },
        ]}
        actions={
          <Button
            disabled={!hasWritePermission}
            variant="primary"
            onClick={() => setOpenAuthenticationDrawer({ open: true })}
          >
            <Icon name="add-plus" />
            Add authentication
          </Button>
        }
      >
        {!config ? (
          <Loading />
        ) : (
          <Stack gap={32}>
            <Heading as="h2">Authentication for {config?.displayName}</Heading>
            <DataTable data={authentications} columns={columns} />
          </Stack>
        )}
      </MerchantPagesLayout>
      <AuthenticationDrawer
        open={openAuthenticationDrawer.open}
        onClose={() =>
          setOpenAuthenticationDrawer({
            open: false,
            authentication: undefined,
          })
        }
        create={create}
        update={update}
        definition={definitions?.items?.find(
          ({ id }) => id === config?.definitionId
        )}
        config={config}
        authentication={openAuthenticationDrawer.authentication}
      />
      {removingAuthentication && (
        <ModalRemove
          title="Do you want to permanently delete this authentication method?"
          okText="Yes, delete"
          loading={remove.isPending}
          loadingText="Deleting..."
          onCancel={() => setRemovingAuthentication(undefined)}
          onOk={() => {
            if (!config || !removingAuthentication?.id) {
              return
            }
            const { merchantAccountId, id } = config
            remove.mutate({
              merchantAccountId,
              id,
              authenticationId: removingAuthentication.id,
            })
          }}
        >
          <Stack gap={16}>
            <Text>
              By permanently deleting this authentication method you will loose
              the ability to use it for the {config?.displayName} endpoints. You
              will not be able to recover this authentication method unless
              re-added manually.
            </Text>
          </Stack>
        </ModalRemove>
      )}
    </>
  )
}

export default EditMerchantAccountVaultForwardingAuthenticationPage
