import { Dropdown, Tooltip } from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { ReactNode, useState } from 'react'
import styles from './Menu.module.scss'

export type MenuProps = {
  tooltipContent: string
  trigger: ReactNode
  triggerClass?: string
  children: ReactNode
}

export const Menu = ({
  tooltipContent,
  trigger,
  triggerClass = '',
  children,
}: MenuProps) => {
  const [open, setOpen] = useState(false)

  return (
    <Dropdown onOpenChange={setOpen}>
      <Tooltip content={tooltipContent} side="bottom">
        <Dropdown.Trigger asChild>
          <button
            className={clsx([
              styles.button,
              triggerClass,
              open && styles.active,
            ])}
          >
            {trigger}
          </button>
        </Dropdown.Trigger>
      </Tooltip>
      <Dropdown.Content
        avoidCollisions={false}
        side="bottom"
        align="end"
        sideOffset={-2}
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        {children}
      </Dropdown.Content>
    </Dropdown>
  )
}
