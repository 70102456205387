export enum FilterRange {
  CUSTOM = 'CUSTOM',
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  THIS_WEEK = 'THIS_WEEK',
  LAST_WEEK = 'LAST_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  THIS_QUARTER = 'THIS_QUARTER',
  LAST_QUARTER = 'LAST_QUARTER',
  THIS_YEAR = 'THIS_YEAR',
}

export const RADIO_FILTER_RANGES = [
  {
    value: FilterRange.CUSTOM,
    label: 'Custom',
  },
  {
    value: FilterRange.TODAY,
    label: 'Today',
  },
  {
    value: FilterRange.YESTERDAY,
    label: 'Yesterday',
  },
  {
    value: FilterRange.THIS_WEEK,
    label: 'This week',
  },
  {
    value: FilterRange.LAST_WEEK,
    label: 'Last week',
  },
  {
    value: FilterRange.THIS_MONTH,
    label: 'This month',
  },
  {
    value: FilterRange.LAST_MONTH,
    label: 'Last month',
  },
  {
    value: FilterRange.THIS_QUARTER,
    label: 'This quarter',
  },
  {
    value: FilterRange.LAST_QUARTER,
    label: 'Last quarter',
  },
  {
    value: FilterRange.THIS_YEAR,
    label: 'This year',
  },
]
