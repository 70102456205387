import { Box } from '@gr4vy/poutine-react'
import { ReactNode } from 'react'

function Content({ children }: { children: ReactNode }) {
  return <Box>{children}</Box>
}

export default Content

Content.displayName = 'Panel.Content'
