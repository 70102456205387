import { Heading, Stack } from '@gr4vy/poutine-react'
import {
  connectionDefinitionCategoryNames,
  CatalogGroup as CatalogGroupDef,
  connectionGroups,
} from 'connections/constants'
import {
  ConnectionDefinition,
  ConnectionDefinitionGroup,
} from 'connections/services'
import { CatalogCategory } from './CatalogCategory'

export const CatalogGroup = ({
  catalogDefinition,
  catalogGroup,
}: {
  catalogDefinition: ConnectionDefinitionGroup
  catalogGroup: CatalogGroupDef
}) => {
  if ((catalogGroup.count ?? 0) === 0) {
    return null
  }

  return (
    <Stack gap={16}>
      <Heading>{catalogGroup.name}</Heading>
      <Stack gap={32}>
        {connectionGroups[catalogDefinition].categories.map((category) => {
          if (!catalogGroup.categories[category]) {
            return null
          }
          return (
            <CatalogCategory
              key={category}
              category={category}
              categoryName={connectionDefinitionCategoryNames[category]}
              connectionDefinitions={
                catalogGroup.categories[category] as ConnectionDefinition[]
              }
            />
          )
        })}
      </Stack>
    </Stack>
  )
}
