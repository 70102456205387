import { useQuery } from '@tanstack/react-query'
import { getGiftCard } from 'gift-cards/services/giftCards'
import { GIFT_CARD } from 'shared/constants'

export const useGiftCard = (id?: string) => {
  const { data: giftCard, isLoading } = useQuery({
    queryFn: () => getGiftCard(id),
    queryKey: [GIFT_CARD, id],
    enabled: !!id,
  })

  return { giftCard, isLoading }
}
