import { currencyToSubunits } from 'shared/utils/currency'
import { is } from './is'

export default (value: any, currency = 'USD', locale = 'en-US') => {
  if (!value || is.emptyStringOrWhitespace(value)) {
    return value
  }
  const { group, decimal } = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })
    .formatToParts(1111.11)
    .reduce(
      (acc, { type, value }) =>
        type === 'group' || type === 'decimal'
          ? { ...acc, [type]: value }
          : acc,
      { group: ' ', decimal: ' ' }
    )
  const reversedValue = value
    .replace(new RegExp(`\\${group}`, 'g'), '')
    .replace(decimal, '.')

  return currencyToSubunits(reversedValue, currency)
}
