import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'shared/paths/errors'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'

export const useHandleErrorResponse = () => {
  const navigate = useNavigate()

  return (error: AxiosError) => {
    if (error.response?.status === 401) {
      return navigate(pathTo.accessDenied)
    }

    if (error.response?.status === 403) {
      return navigate(pathTo.accessDenied)
    }

    if (error.response?.status === 404) {
      return navigate(pathTo.notFound)
    }

    return handleErrorResponse(error)
  }
}
