import { Box, BoxProps } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { useApiKeys } from 'admin/hooks/use-api-keys'
import { useConnections } from 'admin/hooks/use-connections'
import { ApiKeyStep } from './ApiKeyStep'
import { ConnectionStep } from './ConnectionStep'
import { FinishStep } from './FinishStep'
import { IntegrationStep } from './IntegrationStep'

export interface GettingStartedBannerProps extends BoxProps {
  hasTransactions: boolean
}

export const GettingStartedBanner = ({
  hasTransactions,
  ...rest
}: GettingStartedBannerProps) => {
  const { merchantAccountId } = useParams<{
    merchantAccountId: string
  }>()

  const { showConnectionStep, isLoadingConnections } = useConnections({
    merchantAccountId,
    enabled: !hasTransactions,
  })
  const { showApiKeyStep, isLoadingApiKeys } = useApiKeys({
    merchantAccountId,
    enabled: !hasTransactions && !showConnectionStep,
  })
  const showIntegrationStep =
    !hasTransactions && !showConnectionStep && !showApiKeyStep

  const isLoading = isLoadingConnections || isLoadingApiKeys

  return merchantAccountId && !isLoading ? (
    <Box {...rest}>
      {showConnectionStep && (
        <ConnectionStep merchantAccountId={merchantAccountId} />
      )}
      {showApiKeyStep && <ApiKeyStep />}
      {showIntegrationStep && <IntegrationStep />}
      {hasTransactions && <FinishStep />}
    </Box>
  ) : null
}
