import { Flex, Text } from '@gr4vy/poutine-react'
import { ReactNode } from 'react'

interface LabelProps {
  optional?: boolean
  children: ReactNode
}

const Label = ({ optional = false, children }: LabelProps) => (
  <Flex gap={4} alignItems="center">
    <Text fontSize="xs" marginBottom="none" fontWeight="medium" color="gray100">
      {children}
    </Text>
    {optional && (
      <Text fontSize="xs" fontWeight="medium" color="gray70" as="span">
        (optional)
      </Text>
    )}
  </Flex>
)

export default Label
