import { Flex, Tag, Tooltip } from '@gr4vy/poutine-react'
import { MerchantAccount } from 'shared/services/merchant-accounts'

export const MerchantAccountsList = ({
  accounts,
}: {
  accounts?: MerchantAccount[]
}) => {
  if (!accounts?.length) {
    return <>-</>
  }

  const [primaryAccount, ...rest] = accounts

  return (
    <Flex gap={8} alignItems="center">
      {primaryAccount.displayName}
      {!!rest.length && (
        <Tooltip
          content={
            <ul style={{ padding: 0, margin: '8px 16px' }}>
              {rest
                .sort((a, b) => a.displayName.localeCompare(b.displayName))
                .map((r) => (
                  <li key={r.id}>{r.displayName}</li>
                ))}
            </ul>
          }
        >
          <div>
            <Tag variant="subtle" color="neutral">
              +{rest.length} more
            </Tag>
          </div>
        </Tooltip>
      )}
    </Flex>
  )
}
