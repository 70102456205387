import { Heading, Stack, Text } from '@gr4vy/poutine-react'
import { TransactionEvent } from 'transactions/services'

export const ThreeDSecureRequestErrorEvent = ({
  event,
}: {
  event: TransactionEvent
}) => {
  return event ? (
    <Stack gap={32}>
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          URL
        </Heading>
        <Text>{event.context['url']}</Text>
      </Stack>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Reason
        </Heading>
        <Text>{event.context['reason'] || '-'}</Text>
      </Stack>
    </Stack>
  ) : null
}
