import { FilterButton, Flex, Popover, Stack } from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { useKeyboardListNavigation, Filters, useFilters } from 'shared/hooks'
import styles from './ResourceFilter.module.scss'

interface ResourceFilters extends Filters {
  resourceType?: string
}

export const resourceFilters: Record<string, string> = {
  'anti-fraud-service': 'Anti-Fraud Services',
  'api-key-pair': 'API Keys',
  'apple-pay-certificate': 'Apple Pay Certificates',
  buyer: 'Buyers',
  'digital-wallet': 'Digital Wallets',
  'gift-card-service': 'Gift Card Services',
  rule: 'Flow Rules',
  'payment-service': 'Connections',
  refund: 'Refunds',
  'role-assignment': 'Role Assignments',
  'merchant-account': 'Merchant Accounts',
  session: 'Sessions',
  transaction: 'Transactions',
  user: 'Users',
}

export const ResourceFilter = () => {
  const [filters, setFilters] = useFilters<ResourceFilters>()
  const { onKeyDown } = useKeyboardListNavigation()
  const [open, setOpen] = useState(false)

  const resourceFilter = useMemo(() => filters?.resourceType, [filters])

  const onToggleResourceFilter = (resourceType: string) => {
    setFilters({
      ...filters,
      resourceType:
        resourceFilter?.toLowerCase() === resourceType
          ? undefined
          : resourceType,
    })
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <FilterButton data-selected={!!resourceFilter}>
          Resource
          {resourceFilter ? ' / 1' : null}
        </FilterButton>
      </Popover.Trigger>
      <Popover.Content
        align="start"
        side="bottom"
        avoidCollisions={false}
        onKeyDown={onKeyDown}
      >
        <Stack gap={4} className={styles.wrapper} role="listbox">
          {Object.keys(resourceFilters).map((resource) => (
            <Flex
              key={resource}
              alignItems="center"
              className={clsx(
                styles.item,
                resourceFilter === resource && styles.active
              )}
              role="option"
              tabIndex={0}
              onClick={() => onToggleResourceFilter(resource)}
            >
              {resourceFilters[resource]}
            </Flex>
          ))}
        </Stack>
      </Popover.Content>
    </Popover>
  )
}
