import client, { CollectionResponse } from 'shared/services/client'

export interface ApplePayCertificate {
  id: string
  type: 'apple-pay-certificate'
  displayName: string
  status: 'active' | 'expired' | 'incomplete'
  appleMerchantId: string | null
  certificateSigningRequest: string
  publicKeyHash: string
  expiresAt: string
  createdAt: string
  updatedAt: string
}

export const listApplePayCertificates = async (
  params: { cursor?: string; limit?: number; search?: string } = {}
): Promise<CollectionResponse<ApplePayCertificate>> => {
  const response = await client.get('/apple-pay-certificates', { params })
  return response.data
}

export const addApplePayCertificate = async (
  displayName: string
): Promise<ApplePayCertificate> => {
  const { data } = await client.post('/apple-pay-certificates', { displayName })
  return data
}

export const uploadApplePayCertificate = async ({
  id,
  certificate,
}: {
  id: string
  certificate: File
}): Promise<ApplePayCertificate> => {
  const formData = new FormData()
  formData.append('file', certificate)
  const { data } = await client.put(`/apple-pay-certificates/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return data
}

export const removeApplePayCertificate = (id: string) =>
  client.delete(`/apple-pay-certificates/${id}`)
