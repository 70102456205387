import {
  Button,
  Drawer,
  Flex,
  Stack,
  Text,
  TextLink,
  atoms,
} from '@gr4vy/poutine-react'
import { Form, Input } from 'antd'
import { AxiosError } from 'axios'
import { applePayDafErrors } from 'connections/constants'
import { useAddAppleDomain } from 'connections/hooks/use-apple-pay-domain'
import { ApplePayDomain } from 'connections/services/applePayDomains'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { fullyQualifiedDomainNamePattern } from 'shared/constants/regex-patterns'
import { ErrorMessage } from './ErrorMessage'
import { SuccessMessage } from './SuccessMessage'

interface Props {
  open: boolean
  onClose: () => void
  resourceId: string
  domains?: Array<ApplePayDomain>
}

export const AddAppleDomainDrawer = ({
  open,
  onClose,
  resourceId,
  domains,
}: Props) => {
  const [form] = Form.useForm()
  const {
    mutate: add,
    isError,
    isPending: isAdding,
    isSuccess,
    error,
    reset,
  } = useAddAppleDomain()
  const isInlineSubmitError = applePayDafErrors.includes(
    (error as AxiosError<{ message: string }>)?.response?.data.message || ''
  )

  const onSubmit = async () => {
    const values = await form.validateFields()
    add(
      { id: resourceId, domainName: values.domainName },
      {
        onError: (error) => {
          const { message } = (error as AxiosError).response?.data as {
            code: string
            message: string
          }
          form.setFields([
            {
              name: 'domainName',
              errors: [message],
            },
          ])
        },
      }
    )
  }

  const onCancel = () => {
    form.resetFields()
    reset()
    onClose()
  }

  return (
    <Drawer
      open={open}
      title="Add domain"
      footer={
        !isSuccess && (
          <Flex gap={16} justifyContent="flex-end">
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              form="add-domain"
              disabled={isAdding}
              loading={isAdding}
              loadingText="Validating domain"
            >
              Add domain
            </Button>
          </Flex>
        )
      }
      onClose={onCancel}
    >
      <Stack gap={16}>
        {(!isError || isInlineSubmitError) && !isSuccess && (
          <>
            <Stack gap={24}>
              <Text lineHeight={20}>
                Before you can add a domain to Apple Pay you will need to
                download the{' '}
                <TextLink
                  href="/.well-known/apple-developer-merchantid-domain-association"
                  opensInNewWindow
                >
                  Domain Association File
                </TextLink>{' '}
                and host the file at{' '}
                <code>
                  {'{domain}'}
                  .well-known/apple-developer-merchantid-domain-association
                </code>{' '}
                for the domain you are adding below.
              </Text>
              <Text lineHeight={20}>
                You must register and verify all top-level domains and
                subdomains where you will display the Apple Pay button.
              </Text>
            </Stack>
            <Stack gap={24}>
              <Form
                name="add-domain"
                form={form}
                layout="vertical"
                requiredMark={false}
                onFinish={onSubmit}
              >
                <FormItem
                  name="domainName"
                  label={<Label>Domain name</Label>}
                  colon={false}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Enter a domain name',
                    },
                    {
                      validator: (_, value) => {
                        if (
                          domains?.filter((d) => d.displayName === value).length
                        ) {
                          return Promise.reject('Domain is already registered')
                        }
                        if (
                          value &&
                          !new RegExp(
                            fullyQualifiedDomainNamePattern,
                            'i'
                          ).test(value)
                        ) {
                          return Promise.reject(
                            'This field must be a valid fully qualified domain name'
                          )
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}
                  extra={
                    <Text
                      as="span"
                      className={atoms({
                        fontSize: 'xs',
                        paddingTop: 8,
                        display: 'block',
                      })}
                    >
                      A fully qualified domain name in which you will use Gr4vy
                      Embed with Apple Pay transactions.
                    </Text>
                  }
                >
                  <Input maxLength={100} />
                </FormItem>
              </Form>
            </Stack>
          </>
        )}
        {isSuccess && <SuccessMessage onClose={onCancel} />}
        {isError && !isInlineSubmitError && <ErrorMessage onClose={onCancel} />}
      </Stack>
    </Drawer>
  )
}
