import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import {
  getDownloadReportExecutionUrl,
  getReportExecution,
  getReportExecutions,
  ReportExecution,
  ReportExecutionsFilters,
} from 'reports/services/report-execution'
import { REPORT_EXECUTIONS } from 'shared/constants/query-keys'
import { useHandleErrorResponse } from 'shared/hooks'
import { CollectionResponse } from 'shared/services/client'

const useReportExecutions = (
  params: Partial<ReportExecutionsFilters>,
  options: Pick<
    UseQueryOptions<CollectionResponse<ReportExecution>>,
    'refetchInterval' | 'enabled'
  >
) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }

  return useQuery({
    queryKey: [REPORT_EXECUTIONS, merchantAccountId, params],
    queryFn: () => getReportExecutions(params),
    meta: {
      error: {
        redirect: true,
      },
    },
    ...options,
  })
}

const useReportExecution = (merchantAccountId: string, id: string) =>
  useQuery({
    queryKey: [REPORT_EXECUTIONS, id, merchantAccountId],
    queryFn: () => getReportExecution({ id }),
    meta: {
      error: {
        redirect: true,
      },
    },
  })

const useDownloadExecutionUrl = () => {
  const handleErrorResponse = useHandleErrorResponse()
  return useMutation({
    mutationFn: ({
      reportId,
      executionId,
    }: {
      reportId: string
      executionId: string
    }) => getDownloadReportExecutionUrl({ reportId, executionId }),
    onError: handleErrorResponse,
  })
}

export { useReportExecutions, useReportExecution, useDownloadExecutionUrl }
