import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AntiFraudService, getAntiFraudService } from 'connections/services'
import { ANTI_FRAUD_SERVICES } from 'shared/constants'

export const useAntiFraudService = (
  merchantAccountId: string,
  id: string,
  options: Pick<UseQueryOptions<AntiFraudService>, 'meta'>
) => {
  const { data, isLoading } = useQuery({
    queryKey: [ANTI_FRAUD_SERVICES, merchantAccountId, id],
    queryFn: () => getAntiFraudService(id),
    refetchOnWindowFocus: false,
    ...options,
  })

  return { data, isLoading }
}
