import client, { CollectionResponse } from 'shared/services/client'
import { MerchantAccount } from 'shared/services/merchant-accounts'

export interface ApiKeyPairCreator {
  id: string
  emailAddress: string
  name: string
}

export interface ApiKeyPair {
  type: 'api-key-pair'
  id: string
  displayName: string
  privateKey: string
  thumbprint: string
  algorithm: string
  creator?: ApiKeyPairCreator
  createdAt: string
  updatedAt: string
  merchantAccount: MerchantAccount | null
}

export type NewApiKeyPair = Pick<ApiKeyPair, 'displayName' | 'algorithm'> & {
  merchantAccountId: string | null
  roleId: string
}

export type UpdateApiKeyPair = Pick<ApiKeyPair, 'id' | 'displayName'>

export const listApiKeyPairs = async (
  params: { cursor?: string; limit?: number } = {}
): Promise<CollectionResponse<ApiKeyPair>> => {
  const response = await client.get('/api-key-pairs', { params })
  return response.data
}

export const addApiKeyPair = async ({
  displayName,
  algorithm,
  merchantAccountId,
  roleId,
}: NewApiKeyPair): Promise<ApiKeyPair> => {
  const response = await client.post('/api-key-pairs', {
    displayName,
    algorithm,
    merchantAccountId,
    roleIds: [roleId],
  })
  return response.data
}

export const updateApiKeyPair = async ({
  id,
  displayName,
}: UpdateApiKeyPair): Promise<ApiKeyPair> => {
  const response = await client.put(`/api-key-pairs/${id}`, { displayName })
  return response.data
}

export const removeApiKeyPair = async (id: string) => {
  await client.delete(`/api-key-pairs/${id}`)
}
