import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { PAYOUTS } from 'shared/constants/query-keys'
import { is } from 'shared/helpers/is'
import { useCursor, useFilters } from 'shared/hooks'
import { listPayouts, PayoutFilters } from 'transactions/services/payouts'
import View from './PayoutsBrowsePage'

interface PayoutsBrowsePageProps {
  title: string
}

const PayoutsBrowsePage = ({ title }: PayoutsBrowsePageProps) => {
  const { merchantAccountId } = useParams<{ merchantAccountId: string }>()
  const [filters, setFilters] = useFilters<PayoutFilters>({})
  const [cursor] = useCursor()

  const page = useQuery({
    queryKey: [PAYOUTS, merchantAccountId, cursor, filters],
    queryFn: () => listPayouts({ cursor, ...filters }),
    meta: {
      error: {
        redirect: true,
      },
    },
    enabled: !is.emptyObject(filters),
  })

  const updateFilter = (f: PayoutFilters) => {
    setFilters({ ...filters, ...f })
  }

  return (
    <View title={title} page={page} filters={filters} onFilter={updateFilter} />
  )
}

export default PayoutsBrowsePage
