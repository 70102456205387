import { useMutation } from '@tanstack/react-query'
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { RuleConfig } from 'flows/constants'
import { useOutcomes } from 'flows/hooks/useOutcomes'
import { useRuleConfig } from 'flows/hooks/useRuleConfig'
import RulePage from 'flows/pages/RulePage/RulePage'
import { addRule } from 'flows/services'
import { Loading } from 'shared/components/Loading'
import { Action, Flow } from 'shared/constants/flow'
import { pathTo } from 'shared/paths/flows'
import { showErrorMessage } from 'shared/utils/showMessage'

type AddRulePageProps = {
  title: string
}

const AddRulePage = ({ title }: AddRulePageProps) => {
  const { flow, action, merchantAccountId } = useParams() as {
    flow: Flow
    action: Action
    merchantAccountId: string
  }
  const [searchParams] = useSearchParams()
  const flowUrl = pathTo.flow(merchantAccountId, flow, searchParams)
  const navigate = useNavigate()
  const ruleConfig = useRuleConfig(flow, action)
  const outcomesQuery = useOutcomes({
    merchantAccountId,
    flow,
    action,
    outcome: ruleConfig?.data?.outcome,
    queryParams: searchParams,
  })

  const onSaveRule = useMutation({
    mutationFn: addRule,
    onSuccess: (data) => {
      navigate(flowUrl, { replace: true, state: { newRuleId: data.id } })
    },
    onError: () => {
      showErrorMessage('This rule could not be saved')
    },
  })
  const onCancel = () => {
    navigate(flowUrl, { replace: true })
  }

  if (ruleConfig.isLoading || outcomesQuery.isLoading) {
    return <Loading />
  }

  if (!outcomesQuery.isSuccess) {
    showErrorMessage('Failed to load rule outcomes, please try again')
    return <Navigate replace to={flowUrl} />
  }

  return (
    <RulePage
      flow={flow}
      action={action}
      title={title}
      config={ruleConfig.data as RuleConfig}
      outcomes={outcomesQuery.data.items}
      onSaveRule={onSaveRule.mutate}
      isSaving={onSaveRule.isPending}
      onCancelRule={onCancel}
      searchParams={searchParams}
      merchantAccountId={merchantAccountId}
    />
  )
}

export default AddRulePage
