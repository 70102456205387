import { Stack } from '@gr4vy/poutine-react'
import { ReactElement } from 'react'
import { Summary } from 'shared/components/Summary'
import { SummaryGrid } from 'shared/components/SummaryGrid'
import { SummaryHeading } from 'shared/components/SummaryHeading'

interface SummaryGroupProps {
  title: string
  gap?: 24 | 32
  children?:
    | ReactElement<typeof SummaryGrid>
    | ReactElement<typeof Summary>
    | false
    | null
}

export const SummaryGroup = ({
  title,
  children,
  gap = 32,
}: SummaryGroupProps) => (
  <Stack gap={gap}>
    <SummaryHeading>{title}</SummaryHeading>
    {children}
  </Stack>
)
