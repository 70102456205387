import { atoms } from '@gr4vy/poutine-react'
import * as RadixTabs from '@radix-ui/react-tabs'
import clsx from 'clsx'
import { PropsWithChildren } from 'react'

export type ContentProps = PropsWithChildren<RadixTabs.TabsContentProps>

export const Content = ({ children, className, ...rest }: ContentProps) => {
  return (
    <RadixTabs.Content
      className={clsx(atoms({ width: 'full' }), className)}
      {...rest}
    >
      {children}
    </RadixTabs.Content>
  )
}
