import { Button, Grid, GridItem, Stack } from '@gr4vy/poutine-react'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { uniq } from 'lodash'
import { SyntheticEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApplePayActions } from 'connections/components/ApplePayActions'
import { MerchantNameInput } from 'connections/components/DigitalWalletForm'
import { useDigitalWallet } from 'connections/hooks/use-digital-wallet'
import {
  DigitalWalletEditFormValues as FormValues,
  getDigitalWallet,
} from 'connections/services'
import { FormPromptUnsavedChanges } from 'shared/components/FormPromptUnsavedChanges'
import { Loading } from 'shared/components/Loading'
import { DIGITAL_WALLET } from 'shared/constants'
import { host } from 'shared/helpers/get-environment'
import { pathTo } from 'shared/paths/connections'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { showErrorMessage, showSuccessMessage } from 'shared/utils/showMessage'
import { ApplePayLayout } from './ApplePayLayout'

interface Props {
  title: string
}

export const ApplePayOverview = ({ title }: Props) => {
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }
  const navigate = useNavigate()

  const hasWritePermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )
  const {
    data: digitalWallet,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: [DIGITAL_WALLET, id, merchantAccountId],
    queryFn: () => getDigitalWallet(id),
    meta: {
      error: {
        redirect: true,
      },
    },
  })
  const { update, remove } = useDigitalWallet(merchantAccountId)
  const [form] = Form.useForm<FormValues>()
  const [initialValues, setInitialValues] =
    useState<Partial<FormValues> | null>()

  const handleSubmit = (values: FormValues) => {
    form.validateFields()
    const domainNames = digitalWallet?.domainNames || []
    values.domainNames = uniq([host, ...domainNames])
    setInitialValues(values)
    update.mutate(
      { id, values },
      {
        onSuccess: () => {
          showSuccessMessage(
            <>
              <b>Apple Pay</b> has been updated
            </>
          )
          navigate(pathTo.connectionsConfigured(merchantAccountId))
        },
      }
    )
  }

  const onFinishFailed = () => {
    showErrorMessage('Please check the form for any errors before continuing.')
  }

  useEffect(() => {
    setInitialValues({
      merchantName: digitalWallet?.merchantName || '',
      domainNames: digitalWallet?.domainNames.filter(
        (dn) => ![host].includes(dn)
      ),
    })
  }, [
    digitalWallet?.id,
    digitalWallet?.merchantName,
    digitalWallet?.domainNames,
  ])

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault()
    navigate(pathTo.connectionsConfigured(merchantAccountId), { replace: true })
  }

  const onDelete = () => {
    setInitialValues(form.getFieldsValue())
    remove.mutate(id, {
      onSuccess: () => {
        navigate(pathTo.connectionsConfigured(merchantAccountId))
      },
    })
  }

  if (isLoading || !initialValues || !initialValues.domainNames) {
    return <Loading />
  }

  return (
    <ApplePayLayout
      title={title}
      isLoading={!isSuccess}
      actions={
        <ApplePayActions
          activeCertificateCount={digitalWallet?.activeCertificateCount}
          pendingCertificateCount={digitalWallet?.pendingCertificateCount}
          expiredCertificateCount={digitalWallet?.expiredCertificateCount}
          onViewCertificates={() =>
            navigate(
              pathTo.editConnectionApplePayCertificates(merchantAccountId, id)
            )
          }
          onDelete={onDelete}
          isReadOnlyPermission={!hasWritePermission}
        />
      }
    >
      <Form
        name="connection"
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        scrollToFirstError={{
          block: 'center',
        }}
        onFinishFailed={onFinishFailed}
        disabled={!hasWritePermission}
      >
        <Stack paddingTop={8} gap={40}>
          <Grid>
            <GridItem gridColumn="span 6">
              <Stack gap={24}>
                <MerchantNameInput />
              </Stack>
            </GridItem>
          </Grid>
          {hasWritePermission ? (
            <Stack direction="row" gap={16}>
              <Button
                loading={update.isPending}
                loadingText="Saving connection"
                variant="primary"
              >
                Save changes
              </Button>
            </Stack>
          ) : (
            <Stack direction="row" gap={16}>
              <Button variant="secondary" onClick={handleCancel}>
                Back to connections
              </Button>
            </Stack>
          )}
        </Stack>
        <FormPromptUnsavedChanges form={form} initialValues={initialValues} />
      </Form>
    </ApplePayLayout>
  )
}
