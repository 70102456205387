import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import { addUser } from 'users/services/users'
import View from './UserDetailsPage'

const UserDetailsPage = ({ title }: { title: string }) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }

  const onAdd = useMutation({
    mutationFn: addUser,
    onError: handleErrorResponse,
  })

  return (
    <View title={title} add={onAdd} merchantAccountId={merchantAccountId} />
  )
}

export default UserDetailsPage
