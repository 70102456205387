import { Action, Flow } from 'shared/constants'

export function componentConfigSelector<T>(
  flow: Flow,
  action: Action | string,
  config: {
    [key: string]: {
      [key: string]: T
    }
  }
): T | undefined {
  const flowConfig = config[flow]

  if (!flowConfig) {
    return undefined
  }

  return flowConfig[action]
}
