import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { LIST_CARD_SCHEME_DEFINITIONS } from 'shared/constants'
import {
  listCardSchemeDefinitions,
  CardSchemeDefinition,
} from 'shared/services'

export const useCardSchemeDefinitions = (enabled?: boolean) => {
  const definitionsQuery = useQuery({
    queryKey: [LIST_CARD_SCHEME_DEFINITIONS],
    queryFn: () => listCardSchemeDefinitions(),
    gcTime: 1000 * 60 * 15,
    staleTime: 1000 * 60 * 15,
    enabled,
  })

  return useMemo(
    () =>
      (definitionsQuery.data?.items ?? []).reduce(
        (prev, curr) => ({
          ...prev,
          [curr.id]: curr,
        }),
        {}
      ) as Record<string, CardSchemeDefinition>,
    [definitionsQuery.data]
  )
}
