import { Button, Box, Flex, Modal, Text } from '@gr4vy/poutine-react'
import currencyFormat from 'shared/helpers/currency-format'
import { Transaction } from 'transactions/services'

interface ModalTransactionVoidProps {
  loading: boolean
  transaction: Transaction
  onVoid: () => void
  onClose: () => void
}

const ModalTransactionVoid = ({
  transaction,
  loading,
  onVoid,
  onClose,
}: ModalTransactionVoidProps) => {
  return (
    <Modal
      id="void"
      type="negative"
      title={`Void transaction amount ${currencyFormat(
        transaction.authorizedAmount,
        {
          currency: transaction.currency,
        }
      )}`}
      open
      onOpenChange={(status) => {
        !status && onClose()
      }}
      closeBtn={false}
    >
      <Text as="div">
        All voids are for the full authorized amount only. You can only void a
        transaction once.
      </Text>
      <Box display="inline-flex" gap={4}>
        <Text as="span" fontWeight="bold">
          Warning:
        </Text>
        <Text as="span">This action can not be undone.</Text>
      </Box>
      <Flex justifyContent="flex-end" gap={16}>
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          variant="primaryNegative"
          onClick={onVoid}
          loading={loading}
          loadingText="Voiding"
        >
          Void
        </Button>
      </Flex>
    </Modal>
  )
}

export default ModalTransactionVoid
