import { Box, Stack } from '@gr4vy/poutine-react'
import { Input } from 'antd'
import React from 'react'
import { Icon, IconType } from 'shared/components/Icon'
import styles from './PageTitleInput.module.scss'

interface InputProps {
  value?: string
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const PageTitleInput = ({ value = '', onChange, ...props }: InputProps) => (
  <Stack
    gap={16}
    direction="row"
    alignItems="center"
    justifyContent="flex-start"
  >
    <Box width={32}>
      <Icon iconType={IconType.Edit} as="span" color="blue60" />
    </Box>
    <Input
      {...props}
      variant="borderless"
      className={styles.input}
      value={value}
      onChange={onChange}
    />
  </Stack>
)

export default PageTitleInput
