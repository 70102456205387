import { Badge, Heading, Stack } from '@gr4vy/poutine-react'
import { TransactionEvent } from 'transactions/services'
import { RequestEvent } from './RequestEvent'

export const ThreeDSecurePreparationRequestEvent = ({
  event,
}: {
  event?: TransactionEvent
}) => {
  return event ? (
    <Stack gap={32}>
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Result
        </Heading>
        <Stack gap={8} alignItems="flex-start" direction="row">
          <Badge variant="subtle" color="neutral">
            {event.context['version']}
          </Badge>
          <Badge
            variant="subtle"
            color={event.context['isEnrolled'] ? 'positive' : 'negative'}
          >
            {event.context['isEnrolled'] ? 'Enrolled' : 'Not enrolled'}
          </Badge>
        </Stack>
      </Stack>
      <RequestEvent event={event} />
    </Stack>
  ) : null
}
