import { Description } from '@gr4vy/poutine-react'
import { PaymentMethod } from 'buyers/services/paymentMethods'
import { Status } from 'shared/components/Status'

const STATUS_LABELS: Record<string, string> = {
  processing: 'Pending',
  // eslint-disable-next-line camelcase
  buyer_approval_required: 'Authenticating',
  succeeded: 'Ready',
  failed: 'Failed',
}
export interface PaymentMethodStatusProps {
  paymentMethod: Pick<PaymentMethod, 'status'>
}

export const PaymentMethodStatus = ({
  paymentMethod,
}: PaymentMethodStatusProps) => {
  const statusLabel =
    STATUS_LABELS[paymentMethod.status] ?? paymentMethod.status
  return (
    <Description>
      <Description.Icon
        size={24}
        src={<Status value={paymentMethod.status} />}
      />
      <Description.Text>{statusLabel}</Description.Text>
    </Description>
  )
}
