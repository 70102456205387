import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export type Cursor = string | undefined

export const useCursor = (initialCursor: Cursor = undefined) => {
  const [params, setParams] = useSearchParams()
  const [cursor, setStateCursor] = useState<Cursor>(initialCursor)

  const setCursor = (newCursor: string) => {
    params.set('cursor', newCursor)
    setParams(params)
    setStateCursor(newCursor)
  }

  useEffect(() => {
    if (cursor && params.get('cursor') !== cursor) {
      setStateCursor(params.get('cursor') || undefined)
    }
  }, [cursor, params])

  return [cursor, setCursor] as const
}
