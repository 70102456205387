import { FilterButton, Flex, Popover, Stack } from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { useKeyboardListNavigation, Filters, useFilters } from 'shared/hooks'
import styles from './StatusFilter.module.scss'

interface StatusFilters extends Filters {
  status?: string
}

const statusOptions = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'pending',
    label: 'Invited',
  },
]

export const StatusFilter = () => {
  const [filters, setFilters] = useFilters<StatusFilters>()
  const { onKeyDown } = useKeyboardListNavigation()
  const [open, setOpen] = useState(false)

  const statusFilter = useMemo(
    () => (filters as StatusFilters)?.status,
    [filters]
  )

  const onToggleStatusFilter = (status: string) => {
    setFilters({
      ...filters,
      status: statusFilter?.toLowerCase() === status ? undefined : status,
    })
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <FilterButton data-selected={!!statusFilter}>
          Status
          {statusFilter ? ' / 1' : null}
        </FilterButton>
      </Popover.Trigger>
      <Popover.Content
        align="start"
        side="bottom"
        avoidCollisions={false}
        onKeyDown={onKeyDown}
      >
        <Stack gap={4} className={styles.wrapper} role="listbox">
          {statusOptions.map((status) => (
            <Flex
              key={status.value}
              alignItems="center"
              className={clsx(
                styles.item,
                statusFilter === status.value && styles.active
              )}
              role="option"
              tabIndex={0}
              onClick={() => onToggleStatusFilter(status.value)}
            >
              {status.label}
            </Flex>
          ))}
        </Stack>
      </Popover.Content>
    </Popover>
  )
}
