import { AccessLevel, Resource } from 'shared/permissions/constants'
import { useScopes } from './use-scopes'

export const useResourcePermissions = (
  resources: Resource[],
  accessLevel: AccessLevel
) => {
  const userScopes = useScopes()

  return resources.map(
    (resource) =>
      userScopes[accessLevel].includes(resource) ||
      userScopes[accessLevel].includes(Resource.all)
  )
}
