import { Box } from '@gr4vy/poutine-react'
import { ComponentProps, forwardRef, ReactNode } from 'react'
import { Card } from 'flows/components/Card/Card'
import { Item } from './Item'

type OutcomeProps = ComponentProps<'div'> & {
  className: string
  children: ReactNode
  isDragging: boolean
}

const OutcomeWrapper = forwardRef<HTMLDivElement, OutcomeProps>(
  function OutcomeWrapper({ className, children, isDragging, ...rest }, ref) {
    return (
      <div ref={ref} {...rest}>
        <Card className={className} data-dragging={isDragging}>
          <Box paddingX={16} paddingY={8}>
            {children}
          </Box>
        </Card>
      </div>
    )
  }
)

export const Outcome = Object.assign({}, OutcomeWrapper, { Item })
