import { ButtonHTMLAttributes } from 'react'
import styles from './Button.module.scss'

export const QuickFilterButton = ({
  children,
  ...rest
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button {...rest} className={styles.quickFilterButton}>
      {children && <span>{children}</span>}
    </button>
  )
}
