import client, { CollectionResponse } from 'shared/services/client'

interface PaymentServiceField {
  key: string
  value: string
}

export enum CardSchemes {
  amex = 'American Express',
  discover = 'Discover',
  mastercard = 'MasterCard',
  visa = 'VISA',
  jcb = 'JCB',
  dankort = 'Dankort/FBF',
  unionpay = 'UnionPay International',
}

export type MerchantProfileScheme = keyof typeof CardSchemes

interface MerchantProfile {
  createdAt: string
  merchantAcquirerBin: string
  merchantAcquirerId: string
  merchantCategoryCode: string
  merchantCountryCode: string
  merchantName: string
  merchantUrl: string
}

export type MerchantProfileSchemeStatus = 'active' | 'inactive'

export interface MerchantProfileSchemeTableData {
  scheme: { id: string; label: string }
  activated: string
  status: { id: MerchantProfileSchemeStatus; label: string }
}

export interface PaymentService {
  id: string
  type: 'payment-service'
  paymentServiceDefinitionId: string
  method: 'card' | 'paypal' | 'banked'
  displayName: string | null
  status: 'pending' | 'created' | 'failed'
  acceptedCurrencies: string[]
  acceptedCountries: string[]
  active: boolean
  position: number
  threeDSecureEnabled: boolean
  acquirerBinVisa: string | null
  acquirerBinMastercard: string | null
  acquirerBinAmex: string | null
  acquirerBinDiscover: string | null
  acquirerMerchantId: string | null
  merchantName: string | null
  merchantCountryCode: string | null
  merchantCategoryCode: string | null
  merchantUrl: string | null
  networkTokensEnabled: boolean | null
  openLoop: boolean
  paymentMethodTokenizationEnabled: boolean | null
  webhookUrl: string
  fields?: PaymentServiceField[]
  isDeleted: boolean
  merchantProfile: Record<MerchantProfileScheme, MerchantProfile | null> | null
  settlementReportingEnabled: boolean
}

export interface PaymentServiceUpdate
  extends Partial<
    Pick<
      PaymentService,
      | 'networkTokensEnabled'
      | 'paymentMethodTokenizationEnabled'
      | 'threeDSecureEnabled'
      | 'openLoop'
    >
  > {
  id: string
  paymentServiceDefinitionId?: string
  fields?: PaymentServiceField[]
  active?: boolean
  displayName?: string | null
  merchantProfile?: Record<any, any>
}

export interface Connection {
  id: string
  type: 'connection'
  name: string
  active: boolean
  definition: ConnectionDefinition
}

export enum ConnectionDefinitionGroup {
  cards = 'cards',
  nonCards = 'nonCards',
  other = 'other',
}

export enum ConnectionDefinitionCategory {
  card = 'card',
  bank = 'bank',
  bnpl = 'bnpl',
  wallet = 'wallet',
  digitalWallet = 'digital-wallet',
  cash = 'cash',
  antiFraud = 'anti-fraud',
  storedCredit = 'stored-credit',
  crypto = 'crypto',
  tokenization = 'tokenization',
}

export interface ConnectionDefinition {
  id: string
  type: 'connection-definition'
  name: string
  category: ConnectionDefinitionCategory
  count: number
  group: ConnectionDefinitionGroup
  iconUrl: string
  provider: string
}

export interface VerifyConnectionCredentials {
  fields: Array<Record<string, string>>
  paymentServiceId?: string
  paymentServiceDefinitionId?: string
}

export const listPaymentServices = async (
  params = {}
): Promise<CollectionResponse<PaymentService>> => {
  const response = await client.get('/payment-services', { params })
  return response.data
}

export const getPaymentService = async (
  id: string,
  params = {}
): Promise<PaymentService> => {
  const response = await client.get(`/payment-services/${id}`, { params })
  return response.data
}

export const addPaymentService = async (
  resource: Omit<PaymentService, 'createdAt' | 'updatedAt'>
) => {
  const response = await client.post('/payment-services', resource)
  return response.data
}

export const updatePaymentService = async (
  changes: PaymentServiceUpdate
): Promise<PaymentService> => {
  const { id, ...body } = changes
  const response = await client.put(`/payment-services/${id}`, body)
  return response.data
}

export const removePaymentService = async (id: string) => {
  await client.delete(`/payment-services/${id}`)
}

export const verifyPaymentServiceCredentials = async (
  data: VerifyConnectionCredentials
): Promise<void> => {
  const response = await client.post('/payment-services/verify', data)
  return response.data
}

export const listConnections = async (): Promise<{
  items: Connection[]
}> => {
  const response = await client.get('/connections')
  return response.data
}

export const listConnectionDefinitions = async (): Promise<{
  items: ConnectionDefinition[]
}> => {
  const response = await client.get('/connection-definitions')
  return response.data
}
