import { GridItem } from '@gr4vy/poutine-react'
import {
  ColorBlue10,
  ColorGray100,
  RadiusRounded,
  Size32,
} from '@gr4vy/poutine-tokens/js/tokens'
import { Select, Tag } from 'antd'
import { Operation } from 'flows/constants'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'

interface SkuInputProps {
  name: number
}

const ERROR_SKU_REQUIRED = 'At least one SKU is required'
const ERROR_OPERATOR_REQUIRED = 'Operator is required'

function tagRender({ label, closable, onClose }: any) {
  const onPreventMouseDown = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: '3px',
        background: ColorBlue10,
        borderColor: ColorBlue10,
        borderRadius: RadiusRounded,
        color: ColorGray100,
        height: Size32,
        lineHeight: Size32,
        marginTop: 0,
      }}
    >
      {label}
    </Tag>
  )
}

const SkuInput = ({ name }: SkuInputProps) => (
  <>
    <GridItem gridColumn="span 2">
      <FormItem
        label={<Label>Operator</Label>}
        name={[name, 'operator']}
        rules={[{ required: true, message: ERROR_OPERATOR_REQUIRED }]}
      >
        <Select placeholder="Select an operator">
          <Select.Option value={Operation.INCLUDES_AT_LEAST_ONE}>
            includes at least one
          </Select.Option>
          <Select.Option value={Operation.INCLUDES_ALL}>
            includes all
          </Select.Option>
          <Select.Option value={Operation.INCLUDES_NONE}>
            includes none
          </Select.Option>
        </Select>
      </FormItem>
    </GridItem>
    <GridItem gridColumn="span 6">
      <FormItem
        label={<Label>Value(s)</Label>}
        name={[name, 'value']}
        rules={[{ required: true, message: ERROR_SKU_REQUIRED }]}
      >
        <Select
          mode="tags"
          allowClear
          tagRender={tagRender}
          maxTagCount="responsive"
          maxTagPlaceholder={maxTagPlaceholderFormatter}
          placeholder="A127"
        />
      </FormItem>
    </GridItem>
  </>
)

export { ERROR_SKU_REQUIRED, ERROR_OPERATOR_REQUIRED }
export default SkuInput
