import { getCurrencyByCode } from '@gr4vy/poutine-react'

const currencyToSubunits = (value: string, currency: string): number => {
  const { digits = 0 } = getCurrencyByCode(currency) || {}
  const [unit, subunit = ''] = value.split('.')
  const valueFormatted = `${unit}.${subunit.padEnd(digits, '0')}`
  return parseInt(valueFormatted.replace('.', ''))
}

const currencyFromSubunits = (value: number, currency: string): string => {
  const { digits = 2 } = getCurrencyByCode(currency) || {}
  return (value / 10 ** digits).toFixed(digits).toString()
}

const currencyFieldValidator = (value = '', currency = ''): Promise<void> => {
  const { digits: currencyDigits = 2 } = getCurrencyByCode(currency) || {}
  const digits = (value.split('.')[1] || '').length
  if (parseInt(value, 10) < 0) {
    return Promise.reject(Error('Value must be greater than or equal to 0'))
  }
  if (!currency || !value || digits <= currencyDigits) {
    return Promise.resolve()
  }
  const message =
    currencyDigits === 0
      ? 'Value must not contain any decimal'
      : `Value must contain at most ${currencyDigits} decimal digits`

  return Promise.reject(new Error(message))
}

const getCurrencyParts = (currency: string, locale: string) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })
    .formatToParts(1111.11)
    .reduce(
      (acc, { type, value }) =>
        type === 'group' || type === 'decimal'
          ? { ...acc, [type]: value }
          : acc,
      { group: ' ', decimal: ' ' }
    )
}

/**
 * Parses a currency value and returns the number decimals.
 * @param {string} value String representation of a number (e.g. '1,000.00')
 * @param {string} currency String representation of a currency (e.g. 'USD')
 * @param {string} locale String representation of a locale (e.g. 'en-US')
 * @returns {number} Number of digits after the decimal point
 */
const parseDigits = (
  value: string | undefined,
  currency: string,
  locale: string
) => {
  if (!value) {
    return 0
  }
  const { decimal } = getCurrencyParts(currency, locale)
  return value.split(decimal)[1]?.length || 0
}

export {
  currencyFromSubunits,
  currencyToSubunits,
  currencyFieldValidator,
  getCurrencyParts,
  parseDigits,
}
