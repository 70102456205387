import {
  Heading,
  Stack,
  Text,
  Button,
  Divider,
  Box,
} from '@gr4vy/poutine-react'
import { Form, Input } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import React, { Fragment } from 'react'
import { Label } from 'shared/components/Form'

interface Props {
  visible: boolean
}

export const StepMetadata = ({ visible }: Props) => {
  return (
    <Stack gap={24} display={visible ? 'flex' : 'none'}>
      <Stack gap={8}>
        <Heading level={5}>Metadata</Heading>
        <Text>
          If you do not require a metadata filter please leave blank and click
          next.
        </Text>
      </Stack>
      <Form.List name="metadata">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <Fragment key={key}>
                <Box>
                  <FormItem
                    label={<Label>Key</Label>}
                    name={[name, 'key']}
                    rules={[
                      { required: true, message: 'This field is required' },
                    ]}
                  >
                    <Input />
                  </FormItem>
                  <FormItem
                    label={<Label>Value</Label>}
                    name={[name, 'value']}
                    rules={[
                      { required: true, message: 'This field is required' },
                    ]}
                  >
                    <Input />
                  </FormItem>
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      e.preventDefault()
                      remove(name)
                    }}
                  >
                    Remove
                  </Button>
                </Box>
                <Divider />
              </Fragment>
            ))}
            <Button
              onClick={(e) => {
                e.preventDefault()
                add()
              }}
            >
              Add filter
            </Button>
          </>
        )}
      </Form.List>
    </Stack>
  )
}
