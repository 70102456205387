import { Grid, GridItem } from '@gr4vy/poutine-react'
import { useCallback } from 'react'
import { TransactionFilters as _TransactionFilters } from 'shared/services/transactions'
import { AdvancedSearchInput } from 'transactions/components/AdvancedSearchInput'
import { QuickFilters } from 'transactions/components/QuickFilters'

export type TransactionFiltersProps = {
  filters: Partial<_TransactionFilters>
  onFilter: CallableFunction
}

const TransactionFilters = ({ filters, onFilter }: TransactionFiltersProps) => {
  const onSearch = useCallback(
    (filters: Partial<_TransactionFilters>) => onFilter(filters),
    [onFilter]
  )

  return (
    <Grid gap={16}>
      <GridItem gridColumn={['span 12', 'span 12', 'span 4']}>
        <AdvancedSearchInput onSearch={onSearch} />
      </GridItem>
      <GridItem gridColumn={['span 12', 'span 12', 'span 4']}>
        <QuickFilters filters={filters} onFilter={onFilter} />
      </GridItem>
    </Grid>
  )
}

export default TransactionFilters
