import { Description, Icon, Popover, Stack, Text } from '@gr4vy/poutine-react'
import { Input, InputRef } from 'antd'
import { ChangeEvent, KeyboardEvent, useRef, useEffect, useState } from 'react'
import { NavigationKeys } from 'shared/constants'
import { is } from 'shared/helpers/is'
import {
  emptyAdvancedSearchFilters,
  AdvancedSearchFilters,
} from 'transactions/constants'
import useAdvancedSearch from 'transactions/hooks/use-advanced-search'
import styles from './AdvancedSearchInput.module.scss'
import Item from './Item'

interface AdvancedSearchInputProps {
  onSearch: (filters: AdvancedSearchFilters) => void
}

export const AdvancedSearchInput = ({ onSearch }: AdvancedSearchInputProps) => {
  const inputRef = useRef<InputRef>(null)
  const popoverContentRef = useRef<HTMLDivElement>(null)
  const [inputValue, setInputValue] = useState<string>()
  const {
    open,
    setOpen,
    isActiveOption,
    filteredAdvancedSearchOptions,
    advancedSearchOptions,
    getFiltersBySearch,
    defaultValue,
  } = useAdvancedSearch(inputValue)

  const handleSearch = () => {
    onSearch?.({
      ...getFiltersBySearch(inputValue),
    })
    setOpen(false)
  }

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const hasCleaned =
      !is.emptyString(inputValue) && is.emptyString(event.target.value)

    if (hasCleaned) {
      onSearch(emptyAdvancedSearchFilters)
    }
    setInputValue(event.target.value)
    setOpen(true)
  }

  const onInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      open &&
      ([event.key, event.code].includes(NavigationKeys.TAB_KEY) ||
        [event.key, event.code].includes(NavigationKeys.ARROW_DOWN_KEY))
    ) {
      event.preventDefault()
      ;(
        popoverContentRef.current?.firstChild?.firstChild as HTMLDivElement
      )?.focus()
    }
  }

  const handleSelectOption = (selectedOptionTitle: string) => {
    const currentInputValue = inputRef.current?.input?.value
    const endCursorPosition = inputRef.current?.input?.selectionEnd as number
    const contentBeforeCursorPosition = currentInputValue?.slice(
      0,
      endCursorPosition
    )
    const contentAfterCursorPosition = currentInputValue?.slice(
      endCursorPosition,
      currentInputValue?.length
    )
    const selectedOption = `${selectedOptionTitle.split('=')[0]}=`
    const newSearch = contentBeforeCursorPosition?.split(/\b\s/g)?.pop()
    const contentBeforeUpdated = contentBeforeCursorPosition?.slice(
      0,
      endCursorPosition - (newSearch?.length ?? 0)
    )

    setInputValue(
      `${contentBeforeUpdated}${selectedOption}${contentAfterCursorPosition}`
    )

    setTimeout(
      () =>
        inputRef.current?.input?.setSelectionRange(
          endCursorPosition - (newSearch?.length ?? 0) + selectedOption.length,
          endCursorPosition - (newSearch?.length ?? 0) + selectedOption.length
        ),
      10
    )
  }

  const onPopoverKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    const activeElement =
      popoverContentRef.current?.firstChild?.ownerDocument?.activeElement
    if ([event.key, event.code].includes(NavigationKeys.ARROW_DOWN_KEY)) {
      event.preventDefault()
      ;(activeElement?.nextSibling as HTMLLIElement)?.focus()
    }

    if ([event.key, event.code].includes(NavigationKeys.ARROW_UP_KEY)) {
      event.preventDefault()
      ;(activeElement?.previousSibling as HTMLLIElement)?.focus()
    }

    if (
      [event.key, event.code].includes(NavigationKeys.SPACE_KEY) ||
      [event.key, event.code].includes(NavigationKeys.ENTER_KEY)
    ) {
      event.preventDefault()
      handleSelectOption((activeElement as HTMLLinkElement).innerText)
      inputRef.current?.focus()
    }
  }

  useEffect(() => {
    setInputValue(defaultValue)
  }, [defaultValue])

  return (
    <Stack as="label" gap={8}>
      <Text margin="none" fontWeight="medium">
        Search
      </Text>
      <Popover open={open} onOpenChange={setOpen}>
        <Popover.Trigger asChild>
          <div>
            <Input
              ref={inputRef}
              allowClear
              name="search"
              placeholder="Search by additional fields..."
              onPressEnter={handleSearch}
              prefix={
                <Icon name="magnifying-glass" size="small" color="black" />
              }
              value={inputValue}
              size="large"
              onClick={() => setOpen(true)}
              onChange={onInputChange}
              onKeyDown={onInputKeyDown}
              className={styles.input}
            />
          </div>
        </Popover.Trigger>
        <Popover.Content
          ref={popoverContentRef}
          align="start"
          side="bottom"
          onClick={() => inputRef.current?.focus()}
          onKeyDown={onPopoverKeyDown}
          avoidCollisions={false}
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          <Stack gap={8} padding={16} className={styles.wrapper}>
            {filteredAdvancedSearchOptions.map((key: string) => (
              <Item
                name={key}
                key={key}
                onClick={() =>
                  handleSelectOption(
                    advancedSearchOptions?.[
                      key as keyof typeof advancedSearchOptions
                    ].title
                  )
                }
                active={isActiveOption(
                  advancedSearchOptions?.[
                    key as keyof typeof advancedSearchOptions
                  ].title
                )}
              >
                <Description>
                  <Description.Text>
                    {
                      advancedSearchOptions[
                        key as keyof typeof advancedSearchOptions
                      ].title
                    }
                  </Description.Text>
                  <Description.SubText style={{ whiteSpace: 'normal' }}>
                    {
                      advancedSearchOptions[
                        key as keyof typeof advancedSearchOptions
                      ].description
                    }
                  </Description.SubText>
                </Description>
              </Item>
            ))}
          </Stack>
        </Popover.Content>
      </Popover>
    </Stack>
  )
}

export default AdvancedSearchInput
