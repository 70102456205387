import { generatePath } from 'react-router-dom'
import { BuyerTabs } from 'buyers/constants'

export const BUYERS_PATH = '/merchants/:merchantAccountId/buyers'
export const BUYER_PATH = `${BUYERS_PATH}/:id`

export const pathTo = {
  buyer: (merchantAccountId: string, id: string) =>
    generatePath(BUYER_PATH, { merchantAccountId, id }),
  buyerTab: (merchantAccountId: string, id: string, tab: BuyerTabs) =>
    `${generatePath(BUYER_PATH, { merchantAccountId, id })}${
      tab ? `/${tab}` : ''
    }`,
  buyerOverviewTab: `${BUYER_PATH}/${BuyerTabs.overview}`,
  buyerTransactionsTab: `${BUYER_PATH}/${BuyerTabs.transactions}`,
  buyerPaymentMethodsTab: `${BUYER_PATH}/${BuyerTabs.paymentMethods}`,
  buyerGiftCardsTab: `${BUYER_PATH}/${BuyerTabs.giftCards}`,
}
