import { GridItem } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import { Operation } from 'flows/constants'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { CurrencyInput } from './CurrencyInput'

export const CurrencyCondition = () => (
  <>
    <GridItem gridColumn="span 2" data-testid="currency-condition">
      <FormItem
        label={<Label>Operator</Label>}
        name={['amountCondition', 'operator']}
        rules={[{ required: true, message: 'Operator is required' }]}
      >
        <Select placeholder="Select an operator">
          <Select.Option value={Operation.IS_ONE_OF}>includes</Select.Option>
          <Select.Option value={Operation.IS_NOT_ONE_OF}>
            excludes
          </Select.Option>
        </Select>
      </FormItem>
    </GridItem>
    <GridItem gridColumn="span 6">
      <CurrencyInput
        name={['amountCondition', 'value']}
        mode="multiple"
        label="Value(s)"
      />
    </GridItem>
    <GridItem gridColumn="span 1" />
  </>
)
