import {
  Box,
  Description,
  Dropdown,
  Flex,
  Icon as PoutineIcon,
} from '@gr4vy/poutine-react'
import { OutcomeInstrument, OutcomeTransformation } from 'flows/constants'
import {
  getInstrumentName,
  getTransformationNames,
} from 'flows/utils/cardOutcome'
import OutcomeWarning from './OutcomeWarning'

export type ActionMenuProps = {
  instrument: OutcomeInstrument
  transformations: OutcomeTransformation[]
  onRemove: () => void
  onEdit: () => void
  warning?: string
}

export const ActionMenu = ({
  instrument,
  transformations,
  warning,
  onRemove,
  onEdit,
}: ActionMenuProps) => {
  return (
    <Flex
      gap={24}
      justifyContent="flex-end"
      paddingLeft={16}
      style={{ width: 'fit-content' }}
    >
      <Description align="flex-end">
        <Description.Text>{getInstrumentName(instrument)}</Description.Text>
        {!!transformations.length && (
          <Description.SubText>
            {getTransformationNames(transformations)}
          </Description.SubText>
        )}
      </Description>
      <Flex gap={8}>
        <Box textAlign="center">
          <Dropdown>
            <Dropdown.Trigger asChild>
              <Dropdown.Button variant="tertiary" size="small">
                <PoutineIcon name="more-horizontal" />
              </Dropdown.Button>
            </Dropdown.Trigger>
            <Dropdown.Content align="end">
              <Dropdown.Item onSelect={onEdit}>Edit</Dropdown.Item>
              <Dropdown.Item onSelect={onRemove}>Remove</Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>
        </Box>
        {typeof warning === 'string' && (
          <Box justifyContent="flex-end">
            <OutcomeWarning version={2}>{warning}</OutcomeWarning>
          </Box>
        )}
      </Flex>
    </Flex>
  )
}
