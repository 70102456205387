import { Text } from '@gr4vy/poutine-react'
import { FormInstance } from 'antd'
import clsx from 'clsx'
import { useState } from 'react'
import { DragProvided, DragSnapshot } from 'flows/components/DragAndDrop'
import { TransactionActionOutcome } from 'flows/constants'
import { ModalRemove } from 'shared/components/Modal/ModalRemove'
import { Action } from 'shared/constants'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { Outcome } from './Outcome'
import styles from './OutcomeAction.module.scss'
import { validateOutcome } from './outcomeValidation'

export type OutcomeCardProps = {
  action: Action
  connection: TransactionActionOutcome
  dragProvided: DragProvided
  dragSnapshot: DragSnapshot
  form: FormInstance
  onRemoveConnection: (connectionId: string) => void
  onEditConnection: (connection: TransactionActionOutcome) => void
}

export const OutcomeActionV2 = ({
  connection,
  dragProvided,
  dragSnapshot,
  action,
  form,
  onRemoveConnection,
  onEditConnection,
}: OutcomeCardProps) => {
  const [removeModalVisible, setRemoveModalVisible] = useState(false)

  const hasWritePermission = useResourcePermission(
    Resource.flows,
    AccessLevel.write
  )

  const { id, label, displayName, iconUrl, instruments, transformations } =
    connection

  const warning = validateOutcome({
    form,
    outcome: connection,
    action,
    version: 2,
  })

  const onOk = () => {
    setRemoveModalVisible(false)
    onRemoveConnection(id)
  }

  return (
    <>
      <Outcome
        ref={dragProvided.innerRef}
        className={clsx(
          styles.outcomeCard,
          typeof warning !== 'undefined' && styles.warningOutcomeCard
        )}
        isDragging={dragSnapshot.isDragging}
        {...dragProvided.draggableProps}
      >
        <Outcome.Item
          version={2}
          label={label}
          displayName={displayName}
          iconUrl={iconUrl}
          hasWritePermission={hasWritePermission}
          instrument={instruments[0]}
          transformations={transformations}
          warning={warning}
          onRemove={() => setRemoveModalVisible(true)}
          onEdit={() => onEditConnection(connection)}
          {...dragProvided.dragHandleProps}
        />
      </Outcome>
      {removeModalVisible && (
        <ModalRemove
          title="Remove connection"
          okText="Yes, remove"
          onCancel={() => setRemoveModalVisible(false)}
          onOk={onOk}
        >
          <Text>
            You are about to remove{' '}
            <Text as="span" fontWeight="bold">
              {label}
            </Text>{' '}
            from route to connection. This connection can be added again at any
            time. Are you sure you want to proceed?
          </Text>
        </ModalRemove>
      )}
    </>
  )
}
