import { compact } from 'lodash'
import { Reports, reportsNames } from 'reports/constants'
import { pathTo } from 'shared/paths/reports'
import { useReportsPermission } from './use-reports-permission'

export const useReportsRoutes = (merchantAccountId: string) => {
  const { hasWritePermission } = useReportsPermission()

  return compact([
    hasWritePermission
      ? {
          title: reportsNames[Reports.all],
          url: pathTo.allReports(merchantAccountId),
        }
      : undefined,
    {
      title: reportsNames[Reports.generated],
      url: pathTo.generatedReports(merchantAccountId),
    },
    {
      title: reportsNames[Reports.scheduled],
      url: pathTo.scheduledReports(merchantAccountId),
    },
  ])
}
