import { useState, ReactNode, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSession } from 'session'
import { getAdminPathFromUrl } from 'shared/helpers/routes'
import { MerchantAccount } from 'shared/services/merchant-accounts'
import MerchantContext from './MerchantContext'

const MerchantProvider = ({ children }: { children: ReactNode }) => {
  const [merchantAccounts, setMerchantAccounts] = useState<MerchantAccount[]>(
    []
  )
  const navigate = useNavigate()
  const { isSignedIn, isSignedOut, user } = useSession()
  const isAdminMode = useMemo(() => !!getAdminPathFromUrl(), [])

  useEffect(() => {
    function updateMerchantAccounts(userMerchantAccounts: MerchantAccount[]) {
      setMerchantAccounts(userMerchantAccounts)
    }
    if (user && !!merchantAccounts.length) {
      updateMerchantAccounts(user.merchantAccounts)
    }
  }, [user, merchantAccounts])

  useEffect(() => {
    function setupMerchantAccountOnSignIn(
      userMerchantAccounts: MerchantAccount[]
    ) {
      setMerchantAccounts(userMerchantAccounts)
    }

    if (isSignedIn && user && !merchantAccounts.length) {
      setupMerchantAccountOnSignIn(user.merchantAccounts)
    }
  }, [isSignedIn, user, navigate, isAdminMode, merchantAccounts])

  useEffect(() => {
    function removeMerchantAccountOnSignOut() {
      setMerchantAccounts([])
    }

    if (isSignedOut) {
      removeMerchantAccountOnSignOut()
    }
  }, [isSignedOut])

  return (
    <MerchantContext.Provider
      value={{
        merchantAccounts,
        setMerchantAccounts,
      }}
    >
      {children}
    </MerchantContext.Provider>
  )
}

export default MerchantProvider
