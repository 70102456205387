import { useSession } from 'session'
import { env } from 'shared/constants/env'

export const useHealthDashboardStatus = () => {
  const { isStaff } = useSession()
  const isHealthDashboardEnabled =
    isStaff || env.REACT_APP_HEALTH_DASHBOARD === 'true'

  return {
    isHealthDashboardEnabled,
  }
}
