import { GridItem } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import { Operation } from 'flows/constants'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'

interface CardTypeInputProps {
  name: number
}

const cardTypes = [
  { value: 'credit', name: 'Credit' },
  { value: 'debit', name: 'Debit' },
  { value: 'prepaid', name: 'Prepaid' },
]

const ERROR_CARD_TYPE_REQUIRED = 'At least one card type is required'
const ERROR_OPERATOR_REQUIRED = 'Operator is required'

const CardTypeInput = ({ name }: CardTypeInputProps) => (
  <>
    <GridItem gridColumn="span 2">
      <FormItem
        label={<Label>Operator</Label>}
        name={[name, 'operator']}
        rules={[{ required: true, message: ERROR_OPERATOR_REQUIRED }]}
      >
        <Select placeholder="Select an operator">
          <Select.Option value={Operation.IS_ONE_OF}>includes</Select.Option>
          <Select.Option value={Operation.IS_NOT_ONE_OF}>
            excludes
          </Select.Option>
        </Select>
      </FormItem>
    </GridItem>
    <GridItem gridColumn="span 6">
      <FormItem
        label={<Label>Value(s)</Label>}
        name={[name, 'value']}
        rules={[{ required: true, message: ERROR_CARD_TYPE_REQUIRED }]}
      >
        <Select
          mode="multiple"
          allowClear
          maxTagCount="responsive"
          maxTagPlaceholder={maxTagPlaceholderFormatter}
          placeholder="Select an option"
        >
          {cardTypes.map(({ value, name }) => (
            <Select.Option value={value} key={value}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    </GridItem>
  </>
)

export { ERROR_CARD_TYPE_REQUIRED, ERROR_OPERATOR_REQUIRED }
export default CardTypeInput
