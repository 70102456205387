import {
  Button,
  ButtonLink,
  Flex,
  Heading,
  Icon,
  Text,
} from '@gr4vy/poutine-react'
import { useNavigate } from 'react-router-dom'
import ErrorPageLayout from 'errors/components/ErrorPageLayout'

const AccessDeniedPage = ({ title }: { title: string }) => {
  const navigate = useNavigate()

  return (
    <ErrorPageLayout title={title}>
      <Heading level={1}>Access Denied</Heading>
      <Text as="div">
        Sorry, the page you are looking for has restricted access. Please
        contact your administrator to get permission or browse other pages.
      </Text>
      <Flex gap={16} justifyContent="center" paddingTop={32}>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Icon name="arrow-left-md" />
          Go back
        </Button>
        <ButtonLink href={'/'}>Take me home</ButtonLink>
      </Flex>
    </ErrorPageLayout>
  )
}

export default AccessDeniedPage
