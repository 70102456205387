import qs from 'qs'
import client, { CollectionResponse } from 'shared/services/client'
import { MerchantAccount } from 'shared/services/merchant-accounts'

export type User = {
  name: string
  id: string
  status: string
  emailAddress: string
  hasValidPassword: boolean
  resetTokenExpiresAt: string | null
  resetToken: string | null
  lastLoggedInAt: string | null
  roles: UserRole[]
  merchantAccounts: MerchantAccount[]
  category: 'sso' | 'email'
  isStaff: boolean
}

export type UserRole = {
  id: string
  assignmentId: string
  name: string
}

export type Role = {
  id: string
  name: string
  description: string
}

export type RoleAssignment = {
  id: string
  user: string
  role: string
}

export const listUsers = async (
  params = {}
): Promise<CollectionResponse<User>> => {
  const response = await client.get('/users', {
    params,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat', encode: false }),
  })

  return response.data
}

export const removeUser = async (id: string) => {
  await client.delete(`/users/${id}`)
}

export const resetPassword = async (emailAddress: string): Promise<User> => {
  const response = await client.post('/users/reset-password', {
    // eslint-disable-next-line camelcase
    email_address: emailAddress,
  })
  return response.data
}

export type NewUser = Pick<User, 'name' | 'emailAddress'> & {
  roleIds: string[]
  merchantAccountIds: string[]
}

export const addUser = async ({
  name,
  emailAddress,
  roleIds,
  merchantAccountIds,
}: NewUser): Promise<User> => {
  const response = await client.post('/users', {
    name,
    emailAddress,
    roleIds,
    merchantAccountIds,
  })
  return response.data
}

export type UpdateUser = Pick<User, 'id' | 'name'> & {
  roleIds?: string[]
  merchantAccountIds?: string[]
}

export const updateUser = async ({
  id,
  name,
  roleIds,
  merchantAccountIds,
}: UpdateUser): Promise<User> => {
  const response = await client.put(`/users/${id}`, {
    name,
    ...(roleIds ? { roleIds } : {}),
    ...(merchantAccountIds ? { merchantAccountIds } : {}),
  })
  return response.data
}

export const getUser = async (id: string): Promise<User> => {
  const response = await client.get(`/users/${id}`)
  return response.data
}

export const listRoles = async (): Promise<CollectionResponse<Role>> => {
  const response = await client.get('/roles')
  return response.data
}

export type NewRoleAssignment = {
  user: string
  role: string
}

export const addRoleAssignment = async ({
  user,
  role,
}: NewRoleAssignment): Promise<RoleAssignment> => {
  const response = await client.post('/roles/assignments', {
    role: { id: role },
    assignee: { type: 'user', id: user },
  })
  return response.data
}

export const removeRoleAssignment = async (id: string) =>
  await client.delete(`/roles/assignments/${id}`)
