import { useNavigate, useParams } from 'react-router-dom'
import { useGiftCardServiceDefinition } from 'connections/hooks/use-gift-card-service-definition'
import { useGiftCardServices } from 'connections/hooks/use-gift-card-services'
import { Loading } from 'shared/components/Loading'
import { pathTo } from 'shared/paths/connections'
import { GiftCardServiceAddPage as Page } from './GiftCardServiceAddPage'

const GiftCardServiceAddPage = () => {
  const navigate = useNavigate()
  const { create } = useGiftCardServices()
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }

  const { giftCardServiceDefinition, isLoading } = useGiftCardServiceDefinition(
    merchantAccountId,
    id
  )

  if (isLoading || !giftCardServiceDefinition) {
    return <Loading />
  }

  const handleSubmit = async (values: any) => {
    create.mutate(values, {
      onSuccess: (data) => {
        navigate(
          pathTo.editConnectionGiftCardService(merchantAccountId, data.id)
        )
      },
    })
  }
  const handleCancel = () => {
    navigate(pathTo.connectionsCatalog(merchantAccountId), { replace: true })
  }

  return (
    <Page
      definition={giftCardServiceDefinition}
      saving={create.isPending}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    />
  )
}

export default GiftCardServiceAddPage
