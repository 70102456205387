import { Stack } from '@gr4vy/poutine-react'
import { Configured } from 'connections/constants'
import { ConfiguredGroup } from './ConfiguredGroup'

export const ConfiguredGroups = ({
  configured,
}: {
  configured: Configured
}) => (
  <Stack style={{ gap: '96px' }}>
    <ConfiguredGroup configuredGroup={configured.cards} />
    <ConfiguredGroup configuredGroup={configured.nonCards} />
    <ConfiguredGroup configuredGroup={configured.other} />
  </Stack>
)
