import { Box } from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { ReactNode } from 'react'
import styles from './styles.module.scss'

interface OrderedListProps {
  className?: string
  children: ReactNode
}

const Item = ({ children, className }: OrderedListProps) => {
  return (
    <Box as="li" className={clsx(styles.item, className)}>
      <div>{children}</div>
    </Box>
  )
}

export { Item }
