import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { formatCurrencies } from 'home/helpers/format'
import {
  getHealthDashboardStatus,
  HealthDashboardStatus,
} from 'home/services/health-dashboard'
import { HEALTH_DASHBOARD_STATUS } from 'shared/constants'

const transformCurrencies = (data: HealthDashboardStatus) => ({
  ...data,
  currencies: formatCurrencies(data.currencies),
})

export const useStatus = () => {
  const { merchantAccountId } = useParams<{
    merchantAccountId: string
  }>()

  const {
    data: status,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [HEALTH_DASHBOARD_STATUS, merchantAccountId],
    queryFn: getHealthDashboardStatus,
    select: transformCurrencies,
    initialData: { currencies: [], latestExecution: undefined },
    meta: {
      error: {
        ignore: [404],
      },
    },
  })

  return { status, isLoading, isError }
}
