import { PropsWithChildren } from 'react'
import { Action, Flow } from 'shared/constants'
import { FlowContext } from './FlowContext'

export const FlowProvider = ({
  children,
  flow,
  action,
}: PropsWithChildren<{ flow: Flow; action: Action }>) => (
  <FlowContext.Provider value={{ flow, action }}>
    {children}
  </FlowContext.Provider>
)
