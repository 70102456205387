import { FilterButton, Flex, Popover, Stack } from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { useKeyboardListNavigation, Filters, useFilters } from 'shared/hooks'
import { MerchantAccount } from 'shared/services/merchant-accounts'
import styles from './MerchantsFilter.module.scss'

interface MerchantsFilters extends Filters {
  merchantAccountId?: string
}

interface MerchantsFitlerProps {
  merchantAccounts?: MerchantAccount[]
}

const MerchantsFilter = ({ merchantAccounts }: MerchantsFitlerProps) => {
  const [filters, setFilters] = useFilters<MerchantsFilters>()
  const { onKeyDown } = useKeyboardListNavigation()
  const [open, setOpen] = useState(false)

  const merchantAccountIdFilter = useMemo(
    () => (filters as MerchantsFilters)?.merchantAccountId,
    [filters]
  )

  const onToggleMerchantAccountFilter = (status: string) => {
    setFilters({
      ...filters,
      merchantAccountId:
        merchantAccountIdFilter?.toLowerCase() === status ? undefined : status,
    })
    setOpen(false)
  }

  return merchantAccounts ? (
    <Popover open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <FilterButton data-selected={!!merchantAccountIdFilter}>
          Merchants
          {merchantAccountIdFilter ? ' / 1' : null}
        </FilterButton>
      </Popover.Trigger>
      <Popover.Content
        align="start"
        side="bottom"
        avoidCollisions={false}
        onKeyDown={onKeyDown}
      >
        <Stack gap={4} className={styles.wrapper} role="listbox">
          {merchantAccounts?.map((merchantAccount) => (
            <Flex
              key={merchantAccount.id}
              alignItems="center"
              className={clsx(
                styles.item,
                merchantAccountIdFilter === merchantAccount.id && styles.active
              )}
              role="option"
              tabIndex={0}
              onClick={() => onToggleMerchantAccountFilter(merchantAccount.id)}
            >
              {merchantAccount.displayName}
            </Flex>
          ))}
        </Stack>
      </Popover.Content>
    </Popover>
  ) : (
    <>Merchants</>
  )
}

export default MerchantsFilter
