import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  addPaymentService,
  listPaymentServices,
  removePaymentService,
  updatePaymentService,
} from 'connections/services'
import { PAYMENT_SERVICES } from 'shared/constants'
import { useCursor, Filters, useFilters } from 'shared/hooks'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import { handleSuccessResponse } from 'shared/utils/handleSuccessResponse'

export const usePaymentServices = (initialParams?: Filters) => {
  const queryClient = useQueryClient()
  const [cursor] = useCursor()
  const [filters, setFilters] = useFilters(initialParams)

  const invalidatePaymentServices = () =>
    queryClient.invalidateQueries({ queryKey: [PAYMENT_SERVICES] })

  const items = useQuery({
    queryKey: [PAYMENT_SERVICES, cursor, filters],
    queryFn: () => listPaymentServices({ cursor, ...filters }),
  })

  const create = useMutation({
    mutationFn: addPaymentService,
    onSuccess: handleSuccessResponse(
      'Connection created',
      invalidatePaymentServices
    ),
    onError: handleErrorResponse,
  })

  const update = useMutation({
    mutationFn: updatePaymentService,
    onSuccess: (data) => {
      handleSuccessResponse(
        <>
          <b>{data.displayName || 'Connection'}</b> has been updated
        </>,
        invalidatePaymentServices
      )()
    },
    onError: handleErrorResponse,
  })

  const remove = useMutation({
    mutationFn: removePaymentService,
    onSuccess: handleSuccessResponse('Connection removed'),
    onError: handleErrorResponse,
  })

  return {
    cursor,
    filters,
    setFilters,
    items,
    create,
    update,
    remove,
  }
}
