import { GridItem } from '@gr4vy/poutine-react'
import { Input } from 'antd'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { currencyFieldValidator } from 'shared/utils/currency'
import { CurrencyInput } from './CurrencyInput'

export const ERROR_MIN_REQUIRED = 'Minimum is required'
export const ERROR_MAX_REQUIRED = 'Maximum is required'
export const ERROR_MAX_LESS_MIN = 'The maximum cannot be less than the minimum'

export const RangeAmountCondition = () => {
  return (
    <>
      <GridItem gridColumn="span 2" data-testid="range-amount-condition">
        <FormItem
          label={<Label>Minimum</Label>}
          name={['amountCondition', 'value', 'min']}
          rules={[
            { required: true, message: ERROR_MIN_REQUIRED },
            ({ getFieldValue }) => ({
              validator(_, value) {
                return currencyFieldValidator(
                  value,
                  getFieldValue(['amountCondition', 'value', 'currency'])
                )
              },
            }),
          ]}
        >
          <Input type="number" />
        </FormItem>
      </GridItem>
      <GridItem gridColumn="span 2">
        <FormItem
          label={<Label>Maximum</Label>}
          name={['amountCondition', 'value', 'max']}
          rules={[
            { required: true, message: ERROR_MAX_REQUIRED },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const minValue = getFieldValue([
                  'amountCondition',
                  'value',
                  'min',
                ])
                const min = minValue ? Number(minValue) : 0
                const max = value ? Number(value) : 0

                if (min > max) {
                  return Promise.reject(new Error(ERROR_MAX_LESS_MIN))
                }

                return currencyFieldValidator(
                  value,
                  getFieldValue(['amountCondition', 'value', 'currency'])
                )
              },
            }),
          ]}
        >
          <Input type="number" />
        </FormItem>
      </GridItem>
      <GridItem gridColumn="span 2">
        <CurrencyInput
          name={['amountCondition', 'value', 'currency']}
          label="Currency"
        />
      </GridItem>
    </>
  )
}
