import { Heading } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { Link } from 'shared/components/Link'
import { Panel } from 'shared/components/Panel'
import { Summary } from 'shared/components/Summary'
import { Action, Flow } from 'shared/constants'
import { pathToFlow } from 'shared/paths'
import { pathTo } from 'shared/paths/flows'
import {
  AccessLevel,
  Resource,
  RestrictAccess,
  useResourcePermission,
} from 'shared/permissions'
import {
  Transaction,
  TransactionFlow,
  TransactionFlowId,
} from 'transactions/services'
import { ConnectorRouting } from './ConnectorRouting'
import styles from './TransactionFlowPanel.module.scss'

export const OtherTransactionFlowPanel = ({
  actions,
  transaction,
}: {
  actions: Record<TransactionFlowId, TransactionFlow>
  transaction?: Transaction
}) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }

  const hasFlowReadPermission = useResourcePermission(
    Resource.flows,
    AccessLevel.read
  )

  return (
    <Panel>
      <Panel.Header>
        <Heading level={4}>Transaction flow</Heading>
        <RestrictAccess
          resource={Resource.flows}
          accessLevel={AccessLevel.read}
        >
          <Link
            href={pathToFlow.flow(merchantAccountId, Flow.redirectTransactions)}
          >
            Go to flow
          </Link>
        </RestrictAccess>
      </Panel.Header>
      <Panel.Content>
        <Summary inline className={styles.flow}>
          <Summary.Key>
            {hasFlowReadPermission && actions['route-transaction'] ? (
              <Link
                href={pathTo.rule(
                  merchantAccountId,
                  Flow.redirectTransactions,
                  Action.routeTransaction,
                  actions['route-transaction'].ruleId
                )}
              >
                Routing
              </Link>
            ) : (
              <>Routing</>
            )}
          </Summary.Key>
          <Summary.Value>
            {actions['route-transaction'] && (
              <ConnectorRouting
                transaction={transaction}
                transactionFlow={actions['route-transaction']}
              />
            )}
          </Summary.Value>
        </Summary>
      </Panel.Content>
    </Panel>
  )
}
