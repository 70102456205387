import { Anchor as AnchorAntd } from 'antd'
import { AnchorLinkItemProps } from 'antd/es/anchor/Anchor'
import { isSandbox } from 'shared/helpers/is-sandbox'
import styles from './SideNav.module.scss'
import { Title } from './Title'

const BANNER_HEIGHT = 6
const TITLE_BAR_HEIGHT = 120

interface SideNavProps {
  url?: string
  items: AnchorLinkItemProps[]
}

export const SideNav = ({ items }: SideNavProps) => (
  <AnchorAntd
    offsetTop={TITLE_BAR_HEIGHT + (isSandbox ? BANNER_HEIGHT : 0) + 20}
    className={styles.sideNav}
    items={items}
  />
)

SideNav.Title = Title
