import {
  Button,
  FilterButton,
  Flex,
  Popover,
  Stack,
} from '@gr4vy/poutine-react'
import { Form, Input } from 'antd'
import { useState, useCallback } from 'react'
import {
  ERROR_MAX_LESS_MIN,
  ERROR_MAX_REQUIRED,
  ERROR_MIN_REQUIRED,
} from 'flows/components/conditions/AmountOrCurrencyInput/RangeAmountCondition'
import { ERROR_VALUE_REQUIRED } from 'flows/components/conditions/AmountOrCurrencyInput/SingleAmountCondition'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import currencyParse from 'shared/helpers/currency-parse'
import { getCurrentLocale } from 'shared/helpers/locale'
import { useFilters } from 'shared/hooks'
import { currencyFieldValidator } from 'shared/utils/currency'
import {
  INITIAL_OPERATORS,
  INITIAL_RANGE,
  TransactionFilters,
  useTransactionAmount,
} from 'transactions/hooks/use-transaction-amount'
import CurrencySelect from './CurrencySelect'
import OperatorSelect, { Types } from './OperatorSelect'
import styles from './TransactionAmountFilter.module.scss'

const SINGLE_AMOUNT_OPERATORS = [
  Types.EQUAL_TO,
  Types.LESS_THAN_OR_EQUAL,
  Types.GREATER_THAN_OR_EQUAL,
]

const TransactionAmountFilter = () => {
  const [filters, setFilters] = useFilters<TransactionFilters>()
  const [open, setOpen] = useState(false)
  const [form] = Form.useForm()
  const {
    operator,
    setOperator,
    currencies,
    setCurrencies,
    amount,
    setAmount,
    amountRange,
    setAmountRange,
    getFilterFromOperator,
    isActive,
  } = useTransactionAmount()

  const canApply =
    (operator === Types.ANY && !!currencies.length) ||
    (SINGLE_AMOUNT_OPERATORS.includes(operator) &&
      amount &&
      !!currencies.length) ||
    (operator === Types.BETWEEN &&
      typeof amountRange.min === 'number' &&
      amountRange.min >= 0 &&
      amountRange.max &&
      amountRange.max > amountRange.min &&
      !!currencies.length)

  const handleApply = () => {
    setOpen(false)
    setFilters({
      ...filters,
      ...getFilterFromOperator(operator),
      currency: currencies,
    })
  }

  const resetFields = () => {
    setAmount(undefined)
    setAmountRange(INITIAL_RANGE)
    setCurrencies([])
    form.setFieldsValue({
      amount: undefined,
      amountMin: undefined,
      amountMax: undefined,
    })
  }

  const handleClear = () => {
    setFilters({
      ...INITIAL_OPERATORS,
      currency: undefined,
    })
    resetFields()
    setOperator(Types.ANY)
  }

  const handleChangeOperator = (newOperator: Types) => {
    setCurrencies([])
    setOperator(newOperator)
  }

  const handleChangeCurrency = (newCurrency: string[]) => {
    updateAmount({
      fields: ['amount', 'min', 'max'],
      currency: newCurrency,
    })
    setCurrencies([newCurrency].flat())
  }

  const updateAmount = useCallback(
    ({
      value,
      currency = currencies,
      fields = [],
    }: {
      value?: string | number
      currency?: string | string[]
      fields: string[]
    }) => {
      if (
        !fields.length ||
        ![...SINGLE_AMOUNT_OPERATORS, Types.BETWEEN].includes(operator) ||
        !currency ||
        (Array.isArray(currency) && currency.length !== 1)
      ) {
        return
      }

      fields.forEach((field) => {
        if (
          field === 'amount' &&
          SINGLE_AMOUNT_OPERATORS.includes(operator) &&
          (value ?? form.getFieldValue('amount'))
        ) {
          const newValue = currencyParse(
            String(value ?? form.getFieldValue('amount')),
            String(currency),
            getCurrentLocale()
          )
          setAmount(newValue)
        }

        if (
          ['min', 'max'].includes(field) &&
          Types.BETWEEN === operator &&
          (value ??
            (form.getFieldValue('amountMin') ||
              form.getFieldValue('amountMax')))
        ) {
          const newValue = currencyParse(
            String(
              value ??
                (field === 'min'
                  ? form.getFieldValue('amountMin')
                  : form.getFieldValue('amountMax'))
            ),
            String(currency),
            getCurrentLocale()
          )
          setAmountRange((currAmountRange) => ({
            ...currAmountRange,
            [field]: newValue,
          }))
        }
      })
    },
    [currencies, operator, form, setAmount, setAmountRange]
  )

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <FilterButton data-selected={!!isActive}>Amount</FilterButton>
      </Popover.Trigger>
      <Popover.Content align="start" side="bottom" avoidCollisions={false}>
        <Form
          name="amountAndCurrencyFilter"
          form={form}
          initialValues={{
            amount,
            amountMin: amountRange.min,
            amountMax: amountRange.max,
          }}
        >
          <Flex
            gap={16}
            justifyContent="space-between"
            className={styles.contentWrapper}
          >
            <Stack gap={8} direction="column" width={200}>
              <Label>Type</Label>
              <OperatorSelect
                value={operator}
                onChange={(value: Types) => handleChangeOperator(value)}
              />
            </Stack>
            {operator === Types.BETWEEN && (
              <>
                <Stack gap={8} direction="column" width={120}>
                  <Label>Minimum</Label>
                  <FormItem
                    className={styles.numberInput}
                    name="amountMin"
                    rules={[
                      { required: true, message: ERROR_MIN_REQUIRED },
                      ({ getFieldValue, validateFields }) => ({
                        validator(_, value) {
                          const amountMax = getFieldValue('amountMax')
                          if (amountMax) {
                            validateFields(['amountMax'])
                          }

                          updateAmount({ value, fields: ['min'] })
                          return currencyFieldValidator(value)
                        },
                      }),
                    ]}
                  >
                    <Input type="number" placeholder="0" min={0} />
                  </FormItem>
                </Stack>
                <Stack gap={8} direction="column" width={120}>
                  <Label>Maximum</Label>
                  <FormItem
                    className={styles.numberInput}
                    name="amountMax"
                    rules={[
                      { required: true, message: ERROR_MAX_REQUIRED },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const amountMin = getFieldValue('amountMin')
                          const min = Number(amountMin) || 0
                          const max = Number(value) || 0

                          if (min > max) {
                            return Promise.reject(new Error(ERROR_MAX_LESS_MIN))
                          }

                          updateAmount({
                            value,
                            fields: ['max'],
                          })

                          return currencyFieldValidator(value)
                        },
                      }),
                    ]}
                  >
                    <Input type="number" placeholder="0" min={0} />
                  </FormItem>
                </Stack>
              </>
            )}
            {[
              Types.EQUAL_TO,
              Types.GREATER_THAN_OR_EQUAL,
              Types.LESS_THAN_OR_EQUAL,
            ].includes(operator) && (
              <Stack gap={8} direction="column" width={120}>
                <Label>Value</Label>
                <FormItem
                  className={styles.numberInput}
                  name="amount"
                  rules={[
                    { required: true, message: ERROR_VALUE_REQUIRED },
                    () => ({
                      validator(_, value) {
                        updateAmount({ fields: ['amount'], value })
                        return currencyFieldValidator(value)
                      },
                    }),
                  ]}
                >
                  <Input type="number" placeholder="0" min={0} />
                </FormItem>
              </Stack>
            )}

            <Stack gap={8} direction="column" width={240}>
              <Label>Currency</Label>
              <CurrencySelect
                mode={operator === Types.ANY ? 'multiple' : undefined}
                currencies={currencies}
                onChange={handleChangeCurrency}
              />
            </Stack>
          </Flex>
          <FormItem>
            <Flex gap={16} justifyContent="flex-end" padding={8}>
              <Button
                onClick={handleClear}
                variant="secondary"
                size="small"
                disabled={open && !form.isFieldsTouched() && !currencies.length}
              >
                Clear
              </Button>
              <Button onClick={handleApply} size="small" disabled={!canApply}>
                Apply
              </Button>
            </Flex>
          </FormItem>
        </Form>
      </Popover.Content>
    </Popover>
  )
}

export default TransactionAmountFilter
