import { atoms, Box, Flex } from '@gr4vy/poutine-react'
import { GiftCardRedemption } from 'transactions/services/transactions'
import { MaskedCardNumber } from '../MaskedCardNumber'

export interface GiftCardItemProps {
  giftCardRedemption: GiftCardRedemption
  iconUrl?: string | undefined
}

export const GiftCardItem = ({
  giftCardRedemption,
  iconUrl,
}: GiftCardItemProps) => (
  <Flex alignItems="center" height={40} gap={16}>
    <img
      alt="Gift card"
      className={atoms({ height: 24, width: 24 })}
      src={iconUrl}
    />
    <Box>
      <MaskedCardNumber
        paymentMethod={null}
        giftCardRedemptions={[giftCardRedemption]}
      />
    </Box>
  </Flex>
)
