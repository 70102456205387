import ResetPage from './pages/ResetPage'

export default [
  {
    title: 'Reset',
    longTitle: 'Reset',
    key: 'reset',
    path: '/admin/reset',
    menu: false,
    component: ResetPage,
  },
]
