import { Flex, Stack, Button, Drawer, Icon, Text } from '@gr4vy/poutine-react'
import { Form } from 'antd'
import { Dayjs } from 'dayjs'
import { useState, useEffect } from 'react'
import { ReportExecutionMessage } from 'reports/components/ReportExecutionMessage'
import { ReportTemplate, reportTemplates } from 'reports/constants'
import { useCreateReport } from 'reports/hooks/use-report'
import {
  formatTimestamp,
  getTimezones,
  Timezone,
} from 'shared/helpers/timezone'
import { useHandleErrorResponse } from 'shared/hooks'
import { TransactionStatus } from 'shared/services/transactions'
import { getRangeByFrequency } from '../../helpers/schedule'
import { Frequency, ReportPayload } from '../../services/report'
import { StepDate } from './StepDate'
import { StepMetadata } from './StepMetadata'
import { StepName } from './StepName'
import { StepPaymentFilters } from './StepPaymentFilters'
import { StepThreeDSecure } from './StepThreeDSecure'

interface Props {
  templateId: string | null
  open: boolean
  schedule?: boolean
  onClose: () => void
}

interface FormValues {
  name: string
  status: TransactionStatus
  timezone: string
  range: [Dayjs, Dayjs]
  schedule: ReportPayload['schedule']
  paymentMethod: string[]
  paymentScheme: string[]
  currency: string[]
  threeDSecureStatus: string[]
  threeDSecureEci: string[]
  threeDSecureAuthResp: string[]
  metadata: { key: string; value: string }[]
}

export const CustomReportDrawer = ({
  templateId,
  open,
  schedule,
  onClose,
}: Props) => {
  const [step, setStep] = useState(1)
  const [form] = Form.useForm<FormValues>()
  const initialValues = {
    name: '',
    status: undefined,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    range: undefined,
    schedule: undefined,
    paymentMethod: undefined,
    paymentScheme: undefined,
    currency: undefined,
    threeDSecureStatus: undefined,
    threeDSecureEci: undefined,
    threeDSecureAuthResp: undefined,
    metadata: [],
  }
  const template = reportTemplates.find(
    ({ id }) => templateId === id
  ) as ReportTemplate
  const handleErrorResponse = useHandleErrorResponse()
  const {
    mutate: generate,
    isPending,
    isSuccess,
    isError,
    reset,
  } = useCreateReport()
  const handleSubmit = (values: FormValues) => {
    const timezone = getTimezones().find(
      ({ code }) => code === values.timezone
    ) as Timezone

    generate(
      {
        name: values.name,
        schedule: schedule ? values.schedule : 'once',
        scheduleEnabled: !!schedule,
        scheduleTimezone: values.timezone,
        spec: template.spec({
          status: values.status,
          from: schedule
            ? getRangeByFrequency(values.schedule as Frequency).from
            : formatTimestamp(values.range[0], timezone),
          to: schedule
            ? getRangeByFrequency(values.schedule as Frequency).to
            : formatTimestamp(values.range[1], timezone),
          method: values.paymentMethod,
          scheme: values.paymentScheme,
          currency: values.currency,
          threeDSecureStatus: values.threeDSecureStatus,
          threeDSecureEci: values.threeDSecureEci,
          threeDSecureAuthResp: values.threeDSecureAuthResp,
          metadata: values.metadata.map(({ key, value }) => ({ [key]: value })),
        }),
      },
      { onError: handleErrorResponse }
    )
  }
  const handleBack = () => {
    if (step === 1) {
      onClose()
    } else {
      setStep(step - 1)
    }
  }
  const handleNext = async () => {
    const fieldsByStep = [
      ['name'],
      ['status', 'timezone', 'range', 'schedule'],
      ['paymentMethod', 'paymentScheme', 'currency'],
      ['threeDSecureStatus', 'threeDSecureEci', 'threeDSecureAuthResp'],
      ['metadata'],
    ]
    try {
      await form.validateFields(fieldsByStep[step - 1])
      if (step === 5) {
        form.submit()
      } else {
        setStep(step + 1)
      }
    } catch {}
  }
  useEffect(() => {
    if (open) {
      setStep(1)
      form.resetFields()
      if (isSuccess || isError) {
        reset()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Drawer
      open={open}
      title={`${schedule ? 'Schedule' : 'Generate'} custom report`}
      footer={
        !isSuccess && (
          <Flex gap={16} justifyContent="flex-end">
            <Button variant="secondary" onClick={handleBack}>
              {step === 1 ? 'Cancel' : 'Back'}
            </Button>
            <Button loading={isPending} onClick={handleNext}>
              Next
              <Icon name="arrow-right-md" />
            </Button>
          </Flex>
        )
      }
      onClose={onClose}
    >
      {isSuccess ? (
        <ReportExecutionMessage schedule={schedule} onClose={onClose} />
      ) : (
        <Stack gap={24}>
          <Text variant="overline1">Step {step} of 5</Text>
          <Form
            form={form}
            initialValues={initialValues}
            onFinish={handleSubmit}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
          >
            <StepName schedule={schedule} visible={step === 1} />
            <StepDate schedule={schedule} visible={step === 2} />
            <StepPaymentFilters visible={step === 3} />
            <StepThreeDSecure visible={step === 4} />
            <StepMetadata visible={step === 5} />
          </Form>
        </Stack>
      )}
    </Drawer>
  )
}
