import { Select } from 'antd'
import isoCountries from 'iso-3166-1'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'

const countryOptions = isoCountries.all().map((value) => ({
  label: value.country,
  value: value.alpha2,
}))

export const MerchantCountryCodeInput = () => (
  <FormItem
    name="merchantCountryCode"
    label={<Label>Merchant country</Label>}
    required
    rules={[{ required: true, message: 'Merchant country is required' }]}
  >
    <Select
      showSearch
      placeholder="Select a country"
      options={countryOptions}
      filterOption={(input, option) =>
        `${option?.label}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    />
  </FormItem>
)
