import { dateFormat } from 'shared/helpers/date-format'
import { getCurrentLocale } from 'shared/helpers/locale'

export const formatGiftCardExpirationDate = (
  expirationDate?: string | null
) => {
  if (!expirationDate) {
    return '-'
  }

  return dateFormat(expirationDate, getCurrentLocale(), {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
}
