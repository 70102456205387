import {
  Badge,
  ButtonCopy,
  Flex,
  Heading,
  Stack,
  Tag,
} from '@gr4vy/poutine-react'
import { Summary } from 'shared/components/Summary'
import { EventCodeBlock } from 'transactions/components/EventCodeBlock'
import EventsConnection from 'transactions/components/EventsPanel/EventsConnection'
import { formatCode, getColorByDecision } from 'transactions/helpers'
import { TransactionEvent } from 'transactions/services'
import styles from './styles.module.scss'

export const AntiFraudWebhookEvent = ({
  event,
}: {
  event: TransactionEvent
}) => {
  return event ? (
    <Stack gap={32}>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Connection
        </Heading>
        <EventsConnection event={event} />
      </Stack>
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Decision
        </Heading>
        <Badge
          variant="subtle"
          color={getColorByDecision(event.context['decision'])}
        >
          {event.context['decision']}
        </Badge>
      </Stack>
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Details
        </Heading>
        <Summary className={styles.summary} inline>
          <Summary.Key>ID</Summary.Key>
          <Summary.Value fallback={!event.context.antiFraudServiceCheckId}>
            {event.context.antiFraudServiceCheckId}
          </Summary.Value>
          <Summary.Key>Comment</Summary.Key>
          <Summary.Value fallback={!event.context.comment}>
            {event.context.comment}
          </Summary.Value>
        </Summary>
      </Stack>
      <Stack gap={8}>
        <Flex alignItems="flex-end" justifyContent="space-between">
          <Flex alignItems="center" gap={8}>
            <Heading as="h5" level={5}>
              Webhook payload
            </Heading>
            <Tag variant="subtle">{event.context['content_type']}</Tag>
          </Flex>
          <ButtonCopy
            type="button"
            variant="secondary"
            copy={formatCode(event.context['content'])}
            size="small"
          >
            Copy
          </ButtonCopy>
        </Flex>
        <EventCodeBlock code={event.context['content']} />
      </Stack>
    </Stack>
  ) : null
}
