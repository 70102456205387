import dayjs, { Dayjs } from 'dayjs'
import { memoize } from 'lodash'

interface Timezone {
  code: string
  name: string
  offset: number
  offsetFormatted: string
}

const getOffset = (timeZone = 'UTC', date = new Date()) => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }))
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }))
  return (tzDate.getTime() - utcDate.getTime()) / 60000
}

const formatOffset = (offset: number) => {
  const hours = Math.floor(Math.abs(offset) / 60)
    .toString()
    .padStart(2, '0')
  const minutes = (Math.abs(offset) % 60).toString().padStart(2, '0')
  const sign = offset < 0 ? '-' : '+'
  return `${sign}${hours}:${minutes}`
}

const getTimezones = memoize((): Timezone[] => {
  // @ts-ignore https://github.com/microsoft/TypeScript/issues/49231
  const timezones = Intl.supportedValuesOf('timeZone')
  return timezones.map((name: string) => {
    const offset = getOffset(name)
    return {
      name: getTimezoneName(name),
      code: name,
      offset,
      offsetFormatted: formatOffset(offset),
    }
  })
})

const formatTimestamp = (date: Dayjs, timezone: Timezone) =>
  `${dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSS')}${timezone?.offsetFormatted}`

const getTimezoneName = (timezone: string) => {
  return timezone.replaceAll('_', ' ').replaceAll('/', ' - ')
}

export type { Timezone }
export {
  getOffset,
  formatOffset,
  getTimezones,
  formatTimestamp,
  getTimezoneName,
}
