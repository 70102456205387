import client from 'shared/services/client'

export interface AntiFraudServiceDefinition {
  id: string
  type: 'anti-fraud-service-definition'
  displayName: string
  fields: {
    key: string
    displayName: string
    description?: string
    required: boolean
    format: string
    secret: boolean
  }[]
  iconUrl: string
}

export interface AntiFraudServiceField {
  key: string
  value: string
}

export interface AntiFraudService {
  id: string
  type: 'anti-fraud-service'
  antiFraudServiceDefinitionId: string
  fields: AntiFraudServiceField[]
  active: boolean
  displayName: string
  createdAt: string
  updatedAt: string
  reviewsEnabled?: boolean
  webhookUrl?: string
}

export interface AntiFraudServiceUpdate {
  id: string
  antiFraudServiceDefinitionId: string
  fields?: AntiFraudServiceField[]
  active?: boolean
  displayName?: string
}

export interface VerifyAntiFraudCredentials {
  fields: Array<Record<string, string>>
  antiFraudServiceId?: string
  antiFraudServiceDefinitionId?: string
}

export const getAntiFraudServiceDefinition = async (
  id: string
): Promise<AntiFraudServiceDefinition> => {
  const { data } = await client.get(`/anti-fraud-service-definitions/${id}`)
  return data
}

export const validateAntiFraudService = async (
  data: VerifyAntiFraudCredentials
): Promise<void> => {
  const response = await client.post('/anti-fraud-services/verify', data)
  return response.data
}

export const getAntiFraudService = async (
  id: string
): Promise<AntiFraudService> => {
  const { data } = await client.get(`/anti-fraud-services/${id}`)
  return data
}

export const addAntiFraudService = async (
  body: Omit<AntiFraudService, 'id' | 'type' | 'createdAt' | 'updatedAt'> & {
    fields: Record<string, any>
  }
): Promise<AntiFraudService> => {
  const { data } = await client.post('/anti-fraud-services', body)
  return data
}

export const updateAntiFraudService = async (
  changes: AntiFraudServiceUpdate
): Promise<AntiFraudService> => {
  const { id, ...body } = changes
  const { data } = await client.put(`/anti-fraud-services/${id}`, body)
  return data
}

export const removeAntiFraudService = async (id: string) => {
  await client.delete(`/anti-fraud-services/${id}`)
}
