import { SummaryGrid } from 'shared/components/SummaryGrid'
import { SummaryGroup } from 'shared/components/SummaryGroup'
import { Buyer } from 'transactions/services'

export function BuyerPanelDecriptionFields({ buyer }: { buyer?: Buyer }) {
  return (
    <SummaryGroup title="Identification">
      <SummaryGrid gridTemplateColumns={{ xs: 1, md: 2, lg: 3 }}>
        <SummaryGrid.Item>
          <SummaryGrid.Key>Buyer ID</SummaryGrid.Key>
          <SummaryGrid.Value>{buyer?.id}</SummaryGrid.Value>
        </SummaryGrid.Item>
        <SummaryGrid.Item>
          <SummaryGrid.Key>External identifier</SummaryGrid.Key>
          <SummaryGrid.Value>{buyer?.externalIdentifier}</SummaryGrid.Value>
        </SummaryGrid.Item>
        <SummaryGrid.Item>
          <SummaryGrid.Key>Display name</SummaryGrid.Key>
          <SummaryGrid.Value>{buyer?.displayName}</SummaryGrid.Value>
        </SummaryGrid.Item>
      </SummaryGrid>
    </SummaryGroup>
  )
}
