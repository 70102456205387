import { createContext } from 'react'
import { Action, Flow } from 'shared/constants'

export const FlowContext = createContext<{
  flow: Flow
  action: Action
}>({
  flow: Flow.cardTransactions,
  action: Action.selectPaymentOption,
})
