import { Buyer } from 'buyers/services/buyers'

export const getBuyerName = (buyer?: Buyer) => {
  if (buyer?.billingDetails?.firstName && buyer?.billingDetails?.lastName) {
    return `${buyer.billingDetails?.firstName} ${buyer.billingDetails?.lastName}`
  }

  if (buyer?.displayName) {
    return buyer?.displayName
  }

  return 'Unnamed buyer'
}
