import { Box, Stack, Text } from '@gr4vy/poutine-react'
import { Link } from 'react-router-dom'
import { Icon, IconType } from 'shared/components/Icon'
import { Flow, Action } from 'shared/constants'
import { pathTo } from 'shared/paths/flows'
import styles from './AddNewRuleCard.module.scss'
import { Card } from './Card'

interface AddNewRuleCardProps {
  flow: Flow
  action: Action
  disabled?: boolean
  additionalParams?: URLSearchParams
  merchantAccountId: string
}

const AddNewRuleCardContents = () => (
  <Box padding={16}>
    <Stack gap={16} direction="row" alignContent="center" alignItems="center">
      <Box width={32} height={32}>
        <Icon iconType={IconType.Add} className="icon-add" />
      </Box>
      <Text fontWeight="bold" margin="none">
        Add a new rule
      </Text>
    </Stack>
  </Box>
)

export const AddNewRuleCard = ({
  flow,
  action,
  disabled = false,
  additionalParams = new URLSearchParams(),
  merchantAccountId,
}: AddNewRuleCardProps) => {
  const card = (
    <Card
      className={
        disabled ? styles.disabledAddNewRuleCard : styles.addNewRuleCard
      }
    >
      <AddNewRuleCardContents />
    </Card>
  )

  if (disabled) {
    return card
  }

  return (
    <Link
      to={pathTo.rules(merchantAccountId, flow, action, additionalParams)}
      className={styles.addNewRuleCardLink}
    >
      {card}
    </Link>
  )
}
