import { useQuery } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSession } from 'session'
import { ROLES } from 'shared/constants'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import {
  additionalPermissionNames,
  nonAdministratorRoleNames,
  roleNames,
} from 'users/constants'
import { listRoles, Role } from 'users/services/users'

export const useRoles = () => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const { user: loggedUser, isStaff } = useSession()
  const hasRolesPermission = useResourcePermission(
    Resource.roles,
    AccessLevel.read
  )
  const loggedUserId = loggedUser ? loggedUser.id : null

  const { data: { items: roles } = {} } = useQuery({
    queryKey: [ROLES, loggedUserId, merchantAccountId],
    queryFn: () => listRoles(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: hasRolesPermission && !!loggedUserId,
  })
  const availableRoles = !roles && loggedUser ? loggedUser.roles : roles

  const sortedRoles = useMemo(
    () => availableRoles?.sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [availableRoles]
  )

  const administratorRoleId = useMemo(
    () => sortedRoles?.find((role) => role.name === 'Administrator')?.id,
    [sortedRoles]
  )

  const userManagerRoleId = useMemo(
    () => sortedRoles?.find((role) => role.name === 'User Manager')?.id,
    [sortedRoles]
  )

  const getNonAdministrativeRoles = useCallback(() => {
    const defaultRoles = roleNames.map((roleName) => ({
      id: '',
      name: roleName,
      description: '',
    }))

    const userNonAdministratorRoles = sortedRoles.filter(
      (role) =>
        !nonAdministratorRoleNames.includes(role.name) &&
        !additionalPermissionNames.includes(role.name)
    )

    const pendingRoleNames: string[] = []
    const mergedRoles = [...defaultRoles]

    for (let i = 0; i < defaultRoles.length; i++) {
      for (let j = 0; j < userNonAdministratorRoles.length; j++) {
        if (userNonAdministratorRoles[j].name === mergedRoles[i].name) {
          mergedRoles[i] = userNonAdministratorRoles[j]
        }
        if (
          !roleNames.includes(userNonAdministratorRoles[j].name) &&
          !pendingRoleNames.includes(userNonAdministratorRoles[j].name)
        ) {
          pendingRoleNames.push(userNonAdministratorRoles[j].name)
          mergedRoles.push(userNonAdministratorRoles[j])
        }
      }
    }

    return mergedRoles.sort((a, b) => a.name.localeCompare(b.name))
  }, [sortedRoles])

  const nonAdministratorRoles = useMemo(
    () => getNonAdministrativeRoles(),
    [getNonAdministrativeRoles]
  )

  const apiKeyPairRoles = useMemo(() => {
    const apiKeyPairRoleNames = ['Administrator', 'Integration']
    return (roles || []).filter((role) =>
      apiKeyPairRoleNames.includes(role.name)
    )
  }, [roles])

  const userRoles = useMemo(() => {
    const nonUserRoleNames = ['Integration']
    return (
      sortedRoles?.filter((role) => !nonUserRoleNames.includes(role.name)) ?? []
    )
  }, [sortedRoles])

  const additionalPermissionsRoles = useMemo(
    () =>
      sortedRoles
        ?.filter((role) => additionalPermissionNames.includes(role.name))
        .reduce(
          (permissions: Pick<Role, 'id' | 'name'>[], role) => {
            permissions[0] = {
              ...permissions[0],
              ...(role.name === permissions[0].name ? role : {}),
            }
            permissions[1] = {
              ...permissions[1],
              ...(role.name === permissions[1].name ? role : {}),
            }
            permissions[2] = {
              ...permissions[2],
              ...(role.name === permissions[2].name ? role : {}),
            }

            return permissions
          },
          [
            { id: '', name: 'Report Viewer' },
            { id: '', name: 'Report Manager' },
            { id: '', name: 'PII Viewer' },
          ]
        ) ?? [],
    [sortedRoles]
  )

  const isAdmin = useMemo(() => {
    if (!!loggedUser && loggedUser.roles.at(0)) {
      return administratorRoleId === loggedUser.roles.at(0)?.id
    }
    return isStaff
  }, [administratorRoleId, isStaff, loggedUser])

  return {
    isAdmin,
    sortedRoles,
    userRoles,
    administratorRoleId,
    userManagerRoleId,
    nonAdministratorRoles,
    additionalPermissionsRoles,
    apiKeyPairRoles,
  }
}
