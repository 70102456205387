import { Description, Flex } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { useCardSchemeDefinition } from 'shared/hooks'
import { pathTo } from 'shared/paths/payment-methods'
import { pathTo as pathToTransactions } from 'shared/paths/transactions'
import { MaskedCardNumber } from 'transactions/components/MaskedCardNumber'
import { PaymentMethodsTag } from 'transactions/components/PaymentMethodsTag'
import {
  PaymentMethod,
  PayoutSummary,
  Transaction,
} from 'transactions/services'
import defaultCardIcon from './card.svg'

interface TransactionOverviewCardPaymentProps {
  transaction: Transaction | PayoutSummary
  paymentMethod?: PaymentMethod
}

const TransactionOverviewCardPayment = ({
  transaction,
  paymentMethod,
}: TransactionOverviewCardPaymentProps) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }

  const cardSchemeDefinition = useCardSchemeDefinition(
    transaction?.paymentMethod?.scheme
  )

  const label = transaction.paymentMethod?.label && (
    <MaskedCardNumber
      paymentMethod={transaction.paymentMethod}
      giftCardRedemptions={transaction.giftCardRedemptions}
    />
  )

  const title = label ?? cardSchemeDefinition?.displayName
  const scheme = label && cardSchemeDefinition?.displayName
  const SOURCES: Record<string, string> = {
    applepay: 'Apple Pay',
    googlepay: 'Google Pay',
    'network-token': 'Network Token',
    'click-to-pay': 'Click to Pay',
  }
  const source = SOURCES[transaction?.paymentMethod?.method as string]
  const subTitle = source ? `${scheme} - ${source}` : scheme

  return cardSchemeDefinition ? (
    <Flex alignItems={subTitle ? 'flex-start' : 'center'}>
      <Description>
        <Description.Icon
          src={cardSchemeDefinition.iconUrl ?? defaultCardIcon}
        />
        {paymentMethod?.id ? (
          <Description.Link
            href={pathTo.paymentMethodId(merchantAccountId, paymentMethod?.id)}
          >
            {title as string}
          </Description.Link>
        ) : transaction.paymentMethod?.fingerprint ? (
          <Description.Link
            href={pathToTransactions[
              transaction.type === 'payout' ? 'payouts' : 'transactions'
            ](merchantAccountId, {
              // eslint-disable-next-line camelcase
              payment_method_fingerprint: transaction.paymentMethod.fingerprint,
            })}
          >
            {title as string}
          </Description.Link>
        ) : (
          <Description.Text>{title as string}</Description.Text>
        )}
        {!!subTitle && <Description.SubText>{subTitle}</Description.SubText>}
      </Description>
      <PaymentMethodsTag
        paymentMethod={transaction?.paymentMethod}
        giftCardRedemptions={transaction?.giftCardRedemptions}
      />
    </Flex>
  ) : null
}

export default TransactionOverviewCardPayment
