import { Box } from '@gr4vy/poutine-react'
import { CardNumber } from 'transactions/hooks/use-transaction-payment-methods'
import { PaymentMethod } from 'transactions/services'
import styles from './MaskedCardNumber.module.scss'
import { PaymentLabel } from './PaymentLabel'

interface PaymentMethodMaskedNumberProps {
  paymentMethod: PaymentMethod | null
  cardNumber: CardNumber
}

export const PaymentMethodMaskedNumber = ({
  paymentMethod,
  cardNumber,
}: PaymentMethodMaskedNumberProps) => {
  return (
    <Box className={styles.wrapper}>
      {cardNumber.bin && (
        <Box as="span" display="inline" className={styles.number}>
          {cardNumber.prefix}
        </Box>
      )}
      <Box as="span" display="inline" aria-hidden className={styles.mask}>
        {!cardNumber.bin && cardNumber.prefix}
        {cardNumber.mask}
      </Box>
      <PaymentLabel label={paymentMethod?.label} />
    </Box>
  )
}
