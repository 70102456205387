import { Badge, Flex, Heading, Stack } from '@gr4vy/poutine-react'
import { Fragment } from 'react'
import { Summary } from 'shared/components/Summary'
import { DirectoryResponseInfo } from 'transactions/components/ThreeDSecurePanel/DirectoryResponseInfo'
import { ECIInfo } from 'transactions/components/ThreeDSecurePanel/ECIInfo'
import { TransactionEvent } from 'transactions/services'
import { RequestEvent } from './RequestEvent'
import styles from './styles.module.scss'

export const ThreeDSecureResultRequestEvent = ({
  event,
}: {
  event?: TransactionEvent
}) => {
  const version = (event: TransactionEvent) => {
    try {
      return JSON.parse(event.context['response'])['messageVersion']
    } catch {
      return '-'
    }
  }

  return event ? (
    <Stack gap={32}>
      {event.context['response'] && (
        <Stack gap={8} alignItems="flex-start">
          <Heading as="h5" level={5}>
            Version
          </Heading>
          <Badge variant="subtle" color="neutral">
            {version(event)}
          </Badge>
        </Stack>
      )}
      {event.context['isChallengeCancelled'] && (
        <Stack gap={8} alignItems="flex-start">
          <Heading as="h5" level={5}>
            Challenge
          </Heading>
          <Badge variant="subtle" color="negative">
            Cancelled
          </Badge>
        </Stack>
      )}
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Result
        </Heading>
        <Summary className={styles.summary} inline>
          <Summary.Key>Status</Summary.Key>
          <Summary.Value fallback={!event.context['authenticationResponse']}>
            <Flex gap={4}>
              {event.context['authenticationResponse']}
              <DirectoryResponseInfo
                code={event.context['authenticationResponse']}
                version="2"
              />
            </Flex>
          </Summary.Value>
          {event.context['eci'] && (
            <Fragment>
              <Summary.Key>ECI</Summary.Key>
              <Summary.Value fallback={!event.context['eci']}>
                <Flex gap={4}>
                  {event.context['eci']}
                  <ECIInfo eci={event.context['eci']} />
                </Flex>
              </Summary.Value>
            </Fragment>
          )}
          {event.context['cavv'] && (
            <Fragment>
              <Summary.Key>CAVV</Summary.Key>
              <Summary.Value fallback={!event.context['cavv']}>
                {event.context['cavv']}
              </Summary.Value>
            </Fragment>
          )}
          <Summary.Key>Transaction ID</Summary.Key>
          <Summary.Value fallback={!event.context['directoryTransactionId']}>
            {event.context['directoryTransactionId']}
          </Summary.Value>
        </Summary>
      </Stack>
      <RequestEvent event={event} />
    </Stack>
  ) : null
}
