import { matchPath } from 'react-router-dom'

export const getMerchantAccountIdFromUrl = () => {
  return matchPath(
    {
      path: '/merchants/:merchantAccoundId',
      end: false,
    },
    location.pathname
  )?.params.merchantAccoundId
}

export const getAdminPathFromUrl = () => {
  return matchPath(
    {
      path: '/admin/',
      end: false,
    },
    location.pathname
  )
}

export const getEntityPathFromUrl = () => {
  const merchantAccountId = getMerchantAccountIdFromUrl()
  if (!merchantAccountId) {
    return null
  }

  return window.location.pathname
    .replace(`/merchants/${merchantAccountId}`, '')
    .split('/')[1]
}
