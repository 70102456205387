import { Grid, GridItem, Text, Divider } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { ConnectionCard } from 'connections/components/ConnectionCard/ConnectionCard'
import { digitalWalletFilter } from 'connections/hooks/use-catalog-search'
import {
  ConnectionDefinition,
  ConnectionDefinitionCategory,
} from 'connections/services'
import { pathTo } from 'shared/paths/connections'

export const CatalogCategory = ({
  category,
  categoryName,
  connectionDefinitions,
}: {
  category: ConnectionDefinitionCategory
  categoryName: string
  connectionDefinitions: ConnectionDefinition[]
}) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }

  if (connectionDefinitions.length === 0) {
    return null
  }

  return (
    <>
      <Divider width="md" />
      <Text variant="overline1" id={category}>
        {categoryName}
      </Text>
      <Grid>
        {connectionDefinitions
          .filter(digitalWalletFilter)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ id, group, iconUrl, provider, name, count }) => (
            <GridItem key={id} gridColumn="span 4">
              <ConnectionCard
                url={pathTo.addConnectionSelector(merchantAccountId, group, id)}
              >
                <ConnectionCard.Banner iconUrl={iconUrl} tag={provider} />
                <ConnectionCard.Content
                  title={name}
                  subTitle={
                    count > 0 ? `${count} account${count > 1 ? 's' : ''}` : ''
                  }
                />
              </ConnectionCard>
            </GridItem>
          ))}
      </Grid>
    </>
  )
}
