import currencyFormat from 'shared/helpers/currency-format'
import { Transaction } from 'transactions/services'

export type HistoryData = {
  amount: number
  event: string
  status: string
}

export const getTooltipTransactionHistory = (transaction: Transaction) => {
  const transactionHistory: HistoryData[] = []

  if (transaction.intent === 'authorize') {
    transactionHistory.unshift({
      amount: currencyFormat(transaction.authorizedAmount, {
        currency: transaction.currency,
      }),
      event: 'Authorized',
      status: 'Succeeded',
    })
  }

  if (transaction.capturedAmount > 0) {
    transactionHistory.unshift({
      amount: currencyFormat(transaction.capturedAmount, {
        currency: transaction.currency,
      }),
      event: 'Captured',
      status: 'Completed',
    })
  }

  if (transaction.refundedAmount > 0) {
    transactionHistory.unshift({
      amount: currencyFormat(transaction.refundedAmount, {
        currency: transaction.currency,
      }),
      event: 'Refunded',
      status: 'Succeeded',
    })
  }

  return [...transactionHistory]
}
