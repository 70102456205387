import { Badge, Heading, Stack } from '@gr4vy/poutine-react'
import { Fragment } from 'react'
import { Summary } from 'shared/components/Summary'
import { formatSectionName } from 'transactions/helpers'
import { TransactionEvent } from 'transactions/services'
import styles from './styles.module.scss'

export const ThreeDSecureSuccessEvent = ({
  event,
}: {
  event?: TransactionEvent
}) => {
  return event ? (
    <Stack gap={32}>
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Method
        </Heading>
        <Badge variant="subtle">{event.context['method']}</Badge>
      </Stack>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Response
        </Heading>
        <Summary className={styles.summary} inline>
          {Object.keys(event.context)
            .sort()
            .map((item) => (
              <Fragment key={item}>
                <Summary.Key>{formatSectionName(item)}</Summary.Key>
                <Summary.Value fallback={!event.context[item]}>
                  {event.context[item]}
                </Summary.Value>
              </Fragment>
            ))}
        </Summary>
      </Stack>
    </Stack>
  ) : null
}
