import axios, { AxiosError } from 'axios'
import { applePayDafErrors } from 'connections/constants'
import { isSandbox } from 'shared/helpers/is-sandbox'
import client, { CollectionResponse } from 'shared/services/client'

export interface ApplePayDomain {
  id: string
  displayName: string
}

export const listApplePayDomains = async (
  id: string
): Promise<CollectionResponse<ApplePayDomain>> => {
  const response = await client.get(`/digital-wallets/${id}`)
  const items = response.data?.domainNames
    ? response.data.domainNames.map((domain: string, idx: number) => ({
        id: idx + 1,
        displayName: domain,
      }))
    : []
  return {
    items,
    limit: 20,
  }
}

export const getApplePayDaf = (baseURL = '') => {
  return axios.request({
    baseURL,
    url: '/.well-known/apple-developer-merchantid-domain-association',
  })
}

export const validateApplePayDaf = async (domainName: string) => {
  const [
    applePayDafNotDetectedError,
    applePayDafIncorrectError,
    applePayDafRedirectError,
  ] = applePayDafErrors

  if (isSandbox) {
    return {}
  }

  try {
    const url = `https://${domainName}`
    const { data: gr4vyDafData } = await getApplePayDaf()
    const { data, request } = await getApplePayDaf(url)

    // Check for redirects by comparing the url in the 301
    // response url and the one of the domain being registered.
    if (new URL(request.responseURL).origin !== new URL(url).origin) {
      throw applePayDafRedirectError
    }

    // Check if the content differs.
    if (gr4vyDafData !== data) {
      throw applePayDafIncorrectError
    }

    return { data }
  } catch (error) {
    const { code, response } = error as AxiosError
    const status = response?.status

    if (status && status === 301) {
      return { error: applePayDafRedirectError }
    }

    if (status && [401, 403, 404].includes(status)) {
      return { error: applePayDafNotDetectedError }
    }

    if (code === 'ERR_NETWORK') {
      return { skip: true }
    }

    return { error }
  }
}

export const addApplePayDomain = async (
  id: string,
  domainName: string
): Promise<ApplePayDomain> => {
  const { error, skip } = await validateApplePayDaf(domainName)
  if (error && !skip) {
    return Promise.reject({ response: { data: { message: error } } })
  }

  const { data } = await client.post(`/digital-wallets/${id}/domains`, {
    domainName,
  })
  return data
}

export const removeApplePayDomain = (id: string, domainName: string) =>
  client.delete(`/digital-wallets/${id}/domains`, { data: { domainName } })
