import { Box, ButtonCopy, Flex, Text } from '@gr4vy/poutine-react'

interface CopyProps {
  text: string
  fullWidth?: boolean
}

export const Copy = ({ text, fullWidth = true }: CopyProps) => (
  <Box
    borderStyle="solid"
    borderColor="gray50"
    borderWidth="sm"
    borderRadius="rounded"
    paddingY={4}
    paddingX={8}
    paddingRight={4}
    display={fullWidth ? 'block' : 'inline-block'}
    background="white"
  >
    <Flex justifyContent="space-between" gap={8} alignItems="center">
      <Text style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>{text}</Text>
      <ButtonCopy type="button" variant="tertiary" copy={text} size="small" />
    </Flex>
  </Box>
)

export default Copy
