import { Button, Stack, Flex, Heading, Text } from '@gr4vy/poutine-react'
import { useReportsPermission } from 'reports/hooks/use-reports-permission'
import styles from './ReportCard.module.scss'

interface ReportCardProps {
  id: string
  title: string
  description: string
  onGenerateClick?: () => void
  onScheduleClick: () => void
}

export const ReportCard = ({
  id,
  title,
  description,
  onGenerateClick,
  onScheduleClick,
}: ReportCardProps) => {
  const { hasWritePermission } = useReportsPermission()

  return (
    <Stack id={id} className={styles.box}>
      <Stack className={styles.details}>
        <Heading level={5} className={styles.title}>
          {title}
        </Heading>
        <Text className={styles.description}>{description}</Text>
      </Stack>
      {hasWritePermission && (
        <Flex className={styles.actions}>
          {onGenerateClick && (
            <Button size="small" onClick={onGenerateClick}>
              Generate
            </Button>
          )}
          <Button size="small" variant="secondary" onClick={onScheduleClick}>
            Schedule
          </Button>
        </Flex>
      )}
    </Stack>
  )
}
