import {
  additionalPermissionNames,
  getPermissionNameByRoleName,
} from 'users/constants'
import { User, UserRole } from 'users/services/users'

export const getRolesAndPermissionsNames = (userRoles: UserRole[]) => {
  const rolesAndPermissions = userRoles.reduce(
    (result: { roles: string[]; permissions: string[] }, userRole) => {
      if (additionalPermissionNames.includes(userRole.name)) {
        return {
          ...result,
          permissions: [
            ...result.permissions,
            getPermissionNameByRoleName(userRole.name),
          ],
        }
      }

      return {
        ...result,
        roles: [...result.roles, userRole.name],
      }
    },
    { roles: [], permissions: [] }
  )

  return {
    rolesNames: !!rolesAndPermissions.roles.length
      ? rolesAndPermissions.roles.join(', ')
      : 'None',
    additionalPermissionsNames: !!rolesAndPermissions.permissions.length
      ? rolesAndPermissions.permissions.join(', ')
      : 'None',
  }
}

export const getPermissionRoleIds = (roles: UserRole[]) =>
  roles
    ?.filter((role) => additionalPermissionNames.includes(role.name))
    ?.map((role) => role.id) ?? []

export const isSelectedUserAnAdmin = (user?: User) =>
  !!user?.roles.find((role: UserRole) => role.name === 'Administrator')
