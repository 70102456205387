import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { Filters } from 'audit-logs/pages/AuditLogsBrowsePage/AuditLogsBrowsePage'
import { listAuditLogEntries } from 'audit-logs/services'
import { GET_USER, USERS, USER_ACTIVITY } from 'shared/constants'
import { useCursor, useFilters } from 'shared/hooks'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import { handleSuccessResponse } from 'shared/utils/handleSuccessResponse'
import { getUser, removeUser } from 'users/services/users'
import View from './UserOverviewPage'

export default function UserOverviewPage() {
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }
  const [cursor] = useCursor()
  const [filters, setFilters] = useFilters()
  const queryClient = useQueryClient()
  const hasAuditLogsPermission = useResourcePermission(
    Resource.auditLogs,
    AccessLevel.read
  )
  const { data: user } = useQuery({
    queryKey: [GET_USER, id, merchantAccountId],
    queryFn: () => getUser(id),
    meta: {
      error: {
        redirect: true,
      },
    },
    refetchOnWindowFocus: false,
  })

  const invalidateUsers = () =>
    queryClient.invalidateQueries({ queryKey: [USERS] })

  const onRemove = useMutation({
    mutationFn: removeUser,
    onSuccess: handleSuccessResponse('User removed', invalidateUsers),
    onError: handleErrorResponse,
  })

  const page = useQuery({
    queryKey: [USER_ACTIVITY, user?.id, merchantAccountId, cursor, filters],
    queryFn: () =>
      listAuditLogEntries({ cursor, userId: user?.id, ...filters }),
    enabled: !!user?.id && hasAuditLogsPermission,
  })

  const onFilter = (f: Filters) => {
    setFilters({ ...filters, ...f })
  }

  return (
    <View
      user={user}
      filters={filters}
      onFilter={onFilter}
      auditLogs={page}
      remove={onRemove}
    />
  )
}
