import { Dropdown } from '@gr4vy/poutine-react'

export interface ActionsDropdownProps {
  isReadOnlyPermission: boolean
  onDelete: () => void
}

export const ActionsDropdown = ({
  isReadOnlyPermission,
  onDelete,
}: ActionsDropdownProps) => (
  <Dropdown>
    <Dropdown.Trigger asChild>
      <Dropdown.Button variant="primary">Actions</Dropdown.Button>
    </Dropdown.Trigger>
    <Dropdown.Content align="end">
      <Dropdown.Item
        variant="negative"
        opensInModal
        onSelect={onDelete}
        disabled={isReadOnlyPermission}
      >
        Delete connection
      </Dropdown.Item>
    </Dropdown.Content>
  </Dropdown>
)
