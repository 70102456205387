import {
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
  TextLink,
  Checkbox,
} from '@gr4vy/poutine-react'
import { Input } from 'antd'
import { useState } from 'react'
import { AddConnectionForm } from 'connections/components/AddConnectionForm/AddConnectionForm'
import { CredentialsChecker } from 'connections/components/CredentialsChecker'
import { fieldsObjToArray } from 'connections/helpers/fields'
import {
  GiftCardServiceDefinition,
  GiftCardService,
  verifyGiftCardServiceCredentials,
} from 'connections/services'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'

type FormValues = Pick<GiftCardService, 'active' | 'displayName'> & {
  fields: Record<string, any>
}

type BodyProps = Omit<FormValues, 'fields'> & {
  fields: { key: string; value: string }[]
  giftCardServiceDefinitionId: string
}

interface GiftCardServiceAddPageProps {
  definition: GiftCardServiceDefinition
  saving: boolean
  onSubmit: (body: BodyProps) => void
  onCancel: () => void
}

export const GiftCardServiceAddPage = ({
  definition,
  saving,
  onSubmit,
  onCancel,
}: GiftCardServiceAddPageProps) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    active: true,
    displayName: definition.displayName,
    fields: {},
  })

  const handleSubmit = ({ active, displayName, fields }: FormValues) => {
    setInitialValues({
      active,
      displayName,
      fields,
    })

    onSubmit({
      active,
      displayName,
      giftCardServiceDefinitionId: definition.id,
      fields: fieldsObjToArray(fields),
    })
  }

  return (
    <AddConnectionForm
      saving={saving}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      title="Add new connection"
    >
      <Grid>
        <GridItem gridColumn="span 6">
          <Stack gap={16}>
            <Stack gap={32}>
              <Stack gap={8}>
                <Heading level={4}>Credentials</Heading>
                <Text>
                  Learn how to configure {definition.displayName} in{' '}
                  <TextLink
                    href={`https://docs.gr4vy.com/connections/payments/${definition.id}`}
                    opensInNewWindow
                  >
                    this quick start guide.
                  </TextLink>{' '}
                  We will help you find all the information you need to set up
                  your payment service correctly.
                </Text>
              </Stack>
            </Stack>
            <Stack gap={24}>
              {definition.fields.map((item) => (
                <FormItem
                  key={item.key}
                  label={<Label>{item.displayName}</Label>}
                  name={['fields', item.key]}
                  required={item.required}
                  rules={[
                    {
                      required: item.required,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Input />
                </FormItem>
              ))}
              <CredentialsChecker
                fieldNames={definition?.fields.map((field) => [
                  'fields',
                  field.key,
                ])}
                verifyCredentials={verifyGiftCardServiceCredentials}
                giftCardServiceDefinitionId={definition.id}
              />
            </Stack>
          </Stack>
        </GridItem>
      </Grid>
      <Grid>
        <GridItem gridColumn="span 6">
          <Stack gap={24}>
            <FormItem
              name="active"
              label={<Label>Connection status</Label>}
              valuePropName="checked"
              trigger="onCheckedChange"
            >
              <Checkbox>Active</Checkbox>
            </FormItem>
          </Stack>
        </GridItem>
      </Grid>
    </AddConnectionForm>
  )
}
