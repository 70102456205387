import { formatRelative, isValid } from 'date-fns'
import { getCurrentLocale } from './locale'

export const dateFormat = (
  value: string,
  locale?: string,
  options?: Intl.DateTimeFormatOptions
) => new Date(Date.parse(value)).toLocaleString(locale, options || {})

/**
 * Localizes a standard date returned by formatRelative
 * @param date Date string
 * @returns string Date in relative or standard format
 */
export const dateFormatRelative = (date: string, baseDate?: Date | number) => {
  const formatted = formatRelative(Date.parse(date), baseDate || Date.now())
  return isValid(new Date(formatted))
    ? dateFormat(formatted, getCurrentLocale(), {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      })
    : formatted
}

export const dateFormatHeader = (date?: string) =>
  dateFormat(date ?? '', getCurrentLocale(), {
    dateStyle: 'long',
    timeStyle: 'medium',
  })

/**
 * Takes a string like '04/23' and returns a formatted value e.g. '04 / 2023'
 * @param monthYear string representing a month and year
 * @returns a formatted month year with a full year i.e. 20XX
 */
export const monthYearFormatter = (monthYear: string | undefined) => {
  if (!monthYear || !monthYear.includes('/')) {
    return undefined
  }

  const [month, year] = monthYear.split('/')

  return `${month.trim()} / 20${year.trim()}`
}

export default dateFormat
