import { Box, Heading, Stack } from '@gr4vy/poutine-react'
import { Panel } from 'shared/components/Panel'
import { SummaryGrid } from 'shared/components/SummaryGrid'
import { countryCodes } from 'shared/constants'
import { BuyerTaxId } from 'transactions/services'
import styles from './TaxInformationPanel.module.scss'

function TaxInformationPanel({ taxId }: { taxId?: BuyerTaxId }) {
  const [countryCode, taxType] = taxId?.kind.split('.') || []

  return (
    <Box>
      <Panel height="full" className={styles.panel}>
        <Panel.Header>
          <Heading level={4}>Tax information</Heading>
        </Panel.Header>
        <Panel.Content>
          <Stack gap={48}>
            <SummaryGrid
              gridTemplateColumns={{ xs: 1, md: 1, lg: 1 }}
              width={240}
            >
              <SummaryGrid.Item>
                <SummaryGrid.Key>Tax ID</SummaryGrid.Key>
                <SummaryGrid.Value>{taxId?.value}</SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>Tax type</SummaryGrid.Key>
                <SummaryGrid.Value>
                  {taxType && taxType.toUpperCase()}
                </SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>Country</SummaryGrid.Key>
                <SummaryGrid.Value>
                  {countryCode && countryCodes[countryCode.toUpperCase()].name}
                </SummaryGrid.Value>
              </SummaryGrid.Item>
            </SummaryGrid>
          </Stack>
        </Panel.Content>
      </Panel>
    </Box>
  )
}

export default TaxInformationPanel
