import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
  TextLink,
} from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { omit } from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MerchantPagesLayout } from 'admin/components/MerchantsPageLayout'
import { pathTo } from 'admin/paths'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import {
  MerchantAccount,
  UpdateMerchantAccountNetworkTokens,
} from 'shared/services/merchant-accounts'

interface EditMerchantAccountNetworkTokensPageProps {
  title: string
  merchantAccount: MerchantAccount
  update: UseMutationResult<
    MerchantAccount,
    any,
    UpdateMerchantAccountNetworkTokens,
    unknown
  >
}

interface FieldData {
  name: string | number | (string | number)[]
  value?: any
  touched?: boolean
  validating?: boolean
  errors?: string[]
}

const VALIDATION_MESSAGE_APP_ID = 'App ID is required when Requestor ID is set.'
const VALIDATION_MESSAGE_REQUESTOR_ID =
  'Requestor ID is required when App ID is set.'

const EditMerchantAccountNetworkTokensPage = ({
  title,
  merchantAccount,
  update,
}: EditMerchantAccountNetworkTokensPageProps) => {
  const navigate = useNavigate()

  const [form] = Form.useForm()
  const [visaFieldsRequired, setVisaFieldsRequired] = useState(false)
  const [mastercardFieldsRequired, setMastercardFieldsRequired] =
    useState(false)
  const [amexFieldsRequired, setAmexFieldsRequired] = useState(false)

  const onChange = (changedFields: FieldData[]): void => {
    const setFields = changedFields.reduce(
      (acc: Record<string, boolean>, field) => {
        acc[field.name.toString()] = field.value && field.value != ''

        return acc
      },
      {}
    )

    setVisaFieldsRequired(
      setFields?.visaNetworkTokensAppId ||
        setFields?.visaNetworkTokensRequestorId
    )
    setMastercardFieldsRequired(
      setFields?.mastercardNetworkTokensAppId ||
        setFields?.mastercardNetworkTokensRequestorId
    )
    setAmexFieldsRequired(
      setFields?.amexNetworkTokensAppId ||
        setFields?.amexNetworkTokensRequestorId
    )
  }

  const onSubmit =
    (merchantAccount: MerchantAccount) =>
    async (values: UpdateMerchantAccountNetworkTokens) => {
      await form.validateFields()
      update.mutate({
        ...omit(merchantAccount, ['type', 'createdAt', 'updatedAt']),
        ...values,
      })
    }

  return (
    <MerchantPagesLayout
      title={title}
      breadcrumbs={[
        { title: 'Merchants', url: pathTo.merchants },
        { title: merchantAccount.displayName },
      ]}
    >
      <Grid>
        <GridItem gridColumn="span 6">
          <Stack gap={32}>
            <Text>
              Learn more about Network Tokens in{' '}
              <TextLink
                href="https://docs.gr4vy.com/guides/features/recurring-payments/network-tokens"
                opensInNewWindow
              >
                the documentation.
              </TextLink>
            </Text>
            <Form
              form={form}
              name="update-merchant-network-tokens"
              layout="vertical"
              autoComplete="off"
              requiredMark={false}
              initialValues={{
                visaNetworkTokensAppId: merchantAccount?.visaNetworkTokensAppId,
                visaNetworkTokensRequestorId:
                  merchantAccount?.visaNetworkTokensRequestorId,
                mastercardNetworkTokensAppId:
                  merchantAccount?.mastercardNetworkTokensAppId,
                mastercardNetworkTokensRequestorId:
                  merchantAccount?.mastercardNetworkTokensRequestorId,
                amexNetworkTokensAppId: merchantAccount?.amexNetworkTokensAppId,
                amexNetworkTokensRequestorId:
                  merchantAccount?.amexNetworkTokensRequestorId,
              }}
              onFieldsChange={onChange}
              onFinish={onSubmit(merchantAccount)}
            >
              <Stack gap={32}>
                <Stack gap={16}>
                  <Heading level={5} as="h2">
                    Visa
                  </Heading>
                  <FormItem
                    name="visaNetworkTokensAppId"
                    label={<Label>App ID</Label>}
                    rules={[
                      {
                        required: visaFieldsRequired,
                        message: VALIDATION_MESSAGE_APP_ID,
                      },
                    ]}
                  >
                    <Input maxLength={300} />
                  </FormItem>
                  <FormItem
                    name="visaNetworkTokensRequestorId"
                    label={<Label>Requestor ID</Label>}
                    rules={[
                      {
                        required: visaFieldsRequired,
                        message: VALIDATION_MESSAGE_REQUESTOR_ID,
                      },
                    ]}
                  >
                    <Input maxLength={300} />
                  </FormItem>
                </Stack>
                <Stack gap={16}>
                  <Heading level={5} as="h2">
                    Mastercard
                  </Heading>
                  <FormItem
                    name="mastercardNetworkTokensAppId"
                    label={<Label>App ID</Label>}
                    rules={[
                      {
                        required: mastercardFieldsRequired,
                        message: VALIDATION_MESSAGE_APP_ID,
                      },
                    ]}
                  >
                    <Input maxLength={300} />
                  </FormItem>
                  <FormItem
                    name="mastercardNetworkTokensRequestorId"
                    label={<Label>Requestor ID</Label>}
                    rules={[
                      {
                        required: mastercardFieldsRequired,
                        message: VALIDATION_MESSAGE_REQUESTOR_ID,
                      },
                    ]}
                  >
                    <Input maxLength={300} />
                  </FormItem>
                </Stack>
                <Stack gap={16}>
                  <Heading level={5} as="h2">
                    American Express (AMEX)
                  </Heading>
                  <FormItem
                    name="amexNetworkTokensAppId"
                    label={<Label>App ID</Label>}
                    rules={[
                      {
                        required: amexFieldsRequired,
                        message: VALIDATION_MESSAGE_APP_ID,
                      },
                    ]}
                  >
                    <Input maxLength={300} />
                  </FormItem>
                  <FormItem
                    name="amexNetworkTokensRequestorId"
                    label={<Label>Requestor ID</Label>}
                    rules={[
                      {
                        required: amexFieldsRequired,
                        message: VALIDATION_MESSAGE_REQUESTOR_ID,
                      },
                    ]}
                  >
                    <Input maxLength={300} />
                  </FormItem>
                </Stack>
              </Stack>
            </Form>
            <Flex gap={16}>
              <Button
                form="update-merchant-network-tokens"
                variant="primary"
                loading={update.isPending}
              >
                Update
              </Button>
              <Button
                variant="secondary"
                disabled={update.isPending}
                onClick={() => navigate(pathTo.merchants)}
              >
                Cancel
              </Button>
            </Flex>
          </Stack>
        </GridItem>
      </Grid>
    </MerchantPagesLayout>
  )
}

export default EditMerchantAccountNetworkTokensPage
