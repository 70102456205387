import { SummaryGrid } from 'shared/components/SummaryGrid'
import { SummaryGroup } from 'shared/components/SummaryGroup'
import { countryCodes } from 'shared/constants'
import { ContactDetails } from 'transactions/services'

export function ContactDetailFields({
  contactDetails,
  title,
}: {
  contactDetails?: ContactDetails
  title: string
}) {
  const address = contactDetails?.address
  return (
    <SummaryGroup title={title}>
      <SummaryGrid gridTemplateColumns={{ xs: 1, md: 2, lg: 3 }}>
        <SummaryGrid.Item>
          <SummaryGrid.Key>First name</SummaryGrid.Key>
          <SummaryGrid.Value>{contactDetails?.firstName}</SummaryGrid.Value>
        </SummaryGrid.Item>
        <SummaryGrid.Item>
          <SummaryGrid.Key>Last name</SummaryGrid.Key>
          <SummaryGrid.Value>{contactDetails?.lastName}</SummaryGrid.Value>
        </SummaryGrid.Item>
        <SummaryGrid.Item>
          <SummaryGrid.Key>Email</SummaryGrid.Key>
          <SummaryGrid.Value>{contactDetails?.emailAddress}</SummaryGrid.Value>
        </SummaryGrid.Item>
        <SummaryGrid.Item>
          <SummaryGrid.Key>House number</SummaryGrid.Key>
          <SummaryGrid.Value>{address?.houseNumberOrName}</SummaryGrid.Value>
        </SummaryGrid.Item>
        <SummaryGrid.Item>
          <SummaryGrid.Key>Address line 1</SummaryGrid.Key>
          <SummaryGrid.Value>{address?.line1}</SummaryGrid.Value>
        </SummaryGrid.Item>
        <SummaryGrid.Item>
          <SummaryGrid.Key>Address line 2</SummaryGrid.Key>
          <SummaryGrid.Value>{address?.line2}</SummaryGrid.Value>
        </SummaryGrid.Item>
        <SummaryGrid.Item>
          <SummaryGrid.Key>City</SummaryGrid.Key>
          <SummaryGrid.Value>{address?.city}</SummaryGrid.Value>
        </SummaryGrid.Item>
        <SummaryGrid.Item>
          <SummaryGrid.Key>State</SummaryGrid.Key>
          <SummaryGrid.Value>{address?.state}</SummaryGrid.Value>
        </SummaryGrid.Item>
        <SummaryGrid.Item>
          <SummaryGrid.Key>Post code</SummaryGrid.Key>
          <SummaryGrid.Value>{address?.postalCode}</SummaryGrid.Value>
        </SummaryGrid.Item>
        <SummaryGrid.Item>
          <SummaryGrid.Key>Country</SummaryGrid.Key>
          <SummaryGrid.Value>
            {address?.country &&
              countryCodes[address.country.toUpperCase()].name}
          </SummaryGrid.Value>
        </SummaryGrid.Item>
        <SummaryGrid.Item>
          <SummaryGrid.Key>Organization</SummaryGrid.Key>
          <SummaryGrid.Value>{address?.organization}</SummaryGrid.Value>
        </SummaryGrid.Item>
        <SummaryGrid.Item>
          <SummaryGrid.Key>Phone number</SummaryGrid.Key>
          <SummaryGrid.Value>{contactDetails?.phoneNumber}</SummaryGrid.Value>
        </SummaryGrid.Item>
      </SummaryGrid>
    </SummaryGroup>
  )
}
