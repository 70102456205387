import {
  TextLine,
  Description,
  Flex,
  Tooltip,
  ButtonLink,
} from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { PaymentMethodTableColumnId } from 'buyers/constants'
import { PaymentMethod } from 'buyers/services/paymentMethods'
import {
  DataTable,
  ColumnDef,
  DataTableProps,
  TimeDate,
} from 'shared/components/DataTable'
import { monthYearFormatter } from 'shared/helpers/date-format'
import {
  useCardSchemeDefinition,
  usePaymentMethodDefinition,
} from 'shared/hooks'
import { pathTo } from 'shared/paths/payment-methods'
import { QueryResult } from 'shared/services/client'
import { AdditionalSchemesTooltip } from 'transactions/components/AdditionalSchemesTooltip'
import { MaskedCardNumber } from 'transactions/components/MaskedCardNumber'
import { PaymentMethodStatus } from 'transactions/components/PaymentMethodStatus'

export type PaymentMethodsTableProps = Pick<
  DataTableProps<PaymentMethod>,
  'pagination'
> & { page: QueryResult<PaymentMethod> }

const PaymentMethodsTable = ({
  page,
  pagination,
}: PaymentMethodsTableProps) => {
  const { data, isLoading: loading } = page

  const columns: Array<ColumnDef<PaymentMethod>> = [
    {
      header: 'Type',
      accessorKey: 'method',
      size: 195,
      cell: function Method({ getValue }) {
        const details =
          usePaymentMethodDefinition(getValue<PaymentMethod['method']>())
        return (
          <Description>
            <Description.Icon src={details?.iconUrl} size={24} />
            {!!details?.displayName && (
              <Description.Text>{details?.displayName}</Description.Text>
            )}
          </Description>
        )
      },
    },
    {
      header: 'Label',
      accessorKey: 'label',
      size: 207,
      cell: function PaymentMethodLabel({ row }) {
        const paymentMethod = row.original as PaymentMethod
        const definition = usePaymentMethodDefinition(paymentMethod?.method)

        return paymentMethod?.method === 'card' ? (
          <MaskedCardNumber paymentMethod={paymentMethod} />
        ) : (
          <TextLine display="block">
            {paymentMethod?.label || definition?.displayName || '-'}
          </TextLine>
        )
      },
    },
    {
      header: 'Scheme',
      accessorKey: 'scheme',
      size: 250,
      cell: function PaymentMethodScheme({ row }) {
        const scheme = useCardSchemeDefinition(row.original.scheme)
        return scheme ? (
          <Flex gap={8} alignItems="center">
            <Description>
              <Description.Icon src={scheme.iconUrl} size={24} />
              <Description.Text>{scheme.displayName}</Description.Text>
            </Description>
            {!!row.original?.additionalSchemes?.length && (
              <AdditionalSchemesTooltip
                additionalSchemes={row.original.additionalSchemes}
              />
            )}
          </Flex>
        ) : (
          '-'
        )
      },
    },
    {
      header: 'Expiration',
      accessorKey: 'expirationDate',
      size: 100,
      cell: ({ getValue }) =>
        getValue<PaymentMethod['expirationDate']>() ? (
          <TextLine display="block">
            {
              monthYearFormatter(
                getValue<PaymentMethod['expirationDate']>()
              ) as string
            }
          </TextLine>
        ) : (
          '-'
        ),
    },
    {
      header: 'Currency',
      accessorKey: 'currency',
      size: 80,
      cell: ({ row }) => {
        const paymentMethod = row.original as PaymentMethod
        return paymentMethod?.currency ? (
          <TextLine display="block">{paymentMethod.currency}</TextLine>
        ) : (
          '-'
        )
      },
    },
    {
      header: 'Status',
      accessorKey: 'status',
      size: 144,
      cell: ({ row }) => {
        const paymentMethod = row.original as PaymentMethod

        return <PaymentMethodStatus paymentMethod={paymentMethod} />
      },
    },
    {
      header: 'Created',
      accessorKey: 'createdAt',
      size: 100,
      cell: ({ getValue }) => {
        const createdAt = getValue<PaymentMethod['createdAt']>()
        return <TimeDate value={createdAt} />
      },
    },
    {
      id: PaymentMethodTableColumnId.ACTIONS,
      header: '',
      size: 64,
      cell: function ActionsCell({ row }) {
        const paymentMethod = row.original
        const { merchantAccountId } = useParams() as {
          merchantAccountId: string
        }

        return paymentMethod ? (
          <Flex justifyContent="flex-end">
            <Tooltip content="Go to payment method">
              <ButtonLink
                variant="tertiary"
                size="small"
                href={pathTo.paymentMethodId(
                  merchantAccountId,
                  paymentMethod.id
                )}
              />
            </Tooltip>
          </Flex>
        ) : null
      },
    },
  ]

  return (
    <DataTable
      data={data}
      loading={loading}
      columns={columns}
      pagination={pagination}
    />
  )
}

export default PaymentMethodsTable
