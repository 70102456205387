import {
  DefaultError,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import {
  getReport,
  createReport,
  getReports,
  ReportPayload,
  updateReport,
  ReportsFilters,
} from 'reports/services/report'
import { REPORTS } from 'shared/constants/query-keys'

interface CreateReportProps {
  name: string
  schedule?: ReportPayload['schedule']
  scheduleEnabled?: ReportPayload['scheduleEnabled']
  scheduleTimezone: ReportPayload['scheduleTimezone']
  spec: ReportPayload['spec']
}

type QueryFnData = Awaited<ReturnType<typeof getReports>>

const useReports = <TData = QueryFnData>(
  params: Partial<ReportsFilters>,
  options?: Omit<
    UseQueryOptions<QueryFnData, DefaultError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }

  return useQuery({
    queryKey: [REPORTS, merchantAccountId, params],
    queryFn: () => getReports(params),
    meta: {
      error: {
        redirect: true,
      },
    },
    ...options,
  })
}

const useReport = (merchantAccountId: string, id: string) =>
  useQuery({
    queryKey: [REPORTS, id, merchantAccountId],
    queryFn: () => getReport(id),
    meta: {
      error: {
        redirect: true,
      },
    },
  })

const useCreateReport = () =>
  useMutation({
    mutationFn: (data: CreateReportProps) => createReport(data),
  })

const useUpdateReport = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (
      data: Partial<
        Pick<ReportPayload, 'id' | 'name' | 'description' | 'scheduleEnabled'>
      >
    ) => updateReport(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [REPORTS] })
    },
  })
}

export { useCreateReport, useUpdateReport, useReports, useReport }
