import { Box, Button, Description, Dropdown, Icon } from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { useState } from 'react'
import { EditApiKeyPairModal } from 'integrations/modals/EditApiKeyPairModal'
import { ApiKeyPair, UpdateApiKeyPair } from 'integrations/services/apiKeyPairs'
import {
  ColumnDef,
  DataTable,
  TimeDate,
  DataTableProps,
} from 'shared/components/DataTable'
import { ModalRemove } from 'shared/components/Modal/ModalRemove'
import { useMultiMerchant } from 'shared/permissions/hooks/use-multi-merchant'
import { QueryResult } from 'shared/services/client'

export type ApiKeysTableProps = Pick<
  DataTableProps<ApiKeyPair>,
  'pagination'
> & {
  page: QueryResult<ApiKeyPair>
  updateApiKey: UseMutationResult<ApiKeyPair, any, UpdateApiKeyPair, unknown>
  removeKey: UseMutationResult<void, any, string, unknown>
}

export const ApiKeysTable = ({
  page,
  pagination,
  updateApiKey,
  removeKey,
}: ApiKeysTableProps) => {
  const { hasMultipleMerchantAccounts } = useMultiMerchant()
  const [removeKeyId, setRemoveKeyId] = useState<string | null>(null)
  const [updateKey, setUpdateKey] = useState<ApiKeyPair | null>(null)
  const { data, isLoading: loading } = page

  const onRemoveKey = (id: string) => {
    removeKey.mutate(id)
  }

  const columns: Array<ColumnDef<ApiKeyPair>> = [
    {
      header: 'Name',
      accessorKey: 'displayName',
      size: 400,
      cell: ({ row }) => {
        const apiKey = row.original as ApiKeyPair
        return (
          <Description>
            <Description.Text>
              {apiKey.displayName == apiKey.thumbprint
                ? '-'
                : apiKey.displayName}
            </Description.Text>
            <Description.SubText>{apiKey.thumbprint}</Description.SubText>
          </Description>
        )
      },
    },
    {
      header: 'Algorithm',
      size: 150,
      cell: ({ row }) => (
        <Description>
          <Description.Text>{row.original.algorithm}</Description.Text>
          <Description.SubText>
            {row.original.algorithm === 'ECDSA' ? 'ES512' : 'RS512'}
          </Description.SubText>
        </Description>
      ),
    },
    {
      header: 'Creator',
      size: 200,
      cell: function CreatorCell({ row }) {
        const { creator } = row.original
        return creator ? (
          <Description>
            <Description.Text>{creator.name}</Description.Text>
            <Description.SubText>{creator.emailAddress}</Description.SubText>
          </Description>
        ) : (
          '-'
        )
      },
    },
    {
      header: 'Created',
      accessorKey: 'createdAt',
      cell: function DatetimeCell({ getValue }) {
        const createdAt = getValue<ApiKeyPair['createdAt']>()
        return <TimeDate value={createdAt} />
      },
    },
    {
      header: () => <Box textAlign="center">Actions</Box>,
      id: 'actions',
      size: 70,
      cell: ({ row }) => {
        const apiKey = row.original as ApiKeyPair
        return (
          <Box textAlign="center">
            <Dropdown>
              <Dropdown.Trigger asChild>
                <Button
                  variant="tertiary"
                  size="small"
                  data-testid="action-menu"
                >
                  <Icon name="more-horizontal" />
                </Button>
              </Dropdown.Trigger>
              <Dropdown.Content align="end">
                <Dropdown.Item
                  opensInModal
                  onSelect={() => setUpdateKey(apiKey)}
                >
                  Rename API key
                </Dropdown.Item>
                <Dropdown.Item
                  opensInModal
                  onSelect={() => setRemoveKeyId(apiKey.id)}
                >
                  Remove API key
                </Dropdown.Item>
              </Dropdown.Content>
            </Dropdown>
          </Box>
        )
      },
    },
  ]

  hasMultipleMerchantAccounts &&
    columns.splice(2, 0, {
      header: 'Merchant',
      size: 200,
      cell: function CreatorCell({ row }) {
        const { merchantAccount } = row.original
        return (
          <Description>
            <Description.Text>
              {merchantAccount?.displayName || 'All merchants'}
            </Description.Text>
          </Description>
        )
      },
    })

  return (
    <>
      {removeKeyId && (
        <ModalRemove
          title="Remove API key"
          onCancel={() => setRemoveKeyId(null)}
          onOk={() => {
            onRemoveKey(removeKeyId)
            setRemoveKeyId(null)
          }}
        >
          You are about to remove this API key and this change is
          non-reversable. Are you sure you want to remove?
        </ModalRemove>
      )}
      {updateKey && (
        <EditApiKeyPairModal
          apiKeyPair={updateKey}
          onUpdate={updateApiKey}
          onClose={() => setUpdateKey(null)}
        />
      )}
      <DataTable
        data={data}
        loading={loading}
        columns={columns}
        pagination={pagination}
      />
    </>
  )
}
