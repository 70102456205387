import { ReactNode } from 'react'
import { PageLayout } from 'shared/components/PageLayout'

interface AdminPagesLayoutProps {
  breadcrumbs?: Array<{
    title: string
    url?: string
  }>
  children: ReactNode
  title: string
  actions?: ReactNode
  loading?: boolean
  tabs?: Array<{ title: string; url: string; end?: boolean }>
}

export const AdminPagesLayout = ({
  title,
  breadcrumbs,
  children,
  loading,
  tabs,
  actions,
}: AdminPagesLayoutProps) => (
  <PageLayout
    title={title}
    breadcrumbs={breadcrumbs}
    isLoading={loading}
    subNavigation={tabs}
    actions={actions}
  >
    {children}
  </PageLayout>
)
