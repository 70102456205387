import { Heading, Stack, StackProps } from '@gr4vy/poutine-react'
import { ReactNode } from 'react'

export interface StepProps extends Omit<StackProps, 'children' | 'title'> {
  children: ReactNode
  title: string
}

export const Step = ({ children, title, ...rest }: StepProps) => (
  <Stack
    background="gray10"
    borderRadius="rounded"
    padding={24}
    gap={8}
    position="relative"
    {...rest}
  >
    <Heading level={4}>{title}</Heading>
    {children}
  </Stack>
)
