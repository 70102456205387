import {
  Checkbox,
  FilterButton,
  Flex,
  Popover,
  Stack,
} from '@gr4vy/poutine-react'
import { useMemo } from 'react'
import { useFilters, Filters } from 'shared/hooks'
import { TransactionStatus } from 'shared/services/transactions'
import { getTransactionStatusConfigs } from 'transactions/constants/transactionStatuses'
import { getToggledTransactionStatus } from 'transactions/helpers/get-toggled-status'
import styles from './TransactionStatusFilter.module.scss'

type TransactionStatusFilterProps = {
  label: string
}

type StatusItemProps = {
  status: TransactionStatus
  label: string
}

interface TransactionFilters extends Filters {
  transactionStatus?: TransactionStatus | TransactionStatus[]
  cursor?: string
}

const TransactionStatusFilter = ({ label }: TransactionStatusFilterProps) => {
  const [filters, setFilters] = useFilters<TransactionFilters>()

  const getTransactionStatusList: StatusItemProps[] = Object.entries(
    getTransactionStatusConfigs()
  ).reduce((acc: StatusItemProps[], [key, { label }]) => {
    return [...acc, { status: key as TransactionStatus, label }]
  }, [])

  const transactionStatusFilter = useMemo(
    () => (filters as TransactionFilters)?.transactionStatus,
    [filters]
  )

  const handleToggleFilter = (status: TransactionStatus) => {
    setFilters({
      ...filters,
      transactionStatus: getToggledTransactionStatus(
        status,
        transactionStatusFilter
      ),
    })
  }

  const statusFilterCounter = useMemo(() => {
    if (!transactionStatusFilter) {
      return null
    }
    return ` / ${[transactionStatusFilter].flat().length}`
  }, [transactionStatusFilter])

  return (
    <Popover>
      <Popover.Trigger asChild>
        <FilterButton
          data-selected={!!transactionStatusFilter}
          data-testid="Status-filter"
        >
          {label}
          {statusFilterCounter}
        </FilterButton>
      </Popover.Trigger>
      <Popover.Content align="start" side="bottom" avoidCollisions={false}>
        <Stack gap={4} className={styles.contentWrapper}>
          {getTransactionStatusList.map((transaction) => (
            <Flex
              key={transaction.status}
              alignItems="center"
              className={styles.statusItem}
            >
              <Checkbox
                id={transaction.status}
                data-testid={`Status-filter-${transaction.status}`}
                onCheckedChange={() => handleToggleFilter(transaction.status)}
                checked={
                  !!transactionStatusFilter?.includes(transaction.status)
                }
              >
                {transaction.label}
              </Checkbox>
            </Flex>
          ))}
        </Stack>
      </Popover.Content>
    </Popover>
  )
}

export default TransactionStatusFilter
