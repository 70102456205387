import {
  Alert,
  atoms,
  Checkbox,
  Drawer,
  Heading,
  Stack,
  Text,
  TextLink,
} from '@gr4vy/poutine-react'
import { Form, Input } from 'antd'
import { MouseEvent, useState } from 'react'
import { EditPaymentServiceForm } from 'connections/components/EditPaymentServiceForm/EditPaymentServiceForm'
import { usePaymentServiceAndDefinition } from 'connections/hooks/use-payment-service-and-definition'
import { useSession } from 'session'
import { Copy } from 'shared/components/Copy'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { Loading } from 'shared/components/Loading'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'

const PaymentServiceOverview = () => {
  const form = Form.useFormInstance()
  const webhookUrl = form.getFieldValue(['webhookUrl'])
  const { paymentService, definition, isLoading } =
    usePaymentServiceAndDefinition()
  const hasPermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )
  const [showOpenLoopDrawer, setShowOpenLoopDrawer] = useState(false)
  const { isStaff } = useSession()

  const paymentMethodTokenizationToggleSupported =
    !paymentService?.openLoop &&
    definition?.supportedFeatures?.paymentMethodTokenizationToggle
  const openLoopToggleSupported =
    !definition?.supportedFeatures?.openLoop &&
    definition?.supportedFeatures?.openLoopToggle
  const networkTokensSupported =
    paymentService?.openLoop &&
    definition?.supportedFeatures?.networkTokensToggle
  const threeDSecureSupported =
    definition?.supportedFeatures?.threeDSecurePassThrough
  const threeDSecureEnabled = form.getFieldValue(['threeDSecureEnabled'])
  const requiresWebhookSetup =
    definition?.supportedFeatures?.requiresWebhookSetup

  const handleShowDrawer = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setShowOpenLoopDrawer(true)
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {threeDSecureSupported && !threeDSecureEnabled && (
        <Alert variant="information">
          <Alert.Icon />
          <Alert.Content>
            <Alert.Title>3-D Secure</Alert.Title>
            <Alert.Text>
              3-D Secure is not enabled for this connection. You can enable this
              within the 3-D Secure tab above.
            </Alert.Text>
          </Alert.Content>
        </Alert>
      )}
      <Text margin="none">
        Set a display name for this connection, in order to find it easily
        within the dashboard. If it’s deactivated, any flows rules using it will
        no longer be processed.
      </Text>
      <FormItem
        name={['displayName']}
        label={<Label>Display name</Label>}
        rules={[{ required: true, message: 'This field is required' }]}
        style={{ margin: 0 }}
      >
        <Input disabled={!hasPermission} />
      </FormItem>
      {paymentMethodTokenizationToggleSupported && (
        <FormItem
          name="paymentMethodTokenizationEnabled"
          label={<Label>Payment tokenization</Label>}
          valuePropName="checked"
          trigger="onCheckedChange"
          shouldUpdate
          help={
            <Text
              as="span"
              className={atoms({ fontSize: 'xs', paddingTop: 4 })}
            >
              Allow buyers to store their account details in the vault. This
              requires {definition?.displayName} to have enabled this feature
              for your account.
            </Text>
          }
        >
          <Checkbox disabled={!hasPermission}>Enabled</Checkbox>
        </FormItem>
      )}
      {openLoopToggleSupported && isStaff && (
        <FormItem
          name="openLoop"
          label={<Label>Open loop</Label>}
          valuePropName="checked"
          trigger="onCheckedChange"
          shouldUpdate
          help={
            <>
              <Text as="span" fontSize="xs" paddingTop={4}>
                Use this connector as an open loop connector.
              </Text>
              <TextLink
                href="#"
                className={atoms({ fontSize: 'xs' })}
                onClick={handleShowDrawer}
              >
                Learn more.
              </TextLink>
            </>
          }
        >
          <Checkbox disabled={!hasPermission}>Enabled</Checkbox>
        </FormItem>
      )}
      {networkTokensSupported && (
        <FormItem
          name="networkTokensEnabled"
          label={<Label>Network tokens</Label>}
          valuePropName="checked"
          trigger="onCheckedChange"
          shouldUpdate
          help={
            <Text
              as="span"
              className={atoms({ fontSize: 'xs', paddingTop: 4 })}
            >
              When enabled we’ll try to use Network Tokens to process
              transactions before traditional card numbers (PAN). This can
              significantly help improve your authorization rates.
            </Text>
          }
        >
          <Checkbox disabled={!hasPermission}>Enabled</Checkbox>
        </FormItem>
      )}
      <FormItem
        name={['active']}
        label={<Label>Connection status</Label>}
        valuePropName="checked"
        trigger="onCheckedChange"
        shouldUpdate
        help={
          <Text as="span" className={atoms({ fontSize: 'xs', paddingTop: 4 })}>
            When disabled this connection will no longer be used to process
            transactions.
          </Text>
        }
      >
        <Checkbox disabled={!hasPermission}>Active</Checkbox>
      </FormItem>
      {requiresWebhookSetup && (
        <>
          <Stack gap={8}>
            <Heading level={4}>Synchronization</Heading>
            <Text marginBottom="none">
              Webhooks are recommended to notify us of any update to the status
              of every transaction from the payment service. Please register the
              following URL with your payment service as the receiving endpoint
              for webhooks.
            </Text>
          </Stack>
          <Copy text={webhookUrl} />
        </>
      )}
      <Drawer
        open={showOpenLoopDrawer}
        title="Cloud Vault"
        onClose={() => setShowOpenLoopDrawer(false)}
      >
        <Text>
          By default {definition?.displayName} does not support our Cloud Vault
          for storing card data for recurring transactions, 3DS data, or network
          tokens. As a result this service can not be used when retrying
          transactions across different connectors. {definition?.displayName}{' '}
          can provide this for some merchants. If this has been enabled for your
          account then please tick this checkbox to let Gr4vy use its Cloud
          Vault with {definition?.displayName}.
        </Text>
      </Drawer>
    </>
  )
}

export const PaymentServiceOverviewPage = () => (
  <EditPaymentServiceForm submitButtonText="Save changes">
    <PaymentServiceOverview />
  </EditPaymentServiceForm>
)
