import { Box, Container, Flex, Stack } from '@gr4vy/poutine-react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import BillingDetailsPanel from 'buyers/components/BillingDetailsPanel'
import BuyerPageLayout from 'buyers/components/BuyerPageLayout'
import TaxInformationPanel from 'buyers/components/TaxInformationPanel/TaxInformationPanel'
import { getBuyer } from 'buyers/services/buyers'
import { SummaryGrid } from 'shared/components/SummaryGrid'
import { GET_BUYER } from 'shared/constants/query-keys/transactions'

export default function BuyerOverviewPage() {
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }

  const { data: buyer } = useQuery({
    queryKey: [GET_BUYER, id, merchantAccountId],
    queryFn: () => getBuyer(id),
    refetchOnWindowFocus: false,
  })

  return (
    <BuyerPageLayout>
      <Stack gap={32}>
        <Box paddingX={32} paddingY={48}>
          <Container>
            <SummaryGrid gridTemplateColumns={{ xs: 1, md: 2, lg: 3 }}>
              <SummaryGrid.Item>
                <SummaryGrid.Key>Buyer ID</SummaryGrid.Key>
                <SummaryGrid.Value>{buyer?.id ?? '-'}</SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>External identifier</SummaryGrid.Key>
                <SummaryGrid.Value>
                  {buyer?.externalIdentifier ?? '-'}
                </SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>E-mail</SummaryGrid.Key>
                <SummaryGrid.Value>
                  {buyer?.billingDetails?.emailAddress ?? '-'}
                </SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>First name</SummaryGrid.Key>
                <SummaryGrid.Value>
                  {buyer?.billingDetails?.firstName ?? '-'}
                </SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>Last name</SummaryGrid.Key>
                <SummaryGrid.Value>
                  {buyer?.billingDetails?.lastName ?? '-'}
                </SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>Display name</SummaryGrid.Key>
                <SummaryGrid.Value>
                  {buyer?.displayName ?? '-'}
                </SummaryGrid.Value>
              </SummaryGrid.Item>
            </SummaryGrid>
          </Container>
        </Box>
        <Box>
          <Container>
            <Flex gap={32}>
              <BillingDetailsPanel buyer={buyer} />
              <TaxInformationPanel taxId={buyer?.billingDetails?.taxId} />
            </Flex>
          </Container>
        </Box>
      </Stack>
    </BuyerPageLayout>
  )
}
