import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { GET_MERCHANT_ACCOUNT } from 'shared/constants/query-keys/merchants'
import {
  getMerchantAccount,
  MerchantAccount,
  updateMerchantAccountWebhook,
} from 'shared/services/merchant-accounts'
import { showErrorMessage, showSuccessMessage } from 'shared/utils/showMessage'
import View from './EditMerchantAccountWebhookPage'

const EditMerchantAccountWebhooksPage = ({ title }: { title: string }) => {
  const { merchantAccountId } = useParams() as {
    merchantAccountId: string
  }

  const queryClient = useQueryClient()

  const { data } = useQuery({
    queryKey: [GET_MERCHANT_ACCOUNT, merchantAccountId],
    queryFn: () => getMerchantAccount(merchantAccountId),
    meta: {
      error: {
        redirect: true,
      },
    },
    enabled: !!merchantAccountId,
  })

  const update = useMutation({
    mutationFn: updateMerchantAccountWebhook,
    onSuccess: (merchantAccount) => {
      showSuccessMessage(
        <>{merchantAccount.displayName} webhooks successfully updated.</>
      )
      queryClient.invalidateQueries({
        queryKey: [GET_MERCHANT_ACCOUNT, merchantAccountId],
      })
    },
    onError: () =>
      showErrorMessage('Unable to update Merchant details. Please try again.'),
  })

  return (
    <View
      title={title}
      merchantAccount={data || ({ outboundWebhookUrl: '' } as MerchantAccount)}
      update={update}
    />
  )
}

export default EditMerchantAccountWebhooksPage
