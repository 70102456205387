import { Heading } from '@gr4vy/poutine-react'
import { Fragment } from 'react'
import { Panel } from 'shared/components/Panel'
import { Summary } from 'shared/components/Summary'

function MetadataPanel({ metadata }: { metadata: { [key: string]: string } }) {
  return (
    <Panel>
      <Panel.Header>
        <Heading level={4}>Metadata</Heading>
      </Panel.Header>
      <Panel.Content>
        <Summary inline>
          {Object.keys(metadata).map((key: string) => (
            <Fragment key={key}>
              <Summary.Key>{key}</Summary.Key>
              <Summary.Value fallback={false}>{metadata[key]}</Summary.Value>
            </Fragment>
          ))}
        </Summary>
      </Panel.Content>
    </Panel>
  )
}

export default MetadataPanel
