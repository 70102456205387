import { Outlet } from 'react-router-dom'
import { Footer } from 'App/components/Footer'
import { SandboxBanner } from 'App/components/SandboxBanner'
import { TopBar } from 'App/components/TopBar'
import { TopNav } from 'App/components/TopNav'
import { StickyBox } from 'shared/components/StickyBox'

export default function Layout() {
  return (
    <>
      <StickyBox>
        <SandboxBanner />
        <TopBar />
        <TopNav />
      </StickyBox>
      <Outlet />
      <Footer />
    </>
  )
}
