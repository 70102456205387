import { Box, Description, Flex } from '@gr4vy/poutine-react'
import { ComponentProps } from 'react'
import { OutcomeInstrument, OutcomeTransformation } from 'flows/constants'
import { Icon, IconType } from 'shared/components/Icon'
import { ActionMenu } from './ActionMenu'
import OutcomeWarning from './OutcomeWarning'

export type ItemV1Props = ComponentProps<typeof Box> & {
  label: string
  hasWritePermission: boolean
  iconUrl?: string
  warning?: string
  isHidden: boolean
  version: 1
}

export type ItemV2Props = ComponentProps<typeof Box> & {
  label: string
  hasWritePermission: boolean
  instrument: OutcomeInstrument
  transformations: OutcomeTransformation[]
  onRemove: () => void
  onEdit: () => void
  displayName?: string
  iconUrl?: string
  warning?: string
  version: 2
}

export type ItemProps = ItemV1Props | ItemV2Props

const sanitizeProps = (
  props: ItemProps
): Omit<ItemProps, 'isHidden'> | ItemProps => {
  if ('isHidden' in props) {
    //eslint-disable-next-line
    const { isHidden, ...sanitizedProps } = props
    return sanitizedProps
  }

  if ('displayName' in props) {
    //eslint-disable-next-line
    const { displayName, onEdit, onRemove, ...sanitizedProps } = props
    return sanitizedProps
  }
  return props
}

export const Item = (props: ItemProps) => {
  const { iconUrl, label, hasWritePermission, version, warning, ...rest } =
    props

  return (
    <Flex gap={16} alignItems="center" justifyContent="space-between">
      <Flex gap={16} alignItems="center" minWidth={0}>
        {hasWritePermission && (
          <Box width={32} height={32} {...sanitizeProps(rest as ItemProps)}>
            <Icon iconType={IconType.Drag} />
          </Box>
        )}
        <Box>
          <img
            src={iconUrl ?? '/img/logos/card.svg'}
            width={48}
            height={48}
            alt={label}
          />
        </Box>
        <Box minWidth={0}>
          <Description>
            <Description.Text>{label}</Description.Text>
            {version === 2 && !!props.displayName && (
              <Description.SubText>{props.displayName}</Description.SubText>
            )}
          </Description>
        </Box>
      </Flex>
      {version === 2 && props.instrument && props.transformations && (
        <ActionMenu
          instrument={props.instrument}
          transformations={props.transformations}
          onRemove={props.onRemove}
          onEdit={props.onEdit}
          warning={warning}
        />
      )}
      {version === 1 && typeof props.warning === 'string' && (
        <Box justifyContent="flex-end">
          <OutcomeWarning isHidden={props.isHidden} version={version}>
            {warning}
          </OutcomeWarning>
        </Box>
      )}
    </Flex>
  )
}
