import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  GET_BUYER_TRX,
  LIST_EVENTS,
  TRANSACTION,
} from 'shared/constants/query-keys/transactions'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import { showSuccessMessage } from 'shared/utils/showMessage'
import { Transaction, refreshTransaction } from 'transactions/services'

export const useTransactionRefresh = (
  transaction?: Transaction,
  callback?: () => void
) => {
  const queryClient = useQueryClient()
  const invalidateTransactions = async () => {
    await queryClient.invalidateQueries({ queryKey: [GET_BUYER_TRX] })
    await queryClient.invalidateQueries({
      queryKey: [TRANSACTION, transaction?.id],
    })
    await queryClient.invalidateQueries({
      queryKey: [LIST_EVENTS, transaction?.id],
    })
  }
  const refreshMutation = useMutation({
    mutationFn: refreshTransaction,
    onSuccess: () => {
      showSuccessMessage('Transaction refreshed')
    },
    onError: handleErrorResponse,
    onSettled() {
      invalidateTransactions()
      callback && callback()
    },
  })
  const onRefresh = () =>
    transaction?.id ? refreshMutation.mutate(transaction.id) : () => {}

  return { onRefresh, refreshing: refreshMutation.isPending }
}
