import { atoms } from '@gr4vy/poutine-react'
import * as RadixTabs from '@radix-ui/react-tabs'
import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { Content } from './Content'
import { List } from './List'
import { Trigger } from './Trigger'

export type TabsProps = PropsWithChildren<RadixTabs.TabsProps>

export const Tabs = ({ children, className, ...rest }: TabsProps) => {
  return (
    <RadixTabs.Root
      orientation="vertical"
      className={clsx(atoms({ width: 'full' }), className)}
      {...rest}
    >
      {children}
    </RadixTabs.Root>
  )
}

Tabs.List = List
Tabs.Trigger = Trigger
Tabs.Content = Content
