import { useMutation } from '@tanstack/react-query'
import {
  Navigate,
  useParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useEditableRule } from 'flows/hooks/useEditableRule'
import RulePage from 'flows/pages/RulePage/RulePage'
import { removeRule, updateRule } from 'flows/services'
import { Loading } from 'shared/components/Loading'
import { Action, Flow } from 'shared/constants'
import { pathTo } from 'shared/paths/flows'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import { handleSuccessResponse } from 'shared/utils/handleSuccessResponse'
import { showErrorMessage } from 'shared/utils/showMessage'

type EditRulePageProps = {
  title: string
}

const EditRulePage = ({ title }: EditRulePageProps) => {
  const hasWriteAccess = useResourcePermission(
    Resource.flows,
    AccessLevel.write
  )
  const { flow, action, id, merchantAccountId } = useParams() as {
    flow: Flow
    action: Action
    id: string
    merchantAccountId: string
  }
  const [searchParams] = useSearchParams()
  const flowUrl = pathTo.flow(merchantAccountId, flow, searchParams)
  const navigate = useNavigate()
  const { isLoading, isSuccess, rule, ruleConfig, outcomes } = useEditableRule(
    merchantAccountId,
    flow,
    action,
    id,
    searchParams
  )

  const onSaveRule = useMutation({
    mutationFn: updateRule,
    onSuccess: handleSuccessResponse('Saved rule changes', () => {
      navigate(flowUrl)
    }),
    onError: () => {
      showErrorMessage('This rule could not be saved')
    },
  })
  const onRemoveRule = useMutation({
    mutationFn: removeRule,
    onSuccess: handleSuccessResponse('Rule removed', () => {
      navigate(flowUrl, { replace: true })
    }),
    onError: handleErrorResponse,
  })
  const onCancel = () => {
    navigate(flowUrl)
  }

  if (isLoading) {
    return <Loading />
  }

  if (!isSuccess) {
    showErrorMessage('Failed to load rule outcomes, please try again')
    return <Navigate replace to={flowUrl} />
  }

  return (
    <RulePage
      flow={flow}
      action={action}
      rule={rule}
      config={ruleConfig}
      outcomes={outcomes}
      title={hasWriteAccess ? title : 'View rule'}
      onSaveRule={onSaveRule.mutate}
      isSaving={onSaveRule.isPending}
      onRemoveRule={onRemoveRule.mutate}
      onCancelRule={onCancel}
      searchParams={searchParams}
      merchantAccountId={merchantAccountId}
    />
  )
}

export default EditRulePage
