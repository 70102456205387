import { groupBy } from 'lodash'
import { ConfigOutcomeType, TransactionActionOutcome } from 'flows/constants'
import { Action, Flow } from 'shared/constants'
import { usePaymentMethodDefinitions } from 'shared/hooks'
import { useOutcomes } from './useOutcomes'

export type OutcomeOption = {
  key: string
  value: string
  ids: string[]
}

export const useOutcomeOptions = (
  merchantAccountId: string,
  flow: Flow,
  action: Action
): { options: OutcomeOption[] } => {
  // Know the outcome type need here already dont need to load
  // the rules config
  const outcomes = useOutcomes({
    merchantAccountId,
    flow,
    action,
    outcome: {
      type: ConfigOutcomeType.LIST,
    },
  })
  const definitions = usePaymentMethodDefinitions(merchantAccountId)

  if (outcomes.isLoading || !outcomes.isSuccess) {
    return {
      options: [],
    }
  }

  const groupedOutcomes = groupBy(
    outcomes.data.items as TransactionActionOutcome[],
    'method'
  )

  const options = Object.entries(groupedOutcomes).map(([method, outcomes]) => {
    const definition = definitions[method]
    return {
      key: outcomes[0].method as string,
      value: definition
        ? definition.longDisplayName
        : (outcomes[0].displayName as string),
      ids: outcomes.map((i) => i.id),
    }
  })

  return { options }
}
