import {
  atoms,
  Tag,
  Popover,
  Stack,
  Text,
  FilterButton,
  Skeleton,
  Divider,
  Flex,
} from '@gr4vy/poutine-react'
import { isEqual } from 'lodash'
import { useMemo, useState } from 'react'
import { PaymentService, PaymentServiceDefinition } from 'connections/services'
import DebouncedSearchBar from 'shared/components/DebouncedSearchBar'
import { PopoverMenu } from 'shared/components/PopoverMenu'
import styles from './TransactionColumnFilter.module.scss'

type PickedPaymentService = Pick<PaymentService, 'id' | 'active' | 'method'>

export type ConnectionItemProps = PickedPaymentService &
  Pick<PaymentServiceDefinition, 'displayName' | 'iconUrl'>
export type PaymentMethodItemProps = Omit<ConnectionItemProps, 'method'> & {
  method?:
    | PaymentService['method']
    | 'applepay'
    | ['googlepay', 'googlepay_pan_only']
    | 'click-to-pay'
    | 'network-token'
}

export enum FilterKey {
  PaymentMethod = 'method',
  Connection = 'id',
  GiftCardRedemption = 'hasGiftCardRedemptions',
}

type TransactionColumnFilterProps = {
  label: string
  isLoading: boolean
  filter: {
    key: FilterKey
    value: string | string[] | undefined
  }
  data: ConnectionItemProps[] | PaymentMethodItemProps[]
  onToggleFilter(item: ConnectionItemProps | PaymentMethodItemProps): void
}

const SkeletonItems = Array.from({ length: 6 }, (_, k) => k).map((item) => (
  <PopoverMenu.Item key={item}>
    <Skeleton minWidth={32}>
      <Skeleton.Box borderRadius="rounded" height={32} />
    </Skeleton>
    <Skeleton width={200}>
      <Skeleton.Paragraph lines={1} />
    </Skeleton>
  </PopoverMenu.Item>
))

const TransactionColumnFilter = ({
  label,
  onToggleFilter,
  filter,
  isLoading,
  data,
}: TransactionColumnFilterProps) => {
  const isItemActive = (item: PaymentMethodItemProps) =>
    item.id === 'gift_card'
      ? filter.key === FilterKey.GiftCardRedemption && filter.value === 'true'
      : isEqual(
          filter?.value,
          item[
            filter.key as keyof (PaymentMethodItemProps | ConnectionItemProps)
          ]
        )

  const [search, setSearch] = useState('')
  const filteredData = useMemo(
    () =>
      data.filter(
        (item) =>
          (item[filter.key as keyof typeof item] || '') === filter.value ||
          item.displayName.toLowerCase().includes(search.toLowerCase())
      ),
    [data, search, filter.key, filter.value]
  )

  return (
    <Popover onOpenChange={(open) => !open && setSearch('')}>
      <Popover.Trigger asChild>
        <FilterButton data-selected={!!filter.value}>
          {label}
          {filter.value ? ' / 1' : null}
        </FilterButton>
      </Popover.Trigger>
      <Popover.Content align="start" side="bottom" avoidCollisions={false}>
        <Stack gap={8} className={styles.contentWrapper}>
          <Flex>
            <DebouncedSearchBar
              placeholder="Search methods..."
              onChange={(value) => setSearch(value || '')}
              size="medium"
              className={styles.searchBar}
              hideLabel
            />
          </Flex>
          <Divider width="sm" />
          {!isLoading && filteredData?.length === 0 && (
            <Text paddingTop={8} paddingBottom={4}>
              No results.
            </Text>
          )}
          <PopoverMenu>
            {isLoading && !!filteredData
              ? SkeletonItems
              : filteredData?.map((item) => (
                  <PopoverMenu.Item
                    key={item.id}
                    onClick={() => onToggleFilter(item)}
                    active={isItemActive(item)}
                    title={item.displayName}
                  >
                    <img
                      alt={item.displayName}
                      className={atoms({ height: 24, width: 24 })}
                      src={item.iconUrl}
                    />

                    <Text as="span" flexGrow={1} truncate>
                      {item.displayName}
                    </Text>
                    {!item.active && <Tag>Inactive</Tag>}
                  </PopoverMenu.Item>
                ))}
          </PopoverMenu>
        </Stack>
      </Popover.Content>
    </Popover>
  )
}

export default TransactionColumnFilter
