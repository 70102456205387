import { GridItem } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import { Operation } from 'flows/constants'
import { OutcomeOption } from 'flows/hooks/useOutcomeOptions'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'

interface PaymentMethodInputProps {
  name: number
  options: OutcomeOption[]
}

const ERROR_PAYMENT_METHOD_REQUIRED = 'At least one payment method is required'
const ERROR_OPERATOR_REQUIRED = 'Operator is required'

const PaymentMethodInput = ({ name, options }: PaymentMethodInputProps) => {
  return (
    <>
      <GridItem gridColumn="span 2">
        <FormItem
          label={<Label>Operator</Label>}
          name={[name, 'operator']}
          rules={[{ required: true, message: ERROR_OPERATOR_REQUIRED }]}
        >
          <Select placeholder="Select an operator">
            <Select.Option value={Operation.IS_ONE_OF}>includes</Select.Option>
            <Select.Option value={Operation.IS_NOT_ONE_OF}>
              excludes
            </Select.Option>
          </Select>
        </FormItem>
      </GridItem>
      <GridItem gridColumn="span 6">
        <FormItem
          label={<Label>Value(s)</Label>}
          name={[name, 'value']}
          rules={[{ required: true, message: ERROR_PAYMENT_METHOD_REQUIRED }]}
        >
          <Select
            mode="multiple"
            allowClear
            maxTagCount="responsive"
            maxTagPlaceholder={maxTagPlaceholderFormatter}
            placeholder="Select an option"
          >
            {options.map((paymentMethod) => (
              <Select.Option
                value={paymentMethod.key}
                label={paymentMethod.value}
                key={paymentMethod.key}
              >
                {paymentMethod.value}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      </GridItem>
    </>
  )
}

export { ERROR_OPERATOR_REQUIRED, ERROR_PAYMENT_METHOD_REQUIRED }
export default PaymentMethodInput
