import { Box, Container, Stack, Text } from '@gr4vy/poutine-react'
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { useMemo } from 'react'
import { Filters } from 'audit-logs/pages/AuditLogsBrowsePage/AuditLogsBrowsePage'
import { AuditLogEntry } from 'audit-logs/services/audit-logs'
import { SummaryGrid } from 'shared/components/SummaryGrid'
import { dateFormat } from 'shared/helpers/date-format'
import { getCurrentLocale } from 'shared/helpers/locale'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { CollectionResponse } from 'shared/services/client'
import { ActivityPanel } from 'users/components/ActivityPanel/ActivityPanel'
import UserPageLayout from 'users/components/UserPageLayout'
import { getPermissionNameByRoleName } from 'users/constants'
import { getPermissionRoleIds } from 'users/helpers'
import { User } from 'users/services/users'

interface UserOverviewPageProps {
  user?: User
  filters: Partial<Filters>
  onFilter: (filter: Filters) => void
  auditLogs: UseQueryResult<CollectionResponse<AuditLogEntry>>
  remove: UseMutationResult<void, any, string>
}

export default function UserOverviewPage({
  user,
  filters,
  onFilter,
  auditLogs,
  remove,
}: UserOverviewPageProps) {
  const hasAuditLogsPermission = useResourcePermission(
    Resource.auditLogs,
    AccessLevel.read
  )

  const roles = useMemo(() => user?.roles ?? [], [user?.roles])

  const permissionRoleIds = getPermissionRoleIds(roles)

  const userRoles =
    roles
      .filter((role) => !permissionRoleIds.includes(role.id))
      .map((role) => role.name)
      .join(', ') || 'None'

  const userPermissions =
    roles
      .filter((role) => permissionRoleIds.includes(role.id))
      .map((role) => getPermissionNameByRoleName(role.name))
      .join(', ') || 'None'

  return (
    <UserPageLayout user={user} remove={remove}>
      <Stack gap={32}>
        <Box paddingX={32} paddingY={48}>
          <Container>
            <Stack gap={40}>
              <SummaryGrid
                gridTemplateColumns={{ xs: 1, md: 2, lg: 3 }}
                columnGap={4}
              >
                <SummaryGrid.Item>
                  <SummaryGrid.Key>Last logged in</SummaryGrid.Key>
                  <SummaryGrid.Value height="auto" monospace>
                    {user?.lastLoggedInAt &&
                      dateFormat(
                        user?.lastLoggedInAt ?? '',
                        getCurrentLocale(),
                        {
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        }
                      )}
                  </SummaryGrid.Value>
                </SummaryGrid.Item>
                <SummaryGrid.Item>
                  <SummaryGrid.Key>Roles</SummaryGrid.Key>
                  <SummaryGrid.Value height="auto" monospace>
                    <Text>{userRoles}</Text>
                  </SummaryGrid.Value>
                </SummaryGrid.Item>
              </SummaryGrid>
              <SummaryGrid
                gridTemplateColumns={{ xs: 1, md: 2, lg: 3 }}
                columnGap={4}
              >
                <SummaryGrid.Item>
                  <SummaryGrid.Key>Email</SummaryGrid.Key>
                  <SummaryGrid.Value height="auto" monospace>
                    {user?.emailAddress}
                  </SummaryGrid.Value>
                </SummaryGrid.Item>
                <SummaryGrid.Item>
                  <SummaryGrid.Key>Additional permissions</SummaryGrid.Key>
                  <SummaryGrid.Value height="auto" monospace>
                    <Text>{userPermissions}</Text>
                  </SummaryGrid.Value>
                </SummaryGrid.Item>
              </SummaryGrid>
            </Stack>
          </Container>
        </Box>
        {hasAuditLogsPermission && (
          <Box>
            <Container>
              <ActivityPanel
                page={auditLogs}
                filters={filters}
                onFilter={onFilter}
              />
            </Container>
          </Box>
        )}
      </Stack>
    </UserPageLayout>
  )
}
