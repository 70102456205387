import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { usePaymentServiceDefinitions } from 'connections'
import { listConnections, listPaymentServices } from 'connections/services'
import {
  extractPaymentServiceIdsWithSettlementReporting,
  prepareConnections,
} from 'reports/helpers/settlement'
import { CONNECTIONS, PAYMENT_SERVICES } from 'shared/constants'

export const useConnections = () => {
  const { merchantAccountId } = useParams<{
    merchantAccountId: string
  }>()

  const { items: paymentServiceDefinitions } = usePaymentServiceDefinitions()
  const supportedPaymentServiceDefinitionIds = useMemo(
    () =>
      extractPaymentServiceIdsWithSettlementReporting(
        paymentServiceDefinitions?.items
      ),
    [paymentServiceDefinitions?.items]
  )

  const paymentServices = useQuery({
    queryKey: [PAYMENT_SERVICES, merchantAccountId],
    queryFn: () => listPaymentServices({ limit: 100 }),
    enabled: !!merchantAccountId && !!paymentServiceDefinitions,
  })

  const connections = useQuery({
    queryKey: [CONNECTIONS, merchantAccountId],
    queryFn: () => listConnections(),
    select: (data) =>
      prepareConnections({
        connections: data.items,
        paymentServices: paymentServices.data?.items,
        supportedPaymentServiceDefinitionIds,
      }),
    enabled: !!merchantAccountId && !!paymentServices.data,
  })

  return {
    data: connections.data,
    isLoading: paymentServices.isLoading || connections.isLoading,
    isSuccess: paymentServices.isSuccess && connections.isSuccess,
    hasSupportedConnections: !!connections?.data?.supportedConnections.length,
    hasUnsupportedConnections:
      !!connections?.data?.unsupportedConnections.length,
  }
}
