import { useQuery } from '@tanstack/react-query'
import { getPaymentMethod } from 'payment-methods/services/paymentMethods'
import { PAYMENT_METHOD } from 'shared/constants'
import {
  useCardSchemeDefinition,
  usePaymentMethodDefinition,
} from 'shared/hooks'

export const usePaymentMethod = (id: string) => {
  const paymentMethodQueryKey = [PAYMENT_METHOD, id]
  const paymentMethod = useQuery({
    queryKey: paymentMethodQueryKey,
    queryFn: () => getPaymentMethod(id),
    meta: {
      error: {
        redirect: true,
      },
    },
  })

  const scheme = useCardSchemeDefinition(paymentMethod?.data?.scheme)
  const definition = usePaymentMethodDefinition(paymentMethod?.data?.method)
  const parentDefinition = usePaymentMethodDefinition(definition?.typeof)

  const isCard = definition?.id === 'card' || definition?.typeof === 'card'

  return {
    paymentMethod: paymentMethod.data,
    isCard,
    scheme,
    definition,
    parentDefinition,
  }
}
