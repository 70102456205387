import { Text } from '@gr4vy/poutine-react'
import { ReactNode } from 'react'
import styles from './Key.module.scss'

interface KeyProps {
  children: ReactNode
  multiline?: boolean
}

export const Key = ({ children }: KeyProps) => {
  return (
    <Text as="dt" variant="reg3" className={styles.key}>
      {children}
    </Text>
  )
}

Key.displayName = 'Summary.Key'
