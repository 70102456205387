import { Box, Description, Tag, Tooltip } from '@gr4vy/poutine-react'
import { useCardSchemeDefinitions } from 'shared/hooks'

export interface AdditionalSchemesTooltipProps {
  additionalSchemes: string[]
}

export const AdditionalSchemesTooltip = ({
  additionalSchemes,
}: AdditionalSchemesTooltipProps) => {
  const definitions = useCardSchemeDefinitions()

  return (
    <Tooltip
      content={additionalSchemes.map((scheme: string) => (
        <Description key={scheme}>
          <Description.Icon size={24} src={definitions[scheme]?.iconUrl} />
          <Description.Text>
            {definitions[scheme]?.displayName}
          </Description.Text>
        </Description>
      ))}
    >
      <Box>
        <Tag variant="subtle" color="neutral">
          +{additionalSchemes.length} more
        </Tag>
      </Box>
    </Tooltip>
  )
}
