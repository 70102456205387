import {
  Box,
  Container,
  Stack,
  Description,
  Text,
  Tooltip,
  Flex,
  Icon,
} from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import PaymentMethodPageLayout from 'payment-methods/components/PaymentMethodPageLayout/PaymentMethodPageLayout'
import { usePaymentMethod } from 'payment-methods/hooks/use-payment-method'
import { SummaryGrid } from 'shared/components/SummaryGrid'
import {
  dateFormat,
  dateFormatRelative,
  monthYearFormatter,
} from 'shared/helpers/date-format'
import { getCurrentLocale } from 'shared/helpers/locale'
import { pathTo } from 'shared/paths/transactions'
import { AdditionalSchemesTooltip } from 'transactions/components/AdditionalSchemesTooltip'
import { BuyerPanel } from 'transactions/components/BuyerPanel'
import { PaymentMethodStatus } from 'transactions/components/PaymentMethodStatus'
import { getCountryName } from 'transactions/helpers'

const PaymentMethodOverviewPage = () => {
  const { paymentMethodId } = useParams() as { paymentMethodId: string }
  const { paymentMethod, scheme, definition, parentDefinition } =
    usePaymentMethod(paymentMethodId)

  const { merchantAccountId } = useParams() as { merchantAccountId: string }

  return (
    <PaymentMethodPageLayout>
      <Stack gap={32}>
        <Box>
          <Container>
            <SummaryGrid
              paddingX={32}
              paddingY={48}
              gridTemplateColumns={{ xs: 1, md: 2, lg: 3 }}
            >
              <SummaryGrid.Item>
                <SummaryGrid.Key>Status</SummaryGrid.Key>
                <SummaryGrid.Value>
                  {paymentMethod && (
                    <PaymentMethodStatus paymentMethod={paymentMethod} />
                  )}
                </SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>Type</SummaryGrid.Key>
                <SummaryGrid.Value>
                  {definition &&
                    (definition?.typeof ? (
                      <Description>
                        <Description.Icon src={definition.iconUrl} />
                        {parentDefinition?.displayName ? (
                          <Description.Text>
                            {parentDefinition.displayName}
                          </Description.Text>
                        ) : (
                          <></>
                        )}
                        <Description.SubText>
                          {definition.displayName}
                        </Description.SubText>
                      </Description>
                    ) : (
                      <Description>
                        <Description.Icon src={definition?.iconUrl} />
                        {!!definition?.displayName && (
                          <Description.Text>
                            {definition.displayName}
                          </Description.Text>
                        )}
                      </Description>
                    ))}
                </SummaryGrid.Value>
              </SummaryGrid.Item>
              {scheme && (
                <SummaryGrid.Item>
                  <SummaryGrid.Key>Scheme</SummaryGrid.Key>
                  <SummaryGrid.Value>
                    <Flex gap={8} alignItems="center">
                      <Description>
                        <Description.Icon src={scheme.iconUrl} />
                        <Description.Text>
                          {scheme.displayName}
                        </Description.Text>
                      </Description>
                      {!!paymentMethod?.additionalSchemes?.length && (
                        <AdditionalSchemesTooltip
                          additionalSchemes={paymentMethod.additionalSchemes}
                        />
                      )}
                    </Flex>
                  </SummaryGrid.Value>
                </SummaryGrid.Item>
              )}
              <SummaryGrid.Item>
                <SummaryGrid.Key>Payment method ID</SummaryGrid.Key>
                <SummaryGrid.Value>{paymentMethod?.id}</SummaryGrid.Value>
              </SummaryGrid.Item>
              {paymentMethod?.currency && (
                <SummaryGrid.Item>
                  <SummaryGrid.Key>Currency</SummaryGrid.Key>
                  <SummaryGrid.Value>
                    {paymentMethod.currency}
                  </SummaryGrid.Value>
                </SummaryGrid.Item>
              )}
              {paymentMethod?.country && (
                <SummaryGrid.Item>
                  <SummaryGrid.Key>Country</SummaryGrid.Key>
                  <SummaryGrid.Value>
                    {getCountryName(paymentMethod.country)}
                  </SummaryGrid.Value>
                </SummaryGrid.Item>
              )}
              {paymentMethod?.expirationDate && (
                <SummaryGrid.Item>
                  <SummaryGrid.Key>Expiration</SummaryGrid.Key>
                  <SummaryGrid.Value>
                    {monthYearFormatter(paymentMethod.expirationDate)}
                  </SummaryGrid.Value>
                </SummaryGrid.Item>
              )}
              {paymentMethod?.fingerprint && (
                <SummaryGrid.Item>
                  <SummaryGrid.Key>Fingerprint</SummaryGrid.Key>
                  <SummaryGrid.Value>
                    <Description>
                      <Description.Link
                        href={pathTo.transactions(merchantAccountId, {
                          // eslint-disable-next-line camelcase
                          payment_method_fingerprint: paymentMethod.fingerprint,
                        })}
                      >
                        {paymentMethod?.fingerprint}
                      </Description.Link>
                    </Description>
                  </SummaryGrid.Value>
                </SummaryGrid.Item>
              )}
              {paymentMethod?.lastReplacedAt && (
                <SummaryGrid.Item>
                  <SummaryGrid.Key>Last updated</SummaryGrid.Key>
                  <SummaryGrid.Value>
                    <Flex alignItems="center" gap={8}>
                      <Text>
                        {dateFormat(
                          paymentMethod.lastReplacedAt,
                          getCurrentLocale(),
                          {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                          }
                        )}
                      </Text>
                      <Tooltip
                        content={`New card details received on ${dateFormatRelative(
                          paymentMethod?.lastReplacedAt
                        )}`}
                      >
                        <Icon name="info" size="small" />
                      </Tooltip>
                    </Flex>
                  </SummaryGrid.Value>
                </SummaryGrid.Item>
              )}
            </SummaryGrid>
          </Container>
        </Box>
        {paymentMethod?.buyer && (
          <Box paddingX={8} paddingBottom={80}>
            <Container>
              <BuyerPanel data={paymentMethod} />
            </Container>
          </Box>
        )}
      </Stack>
    </PaymentMethodPageLayout>
  )
}

export default PaymentMethodOverviewPage
