import client, { CollectionResponse } from 'shared/services/client'

export type PaymentMethodDefinition = {
  displayName: string
  longDisplayName: string
  iconUrl: string
  typeof?: string
  id: string
}

export const listPaymentMethodDefinitions = async (): Promise<
  CollectionResponse<PaymentMethodDefinition>
> => {
  const response = await client.get('/payment-method-definitions')
  return response.data
}
