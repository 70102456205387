import { Box, Heading, Stack } from '@gr4vy/poutine-react'
import { Buyer } from 'buyers/services/buyers'
import { Panel } from 'shared/components/Panel'
import { SummaryGrid } from 'shared/components/SummaryGrid'
import { countryCodes } from 'shared/constants'

function BillingDetailsPanel({ buyer }: { buyer?: Buyer }) {
  const address = buyer?.billingDetails?.address

  return (
    <Box width="full">
      <Panel>
        <Panel.Header>
          <Heading level={4}>Billing details</Heading>
        </Panel.Header>
        <Panel.Content>
          <Stack gap={48}>
            <SummaryGrid gridTemplateColumns={{ xs: 1, md: 1, lg: 2 }}>
              <SummaryGrid.Item>
                <SummaryGrid.Key>House number</SummaryGrid.Key>
                <SummaryGrid.Value>
                  {address?.houseNumberOrName}
                </SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>Address line 1</SummaryGrid.Key>
                <SummaryGrid.Value>{address?.line1}</SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>Address line 2</SummaryGrid.Key>
                <SummaryGrid.Value>{address?.line2}</SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>City</SummaryGrid.Key>
                <SummaryGrid.Value>{address?.city}</SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>State</SummaryGrid.Key>
                <SummaryGrid.Value>{address?.state}</SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>Post code</SummaryGrid.Key>
                <SummaryGrid.Value>{address?.postalCode}</SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>Country</SummaryGrid.Key>
                <SummaryGrid.Value>
                  {address?.country &&
                    countryCodes[address.country.toUpperCase()].name}
                </SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>Organization</SummaryGrid.Key>
                <SummaryGrid.Value>{address?.organization}</SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>Phone number</SummaryGrid.Key>
                <SummaryGrid.Value>
                  {buyer?.billingDetails?.phoneNumber}
                </SummaryGrid.Value>
              </SummaryGrid.Item>
            </SummaryGrid>
          </Stack>
        </Panel.Content>
      </Panel>
    </Box>
  )
}

export default BillingDetailsPanel
