import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { HomeFilters } from 'home/constants/filter'
import { TRANSACTIONS } from 'shared/constants'
import { is } from 'shared/helpers/is'
import { useCursor } from 'shared/hooks'
import { listTransactions } from 'transactions/services'

interface TransactionsProps {
  filters: Partial<HomeFilters>
  enabled: boolean
}

export const useTransactions = ({ filters, enabled }: TransactionsProps) => {
  const { merchantAccountId } = useParams<{
    merchantAccountId: string
  }>()
  const [cursor] = useCursor()

  return useQuery({
    queryKey: [
      TRANSACTIONS,
      merchantAccountId,
      cursor,
      filters.cursor,
      filters.currency,
      filters.afterCreatedAt,
      filters.beforeCreatedAt,
      filters.limit,
    ],
    queryFn: () =>
      listTransactions({
        cursor: filters.cursor,
        currency: filters.currency,
        afterCreatedAt: filters.afterCreatedAt,
        beforeCreatedAt: filters.beforeCreatedAt,
        limit: filters.limit,
      }),
    meta: {
      error: {
        redirect: true,
      },
    },
    enabled: enabled && !is.emptyObject(filters) && !!filters.currency,
  })
}
