import { Dropdown } from '@gr4vy/poutine-react'
import { useTransactionDetails } from 'transactions/hooks/use-transaction-details'
import { Transaction } from 'transactions/services'

type TransactionActionsProps = {
  transaction: Transaction
  onCapture: () => void
  onVoid: () => void
  onRefund: () => void
  onRefresh: () => void
}

const TransactionActions = ({
  transaction,
  onCapture,
  onVoid,
  onRefund,
  onRefresh,
}: TransactionActionsProps) => {
  const {
    captureSupported,
    captureEnabled,
    refundSupported,
    refundEnabled,
    voidSupported,
    voidEnabled,
    refreshEnabled,
    refreshSupported,
  } = useTransactionDetails(transaction)

  const hasGiftCardWithRefundableAmount =
    !!transaction.giftCardRedemptions.find(
      (giftCardRedemption) =>
        giftCardRedemption.status === 'succeeded' &&
        giftCardRedemption.amount - giftCardRedemption.refundedAmount > 0
    )

  const isRefundEnabled =
    (transaction.paymentMethod && refundSupported && refundEnabled) ||
    hasGiftCardWithRefundableAmount

  const isCaptureEnabled =
    (transaction.paymentMethod && captureSupported && captureEnabled) ||
    (!transaction.paymentMethod && captureEnabled)

  return (
    <Dropdown>
      <Dropdown.Trigger asChild>
        <Dropdown.Button variant="primary">Actions</Dropdown.Button>
      </Dropdown.Trigger>
      <Dropdown.Content align="end">
        {refreshSupported && (
          <Dropdown.Item
            opensInModal
            disabled={!refreshEnabled}
            onSelect={onRefresh}
          >
            Refresh
          </Dropdown.Item>
        )}
        <Dropdown.Item
          opensInModal
          disabled={!isCaptureEnabled}
          onSelect={onCapture}
        >
          Capture
        </Dropdown.Item>
        <Dropdown.Item
          opensInModal
          disabled={!isRefundEnabled}
          onSelect={onRefund}
        >
          Refund
        </Dropdown.Item>
        {transaction.paymentMethod && (
          <Dropdown.Item
            opensInModal
            disabled={!voidSupported || !voidEnabled}
            onSelect={onVoid}
          >
            Void
          </Dropdown.Item>
        )}
      </Dropdown.Content>
    </Dropdown>
  )
}

export default TransactionActions
