import {
  Badge,
  Box,
  Dropdown,
  Flex,
  Icon,
  Stack,
  Tag,
  Text,
} from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MerchantPagesLayout } from 'admin/components/MerchantsPageLayout'
import { pathTo } from 'admin/paths'
import { useSession } from 'session'
import { ColumnDef, DataTable, DateTime } from 'shared/components/DataTable'
import { ModalRemove } from 'shared/components/Modal/ModalRemove'
import { CollectionResponse } from 'shared/services/client'
import { MerchantAccount } from 'shared/services/merchant-accounts'
import {
  AddVaultForwardConfig,
  RemoveVaultForwardConfig,
  VaultForwardConfig,
  VaultForwardDefinition,
} from 'shared/services/vault-forward'

interface EditMerchantAccountVaultForwardingPageProps {
  title: string
  configs: VaultForwardConfig[]
  definitions: Partial<CollectionResponse<VaultForwardDefinition>>
  merchantAccount: MerchantAccount
  enable: UseMutationResult<
    VaultForwardConfig,
    any,
    AddVaultForwardConfig,
    unknown
  >
  remove: UseMutationResult<void, unknown, RemoveVaultForwardConfig, unknown>
  removingVaultAuth?: VaultForwardConfig
  setRemovingVaultAuth: (vaultAuth?: VaultForwardConfig) => void
}

const getDisableWarning = (vaultForwardConfig: VaultForwardConfig) => {
  if (vaultForwardConfig.authentications.length) {
    return `By disabling Vault Forward you will loose all authentication
      methods and their credentials. You will not be able to recover
      these method unless you manually add them again after reenabling
      Vault Forward for ${vaultForwardConfig.displayName}. You will no 
      longer be able to forward data to ${vaultForwardConfig.displayName}
      as a result of this action.`
  } else {
    return `By performing this action the Vault Forward endpoint will no longer be
    available and we won't be able to forward data to ${vaultForwardConfig.displayName}.`
  }
}

const EditMerchantAccountVaultForwardingPage = ({
  title,
  configs,
  definitions,
  merchantAccount,
  enable,
  remove,
  removingVaultAuth,
  setRemovingVaultAuth,
}: EditMerchantAccountVaultForwardingPageProps) => {
  const { isStaff } = useSession()
  const find = (
    definition: VaultForwardDefinition
  ): VaultForwardConfig | undefined =>
    configs.find((config) => config?.definitionId === definition?.id)

  useEffect(() => {
    setRemovingVaultAuth(undefined)
  }, [remove.isSuccess, setRemovingVaultAuth])

  const columns: Array<ColumnDef<VaultForwardDefinition>> = [
    {
      header: 'Name',
      accessorKey: 'displayName',
      size: 80,
      cell: ({ row }) => <Text>{row.original.displayName}</Text>,
    },
    {
      header: 'Endpoints',
      accessorKey: 'hosts',
      size: 180,
      cell: ({ row }) => (
        <Flex gap={8}>
          {row.original.hosts.map((host) => (
            <Tag variant="subtle" key={host}>
              {new URL(host).hostname}
            </Tag>
          ))}
        </Flex>
      ),
    },
    {
      header: 'Enabled',
      size: 60,
      cell: ({ row }) => {
        const config = find(row.original)
        const createdAt = config?.createdAt
        return createdAt ? <DateTime value={createdAt} /> : '-'
      },
    },
    {
      header: 'State',
      size: 45,
      cell: ({ row }) => {
        const config = find(row.original)
        return config ? (
          <Badge variant="subtle" color="positive">
            Active
          </Badge>
        ) : (
          <Badge variant="subtle" color="neutral">
            Inactive
          </Badge>
        )
      },
    },
    {
      id: 'actions',
      accessorKey: 'id',
      size: 45,
      header: () => <Box textAlign="center">Actions</Box>,
      cell: function Actions({ row }) {
        const navigate = useNavigate()
        const config = find(row.original)
        const hasAuthentications =
          !!row.original?.authentications?.length && config

        return (
          <Box textAlign="center">
            <Dropdown>
              <Dropdown.Trigger asChild>
                <Dropdown.Button
                  variant="tertiary"
                  size="small"
                  disabled={!isStaff && !hasAuthentications}
                >
                  <Icon name="more-horizontal" />
                </Dropdown.Button>
              </Dropdown.Trigger>
              <Dropdown.Content align="end">
                {hasAuthentications && (
                  <Dropdown.Item
                    onSelect={() =>
                      navigate(
                        pathTo.vaultForwardingAuthentication(
                          merchantAccount.id,
                          config.id
                        )
                      )
                    }
                  >
                    Authentication methods
                  </Dropdown.Item>
                )}
                {isStaff && (
                  <>
                    {config ? (
                      <Dropdown.Item
                        opensInModal
                        onSelect={() => setRemovingVaultAuth(config)}
                        data-testid="action-menu-item-remove-vault-auth"
                      >
                        Disable
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        onSelect={() =>
                          enable.mutate({
                            merchantAccountId: merchantAccount.id,
                            definitionId: row.original.id,
                          })
                        }
                      >
                        Enable
                      </Dropdown.Item>
                    )}
                  </>
                )}
              </Dropdown.Content>
            </Dropdown>
          </Box>
        )
      },
    },
  ]

  return (
    <MerchantPagesLayout
      title={title}
      breadcrumbs={[
        { title: 'Merchants', url: pathTo.merchants },
        { title: merchantAccount.displayName },
        { title: 'Vault Forward' },
      ]}
    >
      <DataTable data={definitions} columns={columns} />
      {removingVaultAuth && (
        <ModalRemove
          title={`Do you want to disable Vault Forward for ${removingVaultAuth.displayName}?`}
          okText="Yes, disable"
          loading={remove.isPending}
          loadingText="Removing..."
          onCancel={() => setRemovingVaultAuth(undefined)}
          onOk={() => {
            remove.mutate({
              merchantAccountId: merchantAccount.id,
              id: removingVaultAuth.id,
            })
          }}
        >
          <Stack gap={16}>
            <Text>{getDisableWarning(removingVaultAuth)}</Text>
          </Stack>
        </ModalRemove>
      )}
    </MerchantPagesLayout>
  )
}

export default EditMerchantAccountVaultForwardingPage
