import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useConnectionRoutes } from 'connections/hooks/use-connection-routes'
import { listConnections } from 'connections/services'
import { PageLayout } from 'shared/components/PageLayout'
import { CONNECTIONS } from 'shared/constants'
import { ConnectionsConfiguredPage as View } from './ConnectionsConfiguredPage'
import { NoConnectionsConfigured } from './NoConnectionsConfigured'

const ConnectionsConfigurationPage = ({ title }: { title: string }) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const navigationTabs = useConnectionRoutes()

  const connections = useQuery({
    queryKey: [CONNECTIONS, merchantAccountId],
    queryFn: listConnections,
    meta: {
      error: {
        redirect: true,
      },
    },
  })

  if (connections.isLoading || !connections.isSuccess) {
    return null
  }

  return (
    <PageLayout title={title} subNavigation={navigationTabs}>
      {connections.data.items.length > 0 ? (
        <View connections={connections.data.items} />
      ) : (
        <NoConnectionsConfigured merchantAccountId={merchantAccountId} />
      )}
    </PageLayout>
  )
}
export default ConnectionsConfigurationPage
