import {
  Box,
  Description,
  Dropdown,
  Flex,
  Icon,
  Stack,
  Tag,
} from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { pathTo as pathToConnections } from 'connections'
import { ConfiguredConnection } from 'home/constants/connection'
import { getTransactionsFilterByConnection } from 'home/helpers/transaction'
import { ClientPagination } from 'shared/components/ClientPagination'
import {
  ColumnDef,
  DataTable,
  DataTableProps,
} from 'shared/components/DataTable'
import { useClientFiltering } from 'shared/hooks'
import { pathTo as pathToTransactions } from 'shared/paths/transactions'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { QueryResult } from 'shared/services/client'

export type ConfiguredConnectionsTableProps = Pick<
  DataTableProps<ConfiguredConnection>,
  'pagination'
> & {
  page: QueryResult<ConfiguredConnection>
}

const configuredConnectionTableColumns: Array<ColumnDef<ConfiguredConnection>> =
  [
    {
      id: 'name',
      accessorKey: 'name',
      size: 320,
      cell: ({ row }) => {
        return (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            height={24}
            gap={8}
          >
            <Box minWidth={0}>
              <Description>
                <Description.Icon src={row.original.iconUrl} />
                <Description.Text truncated="truncated">
                  {row.original.name}
                </Description.Text>
              </Description>
            </Box>
            {!row.original.active && <Tag variant="strong">Inactive</Tag>}
          </Flex>
        )
      },
      header: 'Configured connections',
    },
    {
      id: 'actions',
      header: '',
      size: 60,
      cell: function ActionsCell({ row }) {
        const { merchantAccountId } = useParams<{
          merchantAccountId: string
        }>()
        const canEditConnections = useResourcePermission(
          Resource.connections,
          AccessLevel.write
        )

        return merchantAccountId ? (
          <Box textAlign="center">
            <Dropdown>
              <Dropdown.Trigger asChild>
                <Dropdown.Button variant="tertiary" size="small">
                  <Icon name="more-horizontal" />
                </Dropdown.Button>
              </Dropdown.Trigger>
              <Dropdown.Content align="end">
                {canEditConnections && (
                  <a
                    href={pathToConnections.editConnectionSelector(
                      merchantAccountId,
                      row.original.group,
                      row.original.id,
                      row.original.definitionId
                    )}
                  >
                    <Dropdown.Item>Edit connection</Dropdown.Item>
                  </a>
                )}
                {['payment-service', 'digital-wallet'].includes(
                  row.original.group
                ) && (
                  <a
                    href={pathToTransactions.transactions(
                      merchantAccountId,
                      getTransactionsFilterByConnection(row.original)
                    )}
                  >
                    <Dropdown.Item>View transactions</Dropdown.Item>
                  </a>
                )}
              </Dropdown.Content>
            </Dropdown>
          </Box>
        ) : null
      },
    },
  ]

export const ConfiguredConnectionsTable = ({
  page,
  pagination,
}: ConfiguredConnectionsTableProps) => {
  const { data, isLoading } = page

  const { pagedData, ...rest } = useClientFiltering(data?.items || [], {
    limit: 10,
  })

  return (
    <Stack gap={32} width="full">
      <DataTable
        data={{ items: pagedData }}
        pagination={pagination}
        columns={configuredConnectionTableColumns}
        loading={isLoading}
      >
        <DataTable.Empty>No connections setup yet.</DataTable.Empty>
        <DataTable.Loading>Loading...</DataTable.Loading>
      </DataTable>
      {!!data && data.items.length > 10 && (
        <ClientPagination {...rest} limits={[10, 20, 50, 100]} />
      )}
    </Stack>
  )
}
