import { createContext } from 'react'
import { ADD_USER, INVITATION, REMOVE_USER } from 'users/constants'
import { User } from 'users/services/users'

export type ModalType = typeof ADD_USER | typeof REMOVE_USER | typeof INVITATION

interface UserModalContextProps {
  openUserModal: (user: User, modalName: ModalType) => void
}

export const UserModalContext = createContext<UserModalContextProps>({
  openUserModal: () => {},
})
