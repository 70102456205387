import { Modal, Stack, Button, Flex, Text } from '@gr4vy/poutine-react'
import React from 'react'

interface ModalCancelProps {
  children: React.ReactNode
  title: string
  okText?: string
  onOk: () => void
  onCancel: () => void
}

export const ModalCancel = ({
  children,
  title,
  okText = 'Yes, cancel',
  onOk,
  onCancel,
}: ModalCancelProps) => {
  return (
    <Modal
      open
      type="notice"
      title={title}
      onOpenChange={(status) => {
        !status && onCancel()
      }}
    >
      <Stack gap={24}>
        <Text>{children}</Text>
        <Flex justifyContent="flex-end">
          <Button variant="secondary" marginRight={16} onClick={onCancel}>
            No
          </Button>
          <Button onClick={onOk}>{okText}</Button>
        </Flex>
      </Stack>
    </Modal>
  )
}
