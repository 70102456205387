import { Button, Flex, Icon, Modal } from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { ApiKeyPair, UpdateApiKeyPair } from 'integrations/services/apiKeyPairs'

interface EditApiKeyPairModalProps {
  apiKeyPair: ApiKeyPair
  onUpdate: UseMutationResult<ApiKeyPair, any, UpdateApiKeyPair, unknown>
  onClose: () => void
}

type FormData = {
  displayName: string
}

export const EditApiKeyPairModal = ({
  apiKeyPair,
  onUpdate,
  onClose,
}: EditApiKeyPairModalProps) => {
  const [form] = Form.useForm<FormData>()
  const initialValues: FormData = {
    displayName: apiKeyPair.displayName,
  }

  const onSubmit = (resource: FormData) => {
    onUpdate.mutate(
      { id: apiKeyPair.id, ...resource },
      {
        onSuccess: () => {
          form.resetFields()
          onClose()
        },
        onError: () => form.resetFields(),
      }
    )
  }

  return (
    <Modal
      id="api-key-pair"
      icon={<Icon name="link" color="blue60" size="large" />}
      title="Edit API key"
      type="notice"
      open
      onOpenChange={() => onClose()}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        initialValues={initialValues}
        preserve={false}
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item
          label="Name"
          name="displayName"
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input
            disabled={onUpdate.isPending}
            autoComplete="off"
            maxLength={200}
          />
        </Form.Item>
        <Flex justifyContent="flex-end" gap={16}>
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            loading={onUpdate.isPending}
            loadingText="Updating..."
          >
            Update
          </Button>
        </Flex>
      </Form>
    </Modal>
  )
}
