import { Button } from '@gr4vy/poutine-react'
import { useState, SyntheticEvent } from 'react'
import { ModalRemove } from 'shared/components/Modal/ModalRemove'

export const RemoveRuleButton = ({ onDelete }: { onDelete: () => void }) => {
  const [removeModalVisible, setRemoveModalVisible] = useState(false)

  return (
    <>
      <Button
        variant="secondaryNegative"
        onClick={(e: SyntheticEvent) => {
          e.preventDefault()
          setRemoveModalVisible(true)
        }}
      >
        Delete rule
      </Button>
      {removeModalVisible && (
        <ModalRemove
          title="Delete rule"
          onCancel={() => setRemoveModalVisible(false)}
          onOk={() => {
            onDelete()
            setRemoveModalVisible(false)
          }}
        >
          You are about to delete this rule and this change is non-reversible.
          Are you sure you want to proceed?
        </ModalRemove>
      )}
    </>
  )
}
