import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
} from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { debounce } from 'lodash'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MerchantPagesLayout } from 'admin/components/MerchantsPageLayout/MerchantsPageLayout'
import { pathTo } from 'admin/paths'
import { CredentialField } from 'shared/components/CredentialField/CredentialField'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { is } from 'shared/helpers/is'
import {
  MerchantAccount,
  UpdateMerchantAccountWebhook,
} from 'shared/services/merchant-accounts'

interface EditMerchantAccountWebhooksPageProps {
  title: string
  merchantAccount: MerchantAccount
  update: UseMutationResult<
    MerchantAccount,
    any,
    UpdateMerchantAccountWebhook,
    unknown
  >
}

const getBreadcrumbs = (displayName: string) => [
  { title: 'Merchants', url: pathTo.merchants },
  { title: displayName },
]

const formatValue = (value: string | null) =>
  is.emptyString(value) ? null : value

const EditMerchantAccountWebhooksPage = ({
  title,
  merchantAccount,
  update,
}: EditMerchantAccountWebhooksPageProps) => {
  const [form] = Form.useForm<UpdateMerchantAccountWebhook>()
  const navigate = useNavigate()
  const debouncedValidation = debounce(form.validateFields, 200)
  const outboundWebhookUsername = Form.useWatch('outboundWebhookUsername', form)
  const outboundWebhookPassword = Form.useWatch('outboundWebhookPassword', form)

  useEffect(() => {
    form.setFieldsValue({
      id: merchantAccount.id,
      outboundWebhookUrl: is.null(merchantAccount.outboundWebhookUrl)
        ? undefined
        : merchantAccount.outboundWebhookUrl,
      outboundWebhookUsername: is.null(merchantAccount.outboundWebhookUsername)
        ? undefined
        : merchantAccount.outboundWebhookUsername,
      outboundWebhookPassword: undefined,
    })
  }, [form, merchantAccount])

  const preparePayload = (
    values: UpdateMerchantAccountWebhook
  ): UpdateMerchantAccountWebhook =>
    Object.entries(values).reduce(
      (acc, [key, value]) => {
        if (key === ('id' as keyof MerchantAccount)) {
          return { ...acc, [key]: value }
        }

        if (key === 'outboundWebhookPassword') {
          return { ...acc, [key]: formatValue(value) }
        }

        return {
          ...acc,
          [key]:
            merchantAccount[key as keyof MerchantAccount] === value
              ? undefined
              : formatValue(value),
        }
      },
      {
        ...values,
      }
    )

  const onSubmit = (values: UpdateMerchantAccountWebhook) =>
    update.mutate(preparePayload(values), {
      onSuccess: () => {
        form.resetFields(['outboundWebhookPassword'])
      },
    })

  return (
    <MerchantPagesLayout
      title={title}
      breadcrumbs={getBreadcrumbs(merchantAccount.displayName)}
    >
      <Grid>
        <GridItem gridColumn="span 6">
          <Stack gap={24} marginBottom={24}>
            <Text>
              Use this form to configure webhooks which will inform your own
              system about changes to Gr4vy resources (i.e. transactions). The
              endpoint URL below is cached for 2 minutes so there may be a short
              delay if you update this value.
            </Text>
          </Stack>
        </GridItem>
      </Grid>
      <Grid>
        <GridItem gridColumn="span 4">
          <Stack gap={32}>
            <Form
              name="update-merchant-webhook"
              form={form}
              onFinish={onSubmit}
              autoComplete="off"
              layout="vertical"
              requiredMark={false}
            >
              <Stack gap={24}>
                <FormItem
                  name="outboundWebhookUrl"
                  label={<Label>Endpoint URL</Label>}
                  required
                  rules={[
                    {
                      required: !!(
                        merchantAccount.outboundWebhookUsername &&
                        outboundWebhookUsername
                      ),
                      message: 'Enter URL.',
                    },
                    {
                      type: 'url',
                      message: 'Enter a valid URL.',
                    },
                  ]}
                >
                  <Input autoFocus />
                </FormItem>
                <Stack gap={24}>
                  <Heading level={6} as="h2">
                    Basic authentication (optional)
                  </Heading>
                  <FormItem
                    name="outboundWebhookUsername"
                    label={<Label>Username</Label>}
                    rules={[
                      {
                        required:
                          !is.emptyString(outboundWebhookPassword) &&
                          !!merchantAccount.outboundWebhookPassword,
                        message: 'Enter username.',
                      },
                    ]}
                  >
                    <Input />
                  </FormItem>
                  <CredentialField
                    item={{
                      key: 'outboundWebhookPassword',
                      displayName: <Label>Password</Label>,
                    }}
                    required={false}
                    onEdit={(isEditing) => {
                      form.setFieldValue(
                        'outboundWebhookPassword',
                        isEditing ? '' : undefined
                      )
                      debouncedValidation()
                    }}
                    secret={
                      is.undefined(outboundWebhookPassword) &&
                      !!merchantAccount.outboundWebhookPassword
                    }
                  />
                </Stack>
                <FormItem name="id" hidden>
                  <Input />
                </FormItem>
              </Stack>
            </Form>
            <Flex gap={16}>
              <Button
                form="update-merchant-webhook"
                loading={update.isPending}
                variant="primary"
              >
                Update
              </Button>
              <Button
                variant="secondary"
                disabled={update.isPending}
                onClick={() => navigate(pathTo.merchants)}
              >
                Cancel
              </Button>
            </Flex>
          </Stack>
        </GridItem>
      </Grid>
    </MerchantPagesLayout>
  )
}

export default EditMerchantAccountWebhooksPage
