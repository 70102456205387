import { Box, Stack, Text, ToggleSwitch } from '@gr4vy/poutine-react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Configure3DSecureSchemeDrawer } from 'connections/components/Configure3DSecureSchemeDrawer'
import { EditPaymentServiceForm } from 'connections/components/EditPaymentServiceForm/EditPaymentServiceForm'
import { PaymentService3DSecureSchemesTable } from 'connections/components/PaymentService3DSecure'
import { usePaymentService } from 'connections/hooks/use-payment-service'
import { usePaymentServices } from 'connections/hooks/use-payment-services'
import {
  CardSchemes,
  MerchantProfileScheme,
  PaymentServiceUpdate,
} from 'connections/services/paymentServices'
import { FormItem } from 'shared/components/FormItem'
import { Loading } from 'shared/components/Loading'
import { ModalRemove } from 'shared/components/Modal/ModalRemove'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'

const DeactivateModal = ({ onCancel, onOk }: any) => {
  return (
    <ModalRemove
      title="Are you sure you want to deactivate this scheme?"
      onCancel={onCancel}
      onOk={onOk}
      okText="Yes, deactivate"
    >
      By performing this action the schemes 3-D Secure data will be removed and
      cards for this scheme will no longer attempt 3-D Secure.
    </ModalRemove>
  )
}

export const PaymentService3DSecurePage = () => {
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }
  const [scheme, setScheme] = useState<MerchantProfileScheme | null>(null)
  const [deactivatingScheme, setDeactivatingScheme] = useState<string | null>(
    null
  )
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const hasWritePermission = useResourcePermission(
    Resource.antiFraudServices,
    AccessLevel.write
  )

  const { data: paymentService, isLoading: paymentServiceLoading } =
    usePaymentService(merchantAccountId, id)
  const { update } = usePaymentServices()
  const [checked, setChecked] = useState(paymentService?.threeDSecureEnabled)
  const handleChange = (checked: boolean) => {
    setChecked(checked)
    if (paymentService?.merchantProfile) {
      update.mutate({
        id,
        threeDSecureEnabled: checked,
      } as unknown as PaymentServiceUpdate)
    }
  }

  useEffect(() => {
    setChecked(paymentService?.threeDSecureEnabled)
  }, [paymentService])

  if (paymentServiceLoading) {
    return <Loading />
  }

  const configure = (value: typeof scheme) => {
    setScheme(value)
  }

  const deactivate = (value: typeof scheme) => {
    setDeactivatingScheme(value)
    setModalVisible(true)
  }

  const onDeactivateScheme = () => {
    if (!deactivatingScheme) {
      return
    }

    let activeSchemes = Object.entries(paymentService?.merchantProfile || {})
    activeSchemes = activeSchemes.filter(
      ([s, v]) => s !== deactivatingScheme && v
    )

    update.mutate({
      id,
      merchantProfile: {
        [deactivatingScheme]: null,
      },
      threeDSecureEnabled: !!activeSchemes.length,
    } as unknown as PaymentServiceUpdate)
  }

  const schemesData = {
    items: Object.entries(CardSchemes)
      .sort((a, b) => (a[1] < b[1] ? -1 : 1))
      .map(([id, label]) => {
        const merchantProfile =
          paymentService?.merchantProfile &&
          paymentService?.merchantProfile[id as keyof typeof CardSchemes]
        return {
          scheme: { id, label },
          activated: merchantProfile?.createdAt,
          status: merchantProfile
            ? { id: 'active', label: 'Active' }
            : { id: 'inactive', label: 'Inactive' },
        }
      }),
  }

  return (
    <EditPaymentServiceForm hasFooterButtons={false}>
      <Text margin="none">
        This payment service supports 3-D Secure authentication data. By
        enabling 3-D Secure below, Gr4vy will check each card transaction is
        enrolled for 3-D Secure and provide a buyer challenge where necessary to
        reduce fraudulent transactions.
      </Text>
      <Box display="flex" justifyContent="space-between">
        <Stack
          gap={8}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <FormItem
            name={['threeDSecureEnabled']}
            valuePropName="checked"
            trigger="onCheckedChange"
            shouldUpdate
          >
            <ToggleSwitch
              onCheckedChange={handleChange}
              disabled={!hasWritePermission}
            />
          </FormItem>
          <Text as="span">Enable 3-D Secure</Text>
        </Stack>
      </Box>
      {checked && (
        <PaymentService3DSecureSchemesTable
          configure={configure}
          data={schemesData}
          deactivate={deactivate}
          hasWritePermission={hasWritePermission}
        />
      )}
      <Configure3DSecureSchemeDrawer
        open={!!scheme}
        onClose={() => setScheme(null)}
        paymentService={paymentService}
        scheme={scheme}
      />
      {modalVisible && (
        <DeactivateModal
          onCancel={() => setModalVisible(false)}
          onOk={() => {
            onDeactivateScheme()
            setModalVisible(false)
          }}
        />
      )}
    </EditPaymentServiceForm>
  )
}
