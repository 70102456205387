import { Button, Flex, Heading, Icon } from '@gr4vy/poutine-react'

type ErrorMessageProps = {
  onClose: () => void
  onGoBack: () => void
}

export const ErrorMessage = ({ onClose, onGoBack }: ErrorMessageProps) => (
  <>
    <Icon name="exclamation-circle-md" size="large" color="red90" />
    <Heading as="h2" level={5}>
      There was an error while creating the new merchant account. Please try
      again.
    </Heading>
    <Flex gap={16}>
      <Button variant="secondary" onClick={onGoBack}>
        <Icon name="arrow-left-md" />
        back
      </Button>
      <Button variant="secondary" onClick={onClose}>
        Close
      </Button>
    </Flex>
  </>
)
