import { atoms, Icon } from '@gr4vy/poutine-react'
import { message } from 'antd'
import { ReactNode } from 'react'

const showSuccessMessage = (content: ReactNode | string, duration = 3) => {
  message.success({
    content,
    duration,
    icon: (
      <Icon
        name="check"
        size="medium"
        className={atoms({ color: 'green70', marginRight: 8 })}
      />
    ),
  })
}

const showProcessingMessage = (content: ReactNode | string, duration = 3) => {
  message.success({
    content,
    duration,
    icon: (
      <Icon
        name="processing"
        size="medium"
        className={atoms({ color: 'blue60', marginRight: 8 })}
      />
    ),
  })
}

const showWarningMessage = (content: ReactNode | string, duration = 3) => {
  message.warning({
    content,
    duration,
    icon: (
      <Icon
        name="warning"
        size="medium"
        className={atoms({ color: 'amber60', marginRight: 8 })}
      />
    ),
  })
}

const showErrorMessage = (content: ReactNode | string, duration = 3) => {
  message.error({
    content,
    duration,
    icon: (
      <Icon
        name="strike"
        size="medium"
        className={atoms({ color: 'red80', marginRight: 8 })}
      />
    ),
  })
}

export {
  showSuccessMessage,
  showProcessingMessage,
  showWarningMessage,
  showErrorMessage,
}
