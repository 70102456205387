import { Heading } from '@gr4vy/poutine-react'
import { Panel } from 'shared/components/Panel'
import { PaymentMethod, Transaction } from 'transactions/services'
import PaymentMethodsTable from '../PaymentMethodsTable/PaymentMethodsTable'

export interface PaymentMethodsPanelProps {
  transaction: Transaction
  paymentMethod?: PaymentMethod
}

export const PaymentMethodsPanel = ({
  transaction,
  paymentMethod,
}: PaymentMethodsPanelProps) => {
  return (
    <Panel>
      <Panel.Header>
        <Heading level={4}>Payment methods</Heading>
      </Panel.Header>
      <Panel.Content>
        <PaymentMethodsTable
          transaction={transaction}
          paymentMethod={paymentMethod}
        />
      </Panel.Content>
    </Panel>
  )
}
