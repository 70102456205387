import {
  Connection,
  PaymentService,
  PaymentServiceDefinition,
} from 'connections/services'

interface ConnectionsProps {
  connections?: Connection[]
  paymentServices?: PaymentService[]
  supportedPaymentServiceDefinitionIds: string[]
}

export interface ConnectionItem {
  id: string
  active: boolean
  name: string
  iconUrl: string
  settlementReportingEnabled: boolean
}

export const getSettlementReportingStatusById = (
  paymentServices: PaymentService[]
) =>
  paymentServices.reduce(
    (acc: Record<string, boolean>, paymentService) => ({
      ...acc,
      [paymentService.id]: paymentService.settlementReportingEnabled,
    }),
    {}
  )

export const extractPaymentServiceIdsWithSettlementReporting = (
  items?: PaymentServiceDefinition[]
) =>
  (items || [])
    .filter((item) => item.supportedFeatures.settlementReporting)
    .map((item) => item.id)

export const prepareConnections = ({
  connections = [],
  paymentServices = [],
  supportedPaymentServiceDefinitionIds = [],
}: ConnectionsProps) => {
  const settlementReportingStatusById =
    getSettlementReportingStatusById(paymentServices)

  return connections.reduce(
    (acc, connection) => {
      const connectionItem: ConnectionItem = {
        id: connection.id,
        active: connection.active,
        name: connection.name,
        iconUrl: connection.definition.iconUrl,
        settlementReportingEnabled:
          settlementReportingStatusById[connection.id],
      }

      if (
        supportedPaymentServiceDefinitionIds.includes(connection.definition.id)
      ) {
        acc.supportedConnections = [...acc.supportedConnections, connectionItem]
        return acc
      }

      acc.unsupportedConnections = [
        ...acc.unsupportedConnections,
        connectionItem,
      ]
      return acc
    },
    {
      supportedConnections: [] as ConnectionItem[],
      unsupportedConnections: [] as ConnectionItem[],
    }
  )
}
