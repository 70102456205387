import { useQuery } from '@tanstack/react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { GET_USER } from 'shared/constants'
import { getUser } from 'users/services/users'
import View from './UserInvitePage'

const UserInvitePage = ({ title }: { title: string }) => {
  const [searchParams] = useSearchParams()
  const { id } = useParams<{
    id: string
  }>()

  const resetToken = searchParams.get('resetToken') ?? ''

  const { data: user } = useQuery({
    queryKey: [GET_USER, id],
    queryFn: () => getUser(id as string),
    meta: {
      error: {
        redirect: true,
      },
    },
    refetchOnWindowFocus: false,
    enabled: !!id,
  })

  return (
    <View title={title} user={user ? { ...user, resetToken } : undefined} />
  )
}

export default UserInvitePage
