import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { GET_USER } from 'shared/constants'
import { getUser } from 'users/services/users'
import View from './UserUpdatedMerchantsPage'

const UserUpdatedMerchantsPage = ({ title }: { title: string }) => {
  const { id } = useParams<{
    id: string
  }>()

  const { data: user } = useQuery({
    queryKey: [GET_USER, id],
    queryFn: () => getUser(id as string),
    meta: {
      error: {
        redirect: true,
      },
    },
    refetchOnWindowFocus: false,
    enabled: !!id,
  })

  return <View title={title} user={user} />
}

export default UserUpdatedMerchantsPage
