import { Box } from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { CSSProperties } from 'react'
import { getReportStatusName } from 'reports/helpers/report'
import { ReportExecution } from 'reports/services/report-execution'
import styles from './ReportExecutionStatus.module.scss'
import { statusesMap } from './statuses-map'

interface StatusProps {
  value: ReportExecution['status']
}

export const ReportExecutionStatus = ({ value }: StatusProps) => {
  return (
    <Box
      as="span"
      className={clsx(styles.dot, styles[statusesMap[value].style])}
      style={
        {
          '--status-dot-color': statusesMap[value].color,
        } as CSSProperties
      }
    >
      {getReportStatusName(value)}
    </Box>
  )
}
