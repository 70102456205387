import { Input } from 'antd'
import { HelpText, Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'

export const MerchantNameInput = ({
  help = <HelpText>The name of your company.</HelpText>,
}) => (
  <FormItem
    name="merchantName"
    label={<Label>Merchant name</Label>}
    required
    rules={[{ required: true, message: 'Merchant name is required' }]}
    help={help}
  >
    <Input />
  </FormItem>
)
