import { Box, Dropdown, Icon, TextLine } from '@gr4vy/poutine-react'
import { capitalize } from 'lodash'
import { ApplePayCertificate } from 'connections/services/applePayCertificates'
import {
  ColumnDef,
  DataTable,
  DateTime,
  DataTableProps,
} from 'shared/components/DataTable'
import { Status } from 'shared/components/Status'
import {
  useResourcePermission,
  Resource,
  AccessLevel,
} from 'shared/permissions'
import { QueryResult } from 'shared/services/client'

export type CertificatesTableProps = Pick<
  DataTableProps<ApplePayCertificate>,
  'pagination'
> & {
  page: QueryResult<ApplePayCertificate>
  onResume: (data: ApplePayCertificate) => void
  onRemove: (data: ApplePayCertificate) => void
}

const CertificatesTable = ({
  page,
  pagination,
  onResume,
  onRemove,
}: CertificatesTableProps) => {
  const { data, isLoading: loading } = page
  const hasWritePermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )
  const columns: Array<ColumnDef<ApplePayCertificate>> = [
    {
      header: 'Name',
      accessorKey: 'displayName',
      size: 334,
      cell: ({ getValue }) => {
        return <TextLine display="block">{getValue() as string}</TextLine>
      },
    },
    {
      header: 'Merchant ID',
      accessorFn: ({ appleMerchantId }) => {
        return appleMerchantId ?? '-'
      },
      size: 277,
      cell: ({ getValue }) => {
        return <TextLine>{getValue() as string}</TextLine>
      },
    },
    {
      header: 'Activated',
      accessorKey: 'updatedAt',
      size: 188,
      cell: ({ getValue }) => {
        const updatedAt = getValue<ApplePayCertificate['updatedAt']>()
        return <DateTime value={updatedAt} />
      },
    },
    {
      header: 'Expires',
      accessorFn: ({ expiresAt }) => {
        return expiresAt ?? '-'
      },
      size: 154,
      cell: ({ getValue }) => {
        const expiresAt = getValue<ApplePayCertificate['expiresAt']>()
        if (expiresAt === '-') {
          return <TextLine>{expiresAt}</TextLine>
        }

        return <DateTime value={expiresAt} />
      },
    },
    {
      header: 'Status',
      accessorKey: 'status',
      size: 140,
      cell: ({ getValue }) => {
        const value = getValue() as ApplePayCertificate['status']
        return <Status value={value}>{capitalize(value)}</Status>
      },
    },
    {
      id: 'actions',
      size: 90,
      header: () => <Box textAlign="center">Actions</Box>,
      cell: function ActionsRow({ row }) {
        const { status } = row.original
        return (
          <Box textAlign="center">
            <Dropdown>
              <Dropdown.Trigger asChild>
                <Dropdown.Button
                  variant="tertiary"
                  size="small"
                  data-testid="action-menu"
                >
                  <Icon name="more-horizontal" />
                </Dropdown.Button>
              </Dropdown.Trigger>
              <Dropdown.Content align="end">
                {status === 'incomplete' && (
                  <Dropdown.Item
                    opensInModal
                    disabled={!hasWritePermission}
                    onSelect={() => onResume(row.original)}
                    data-testid="action-menu-item-resume-certificate-setup"
                  >
                    Resume setup
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  opensInModal
                  onSelect={() => onRemove(row.original)}
                  disabled={!hasWritePermission}
                  data-testid="action-menu-item-remove-certificate"
                >
                  Remove
                </Dropdown.Item>
              </Dropdown.Content>
            </Dropdown>
          </Box>
        )
      },
    },
  ]

  return (
    <DataTable
      data={data}
      loading={loading}
      columns={columns}
      pagination={pagination}
    >
      <DataTable.Empty>No certificates to display.</DataTable.Empty>
      <DataTable.Loading>Loading certificates...</DataTable.Loading>
    </DataTable>
  )
}

export default CertificatesTable
