import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  DigitalWalletEditFormValues as FormValues,
  listDigitalWallets,
  removeDigitalWallet,
  updateDigitalWallet,
} from 'connections/services'
import { DIGITAL_WALLET, DIGITAL_WALLETS } from 'shared/constants'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import { handleSuccessResponse } from 'shared/utils/handleSuccessResponse'

export const useDigitalWallet = (merchantAccountId: string) => {
  const queryClient = useQueryClient()
  const invalidateDigitalWallets = () =>
    queryClient.invalidateQueries({ queryKey: [DIGITAL_WALLETS] })
  const items = useQuery({
    queryKey: [DIGITAL_WALLETS, merchantAccountId],
    queryFn: listDigitalWallets,
    retry: 0,
  })
  const remove = useMutation({
    mutationFn: removeDigitalWallet,
    onSuccess: handleSuccessResponse(
      'Connection removed',
      invalidateDigitalWallets
    ),
    onError: handleErrorResponse,
  })
  const update = useMutation({
    mutationFn: ({ id, values }: { id: string; values: FormValues }) =>
      updateDigitalWallet(id, values),
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries({ queryKey: [DIGITAL_WALLET, id] })
    },
    onError: handleErrorResponse,
  })

  return { items, update, remove }
}
