import { Text } from '@gr4vy/poutine-react'

export const NoConnections = () => {
  return (
    <Text variant="med3">
      There are no connections set up yet. In order to schedule settlement
      reporting configure your connections first.
    </Text>
  )
}
