import { useQuery } from '@tanstack/react-query'
import { listConnections } from 'connections/services'
import { CONNECTIONS } from 'shared/constants'

export interface ConnectionsProps {
  merchantAccountId?: string
  enabled: boolean
}

export const useConnections = ({
  merchantAccountId,
  enabled = false,
}: ConnectionsProps) => {
  const { data, isLoading } = useQuery({
    queryKey: [CONNECTIONS, merchantAccountId],
    queryFn: listConnections,
    meta: {
      error: {
        redirect: true,
      },
    },
    enabled,
  })

  const showConnectionStep = enabled && !data?.items.length

  return {
    showConnectionStep,
    isLoadingConnections: isLoading,
  }
}
