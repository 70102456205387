import { createContext } from 'react'
import { HomeFilters } from 'home/constants/filter'

type FilterContextProps = {
  filters: Partial<HomeFilters>
  setFilters: (filters: Partial<HomeFilters>) => void
}

const FilterContext = createContext<FilterContextProps>({
  filters: {},
  setFilters: () => {},
})

export default FilterContext
