import { useMutation } from '@tanstack/react-query'
import { differenceInMilliseconds, parseISO } from 'date-fns'
import humanizeDuration from 'humanize-duration'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import { INVITATION } from 'users/constants'
import { UserModalContext } from 'users/contexts/UserModalContext'
import { resetPassword, User } from 'users/services/users'
import { useLoggedInUser } from './use-logged-in-user'

export const getResetPasswordLink = (resetToken: string, pathOnly?: boolean) =>
  `${
    pathOnly ? '' : window.location.origin
  }/set-password?resetToken=${resetToken}`

export const useResetPassword = (user?: User) => {
  const navigate = useNavigate()
  const { isLoggedInUser } = useLoggedInUser()
  const onReset = useMutation({
    mutationFn: resetPassword,
    onError: handleErrorResponse,
  })
  const { openUserModal } = useContext(UserModalContext)
  const isInvalidToken = !user?.resetToken || !user?.resetTokenExpiresAt

  const regenerateInviteLink = (user: User) => {
    onReset.mutate(user.emailAddress, {
      onSuccess: (userData) => {
        if (
          userData.hasValidPassword &&
          userData.resetToken &&
          isLoggedInUser(userData)
        ) {
          const resetLink = getResetPasswordLink(userData.resetToken, true)
          return navigate(resetLink)
        }

        return openUserModal(userData, INVITATION)
      },
    })
  }

  if (isInvalidToken) {
    return {
      expirationTime: undefined,
      isExpiredToken: false,
      isInvalidToken,
      resetLink: 'Invalid reset token, please try again.',
      regenerateInviteLink,
      reset: onReset,
    }
  }

  const expiry = parseISO(user.resetTokenExpiresAt as string)
  const diff = differenceInMilliseconds(expiry, new Date())
  const expirationTime = humanizeDuration(diff, { largest: 2, round: true })
  const isExpiredToken = !!user.resetTokenExpiresAt && diff < 0

  return {
    expirationTime,
    isExpiredToken,
    isInvalidToken: !user.resetToken || !user.resetTokenExpiresAt,
    resetLink: isExpiredToken
      ? 'Expired token, please try again.'
      : user.resetToken
        ? getResetPasswordLink(user.resetToken)
        : '',
    regenerateInviteLink,
    reset: onReset,
  }
}
