import { Flex, Tag, Tooltip } from '@gr4vy/poutine-react'
import { UserRole } from 'users/services/users'

const roleOrder = {
  Administrator: 1,
  Analyst: 2,
  'Customer Support': 3,
  'System Manager': 4,
  'System Support': 5,
  'User Manager': 6,
} as { [index: string]: number }

const roleSort = (a: string, b: string) => {
  if (roleOrder[a] && roleOrder[b]) {
    return roleOrder[a] - roleOrder[b]
  }

  if (roleOrder[a]) {
    return -1
  }

  if (roleOrder[b]) {
    return 1
  }
  return a.localeCompare(b)
}

const getRolesPriorityOrder = (roles: UserRole[]) =>
  roles.map((r) => r.name).sort(roleSort)

export const RolesList = ({ roles }: { roles?: UserRole[] }) => {
  if (!roles?.length) {
    return <>-</>
  }

  const [primaryRole, ...rest] = getRolesPriorityOrder(roles)

  return (
    <Flex gap={8} alignItems="center">
      {primaryRole}
      {!!rest.length && (
        <Tooltip
          content={
            <ul style={{ padding: 0, margin: '8px 16px' }}>
              {rest
                .sort((a, b) => a.localeCompare(b))
                .map((r) => (
                  <li key={r}>{r}</li>
                ))}
            </ul>
          }
        >
          <div>
            <Tag variant="subtle" color="neutral">
              +{rest.length} more
            </Tag>
          </div>
        </Tooltip>
      )}
    </Flex>
  )
}
