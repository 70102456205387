import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { omitBy, isUndefined } from 'lodash'
import {
  ConfigOutcomeType,
  BooleanOutcome,
  Outcome,
  RuleConfigOutcome,
} from 'flows/constants'
import { listOutcomes } from 'flows/services'
import { Flow, Action } from 'shared/constants'
import { CollectionResponse } from 'shared/services/client'

export type UseOutcomesProps = {
  merchantAccountId?: string
  flow: Flow
  action: Action
  outcome?: RuleConfigOutcome
  queryParams?: URLSearchParams
}

export const useOutcomes = ({
  merchantAccountId,
  flow,
  action,
  outcome,
  queryParams = new URLSearchParams(),
}: UseOutcomesProps): // eslint-disable-next-line max-params
UseQueryResult<CollectionResponse<Outcome>> =>
  useQuery({
    queryKey: [`${flow}-${action}-outcomes`, merchantAccountId],
    queryFn: () => {
      const paymentMethod = queryParams.get('payment_method') || undefined
      // outcome is not undefined at this point
      // as its triggered by `enabled`
      const outcomeType = (outcome as RuleConfigOutcome).type
      if (
        outcomeType === ConfigOutcomeType.BOOLEAN ||
        outcomeType === ConfigOutcomeType.THREE_D_SECURE
      ) {
        return { items: [] as Outcome[] } as CollectionResponse<BooleanOutcome>
      }
      return listOutcomes(flow, action, omitBy({ paymentMethod }, isUndefined))
    },
    enabled: !!outcome,
  })
