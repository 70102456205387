import { Text, TextLink } from '@gr4vy/poutine-react'

export const NoSearchResults = ({
  clearSearch,
}: {
  clearSearch: (value: string) => void
}) => (
  <Text>
    <TextLink
      href=""
      onClick={(e) => {
        e.preventDefault()
        clearSearch('')
      }}
    >
      View
    </TextLink>{' '}
    the full report list or try a another search term.
  </Text>
)
