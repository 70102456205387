import { Badge, Flex, Heading, Stack } from '@gr4vy/poutine-react'
import { Summary } from 'shared/components/Summary'
import EventsConnection from 'transactions/components/EventsPanel/EventsConnection'
import { ECIInfo } from 'transactions/components/ThreeDSecurePanel/ECIInfo'
import { TransactionEvent } from 'transactions/services'
import styles from './styles.module.scss'

export const DigitalWalletApplePayTokenDecryptedEvent = ({
  event,
}: {
  event?: TransactionEvent
}) => {
  return event ? (
    <Stack gap={32}>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Digital Wallet
        </Heading>
        <EventsConnection event={event} />
      </Stack>
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Instrument
        </Heading>
        <Stack gap={8} alignItems="flex-start" direction="row">
          <Badge variant="subtle" color="neutral">
            {event.context['version']}
          </Badge>
          <Badge
            variant="subtle"
            color={
              event.context['type'] === 'fpan' ? 'information' : 'positive'
            }
          >
            {event.context['type'] === 'fpan' ? 'FPAN' : 'DPAN'}
          </Badge>
          {event.context['type'] === 'dpan' && (
            <Badge
              variant="subtle"
              color={event.context['hasCryptogram'] ? 'positive' : 'negative'}
            >
              {event.context['hasCryptogram'] ? 'Cryptogram' : 'No cryptogram'}
            </Badge>
          )}
        </Stack>
      </Stack>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Card details
        </Heading>
        <Summary className={styles.summary} inline>
          <Summary.Key>Card suffix</Summary.Key>
          <Summary.Value fallback={!event.context['cardSuffix']}>
            {event.context['cardSuffix']}
          </Summary.Value>
          <Summary.Key>Card Expiration</Summary.Key>
          <Summary.Value fallback={!event.context['cardExpirationDate']}>
            {event.context['cardExpirationDate']}
          </Summary.Value>
          <Summary.Key>Cardholder name</Summary.Key>
          <Summary.Value fallback={!event.context['cardholderName']}>
            {event.context['cardholderName']}
          </Summary.Value>
        </Summary>
      </Stack>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Headers
        </Heading>
        <Summary className={styles.summary} inline>
          <Summary.Key>Transaction ID</Summary.Key>
          <Summary.Value fallback={!event.context['transactionIdentifier']}>
            {event.context['transactionIdentifier']}
          </Summary.Value>
          <Summary.Key>Application Data</Summary.Key>
          <Summary.Value fallback={!event.context['applicationData']}>
            {event.context['applicationData']}
          </Summary.Value>
        </Summary>
      </Stack>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Message
        </Heading>
        <Summary className={styles.summary} inline>
          <Summary.Key>Currency code</Summary.Key>
          <Summary.Value fallback={!event.context['currencyCode']}>
            {event.context['currencyCode']}
          </Summary.Value>
          <Summary.Key>Device manufacturer ID</Summary.Key>
          <Summary.Value
            fallback={!event.context['deviceManufacturerIdentifier']}
          >
            {event.context['deviceManufacturerIdentifier']}
          </Summary.Value>
          <Summary.Key>DPAN expiration date</Summary.Key>
          <Summary.Value fallback={!event.context['expirationDate']}>
            {event.context['expirationDate']}
          </Summary.Value>
          <Summary.Key>ECI</Summary.Key>
          <Summary.Value fallback={!event.context['eci']}>
            <Flex gap={4}>
              {event.context['eci'] ?? '-'}
              <ECIInfo eci={event.context['eci']} />
            </Flex>
          </Summary.Value>
          <Summary.Key>Merchant token ID</Summary.Key>
          <Summary.Value fallback={!event.context['merchantTokenIdentifier']}>
            {event.context['merchantTokenIdentifier']}
          </Summary.Value>
          <Summary.Key>Payment data type</Summary.Key>
          <Summary.Value fallback={!event.context['paymentDataType']}>
            {event.context['paymentDataType']}
          </Summary.Value>
          <Summary.Key>Transaction amount</Summary.Key>
          <Summary.Value fallback={!event.context['transactionAmount']}>
            {event.context['transactionAmount']}
          </Summary.Value>
        </Summary>
      </Stack>
    </Stack>
  ) : null
}
