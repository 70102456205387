import { uniq } from 'lodash'
import { useState } from 'react'
import { Category, ReportTemplate } from 'reports/constants'

export const useReportsSearch = (
  allTemplates: ReportTemplate[],
  allCategories: Category[]
) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [templates, setTemplates] = useState(allTemplates)
  const [categories, setCategories] = useState(allCategories)
  const search = (value: string) => {
    const foundTemplates = allTemplates.filter(
      ({ title, description }) =>
        title.toLowerCase().includes(value.toLowerCase()) ||
        description.toLowerCase().includes(value.toLowerCase())
    )
    setTemplates(foundTemplates)
    setCategories(uniq(foundTemplates.map(({ category }) => category)))
    setSearchTerm(value)
  }

  return {
    templates,
    categories,
    searchTerm,
    search,
  }
}
