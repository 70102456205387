import {
  Alert,
  Heading,
  Stack,
  Text,
  TextLink,
  TileGroup,
} from '@gr4vy/poutine-react'
import { TransactionActionOutcome } from 'flows/constants'
import { getInstrumentOptions } from 'flows/utils/cardOutcome'
import { FormItem } from 'shared/components/FormItem'

export type InstrumentStepProps = {
  selectedConnection: TransactionActionOutcome
  hidden: boolean
}

export const InstrumentStep = ({
  selectedConnection,
  hidden,
}: InstrumentStepProps) => (
  <Stack gap={8} display={hidden ? 'none' : 'flex'}>
    <FormItem
      label={
        <Stack gap={16}>
          <Heading as="h5" level={5}>
            Instrument
          </Heading>
          <Text variant="reg3" marginBottom={8}>
            The instrument defines the way in which we send the card data to the
            connection.
          </Text>
        </Stack>
      }
      name="instrument"
      trigger="onValueChange"
    >
      <TileGroup gap={8}>
        {getInstrumentOptions(selectedConnection).map((instrument) => (
          <TileGroup.Tile
            defaultValue={instrument.value}
            key={instrument.value}
            title={instrument.title}
            text={instrument.text}
            value={instrument.value}
          />
        ))}
      </TileGroup>
    </FormItem>
    {selectedConnection.networkTokensEnabled &&
      !selectedConnection.networkTokensSetupForMerchant && (
        <Alert variant="information" gap={8} padding={16}>
          <Alert.Icon size="medium" />
          <Alert.Content>
            <Text>
              To start using network tokens contact the{' '}
              <TextLink href="https://gr4vy.com" opensInNewWindow>
                sales team
              </TextLink>{' '}
              directly, or to learn more visit
              <TextLink
                href="https://docs.gr4vy.com/guides/features/network-tokens/overview"
                opensInNewWindow
              >
                Gr4vy&rsquo;s docs
              </TextLink>
              .
            </Text>
          </Alert.Content>
        </Alert>
      )}
  </Stack>
)
