import { NoResultsFound } from 'shared/components/NoResultsFound'
import { pathTo } from 'shared/paths/connections'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'

export const NoConnectionsConfigured = ({
  merchantAccountId,
}: {
  merchantAccountId: string
}) => {
  const hasPermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )

  return (
    <NoResultsFound
      heading="No configured connections yet"
      body="Once you&rsquo;ve set up your first connection, it will appear here. Get
        started via the connections catalog."
      buttonUrl={pathTo.connectionsCatalog(merchantAccountId)}
      buttonText="Go to catalog"
      showButton={hasPermission}
    />
  )
}
