import { Grid, GridItem } from '@gr4vy/poutine-react'
import DebouncedSearchBar from 'shared/components/DebouncedSearchBar'

type Props = {
  defaultValue?: string
  onChange: (value?: string) => void
}

export const ReportsSearch = ({ defaultValue, onChange }: Props) => (
  <Grid>
    <GridItem gridColumn="span 4">
      <DebouncedSearchBar
        placeholder="Search by report name or type..."
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </GridItem>
  </Grid>
)
