import {
  Button,
  Drawer,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
} from '@gr4vy/poutine-react'
import { Form, Input, Select } from 'antd'
import isoCountries from 'iso-3166-1'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePaymentServices } from 'connections/hooks/use-payment-services'
import {
  CardSchemes,
  MerchantProfileScheme,
  PaymentService,
  PaymentServiceUpdate,
} from 'connections/services'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'

const countryOptions = isoCountries.all().map((value) => ({
  label: `${value.country} (${value.numeric})`,
  value: value.numeric,
}))

export interface Configure3DSecureSchemeDrawerProps {
  onClose: () => void
  open: boolean
  paymentService: Partial<PaymentService> | undefined
  scheme: MerchantProfileScheme | null
}

export const Configure3DSecureSchemeDrawer = ({
  onClose,
  open,
  paymentService,
  scheme,
}: Configure3DSecureSchemeDrawerProps) => {
  const [form] = Form.useForm()
  const { id } = useParams()
  const { update } = usePaymentServices()
  const hasWritePermission = useResourcePermission(
    Resource.antiFraudServices,
    AccessLevel.write
  )
  const [status, setStatus] = useState<string | null>()
  const heading = (scheme && CardSchemes[scheme]) || ''
  const isUpdating =
    scheme &&
    paymentService?.merchantProfile &&
    paymentService?.merchantProfile[scheme]

  const onUpdate = () => {
    form.validateFields().then(
      (fields) => {
        update.mutate(
          {
            id,
            threeDSecureEnabled: true,
            ...fields,
          } as PaymentServiceUpdate,
          {
            onSuccess: () => {
              setStatus(isUpdating ? 'updated' : 'activated')
            },
          }
        )
      },
      // Do nothing instead of letting it throw an error.
      () => {}
    )
  }

  const handleClose = () => {
    onClose()
    setStatus(null)
    form.resetFields()
    form.setFieldsValue({ merchantProfile: paymentService?.merchantProfile })
  }

  useEffect(() => {
    form.setFieldsValue({ merchantProfile: paymentService?.merchantProfile })
  }, [form, paymentService])

  return (
    <Drawer
      open={open}
      title="Configure 3-D Secure"
      footer={
        <Flex gap={16} justifyContent="flex-end">
          {!status ? (
            <>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                onClick={onUpdate}
                loading={update.isPending}
                loadingText={isUpdating ? 'Update' : 'Activate'}
              >
                {isUpdating ? 'Update' : 'Activate'}
              </Button>
            </>
          ) : (
            <Button variant="secondary" onClick={handleClose}>
              Exit
            </Button>
          )}
        </Flex>
      }
      onClose={handleClose}
    >
      {status ? (
        <Stack gap={24}>
          <Icon name="circle-check" size="large" color="green60" />
          <Heading as="h2" level={5}>
            {heading} 3-D Secure{' '}
            <Text as="span" fontWeight="normal">
              {status} successfully!
            </Text>
          </Heading>
        </Stack>
      ) : (
        <Stack gap={24}>
          <Heading level={5}>{heading}</Heading>
          <Text margin="none">
            Complete all fields inline with your providers settings. These
            details should be entered correctly to ensure comprehensive security
            for every transaction.
          </Text>
          <Form form={form} layout="vertical" requiredMark={false}>
            <Stack gap={24}>
              <FormItem
                name={['merchantProfile', scheme, 'merchantAcquirerBin']}
                label={<Label>Acquirer BIN</Label>}
                rules={[
                  {
                    required: true,
                    message: 'Please enter the acquirer BIN',
                  },
                ]}
              >
                <Input maxLength={11} disabled={!hasWritePermission} />
              </FormItem>
              <FormItem
                name={['merchantProfile', scheme, 'merchantAcquirerId']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter the acquirer merchant ID',
                  },
                ]}
                label={<Label>Acquirer Merchant ID</Label>}
              >
                <Input maxLength={35} disabled={!hasWritePermission} />
              </FormItem>
              <FormItem
                name={['merchantProfile', scheme, 'merchantName']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter the merchant name',
                  },
                ]}
                label={<Label>Merchant Name</Label>}
              >
                <Input maxLength={40} disabled={!hasWritePermission} />
              </FormItem>
              <FormItem
                name={['merchantProfile', scheme, 'merchantCountryCode']}
                rules={[
                  {
                    required: true,
                    message: 'Please select the merchant country code',
                  },
                ]}
                label={<Label>Merchant Country Code</Label>}
              >
                <Select
                  disabled={!hasWritePermission}
                  filterOption={(input, option) =>
                    `${option?.label}`
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  getPopupContainer={(triggerNode: HTMLElement) => {
                    return triggerNode.parentElement as HTMLElement
                  }}
                  optionFilterProp="children"
                  options={countryOptions}
                  placeholder="Select an option"
                  showSearch
                />
              </FormItem>
              <FormItem
                name={['merchantProfile', scheme, 'merchantCategoryCode']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid four-digit number',
                  },
                  {
                    pattern: new RegExp('\\d{4}'),
                    message: 'Must be a four digit number',
                  },
                ]}
                label={<Label>Merchant Category Code</Label>}
              >
                <Input maxLength={4} disabled={!hasWritePermission} />
              </FormItem>
              <FormItem
                name={['merchantProfile', scheme, 'merchantUrl']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid URL',
                  },
                  {
                    type: 'url',
                    message: 'This field must be a valid url',
                  },
                ]}
                label={<Label>Merchant URL</Label>}
              >
                <Input maxLength={2048} disabled={!hasWritePermission} />
              </FormItem>
            </Stack>
          </Form>
        </Stack>
      )}
    </Drawer>
  )
}
