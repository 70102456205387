import { Box, Container, Description, Stack } from '@gr4vy/poutine-react'
import { useQuery } from '@tanstack/react-query'
import { ColumnDef, Row } from '@tanstack/react-table'
import { useParams } from 'react-router-dom'
import BuyerPageLayout from 'buyers/components/BuyerPageLayout'
import { TRANSACTIONS } from 'shared/constants/query-keys'
import { useCursor, useFilters } from 'shared/hooks'
import { TransactionFilters as ITransactionFilters } from 'shared/services/transactions'
import TransactionFilters from 'transactions/components/TransactionFilters'
import TransactionTable, {
  getTransactionColumns,
  Summary,
} from 'transactions/components/TransactionTable'
import { TransactionTableColumnId } from 'transactions/constants/table'
import { listTransactions } from 'transactions/services/transactions'

export default function BuyerTransactionsPage() {
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }
  const [filters, setFilters] = useFilters<ITransactionFilters>()
  const [cursor] = useCursor()

  const page = useQuery({
    queryKey: [TRANSACTIONS, id, merchantAccountId, cursor, filters],
    queryFn: () => listTransactions({ cursor, buyerId: id, ...filters }),
    refetchOnWindowFocus: false,
  })

  const updateFilter = (f: ITransactionFilters) => {
    setFilters({ ...filters, ...f })
  }

  const formatColumns = (column: ColumnDef<Summary, unknown>) =>
    ({
      [column.id as string]: column,
      [TransactionTableColumnId.BUYER]: {
        ...column,
        cell: function BuyerCell({ row }: { row: Row<Summary> }) {
          const transaction = row.original
          const displayName =
            transaction?.buyer?.displayName ||
            transaction?.buyer?.externalIdentifier

          return (
            displayName && (
              <Description>
                <Description.Text>{displayName}</Description.Text>
              </Description>
            )
          )
        },
      },
    })[column.id as string]

  const buyerTransactionColumns = getTransactionColumns().map(formatColumns)

  return (
    <BuyerPageLayout>
      <Box padding={32}>
        <Container>
          <Stack gap={32}>
            <TransactionFilters filters={filters} onFilter={updateFilter} />
            <TransactionTable
              page={page}
              columns={buyerTransactionColumns}
              pagination={{ filters, onFilter: updateFilter }}
            />
          </Stack>
        </Container>
      </Box>
    </BuyerPageLayout>
  )
}
