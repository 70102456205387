import { Dropdown } from '@gr4vy/poutine-react'
import { ComponentProps } from 'react'
import styles from './Select.module.scss'

export type OptionProps = ComponentProps<typeof Dropdown.Item>

export const Option = ({ children, ...rest }: OptionProps) => {
  return (
    <Dropdown.Item {...rest} className={styles.option}>
      {children}
    </Dropdown.Item>
  )
}
