import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  addGiftCardService,
  removeGiftCardService,
  updateGiftCardService,
} from 'connections/services'
import { GIFT_CARD_SERVICES } from 'shared/constants'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import { handleSuccessResponse } from 'shared/utils/handleSuccessResponse'

export const useGiftCardServices = () => {
  const queryClient = useQueryClient()

  const invalidateGiftCardServices = () =>
    queryClient.invalidateQueries({ queryKey: [GIFT_CARD_SERVICES] })

  const create = useMutation({
    mutationFn: addGiftCardService,
    onSuccess: handleSuccessResponse(
      'Connection created',
      invalidateGiftCardServices
    ),
    onError: handleErrorResponse,
  })

  const update = useMutation({
    mutationFn: updateGiftCardService,
    onSuccess: (data) => {
      handleSuccessResponse(
        <>
          <b>{data.displayName || 'Connection'}</b> has been updated
        </>,
        invalidateGiftCardServices
      )()
    },
    onError: handleErrorResponse,
  })

  const remove = useMutation({
    mutationFn: removeGiftCardService,
    onSuccess: handleSuccessResponse(
      'Connection removed',
      invalidateGiftCardServices
    ),
    onError: handleErrorResponse,
  })

  return {
    create,
    update,
    remove,
  }
}
