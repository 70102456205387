import { getCurrencyByCode } from '@gr4vy/poutine-react'
import { useMemo } from 'react'

export const useCurrencies = (supportedCurrencies: string[] | undefined) =>
  useMemo(
    () =>
      supportedCurrencies
        ?.map((value) => ({
          value,
          label: `${value} - ${getCurrencyByCode(value)?.currency}`,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
    [supportedCurrencies]
  )
