import {
  Alert,
  Checkbox,
  Heading,
  Stack,
  Text,
  atoms,
} from '@gr4vy/poutine-react'
import { Form, Input } from 'antd'
import { Copy } from 'shared/components/Copy'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { Cybersource } from './Cybersource'

export const CybersourceOverviewForm = () => {
  const form = Form.useFormInstance()
  const webhookUrl = form.getFieldValue('webhookUrl')
  const active = Form.useWatch('active')

  const hasWritePermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )

  return (
    <>
      {active && (
        <Alert variant="information">
          <Alert.Icon />
          <Alert.Content>
            <Alert.Title>
              Only 1 anti-fraud connection can be active at a time
            </Alert.Title>
            <Alert.Text>
              Activating this connection will de-activate all other anti-fraud
              connections. Please make sure to update all Flow rules to use
              conditions for this connection before activating.
            </Alert.Text>
          </Alert.Content>
        </Alert>
      )}
      <Text margin="none">
        Use this page to set up your connection, including a custom display
        name. This will help you find this connection within the dashboard. You
        can also toggle the status of this connection.
      </Text>
      <FormItem
        name="displayName"
        label={<Label>Display name</Label>}
        rules={[{ required: true, message: 'This field is required' }]}
        style={{ margin: 0 }}
      >
        <Input disabled={!hasWritePermission} />
      </FormItem>
      <FormItem
        name="active"
        label={<Label>Connection status</Label>}
        valuePropName="checked"
        trigger="onCheckedChange"
        help={
          <Text as="span" className={atoms({ fontSize: 'xs', paddingTop: 4 })}>
            When disabled this connection will no longer be used to run
            anti-fraud decision.
          </Text>
        }
      >
        <Checkbox disabled={!hasWritePermission}>Active</Checkbox>
      </FormItem>
      <Stack gap={8}>
        <Heading level={4}>Manual reviews</Heading>
        <Text marginBottom="none">
          When a transaction is held in a manual review queue we will mark it as
          pending review and delay capture until we receive a webhook, or until
          it is manually captured or voided.
        </Text>
      </Stack>
      <FormItem
        name="reviewsEnabled"
        label={<Label>Review queue status</Label>}
        valuePropName="checked"
        trigger="onCheckedChange"
      >
        <Checkbox disabled={!hasWritePermission}>Enable review queue</Checkbox>
      </FormItem>
      <FormItem label={<Label>Review queue webhook URL</Label>}>
        <Copy text={webhookUrl} />
      </FormItem>
    </>
  )
}

export const CybersourceOverview = () => (
  <Cybersource submitButtonText="Save changes">
    <CybersourceOverviewForm />
  </Cybersource>
)

export default CybersourceOverview
