import { GridItem } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import { Operation } from 'flows/constants'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'

interface ProductTypeInputProps {
  name: number
}

const filter = (value: string, option: any) =>
  option.value.toLowerCase().includes(value.toLowerCase()) ||
  option.label.toLowerCase().includes(value.toLowerCase())

const ERROR_PRODUCT_TYPE_REQUIRED = 'At least one product type is required'
const ERROR_OPERATOR_REQUIRED = 'Operator is required'

const productTypes = [
  {
    key: 'physical',
    name: 'Physical',
  },
  {
    key: 'discount',
    name: 'Discount',
  },
  {
    key: 'shipping_fee',
    name: 'Shipping Fee',
  },
  {
    key: 'sales_tax',
    name: 'Sales Tax',
  },
  {
    key: 'digital',
    name: 'Digital',
  },
  {
    key: 'gift_card',
    name: 'Gift Card',
  },
  {
    key: 'store_credit',
    name: 'Store Credit',
  },
  {
    key: 'surcharge',
    name: 'Surcharge',
  },
]

const ProductTypeInput = ({ name }: ProductTypeInputProps) => (
  <>
    <GridItem gridColumn="span 2">
      <FormItem
        label={<Label>Operator</Label>}
        name={[name, 'operator']}
        rules={[{ required: true, message: ERROR_OPERATOR_REQUIRED }]}
      >
        <Select placeholder="Select an operator">
          <Select.Option value={Operation.INCLUDES_AT_LEAST_ONE}>
            includes at least one
          </Select.Option>
          <Select.Option value={Operation.INCLUDES_ALL}>
            includes all
          </Select.Option>
          <Select.Option value={Operation.INCLUDES_NONE}>
            includes none
          </Select.Option>
        </Select>
      </FormItem>
    </GridItem>
    <GridItem gridColumn="span 6">
      <FormItem
        label={<Label>Value(s)</Label>}
        name={[name, 'value']}
        rules={[{ required: true, message: ERROR_PRODUCT_TYPE_REQUIRED }]}
      >
        <Select
          mode="multiple"
          allowClear
          maxTagCount="responsive"
          maxTagPlaceholder={maxTagPlaceholderFormatter}
          placeholder="Select an option"
          filterOption={filter}
        >
          {productTypes.map((productType) => (
            <Select.Option
              value={productType.key}
              label={productType.name}
              key={productType.key}
            >
              {productType.name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    </GridItem>
  </>
)

export { ERROR_PRODUCT_TYPE_REQUIRED, ERROR_OPERATOR_REQUIRED }
export default ProductTypeInput
