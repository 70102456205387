import { Button, Icon, Text } from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { CSSProperties, forwardRef } from 'react'
import { env } from 'shared/constants/env'
import { isSandbox } from 'shared/helpers/is-sandbox'
import styles from './TriggerButton.module.scss'

export interface TriggerButtonProps {
  open: boolean
  children: string
  readOnly?: boolean
}

export interface CustomCSSProperties extends CSSProperties {
  '--char-limit': string
}

const charLimitStyle: CustomCSSProperties = {
  '--char-limit': isSandbox && env.REACT_APP_MERCHANT_LOGO ? '28ch' : '42ch',
}

export const TriggerButton = forwardRef<HTMLButtonElement, TriggerButtonProps>(
  ({ open, readOnly, children, ...rest }, ref) => {
    return (
      <Button
        {...rest}
        ref={ref}
        className={clsx([
          styles.trigger,
          open && styles.active,
          readOnly && styles.readOnly,
        ])}
        tabIndex={readOnly ? -1 : 0}
        style={charLimitStyle}
      >
        <Text truncate>{children}</Text>
        {!readOnly && (
          <Icon
            name={open ? 'chevron-up-lg' : 'chevron-down-lg'}
            style={{ flexShrink: 0 }}
          />
        )}
      </Button>
    )
  }
)

TriggerButton.displayName = 'TriggerButton'
