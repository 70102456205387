import {
  FilterButton,
  Popover,
  Flex,
  Checkbox,
  Stack,
} from '@gr4vy/poutine-react'
import { capitalize, compact, without } from 'lodash'
import { useMemo } from 'react'
import { ReportsFilters, Frequency } from 'reports/services/report'
import { useFilters } from 'shared/hooks'
import styles from './ReportFrequencyFilter.module.scss'

const frequencyOptions = ['daily', 'monthly', 'weekly'] as Frequency[]

export const ReportFrequencyFilter = () => {
  const [filters, setFilters] = useFilters<ReportsFilters>()

  const selectedFrequencies: Frequency[] = useMemo(
    () => compact([filters?.schedule].flat()),
    [filters]
  )

  const handleToggleFilter = (frequency: Frequency) => {
    let nextFrequencies
    if (selectedFrequencies.includes(frequency)) {
      nextFrequencies = without(selectedFrequencies, frequency)
    } else {
      nextFrequencies = [...selectedFrequencies, frequency]
    }

    setFilters({
      ...filters,
      schedule: nextFrequencies.length ? nextFrequencies : undefined,
    })
  }

  return (
    <Popover>
      <Popover.Trigger asChild>
        <FilterButton>
          Frequency
          {selectedFrequencies.length
            ? ` / ${selectedFrequencies.length}`
            : null}
        </FilterButton>
      </Popover.Trigger>
      <Popover.Content align="start" side="bottom" avoidCollisions={false}>
        <Stack gap={4} className={styles.contentWrapper}>
          {frequencyOptions.map((frequency) => (
            <Flex key={frequency} alignItems="center" className={styles.item}>
              <Checkbox
                id={frequency}
                onCheckedChange={() => handleToggleFilter(frequency)}
                checked={selectedFrequencies.includes(frequency)}
              >
                {capitalize(frequency)}
              </Checkbox>
            </Flex>
          ))}
        </Stack>
      </Popover.Content>
    </Popover>
  )
}
