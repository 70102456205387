import { useParams } from 'react-router-dom'
import { useGiftCardService } from './use-gift-card-service'
import { useGiftCardServiceDefinition } from './use-gift-card-service-definition'

export const useGiftCardServiceAndDefinition = () => {
  const { id, merchantAccountId } = useParams<{
    id: string
    merchantAccountId: string
  }>()

  const { giftCardService, isLoading: serviceLoading } = useGiftCardService(
    merchantAccountId,
    id
  )

  const { giftCardServiceDefinition, isLoading: definitionLoading } =
    useGiftCardServiceDefinition(
      merchantAccountId,
      giftCardService?.giftCardServiceDefinitionId
    )

  return {
    service: giftCardService,
    definition: giftCardServiceDefinition,
    isLoading: serviceLoading || definitionLoading,
  }
}
