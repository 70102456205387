import { Heading, Stack, Text } from '@gr4vy/poutine-react'
import React from 'react'

export const StepDownloadCsr = () => {
  return (
    <Stack gap={24}>
      <Heading level={5}>Download CSR certificate</Heading>
      <Text>
        Now, you will download the Certificate Sign Request (CSR) that can be
        used to create a new certificate.
      </Text>
      <Text>
        Once you have downloaded the CSR, we will guide you through the steps to
        generate a certificate with Apple.
      </Text>
    </Stack>
  )
}
