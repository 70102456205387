import { Badge, Description, Heading, Stack } from '@gr4vy/poutine-react'
import { Summary } from 'shared/components/Summary'
import { TransactionEvent } from 'transactions/services'
import styles from './styles.module.scss'

export const DigitalWalletClickToPayTokenDecryptedEvent = ({
  event,
}: {
  event?: TransactionEvent
}) => {
  return event ? (
    <Stack gap={32}>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Digital Wallet
        </Heading>
        <Description>
          <Description.Text>Click to Pay</Description.Text>
        </Description>
      </Stack>
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Instrument
        </Heading>
        <Stack gap={8} alignItems="flex-start" direction="row">
          <Badge
            variant="subtle"
            color={
              event.context['type'] === 'dpan' ? 'positive' : 'information'
            }
          >
            {event.context['type']
              ? event.context['type'].toUpperCase()
              : 'Unknown'}
          </Badge>
          {event.context['type'] === 'dpan' && (
            <Badge
              variant="subtle"
              color={event.context['hasCryptogram'] ? 'positive' : 'negative'}
            >
              {event.context['hasCryptogram'] ? 'Cryptogram' : 'No cryptogram'}
            </Badge>
          )}
        </Stack>
      </Stack>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Details
        </Heading>
        <Summary className={styles.summary} inline>
          <Summary.Key>Expiration</Summary.Key>
          <Summary.Value fallback={!event.context['expirationDate']}>
            {event.context['expirationDate']}
          </Summary.Value>
          <Summary.Key>Cardholder name</Summary.Key>
          <Summary.Value fallback={!event.context['cardholderName']}>
            {event.context['cardholderName']}
          </Summary.Value>
          <Summary.Key>Card BIN</Summary.Key>
          <Summary.Value fallback={!event.context['cardBin']}>
            {event.context['cardBin']}
          </Summary.Value>
          <Summary.Key>Card last four</Summary.Key>
          <Summary.Value fallback={!event.context['cardLastFour']}>
            {event.context['cardLastFour']}
          </Summary.Value>
          <Summary.Key>Card expiration date</Summary.Key>
          <Summary.Value fallback={!event.context['cardExpirationDate']}>
            {event.context['cardExpirationDate']}
          </Summary.Value>
          <Summary.Key>Card type</Summary.Key>
          <Summary.Value fallback={!event.context['cardType']}>
            {event.context['cardType']}
          </Summary.Value>
        </Summary>
      </Stack>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Message
        </Heading>
        <Summary className={styles.summary} inline>
          <Summary.Key>Correlation ID</Summary.Key>
          <Summary.Value fallback={!event.context['correlationId']}>
            {event.context['correlationId']}
          </Summary.Value>
          <Summary.Key>Merchant Transaction ID</Summary.Key>
          <Summary.Value fallback={!event.context['merchantTransactionId']}>
            {event.context['merchantTransactionId']}
          </Summary.Value>
        </Summary>
      </Stack>
    </Stack>
  ) : null
}
