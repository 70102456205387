import { Flex, Button, ButtonCopy } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { ReportExecutionStatus } from 'reports/components/ReportExecutionStatus'
import { isReportDownloadable } from 'reports/helpers/report'
import {
  useReportExecution,
  useDownloadExecutionUrl,
} from 'reports/hooks/use-report-execution'
import { ReportExecution } from 'reports/services/report-execution'
import { Loading } from 'shared/components/Loading'
import { PageLayout } from 'shared/components/PageLayout'
import { SummaryGrid } from 'shared/components/SummaryGrid'
import { dateFormat } from 'shared/helpers/date-format'
import { pathTo } from 'shared/paths/reports'

export const ReportDetailsPage = () => {
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }
  const { data } = useReportExecution(merchantAccountId, id)
  const isDownloadable = isReportDownloadable(data?.status)
  const { mutate, isPending: isDownloading } = useDownloadExecutionUrl()
  const handleDownloadClick = (data: ReportExecution) => {
    mutate(
      { executionId: data.id, reportId: data.report.id },
      {
        onSuccess: ({ url }) => {
          window.open(url, '_blank')
        },
      }
    )
  }

  const breadcrumbs = [
    {
      title: 'Generated',
      url: pathTo.generatedReports(merchantAccountId),
    },
    {
      title: 'Details',
    },
  ]

  return data ? (
    <PageLayout
      title={data.report.name || ''}
      breadcrumbs={breadcrumbs}
      actions={
        <Flex gap={16}>
          <ButtonCopy variant="secondary" copy={document.location.href}>
            Copy link
          </ButtonCopy>
          {isDownloadable && (
            <Button
              loading={isDownloading}
              loadingText="Downloading CSV"
              onClick={() => handleDownloadClick(data)}
            >
              Download CSV
            </Button>
          )}
        </Flex>
      }
    >
      <SummaryGrid>
        <SummaryGrid.Item gridColumn="span 4">
          <SummaryGrid.Key>Report ID</SummaryGrid.Key>
          <SummaryGrid.Value>{data.id}</SummaryGrid.Value>
        </SummaryGrid.Item>
        <SummaryGrid.Item gridColumn="span 4">
          <SummaryGrid.Key>Generated</SummaryGrid.Key>
          <SummaryGrid.Value>{dateFormat(data.createdAt)}</SummaryGrid.Value>
        </SummaryGrid.Item>
        <SummaryGrid.Item gridColumn="span 4">
          <SummaryGrid.Key>Status</SummaryGrid.Key>
          <SummaryGrid.Value>
            <ReportExecutionStatus value={data.status} />
          </SummaryGrid.Value>
        </SummaryGrid.Item>
      </SummaryGrid>
    </PageLayout>
  ) : (
    <Loading />
  )
}
