import client, { CollectionResponse } from 'shared/services/client'

export interface GiftCardServiceDefinitionField {
  key: string
  displayName: string
  required: boolean
  format: 'text' | 'multiline' | 'number'
  secret: boolean
}

export interface GiftCardServiceDefinition {
  type: string
  id: string
  displayName: string
  fields: GiftCardServiceDefinitionField[]
  iconUrl: string
}

export const listGiftCardServiceDefinitions = async (
  params = {}
): Promise<CollectionResponse<GiftCardServiceDefinition>> => {
  const response = await client.get('/gift-card-service-definitions', params)
  return response.data
}

export const getGiftCardDefinition = async (
  id: string,
  params = {}
): Promise<GiftCardServiceDefinition> => {
  const response = await client.get(`/gift-card-service-definitions/${id}`, {
    params,
  })
  return response.data
}
