import { Box, Flex, Container } from '@gr4vy/poutine-react'
import { useEffect, useRef, useState } from 'react'
import {
  merchantLogo,
  merchantLogoFooterPosition,
} from 'shared/constants/merchant'
import styles from './Footer.module.scss'
import poweredBy from './powered-by.svg'

export const Footer = () => {
  const footerRef = useRef<HTMLDivElement>(null)
  const [hasScroll, setHasScroll] = useState<boolean>(false)

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const [entry] = entries

      setHasScroll(entry.target.scrollHeight > window.innerHeight)
    })

    observer.observe(document.querySelector('#root') || document.body)

    return () =>
      observer.unobserve(document.querySelector('#root') || document.body)
  }, [])

  return merchantLogo && merchantLogoFooterPosition ? (
    <Box
      ref={footerRef}
      display="flex"
      background="white"
      justifyContent="center"
      width="full"
      className={hasScroll ? null : styles.wrapper}
    >
      <Container>
        <Flex
          justifyContent="center"
          alignItems="center"
          color="white"
          height={56}
          as="nav"
          aria-label="top"
          className={styles.footer}
        >
          <Box>
            <img src={poweredBy} alt="Powered by Gr4vy" width={140} />
          </Box>
        </Flex>
      </Container>
    </Box>
  ) : (
    <></>
  )
}
