import { Text } from '@gr4vy/poutine-react'
import { CountryInput } from 'connections/components/CountryInput/CountryInput'
import { CurrencyInput } from 'connections/components/CurrencyInput/CurrencyInput'
import { EditPaymentServiceForm } from 'connections/components/EditPaymentServiceForm/EditPaymentServiceForm'
import { usePaymentServiceAndDefinition } from 'connections/hooks/use-payment-service-and-definition'
import { Loading } from 'shared/components/Loading'
import {
  useResourcePermission,
  Resource,
  AccessLevel,
} from 'shared/permissions'

export const PaymentServiceRegionDetailsPage = () => {
  const { definition, isLoading } = usePaymentServiceAndDefinition()
  const hasPermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )

  if (isLoading) {
    return <Loading />
  }
  return (
    <EditPaymentServiceForm submitButtonText="Save changes">
      <Text margin="none">
        Specify the countries and currencies that you know are supported by this
        service for your account. This may depend on your contract with this
        service provider.
      </Text>
      <CountryInput
        disabled={!hasPermission}
        supportedCountries={definition?.supportedCountries}
      />
      <CurrencyInput
        disabled={!hasPermission}
        supportedCurrencies={definition?.supportedCurrencies}
      />
    </EditPaymentServiceForm>
  )
}
