import { Drawer } from '@gr4vy/poutine-react'
import { useEffect } from 'react'
import { ReportExecutionMessage } from 'reports/components/ReportExecutionMessage'
import { TemplatedReportForm } from 'reports/components/TemplatedReportForm'
import { reportTemplates } from 'reports/constants'
import { getRangeByFrequency } from 'reports/helpers/schedule'
import { useCreateReport } from 'reports/hooks/use-report'
import { Frequency, ReportPayload } from 'reports/services/report'
import { useHandleErrorResponse } from 'shared/hooks'

interface Props {
  templateId: string | null
  open: boolean
  schedule?: boolean
  onClose: () => void
}

export const TemplatedReportDrawer = ({
  templateId,
  open,
  schedule,
  onClose,
}: Props) => {
  const template = reportTemplates.find(({ id }) => templateId === id)
  const handleErrorResponse = useHandleErrorResponse()
  const { mutate: create, isPending, isSuccess, reset } = useCreateReport()
  const handleSubmit = (data: {
    timezone: string
    schedule?: ReportPayload['schedule']
    from?: string
    to?: string
  }) => {
    if (template) {
      create(
        {
          name: template.title,
          schedule: schedule ? data.schedule : 'once',
          scheduleEnabled: !!schedule,
          scheduleTimezone: data.timezone,
          spec: template.spec(
            schedule
              ? getRangeByFrequency(data.schedule as Frequency)
              : { from: data.from, to: data.to }
          ),
        },
        {
          onError: handleErrorResponse,
        }
      )
    }
  }
  useEffect(() => {
    reset()
  }, [reset, open])

  return (
    <Drawer
      open={open}
      title={`${schedule ? 'Schedule' : 'Generate'} report`}
      onClose={onClose}
    >
      {isSuccess ? (
        <ReportExecutionMessage schedule={schedule} onClose={onClose} />
      ) : (
        <TemplatedReportForm
          isLoading={isPending}
          schedule={schedule}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      )}
    </Drawer>
  )
}
