import { Heading, Icon, Stack, Button, Flex, Text } from '@gr4vy/poutine-react'
import { useNavigate, useParams } from 'react-router-dom'
import { pathTo } from 'shared/paths/reports'

interface Props {
  schedule?: boolean
  onClose: () => void
}

export const ReportExecutionMessage = ({ schedule, onClose }: Props) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const navigate = useNavigate()
  const handleSeeReportsClick = () => {
    navigate(
      schedule
        ? pathTo.scheduledReports(merchantAccountId)
        : pathTo.generatedReports(merchantAccountId)
    )
    onClose()
  }

  return (
    <Stack gap={32}>
      <Stack gap={16}>
        <Icon name="circle-check" size="large" color="green70" />
        <Stack gap={8}>
          <Heading level={5}>
            {schedule ? 'Successfully scheduled' : 'Successfully queued'}
          </Heading>
          <Text as="div">
            {schedule
              ? 'You will receive a notification when each scheduled report is ready.'
              : 'You will receive a notification when your report is ready.'}
          </Text>
        </Stack>
      </Stack>
      <Flex gap={16}>
        <Button onClick={handleSeeReportsClick}>
          See {schedule ? 'scheduled' : 'generated'} reports
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Exit
        </Button>
      </Flex>
    </Stack>
  )
}
