import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  addApplePayCertificate,
  uploadApplePayCertificate,
} from 'connections/services/applePayCertificates'
import { APPLE_PAY_CERTIFICATES } from 'shared/constants'

export const useAddApplePayCertificate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addApplePayCertificate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [APPLE_PAY_CERTIFICATES] })
    },
  })
}

export const useUpdateApplePayCertificate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: uploadApplePayCertificate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [APPLE_PAY_CERTIFICATES] })
    },
  })
}
