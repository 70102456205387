import { atoms, Text, TextLink } from '@gr4vy/poutine-react'
import { Input } from 'antd'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { ForterEdit } from './ForterEdit'

export const ForterMerchantInfo = () => {
  const hasWritePermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )

  return (
    <ForterEdit submitButtonText="Save changes">
      <Text marginBottom="none">
        These default{' '}
        <TextLink
          href="https://portal.forter.com/app/developer/api/api/data-objects/MerchantIdentifiers"
          opensInNewWindow
        >
          merchant identifiers
        </TextLink>{' '}
        help Forter identify the right transaction with the right site.
      </Text>
      <FormItem
        name={['fields', 'merchant_id']}
        label={<Label optional>Merchant ID</Label>}
        extra={
          <Text as="span" className={atoms({ fontSize: 'xs', paddingTop: 4 })}>
            The unique ID for of your Forter merchant account.
          </Text>
        }
      >
        <Input disabled={!hasWritePermission} />
      </FormItem>
      <FormItem
        name={['fields', 'merchant_domain']}
        label={<Label optional>Merchant Domain</Label>}
        extra={
          <Text as="span" className={atoms({ fontSize: 'xs', paddingTop: 4 })}>
            The host name of the site for which to get a decision.
          </Text>
        }
      >
        <Input disabled={!hasWritePermission} />
      </FormItem>
      <FormItem
        name={['fields', 'merchant_name']}
        label={<Label optional>Merchant Name</Label>}
        extra={
          <Text as="span" className={atoms({ fontSize: 'xs', paddingTop: 4 })}>
            The brand name of the site for which to get a decision.
          </Text>
        }
      >
        <Input disabled={!hasWritePermission} />
      </FormItem>
    </ForterEdit>
  )
}
