import { GridItem, Grid, Stack, Divider } from '@gr4vy/poutine-react'
import { FormInstance, Select } from 'antd'
import { useContext, useState } from 'react'
import { AmountCondition as ConditionTypes, Operation } from 'flows/constants'
import { FlowContext } from 'flows/contexts/FlowContext'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { Flow } from 'shared/constants'
import { AmountCondition } from './AmountCondition'
import { CurrencyCondition } from './CurrencyCondition'

interface AmountOrCurrencyInputProps {
  form: FormInstance
}

const AmountOrCurrencyInput = ({ form }: AmountOrCurrencyInputProps) => {
  const [selectedCondition, setSelectedCondition] = useState(
    form.getFieldValue(['amountCondition', 'name'])
      ? form.getFieldValue(['amountCondition', 'name'])
      : ConditionTypes.ANY_AMOUNT_OR_CURRENCY
  )

  const { flow } = useContext(FlowContext)
  const onSelect = (selectValue: any) => {
    if (selectValue === selectedCondition) {
      return
    }

    form.setFieldsValue({
      amountCondition: {
        name: selectValue,
        operator:
          selectValue === ConditionTypes.AMOUNT
            ? Operation.IS_BETWEEN
            : Operation.IS_ONE_OF,
        value: undefined,
      },
    })
    setSelectedCondition(selectValue)
  }

  return (
    <Stack direction="column" gap={16}>
      <Grid marginBottom={24}>
        <GridItem gridColumn="span 3">
          <FormItem
            label={<Label>Condition</Label>}
            name={['amountCondition', 'name']}
          >
            <Select
              // @ts-ignore - https://github.com/ant-design/ant-design/issues/21839
              value={null}
              placeholder="Any amount or currency"
              onSelect={onSelect}
              style={{ width: '100%' }}
            >
              <Select.Option value={ConditionTypes.ANY_AMOUNT_OR_CURRENCY}>
                Any amount or currency
              </Select.Option>
              <Select.Option value={ConditionTypes.AMOUNT}>
                Amount
              </Select.Option>
              <Select.Option value={ConditionTypes.CURRENCY}>
                Currency
              </Select.Option>
              {flow === Flow.checkout && (
                <Select.Option value={ConditionTypes.AMOUNT_ZERO}>
                  Zero amount (any currency)
                </Select.Option>
              )}
            </Select>
          </FormItem>
        </GridItem>
        {selectedCondition === ConditionTypes.ANY_AMOUNT_OR_CURRENCY && (
          <GridItem gridColumn="span 9" data-testid="any-amount-or-currency" />
        )}
        {selectedCondition === ConditionTypes.AMOUNT && (
          <AmountCondition form={form} />
        )}
        {selectedCondition === ConditionTypes.CURRENCY && <CurrencyCondition />}
        {selectedCondition === ConditionTypes.AMOUNT_ZERO && (
          <GridItem gridColumn="span 9" />
        )}
      </Grid>
      <Divider />
    </Stack>
  )
}

export default AmountOrCurrencyInput
