import { Stack } from '@gr4vy/poutine-react'
import { useParams, useSearchParams } from 'react-router-dom'
import { ReportExecutionsTable } from 'reports/components/ReportExecutionsTable'
import { ReportsSearch } from 'reports/components/ReportsSearch'
import { useReportExecutions } from 'reports/hooks/use-report-execution'
import { useReportsRoutes } from 'reports/hooks/use-reports-routes'
import { ReportExecutionsFilters as ReportExecutionsFiltersType } from 'reports/services/report-execution'
import { PageLayout } from 'shared/components/PageLayout'
import { useCursor, useFilters } from 'shared/hooks'

export const GeneratedReportsPage = ({ title }: { title: string }) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const [searchParams] = useSearchParams()
  const navigationTabs = useReportsRoutes(merchantAccountId)
  const [filters, setFilters] = useFilters<ReportExecutionsFiltersType>()
  const [cursor] = useCursor()
  const reportExecutions = useReportExecutions(
    { cursor, ...filters },
    { refetchInterval: 60000 }
  )
  const updateFilter = (f: Partial<ReportExecutionsFiltersType>) => {
    setFilters({ ...filters, ...f })
  }

  return (
    <PageLayout title={title} subNavigation={navigationTabs}>
      <Stack gap={32}>
        <ReportsSearch
          defaultValue={searchParams.get('reportName') || undefined}
          onChange={(search) => updateFilter({ reportName: search })}
        />
        <ReportExecutionsTable
          page={reportExecutions}
          pagination={{
            filters,
            onFilter: updateFilter,
          }}
        />
      </Stack>
    </PageLayout>
  )
}
