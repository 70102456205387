import {
  Badge,
  ButtonCopy,
  Flex,
  Heading,
  Skeleton,
  Modal,
} from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSession } from 'session'
import { PageLayout } from 'shared/components/PageLayout'
import { UserTabs } from 'shared/constants'
import { pathTo } from 'shared/paths/users'
import UsersActions from 'users/components/UsersActions'
import { INVITATION, REMOVE_USER, userTabName } from 'users/constants'
import { ModalType, UserModalContext } from 'users/contexts/UserModalContext'
import { useLoggedInUser } from 'users/hooks/use-logged-in-user'
import { User } from 'users/services/users'
import InvitationModal from '../InvitationModal'
import RemoveUserModal from '../RemoveUserModal/RemoveUserModal'

const { useModal } = Modal
interface UserPageLayoutProps {
  children: ReactNode
  user?: User
  remove: UseMutationResult<void, any, string>
}

const UserNameLoader = (
  <Skeleton width={160}>
    <Skeleton.Box height={32} />
  </Skeleton>
)

export default function UserPageLayout({
  children,
  user,
  remove,
}: UserPageLayoutProps) {
  const { isModalOpen, openModal, closeModal } = useModal()
  const [selectedUser, setSelectedUser] = useState<User>()
  const navigate = useNavigate()
  const { isLoggedInUser } = useLoggedInUser()
  const { isStaff } = useSession()

  const breadcrumbs = [
    { title: 'Users', url: pathTo.users },
    {
      title: userTabName[UserTabs.details],
      url: pathTo.userTab(
        user && isLoggedInUser(user) ? 'me' : (user?.id ?? ''),
        UserTabs.details
      ),
    },
  ]

  const openUserModal = (user: User, modalName: ModalType) => {
    setSelectedUser(user)
    openModal(modalName)
  }

  const closeUserModal = (modalName: ModalType) => {
    setSelectedUser(undefined)
    closeModal(modalName)
  }

  const removeUser = (user: User) => {
    remove.mutate(user.id, {
      onSuccess: () => {
        closeUserModal(REMOVE_USER)
        navigate(pathTo.users)
      },
    })
  }

  return (
    <PageLayout
      titleChildren={
        <Flex gap={16} alignItems="center">
          {user ? (
            <Heading level={3} as="h1">
              {user?.name || 'User'}
              {isLoggedInUser(user) ? ' (You)' : null}
            </Heading>
          ) : (
            UserNameLoader
          )}
          {user && (
            <Badge
              variant="subtle"
              color={user.status === 'active' ? 'information' : 'notice'}
              size={8}
            >
              {user.status === 'active' ? 'Active' : 'Invited'}
            </Badge>
          )}
        </Flex>
      }
      breadcrumbs={breadcrumbs}
      actions={
        <>
          <ButtonCopy variant="secondary" copy={document.location.href}>
            Copy link
          </ButtonCopy>
          {user &&
            (!isLoggedInUser(user) || (!isStaff && isLoggedInUser(user))) && (
              <UserModalContext.Provider value={{ openUserModal }}>
                <UsersActions user={user} />
              </UserModalContext.Provider>
            )}
        </>
      }
      noContainer
      gap="none"
    >
      <>
        {children}
        <RemoveUserModal
          open={isModalOpen(REMOVE_USER)}
          onClose={() => closeUserModal(REMOVE_USER)}
          user={selectedUser}
          removeUser={removeUser}
          isRemovingUser={remove.isPending}
        />
        <InvitationModal
          open={isModalOpen(INVITATION)}
          onClose={() => closeUserModal(INVITATION)}
          user={selectedUser}
        />
      </>
    </PageLayout>
  )
}
