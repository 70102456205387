import { Box, Container } from '@gr4vy/poutine-react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import BuyerPageLayout from 'buyers/components/BuyerPageLayout'
import PaymentMethodsTable from 'buyers/components/PaymentMethodsTable'
import { getPaymentMethodsForBuyer } from 'buyers/services/paymentMethods'
import { BUYERS_PAYMENT_METHODS } from 'shared/constants/query-keys'
import { useCursor, useFilters } from 'shared/hooks'
import { TransactionFilters } from 'shared/services/transactions'

export default function BuyerPaymentMethodsPage() {
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }
  const [cursor] = useCursor()
  const [filters, setFilters] = useFilters()

  const page = useQuery({
    queryKey: [BUYERS_PAYMENT_METHODS, id, merchantAccountId, cursor, filters],
    queryFn: () =>
      getPaymentMethodsForBuyer({ cursor, buyerId: id, ...filters }),
    meta: {
      error: {
        redirect: true,
      },
    },
  })

  return (
    <BuyerPageLayout>
      <Box paddingY={48} paddingLeft={4}>
        <Container>
          <PaymentMethodsTable
            page={page}
            pagination={{
              filters,
              onFilter: (newFilters: TransactionFilters) =>
                setFilters({ ...filters, ...newFilters }),
            }}
          />
        </Container>
      </Box>
    </BuyerPageLayout>
  )
}
