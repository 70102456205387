import { Stack, Heading, Text, atoms } from '@gr4vy/poutine-react'
import React from 'react'
import { FormItem } from 'shared/components/FormItem'
import { InputName } from './InputName'

interface Props {
  schedule?: boolean
  visible: boolean
}

export const StepName = ({ schedule, visible }: Props) => {
  return (
    <Stack gap={24} display={visible ? 'flex' : 'none'}>
      <Stack gap={8}>
        <Heading level={5}>
          {schedule ? 'Schedule name' : 'Name your report'}
        </Heading>
        {schedule ? (
          <Text>
            The following steps will contain filters on all transactional data
            elements, allowing you to create a very specific report schedule.
            Begin by naming your schedule so that it is easily identifiable.
          </Text>
        ) : (
          <Text>
            The following steps will contain filters on all transactional data
            elements, allowing you to create a very specific report. Begin by
            naming your report so that it is easily identifiable.
          </Text>
        )}
      </Stack>
      <FormItem
        style={{ flexGrow: 3 }}
        className={atoms({ fontWeight: 'normal' })}
        name="name"
        rules={[
          {
            required: true,
            message: `Please add a name for the ${
              schedule ? 'schedule' : 'report'
            }`,
          },
        ]}
      >
        <InputName
          autoFocus
          placeholder={`Type ${schedule ? 'schedule' : 'report'} name here`}
        />
      </FormItem>
    </Stack>
  )
}
