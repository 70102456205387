import { showErrorMessage, showWarningMessage } from './showMessage'

const ERROR_TIMEOUT = 10

interface ErrorDetail {
  pointer: string
  message: string
}

export const handleErrorResponse = (error: any) => {
  const response = error?.response
  if (response?.status === 409) {
    showWarningMessage('Duplicate item found', ERROR_TIMEOUT)
  } else if (response?.status === 400 && response.data?.details?.length) {
    response.data.details.forEach((detail: ErrorDetail) => {
      showErrorMessage(`${detail.pointer}: ${detail.message}`, ERROR_TIMEOUT)
    })
  } else if (response?.status === 401 || response?.status === 403) {
    // show nothing: handled by redirect to access denied page
  } else {
    showErrorMessage('Unexpected error', ERROR_TIMEOUT)
  }
}
