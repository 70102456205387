import { ReactElement, ReactNode } from 'react'

interface WrapperProps {
  condition?: boolean
  render: (children: ReactNode) => ReactElement
  children: ReactElement
}

export const Wrapper = ({ condition, render, children }: WrapperProps) =>
  condition ? render(children) : children
