import { REFUND_PATH } from 'shared/paths/refunds'
import { RefundPage } from './pages'

export default [
  {
    title: 'Refund',
    longTitle: 'Refund',
    key: 'refund',
    path: REFUND_PATH,
    menu: false,
    component: RefundPage,
  },
]
