export enum BuyerTabs {
  overview = 'overview',
  transactions = 'transactions',
  paymentMethods = 'payment-methods',
  giftCards = 'gift-cards',
}

export const buyerTabName: Record<BuyerTabs, string> = {
  [BuyerTabs.overview]: 'Overview',
  [BuyerTabs.transactions]: 'Transactions',
  [BuyerTabs.paymentMethods]: 'Payment methods',
  [BuyerTabs.giftCards]: 'Gift cards',
}
