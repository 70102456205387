import { Navigate, useParams } from 'react-router-dom'
import { PaymentMethodTabs } from 'shared/constants'
import { PAYMENT_METHOD_PATH, pathTo } from 'shared/paths/payment-methods'
import {
  PaymentMethodOverviewPage,
  PaymentMethodTransactionsPage,
} from './pages'

export default [
  {
    title: 'Payment Method Overview',
    longTitle: 'Payment Method Overview',
    key: 'payment-method-overview',
    path: `${PAYMENT_METHOD_PATH}/${PaymentMethodTabs.overview}`,
    menu: false,
    exact: true,
    component: PaymentMethodOverviewPage,
  },
  {
    title: 'Payment Method Transactions',
    longTitle: 'Payment Method Transactions',
    key: 'payment-method-transactions',
    path: `${PAYMENT_METHOD_PATH}/${PaymentMethodTabs.transactions}`,
    menu: false,
    exact: true,
    component: PaymentMethodTransactionsPage,
  },
  {
    title: 'Payment Method Overview',
    longTitle: 'Payment Method',
    key: 'payment-method',
    path: PAYMENT_METHOD_PATH,
    menu: false,
    component: function Default() {
      const { paymentMethodId, merchantAccountId } = useParams() as {
        paymentMethodId: string
        merchantAccountId: string
      }

      return (
        <Navigate
          replace
          to={pathTo.paymentMethodId(
            merchantAccountId,
            paymentMethodId,
            PaymentMethodTabs.overview
          )}
        />
      )
    },
  },
]
