import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { GET_USER, USERS } from 'shared/constants'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import { getUser, updateUser } from 'users/services/users'
import View from './UserAccessPage'

const UserAccessPage = ({ title }: { title: string }) => {
  const { id } = useParams() as {
    id: string
  }
  const response = useQuery({
    queryKey: [GET_USER, id],
    queryFn: () => getUser(id),
    enabled: !!id,
  })
  const queryClient = useQueryClient()

  const onUpdate = useMutation({
    mutationFn: updateUser,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [USERS] }),
    onError: handleErrorResponse,
  })

  return (
    <View
      title={title}
      user={response?.data}
      loading={response.isLoading}
      update={onUpdate}
    />
  )
}

export default UserAccessPage
