import { ContextType, useCallback, useContext, useEffect } from 'react'
import {
  Navigator as BaseNavigator,
  UNSAFE_NavigationContext as NavigationContext,
} from 'react-router-dom'
import type { History, Location } from 'history'

interface Navigator extends BaseNavigator {
  block: History['block']
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
  navigator: Navigator
}

/**
 * @source https://gist.github.com/MarksCode/64e438c82b0b2a1161e01c88ca0d0355
 */
function useBlocker(blocker: (location: Location) => boolean, when = true) {
  const { navigator } = useContext(
    NavigationContext
  ) as NavigationContextWithBlock

  useEffect(() => {
    if (!when) {
      return
    }

    const push = navigator.push

    navigator.push = (to, state, opts) => {
      const result = blocker(to as Location)
      if (result) {
        push(to, state, opts)
      }
    }

    // eslint-disable-next-line consistent-return
    return () => {
      navigator.push = push
    }
  }, [navigator, blocker, when])
}

/**
 * @source https://github.com/remix-run/react-router/issues/8139#issuecomment-1021457943
 */
function usePrompt(
  message: string | ((location: Location) => string | boolean),
  when = true
) {
  const blocker = useCallback(
    (location) => {
      let response
      if (typeof message === 'function') {
        response = message(location)
        if (typeof response === 'string') {
          response = window.confirm(response)
        }
      } else {
        response = window.confirm(message)
      }
      return response
    },
    [message]
  )
  return useBlocker(blocker, when)
}

export const Prompt = ({
  message,
  when,
}: {
  message: string | ((location: Location) => string | boolean)
  when?: boolean
}) => {
  usePrompt(message, when)

  return null
}
