export function groupBy<TItem>(
  items: TItem[],
  property: string
): Record<string, TItem[]> {
  //https://stackoverflow.com/a/34890276
  // Grouping array into actions
  return items.reduce(function (previousValue: any, currentValue: any) {
    ;(previousValue[currentValue[property]] =
      previousValue[currentValue[property]] || []).push(currentValue)
    return previousValue
  }, {})
}
