import { Text, TextLink, Divider, atoms } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { CredentialsChecker } from 'connections/components/CredentialsChecker'
import { validateAntiFraudService } from 'connections/services'
import { CredentialField } from 'shared/components/CredentialField/CredentialField'
import { Label } from 'shared/components/Form'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { Cybersource } from './Cybersource'

export const Credentials = () => {
  const { id } = useParams() as { id: string }
  const hasWritePermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )

  return (
    <Cybersource submitButtonText="Save changes">
      <Text margin="none">
        Learn more on how to{' '}
        <TextLink
          href="https://docs.gr4vy.com/guides/features/anti-fraud/cybersource"
          opensInNewWindow
        >
          configure Cybersource
        </TextLink>
        .
      </Text>
      <CredentialField
        item={{ key: 'merchant_id', displayName: <Label>Merchant ID</Label> }}
        required
        secret={false}
        group="fields"
        disabled={!hasWritePermission}
        extra={
          <Text as="span" className={atoms({ fontSize: 'xs', paddingTop: 4 })}>
            This is the{' '}
            <TextLink
              href="https://support.cybersource.com/knowledgebase/Knowledgearticle/?code=000002872"
              opensInNewWindow
            >
              unique identifier
            </TextLink>{' '}
            of your Cybersource account.
          </Text>
        }
      />
      <CredentialField
        item={{
          key: 'transaction_key',
          displayName: <Label>SOAP Toolkit Key</Label>,
        }}
        required
        secret
        disabled={!hasWritePermission}
        group="fields"
        extra={
          <Text as="span" className={atoms({ fontSize: 'xs', paddingTop: 4 })}>
            This is the{' '}
            <TextLink
              href="https://support.cybersource.com/knowledgebase/Knowledgearticle/?code=000002769"
              opensInNewWindow
            >
              API key
            </TextLink>{' '}
            for accessing the SOAP API.
          </Text>
        }
      />
      <CredentialField
        item={{
          key: 'org_id',
          displayName: <Label>ThreatMetrix Org ID</Label>,
        }}
        group="fields"
        secret={false}
        required={false}
        disabled={!hasWritePermission}
        extra={
          <Text as="span" className={atoms({ fontSize: 'xs', paddingTop: 4 })}>
            The organization ID for use with ThreatMetrix device fingerprints.
            Please contact your Cybersource support representative for this
            value.
          </Text>
        }
      />
      {hasWritePermission && (
        <CredentialsChecker
          fieldNames={[
            ['fields', 'merchant_id'],
            ['fields', 'transaction_key'],
          ]}
          verifyCredentials={validateAntiFraudService}
          antiFraudServiceDefinitionId="cybersource-anti-fraud"
          antiFraudServiceId={id}
        />
      )}
      <Divider />
    </Cybersource>
  )
}

export default Credentials
