import { Box, Heading, Stack } from '@gr4vy/poutine-react'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { pathTo, getBuyer } from 'buyers'
import { GiftCard } from 'gift-cards/services/giftCards'
import { PaymentMethod } from 'payment-methods/services/paymentMethods'
import { Link } from 'shared/components/Link'
import { Panel } from 'shared/components/Panel'
import { GET_BUYER } from 'shared/constants/query-keys/transactions'
import { AccessLevel, Resource, RestrictAccess } from 'shared/permissions'
import { ContactDetailFields } from 'transactions/components/ContactDetailFields/ContactDetailFields'
import { PayoutSummary, Transaction } from 'transactions/services'
import { BuyerPanelDecriptionFields } from './BuyerPanelDecriptionFields'
import { BuyerPanelTaxInformationFields } from './BuyerPanelTaxInformationFields'
import { hasBillingDetails } from './has-billing-details'

function BuyerPanel({
  data,
}: {
  data: Transaction | PayoutSummary | PaymentMethod | GiftCard
}) {
  const { buyer } = data
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const buyerDetails = useQuery({
    queryKey: [GET_BUYER, buyer?.id, merchantAccountId],
    queryFn: () => getBuyer(buyer?.id as string),
    enabled: !!buyer?.id,
  })
  const shippingDetails = (data as Transaction).shippingDetails
  const isPayoutBuyerDetails =
    data.type === 'payout' &&
    (hasBillingDetails(buyer?.billingDetails) || shippingDetails)

  const { hash } = useLocation()
  const panel = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (hash === '#buyer' && panel.current) {
      panel.current.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }, [hash])

  return (
    <Box ref={panel}>
      <Panel>
        <Panel.Header>
          <Heading level={4}>Buyer</Heading>
          <RestrictAccess
            resource={Resource.buyers}
            accessLevel={AccessLevel.read}
          >
            {buyer && buyerDetails.data && (
              <Link href={pathTo.buyer(merchantAccountId, buyer.id)}>
                Go to buyer
              </Link>
            )}
          </RestrictAccess>
        </Panel.Header>
        <Panel.Content>
          <Stack gap={48}>
            <BuyerPanelDecriptionFields buyer={buyer} />
            {isPayoutBuyerDetails ? (
              <>
                <ContactDetailFields
                  contactDetails={buyer?.billingDetails}
                  title="Billing/Shipping details"
                />
                {buyer?.billingDetails?.taxId && (
                  <BuyerPanelTaxInformationFields
                    taxInfo={buyer?.billingDetails?.taxId}
                  />
                )}
              </>
            ) : (
              <>
                {hasBillingDetails(buyer?.billingDetails) && (
                  <ContactDetailFields
                    contactDetails={buyer?.billingDetails}
                    title="Billing details"
                  />
                )}
                {buyer?.billingDetails?.taxId && (
                  <BuyerPanelTaxInformationFields
                    taxInfo={buyer?.billingDetails?.taxId}
                  />
                )}
                {shippingDetails && (
                  <ContactDetailFields
                    contactDetails={shippingDetails}
                    title="Shipping details"
                  />
                )}
              </>
            )}
          </Stack>
        </Panel.Content>
      </Panel>
    </Box>
  )
}

export default BuyerPanel
