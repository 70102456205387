import { Form, Input, FormItemProps as AntdFormItemProps } from 'antd'
import {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  useEffect,
} from 'react'
import { Uploader } from 'shared/components/Uploader'
import { useEditFormItem } from 'shared/hooks'

export type EditableFormItemProps = PropsWithChildren<
  AntdFormItemProps & {
    secret?: boolean
  }
>

export const EditableFormItem = ({
  children,
  secret,
  required,
  rules,
  ...rest
}: EditableFormItemProps) => {
  const form = Form.useFormInstance()
  const { placeholder, isEditing, Toggle } = useEditFormItem({ secret })

  const input = Children.map(children, (child) => {
    const props = {
      disabled: !isEditing,
      placeholder,
    }

    if (!isValidElement(child)) {
      return child
    }

    switch (child.type) {
      case Input:
        return cloneElement(child, {
          ...child.props,
          ...props,
          addonAfter: <Toggle />,
        })
      case Uploader:
        return cloneElement(child, {
          ...child.props,
          ...props,
          editToggle: <Toggle label />,
        })
    }

    return child
  })

  // Disable required validation when editing
  required = isEditing
  rules = required ? rules : []

  useEffect(() => {
    // clear any validation error on close
    if (!isEditing) {
      const { name } = rest
      const error = form.getFieldError(rest.name)

      if (!error?.length) {
        return
      }

      form.setFields([{ name, errors: [] }])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])

  return (
    <Form.Item required={required} rules={rules} {...rest}>
      {input}
    </Form.Item>
  )
}
