import { Modal, Button, Flex, Stack, Text } from '@gr4vy/poutine-react'
import React from 'react'
import { is } from 'shared/helpers/is'

interface ModalRemoveProps {
  children: React.ReactNode
  title: string
  okText?: string
  loading?: boolean
  loadingText?: string
  onOk: () => void
  onCancel: () => void
}

export const ModalRemove = ({
  children,
  title,
  okText,
  loading,
  loadingText,
  onOk,
  onCancel,
}: ModalRemoveProps) => {
  return (
    <Modal
      open
      type="negative"
      title={title}
      onOpenChange={(status) => {
        !status && onCancel()
      }}
    >
      <Stack gap={24}>
        {is.string(children) ? <Text>{children}</Text> : children}
        <Flex justifyContent="flex-end">
          <Button variant="secondary" marginRight={16} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="primaryNegative"
            loading={loading}
            loadingText={loadingText}
            onClick={onOk}
          >
            {okText || 'Yes, delete'}
          </Button>
        </Flex>
      </Stack>
    </Modal>
  )
}
