import { Flex, Container } from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { TopNavLink } from 'App/components/TopNavLink'
import { useMenuItems } from 'App/hooks/use-menu-items'
import styles from './TopNav.module.scss'

export const TopNav = () => {
  const [displayShadow, setDisplayShadow] = useState(false)
  const menuItems = useMenuItems()

  useEffect(() => {
    function onScroll() {
      setDisplayShadow(window.scrollY >= 144)
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <Flex
      background="white"
      alignItems="center"
      height={56}
      className={clsx([styles.topNav, displayShadow && styles.shadow])}
    >
      <Container>
        <Flex as="nav" aria-label="main" gap={8}>
          {menuItems.map(({ key, ...rest }) => (
            <TopNavLink key={key} {...rest} />
          ))}
        </Flex>
      </Container>
    </Flex>
  )
}
