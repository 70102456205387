import {
  Box,
  ButtonLink,
  Description,
  Dropdown,
  Flex,
  Icon,
  Stack,
  Tag,
  Tooltip,
} from '@gr4vy/poutine-react'
import { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ColumnDef,
  DataTable,
  DateTime,
  DataTableProps,
} from 'shared/components/DataTable'
import { pathTo } from 'shared/paths/users'
import { useMultiMerchant } from 'shared/permissions/hooks/use-multi-merchant'
import { QueryResult } from 'shared/services/client'
import { MerchantsFilter } from 'users/components/MerchantsFilter'
import { RolesFilter } from 'users/components/RolesFilter'
import { StatusFilter } from 'users/components/StatusFilter'
import { REMOVE_USER } from 'users/constants'
import { UserModalContext } from 'users/contexts/UserModalContext'
import { isSelectedUserAnAdmin } from 'users/helpers'
import { useLoggedInUser } from 'users/hooks/use-logged-in-user'
import { useResetPassword } from 'users/hooks/use-reset-password'
import { useUseManager } from 'users/hooks/use-use-manager'
import { User } from 'users/services/users'
import { MerchantAccountsList } from '../MerchantAccountsList'
import { RolesList } from '../RolesList'
import styles from './UsersTable.module.scss'

export type UsersTableProps = Pick<
  DataTableProps<User>,
  'data' | 'pagination' | 'loading' | 'columns'
> & { page: QueryResult<User> }

const UsersTable = ({ page, pagination }: UsersTableProps) => {
  const { data, isLoading: loading, isError: error } = page
  const { hasMultipleMerchantAccounts } = useMultiMerchant()

  const columns: Array<ColumnDef<User>> = [
    {
      header: 'Name',
      size: 280,
      cell: function NameRow({ row }) {
        const { isLoggedInUser } = useLoggedInUser()
        const user = row.original
        const name = `${user.name}${isLoggedInUser(user) ? ' (You)' : ''}`

        return (
          <Description>
            <Description.Link href={pathTo.user(user.id)}>
              {name}
            </Description.Link>
            <Description.SubText>{user.emailAddress}</Description.SubText>
          </Description>
        )
      },
    },
    {
      header: () => (
        <Box className={styles.filter}>
          <RolesFilter />
        </Box>
      ),
      id: 'roles',
      size: 240,
      cell: ({ row }) => {
        const roles = row.original.roles

        return <RolesList roles={roles} />
      },
    },
    {
      header: () => (
        <Box className={styles.filter}>
          <StatusFilter />
        </Box>
      ),
      size: 150,
      id: 'hasValidPassword',
      cell: ({ row }) => {
        const color =
          row.original.status === 'active' ? 'information' : 'notice'

        const text = row.original.status === 'active' ? 'Active' : 'Invited'

        return (
          <Stack gap={8} direction="row">
            <Tag variant="subtle" color={color}>
              {text}
            </Tag>
            {row.original.category === 'sso' && (
              <Tag variant="subtle" color="data1">
                SSO
              </Tag>
            )}
          </Stack>
        )
      },
    },
    {
      header: 'Last log in',
      size: 128,
      accessorKey: 'lastLoggedInAt',
      cell: function DatetimeCell({ getValue }) {
        const lastLoggedInAt = getValue<User['lastLoggedInAt']>()
        return lastLoggedInAt ? <DateTime value={lastLoggedInAt} /> : '-'
      },
    },
    {
      id: 'actions',
      size: 70,
      header: () => <Box textAlign="center">Actions</Box>,
      cell: function ActionsRow({ row }) {
        const user = row.original as User
        const { openUserModal } = useContext(UserModalContext)
        const { isLoggedInUser } = useLoggedInUser()
        const { regenerateInviteLink } = useResetPassword()
        const { isUserManager } = useUseManager()
        const navigate = useNavigate()

        const userMerchantAccountIds = useMemo(
          () =>
            user.merchantAccounts.map((merchantAccount) => merchantAccount.id),
          [user.merchantAccounts]
        )

        return (
          <Box textAlign="center">
            <Dropdown>
              <Dropdown.Trigger asChild>
                <Dropdown.Button
                  variant="tertiary"
                  size="small"
                  data-testid="action-menu"
                >
                  <Icon name="more-horizontal" />
                </Dropdown.Button>
              </Dropdown.Trigger>
              <Dropdown.Content align="end">
                <Dropdown.Item
                  opensInModal
                  onSelect={() => navigate(pathTo.editUser.name(user.id))}
                  disabled={user.category === 'sso'}
                >
                  Edit name
                </Dropdown.Item>
                <Dropdown.Item
                  opensInModal
                  onSelect={() =>
                    navigate(
                      isUserManager
                        ? pathTo.editUser.roles(user.id, userMerchantAccountIds)
                        : pathTo.editUser.accessType(user.id)
                    )
                  }
                  disabled={
                    isLoggedInUser(user) ||
                    (isSelectedUserAnAdmin(user) && isUserManager) ||
                    user.category === 'sso'
                  }
                >
                  Edit roles
                </Dropdown.Item>
                {hasMultipleMerchantAccounts &&
                  !isSelectedUserAnAdmin(user) && (
                    <Dropdown.Item
                      opensInModal
                      onSelect={() =>
                        navigate(pathTo.editUser.merchantAccount(user.id))
                      }
                      disabled={
                        isLoggedInUser(user) ||
                        (isSelectedUserAnAdmin(user) && isUserManager) ||
                        user.category === 'sso'
                      }
                    >
                      Edit merchants
                    </Dropdown.Item>
                  )}
                <Dropdown.Item
                  opensInModal
                  onSelect={() => openUserModal(user, REMOVE_USER)}
                  disabled={isLoggedInUser(user)}
                  data-testid="action-menu-item-remove"
                >
                  Remove user
                </Dropdown.Item>
                <Dropdown.Item
                  opensInModal
                  onSelect={() => regenerateInviteLink(user)}
                  data-testid="action-menu-item"
                  disabled={user.category === 'sso'}
                >
                  {isLoggedInUser(user)
                    ? 'Change password'
                    : user.hasValidPassword
                      ? 'Reset password'
                      : 'Generate new invite'}
                </Dropdown.Item>
              </Dropdown.Content>
            </Dropdown>
          </Box>
        )
      },
    },
    {
      id: 'user-details',
      header: '',
      size: 64,
      cell: function ActionsCell({ row }) {
        const user = row.original
        return user ? (
          <Flex justifyContent="flex-end">
            <Tooltip content="Go to user">
              <ButtonLink
                variant="tertiary"
                size="small"
                href={pathTo.user(user.id)}
              />
            </Tooltip>
          </Flex>
        ) : null
      },
    },
  ]

  hasMultipleMerchantAccounts &&
    columns.splice(2, 0, {
      id: 'merchants',
      header: () => (
        <Box className={styles.filter}>
          <MerchantsFilter />
        </Box>
      ),
      size: 240,
      cell: ({ row }) => {
        const merchantAccounts = row.original.merchantAccounts
        return <MerchantAccountsList accounts={merchantAccounts} />
      },
    })

  return (
    <DataTable
      data={data}
      loading={loading}
      error={error}
      columns={columns}
      pagination={pagination}
    />
  )
}

export default UsersTable
