import { Grid, GridProps } from '@gr4vy/poutine-react'
import { ReactNode } from 'react'
import { Summary } from 'shared/components/Summary'
import Item from './Item'

interface SummaryGridProps extends GridProps {
  children: ReactNode
}

export const SummaryGrid = ({ children, ...rest }: SummaryGridProps) => {
  return (
    <Grid as="dl" gap={8} {...rest}>
      {children}
    </Grid>
  )
}

SummaryGrid.Key = Summary.Key
SummaryGrid.Value = Summary.Value
SummaryGrid.Item = Item
