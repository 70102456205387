import { Alert, Box, ButtonLink, Stack } from '@gr4vy/poutine-react'

const baseErrorMessage = 'Something went wrong. Please try again.'

type SSOErrorProps = {
  error: string
}

export const SSOError = ({ error }: SSOErrorProps) => {
  return (
    <Box
      background="white"
      borderRadius="rounded"
      padding={32}
      alignItems="center"
      justifyContent="center"
    >
      <Stack gap={16} alignItems="center" style={{ width: 400 }}>
        <Alert variant="negative" key={0} marginY={8}>
          <Alert.Icon />
          <Alert.Content>
            <Alert.Text>
              {baseErrorMessage}(error: {error})
            </Alert.Text>
          </Alert.Content>
        </Alert>
        <ButtonLink href="/sso">Sign in with SSO</ButtonLink>
      </Stack>
    </Box>
  )
}
