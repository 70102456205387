import { capitalize } from 'lodash'
import { GiftCardRedemption } from 'transactions/services/transactions'

export const getGiftCardRedemptionStatus = (
  redemption: Pick<
    GiftCardRedemption,
    'status' | 'errorCode' | 'amount' | 'refundedAmount'
  >
): [string, string | null] => {
  const statuses = {
    created: 'Pending',
    succeeded: 'Captured',
    failed: 'Failed',
    skipped: 'Skipped',
  }

  const label = statuses[redemption.status]
  let subLabel = null

  if (redemption.errorCode !== null) {
    subLabel = capitalize(redemption.errorCode.replaceAll('_', ' '))
    return [label, subLabel]
  }

  if (redemption.refundedAmount > 0) {
    subLabel =
      redemption.amount == redemption.refundedAmount
        ? 'Full refund'
        : 'Partial refund'
  }

  return [label, subLabel]
}
