import { Flex, Button, Icon } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import { useCursor, Filters } from 'shared/hooks'

const { Option } = Select

export interface PaginationProps {
  limits?: Array<number>
  filters: Filters | any
  onFilter: (filter: Filters | any) => void
  nextCursor?: string
  previousCursor?: string
  items?: Array<any>
}

const Pagination = ({
  nextCursor,
  previousCursor,
  filters,
  limits = [20, 50, 100],
  onFilter,
  items = [],
}: PaginationProps) => {
  const [, setCursor] = useCursor()
  const showSelect =
    nextCursor ||
    previousCursor ||
    (items?.length > limits[0] && items?.length < filters.limit) ||
    items?.length === filters.limit

  return (
    <Flex gap={8}>
      {showSelect && (
        <Select
          data-testid="Pagination-limit"
          value={filters.limit}
          onChange={(v) => onFilter({ limit: v })}
        >
          {limits.map((value) => (
            <Option
              data-testid={`Pagination-limit-${value}`}
              key={value}
              value={value}
            >
              {value} / page
            </Option>
          ))}
        </Select>
      )}

      {previousCursor && (
        <Button variant="secondary" onClick={() => setCursor(previousCursor)}>
          <Icon name="chevron-left-lg" />
          Previous
        </Button>
      )}

      {nextCursor && (
        <Button variant="secondary" onClick={() => setCursor(nextCursor)}>
          Next
          <Icon name="chevron-right-lg" />
        </Button>
      )}
    </Flex>
  )
}

export default Pagination
