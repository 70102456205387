import * as RadixTabs from '@radix-ui/react-tabs'
import { PropsWithChildren } from 'react'
import styles from './List.module.scss'

export type ListProps = PropsWithChildren<RadixTabs.TabsListProps>

export const List = ({ children, ...rest }: ListProps) => {
  return (
    <RadixTabs.List className={styles.list} {...rest}>
      {children}
    </RadixTabs.List>
  )
}
