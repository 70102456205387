import {
  Alert,
  Button,
  Drawer,
  Flex,
  Stack,
  TileGroup,
} from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { Form, Input, Select } from 'antd'
import { useContext, useState } from 'react'
import MerchantContext from 'App/contexts/MerchantContext'
import { NewApiKeyPair } from 'integrations/services/apiKeyPairs'
import { useMultiMerchant } from 'shared/permissions/hooks/use-multi-merchant'
import { Role } from 'users/services/users'

interface ApiKeyPairModalProps {
  roles: Role[]
  onAdd: UseMutationResult<void, any, NewApiKeyPair, unknown>
  onClose: () => void
  open: boolean
}

type FormData = {
  displayName: string
  algorithm: string
  merchantAccountId: string | null
  roleId: string
}

const RolesTitles: Record<string, string> = {
  administrator: 'Full access',
  integration: 'Processing only',
}

const RolesDescriptions: Record<string, string> = {
  administrator:
    'Allows the API key to process payments, manage connections, edit flow rules, and more.',
  integration:
    'Allows the API key to be used to process payments, including the ability to manage payment methods and buyers.',
}

const ApiKeyPairModal = ({
  roles,
  onAdd,
  onClose,
  open,
}: ApiKeyPairModalProps) => {
  const { hasMultipleMerchantAccounts } = useMultiMerchant()
  const { merchantAccounts } = useContext(MerchantContext)

  const [algorithm, setAlgorithm] = useState('ECDSA')
  const [merchantAccountId, setMerchantAccountId] = useState(null)

  const [form] = Form.useForm<FormData>()
  const initialValues: FormData = {
    displayName: '',
    algorithm,
    merchantAccountId,
    roleId: roles?.[0]?.id || '',
  }

  const onSubmit = (resource: FormData) => {
    onAdd.mutate(resource, {
      onSuccess: () => {
        form.resetFields()
        onClose()
      },
      onError: () => form.resetFields(),
    })
  }

  return (
    <Drawer title="Add an API key" open={open} onClose={onClose}>
      <Form
        form={form}
        onFinish={onSubmit}
        initialValues={initialValues}
        preserve={false}
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item
          label="Name"
          name="displayName"
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input
            disabled={onAdd.isPending}
            placeholder="e.g. Web API key"
            autoComplete="off"
            maxLength={200}
            autoFocus
          />
        </Form.Item>
        {hasMultipleMerchantAccounts && (
          <Form.Item label="Merchants" name="merchantAccountId">
            <Select
              onChange={(value) => setMerchantAccountId(value)}
              disabled={onAdd.isPending}
              getPopupContainer={(triggerNode: HTMLElement) => {
                return triggerNode.parentElement as HTMLElement
              }}
            >
              <Select.Option value={null}>Access all merchants</Select.Option>
              {merchantAccounts.map((merchantAccount) => (
                <Select.Option
                  key={merchantAccount.id}
                  value={merchantAccount.id}
                >
                  {merchantAccount.displayName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="Permissions" name="roleId" trigger="onValueChange">
          <TileGroup gap={8}>
            {roles.map((role) => (
              <TileGroup.Tile
                key={role.id}
                title={RolesTitles[role.name.toLowerCase()]}
                text={RolesDescriptions[role.name.toLowerCase()]}
                value={role.id}
              />
            ))}
          </TileGroup>
        </Form.Item>
        <Form.Item
          label="Algorithm"
          name="algorithm"
          rules={[{ required: true, message: 'Algorithm is required' }]}
        >
          <Select
            onChange={(value) => setAlgorithm(value)}
            disabled={onAdd.isPending}
            getPopupContainer={(triggerNode: HTMLElement) => {
              return triggerNode.parentElement as HTMLElement
            }}
          >
            <Select.Option value="ECDSA">ECDSA (Recommended)</Select.Option>
            <Select.Option value="RSA">RSA</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          {algorithm === 'RSA' && (
            <Alert variant="information">
              <Alert.Icon />
              <Alert.Content>
                <Alert.Title>RSA vs ECDSA</Alert.Title>
                <Alert.Text>
                  Please only use the RSA algorithm in environments that do not
                  support ECDSA. RSA keys provide considerably less security for
                  the same key size and are currently not supported by our SDKs.
                </Alert.Text>
              </Alert.Content>
            </Alert>
          )}
        </Form.Item>
        <Stack gap={32}>
          <Flex justifyContent="flex-end" gap={16}>
            <Button type="button" variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              loading={onAdd.isPending}
              loadingText="Adding..."
            >
              Add
            </Button>
          </Flex>
        </Stack>
      </Form>
    </Drawer>
  )
}

export default ApiKeyPairModal
