import {
  atoms,
  ButtonLink,
  Heading,
  Icon,
  IconName,
  Stack,
  Text,
} from '@gr4vy/poutine-react'

export const NoResultsFound = ({
  heroIconName = 'plug-connected',
  heading,
  body,
  buttonUrl,
  buttonIconName = 'arrow-right-md',
  buttonText,
  showButton = true,
}: {
  heroIconName?: keyof typeof IconName
  heading: string
  body: string
  buttonUrl: string
  buttonIconName?: keyof typeof IconName
  buttonText: string
  showButton?: boolean
}) => (
  <Stack alignItems="center" gap={24} paddingTop={80}>
    <Icon className={atoms({ width: 120, height: 120 })} name={heroIconName} />
    <Stack gap={16} alignItems="center">
      <Heading>{heading}</Heading>
      <Text as="div">{body}</Text>
    </Stack>
    {showButton && (
      <ButtonLink href={buttonUrl}>
        {buttonText}
        <Icon name={buttonIconName} />
      </ButtonLink>
    )}
  </Stack>
)

export default NoResultsFound
