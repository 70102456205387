import { useQuery } from '@tanstack/react-query'
import { getRule } from 'flows/services'
import { Action, Flow } from 'shared/constants'

export const useRule = (flow: Flow, action: Action, id: string) => {
  return useQuery({
    queryKey: [`rule-${id}`],
    queryFn: () => getRule(flow, action, id),
    meta: {
      error: {
        redirect: true,
      },
    },
    gcTime: 0,
    refetchOnWindowFocus: false,
  })
}
