import { Heading, Stack } from '@gr4vy/poutine-react'
import EventsConnection from 'transactions/components/EventsPanel/EventsConnection'
import { TransactionEvent } from 'transactions/services'
import { RequestEvent } from './RequestEvent'

export const NetworkTokenSucceededEvent = ({
  event,
}: {
  event: TransactionEvent
}) => {
  return event ? (
    <Stack gap={32}>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Connection
        </Heading>
        <EventsConnection event={{ ...event, provider: 'giesecke-devrient' }} />
      </Stack>
      <RequestEvent event={event} />
    </Stack>
  ) : null
}
