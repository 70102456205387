import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { USERS } from 'shared/constants'
import { useCursor, useFilters, Filters } from 'shared/hooks'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import { handleSuccessResponse } from 'shared/utils/handleSuccessResponse'
import { listUsers, removeUser, updateUser } from 'users/services/users'
import View from './UsersPage'

const UsersPage = () => {
  const queryClient = useQueryClient()
  const [cursor] = useCursor()
  const [filters, setFilters] = useFilters()

  const page = useQuery({
    queryKey: [USERS, cursor, filters],
    queryFn: () => listUsers({ cursor, ...filters }),
    meta: {
      error: {
        redirect: true,
      },
    },
  })

  const invalidateUsers = () =>
    queryClient.invalidateQueries({ queryKey: [USERS] })

  const onRemove = useMutation({
    mutationFn: removeUser,
    onSuccess: handleSuccessResponse('User removed', invalidateUsers),
    onError: handleErrorResponse,
  })

  const onUpdate = useMutation({
    mutationFn: updateUser,
    onSuccess: handleSuccessResponse('User updated', invalidateUsers),
    onError: handleErrorResponse,
  })

  const updateFilter = (f: Filters) => {
    setFilters({ ...filters, ...f })
  }

  return (
    <View
      page={page}
      onFilter={updateFilter}
      filters={filters}
      remove={onRemove}
      update={onUpdate}
    />
  )
}

export default UsersPage
