import { Alert, Checkbox, Heading, Stack, Text } from '@gr4vy/poutine-react'
import { Form, Input } from 'antd'
import { useState } from 'react'
import { Copy } from 'shared/components/Copy'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { SimulatorEdit } from './SimulatorEdit'

export const SimulatorOverviewForm = () => {
  const hasWritePermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )
  const form = Form.useFormInstance()
  const [toggled, setToggled] = useState(false)
  const webhookUrl = form.getFieldValue('webhookUrl')

  return (
    <>
      <Text margin="none">
        Set a custom display name for this connection, in order to find it
        easily within the dashboard. If it&#8217;s deactivated, any flows rules
        using it will no longer be processed.
      </Text>
      <FormItem
        name={['displayName']}
        label={<Label>Display name</Label>}
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Input />
      </FormItem>
      <FormItem
        name={['active']}
        label={<Label>Connection status</Label>}
        valuePropName="checked"
        trigger="onCheckedChange"
        shouldUpdate
      >
        <Checkbox
          disabled={!hasWritePermission}
          onCheckedChange={(e: boolean) => setToggled(e)}
        >
          Use this connection
        </Checkbox>
      </FormItem>
      <Stack gap={8}>
        <Heading level={4}>Manual reviews</Heading>
        <Text marginBottom="none">
          When a transaction is held in a manual review queue we will mark it as
          pending review and delay capture until we receive a webhook, or until
          it is manually captured or voided.
        </Text>
      </Stack>
      <FormItem
        name="reviewsEnabled"
        label={<Label>Review queue status</Label>}
        valuePropName="checked"
        trigger="onCheckedChange"
      >
        <Checkbox disabled={!hasWritePermission}>Enable review queue</Checkbox>
      </FormItem>
      <FormItem label={<Label>Review queue webhook URL</Label>}>
        <Copy text={webhookUrl} />
      </FormItem>
      {toggled && (
        <Alert variant="information">
          <Alert.Icon />
          <Alert.Content>
            <Alert.Title>
              Only 1 anti-fraud connection can be active at a time
            </Alert.Title>
            <Alert.Text>
              Activating this connection will de-activate all other anti-fraud
              connections. Please make sure to update all Flow rules to use
              conditions for this connection before activating.
            </Alert.Text>
          </Alert.Content>
        </Alert>
      )}
    </>
  )
}

export const SimulatorOverview = () => (
  <SimulatorEdit submitButtonText="Save changes">
    <SimulatorOverviewForm />
  </SimulatorEdit>
)

export default SimulatorOverview
