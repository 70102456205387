import { Box, Flex, Container, TextLink } from '@gr4vy/poutine-react'
import { SettingsMenu, SupportMenu, UserMenu } from 'App/components/Menu'
import { MerchantAccountDropdown } from 'App/components/MerchantAccountDropdown'
import { useMerchantAccountDropdown } from 'App/hooks/use-merchant-account-dropdown'
import {
  merchantLogo,
  backgroundColor,
  merchantLogoFooterPosition,
} from 'shared/constants/merchant'
import { useUserHomepage } from 'shared/permissions'
import { useMerchantAccounts } from 'shared/permissions/hooks/use-merchant-accounts'
import { useMultiMerchant } from 'shared/permissions/hooks/use-multi-merchant'
import styles from './TopBar.module.scss'
import gr4vyWordmark from './gr4vy-wordmark.svg'
import poweredBy from './powered-by.svg'

const logo = merchantLogo
  ? `/img/merchant-logos/${merchantLogo}`
  : gr4vyWordmark

export const TopBar = () => {
  const {
    merchantAccountId,
    canAccessAdminMode,
    isAdminMode,
    canAccessMerchants,
  } = useMultiMerchant()
  const { hasMultipleMerchantAccounts } = useMerchantAccounts()
  const { merchantAccounts, isReadOnly } = useMerchantAccountDropdown({
    canAccessAdminMode,
  })
  const userHomepage = useUserHomepage(merchantAccountId)

  return (
    <Flex justifyContent="center" style={{ backgroundColor }}>
      <Container>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          color="white"
          height={64}
          as="nav"
          aria-label="top"
          className={styles.noWrap}
        >
          <Flex
            alignItems="center"
            gap={merchantLogo ? 24 : 16}
            className={styles.logoContainer}
          >
            <Flex alignItems="center" gap={16}>
              <TextLink href={userHomepage}>
                <Box height={merchantLogo ? 32 : 20} margin={8} asChild>
                  <img src={logo} alt="Logo" />
                </Box>
              </TextLink>
              {merchantLogo && !merchantLogoFooterPosition && (
                <Box>
                  <img src={poweredBy} alt="Powered by Gr4vy" width={54} />
                </Box>
              )}
            </Flex>
            {hasMultipleMerchantAccounts && (
              <Flex alignItems="center" gap={8}>
                <MerchantAccountDropdown
                  active={!isAdminMode}
                  readOnly={isReadOnly}
                  value={merchantAccountId}
                  merchantManagementEnabled={canAccessMerchants}
                  options={merchantAccounts}
                />
              </Flex>
            )}
          </Flex>
          <Flex gap={16} alignItems="center">
            <SupportMenu />
            {canAccessAdminMode && <SettingsMenu />}
            <UserMenu />
          </Flex>
        </Flex>
      </Container>
    </Flex>
  )
}
