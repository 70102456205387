import {
  ButtonLink,
  Stack,
  Text,
  TextLink,
  Flex,
  ButtonCopy,
} from '@gr4vy/poutine-react'
import { gr4vyId } from 'shared/helpers/get-environment'
import { Step } from './Step'

export const IntegrationStep = () => (
  <Step title="You’re ready to integrate">
    <Stack gap={16}>
      <Text variant="med2">
        Next, it’s time to add one of our integration options to your
        application. You can find more info in our{' '}
        <TextLink
          href="https://docs.gr4vy.com"
          variant="standalone"
          isExternal
          opensInNewWindow
        >
          developer documentation
        </TextLink>
        .
      </Text>
      <Flex gap={24} alignItems="center">
        <ButtonLink
          href="https://docs.gr4vy.com/guides/payments/embed/quick-start/overview"
          opensInNewWindow
        >
          Get Started with Embed
        </ButtonLink>
        <ButtonLink
          href="https://docs.gr4vy.com/guides/payments/secure-fields/quick-start/overview"
          opensInNewWindow
          variant="secondary"
        >
          Capture cards with Secure Fields
        </ButtonLink>
        <CopyIdCard />
      </Flex>
      <Text variant="med2">
        Alternatively, you can{' '}
        <TextLink
          href="https://docs.gr4vy.com/guides/payments/direct-api/quick-start/overview"
          opensInNewWindow
          variant="standalone"
        >
          integrate directly with our API
        </TextLink>
        .
      </Text>
    </Stack>
  </Step>
)

const CopyIdCard = () => (
  <Flex
    gap={8}
    alignItems="center"
    paddingRight={8}
    paddingLeft={16}
    background="white"
    height={40}
    borderRadius="rounded"
  >
    <Text variant="med2" color="gray70">
      Your Gr4vy ID:
    </Text>
    <Text variant="code2" color="black">
      {gr4vyId}
    </Text>
    <ButtonCopy copy={gr4vyId} size="small" variant="tertiary" />
  </Flex>
)
