import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { listAuditLogEntries } from 'audit-logs/services'
import { LIST_AUDIT_LOGS } from 'shared/constants'
import { useCursor, useFilters } from 'shared/hooks'
import View, { Filters } from './AuditLogsBrowsePage'

type AuditLogsBrowsePageProps = {
  title: string
}

const AuditLogsBrowsePage = ({ title }: AuditLogsBrowsePageProps) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const [cursor] = useCursor()
  const [filters, setFilters] = useFilters()

  const page = useQuery({
    queryKey: [LIST_AUDIT_LOGS, merchantAccountId, cursor, filters],
    queryFn: () => listAuditLogEntries({ cursor, ...filters }),
  })

  const updateFilter = (f: Filters) => {
    setFilters({ ...filters, ...f })
  }

  return (
    <View title={title} page={page} onFilter={updateFilter} filters={filters} />
  )
}

export default AuditLogsBrowsePage
