import { Button, Icon, Stack } from '@gr4vy/poutine-react'
import { Form, FormInstance } from 'antd'
import { useState } from 'react'
import { AddConnectionDrawer } from 'flows/components/AddConnectionDrawer'
import { OutcomeListV2 } from 'flows/components/OutcomeList/OutcomeListV2'
import {
  ConfigOutcomeType,
  Outcome,
  OutcomeFormValues,
  OutcomeSectionConfig,
  RuleConfigOutcome,
  TransactionActionOutcome,
} from 'flows/constants'
import { Section } from './Section'

export type CardOutcomeProps = {
  form: FormInstance
  outcomes: Outcome[]
  config?: OutcomeSectionConfig
  outcomeConfig: RuleConfigOutcome
}

export const CardOutcomeV2 = ({
  form,
  outcomes,
  config,
  outcomeConfig,
}: CardOutcomeProps) => {
  const [drawerForm] = Form.useForm<OutcomeFormValues>()
  const [showAddConnectionDrawer, setShowAddConnectionDrawer] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const onEditConnection = () => {
    setIsEditing(true)
    setShowAddConnectionDrawer(true)
  }

  const onCloseDrawer = () => {
    setShowAddConnectionDrawer(false)
    setIsEditing(false)
  }

  return (
    <>
      <Stack gap={16}>
        <Section title={config?.title}>{config?.textV2}</Section>
        {outcomeConfig.type === ConfigOutcomeType.CARD_ROUTING && (
          <>
            <Button
              type="button"
              onClick={() => setShowAddConnectionDrawer(true)}
            >
              <Icon name="add-plus" />
              Add connection
            </Button>
            <AddConnectionDrawer
              form={drawerForm}
              open={showAddConnectionDrawer}
              onClose={onCloseDrawer}
              isEditing={isEditing}
            />
          </>
        )}
      </Stack>
      {outcomeConfig.type === ConfigOutcomeType.CARD_ROUTING && (
        <OutcomeListV2
          form={form}
          drawerForm={drawerForm}
          outcomes={outcomes as TransactionActionOutcome[]}
          onEdit={onEditConnection}
        />
      )}
    </>
  )
}
