/**
 * Returns the user's default locale string
 * @returns string user's locale string
 */
export const getCurrentLocale = () => {
  if (!navigator?.language) {
    return 'en-US'
  }
  return navigator.languages?.length
    ? navigator.languages[0]
    : navigator.language
}
