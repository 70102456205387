import { useQueries } from '@tanstack/react-query'
import { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { getPaymentService } from 'connections/services'
import { Link } from 'shared/components/Link'
import { GET_PAYMENT_SERVICE } from 'shared/constants/query-keys/transactions'
import { is } from 'shared/helpers/is'
import { pathTo } from 'shared/paths/connections'
import { getCardOutcomeConnectorIds } from 'transactions/helpers/card-outcome-ids'
import { Transaction, TransactionFlow } from 'transactions/services'

export const ConnectorRouting = ({
  transactionFlow,
  transaction,
}: {
  transactionFlow: TransactionFlow
  transaction?: Transaction
}) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }

  const connectorIds = getCardOutcomeConnectorIds(transactionFlow.outcome)

  const connectorQueries = useQueries({
    queries: connectorIds.map((connectorId) => ({
      queryKey: [GET_PAYMENT_SERVICE, connectorId],
      queryFn: () => getPaymentService(connectorId),
      cacheTime: 1000 * 60 * 5,
      staleTime: 1000 * 60 * 5,
    })),
  })

  if (connectorQueries.find((c) => c.isLoading)) {
    return null
  }

  return connectorQueries.reduce(
    (result, item): ReactElement | null => {
      if (is.null(item.data)) {
        return result
      }

      const id = item.data?.id || transaction?.paymentService?.id
      const displayName =
        item.data?.displayName || transaction?.paymentService?.displayName

      const link = (
        <Link
          href={pathTo.editConnectionPaymentService(
            merchantAccountId,
            id as string
          )}
          key={id}
        >
          {displayName}
        </Link>
      )

      if (is.null(result)) {
        return link
      }

      return (
        <>
          {result}, {link}
        </>
      )
    },
    null as ReactElement | null
  )
}
