import { Alert, Heading, Stack } from '@gr4vy/poutine-react'
import { Summary } from 'shared/components/Summary'
import { useCardSchemeDefinition } from 'shared/hooks'
import EventsConnection, {
  Service,
} from 'transactions/components/EventsPanel/EventsConnection'
import { TransactionEvent } from 'transactions/services'
import styles from './styles.module.scss'

export const ConnectorCaptureResponseEvent = ({
  event,
}: {
  event: TransactionEvent
}) => {
  const scheme = useCardSchemeDefinition(event?.context?.paymentMethodScheme)

  return event ? (
    <Stack gap={32}>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Connection
        </Heading>
        <EventsConnection event={event} />
      </Stack>
      {scheme && (
        <Stack gap={8} alignItems="flex-start">
          <Heading as="h5" level={5}>
            Scheme
          </Heading>
          <Service iconUrl={scheme.iconUrl} name={scheme.displayName} />
        </Stack>
      )}
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Response
        </Heading>
        {event.context.paymentServiceDefinitionId != 'cybersource-card' && (
          <Summary className={styles.summary} inline>
            <Summary.Key>Connection reference</Summary.Key>
            <Summary.Value
              fallback={!event.context.paymentServiceTransactionId}
            >
              {event.context.paymentServiceTransactionId}
            </Summary.Value>
          </Summary>
        )}
        <Summary className={styles.summary} inline>
          <Summary.Key>Response code</Summary.Key>
          <Summary.Value fallback={!event.context.rawResponseCode}>
            {event.context.rawResponseCode}
          </Summary.Value>
        </Summary>
        <Summary className={styles.summary} inline>
          <Summary.Key>Response description</Summary.Key>
          <Summary.Value fallback={!event.context.rawResponseDescription}>
            {event.context.rawResponseDescription}
          </Summary.Value>
        </Summary>
      </Stack>
      <Alert variant="notice">
        <Alert.Icon />
        <Alert.Content>
          <Alert.Title>Pending captures</Alert.Title>
          <Alert.Text>
            A connection may mark a capture as pending, indicating that
            it&apos;s been sent for settlement but the money has not been
            transferred to your account. At this point we will treat the
            transaction as successfully captured.
          </Alert.Text>
        </Alert.Content>
      </Alert>
    </Stack>
  ) : null
}
