import { Badge, Box, Description, Flex, Stack, Tag } from '@gr4vy/poutine-react'
import { capitalize } from 'lodash'
import AuditLogDescription from 'audit-logs/components/AuditLogDescription'
import {
  ResourceFilter,
  resourceFilters,
} from 'audit-logs/components/ResourceFilter'
import { RESOURCE_NAMES, BADGE_COLOURS } from 'audit-logs/constants'
import { AuditLogAction, AuditLogEntry } from 'audit-logs/services'
import { useSession } from 'session'
import {
  DataTable,
  ColumnDef,
  DateTime,
  DataTableProps,
} from 'shared/components/DataTable'
import currencySymbol from 'shared/helpers/currency-symbol'
import { pathTo } from 'shared/paths/activity-logs'
import { pathTo as usersPathTo } from 'shared/paths/users'
import { QueryResult } from 'shared/services/client'
import styles from './AuditLogEntriesTable.module.scss'

export type AuditLogEntriesTableProps = Pick<
  DataTableProps<AuditLogEntry>,
  'pagination' | 'columns'
> & { page: QueryResult<AuditLogEntry> }

const description = (entry: AuditLogEntry) => {
  const descriptions: Record<string, CallableFunction> = {
    'apple-pay-certificate': (entry: AuditLogEntry) => {
      return (
        <AuditLogDescription
          type="Apple Pay Certificate"
          name={entry.resource.name}
          action={entry.action}
        />
      )
    },
    transaction: (entry: AuditLogEntry) => {
      const len = entry.resource.name.length
      const currency = currencySymbol(entry.resource.name.substring(len - 3))

      return (
        <AuditLogDescription
          type="Transaction"
          name={`${currency}${entry.resource.name}`}
          action={entry.action}
          href={
            entry.merchantAccountId
              ? pathTo.resource(
                  entry.merchantAccountId,
                  entry.resource.type,
                  entry.resource.id
                )
              : undefined
          }
          after
        />
      )
    },
    session: (entry: AuditLogEntry) => {
      return (
        <span>
          User {entry.action == 'created' ? 'logged in' : 'logged out'}
        </span>
      )
    },
    'role-assignment': (entry: AuditLogEntry) => {
      if (entry.action == 'deleted') {
        return (
          <span>Unassigned the {entry.resource.name} role from a user</span>
        )
      } else {
        return <span>Assigned the {entry.resource.name} role to a user</span>
      }
    },
    default: (entry: AuditLogEntry) => {
      const label = (entry: AuditLogEntry): string => {
        if (entry.resource.type in RESOURCE_NAMES) {
          return RESOURCE_NAMES[entry.resource.type]
        }

        return capitalize(entry.resource.type)
      }

      return (
        <AuditLogDescription
          type={label(entry)}
          name={entry.resource.name}
          action={entry.action}
          href={
            entry.merchantAccountId
              ? pathTo.resource(
                  entry.merchantAccountId,
                  entry.resource.type,
                  entry.resource.id
                )
              : undefined
          }
        />
      )
    },
  }

  return (descriptions[entry.resource.type] || descriptions['default'])(entry)
}

export const auditLogColumns: Array<ColumnDef<AuditLogEntry>> = [
  {
    header: 'User',
    size: 324,
    cell: function UserRow({ row }) {
      const { isStaff } = useSession()
      const { user } = row.original
      const canShowUserProfileLink = user.status !== 'deleted' && !user.isStaff

      return (
        <Stack gap={16} direction="row">
          <Description>
            {canShowUserProfileLink ? (
              <Description.Link href={usersPathTo.user(user.id)}>
                {user.name}
              </Description.Link>
            ) : (
              <Description.Text>{user.name}</Description.Text>
            )}
            <Description.SubText>{user.emailAddress}</Description.SubText>
          </Description>
          {user.isStaff && isStaff && (
            <Flex alignItems="center">
              <Tag variant="subtle" color="neutral">
                Gr4vy
              </Tag>
            </Flex>
          )}
        </Stack>
      )
    },
  },
  {
    header: 'Action',
    size: 208,
    cell: ({ row }) => {
      const { action, resource } = row.original
      const colour = action in BADGE_COLOURS ? BADGE_COLOURS[action] : 'neutral'

      const badges: Record<string, CallableFunction> = {
        session: (action: AuditLogAction) => {
          return (
            <Badge variant="subtle" color={colour}>
              {action == 'created' ? 'Logged In' : 'Logged Out'}
            </Badge>
          )
        },
        default: (action: AuditLogAction) => {
          return (
            <Badge variant="subtle" color={colour}>
              {capitalize(action)}
            </Badge>
          )
        },
      }

      return (badges[resource.type] || badges['default'])(action)
    },
  },
  {
    header: () => (
      <Box className={styles.filter}>
        <ResourceFilter />
      </Box>
    ),
    accessorKey: 'resourceType',
    size: 208,
    cell: ({ row }) => {
      return resourceFilters?.[row.original.resource.type]
        ? resourceFilters?.[row.original.resource.type]
        : capitalize(row.original.resource.type)
    },
  },
  {
    header: 'Description',
    size: 296,
    cell: ({ row }) => description(row.original as AuditLogEntry),
  },
  {
    header: 'Date/Time',
    size: 208,
    cell: ({ row }) => {
      const { timestamp } = row.original
      return <DateTime value={timestamp} />
    },
  },
]

const AuditLogEntriesTable = ({
  page,
  pagination,
  columns,
}: AuditLogEntriesTableProps) => {
  const { data, isSuccess } = page
  const loading = !isSuccess

  return (
    <DataTable
      data={data}
      loading={loading}
      columns={columns ?? auditLogColumns}
      pagination={pagination}
    />
  )
}

export default AuditLogEntriesTable
