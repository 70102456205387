import { ButtonCopy, Flex, Heading, Stack } from '@gr4vy/poutine-react'
import { Summary } from 'shared/components/Summary'
import currencyFormat from 'shared/helpers/currency-format'
import EventsConnection from 'transactions/components/EventsPanel/EventsConnection'
import { HTTPBody } from 'transactions/components/HTTPBody'
import { formatCode } from 'transactions/helpers'
import { TransactionEvent } from 'transactions/services'
import styles from './styles.module.scss'

export const TheGivingBlockConvertedReceivedEvent = ({
  event,
}: {
  event: TransactionEvent
}) => {
  return event ? (
    <Stack gap={32}>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Connection
        </Heading>
        <EventsConnection event={event} />
      </Stack>
      <Summary className={styles.summary} inline>
        <Summary.Key>Crypto amount</Summary.Key>
        <Summary.Value fallback={!event.context['cryptoAmount']}>
          {`${event.context['cryptoAmount']} ${event.context['cryptoCurrency']}`}
        </Summary.Value>
        <Summary.Key>Settled amount</Summary.Key>
        <Summary.Value fallback={!event.context['amount']}>
          {currencyFormat(
            event.context['amount'] || 0,
            { currency: event.context['currency'] },
            'en'
          )}
        </Summary.Value>
      </Summary>
      {event.context['response'] && (
        <Stack gap={8}>
          <Flex alignItems="flex-end" justifyContent="space-between">
            <Flex alignItems="flex-end" gap={8}>
              <Heading as="h5" level={5}>
                API response
              </Heading>
            </Flex>
            <ButtonCopy
              type="button"
              variant="tertiary"
              copy={formatCode(event.context['response'])}
              size="small"
              className={styles.responseCopy}
            >
              Copy
            </ButtonCopy>
          </Flex>
          <HTTPBody code={event.context['response']} language={'json'} />
        </Stack>
      )}
    </Stack>
  ) : null
}
