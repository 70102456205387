import {
  Box,
  Description,
  Flex,
  Heading,
  Icon,
  Stack,
  Tag,
  Tooltip,
} from '@gr4vy/poutine-react'
import { pathTo } from 'connections'
import { ConnectionItem } from 'reports/helpers/settlement'

export interface SupportedConnectionsProps {
  connections: ConnectionItem[]
  merchantAccountId?: string
}

export const SupportedConnections = ({
  connections,
  merchantAccountId,
}: SupportedConnectionsProps) => (
  <Stack gap={16}>
    <Heading as="h5" level={5}>
      Supported connections
    </Heading>
    {connections.map((connection) => (
      <Flex
        key={connection.id}
        alignItems="center"
        justifyContent="space-between"
        gap={8}
      >
        <Box minWidth={0}>
          <Description>
            <Description.Icon src={connection.iconUrl} size={32} />
            {!!merchantAccountId && (
              <Description.Link
                href={pathTo.editConnectionPaymentService(
                  merchantAccountId,
                  connection.id
                )}
              >
                {connection.name}
              </Description.Link>
            )}
          </Description>
        </Box>
        <Flex gap={8}>
          {!connection.active && <Tag variant="strong">Inactive</Tag>}
          <Tooltip
            side="left"
            content={
              connection.settlementReportingEnabled
                ? 'Settlement configured for this connection.'
                : 'Settlement not configured for this connection'
            }
          >
            <Icon
              name={
                connection.settlementReportingEnabled
                  ? 'circle-check'
                  : 'strike'
              }
              color={
                connection.settlementReportingEnabled ? 'green70' : 'gray70'
              }
              size="small"
            />
          </Tooltip>
        </Flex>
      </Flex>
    ))}
  </Stack>
)
