import {
  ColorAmber60,
  ColorBlack,
  ColorBlue10,
  ColorBlue60,
  ColorGray100,
  ColorGray50,
  ColorGreen70,
  ColorRed80,
} from '@gr4vy/poutine-tokens'
import { ConfigProvider, App } from 'antd'
import { ReactNode } from 'react'

export interface ThemeProviderProps {
  children: ReactNode
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => (
  <ConfigProvider
    theme={{
      hashed: false,
      token: {
        colorPrimary: ColorBlue60,
        colorLink: ColorBlue60,
        colorSuccess: ColorGreen70,
        colorWarning: ColorAmber60,
        colorError: ColorRed80,
        colorTextHeading: ColorBlack,
        colorText: ColorGray100,
        colorTextSecondary: ColorGray100,
        borderRadius: 4,
        colorBorder: ColorGray50,
        controlHeight: 40,
      },
      components: {
        Select: {
          optionHeight: 32,
          multipleItemHeight: 32,
          multipleItemBg: ColorBlue10,
          multipleItemBorderColor: ColorBlue10,
          colorTextBase: ColorBlack,
        },
        DatePicker: {
          cellWidth: 36,
          cellHeight: 24,
          textHeight: 40,
        },
      },
    }}
  >
    <App>{children}</App>
  </ConfigProvider>
)
