import { useSession } from 'session'

export const useUseManager = () => {
  const { user } = useSession()

  const isUserManager =
    user && !!user?.roles.find((role) => role.name === 'User Manager')

  return { isUserManager, loggedUser: user }
}
