import { useParams } from 'react-router-dom'
import { usePaymentService } from './use-payment-service'
import { usePaymentServiceDefinition } from './use-payment-service-definition'

export const usePaymentServiceAndDefinition = () => {
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }

  const { data: paymentService, isLoading: paymentServiceLoading } =
    usePaymentService(merchantAccountId, id)

  const { paymentServiceDefinition, isLoading: definitionLoading } =
    usePaymentServiceDefinition(
      merchantAccountId,
      paymentService?.paymentServiceDefinitionId
    )

  return {
    paymentService,
    definition: paymentServiceDefinition,
    isLoading: paymentServiceLoading || definitionLoading,
  }
}
