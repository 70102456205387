import { Button, Flex, Modal } from '@gr4vy/poutine-react'
import { Transaction } from 'transactions/services'

interface ModalTransactionRefreshProps {
  loading: boolean
  transaction: Transaction
  onRefresh: () => void
  onClose: () => void
}

const ModalTransactionRefresh = ({
  loading,
  onRefresh,
  onClose,
}: ModalTransactionRefreshProps) => {
  return (
    <Modal
      title="Refresh transaction details"
      description="Refreshing the transaction will fetch the latest details from the payments service. This may cause transaction details such as the status, amount, captured amount, and connector transaction reference to change."
      type="information"
      open
      onOpenChange={(status) => {
        !status && onClose()
      }}
      closeBtn={false}
    >
      <Flex justifyContent="flex-end" gap={16}>
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onRefresh()
          }}
          loading={loading}
          loadingText="Refreshing"
        >
          Refresh
        </Button>
      </Flex>
    </Modal>
  )
}

export default ModalTransactionRefresh
