import { Box, Stack } from '@gr4vy/poutine-react'
import { Input } from 'antd'
import React from 'react'
import { Icon, IconType } from 'shared/components/Icon'

interface Props {
  value?: string
  placeholder?: string
  autoFocus?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const InputName = ({ ...props }: Props) => (
  <Stack
    gap={16}
    direction="row"
    alignItems="center"
    justifyContent="flex-start"
  >
    <Box width={24}>
      <Icon iconType={IconType.Edit} as="span" />
    </Box>
    <Input
      {...props}
      variant="borderless"
      style={{
        padding: 0,
        fontSize: 16,
        fontWeight: 700,
      }}
    />
  </Stack>
)
