import { Select } from 'antd'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { currencies } from 'shared/helpers/currency'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'

interface CurrencyInputProps {
  name: string | string[]
  mode?: 'multiple' | 'tags'
  label?: string
}

const filter = (value: string, option: any) =>
  option.value.toLowerCase().includes(value.toLowerCase()) ||
  option.label.toLowerCase().includes(value.toLowerCase())

export const ERROR_CURRENCY_REQUIRED = 'At least one currency is required'

export const CurrencyInput = ({
  name,
  mode,
  label = 'Value',
}: CurrencyInputProps) => (
  <FormItem
    label={<Label>{label}</Label>}
    name={name}
    rules={[{ required: true, message: ERROR_CURRENCY_REQUIRED }]}
  >
    <Select
      mode={mode}
      allowClear
      placeholder="Select an option"
      filterOption={filter}
      maxTagCount="responsive"
      maxTagPlaceholder={maxTagPlaceholderFormatter}
      showSearch
    >
      {currencies.map(({ code, currency }) => (
        <Select.Option value={code} key={code} label={currency}>
          {code} - {currency}
        </Select.Option>
      ))}
    </Select>
  </FormItem>
)
