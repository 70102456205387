import { UseQueryResult } from '@tanstack/react-query'
import axios, {
  AxiosHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import createAuthRefreshInterceptor, {
  AxiosAuthRefreshRequestConfig,
} from 'axios-auth-refresh'
import { apiBaseUrl } from 'shared/helpers/api-url'
import { getMerchantAccountHeaderId } from 'shared/utils/merchant-account-id'
import { snakeCaseKeys, camelCaseKeys } from './transform-keys'

export interface Session {
  accessToken: string
  expiresIn: string
  refreshToken: string
  tokenType: string
  type: string
}

const snakeCaseInterceptor = (config: InternalAxiosRequestConfig) => {
  const newConfig = { ...config }

  if (config.params) {
    newConfig.params = snakeCaseKeys(config.params, {
      ignore: ['gift_card_last4'],
    })
  }
  if (config.data && !(config.data instanceof FormData)) {
    newConfig.data = snakeCaseKeys(config.data, { ignoreDeep: ['metadata'] })
  }
  return newConfig
}

const camelCaseInterceptor = (response: AxiosResponse) => {
  const newConfig = { ...response }

  if (response.data) {
    newConfig.data = camelCaseKeys(response.data, { ignoreDeep: ['metadata'] })
  }

  return newConfig
}

export type CollectionResponse<T> = {
  items: Array<T>
  limit: number
  nextCursor?: string
  previousCursor?: string
}

export type QueryResult<T> = UseQueryResult<CollectionResponse<T>>

let failedAuthCallback: CallableFunction = () => {}

export const setFailedAuthCallback = (fn: CallableFunction) =>
  (failedAuthCallback = fn)

const config = {
  baseURL: apiBaseUrl,
  timeout: 20000,
}

const client = axios.create(config)
// automatically pickup a bearer token
client.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem('access_token')
  const merchantAccountId = getMerchantAccountHeaderId(config)
  // do not attempt a request without a token
  if (!accessToken) {
    failedAuthCallback()
    config.cancelToken = new axios.CancelToken((cancel) =>
      cancel(
        `Missing access token (${config.url} ${JSON.stringify(config.params)})`
      )
    )
  }

  if (!config['headers']) {
    config['headers'] = new AxiosHeaders()
  }

  config['headers']['Authorization'] = `Bearer ${accessToken}`

  if (merchantAccountId) {
    config['headers']['X-Gr4vy-Merchant-Account-Id'] = merchantAccountId
  }

  return config
})

client.interceptors.request.use(snakeCaseInterceptor)
client.interceptors.response.use(camelCaseInterceptor)

export const clientWithoutToken = axios.create(config)

clientWithoutToken.interceptors.request.use(snakeCaseInterceptor)
clientWithoutToken.interceptors.response.use(camelCaseInterceptor)

const refreshAuthLogic = (failedRequest: any) =>
  clientWithoutToken
    .put<Session>('/auth/sessions', undefined, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('refresh_token')}`,
      },
      skipAuthRefresh: true,
      pauseInstanceWhileRefreshing: true,
    } as AxiosAuthRefreshRequestConfig)
    .then((tokenRefreshResponse) => {
      // store the new tokens
      localStorage.setItem(
        'access_token',
        tokenRefreshResponse.data.accessToken
      )
      localStorage.setItem(
        'refresh_token',
        tokenRefreshResponse.data.refreshToken
      )

      // set the header on the failed request
      failedRequest.response.config.headers['Authorization'] =
        'Bearer ' + tokenRefreshResponse.data.accessToken

      return Promise.resolve()
    })
    .catch((error) => {
      failedAuthCallback()
      return Promise.reject(error)
    })

createAuthRefreshInterceptor(client, refreshAuthLogic)

export default client
