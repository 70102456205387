const emptyObject = (value: Record<string, any> | undefined) => {
  if (!value || !plainObject(value)) {
    return true
  }
  return Object.keys(value).length === 0
}

const emptyString = (value: unknown) =>
  string(value) && (value as string).length === 0

const emptyStringOrWhitespace = (value: unknown) =>
  string(value) && emptyString((value as string).trim())

const plainObject = (value: unknown) =>
  Object.prototype.toString.call(value) === '[object Object]'

const string = (value: unknown) => typeof value === 'string'

export const is = {
  array: (value: unknown) => Array.isArray(value),
  boolean: (value: unknown) => value === true || value === false,
  emptyObject,
  emptyString,
  emptyStringOrWhitespace,
  null: (value: unknown) => value === null,
  plainObject,
  string,
  undefined: (value: unknown) => typeof value === 'undefined',
}
