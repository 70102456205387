import { useEffect, useMemo, useState } from 'react'
import {
  Catalog,
  CatalogGroup,
  categoryToGroupMap,
  connectionDefinitionGroupNames,
} from 'connections/constants'
import {
  ConnectionDefinition,
  ConnectionDefinitionGroup,
} from 'connections/services'

const initialCatalog: Catalog = {
  [ConnectionDefinitionGroup.cards]: {
    name: connectionDefinitionGroupNames[ConnectionDefinitionGroup.cards],
    categories: {},
  },
  [ConnectionDefinitionGroup.nonCards]: {
    name: connectionDefinitionGroupNames[ConnectionDefinitionGroup.nonCards],
    categories: {},
  },
  [ConnectionDefinitionGroup.other]: {
    name: connectionDefinitionGroupNames[ConnectionDefinitionGroup.other],
    categories: {},
  },
}

const addConnectionDefinitionToGroup = (
  group: CatalogGroup,
  connectionDefinition: ConnectionDefinition
) => {
  return {
    ...group,
    count: (group.count ?? 0) + 1,
    categories: {
      ...group.categories,
      [connectionDefinition.category]: [
        ...(group.categories[connectionDefinition.category] ?? []),
        connectionDefinition,
      ],
    },
  }
}

const nameFilter = (name: string, value: string) =>
  name.toLocaleLowerCase().includes(value.toLocaleLowerCase())

const providerFilter = (provider: string, value: string) =>
  provider && provider.toLocaleLowerCase().includes(value.toLocaleLowerCase())

export const digitalWalletFilter = ({
  id,
  count,
}: {
  id: string
  count: number
}) => !(['apple', 'google'].includes(id) && count > 0)

export const useCatalogSearch = (
  connectionDefinitions: ConnectionDefinition[]
) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredConnectionDefinitions, setFilteredConnectionDefinitions] =
    useState(connectionDefinitions)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => search(searchTerm), [connectionDefinitions])

  const search = (value: string) => {
    const filteredConnectionDefinitionState = connectionDefinitions.filter(
      (c) =>
        (nameFilter(c.name, value) || providerFilter(c.provider, value)) &&
        digitalWalletFilter(c)
    )

    setSearchTerm(value)
    setFilteredConnectionDefinitions(filteredConnectionDefinitionState)
  }

  const catalog = useMemo(
    () =>
      filteredConnectionDefinitions.reduce((catalog, connectionDefinition) => {
        const group = categoryToGroupMap[connectionDefinition.category]

        return {
          ...catalog,
          count: (catalog.count ?? 0) + 1,
          [group]: addConnectionDefinitionToGroup(
            catalog[group],
            connectionDefinition
          ),
        }
      }, initialCatalog),
    [filteredConnectionDefinitions]
  )

  return {
    searchTerm,
    search,
    catalog,
  }
}
