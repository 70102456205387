import { Select } from 'antd'
import { useEffect, useRef } from 'react'
import { Condition, RuleConfigCondition } from 'flows/constants'

interface ConditionSelectProps {
  value: Condition | null
  options: RuleConfigCondition[]
  onSelect?: (value: Condition) => void
}

export const ConditionSelect = ({
  value,
  options,
  onSelect,
}: ConditionSelectProps) => {
  const selectEl = useRef<any>(null)

  useEffect(() => {
    selectEl.current.focus()
  }, [])

  return (
    <Select
      placeholder="Select an option"
      value={value}
      onSelect={onSelect}
      style={{ width: '100%' }}
      ref={selectEl}
    >
      {options.map(({ name, label }) => (
        <Select.Option value={name} key={name}>
          {label}
        </Select.Option>
      ))}
    </Select>
  )
}
