import client, { CollectionResponse } from 'shared/services/client'

export interface PaymentServiceDefinitionField {
  key: string
  displayName: string
  required: boolean
  format: 'text' | 'multiline' | 'number' | 'timezone'
  secret: boolean
}

interface PaymentServiceSupportedFeatures {
  delayedCapture: boolean
  paymentMethodTokenization: boolean
  paymentMethodTokenizationToggle: boolean
  threeDSecureHosted: boolean
  threeDSecurePassThrough: boolean
  verifyCredentials: boolean
  refunds: boolean
  void: boolean
  openLoop: boolean
  openLoopToggle: boolean
  networkTokensToggle: boolean
  partialRefunds: boolean
  requiresWebhookSetup: boolean
  overCapture: boolean
  settlementReporting: boolean
  transactionSync: boolean
}

export interface PaymentServiceDefinition {
  id: string
  displayName: string
  method: string
  fields: PaymentServiceDefinitionField[]
  reportingFields: PaymentServiceDefinitionField[]
  supportedCurrencies: string[]
  supportedCountries: string[]
  supportedFeatures: Partial<PaymentServiceSupportedFeatures>
  iconUrl: string
}

export const listDefinitions = async (
  params = {}
): Promise<CollectionResponse<PaymentServiceDefinition>> => {
  const response = await client.get('/payment-service-definitions', params)
  return response.data
}

export const getDefinition = async (
  id: string,
  params = {}
): Promise<PaymentServiceDefinition> => {
  const response = await client.get(`/payment-service-definitions/${id}`, {
    params,
  })
  return response.data
}
