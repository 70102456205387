import { cloneDeep } from 'lodash'
import {
  AmountCondition,
  Condition,
  ConfigOutcomeType,
  Operation,
  RuleOutcomeType,
  ActionOutcome,
  Outcome,
  Rule,
  RuleCondition,
  RuleOutcome,
  RuleConfig,
  RuleConfigCondition,
  RuleConfigOutcome,
  RULE_ERROR_CODE_PREFIX,
} from 'flows/constants'
import { Action, Flow } from 'shared/constants'
import { currencyFromSubunits, currencyToSubunits } from 'shared/utils/currency'

export type RuleAmountCondition = RuleCondition & {
  name: AmountCondition
}

export type FormValues = {
  description: string
  amountCondition: RuleAmountCondition
  conditions: RuleCondition[]
  hiddenConditions: RuleCondition[]
  outcome: RuleOutcome
  errorCode?: string
}

const AMOUNT_CONDITIONS = [
  AmountCondition.ANY_AMOUNT_OR_CURRENCY,
  AmountCondition.AMOUNT,
  AmountCondition.CURRENCY,
  AmountCondition.AMOUNT_ZERO,
]

export const HIDDEN_CONDITIONS = [Condition.PAYMENT_METHOD]

const DEFAULT_AMOUNT_CONDITION: RuleAmountCondition = {
  name: AmountCondition.ANY_AMOUNT_OR_CURRENCY,
}

const currencyFormatAmountCondition = (
  amountCondition: RuleAmountCondition,
  format: (value: any, currency: string) => number | string
): RuleAmountCondition => {
  const { name, operator, value } = amountCondition
  if (name === AmountCondition.AMOUNT) {
    return {
      ...amountCondition,
      value:
        operator === Operation.IS_BETWEEN
          ? {
              ...value,
              min: format(value.min, value.currency),
              max: format(value.max, value.currency),
            }
          : {
              ...value,
              value: format(value.value, value.currency),
            },
    }
  }
  if (name === AmountCondition.AMOUNT_ZERO) {
    return {
      name: name,
      operator: Operation.EQUAL_TO,
      value: 0,
    }
  }
  return amountCondition
}

const createInitialHiddenConditions = (
  conditions: RuleConfigCondition[],
  searchParams: URLSearchParams
): RuleCondition[] => {
  const hiddenConditions = conditions.filter(
    ({ name, hidden }) =>
      (HIDDEN_CONDITIONS as string[]).includes(name) && hidden
  )

  return hiddenConditions.map((condition) => ({
    name: condition.name,
    operator: condition.operator,
    value: searchParams.get(condition.name),
  }))
}

const createInitialRuleOutcome = (outcome: RuleConfigOutcome): RuleOutcome => {
  if (outcome.type === ConfigOutcomeType.CARD_ROUTING) {
    return {
      type: RuleOutcomeType.CARD_ROUTING,
      result: [],
      retryDeclines: 0,
    }
  }
  if (outcome.type === ConfigOutcomeType.BOOLEAN) {
    return {
      type: RuleOutcomeType.BOOLEAN,
      result: true,
    }
  }
  if (outcome.type === ConfigOutcomeType.THREE_D_SECURE) {
    return {
      type: RuleOutcomeType.THREE_D_SECURE,
      result: 'attempt',
    }
  }

  return {
    type: RuleOutcomeType.LIST,
    result: [],
  }
}

const createInitialRule = (
  config: RuleConfig,
  searchParams: URLSearchParams
): FormValues => ({
  description: '',
  conditions: [],
  hiddenConditions: createInitialHiddenConditions(
    config.conditions,
    searchParams
  ),
  amountCondition: DEFAULT_AMOUNT_CONDITION,
  outcome: createInitialRuleOutcome(config.outcome),
})

const getExistingOutcomesFromRule = (rule: Rule, outcomes: Outcome[]) =>
  (rule.outcome.result as string[]).filter((outcomeId) =>
    (outcomes as ActionOutcome[]).find(({ id }) => id === outcomeId)
  )

const outcomeToFormValues = (rule: Rule, outcomes: Outcome[]): RuleOutcome => {
  switch (rule.outcome.type) {
    case RuleOutcomeType.LIST:
      return {
        ...rule.outcome,
        result: getExistingOutcomesFromRule(rule, outcomes),
      }
    case RuleOutcomeType.CARD_ROUTING:
      return {
        ...rule.outcome,
        ...('version' in rule.outcome &&
          rule.outcome.version === 1 && {
            result: getExistingOutcomesFromRule(rule, outcomes),
          }),
      }

    default:
      return cloneDeep(rule.outcome)
  }
}

const ruleToFormValues = (
  rule: Rule | undefined,
  outcomes: Outcome[],
  config: RuleConfig,
  searchParams: URLSearchParams
): FormValues => {
  const getAmountCondition = (conditions: RuleCondition[]) => {
    const amountCondition = conditions.find(({ name }) =>
      (AMOUNT_CONDITIONS as string[]).includes(name)
    )
    return currencyFormatAmountCondition(
      (amountCondition ?? DEFAULT_AMOUNT_CONDITION) as RuleAmountCondition,
      currencyFromSubunits
    )
  }

  const specialConditions = (AMOUNT_CONDITIONS as string[]).concat(
    rule?.flow === Flow.nonCardTransactions
      ? []
      : (HIDDEN_CONDITIONS as string[])
  )

  return rule
    ? {
        description: rule.description,
        amountCondition: getAmountCondition(rule.conditions),
        // Check for amount conditions and remove from the array
        conditions: rule.conditions.filter(
          ({ name }) => !specialConditions.includes(name)
        ),
        hiddenConditions: rule.conditions.filter(
          ({ name }) =>
            (HIDDEN_CONDITIONS as string[]).includes(name) &&
            rule.action !== Action.declineEarly
        ),
        outcome: outcomeToFormValues(rule, outcomes),
        ...(config.hasErrorCode
          ? {
              errorCode: rule.errorCode?.replace(RULE_ERROR_CODE_PREFIX, ''),
            }
          : {}),
      }
    : createInitialRule(config, searchParams)
}

const formValuesToRule = (
  values: FormValues
): Omit<Rule, 'position' | 'flow' | 'action' | 'id'> => {
  const conditions: RuleCondition[] = []
  const isErrorCodeDefined = typeof values.errorCode !== 'undefined'
  let errorCode

  if (values.amountCondition.name) {
    conditions.push(
      currencyFormatAmountCondition(values.amountCondition, currencyToSubunits)
    )
  }

  if (isErrorCodeDefined) {
    errorCode = !values.errorCode
      ? null
      : `${RULE_ERROR_CODE_PREFIX}${values.errorCode}`
  }

  return {
    conditions: [
      ...conditions,
      ...values.hiddenConditions,
      ...values.conditions,
    ],
    description: values.description,
    outcome: values.outcome,
    ...(isErrorCodeDefined
      ? {
          errorCode,
        }
      : {}),
  }
}

export {
  createInitialRule,
  currencyFormatAmountCondition,
  ruleToFormValues,
  formValuesToRule,
  createInitialRuleOutcome,
}
